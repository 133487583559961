import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useAPI from 'components/hooks/useAPI';

// Constants
import { MAX_DESCRIPTION_LENGTH } from 'constants/player';
import { setDescription } from 'store/player';
import stats from 'analytics/matomo';

function DescriptionEditor() {
  const { description } = useSelector((state) => state.player.player);

  const { post } = useAPI();
  const { t } = useTranslation(['general', 'errors']);

  const [newDescription, setNewDescription] = useState(description);
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const dispatch = useDispatch();

  const _setDescription = (_description) => {
    setStatus('');

    if (_description.length >= MAX_DESCRIPTION_LENGTH) {
      setStatus(`${t('errors:max_length_reached_error')}!`);
      return;
    }

    setNewDescription(_description);
  };

  const saveDescription = async () => {
    try {
      setStatus('');
      setDisabled(true);

      const data = { description: newDescription };

      await post('/player/description', data);

      dispatch(setDescription(newDescription));

      stats.push(['trackEvent', 'Player Profile', 'New description set']);

      setDisabled(false);
      setStatus(`${t('general:saved_successfully')}!`);
    } catch (e) {
      setDisabled(false);
      setStatus(`${t('errors:save_new_description_error')}.`);
    }
  };

  const showSaveButton = newDescription !== description;

  return (
    <div className="">
      <label className="label has-text-left has-text-weight-semibold is-size-4-desktop is-size-5-touch">
        {t('general:description')}
      </label>
      <textarea
        onChange={(event) => _setDescription(event.target.value)}
        type="text"
        value={newDescription}
        rows={3}
        placeholder={t('general:description')}
        onKeyPress={(e) => {
          if (e.key === 'Enter') e.preventDefault();
        }}
        className="textarea br-5 no-shadow has-border p-3 has-fixed-size is-size-6 is-paddingless is-inline has-fullheight has-fullwidth"
      />
      {
        showSaveButton && (
        <div className="mt-4 has-text-left">
          <button
            type="button"
            className="button is-block is-primary grow_small has-text-weight-semibold"
            onClick={saveDescription}
            disabled={disabled}
          >
            {t('general:save')}
          </button>

          <p className="has-text-weight-semibold mt-4">
            { status }
          </p>
        </div>
        )
      }
    </div>
  );
}

export default DescriptionEditor;
