import React, { useMemo } from 'react';

// Components
import Dropdown from 'components/utils/Dropdown';

// Hooks
import { useTranslation } from 'react-i18next';

// Libraries
import PropTypes from 'prop-types';

function RankModeSelector({
  allModes, availableModes, mode, setMode,
}) {
  const { t } = useTranslation(['general']);

  const _availableModes = useMemo(() => availableModes.map((_mode) => ({
    label: _mode,
    value: _mode,
  })), [availableModes]);

  const selectedMode = useMemo(() => {
    const _selectedMode = allModes.find((_mode) => _mode === mode);
    if (!_selectedMode) return null;

    return _selectedMode;
  }, [mode, allModes]);

  const _setMode = (newMode) => {
    const modeSelected = _availableModes.find((_mode) => _mode.value === newMode.value);

    if (modeSelected === undefined || modeSelected.value === mode) {
      return;
    }
    setMode(newMode.value);
  };

  return (
    <div className="field is-inline-block">
      <div className="">
        <p className="is-size-5 has-text-grey">
          {t('general:mode')}
        </p>
        <div className="has-width-200 has-margin-left-auto">
          <Dropdown
            items={_availableModes}
            onSelect={_setMode}
            value={selectedMode}
            disabled={availableModes.length === 0}
          />
        </div>
      </div>
    </div>
  );
}

RankModeSelector.propTypes = {
  mode: PropTypes.string.isRequired,
  setMode: PropTypes.func.isRequired,
  availableModes: PropTypes.arrayOf(PropTypes.string).isRequired,
  allModes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RankModeSelector;
