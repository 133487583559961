import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Constants
import { MAX_CARD_NAME_LENGTH } from 'constants/cards';

function NameCreator(props) {
  const { name, setName } = props;
  const [error, setError] = useState('');

  const setNameAndValidate = (text) => {
    if (text.length > MAX_CARD_NAME_LENGTH) {
      setError('Maximale Länge eines Cardnamen erreicht!');
      return;
    }

    setError('');
    setName(text);
  };

  return (
    <div className="field">
      <label className="label">
        Name
      </label>
      <div className="control">
        <input
          className="input"
          type="text"
          value={name}
          placeholder="Teamname / Spielername"
          onChange={(e) => setNameAndValidate(e.target.value)}
        />
        <p className="has-text-weight-semibold has-text-danger">
          {error}
        </p>
      </div>
    </div>
  );
}

NameCreator.propTypes = {
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
};

export default NameCreator;
