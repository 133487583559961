import React, { useState } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import useAPI from 'components/hooks/useAPI';

// Constants
import { MAX_TOURNAMENT_PRIZE_POOL_LENGTH } from 'constants/tournaments';
import AsyncTextareaEditor from 'components/utils/inputs/AsyncTextareaEditor';

function TournamentPrizePoolEditor() {
  const { info, setInfo, publicId } = useTournament();
  const { prizePool } = info;

  const { post } = useAPI();

  const savePrizePool = async (newPrizePool) => {
    const data = { prizePool: newPrizePool };

    await post(`/tournaments/prizePool/${publicId}`, data);

    setInfo({ prizePool: newPrizePool });

    stats.push(['trackEvent', 'Tournament Editor', 'New prizePool set']);
  };

  return (
    <AsyncTextareaEditor
      text={prizePool}
      label="prize_pool"
      onSave={savePrizePool}
      maxLength={MAX_TOURNAMENT_PRIZE_POOL_LENGTH}
    />
  );
}

export default TournamentPrizePoolEditor;
