import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import ContactField from 'components/utils/ContactField';

function EmailContact(props) {
  const { value, setValue } = props;
  const [error, setError] = useState('');

  const { t } = useTranslation(['contacts', 'validation']);

  const setAndValidate = (name) => {
    setError('');

    if (name.length > 64) {
      setError(t('validation:enter_valid_email_error'));
      return;
    }

    setValue('email', name);
  };

  return (
    <ContactField
      icon="fas fa-envelope has-text-white is-size-2"
      value={value}
      onChange={setAndValidate}
      error={error}
      label="Email"
      placeholder="scrimps@scrimps.wtf"
    />
  );
}

EmailContact.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
};

EmailContact.defaultProps = {
  value: '',
};

export default EmailContact;
