import React, { useMemo } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
// Components
import Popup from 'reactjs-popup';
import GameFilterDropdown from 'components/utils/games/GameFilterDropdown';
import EntryTypeSelector from './EntryTypeSelector';
import PlatformFilterDropdown from 'components/utils/PlatformFilterDropdown';
import AmbitionTypeSelector from './AmbitionTypeSelector';
import SpecialIconSelector from './SpecialIconSelector';
import ProviderTypeSelector from './ProviderTypeSelector';

function PinboardFilter({
  filter, setFilter, resetFilter, disabled,
}) {
  const { t } = useTranslation(['general']);

  const {
    entryType, providerType, game, platform, ambitionType, specialIcon,
  } = filter;

  const { tag } = game;

  const _setGameTagFilter = (_tag) => {
    setFilter({
      ...filter,
      platform: '',
      game: { ...game, tag: _tag },
    });
  };

  const _setFilter = (_filter) => {
    setFilter({ ...filter, ..._filter });
  };

  const amountActiveFilters = useMemo(() => {
    let amount = 0;

    if (entryType) amount += 1;
    if (providerType) amount += 1;
    if (game.tag) amount += 1;
    if (platform) amount += 1;
    if (ambitionType) amount += 1;
    if (specialIcon) amount += 1;

    return amount;
  }, [entryType, providerType, game.tag, platform, ambitionType, specialIcon]);

  return (

    <div className="">

      <div className="has-text-right is-flex has-content-centered-vertically">
        <div className="has-margin-left-auto">
          <Popup
            position="top center"
            keepTooltipInside="#root"
            repositionOnResize
            closeOnDocumentClick
            closeOnEscape
            className="no-blur"
            trigger={(
              <div className="is-flex has-content-centered-vertically has-cursor-pointer dont-select-text">
                <i className="fas fa-sliders-h mr-3 is-size-5" />
                <p className="has-text-weight-semibold is-size-5">
                  { `${t('general:filter')} (${amountActiveFilters})`}
                </p>
              </div>
        )}
          >
            {
                () => (
                  <div className="">
                    <div className="box has-max-width-600">
                      <div className="is-flex has-content-centered-vertically">
                        <p className="has-text-weight-semibold is-size-5">
                          { t('general:filter')}
                        </p>
                        <button
                          onClick={resetFilter}
                          type="button"
                          className="cleanButton has-margin-left-auto"
                          aria-label={t('general:reset')}
                        >
                          <i className="fas fa-redo-alt has-text-primary is-size-5" />
                        </button>
                      </div>
                      <div className="columns is-multiline">

                        <div className="column is-2-desktop has-min-width-250">
                          <EntryTypeSelector
                            entryType={entryType}
                            setEntryType={(_entryType) => _setFilter({ entryType: _entryType })}
                            disabled={disabled}
                          />
                        </div>

                        <div className="column is-2-desktop has-min-width-250">
                          <ProviderTypeSelector
                            providerType={providerType}
                            setProviderType={(_providerType) => _setFilter({ providerType: _providerType })}
                            disabled={disabled}
                          />
                        </div>

                        <div className="column is-2-desktop has-min-width-250">
                          <AmbitionTypeSelector
                            ambitionType={ambitionType}
                            setAmbitionType={(_ambitionType) => _setFilter({ ambitionType: _ambitionType })}
                            disabled={disabled}
                          />
                        </div>

                        <div className="column is-2-desktop has-min-width-250">
                          <SpecialIconSelector
                            specialIcon={specialIcon}
                            setSpecialIcon={(_specialIcon) => _setFilter({ specialIcon: _specialIcon })}
                            disabled={disabled}
                          />
                        </div>

                        <div className="column is-2-desktop has-min-width-250">
                          <GameFilterDropdown
                            game={tag}
                            setGame={_setGameTagFilter}
                            filter={['NL']}
                            disabled={disabled}
                          />
                        </div>

                        {
                          game.tag && (
                            <div className="column is-2-desktop has-min-width-250">
                              <PlatformFilterDropdown
                                game={tag}
                                platform={platform}
                                setPlatform={(_platform) => _setFilter({ platform: _platform })}
                                disabled={disabled}
                              />
                            </div>
                          )
                        }

                      </div>
                    </div>
                  </div>
                )
            }
          </Popup>
        </div>
        <button
          onClick={resetFilter}
          type="button"
          className="cleanButton ml-3"
          aria-label={t('general:reset')}
        >
          <i className="fas fa-redo-alt has-text-primary is-size-5" />
        </button>
      </div>

    </div>

  );
}

export default PinboardFilter;
