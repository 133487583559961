import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import { useDropzone } from 'react-dropzone';

function ChatImageUploader(props) {
  const { onUpload } = props;

  const onDrop = (files) => {
    // if file has been rejected, data will be empty
    if (files.length === 0) return;

    const file = {
      data: files[0],
      url: URL.createObjectURL(files[0]),
      type: 'image',
    };

    onUpload(file);
  };

  const onDropRejected = () => {

  };

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
    },
    noDrag: true,
    onDrop,
    maxFiles: 1,
    // max 2 MB
    maxSize: 2097152,
    onDropRejected,
  });

  return (
    <div {...getRootProps({
      className: 'has-cursor-pointer has-text-centered',
    })}
    >
      <input {...getInputProps()} />

      <div className="has-text-centered has-fullwidth">
        <i className="fas fa-image has-text-grey is-size-5" />
      </div>
    </div>
  );
}

ChatImageUploader.propTypes = {
  onUpload: PropTypes.func.isRequired,
};

export default ChatImageUploader;
