import React, { useState, useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

// Hooks
import { useTranslation } from 'react-i18next';
import { useTeamProfile } from 'components/Teams/TeamProfile';
import useAPI from 'components/hooks/useAPI';

// Components
import Dropdown from 'components/utils/Dropdown';

// Constants
// import { INGAME_ROLES } from 'constants/team';
import { getSelectedGame } from 'constants/games';

function SetIngameRoleDropdown(props) {
  const { playerIndex, disabled, setDisabled } = props;

  const { t } = useTranslation(['general']);
  const { post } = useAPI();

  const {
    permissions, members, game, publicId,
    setIngameRole,
  } = useTeamProfile();
  const { canSetIngameRole } = permissions;

  const { playerId, ingameRole } = members[playerIndex];

  const _setIngameRole = async (_role) => {
    try {
      setDisabled(true);

      const newRole = _role.value.toLowerCase();

      const data = {
        teamId: publicId,
        playerId,
        ingameRole: newRole,
      };

      await post('/teams/members/ingameRole/', data);

      setIngameRole(playerId, newRole);

      stats.push(['trackEvent', 'Team Profile', 'Set ingame role']);

      setDisabled(false);
    } catch (e) {
      console.log(e);
      setDisabled(false);
    }
  };

  const availableIngameRoles = useMemo(() => {
    const { tagsPerCategory } = getSelectedGame(game.tag);
    const INGAME_ROLES = tagsPerCategory.find((category) => category.category === 'role');

    const roles = [
      {
        value: '',
        label: t('general:no_ingame_role'),
      },
    ];

    if (!INGAME_ROLES) return roles;

    const { tags } = INGAME_ROLES;

    const allRoles = tags.map((role) => ({
      value: role,
      label: role,
    }));

    return [...roles, ...allRoles];
  }, [game.tag]);

  const selectedRole = useMemo(() => {
    const role = availableIngameRoles.find((_role) => _role.value.toLowerCase() === ingameRole);

    return role;
  }, [ingameRole, availableIngameRoles]);

  if (!canSetIngameRole) return null;

  return (
    <div>
      <p
        className=""
      >
        {t('general:ingame_role')}
      </p>
      <div className="columns is-multiline">
        <div className="column px-0">

          <Dropdown
            items={availableIngameRoles}
            onSelect={_setIngameRole}
            value={selectedRole}
            disabled={disabled}
          />
        </div>

        <div className="column px-0 is-3 has-min-width-200 is-flex has-content-centered">
          {
            selectedRole.value && (
              <p className="is-size-7 has-border-grey p-1 br-5">
                { selectedRole.label }
              </p>
            )
          }
        </div>
      </div>
    </div>

  );
}

SetIngameRoleDropdown.propTypes = {
  playerIndex: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
};

export default SetIngameRoleDropdown;
