import React, { useState, useEffect } from 'react';

// Libaries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import { MAX_ORGA_NAME_LENGTH } from 'constants/orgas';

const regex = new RegExp(/^[\u00C0-\u017FA-Za-z0-9-_. ]+$/);

function NameFilter(props) {
  const { name, setName, disabled } = props;

  const { t } = useTranslation(['general', 'validation']);

  const [currentName, setCurrentName] = useState('');

  useEffect(() => {
    if (name === '') {
      setCurrentName('');
    }
  }, [name]);

  const _setName = (_name) => {
    if (_name.length === 0) {
      setName('');
      setCurrentName('');
      return;
    }

    if (_name.length > MAX_ORGA_NAME_LENGTH) {
      return;
    }

    if (regex.test(_name) === false) {
      return;
    }

    setCurrentName(_name);
  };

  const showSaveButton = currentName.length !== 0;

  return (
    <div>
      <div className="is-flex">
        <div className="is-inline-block has-max-width-250 has-text-left">
          <div className="control">
            <input
              className="input"
              type="text"
              value={currentName}
              placeholder={t('general:name')}
              disabled={disabled}
              onChange={(e) => _setName(e.target.value)}
            />
          </div>
        </div>
        {
        showSaveButton && (
        <div className="has-text-left is-flex mx-2">
          <button
            type="button"
            className="button is-block is-primary grow_small has-text-weight-semibold has-margin-top-auto"
            onClick={() => setName(currentName)}
            disabled={disabled}
          >
            {t('general:search')}
          </button>

        </div>
        )
      }

      </div>
    </div>

  );
}

NameFilter.propTypes = {
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default NameFilter;
