import React, { useMemo, useState } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Constants
import Popup from 'reactjs-popup';

function DeleteAccountPopup() {
  const { logout } = useAuth0();

  const [status, setStatus] = useState('');
  const [disabled, setDisabled] = useState(false);

  const { del } = useAPI();

  const { t } = useTranslation(['general']);

  const deleteAccount = async () => {
    try {
      setStatus('');
      setDisabled(true);

      await del('/users/');

      stats.push(['trackEvent', 'User Account', 'Account deleted']);

      await logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    } catch (e) {
      setDisabled(false);
      setStatus(t('errors:server_error'));
    }
  };

  return (
    <Popup
      trigger={(
        <button
          type="button"
          className="button is-danger"
        >
          {t('general:delete_account')}
        </button>
      )}
      position="top center"
      keepTooltipInside="#root"
      repositionOnResize
      closeOnDocumentClick={!disabled}
      closeOnEscape={!disabled}
      modal
      lockScroll
    >
      {
        (close) => (
          <div className="box p-0 is-max-fullheight has-max-width-400">
            <div
              className="has-background-primary has-text-centered p-4 px-5 pb-6 is-relative br-5"
            >
              <div className="has-text-right">
                <button
                  type="button"
                  className=" cleanButton has-text-white"
                  onClick={close}
                  disabled={disabled}
                >
                  <i className="fas fa-times" />
                </button>
              </div>
              <p
                className="has-text-weight-semibold is-size-3 mt-"
              >
                {t('general:delete_account')}
              </p>

              <div
                className="br-100 has-background-primary is-absolute is-flex has-content-centered"
                style={{
                  left: 'calc(50% - 45px)', width: '90px', height: '85px',
                }}
              >
                <i className="fas fa-user is-size-2" />
              </div>

            </div>

            <div className="p-5 mt-6 has-text-centered">
              <p className="">
                {t('general:really_delete_account_notice')}
              </p>
              <div className="control">

                <button
                  type="button"
                  className={`button is-danger mt-4 ${disabled ? 'is-loading' : ''}`}
                  onClick={deleteAccount}
                  disabled={disabled}
                >
                  {t('general:delete_account')}
                </button>
                <p className=" mt-4">
                  { status }
                </p>
              </div>
            </div>
          </div>
        )
        }
    </Popup>

  );
}

export default DeleteAccountPopup;
