import React, { useMemo } from 'react';

// Libraries
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getRankFromGameMode, getSelectedGame } from 'constants/games';

function HotscrimpInfoDisplay(props) {
  const {
    startDate, region, platform, game, mode, mmrRange,
  } = props;

  const { timezone, language } = useSelector((state) => state.session);

  const { t } = useTranslation(['general']);

  const renderTime = (minutes, time) => {
    const locale = language;
    time = time.setLocale(locale);

    if (minutes <= 5) {
      if (minutes < -1) {
        // if its under 1 hour, show minutes ago
        // if its under 24 hours, show hours ago
        // if its under 7 days, show days ago
        // if its under 4 weeks, show weeks ago
        // if its under 12 months, show months ago
        // if its over 12 months, show years ago

        // first, get the positive amount that is in the past
        minutes *= -1;

        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(weeks / 4);
        const years = Math.floor(months / 12);

        if (years > 0) {
          return t('general:years_ago', { count: years });
        }

        if (months > 0) {
          return t('general:months_ago', { count: months });
        }

        if (weeks > 0) {
          return t('general:weeks_ago', { count: weeks });
        }

        if (days > 0) {
          return t('general:days_ago', { count: days });
        }

        if (hours > 0) {
          return t('general:hours_ago', { count: hours });
        }

        return t('general:minutes_ago', { count: minutes });
      }

      if (minutes < 1) {
        return t('general:now');
      }

      return t('general:in_minutes', { count: minutes });
    }

    // otherwise, just show the time
    return time.toLocaleString(DateTime.TIME_SIMPLE);
  };

  const renderedTimeInfo = useMemo(() => {
    const currentTime = DateTime.now().setZone(timezone);

    const time = DateTime.fromISO(startDate).setZone(timezone);
    const difference = time - currentTime;
    const minutes = Math.round((difference / 1000) / 60);
    const renderedDate = time.hasSame(currentTime, 'day') ? t('general:today') : time.toFormat('D');
    const renderedTime = renderTime(minutes, time);

    return (
      <>
        <div className="columns">
          <div className="column has-width-100 is-narrow px-0">
            <p
              className="mr-3 has-text-grey"
            >
              {t('general:date')}
            </p>
          </div>
          <div className="column">
            <p
              className="has-text-standard"
            >
              {renderedDate}
            </p>
          </div>
        </div>
        <div className="columns my-4">
          <div className="column has-width-100 is-narrow px-0">
            <p
              className="has-text-grey"
            >
              {t('general:time')}
            </p>
          </div>
          <div className="column">
            <p
              className="has-text-standard"
            >
              {`${renderedTime} (${timezone})`}
            </p>
          </div>
        </div>
      </>
    );
  }, [startDate]);

  const gameData = useMemo(() => getSelectedGame(game.tag), [game.tag]);

  const renderedMmrRange = useMemo(() => {
    const startRank = getRankFromGameMode(game.tag, mode, mmrRange.start, 'mmr');
    const endRank = getRankFromGameMode(game.tag, mode, mmrRange.end, 'mmr');

    return `${startRank.name} (${mmrRange.start}) - ${endRank.name} (${mmrRange.end})`;
  }, [game.tag, mode, mmrRange]);

  return (
    <div className="columns is-multiline">

      <div className="column is-narrow has-min-width-300 is-size-6 px-0 mr-5">

        <div className="">

          { renderedTimeInfo }

          <div className="columns is-size-6">
            <div className="column has-width-100 is-narrow px-0">
              <p
                className="has-text-grey"
              >
                {t('general:region')}
              </p>
            </div>
            <div className="column">
              <p
                className="has-text-standard"
              >
                {region.toUpperCase()}

              </p>
            </div>
          </div>
        </div>

      </div>

      <div className="column has-min-width-350 is-size-6 px-0">

        <div className="columns">
          <div className="column has-width-150 is-1 px-0">
            <p
              className="has-text-grey"
            >
              {t('general:platform')}
            </p>
          </div>
          <div className="column">
            <p
              className="has-text-standard"
            >
              {platform.toUpperCase()}
            </p>
          </div>
        </div>

        <div className="columns mt-4">
          <div className="column has-width-150 is-1 px-0">
            <p
              className="has-text-grey"
            >
              {t('general:game')}
            </p>

          </div>
          <div className="column">
            <p
              className="has-text-standard"
            >
              {gameData.name}
            </p>
          </div>
        </div>

        <div className="columns mt-4">
          <div className="column has-width-150 is-1 px-0">
            <p
              className="has-text-grey"
            >
              {t('general:mode')}
            </p>
          </div>
          <div className="column">
            <p
              className=""
            >
              {mode}
            </p>
          </div>
        </div>

        {
          mmrRange.start !== -1 && (
            <div className="columns mt-4">
              <div className="column has-width-150 is-1 px-0">
                <p
                  className="has-text-grey"
                >
                  {t('general:mmr_range')}
                </p>
              </div>
              <div className="column">
                { renderedMmrRange }
              </div>
            </div>
          )
        }

      </div>

    </div>
  );
}

HotscrimpInfoDisplay.propTypes = {
  startDate: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired,
  game: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }).isRequired,
  mode: PropTypes.string.isRequired,
  mmrRange: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
  }).isRequired,
};

export default HotscrimpInfoDisplay;
