import React, { useState, useEffect } from 'react';

// hooks
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// constants
import { HOME } from 'constants/routes';

// components
import CardContainer from 'components/cards/CardContainer';
import MatchFinder from 'components/matches/MatchFinder';

// hocs
import withAuthentication from 'components/hocs/withAuthentication';

function MatchFinderContainer() {
  const [cardIndex, setCardIndex] = useState(-1);

  const navigate = useNavigate();
  const { id } = useParams();

  const { cards } = useSelector((state) => state.cards);

  useEffect(() => {
    if (id === undefined) {
      return;
    }
    const index = parseInt(id, 10);

    if (Number.isNaN(index)) {
      navigate(HOME);
      return;
    }

    const selectedCard = cards[index];

    if (!selectedCard) {
      navigate(HOME);
      return;
    }

    setCardIndex(index);

    // eslint-disable-next-line
	}, [])

  // if user has no card yet, create one
  if (cardIndex === -1) {
    return (
      <div className="px-3 py-3">
        <div className="mb-2">
          <CardContainer />
        </div>
      </div>
    );
  }

  return (
    <MatchFinder index={cardIndex} />
  );
}

export default withAuthentication(MatchFinderContainer);
