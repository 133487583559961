import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';

// Libraries
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';
import { useChatChannel } from 'components/providers/ChatProvider';
import { useTranslation } from 'react-i18next';

// Components
import ChatInputHandler from './ChatInputHandler';

function ChatLayout(props) {
  const { channel, id, allowImageUpload } = props;

  const ref = useRef();
  const [isInit, setIsInit] = useState(true);
  const { t } = useTranslation(['general']);

  const { messages, sendMessage, loadMoreMessages } = useChatChannel(channel, id);

  const { language, timezone } = useSelector((state) => state.session);
  const { playerId } = useSelector((state) => state.player.player);

  const [previousScroll, setPreviousScroll] = useState(0);

  useEffect(() => {
    if (!isInit || messages.length === 0) return;

    // dont scroll into view, but show the latest message directly
    ref.current.scrollIntoView(false);
    setIsInit(false);
  }, [ref, isInit, messages]);

  useEffect(() => {
    const chatBox = document.getElementById(`chat-box-${id}`);

    if (!chatBox) return;

    if (chatBox.scrollTop === 0) {
      chatBox.scrollTop = chatBox.scrollHeight - previousScroll;
      return;
    }

    // only scroll when were not at the top (at the top means were loading old messages)
    ref.current.scrollIntoView({ behavior: 'smooth' });
  }, [id, messages]);

  // load new messages when user scrolls to top
  useEffect(() => {
    const chatBox = document.getElementById(`chat-box-${id}`);

    if (!chatBox) return undefined;

    const handleScroll = async () => {
      if (chatBox.scrollTop === 0) {
        setPreviousScroll(chatBox.scrollHeight);
        await loadMoreMessages();
      }
    };

    chatBox.addEventListener('scroll', handleScroll);

    return () => {
      chatBox.removeEventListener('scroll', handleScroll);
    };
  }, [id, loadMoreMessages]);

  const scrollToBottom = () => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  const renderedMessages = useMemo(() => messages.map((message, index) => {
    /*    const {
      data, timestamp, name: msgType, clientId,
    } = message; */

    const {
      type,
      content,
      playerId: senderId,
      playerName,
      createdAt,
      files,
    } = message;

    if (type !== 'message' && type !== 'system_message') return null;

    const renderedTime = DateTime.fromJSDate(new Date(createdAt), { locale: language, timezone }).toLocaleString(DateTime.DATETIME_SHORT);

    const isPlayer = senderId === playerId;

    const _files = files || [];

    const _renderedFiles = _files.map((file) => {
      const { type: fileType, id: fileId } = file;

      if (fileType !== 'image') return null;

      return (
        <div>
          <img
            alt="chat"
            src={`${process.env.REACT_APP_CDN_URL}/chat/images/${fileId}`}
            className=""
          />
        </div>
      );
    });

    if (type === 'system_message') {
      const renderedMessage = content.includes('_') ? t(`general:${content}`) : content;

      return (
        <div key={index} className={`mx-3 my-3 ${isPlayer ? 'has-text-right' : 'has-text-left'}`}>
          <p className="mb-1 has-text-danger">
            {playerName}
          </p>
          <div className="p-3 is-inline-block has-background-danger br-10 has-fullwidth has-text-left">
            {_renderedFiles}
            <p
              className="has-text-dark has-text-weight-bold"
            >
              {renderedMessage}
            </p>

            <p
              className="has-text-dark is-size-6 mt-0"
            >
              {renderedTime}
            </p>
          </div>

        </div>

      );
    }

    return (
      <div key={index} className={`mx-3 my-3 ${isPlayer ? 'has-text-right' : 'has-text-left'}`}>
        <p className="mb-1">
          {playerName}
        </p>
        <div className={`p-3 is-inline-block has-background-grey-blue br-10 has-max-width-80-percent has-text-left ${isPlayer ? 'has-margin-left-auto' : ''}`}>
          {_renderedFiles}
          <p
            className="has-text-white"
          >
            {content}
          </p>

          <p
            className="has-text-grey-light is-size-6 mt-0"
          >
            {renderedTime}
          </p>
        </div>

      </div>

    );
  }), [messages, t]);

  return (
    <div className="has-fullwidth is-flex flex-direction-column is-relative" style={{ zIndex: 10 }}>

      <div className="has-overflow-y-auto has-fullheight" id={`chat-box-${id}`}>
        { renderedMessages }
        <div ref={ref} />
      </div>

      <ChatInputHandler
        channelId={`${channel}:${id}`}
        onSend={sendMessage}
        allowFileUpload={allowImageUpload}
      />

    </div>
  );
}

ChatLayout.propTypes = {
  channel: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  allowImageUpload: PropTypes.bool,
};

ChatLayout.defaultProps = {
  allowImageUpload: false,
};

export default ChatLayout;
