import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

function AdminAlertButton({ tournamentId, matchId }) {
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { post } = useAPI();
  const { t } = useTranslation(['general']);

  const createAdminAlert = async () => {
    try {
      setDisabled(true);
      setStatus('');

      const data = {
        matchId,
      };

      await post(`/tournaments/admin-alerts/create/${tournamentId}`, data);

      setStatus(t('general:admin_called'));
    } catch (err) {
      console.log(err);

      if (err.response.status === 409) {
        setStatus(t('general:admin_alert_already_exists'));
      } else {
        setStatus(t('general:an_error_occurred'));
      }

      setDisabled(false);
    }
  };

  return (
    <div className="has-text-centered">
      <button
        type="button"
        className="button has-height-25 is-danger has-text-weight-bold"
        onClick={createAdminAlert}
        disabled={disabled}
      >
        {t('general:call_admin')}
      </button>
      {
        status && <p className="has-text-danger mt-2">{status}</p>
      }
    </div>
  );
}

export default AdminAlertButton;
