import React, { useState, useEffect } from 'react';

// libraries
import { Helmet } from 'react-helmet-async';

// components
import CardCreator from 'components/cards/CardCreator';

// constants
import { HOME } from 'constants/routes';

// HOCs
import withAuthentication from 'components/hocs/withAuthentication';

// hooks
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

function CardCreatorContainer() {
  const [editIndex, setEditIndex] = useState(-1);

  const navigate = useNavigate();
  const { id } = useParams();

  const { cards } = useSelector((state) => state.cards);

  useEffect(() => {
    if (id === undefined) {
      return;
    }

    const index = parseInt(id, 10);

    if (Number.isNaN(index)) {
      navigate(HOME);
      return;
    }

    const selectedCard = cards[index];

    if (!selectedCard) {
      navigate(HOME);
      return;
    }

    setEditIndex(index);

    // eslint-disable-next-line
	}, [])

  const goToHome = () => {
    navigate(HOME);
  };

  const type = editIndex === -1 ? 'Erstelle deine Suchkarte' : 'Bearbeite deine Suchkarte';

  return (
    <>
      <Helmet>
        <title>{`Scrimps - ${type}`}</title>
      </Helmet>
      <CardCreator editIndex={editIndex} save={goToHome} />
    </>
  );
}

export default withAuthentication(CardCreatorContainer);
