import React from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';
import stats from 'analytics/matomo';

// Hooks
import { useDispatch } from 'react-redux';

// Actions
import { setCookieSettings } from 'store/session';

// Components
import CookieHandler from 'components/layout/CookieHandler';

function PrivacyPolicy() {
  const dispatch = useDispatch();

  const showCookieBanner = () => {
    stats.push(['trackEvent', 'Privacy Policy', 'Show cookie settings']);
    dispatch(setCookieSettings({ showBanner: true }));
  };

  return (
    <>
      <Helmet>
        <title>Scrimps - Datenschutz</title>
      </Helmet>
      <div className="mx-5 my-3 box">
        <h1>Datenschutzerkl&auml;rung</h1>

        <button
          className="button is-primary has-text-white has-text-weight-semibold my-4"
          onClick={showCookieBanner}
          type="button"
        >
          Cookie Einstellungen öffnen
        </button>

        <CookieHandler />

        <h2>1) Information &uuml;ber die Erhebung personenbezogener Daten und Kontaktdaten des Verantwortlichen</h2>

        <p>
          <b>1.1</b>
     &thinsp;Wir freuen uns, dass Sie unsere Website besuchen und bedanken uns f&uuml;r Ihr Interesse. Im Folgenden informieren wir Sie &uuml;ber den Umgang mit Ihren personenbezogenen Daten bei der Nutzung unserer Website. Personenbezogene Daten sind hierbei alle Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
        </p>

        <p>
          <b>1.2</b>
     &thinsp;Verantwortlicher f&uuml;r die Datenverarbeitung auf dieser Website im Sinne der Datenschutz-Grundverordnung (DSGVO) ist Florian Tinter, MINKZ Gaming, Neunerweg 6, 95138 Bad Steben, Deutschland, Tel.: 015154757442, E-Mail: weplay@minkz.de. Der f&uuml;r die Verarbeitung von personenbezogenen Daten Verantwortliche ist diejenige nat&uuml;rliche oder juristische Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.
        </p>

        <p>
          <b>1.3</b>
     &thinsp;Diese Website nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der &Uuml;bertragung personenbezogener Daten und anderer vertraulicher Inhalte (z.B. Bestellungen oder Anfragen an den Verantwortlichen) eine SSL-bzw. TLS-Verschl&uuml;sselung. Sie k&ouml;nnen eine verschl&uuml;sselte Verbindung an der Zeichenfolge &bdquo;https://&ldquo; und dem Schloss-Symbol in Ihrer Browserzeile erkennen.
        </p>

        <h2>2) Datenerfassung beim Besuch unserer Website</h2>

        <p>Bei der blo&szlig; informatorischen Nutzung unserer Website, also wenn Sie sich nicht registrieren oder uns anderweitig Informationen &uuml;bermitteln, erheben wir nur solche Daten, die Ihr Browser an unseren Server &uuml;bermittelt (sog. &bdquo;Server-Logfiles&ldquo;). Wenn Sie unsere Website aufrufen, erheben wir die folgenden Daten, die f&uuml;r uns technisch erforderlich sind, um Ihnen die Website anzuzeigen:</p>

        <ul>
          <li>Unsere besuchte Website</li>
          <li>Datum und Uhrzeit zum Zeitpunkt des Zugriffes</li>
          <li>Menge der gesendeten Daten in Byte</li>
          <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
          <li>Verwendeter Browser</li>
          <li>Verwendetes Betriebssystem</li>
          <li>Verwendete IP-Adresse (ggf.: in anonymisierter Form)</li>
        </ul>

        <p>Die Verarbeitung erfolgt gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilit&auml;t und Funktionalit&auml;t unserer Website. Eine Weitergabe oder anderweitige Verwendung der Daten findet nicht statt. Wir behalten uns allerdings vor, die Server-Logfiles nachtr&auml;glich zu &uuml;berpr&uuml;fen, sollten konkrete Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen.</p>

        <h2>3) Content Delivery Network</h2>

        <p>
          Cloudflare
          <br />
          Auf unserer Website verwenden wir ein sog. Content Delivery Network (&quot;CDN&quot;) des Technologiedienstleisters Cloudflare Inc., 101 Townsend St. San Francisco, CA 94107, USA (&quot;Cloudflare&quot;). Bei einem Content Delivery Network handelt es sich um einen Online-Dienst, mit dessen Hilfe insbesondere gro&szlig;e Mediendateien (wie z.B. Grafiken, Seiteninhalte oder Skripte) durch ein Netz regional verteilter und &uuml;ber das Internet verbundener Server ausgeliefert werden. Der Einsatz des Content Delivery Network von Cloudflare hilft uns bei der Optimierung der Ladegeschwindigkeiten unserer Website.
          <br />
          Die Verarbeitung erfolgt gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an einer sicheren und effizienten Bereitstellung, sowie Verbesserung der Stabilit&auml;t und Funktionalit&auml;t unserer Website.
          <br />
          Wir haben mit Cloudfare einen Auftragsverarbeitungsvertrag (Data Processing Addendum, einsehbar unter https://www.cloudflare.com/media/pdf/cloudflare-customer-dpa.pdf) abgeschlossen, mit dem Cloudfare verpflichtet wird, die Daten unserer Seitenbesucher zu sch&uuml;tzen und sie nicht an Dritte weiter zu geben. F&uuml;r die &Uuml;bermittlung von Daten aus der EU in die USA beruft sich Cloudfare hierbei auf sog. Standarddatenschutzklauseln der Europ&auml;ischen Kommission, welche die Einhaltung des europ&auml;ischen Datenschutzniveaus in den USA gew&auml;hrleisten sollen.
          <br />
          Weitere Informationen finden Sie in der Datenschutzerkl&auml;rung von Cloudflare unter: https://www.cloudflare.com/privacypolicy/
        </p>

        <h2>4) Cookies</h2>

        <p>Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu erm&ouml;glichen, verwenden wir auf verschiedenen Seiten sogenannte Cookies. Hierbei handelt es sich um kleine Textdateien, die auf Ihrem Endger&auml;t abgelegt werden. Einige der von uns verwendeten Cookies werden nach dem Ende der Browser-Sitzung, also nach Schlie&szlig;en Ihres Browsers, wieder gel&ouml;scht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endger&auml;t und erm&ouml;glichen uns oder unseren Partnerunternehmen (Cookies von Drittanbietern), Ihren Browser beim n&auml;chsten Besuch wiederzuerkennen (persistente Cookies). Werden Cookies gesetzt, erheben und verarbeiten diese im individuellen Umfang bestimmte Nutzerinformationen wie Browser- und Standortdaten sowie IP-Adresswerte. Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gel&ouml;scht, die sich je nach Cookie unterscheiden kann.</p>

        <p>Sofern durch einzelne von uns implementierte Cookies auch personenbezogene Daten verarbeitet werden, erfolgt die Verarbeitung gem&auml;&szlig; Art. 6 Abs. 1 lit. b DSGVO entweder zur Durchf&uuml;hrung des Vertrages oder gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO zur Wahrung unserer berechtigten Interessen an der bestm&ouml;glichen Funktionalit&auml;t der Website sowie einer kundenfreundlichen und effektiven Ausgestaltung des Seitenbesuchs.</p>

        <p>Wir arbeiten unter Umst&auml;nden mit Werbepartnern zusammen, die uns helfen, unser Internetangebot f&uuml;r Sie interessanter zu gestalten. Zu diesem Zweck werden f&uuml;r diesen Fall bei Ihrem Besuch unserer Website auch Cookies von Partnerunternehmen auf Ihrer Festplatte gespeichert (Cookies von Drittanbietern). Wenn wir mit vorbenannten Werbepartnern zusammenarbeiten, werden Sie &uuml;ber den Einsatz derartiger Cookies und den Umfang der jeweils erhobenen Informationen innerhalb der nachstehenden Abs&auml;tze individuell und gesondert informiert.</p>

        <p>Bitte beachten Sie, dass Sie Ihren Browser so einstellen k&ouml;nnen, dass Sie &uuml;ber das Setzen von Cookies informiert werden und einzeln &uuml;ber deren Annahme entscheiden oder die Annahme von Cookies f&uuml;r bestimmte F&auml;lle oder generell ausschlie&szlig;en k&ouml;nnen. Jeder Browser unterscheidet sich in der Art, wie er die Cookie-Einstellungen verwaltet. Diese ist in dem Hilfemen&uuml; jedes Browsers beschrieben, welches Ihnen erl&auml;utert, wie Sie Ihre Cookie-Einstellungen &auml;ndern k&ouml;nnen. Diese finden Sie f&uuml;r die jeweiligen Browser unter den folgenden Links:</p>

        <p>
          Internet Explorer: http://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies
          <br />
          Firefox: https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
          <br />
          Chrome: http://support.google.com/chrome/bin/answer.py?hl=de&amp;hlrm=en&amp;answer=95647
          <br />
          Safari: https://support.apple.com/de-de/guide/safari/sfri11471/mac
          <br />
          Opera: http://help.opera.com/Windows/10.20/de/cookies.html
        </p>

        <p>Bitte beachten Sie, dass bei Nichtannahme von Cookies die Funktionalit&auml;t unserer Website eingeschr&auml;nkt sein kann.</p>

        <h2>5) Kontaktaufnahme</h2>

        <p>Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) werden personenbezogene Daten erhoben. Welche Daten im Falle der Nutzung eines Kontaktformulars erhoben werden, ist aus dem jeweiligen Kontaktformular ersichtlich. Diese Daten werden ausschlie&szlig;lich zum Zweck der Beantwortung Ihres Anliegens bzw. f&uuml;r die Kontaktaufnahme und die damit verbundene technische Administration gespeichert und verwendet. Rechtsgrundlage f&uuml;r die Verarbeitung dieser Daten ist unser berechtigtes Interesse an der Beantwortung Ihres Anliegens gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO. Zielt Ihre Kontaktierung auf den Abschluss eines Vertrages ab, so ist zus&auml;tzliche Rechtsgrundlage f&uuml;r die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO. Ihre Daten werden nach abschlie&szlig;ender Bearbeitung Ihrer Anfrage gel&ouml;scht. Dies ist der Fall, wenn sich aus den Umst&auml;nden entnehmen l&auml;sst, dass der betroffene Sachverhalt abschlie&szlig;end gekl&auml;rt ist und sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen.</p>

        <h2>6) Registrierung beim Portal bzw. Forum</h2>

        <p>Sie k&ouml;nnen sich auf unserer Website unter Angabe von personenbezogenen Daten registrieren. Welche personenbezogenen Daten f&uuml;r die Registrierung verarbeitet werden, ergibt sich aus der Eingabemaske, die f&uuml;r die Registrierung verwendet wird. Wir verwenden f&uuml;r die Registrierung das sog. Double-opt-in-Verfahren, d.&thinsp;h. Ihre Registrierung ist erst abgeschlossen, wenn Sie zuvor Ihre Anmeldung &uuml;ber eine Ihnen zu diesem Zweck zugesandte Best&auml;tigungs-E-Mail durch Klick auf den darin enthaltenem Link best&auml;tigt haben. Falls Ihre diesbez&uuml;gliche Best&auml;tigung nicht binnen 24 Stunden erfolgt, wird Ihre Anmeldung automatisch aus unserer Datenbank gel&ouml;scht. Die Angabe der zuvor genannten Daten ist verpflichtend. Alle weiteren Informationen k&ouml;nnen Sie freiwillig durch Nutzung unseres Portals bereitstellen.</p>

        <p>Wenn Sie unser Portal nutzen, speichern wir Ihre zur Vertragserf&uuml;llung erforderlichen Daten, auch evtl. Angaben zur Zahlungsweise, bis Sie Ihren Zugang endg&uuml;ltig l&ouml;schen. Weiterhin speichern wir die von Ihnen angegebenen freiwilligen Daten f&uuml;r die Zeit Ihrer Nutzung des Portals, soweit Sie diese nicht zuvor l&ouml;schen. Alle Angaben k&ouml;nnen Sie im gesch&uuml;tzten Kundenbereich verwalten und &auml;ndern. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO.</p>

        <p>Dar&uuml;ber hinaus speichern wir alle von Ihnen ver&ouml;ffentlichten Inhalte (wie z.B. &ouml;ffentliche Beitr&auml;ge, Pinnwandeintr&auml;ge, G&auml;stebucheintragungen, etc.), um die Website zu betreiben. Wir haben ein berechtigtes Interesse an der Bereitstellung der Website mit dem vollst&auml;ndigen User-Generated-Content. Die Rechtsgrundlage hierf&uuml;r ist Art. 6 Abs. 1 lit. f DSGVO. Wenn Sie Ihren Account l&ouml;schen, bleiben Ihre &ouml;ffentlichen &Auml;u&szlig;erungen, insbesondere im Forum, weiterhin f&uuml;r alle Leser sichtbar, Ihr Account ist jedoch nicht mehr abrufbar. Alle &uuml;brigen Daten werden in diesem Fall gel&ouml;scht.</p>

        <h2>7) Webanalysedienste</h2>

        <p>
          - Matomo (ehemals Piwik)
          <br />
          Auf dieser Website werden unter Einsatz der Webanalysedienst-Software Matomo (www.matomo.org), einem Dienst des Anbieters InnoCraft Ltd., 150 Willis St, 6011 Wellington, Neuseeland, (&bdquo;Matomo&ldquo;) auf Basis unseres berechtigten Interesses an der statistischen Analyse des Nutzerverhaltens zu Optimierungs- und Marketingzwecken gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO Daten gesammelt und gespeichert. Aus diesen Daten k&ouml;nnen zum selben Zweck pseudonymisierte Nutzungsprofile erstellt und ausgewertet werden. Hierzu k&ouml;nnen Cookies eingesetzt werden. Bei Cookies handelt es sich um kleine Textdateien, die lokal im Zwischenspeicher des Internet-Browsers des Seitenbesuchers gespeichert werden. Die Cookies erm&ouml;glichen unter anderem die Wiedererkennung des Internet-Browsers. Die mit der Matomo-Technologie erhobenen Daten (einschlie&szlig;lich Ihrer pseudonymisierten IP-Adresse) werden auf unseren Servern verarbeitet.
          <br />
          Die durch das Cookie erzeugten Informationen im pseudonymen Nutzerprofil werden nicht dazu benutzt, den Besucher dieser Website pers&ouml;nlich zu identifizieren und nicht mit personenbezogenen Daten &uuml;ber den Tr&auml;ger des Pseudonyms zusammengef&uuml;hrt.
          <br />
          Wenn Sie mit der Speicherung und Auswertung dieser Daten aus Ihrem Besuch nicht einverstanden sind, dann k&ouml;nnen Sie der Speicherung und Nutzung f&uuml;r die Zukunft per Mausklick jederzeit widersprechen. In diesem Fall wird in Ihrem Browser ein sog. Opt-Out-Cookie abgelegt, mit der Folge, dass Matomo keinerlei Sitzungsdaten erhebt. Bitte beachten Sie, dass die vollst&auml;ndige L&ouml;schung Ihrer Cookies zur Folge, dass auch das Opt-Out-Cookie gel&ouml;scht wird und ggf. von Ihnen erneut aktiviert werden muss.
          <br />
          Soweit rechtlich erforderlich, haben wir zur vorstehend dargestellten Verarbeitung Ihrer Daten Ihre Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO eingeholt. Sie k&ouml;nnen Ihre erteilte Einwilligung jederzeit mit Wirkung f&uuml;r die Zukunft widerrufen. Um Ihren Widerruf auszu&uuml;ben, befolgen Sie bitte die vorstehend geschilderte M&ouml;glichkeit zur Vornahme eines Widerspruchs.
        </p>

        <h2>8) Tools und Sonstiges</h2>

        <p>
          <b>8.1</b>
             &thinsp;- FontAwesome
          <br />
          Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten sogenannte Web Fonts von &bdquo;FontAwesome&ldquo;, einem Dienst der Fonticons, Inc., 710 Blackhorn Dr, Carl Junction, 64834, MO, USA (&bdquo;FontAwesome&ldquo;). Beim Aufruf einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten Web Fonts in ihren Browser-Cache, um Texte und Schriftarten korrekt anzuzeigen.
          <br />
          Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von FontAwesome aufnehmen. Hierbei kann es auch zu einer &Uuml;bermittlung von personenbezogenen Daten an die Server von FontAwesome in den USA kommen. Auf diese Weise erlangt FontAwesome Kenntnis dar&uuml;ber, dass &uuml;ber Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von FontAwesome Fonts erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Wenn Ihr Browser Web Fonts nicht unterst&uuml;tzt, wird eine Standardschrift von Ihrem Computer genutzt.
          <br />
          Weitere Informationen zu FontAwesome finden Sie unter: https://fontawesome.com/privacy
          <br />
          - Google Web Fonts
          <br />
          Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts die von der Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland (&bdquo;Google&ldquo;) bereitgestellt werden. Beim Aufruf einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten Web Fonts in ihren Browser-Cache, um Texte und Schriftarten korrekt anzuzeigen.
          <br />
          Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen. Hierbei kann es auch zu einer &Uuml;bermittlung von personenbezogenen Daten an die Server der Google LLC. in den USA kommen. Auf diese Weise erlangt Google Kenntnis dar&uuml;ber, dass &uuml;ber Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von Google Web Fonts erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Wenn Ihr Browser Web Fonts nicht unterst&uuml;tzt, wird eine Standardschrift von Ihrem Computer genutzt. Weitere Informationen zu Google Web Fonts finden Sie unter https://developers.google.com/fonts/faq und in der Datenschutzerkl&auml;rung von Google: https://www.google.com/policies/privacy/
        </p>

        <p>
          <b>8.2</b>
     &thinsp;Google reCAPTCHA
        </p>

        <p>Auf dieser Website verwenden wir auch die reCAPTCHA Funktion von Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland (&bdquo;Google&ldquo;). Diese Funktion dient vor allem zur Unterscheidung, ob eine Eingabe durch eine nat&uuml;rliche Person erfolgt oder missbr&auml;uchlich durch maschinelle und automatisierte Verarbeitung erfolgt. Der Dienst umfasst den Versand der IP-Adresse und ggf. weiterer von Google f&uuml;r den Dienst reCAPTCHA ben&ouml;tigter Daten an Google und erfolgt gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Feststellung der individuellen Eigenverantwortung im Internet und der Vermeidung von Missbrauch und Spam. Im Rahmen der Nutzung von Google reCAPTCHA kann es auch zu einer &Uuml;bermittlung von personenbezogenen Daten an die Server der Google LLC. in den USA kommen.</p>

        <p>Weiterf&uuml;hrende Informationen zu Google reCAPTCHA sowie die Datenschutzerkl&auml;rung von Google k&ouml;nnen Sie einsehen unter: https://www.google.com/intl/de/policies/privacy/</p>

        <p>Soweit rechtlich erforderlich, haben wir zur vorstehend dargestellten Verarbeitung Ihrer Daten Ihre Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO eingeholt. Sie k&ouml;nnen Ihre erteilte Einwilligung jederzeit mit Wirkung f&uuml;r die Zukunft widerrufen. Um Ihren Widerruf auszu&uuml;ben, befolgen Sie bitte die vorstehend geschilderte M&ouml;glichkeit zur Vornahme eines Widerspruchs.</p>

        <h2>9) Rechte des Betroffenen</h2>

        <p>
          <b>9.1</b>
     &thinsp;Das geltende Datenschutzrecht gew&auml;hrt Ihnen gegen&uuml;ber dem Verantwortlichen hinsichtlich der Verarbeitung Ihrer personenbezogenen Daten umfassende Betroffenenrechte (Auskunfts- und Interventionsrechte), &uuml;ber die wir Sie nachstehend informieren:
        </p>

        <ul>
          <li>Auskunftsrecht gem&auml;&szlig; Art. 15 DSGVO: Sie haben insbesondere ein Recht auf Auskunft &uuml;ber Ihre von uns verarbeiteten personenbezogenen Daten, die Verarbeitungszwecke, die Kategorien der verarbeiteten personenbezogenen Daten, die Empf&auml;nger oder Kategorien von Empf&auml;ngern, gegen&uuml;ber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer bzw. die Kriterien f&uuml;r die Festlegung der Speicherdauer, das Bestehen eines Rechts auf Berichtigung, L&ouml;schung, Einschr&auml;nkung der Verarbeitung, Widerspruch gegen die Verarbeitung, Beschwerde bei einer Aufsichtsbeh&ouml;rde, die Herkunft Ihrer Daten, wenn diese nicht durch uns bei Ihnen erhoben wurden, das Bestehen einer automatisierten Entscheidungsfindung einschlie&szlig;lich Profiling und ggf. aussagekr&auml;ftige Informationen &uuml;ber die involvierte Logik und die Sie betreffende Tragweite und die angestrebten Auswirkungen einer solchen Verarbeitung, sowie Ihr Recht auf Unterrichtung, welche Garantien gem&auml;&szlig; Art. 46 DSGVO bei Weiterleitung Ihrer Daten in Drittl&auml;nder bestehen;</li>
          <li>Recht auf Berichtigung gem&auml;&szlig; Art. 16 DSGVO: Sie haben ein Recht auf unverz&uuml;gliche Berichtigung Sie betreffender unrichtiger Daten und/oder Vervollst&auml;ndigung Ihrer bei uns gespeicherten unvollst&auml;ndigen Daten;</li>
          <li>Recht auf L&ouml;schung gem&auml;&szlig; Art. 17 DSGVO: Sie haben das Recht, die L&ouml;schung Ihrer personenbezogenen Daten bei Vorliegen der Voraussetzungen des Art. 17 Abs. 1 DSGVO zu verlangen. Dieses Recht besteht jedoch insbesondere dann nicht, wenn die Verarbeitung zur Aus&uuml;bung des Rechts auf freie Meinungs&auml;u&szlig;erung und Information, zur Erf&uuml;llung einer rechtlichen Verpflichtung, aus Gr&uuml;nden des &ouml;ffentlichen Interesses oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen erforderlich ist;</li>
          <li>Recht auf Einschr&auml;nkung der Verarbeitung gem&auml;&szlig; Art. 18 DSGVO: Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, solange die von Ihnen bestrittene Richtigkeit Ihrer Daten &uuml;berpr&uuml;ft wird, wenn Sie eine L&ouml;schung Ihrer Daten wegen unzul&auml;ssiger Datenverarbeitung ablehnen und stattdessen die Einschr&auml;nkung der Verarbeitung Ihrer Daten verlangen, wenn Sie Ihre Daten zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen ben&ouml;tigen, nachdem wir diese Daten nach Zweckerreichung nicht mehr ben&ouml;tigen oder wenn Sie Widerspruch aus Gr&uuml;nden Ihrer besonderen Situation eingelegt haben, solange noch nicht feststeht, ob unsere berechtigten Gr&uuml;nde &uuml;berwiegen;</li>
          <li>Recht auf Unterrichtung gem&auml;&szlig; Art. 19 DSGVO: Haben Sie das Recht auf Berichtigung, L&ouml;schung oder Einschr&auml;nkung der Verarbeitung gegen&uuml;ber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empf&auml;ngern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder L&ouml;schung der Daten oder Einschr&auml;nkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unm&ouml;glich oder ist mit einem unverh&auml;ltnism&auml;&szlig;igen Aufwand verbunden. Ihnen steht das Recht zu, &uuml;ber diese Empf&auml;nger unterrichtet zu werden.</li>
          <li>Recht auf Daten&uuml;bertragbarkeit gem&auml;&szlig; Art. 20 DSGVO: Sie haben das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, g&auml;ngigen und maschinenlesebaren Format zu erhalten oder die &Uuml;bermittlung an einen anderen Verantwortlichen zu verlangen, soweit dies technisch machbar ist;</li>
          <li>Recht auf Widerruf erteilter Einwilligungen gem&auml;&szlig; Art. 7 Abs. 3 DSGVO: Sie haben das Recht, eine einmal erteilte Einwilligung in die Verarbeitung von Daten jederzeit mit Wirkung f&uuml;r die Zukunft zu widerrufen. Im Falle des Widerrufs werden wir die betroffenen Daten unverz&uuml;glich l&ouml;schen, sofern eine weitere Verarbeitung nicht auf eine Rechtsgrundlage zur einwilligungslosen Verarbeitung gest&uuml;tzt werden kann. Durch den Widerruf der Einwilligung wird die Rechtm&auml;&szlig;igkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht ber&uuml;hrt;</li>
          <li>Recht auf Beschwerde gem&auml;&szlig; Art. 77 DSGVO: Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verst&ouml;&szlig;t, haben Sie - unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs - das Recht auf Beschwerde bei einer Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsortes, Ihres Arbeitsplatzes oder des Ortes des mutma&szlig;lichen Versto&szlig;es.</li>
        </ul>

        <p>
          <b>9.2</b>
     &thinsp;WIDERSPRUCHSRECHT
        </p>

        <p>
          WENN WIR IM RAHMEN EINER INTERESSENABW&Auml;GUNG IHRE PERSONENBEZOGENEN DATEN AUFGRUND UNSERES &Uuml;BERWIEGENDEN BERECHTIGTEN INTERESSES VERARBEITEN, HABEN SIE DAS JEDERZEITIGE RECHT, AUS GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIESE VERARBEITUNG WIDERSPRUCH MIT WIRKUNG F&Uuml;R DIE ZUKUNFT EINZULEGEN.
          <br />
          MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN. EINE WEITERVERARBEITUNG BLEIBT ABER VORBEHALTEN, WENN WIR ZWINGENDE SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG NACHWEISEN K&Ouml;NNEN, DIE IHRE INTERESSEN, GRUNDRECHTE UND GRUNDFREIHEITEN &Uuml;BERWIEGEN, ODER WENN DIE VERARBEITUNG DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN DIENT.
        </p>

        <p>WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN. SIE K&Ouml;NNEN DEN WIDERSPRUCH WIE OBEN BESCHRIEBEN AUS&Uuml;BEN.</p>

        <p>MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN ZU DIREKTWERBEZWECKEN.</p>

        <h2>10) Dauer der Speicherung personenbezogener Daten</h2>

        <p>Die Dauer der Speicherung von personenbezogenen Daten bemisst sich anhand der jeweiligen Rechtsgrundlage, am Verarbeitungszweck und &ndash; sofern einschl&auml;gig &ndash; zus&auml;tzlich anhand der jeweiligen gesetzlichen Aufbewahrungsfrist (z.B. handels- und steuerrechtliche Aufbewahrungsfristen).</p>

        <p>Bei der Verarbeitung von personenbezogenen Daten auf Grundlage einer ausdr&uuml;cklichen Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO werden diese Daten so lange gespeichert, bis der Betroffene seine Einwilligung widerruft.</p>

        <p>Existieren gesetzliche Aufbewahrungsfristen f&uuml;r Daten, die im Rahmen rechtsgesch&auml;ftlicher bzw. rechtsgesch&auml;fts&auml;hnlicher Verpflichtungen auf der Grundlage von Art. 6 Abs. 1 lit. b DSGVO verarbeitet werden, werden diese Daten nach Ablauf der Aufbewahrungsfristen routinem&auml;&szlig;ig gel&ouml;scht, sofern sie nicht mehr zur Vertragserf&uuml;llung oder Vertragsanbahnung erforderlich sind und/oder unsererseits kein berechtigtes Interesse an der Weiterspeicherung fortbesteht.</p>

        <p>Bei der Verarbeitung von personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert, bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 1 DSGVO aus&uuml;bt, es sei denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die die Interessen, Rechte und Freiheiten der betroffenen Person &uuml;berwiegen, oder die Verarbeitung dient der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.</p>

        <p>Bei der Verarbeitung von personenbezogenen Daten zum Zwecke der Direktwerbung auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert, bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 2 DSGVO aus&uuml;bt.</p>

        <p>Sofern sich aus den sonstigen Informationen dieser Erkl&auml;rung &uuml;ber spezifische Verarbeitungssituationen nichts anderes ergibt, werden gespeicherte personenbezogene Daten im &Uuml;brigen dann gel&ouml;scht, wenn sie f&uuml;r die Zwecke, f&uuml;r die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig sind.</p>
      </div>
    </>
  );
}

export default PrivacyPolicy;
