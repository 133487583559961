import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import Select from 'react-select';

// Hooks
import { useTranslation } from 'react-i18next';

// Styles
import styles from './styles.module.scss';

/**
 * Dropdown Component to select an item from a list
 */
function Dropdown({
  items,
  onSelect,
  value = null,
  className = '',
  disabled = false,
  isMulti = false,
  isSearchable = false,
  isOptionDisabled = () => { },
}) {
  const { t } = useTranslation('general');

  const _items = useMemo(() => {
    if (!items || items.length === 0) return [];

    if (typeof items[0] === 'string') {
      return items.map((item) => ({ label: item, value: item }));
    }

    return items;
  }, [items]);

  const _value = useMemo(() => {
    if (!value) return null;

    if (typeof value === 'string') {
      return { label: value, value };
    }

    return value;
  }, [value]);

  const onChange = (data) => {
    onSelect(data);
  };

  const customStyles = {
    control: (base, { isDisabled }) => ({
      ...base,
      boxShadow: 'none',
      color: 'white',
      background: isDisabled ? '#5f5f70' : '#35353F',
      borderColor: '#B5B5B5',
      border: 'none',
      '&:hover': {

        color: 'white',
        background: '#27272F',
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      background: 'inherit',
      color: 'inherit',
    }),
    group: (base) => ({
      ...base,
      background: 'inherit',
      color: 'inherit',
    }),
    singleValue: (base) => ({
      ...base,
      background: 'inherit',
      color: 'inherit',
    }),
    menu: (base) => ({
      ...base,
      borderRadius: '5px',
      background: '#35353F',
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      borderRadius: '5px',
      margin: '5px auto',
      width: '97%',
      backgroundColor: state.isSelected ? '#54b33d' : '#35353F',
      '&:hover': { backgroundColor: state.isSelected ? '#54b33d' : '#54b33d' },
    }),
  };

  return (
    <Select
      value={_value}
      options={_items}
      onChange={onChange}
      isDisabled={disabled}
      id={styles.dropdown}
      styles={customStyles}
      className={className}
      isOptionDisabled={isOptionDisabled}
      isMulti={isMulti}
      placeholder={t('general:select')}
      noOptionsMessage={() => t('general:thats_all')}
      isSearchable={isSearchable}
    />
  );
}

Dropdown.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.string,
  ]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isOptionDisabled: PropTypes.func,
  isSearchable: PropTypes.bool,
};

export default Dropdown;
