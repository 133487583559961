import React, {
  useState, useEffect, useRef,
} from 'react';

// libraries
import axios from 'axios';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import stats from 'analytics/matomo';
import getCardTypeLabel from 'utils/getCardTypeLabel';

// hooks
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

// components
import RoundButton from 'components/utils/RoundButton';
import MatchCard from 'components/matches/MatchCard';
import FlipCard from 'components/matches/FlipCard';
import { Helmet } from 'react-helmet-async';

// assets
import swords from 'assets/images/icons/swords.png';

function MatchFinder(props) {
  const [matches, setMatches] = useState([]);
  const [activeMatchIndex, setActiveMatchIndex] = useState(0);

  const [hasData, setHasData] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState('');

  const [foundMatch, setFoundMatch] = useState();

  const [signal] = useState(axios.CancelToken.source());

  const { getAccessTokenSilently } = useAuth0();

  const { cards } = useSelector((state) => state.cards);

  // animationType describes what animation is to show if user likes / dislikes
  const [animationType, setAnimationType] = useState('bounceOutUp');
  // backOutDown
  const animationRef = useRef();

  const wrapperRef = useRef();

  const { index } = props;
  const card = cards[index];
  const searchesFor = card.searches[0] === 'PLAYER' ? 'Spieler' : 'Teams';

  const [animationIndex, setAnimationIndex] = useState(0);

  useEffect(() => () => {
    signal.cancel();
  }, []);

  useEffect(() => {
    if (activeMatchIndex < matches.length) return;

    setAnimationIndex(animationIndex + 1);
  }, [activeMatchIndex]);

  useEffect(() => {
    const findMatches = async () => {
      try {
        const token = await getAccessTokenSilently();

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: signal.token,
        };

        const data = {
          id: card._id,
          times: card.times,
          games: card.games,
        };

        const response = await axios.post(`${process.env.REACT_APP_API}/matches/search`, data, config);

        const matchData = response.data;

        setMatches(matchData);
        setHasData(true);
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        }

        console.log(err);

        console.log(err.response);

        if (err.response && err.response.data) {
          setError(err.response.data);
          return;
        }

        if (!err.response || err.response.status === 500) {
          setError('Server Fehler. Bitte versuche es später noch einmal');
          return;
        }

        setError('Es ist ein Fehler aufgetreten.');
      }
    };

    if (matches.length === 0) {
      findMatches();
    }

    // eslint-disable-next-line
	}, []);

  const showNextCard = () => {
    setActiveMatchIndex(activeMatchIndex + 1);
  };

  const setMatch = async (data, type) => {
    // set animation for the card that disappears depending if its a like or dislike
    if (type === 0) {
      setAnimationType('backOutDown');
    } else {
      setAnimationType('bounceOutUp');
    }

    try {
      setDisabled(true);

      const token = await getAccessTokenSilently();

      const matchData = {
        userID: card._id,
        secondID: data._id,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: signal.token,
      };

      let route = 'accept';

      if (type === 0) {
        route = 'dismiss';
      }

      const result = await axios.post(`${process.env.REACT_APP_API}/matches/${route}`, matchData, config);

      if (result.data.isMatch && type !== 0) {
        // its a match!
        stats.push(['trackEvent', 'Scrims', 'Match found']);
        setFoundMatch({ ...data, contact: result.data.card });
      } else if (type !== 0) {
        stats.push(['trackEvent', 'Scrims', 'Card Like']);
      } else {
        stats.push(['trackEvent', 'Scrims', 'Card Dislike']);
      }

      setDisabled(false);
      showNextCard();
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }

      setDisabled(false);

      console.log(err);

      console.log(err.response);

      if (err.response) {
        setError(err.response.data);
        return;
      }

      if (!err.response || err.response.status === 500) {
        setError('Etwas hat nicht funktioniert. Bitte versuche es später noch einmal');
        return;
      }

      setError(err.response.data);
    }
  };

  const showContent = () => {
    const match = matches[activeMatchIndex] || { id: 0 };

    if (error) {
      return (
        <p
          className="has-text-centered has-text-danger has-text-weight-semibold is-size-4-desktop mt-3 has-fullwidth"
        >
          {error}
        </p>
      );
    }

    if (!hasData) {
      return (
        <h2
          className="has-text-weight-semibold is-size-3-desktop is-size-4-tablet is-size-5-mobilehas-fullwidth px-3"
        >
          Matches werden gesucht...
        </h2>
      );
    }

    if (foundMatch) {
      return (
        <div className="box boxshadow has-text-centered br-20">
          <h2 className="has-text-weight-semibold is-size-3-desktop is-size-4-tablet is-size-5-mobile px-5 mt-4">
            Slip gefunden!
          </h2>
          <p className="mb-3 px-5">
            Du kannst deinen Gegner nun kontaktieren.
          </p>
          <FlipCard data={foundMatch} close={() => setFoundMatch()}>
            <button
              type="button"
              className="button is-light is-rounded has-fullwidth"
              onClick={() => setFoundMatch()}
            >
              Weiter slippen
            </button>
          </FlipCard>
        </div>
      );
    }

    if (matches.length === 0) {
      return (
        <h2
          className="has-text-weight-semibold is-size-3-desktop is-size-4-tablet is-size-5-mobilehas-fullwidth px-3"
        >
          Es wurden keine passenden Slippartner gefunden. Versuchs später noch mal!
        </h2>
      );
    }

    if (activeMatchIndex === matches.length) {
      return (
        <h2
          className="has-text-weight-semibold is-size-3-desktop is-size-4-tablet is-size-5-mobilepx-3 has-fullwidth"
        >
          Das waren alle! Komm später wieder!
        </h2>
      );
    }

    return (
      <div className="has-overflow-hidden">
        <h2 className="mb-4 is-size-3-desktop is-size-4-tablet is-size-5-mobile has-text-weight-semibold px-3">
          {
            `Wir haben folgende ${searchesFor} für dich:`
          }
        </h2>
        <SwitchTransition>
          <CSSTransition
            classNames={animationType}
            timeout={{
              appear: 500,
              enter: 500,
              exit: 400,
            }}
            key={match._id}
            nodeRef={animationRef}
          >
            <div className="columns is-centered is-mobile" ref={animationRef}>
              <div className="column is-narrow">
                <MatchCard
                  data={match}
                  showType={false}
                  showSearches={false}
                />
              </div>
            </div>
          </CSSTransition>
        </SwitchTransition>

        <div className="columns is-centered is-mobile">
          <div className="column is-narrow">
            <RoundButton
              onClick={() => setMatch(match, 1)}
              className="grow"
              disabled={disabled}
            >
              <img src={swords} alt="" />
            </RoundButton>
          </div>
          <div className="column is-narrow">
            <RoundButton
              onClick={() => setMatch(match, 0)}
              className="grow"
              disabled={disabled}
            >
              <i className="fas fa-thumbs-down is-size-4 has-text-info" />
            </RoundButton>
          </div>
        </div>
      </div>
    );
  };

  // hacky way to show an animation every time the content changes, except when the user can like / dislike a card
  let key = activeMatchIndex === matches.length ? animationIndex : -1;

  if (foundMatch) {
    key = -2;
  }

  return (
    <>
      <Helmet>
        <title>Scrimps - Finde Scrims</title>
      </Helmet>
      <div className={`columns is-centered is-vcentered is-min-fullheight is-mobile has-text-centered ${foundMatch ? 'has-background-grey-light' : ''}`}>
        <div className="column px-0 is-narrow has-max-width-100-percent">
          <SwitchTransition>
            <CSSTransition
              classNames="fade"
              timeout={300}
              key={key}
              nodeRef={wrapperRef}
            >
              <div ref={wrapperRef}>
                {showContent()}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
    </>
  );
}

export default MatchFinder;
