import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Constants
import { HOT_SCRIMPS } from 'constants/routes';

// Components
import { Link } from 'react-router-dom';
import DataDeletedNotification from '../DataDeletedNotification';

function HotscrimpRequestAcceptedNotification(props) {
  const { notification } = props;
  const {
    dataDeleted, hotscrimpId, teamName, startDate,
  } = notification;

  const { t } = useTranslation(['general']);

  const { language, timezone } = useSelector((state) => state.session);

  const date = useMemo(() => {
    if (!startDate) return null;

    const _date = DateTime.now().setZone(timezone).setLocale(language);

    return _date.toLocaleString(DateTime.DATE_SHORT);
  }, [startDate]);

  if (dataDeleted) {
    return (
      <DataDeletedNotification
        icon="fas fa-person-circle-check"
        title={t('hotscrimps:hotscrimp_request_accepted')}
        message=""
      />
    );
  }

  return (
    <Link
      to={`${HOT_SCRIMPS}/${hotscrimpId}`}
    >
      <div>
        <div>

          <div className="columns">
            <div className="column px-0 is-3 is-flex has-content-centered">
              <i className="fas fa-person-circle-check is-size-3 has-text-grey" />
            </div>

            <div className="column px-0 is-9 has-text-left">
              <div
                className="has-fullwidth"
              >
                <p
                  className="is-size-6 has-line-height-full has-text-white"
                >
                  { t('hotscrimps:hotscrimp_request_accepted') }
                </p>
                <p className="mt-2 has-text-grey-light has-line-height-full">
                  { t('hotscrimps:hotscrimp_request_accepted_message', { team: teamName, date }) }
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>

    </Link>
  );
}

HotscrimpRequestAcceptedNotification.propTypes = {
  notification: PropTypes.shape({
    hotscrimpId: PropTypes.string,
    dataDeleted: PropTypes.bool,
    teamName: PropTypes.string,
    startDate: PropTypes.string,
  }).isRequired,
};

export default HotscrimpRequestAcceptedNotification;
