import React, { useState, useEffect } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

function DataDeletedNotification(props) {
  const {
    title, message, icon, canBeOpened,
  } = props;

  const [isOpen, setOpen] = useState(false);

  const { t } = useTranslation(['general']);

  return (

    <div>
      <div>

        <button
          type="button"
          onClick={() => setOpen(!isOpen)}
          className="cleanButton has-fullwidth has-text-standard p-0 break-word"
        >

          <div className="columns">
            <div className="column px-0 is-3 is-flex has-content-centered">
              <i className={`is-size-3 has-text-grey ${icon}`} />
            </div>

            <div className="column px-0 is-9 has-text-left">
              <div
                className="has-fullwidth"
              >
                <p
                  className="is-size-6 has-text-weight-light has-line-height-full  has-text-white"
                >
                  { title }
                </p>
                <p className="mt-2 has-text-grey-light has-line-height-full">
                  { message }
                </p>
              </div>

            </div>
          </div>

        </button>

        {
            isOpen && canBeOpened && (
            <div className="mx-1 mt-2 has-text-white has-text-left">
              <div>
                <p
                  className="has-text-weight-"
                >
                  {title}
                </p>
              </div>
              <div>
                <p className="is-size-7 has-text-grey-light mt-2">
                  {message}
                </p>
              </div>

            </div>
            )
        }
      </div>
    </div>

  );
}

DataDeletedNotification.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  canBeOpened: PropTypes.bool,
};

DataDeletedNotification.defaultProps = {
  canBeOpened: false,
};

export default DataDeletedNotification;
