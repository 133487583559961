import React, { useMemo, useState } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Components
import RegionSelector from 'components/utils/RegionSelector';

function TournamentRegionSelector() {
  const {
    currentPhase, info, setInfo, publicId,
  } = useTournament();
  const { region } = info;

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { t } = useTranslation(['general']);
  const { post } = useAPI();

  const saveNewRegion = async (newRegion) => {
    try {
      setDisabled(true);
      setStatus('');
      const data = { region: newRegion };

      await post(`/tournaments/region/${publicId}`, data);

      setInfo({ region: newRegion });

      stats.push(['trackEvent', 'Tournament Editor', 'New region set']);

      setDisabled(false);
    } catch (e) {
      console.log(e);
      setDisabled(false);
      setStatus(t('errors:could_not_save_error'));
    }
  };

  const renderedRegionSelector = useMemo(() => (
    <div className="has-fullwidth">
      <div className="has-fullwidth">
        <RegionSelector
          region={region}
          setRegion={saveNewRegion}
        />
      </div>
    </div>

  ), [currentPhase, region, disabled]);

  return (
    <div className="field">
      { renderedRegionSelector }
      {
        status && (
        <p className="has-text-weight-semibold mt-4">
          { status }
        </p>
        )
      }
    </div>
  );
}

export default TournamentRegionSelector;
