import React, { useState } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Constants
import { MAX_TOURNAMENT_TITLE_LENGTH } from 'constants/tournaments';
import AsyncInputEditor from 'components/utils/inputs/AsyncInputEditor';

function TitleEditor() {
  const { info, setInfo, publicId } = useTournament();
  const { title } = info;

  const { t } = useTranslation(['general', 'validation']);

  const { post } = useAPI();

  const saveTitle = async (newTitle) => {
    const data = { title: newTitle };

    await post(`/tournaments/title/${publicId}`, data);

    setInfo({ title: newTitle });

    stats.push(['trackEvent', 'Tournament Editor', 'New title set']);
  };

  return (
    <AsyncInputEditor
      label="title"
      text={title}
      onSave={saveTitle}
      maxLength={MAX_TOURNAMENT_TITLE_LENGTH}
    />
  );
}

export default TitleEditor;
