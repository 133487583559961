import React, { useState, useEffect, useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useHotscrimp } from 'components/HotScrimps/HotscrimpsDisplayPage';

// Components
import Dropdown from 'components/utils/Dropdown';

/**
 * This component allows the user to select a team
 * to create a hotscrimp request with
 * @param {Object} props - The props
 * @param {Function} props.setTeam - The function to set the team after selection
 * @returns {React.Component} - The component
 */
function TeamSelector(props) {
  const { setTeam } = props;
  const { publicId } = useHotscrimp();

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const [teamId, setTeamId] = useState('');
  const [availableTeams, setAvailableTeams] = useState([]);

  const { t } = useTranslation(['hotscrimps', 'error']);

  const { post } = useAPI();

  useEffect(() => {
    if (!teamId) return;

    const loadTeamData = async () => {
      try {
        setStatus('');
        setDisabled(true);

        const data = {
          teamId,
        };

        const _team = await post('/hotscrimps/requests/teams/info', data);

        setTeam(_team);
        setDisabled(false);
      } catch (err) {
        setDisabled(false);
        setStatus(t('hotscrimps:team_not_found_hotscrimp_error'));
      }
    };

    loadTeamData();
  }, [teamId]);

  useEffect(() => {
    // load all teams the user is allowed to create hotscrimps for
    const loadAvailableTeams = async () => {
      try {
        setStatus('');
        setDisabled(true);

        const data = {
          publicId,
        };

        const _availableTeams = await post('/hotscrimps/requests/teams/list', data);

        const _availableTeamsWithLabel = _availableTeams.map((tt) => ({
          value: tt.publicId,
          label: tt.name,
        }));

        setAvailableTeams(_availableTeamsWithLabel);

        if (_availableTeams.length === 0) {
          setStatus(t('hotscrimps:no_team_found_error_hotscrimp_error'));
          return;
        }

        setDisabled(false);
      } catch (err) {
        setStatus(t('hotscrimps:no_team_found_error_hotscrimp_error'));
        console.log(err);
      }
    };

    loadAvailableTeams();
  }, []);

  const selectedTeam = useMemo(() => {
    const _team = availableTeams.find((tt) => tt.value === teamId);

    return _team || [];
  }, [availableTeams, teamId]);

  const _setTeamId = (_team) => {
    setTeamId(_team.value);
  };

  return (
    <div className="">
      <p className="mb-1 has-text-grey is-size-5">
        {t('general:team')}
      </p>
      <div className="has-width-200">
        <Dropdown
          items={availableTeams}
          value={selectedTeam}
          onSelect={_setTeamId}
          disabled={disabled}
        />
        <p className="mt-2 is-size-7">
          {status}
        </p>
      </div>
    </div>
  );
}

TeamSelector.propTypes = {
  setTeam: PropTypes.func.isRequired,
};

export default TeamSelector;
