import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Constants
import { TOURNAMENT_PAGE } from 'constants/routes';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import { Link } from 'react-router-dom';
import DataDeletedNotification from '../../DataDeletedNotification';

function TournamentAdminCalled(props) {
  const { notification } = props;
  const {
    tournamentId, tournamentName, dataDeleted,
  } = notification;

  const { t } = useTranslation(['general']);

  if (dataDeleted) {
    return (
      <DataDeletedNotification
        icon="fas fa-circle-xmark"
        title={t('general:tournament_admin_called_but_tournament_deleted')}
        message={t('general:tournament_admin_called_but_tournament_deleted_message')}
        canBeOpened={false}
      />
    );
  }

  return (
    <Link
      to={`${TOURNAMENT_PAGE}/${tournamentId}`}
    >
      <div>
        <div>

          <div className="columns">
            <div className="column px-0 is-3 is-flex has-content-centered">
              <i className="fas fa-circle-xmark is-size-3 has-text-grey" />
            </div>

            <div className="column px-0 is-9 has-text-left">
              <div
                className="has-fullwidth"
              >
                <p
                  className="is-size-6 has-line-height-full has-text-white"
                >
                  {t('general:tournament_admin_called')}
                </p>
                {
                    tournamentName && (
                    <p className="mt-2 has-text-grey-light has-line-height-full">
                        {
                            t('general:tournament_admin_called_message', { tournament: tournamentName })
                        }
                    </p>
                    )
                }
              </div>

            </div>
          </div>
        </div>
      </div>

    </Link>
  );
}

TournamentAdminCalled.propTypes = {
  notification: PropTypes.shape({
    tournamentId: PropTypes.string,
    tournamentName: PropTypes.string,
    dataDeleted: PropTypes.bool,
  }).isRequired,
};

export default TournamentAdminCalled;
