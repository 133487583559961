import React, { useState, useLayoutEffect, useMemo } from 'react';

// Libraries
import i18n, { getCurrentLocale } from 'locales/i18n';
import { setPlayer } from 'store/player';
import stats from 'analytics/matomo';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Actions
import { setSession } from 'store/session';
import { setCards } from 'store/cards';

// Constants
import { ONBOARDING, PRIVACY, TOS } from 'constants/routes';

// Components
import Loading from 'components/utils/Loading';

// If user is authenticated, load data
const DataProvider = (props) => {
  const { children } = props;
  const { isAuthenticated, isLoading, user: auth0User } = useAuth0();

  const { hasData: hasUserData, cookies } = useSelector((state) => state.session);
  const { hasData: hasCardData } = useSelector((state) => state.cards);
  const { hasData: hasPlayerData } = useSelector((state) => state.player);

  const { t } = useTranslation(['general', 'errors']);
  const { get, post } = useAPI();

  const hasData = hasUserData && hasCardData && hasPlayerData;

  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [error, setError] = useState('');

  useLayoutEffect(() => {
    const handleError = (err) => {
      console.log(err);

      if (!err.response || err.response.status === 500) {
        setError(t('errors:server_error'));
        return;
      }

      setError(t('errors:data_load_error'));
    };

    const loadData = async () => {
      try {
        // everything except showBanner
        const cookieSettings = { ...cookies };
        delete cookieSettings.showBanner;

        const analyticsCookies = JSON.parse(localStorage.getItem('analyticsCookies'));
        if (analyticsCookies === true) {
          cookieSettings.analyticsCookies = true;
        }

        const data = {
          email: auth0User.email,
          language: getCurrentLocale(),
          cookies: cookieSettings,
        };

        const response = await post('/users', data);

        const { cards, user, player } = response;

        // set language saved in the db
        i18n.changeLanguage(user.language || 'us');

        stats.setUserId(user.email);
        stats.push(['trackEvent', 'Account', 'Sign In']);

        dispatch(setSession(user));
        dispatch(setCards(cards));
        dispatch(setPlayer(player));

        // if user has not yet created a Player, go to onboarding
        if (!user.hasAcceptedTerms || !player.name) {
          navigate(ONBOARDING);
          return;
        }
      } catch (err) {
        handleError(err);
      }
    };

    if (!isAuthenticated || hasUserData || isLoading) return;

    loadData();
  }, [hasUserData, isLoading, isAuthenticated]);

  const renderedContent = useMemo(() => {
    if (!isAuthenticated) {
      if (isLoading) return <Loading error={error} />;
      return children;
    }

    // if (location.pathname === LOGIN) return children;

    if (!hasData) return <Loading error={error} />;

    return children || null;
  }, [isAuthenticated, isLoading, hasData]);

  return renderedContent;
};

export default DataProvider;
