import React, { useState, useMemo, useEffect } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';
import Dropdown from 'components/utils/Dropdown';

function GameOverwriteHandler() {
  const { sidebarContent, publicId } = useTournament();

  const { t } = useTranslation(['general']);

  const { data: match } = sidebarContent;
  const { participants, status: matchStatus } = match;
  const { gameResults, currentGameOutcome } = match.result;

  const [currentOutcomes, setCurrentOutcomes] = useState([]);

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { post } = useAPI();

  useEffect(() => {
    const results = gameResults.map((game) => game.participant);

    if (!match.result.winner) {
      results.push('');
    }

    setCurrentOutcomes(results);
  }, [match]);

  const setOutcome = (index, outcome) => {
    const newOutcomes = [...currentOutcomes];
    newOutcomes[index] = outcome.value;

    setCurrentOutcomes(newOutcomes);
  };

  const saveOutcomes = async () => {
    try {
      setDisabled(true);
      setStatus('');

      const results = currentOutcomes.filter((participantId) => participantId !== '');

      const data = {
        results,
        matchId: match.publicId,
      };

      await post(`/tournaments/matches/results/${publicId}`, data);

      setDisabled(false);
    } catch (err) {
      console.log(err);
      setStatus(t('general:data_save_error'));
      setDisabled(false);
    }
  };

  const dropdownData = useMemo(() => participants.map((participant) => ({
    value: participant.publicId,
    label: participant.name,
  })), [participants]);

  const renderedResults = useMemo(() => {
    const renderedOutcomes = currentOutcomes.map((winnerId, gameIndex) => {
      // check if player is winner
      const winnerData = participants.find((p) => p.publicId === winnerId);

      let winnerName = '';
      if (winnerData) winnerName = winnerData.name;

      return (
        <div
          key={gameIndex}
          className=""
        >
          <div className="columns">
            <div className="column is-3 px-1 is-flex has-content-centered-vertically">
              <p className="has-text-grey-light">
                {`${t('general:game')} ${gameIndex + 1}`}
              </p>
            </div>
            <div className="column px-1">
              <Dropdown
                items={dropdownData}
                onSelect={(value) => setOutcome(gameIndex, value)}
                value={winnerName}
              />
            </div>
          </div>
        </div>
      );
    });

    return renderedOutcomes;
  }, [currentOutcomes, participants, dropdownData]);

  return (
    <div className="px-3 mb-3">
      <p className="has-text-centered">
        {t('general:overwrite_match_results')}
      </p>
      { renderedResults }

      <div className="mt-3 has-text-centered">
        <button
          type="button"
          className="button has-text-weight-bold py-0 has-text-white is-primary"
          onClick={saveOutcomes}
          disabled={disabled}
        >
          {t('general:save_results')}
        </button>
      </div>

      {
        status && (
          <p className="is-size-7 has-text-centered mt-2">
            {status}
          </p>
        )
      }
    </div>
  );
}

export default GameOverwriteHandler;
