import React, { useEffect } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

// Components
import TriangleDisplay from 'components/utils/TriangleDisplay';
import FilterSelector from './FilterSelector';

// Provider
import AllTeamsDisplay from './AllTeamsDisplay';
import PlayerTeamList from './PlayerTeamList';
import CreateTeamButton from './CreateTeamButton';
import AllTeamsProvider, { useAllTeamsList } from '../AllTeamsProvider';

function TeamList() {
  const {
    isLoading, showLoadMoreBtn, loadMoreTeams,
  } = useAllTeamsList();

  const { isAuthenticated } = useAuth0();

  const { cardPic, name } = useSelector((state) => state.player.player);

  const { t } = useTranslation(['general', 'errors']);

  return (
    <div className="is-min-fullheight is-relative">

      <TriangleDisplay />

      <div
        className="columns is-centered is-mobile is-multiline m-0 px-0 is-min-fullheight"
      >

        <div className="column is-11-desktop is-11-tablet is-11-mobile">

          <div
            className="pt-0"
          >

            <div className="my-6">
              <h1
                className="is-outlined has-text-weight-semibold has-text-primary"
                style={{ fontSize: '65px' }}
              >
                {t('general:teams_list')}
              </h1>
              <p
                className="is-size-4-desktop is-size-5-tablet is-size-5-mobile has-text-grey"
              >
                {t('general:teams_description_short')}
              </p>
            </div>

            {
              isAuthenticated && (
                <div className="mb-6 pb-6">
                  <PlayerTeamList />
                </div>
              )
            }

            <div className="columns is-multiline">

              <div className="column px-0 has-min-width-450 has-text-right is-flex">

                <div className="has-margin-left-auto is-flex has-content-centered">
                  {
                    isAuthenticated && (
                      <div className="mr-4">
                        <CreateTeamButton />
                      </div>
                    )
                  }
                  <FilterSelector />
                </div>
              </div>

            </div>

          </div>

          <div className="columns is-multiline">

            <div className="column is-flex flex-direction-column">
              <div className="flex-grow">

                <div>

                  <div className="mt-0">
                    <AllTeamsDisplay />
                  </div>

                  {
                      showLoadMoreBtn && (
                        <div className="has-text-centered">
                          <button
                            type="button"
                            onClick={loadMoreTeams}
                            disabled={isLoading}
                            className={`button has-text-white has-text-weight-semibold has-background-primary my-4 grow ${isLoading ? 'is-loading' : ''}`}
                          >
                            {t('general:load_more')}
                          </button>
                        </div>
                      )
                    }
                </div>

              </div>

            </div>

          </div>

        </div>
      </div>

    </div>

  );
}

// Wrap TeamListProvider over the TeamList to access the context
function TeamListWrapper() {
  return <AllTeamsProvider><TeamList /></AllTeamsProvider>;
}

export default TeamListWrapper;
