// Rank images
import val_iron1 from 'assets/images/games/val/val_iron1.png';
import val_iron2 from 'assets/images/games/val/val_iron2.png';
import val_iron3 from 'assets/images/games/val/val_iron3.png';
import val_bronze1 from 'assets/images/games/val/val_bronze1.png';
import val_bronze2 from 'assets/images/games/val/val_bronze2.png';
import val_bronze3 from 'assets/images/games/val/val_bronze3.png';
import val_silver1 from 'assets/images/games/val/val_silver1.png';
import val_silver2 from 'assets/images/games/val/val_silver2.png';
import val_silver3 from 'assets/images/games/val/val_silver3.png';
import val_gold1 from 'assets/images/games/val/val_gold1.png';
import val_gold2 from 'assets/images/games/val/val_gold2.png';
import val_gold3 from 'assets/images/games/val/val_gold3.png';
import val_plat1 from 'assets/images/games/val/val_plat1.png';
import val_plat2 from 'assets/images/games/val/val_plat2.png';
import val_plat3 from 'assets/images/games/val/val_plat3.png';
import val_dia1 from 'assets/images/games/val/val_dia1.png';
import val_dia2 from 'assets/images/games/val/val_dia2.png';
import val_dia3 from 'assets/images/games/val/val_dia3.png';
import val_asc1 from 'assets/images/games/val/val_asc1.png';
import val_asc2 from 'assets/images/games/val/val_asc2.png';
import val_asc3 from 'assets/images/games/val/val_asc3.png';
import val_imm1 from 'assets/images/games/val/val_imm1.png';
import val_imm2 from 'assets/images/games/val/val_imm2.png';
import val_imm3 from 'assets/images/games/val/val_imm3.png';
import val_radiant from 'assets/images/games/val/val_radiant.png';

const ranks = [
  {
    name: 'Unranked',
    shortName: 'UNRKD',
    class: 0,
    minMmr: 0,
    maxMmr: 0,
    img: val_iron1,
  },
  {
    name: 'Iron I',
    shortName: 'IR I',
    class: 1,
    minMmr: 1,
    maxMmr: 1,
    img: val_iron1,
  },
  {
    name: 'Iron II',
    shortName: 'IR II',
    class: 2,
    minMmr: 2,
    maxMmr: 2,
    img: val_iron2,
  },
  {
    name: 'Iron III',
    shortName: 'IR III',
    class: 3,
    minMmr: 3,
    maxMmr: 3,
    img: val_iron3,
  },
  {
    name: 'Bronze I',
    shortName: 'B I',
    class: 4,
    minMmr: 4,
    maxMmr: 4,
    img: val_bronze1,
  },
  {
    name: 'Bronze II',
    shortName: 'B II',
    class: 5,
    minMmr: 5,
    maxMmr: 5,
    img: val_bronze2,
  },
  {
    name: 'Bronze III',
    shortName: 'B III',
    class: 6,
    minMmr: 6,
    maxMmr: 6,
    img: val_bronze3,
  },
  {
    name: 'Silver I',
    shortName: 'S I',
    class: 7,
    minMmr: 7,
    maxMmr: 7,
    img: val_silver1,
  },
  {
    name: 'Silver II',
    shortName: 'S II',
    class: 8,
    minMmr: 8,
    maxMmr: 8,
    img: val_silver2,
  },
  {
    name: 'Silver III',
    shortName: 'S III',
    class: 9,
    minMmr: 9,
    maxMmr: 9,
    img: val_silver3,
  },
  {
    name: 'Gold I',
    shortName: 'G I',
    class: 10,
    minMmr: 10,
    maxMmr: 10,
    img: val_gold1,
  },
  {
    name: 'Gold II',
    shortName: 'G II',
    class: 11,
    minMmr: 11,
    maxMmr: 11,
    img: val_gold2,
  },
  {
    name: 'Gold III',
    shortName: 'G III',
    class: 12,
    minMmr: 12,
    maxMmr: 12,
    img: val_gold3,
  },
  {
    name: 'Platinum I',
    shortName: 'P I',
    class: 13,
    minMmr: 13,
    maxMmr: 13,
    img: val_plat1,
  },
  {
    name: 'Platinum II',
    shortName: 'P II',
    class: 14,
    minMmr: 14,
    maxMmr: 14,
    img: val_plat2,
  },
  {
    name: 'Platinum III',
    shortName: 'P III',
    class: 15,
    minMmr: 15,
    maxMmr: 15,
    img: val_plat3,
  },
  {
    name: 'Diamond I',
    shortName: 'D I',
    class: 16,
    minMmr: 16,
    maxMmr: 16,
    img: val_dia1,
  },
  {
    name: 'Diamond II',
    shortName: 'D II',
    class: 17,
    minMmr: 17,
    maxMmr: 17,
    img: val_dia2,
  },
  {
    name: 'Diamond III',
    shortName: 'D III',
    class: 18,
    minMmr: 18,
    maxMmr: 18,
    img: val_dia3,
  },
  {
    name: 'Ascendant I',
    shortName: 'ASC I',
    class: 19,
    minMmr: 19,
    maxMmr: 19,
    img: val_asc1,
  },
  {
    name: 'Ascendant II',
    shortName: 'ASC II',
    class: 20,
    minMmr: 20,
    maxMmr: 20,
    img: val_asc2,
  },
  {
    name: 'Ascendant III',
    shortName: 'ASC III',
    class: 21,
    minMmr: 21,
    maxMmr: 21,
    img: val_asc3,
  },
  {
    name: 'Immortal I',
    shortName: 'IMM I',
    class: 22,
    minMmr: 22,
    maxMmr: 22,
    img: val_imm1,
  },
  {
    name: 'Immortal II',
    shortName: 'IMM II',
    class: 23,
    minMmr: 23,
    maxMmr: 23,
    img: val_imm2,
  },
  {
    name: 'Immortal III',
    shortName: 'IMM III',
    class: 24,
    minMmr: 24,
    maxMmr: 24,
    img: val_imm3,
  },
  {
    name: 'Radiant',
    shortName: 'RAD',
    class: 25,
    minMmr: 25,
    maxMmr: 25,
    img: val_radiant,
  },
];

export default ranks;
