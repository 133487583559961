import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useOrgaPage } from 'components/orgas/OrgaPage';

// Components
import OrgaRemoveTeamButton from './OrgaRemoveTeamButton';

function OrgaRemoveTeamHandler(props) {
  const { team } = props;

  const { permissions } = useOrgaPage();
  const { canRemoveTeam } = permissions;

  if (!canRemoveTeam) return null;

  return <OrgaRemoveTeamButton team={team} />;
}

OrgaRemoveTeamHandler.propTypes = {
  team: PropTypes.shape({
    publicId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default OrgaRemoveTeamHandler;
