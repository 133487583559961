import React, { useMemo, useState, useEffect } from 'react';

// Hooks
import { useHotscrimps } from 'components/HotScrimps/HotscrimpsProvider';
import { useTranslation } from 'react-i18next';

// Components
import ActiveHotscrimp from './ActiveHotscrimp';
import ChatLayout from 'components/utils/ChatLayout';
import { Link } from 'react-router-dom';

// Constants
import { HOT_SCRIMPS } from 'constants/routes';

function ActiveHotscrimpList() {
  const { activeHotscrimps } = useHotscrimps();

  const { t } = useTranslation(['general']);

  const [selectedHotscrimp, setSelectedHotscrimp] = useState();
  const [selectedTeamName, setTeamName] = useState();

  const selectHotscrimp = (teamIndex, hotscrimpIndex) => {
    setSelectedHotscrimp(activeHotscrimps[teamIndex].hotscrimps[hotscrimpIndex]);
    setTeamName(activeHotscrimps[teamIndex].teamName);
  };

  const removeSelectedHotscrimp = () => {
    setSelectedHotscrimp(null);
  };

  useEffect(() => {
    // if selectedHotscrimp is not in activeHotscrimps anymore, remove it
    if (!selectedHotscrimp) return;

    const allHotscrimps = activeHotscrimps.reduce((acc, { hotscrimps }) => [...acc, ...hotscrimps], []);

    const hotscrimpExists = allHotscrimps.find((hotscrimp) => hotscrimp.publicId === selectedHotscrimp.publicId);

    if (!hotscrimpExists) {
      removeSelectedHotscrimp();
    }
  }, [activeHotscrimps]);

  const renderedActive = useMemo(
    () => activeHotscrimps.map((hotscrimpData, index) => {
      const { teamName, hotscrimps } = hotscrimpData;

      const renderedScrimps = hotscrimps.map((hotscrimp, hotscrimpIndex) => (
        <ActiveHotscrimp
          hotscrimp={hotscrimp}
          selectHotscrimp={() => selectHotscrimp(index, hotscrimpIndex)}
          key={hotscrimp.publicId}
        />
      ));

      return (
        <div key={index}>
          <div className="has-text-centered">
            <p className="has-text-grey-light pb-1 has-background-black-ter has-border-darkest-light">
              {teamName}
            </p>
          </div>
          { renderedScrimps }

        </div>
      );
    }),
    [activeHotscrimps],
  );

  if (!selectedHotscrimp) {
    return (
      <div className="has-overflow-y-auto has-fullheight">
        { renderedActive }
      </div>
    );
  }

  return (
    <div className="has-fullheight is-flex flex-direction-column">
      <div className="has-text-centered">
        <p className="has-text-grey-light pb-1 has-background-black-ter has-border-darkest-light">
          { selectedTeamName }
        </p>
      </div>
      <ActiveHotscrimp
        hotscrimp={selectedHotscrimp}
        selectHotscrimp={removeSelectedHotscrimp}
        isSelected
      />
      <Link
        to={`${HOT_SCRIMPS}/${selectedHotscrimp.publicId}`}
        className="button is-size-7 is-small mb-1 has-background-grey-blue"
      >
        {t('general:details')}
      </Link>
      <div className="is-flex is-relative has-flex-1 has-overflow-hidden pt-1">
        <ChatLayout channel="hotscrimp" id={selectedHotscrimp.publicId} />
      </div>
    </div>
  );
}

export default ActiveHotscrimpList;
