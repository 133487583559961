import React, { useMemo, useState } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Actions
import { setNationality, setProfilePrivate } from 'store/player';

// Components
import Dropdown from 'components/utils/Dropdown';

// Constants
import { AVAILABLE_NATIONALITIES } from 'constants/user';
import Popup from 'reactjs-popup';

function PrivateToggler() {
  const dispatch = useDispatch();
  const { player } = useSelector((state) => state.player);
  const { hasProfilePrivate } = player;

  const [status, setStatus] = useState('');
  const [disabled, setDisabled] = useState(false);

  const { post } = useAPI();

  const { t } = useTranslation(['general']);

  const _setPrivate = async (_hasProfilePrivate) => {
    try {
      setStatus('');
      setDisabled(true);

      const data = {
        hasProfilePrivate: _hasProfilePrivate,
      };

      await post('/player/private', data);

      dispatch(setProfilePrivate(_hasProfilePrivate));

      stats.push(['trackEvent', 'Player Profile', `Profile is now${_hasProfilePrivate ? 'private' : 'public'}`]);

      setDisabled(false);
    } catch (e) {
      setDisabled(false);
      setStatus(t('errors:could_not_save_error'));
    }
  };

  return (
    <div className="field has-text-right">
      <div className="is-flex">
        <p className="">{t('general:your_profile_is')}</p>
        <p
          className="ml-1 has-text-weight-semibold"
        >
          { hasProfilePrivate ? t('general:private') : t('general:public') }
        </p>
      </div>

      <button
        className="button has-border-dark has-no-background has-text-dark mt-2 is-size-7"
        onClick={() => _setPrivate(!hasProfilePrivate)}
        disabled={disabled}
        type="button"
      >
        { hasProfilePrivate ? t('general:make_public') : t('general:make_private') }
      </button>

      <p
        className="mt-2 is-size-7"
      >
        {status}
      </p>

    </div>

  );
}

export default PrivateToggler;
