import React, { useEffect, useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAPI from 'components/hooks/useAPI';
import { useSelector } from 'react-redux';

// Constants
import {
  PINBOARD_ENTRY_MAX_DESCRIPTION_LENGTH, PINBOARD_ENTRY_MAX_SUBTITLE_LENGTH, PINBOARD_ENTRY_MAX_TITLE_LENGTH, PINBOARD_ENTRY_TYPES, PINBOARD_PROVIDER_TYPES, PINBOARD_SPECIAL_ICONS,
} from 'constants/pinboard';
import { PINBOARD_LIST } from 'constants/routes';

// HOCs
import { withAuthenticationRequired } from '@auth0/auth0-react';

// Components
import {
  DiscordContact, EmailContact, InstagramContact, TagsggContact, TwitterContact, WebsiteContact, YoutubeContact, DiscordServerContact,
} from 'components/user/Contacts';
import GameSelector from 'components/utils/games/GameSelector';
import PlatformSelector from 'components/utils/PlatformSelector';
import AsyncImageUploadPopup from 'components/utils/images/AsyncImageUploadPopup';

import PinboardDropdown from './PinboardDropdown';
import PinboardAmbitionTypeSelector from './PinboardAmbitionTypeSelector';
import PinboardEntryDisplay from '../PinboardEntryDisplay';
import ReactQuill from 'react-quill';

const DEFAULT_ENTRY = {
  publicId: '',
  entryType: PINBOARD_ENTRY_TYPES[0],
  providerType: PINBOARD_PROVIDER_TYPES[0],
  consumerType: PINBOARD_PROVIDER_TYPES[0],
  logoPic: '',
  specialIcons: [],
  ambitionTypes: [],
  qualification: '',
  title: '',
  subTitle: '',
  description: '',
  game: { tag: '' },
  platform: '',
  contact: {
    email: '',
    website: '',
    xcom: '',
    instagram: '',
    youtube: '',
    twitch: '',
    discord: '',
    discordServer: '',
  },
  isLive: false,
  hasAcceptedTos: false,
};

function PinboardEditor({ entry = DEFAULT_ENTRY }) {
  const { t } = useTranslation(['general', 'pinboard']);

  const { player } = useSelector((state) => state.player);
  const { qualifications } = player;

  const [publicId, setPublicId] = useState(entry.publicId);

  const [entryType, setEntryType] = useState(entry.entryType);
  const [providerType, setProviderType] = useState(entry.providerType);
  const [consumerType, setConsumerType] = useState(entry.consumerType);

  const [logoPic, setLogoPic] = useState(entry.logoPic);
  const [logoBlob, setLogoBlob] = useState(null);

  const [specialIcons, setSpecialIcons] = useState(entry.specialIcons);
  const [ambitionTypes, setAmbitionTypes] = useState(entry.ambitionTypes);
  const [qualification, setQualification] = useState(entry.qualification);

  const [title, setTitle] = useState(entry.title);
  const [subTitle, setSubTitle] = useState(entry.subTitle);
  const [description, setDescription] = useState(entry.description);

  const [game, setGame] = useState(entry.game);
  const [platform, setPlatform] = useState(entry.platform);

  const [contact, setContact] = useState({
    ...DEFAULT_ENTRY.contact,
    ...entry.contact,
  });

  const [isLive, setIsLive] = useState(entry.isLive);
  const [hasAcceptedTos, setHasAcceptedTos] = useState(entry.isLive);
  const [showGameSelector, setShowGameSelector] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const navigate = useNavigate();

  const { post, patch } = useAPI();

  useEffect(() => {
    if (game.tag) {
      setShowGameSelector(true);
    }

    setIsInitialized(true);
  }, []);

  useEffect(() => {
    if (!isInitialized) return;

    if (!showGameSelector) {
      setGame({ tag: '' });
      setPlatform('');
    } else {
      setGame({ tag: 'RL' });
      setPlatform('pc');
    }
  }, [isInitialized, showGameSelector]);

  const prepareLogo = async (blob) => {
    URL.revokeObjectURL(logoPic);
    setLogoPic(URL.createObjectURL(blob));
    setLogoBlob(blob);
  };

  const deleteEntry = async () => {
    try {
      setStatus('');
      setDisabled(true);

      const data = { publicId };
      await post('/pinboard/delete', data);

      navigate(PINBOARD_LIST);
    } catch (e) {
      setDisabled(false);
      setStatus(`${t('pinboard:delete_failed_error')}`);
      console.log(e);
    }
  };

  const saveEntry = async (isPublishCall = false) => {
    try {
      setStatus('');
      setDisabled(true);

      if (!hasAcceptedTos) {
        setStatus(`${t('pinboard:tos_not_accepted_error')}`);
        setDisabled(false);
        return;
      }

      if (!title) {
        setStatus(`${t('pinboard:title_required_error')}`);
        setDisabled(false);
        return;
      }

      if (!subTitle) {
        setStatus(`${t('pinboard:subtitle_required_error')}`);
        setDisabled(false);
        return;
      }

      const data = new FormData();
      data.append('entryType', entryType);
      data.append('providerType', providerType);
      data.append('consumerType', consumerType);
      data.append('specialIcons', JSON.stringify(specialIcons));
      data.append('ambitionTypes', JSON.stringify(ambitionTypes.filter((ambition) => !!ambition)));
      data.append('qualification', qualification);
      data.append('title', title);
      data.append('subTitle', subTitle);
      data.append('description', description);
      data.append('game', JSON.stringify(game));
      data.append('platform', platform);
      data.append('contact', JSON.stringify(contact));
      data.append('isLive', isPublishCall || isLive);

      if (logoBlob) {
        data.append('logoPic', logoBlob);
      }

      if (!publicId) {
        data.append('publicId', '');
        const _publicId = await post('/pinboard/', data);
        setPublicId(_publicId);
      } else {
        data.append('publicId', publicId);
        await patch('/pinboard/', data);
      }

      setIsLive(isPublishCall || isLive);
      setStatus(`${t('pinboard:saved_successfully')}`);
      setDisabled(false);
    } catch (e) {
      console.log(e);

      setStatus(`${t('pinboard:save_failed_error')}`);
      setDisabled(false);
    }
  };

  const _setTitle = (_title) => {
    if (_title.length > PINBOARD_ENTRY_MAX_TITLE_LENGTH) {
      return;
    }

    setTitle(_title);
  };

  const _setSubTitle = (_subTitle) => {
    if (_subTitle.length > PINBOARD_ENTRY_MAX_SUBTITLE_LENGTH) {
      return;
    }

    setSubTitle(_subTitle);
  };

  const _setDescription = (_description) => {
    if (_description.length > PINBOARD_ENTRY_MAX_DESCRIPTION_LENGTH) {
      return;
    }

    setDescription(_description);
  };

  return (
    <div>

      <div className="field has-border-bottom-grey pb-5">
        <div className="control">
          <PinboardDropdown
            label="provider_type_label"
            description="provider_type_description"
            value={providerType}
            setValue={setProviderType}
            availableValues={PINBOARD_PROVIDER_TYPES}
          />
        </div>
      </div>

      <div className="field has-border-bottom-grey pb-5 mt-5">
        <div className="control">
          <PinboardDropdown
            label="entry_type_label"
            description="entry_type_description"
            value={entryType}
            setValue={setEntryType}
            availableValues={PINBOARD_ENTRY_TYPES}
          />
        </div>
      </div>

      <div className="field has-border-bottom-grey pb-5 mt-5">
        <div className="control">
          <div className="columns is-multiline has-fullheight">
            <div className="column is-3">
              <p className="has-text-weight-bold is-size-5">
                {t('pinboard:content_label')}
              </p>
              <p className="mt-2">
                {t('pinboard:content_description')}
              </p>
            </div>
            <div className="column">
              <input
                className="input"
                type="text"
                placeholder={t('pinboard:title_placeholder')}
                value={title}
                onChange={(e) => _setTitle(e.target.value)}
              />
              <input
                className="input my-4"
                type="text"
                placeholder={t('pinboard:subtitle_placeholder')}
                value={subTitle}
                onChange={(e) => _setSubTitle(e.target.value)}
              />
              <ReactQuill
                theme="snow"
                value={description}
                onChange={_setDescription}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="field has-border-bottom-grey pb-5 mt-5">
        <div className="control">
          <div className="columns is-multiline">
            <div className="column is-6">
              <p className="has-text-weight-bold is-size-5">
                {t('pinboard:logo_label')}
              </p>
              <p className="mt-2">
                {t('pinboard:logo_description')}
              </p>
            </div>
            <div className="column">
              <AsyncImageUploadPopup
                label="logo"
                bestSize="200x200"
                width={200}
                height={200}
                onSave={prepareLogo}
                imgPath={logoPic && !logoPic.startsWith('blob') ? `/pinboard/logo_pictures/${logoPic}` : logoPic}
                closePopupAfterSave
              />
            </div>
          </div>
        </div>
      </div>

      <div className="field has-border-bottom-grey pb-5 mt-5">
        <div className="control">
          <PinboardAmbitionTypeSelector
            ambitionTypes={ambitionTypes}
            setAmbitionTypes={setAmbitionTypes}
          />
        </div>
      </div>

      <div className="field has-border-bottom-grey pb-5 mt-5">
        <div className="control">
          <PinboardDropdown
            label="special_icons_label"
            description="special_icons_description"
            value={specialIcons.length > 0 ? specialIcons[0] : null}
            setValue={(icon) => setSpecialIcons([icon])}
            availableValues={PINBOARD_SPECIAL_ICONS}
          />
        </div>
      </div>

      <div className="field has-border-bottom-grey pb-5 mt-5">
        <div className="control">
          <PinboardDropdown
            label="qualification_label"
            description="qualification_description"
            value={qualification}
            setValue={setQualification}
            availableValues={qualifications}
          />
        </div>
      </div>

      <div className="field has-border-bottom-grey pb-5 mt-5">
        <div className="control">
          <div className="columns is-multiline">
            <div className="column">
              <div className="is-flex">
                <p className="has-text-weight-bold is-size-5">
                  {t('pinboard:game_label')}
                </p>
                <input
                  type="checkbox"
                  className="checkbox ml-3"
                  checked={showGameSelector}
                  onChange={() => setShowGameSelector(!showGameSelector)}
                />
              </div>
              <p className="mt-2">
                {t('pinboard:game_description')}
              </p>
            </div>
            <div className="column">
              {
                showGameSelector && (
                  <>
                    <GameSelector
                      game={game}
                      setGame={(tag) => setGame({ tag })}
                      filter="NL"
                    />
                    <div className="mt-4">
                      <PlatformSelector
                        game={game.tag}
                        platform={platform}
                        setPlatform={setPlatform}
                      />
                    </div>
                  </>
                )
              }
            </div>
          </div>
        </div>
      </div>

      <div className="field has-border-bottom-grey pb-5 mt-5">
        <div className="control">
          <div className="columns is-multiline">
            <div className="column">
              <p className="has-text-weight-bold is-size-5">
                {t('pinboard:contact_label')}
              </p>
              <p className="mt-2">
                {t('pinboard:contact_description')}
              </p>
            </div>
            <div className="column">
              <EmailContact
                value={contact.email}
                setValue={(_, name) => setContact({ ...contact, email: name })}
              />
              <WebsiteContact
                value={contact.website}
                setValue={(_, name) => setContact({ ...contact, website: name })}
              />
              <DiscordContact
                value={contact.discord}
                setValue={(_, name) => {
                  setContact({ ...contact, discord: name });
                }}
              />
              <DiscordServerContact
                value={contact.discordServer}
                setValue={(_, name) => {
                  setContact({ ...contact, discordServer: name });
                }}
              />
              <InstagramContact
                value={contact.instagram}
                setValue={(_, name) => setContact({ ...contact, instagram: name })}
              />
              <TwitterContact
                value={contact.xcom}
                setValue={(_, name) => setContact({ ...contact, xcom: name })}
              />
              <YoutubeContact
                value={contact.youtube}
                setValue={(_, name) => setContact({ ...contact, youtube: name })}
              />
              <TagsggContact
                value={contact.twitch}
                setValue={(_, name) => setContact({ ...contact, twitch: name })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="field has-border-bottom-grey pb-5 mt-5">
        <div className="control">
          <div className="mb-3">
            <p className="has-text-weight-bold is-size-5">
              {t('pinboard:preview_label')}
            </p>
            <p className="mt-2">
              {t('pinboard:preview_description')}
            </p>
          </div>
          <PinboardEntryDisplay
            entry={{
              entryType,
              providerType,
              consumerType,
              logoPic,
              specialIcons,
              ambitionTypes,
              qualification,
              title,
              subTitle,
              description,
              game,
              platform,
              contact,
            }}
            isPreview
          />
        </div>
      </div>

      {
        !isLive && (
          <div className="field pb-5 mt-5">
            <div className="control">
              <div className="">
                <p className="has-text-weight-bold is-size-5">
                  {t('pinboard:tos_label')}
                </p>
                <p className="mt-2">
                  {t('pinboard:tos_description')}
                </p>

                <div className="box is-flex has-content-centered-vertically mt-4">
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={hasAcceptedTos}
                    onChange={() => setHasAcceptedTos(!hasAcceptedTos)}
                  />
                  <p className="ml-3">
                    {t('pinboard:tos_full_text')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )
      }

      <div className="field pb-5 mt-5">
        <div className="columns">
          <div className="column">
            {
              publicId && (
                <button
                  type="button"
                  className={`button is-block is-danger is-size-3 ${disabled ? 'is-loading' : ''}`}
                  onClick={deleteEntry}
                  disabled={disabled}
                >
                  {t('pinboard:delete_entry')}
                </button>
              )
            }
          </div>
          <div className="column has-text-right">
            {
              !isLive && (
                <button
                  type="button"
                  className={`button mb-5 is-block has-margin-left-auto is-success is-size-3 ${disabled ? 'is-loading' : ''}`}
                  onClick={() => saveEntry(true)}
                  disabled={disabled}
                >
                  {t('pinboard:publish_now')}
                </button>
              )
            }
            {
              isLive && (
                <button
                  type="button"
                  className={`button is-success is-size-3 ${disabled ? 'is-loading' : ''}`}
                  onClick={() => saveEntry(false)}
                  disabled={disabled}
                >
                  {t('general:save')}
                </button>
              )
            }

          </div>
        </div>

        <div className="has-text-centered mt-5 is-size-5 has-min-height-30">
          {status}
        </div>
      </div>

    </div>
  );
}

/*
<button
                  type="button"
                  className={`button is-success is-size-3 ${disabled ? 'is-loading' : ''}`}
                  onClick={() => saveEntry(false)}
                  disabled={disabled}
                >
                  {t('pinboard:just_save')}
                </button>
*/

export default withAuthenticationRequired(PinboardEditor);
