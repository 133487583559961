import React, {
  useState, useEffect, useContext, useCallback,
  useMemo,
} from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useSocket } from 'components/providers/SocketProvider';
import { useChat } from 'components/providers/ChatProvider';

export const HotscrimpsContext = React.createContext({});

function HotscrimpsProvider({ children = {} }) {
  const [selectedMode, setSelectedMode] = useState(''); // ['pending', 'active', 'historic']
  const [isLoading, setIsLoading] = useState(false);

  const [activeHotscrimps, setActiveHotscrimps] = useState([]);
  const [historicHotscrimps, setHistoricHotscrimps] = useState([]);

  const [pendingHotscrimps, setPendingHotscrimps] = useState([]);
  const [sentRequests, setSentRequests] = useState([]);

  const { socket, isConnected } = useSocket();
  const { reauthenticateChat } = useChat();
  const { get } = useAPI();

  const loadActiveHotscrimps = useCallback(async () => {
    try {
      setIsLoading(true);
      const _activeHotscrimps = await get('/hotscrimps/entries/active');

      setActiveHotscrimps(_activeHotscrimps);

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  }, []);

  const loadHistoricHotscrimps = useCallback(async () => {
    try {
      setIsLoading(true);
      const hotscrimps = await get('/hotscrimps/entries/finished');

      setHistoricHotscrimps(hotscrimps);

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  }, []);

  const loadPendingHotscrimps = useCallback(async () => {
    try {
      setIsLoading(true);

      const data = await get('/hotscrimps/entries/open');
      const { hotscrimps, sentRequests: _sentRequests } = data;

      setPendingHotscrimps(hotscrimps);
      setSentRequests(_sentRequests);

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  }, []);

  useEffect(() => {
    const handleHotscrimpRequestAcceptedEvent = async (hotscrimpId) => {
      try {
        // if the user views the historic tab, dont do anything
        if (selectedMode === 'historic') return;

        // if the user views the pending tab, we just remove the entry from the list
        if (selectedMode === 'pending') {
          setPendingHotscrimps((old) => old.filter((entry) => entry.publicId !== hotscrimpId));
          return;
        }

        await loadActiveHotscrimps();

        // if a user gets an accepted request, we need to reauthenticate the chat
        await reauthenticateChat();
      } catch (e) {
        console.log(e);
      }
    };

    const handleHotscrimpRequestGotAcceptedEvent = async (hotscrimpId) => {
      try {
        // if the user views the historic tab, dont do anything
        if (selectedMode === 'historic') return;

        // if the user views the sent requests in the pending tab, we just remove the entry from the sent requests
        if (selectedMode === 'pending') {
          setSentRequests((old) => old.filter((entry) => entry.hotscrimpId !== hotscrimpId));
          return;
        }

        await loadActiveHotscrimps();

        // if a user gets an accepted request, we need to reauthenticate the chat
        await reauthenticateChat();
      } catch (e) {
        console.log(e);
      }
    };

    const handleHotscrimpCreatedEvent = async (hotscrimpId) => {
      try {
        // if the user views any other tab, dont do anything
        if (selectedMode !== 'pending') return;

        // otherwise, get the open entry and add it to the pending list
        const _hotscrimp = await get(`/hotscrimps/entry/open/${hotscrimpId}`);

        setPendingHotscrimps((old) => [_hotscrimp, ...old]);
      } catch (e) {
        console.log(e);
      }
    };

    const handleHotscrimpRequestSentEvent = async (requestId) => {
      try {
        // if the user views any other tab, dont do anything
        if (selectedMode !== 'pending') return;

        // otherwise, get the entry and add it to the sent requests list
        const request = await get(`/hotscrimps/requests/entry/${requestId}`);

        setSentRequests((old) => [request, ...old]);
      } catch (e) {
        console.log(e);
      }
    };

    const handleHotscrimpAbortedEvent = async (hotscrimpId) => {
      try {
        // if the user views any other tab, dont do anything
        if (selectedMode === 'historic') return;

        if (selectedMode === 'pending') {
          setPendingHotscrimps((old) => old.filter((entry) => entry.hotscrimpId !== hotscrimpId));
          setSentRequests((old) => old.filter((entry) => entry.hotscrimpId !== hotscrimpId));
          return;
        }

        setActiveHotscrimps((old) => old.filter((entry) => entry.hotscrimpId !== hotscrimpId));
      } catch (e) {
        console.log(e);
      }
    };

    const handleReloadHotscrimpsEvent = async () => {
      if (selectedMode === 'active') {
        loadActiveHotscrimps();
      } else if (selectedMode === 'pending') {
        loadPendingHotscrimps();
      } else if (selectedMode === 'historic') {
        loadHistoricHotscrimps();
      }
    };

    const handleOpenHotscrimpCancelledEvent = async () => {
      if (selectedMode === 'pending') {
        loadPendingHotscrimps();
      }
    };

    const handleActiveHotscrimpCancelledEvent = async () => {
      if (selectedMode === 'active') {
        loadActiveHotscrimps();
      }
    };

    const handleFinishedHotscrimpDeletedEvent = async () => {
      if (selectedMode === 'historic') {
        loadHistoricHotscrimps();
      }
    };

    if (!isConnected) return undefined;

    socket.on('hotscrimpRequestAccepted', handleHotscrimpRequestAcceptedEvent);
    socket.on('hotscrimpRequestGotAccepted', handleHotscrimpRequestGotAcceptedEvent);

    socket.on('hotscrimpCreated', handleHotscrimpCreatedEvent);

    socket.on('hotscrimpRequestSent', handleHotscrimpRequestSentEvent);

    socket.on('hotscrimpAborted', handleHotscrimpAbortedEvent);
    socket.on('reloadHotscrimps', handleReloadHotscrimpsEvent);

    socket.on('openHotscrimpCancelled', handleOpenHotscrimpCancelledEvent);
    socket.on('activeHotscrimpCancelled', handleActiveHotscrimpCancelledEvent);
    socket.on('finishedHotscrimpDeleted', handleFinishedHotscrimpDeletedEvent);
    // socket.on('hotscrimpRequestReceived', loadReceivedHotscrimpRequestEntry);

    return () => {
      socket.off('hotscrimpRequestAccepted');
      socket.off('hotscrimpRequestGotAccepted');
      socket.off('hotscrimpCreated');
      socket.off('hotscrimpRequestSent');
      socket.off('hotscrimpAborted');
      socket.off('reloadHotscrimps');

      socket.off('openHotscrimpCancelled');
      socket.off('activeHotscrimpCancelled');
      socket.off('finishedHotscrimpDeleted');
      // socket.off('hotscrimpRequestReceived');
    };
  }, [isConnected, get, socket]);

  useEffect(() => {
    if (!selectedMode) return;

    if (selectedMode === 'active') {
      loadActiveHotscrimps();
    } else if (selectedMode === 'pending') {
      loadPendingHotscrimps();
    } else if (selectedMode === 'historic') {
      loadHistoricHotscrimps();
    }
  }, [selectedMode]);

  const addHotscrimp = useCallback((hotscrimp) => {
    setPendingHotscrimps((old) => [hotscrimp, ...old]);
  }, []);

  const values = useMemo(() => ({
    pendingHotscrimps,
    activeHotscrimps,
    historicHotscrimps,
    sentRequests,

    selectedMode,
    isLoading,

    setIsLoading,
    setPendingHotscrimps,
    setActiveHotscrimps,
    setHistoricHotscrimps,
    setSentRequests,

    loadActiveHotscrimps,
    loadPendingHotscrimps,
    loadHistoricHotscrimps,

    addHotscrimp,

    setSelectedMode,

  }), [
    pendingHotscrimps,
    activeHotscrimps,
    historicHotscrimps,
    sentRequests,
    isLoading,
    selectedMode,

    loadActiveHotscrimps,
    loadPendingHotscrimps,
    loadHistoricHotscrimps,

    addHotscrimp,

    setSelectedMode,
  ]);

  return (
    <HotscrimpsContext.Provider
      value={values}
    >
      {children}
    </HotscrimpsContext.Provider>
  );
}

HotscrimpsProvider.propTypes = {
  children: PropTypes.node,
};

export const useHotscrimps = () => {
  const data = useContext(HotscrimpsContext);

  return data;
};

export const usePendingHotscrimps = () => {
  const { pendingHotscrimps } = useHotscrimps();

  return pendingHotscrimps;
};

export const useActiveHotscrimps = () => {
  const { activeHotscrimps } = useHotscrimps();

  return activeHotscrimps;
};

export default HotscrimpsProvider;
