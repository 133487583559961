import { createSlice } from '@reduxjs/toolkit';

export const ONE_TEAM = {
  name: '',
  cardPic: '',
  teamId: '',
  description: '',
  organization: '',
  nationalities: [],
  platform: '',

  isTrusted: false,
  isVerified: false,

  members: [],
  achievements: [],

  game: {
    tag: 'RL',
  },
  contact: {
    discord: '',
    steam: '',
    riot: '',
    epic: '',
    battlenet: '',
    twitter: '',
    tagsgg: '',
  },

  createdAt: '',
};

const initialState = {
  hasData: false,
  isLoading: true,

  // teams a user has created or is in themselves
  teams: [],

};

export const teamSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },

    setTeams: (state, action) => ({
      ...state,

      hasData: true,
      isLoading: false,

      teams: action.payload,
    }),

    updateTeam: (state, action) => {
      const team = action.payload;

      const index = state.teams.findIndex((t) => t._id === team._id);

      if (index !== -1) {
        state.teams[index] = team;
      }
    },

    addTeam: (state, action) => {
      state.teams = [...state.teams, action.payload];
    },

    setName: (state, action) => {
      state.teams[action.payload.index].name = action.payload.name;
    },

    /*     setNationality: (state, action) => {
      state.team.nationality = action.payload;
    }, */

    setTeamCardPic: (state, action) => {
      const index = state.teams.findIndex((t) => t._id === action.payload.id);

      if (index !== -1) {
        state.teams[index].cardPic = action.payload.cardPic;
      }
    },

    /*     setEngagement: (state, action) => {
      state.team.engagement = action.payload;
    }, */

    setDescription: (state, action) => {
      state.team.description = action.payload;
    },

    setContact: (state, action) => {
      const contact = { ...state.team.contact };
      contact[action.payload.type] = action.payload.name;

      state.team.contact = contact;
    },
    addContact: (state, action) => ({
      ...state,
      contact: [...state.contact, action.payload],
    }),
    removeContact: (state, action) => ({
      ...state,
      contact: state.contact.map((contact) => contact.id !== action.payload),
    }),

    removeTeam: (state, action) => {
      state.teams = state.teams.filter((team) => team._id !== action.payload);
    },

    clearState: () => initialState,
  },
});

export const {
  setTeams,
  addTeam,
  updateTeam,
  clearState,
  setLoading,

  addContact,
  removeContact,
  setContact,
  setTeamContact,

  setName,
  setTeamCardPic,
  setDescription,

  removeTeam,

} = teamSlice.actions;

export default teamSlice.reducer;
