import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';
import { useTeamProfile } from 'components/Teams/TeamProfile';

function TeamAchievementDisplay() {
  const { achievements } = useTeamProfile();

  const { t } = useTranslation(['general']);

  const renderedAchievements = useMemo(() => achievements.map((achievement) => {
    const { type, achievedAt } = achievement;

    return (
      <div>
        {type}
      </div>
    );
  }), [achievements]);

  if (achievements.length === 0) return null;

  return (
    <div>
      <p>
        {t('general:achievements')}
      </p>
      {renderedAchievements}
    </div>
  );
}

export default TeamAchievementDisplay;
