import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import { getSelectedGame } from 'constants/games';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Constants
import { ALL_LANGUAGES } from 'constants/user';
import { PLAYER_PROFILE_DISPLAY } from 'constants/routes';

// Components
import ContactDisplay from 'components/utils/ContactDisplay';
import DescriptionDisplay from 'components/utils/DescriptionDisplay';
import { Link } from 'react-router-dom';
import PlayerboardCardDisplay from '../PlayerboardCardDisplay';

// Assets
import switch_icon from 'assets/images/icons/switch_icon.png';

const PlayerDetails = React.forwardRef(({ open, ...props }, ref) => {
  const { index } = props;

  const { entries, userEntry } = useSelector((state) => state.playerboard);

  const { t } = useTranslation(['general', 'tags']);

  const entry = index === -1 ? userEntry : entries[index];
  const {
    cardPic, name, languages, rank, platform, tags, playerId, nationality, description, contact, engagement,
  } = entry;

  const renderTags = () => {
    const _tags = [];
    // { t(`tags:tag_${tag}`)}
    for (let i = 0; i < tags.length; i++) {
      const tag = tags[i];
      _tags.push(
        <div className="column py-1 px-1 is-narrow" key={i}>
          <div className="has-background-primary has-text-weight-semibold has-text-white is-inline-block br-5 px-4 py-1 is-size-7">
            <p>
              { tag }
            </p>
          </div>
        </div>,
      );
    }

    return _tags;
  };

  const renderedLanguages = useMemo(() => {
    const languageNames = [];

    for (let i = 0; i < languages.length; i++) {
      const _language = languages[i];

      const foundLanguage = ALL_LANGUAGES.find((_lang) => _lang.value === _language);

      // eslint-disable-next-line
      if (!foundLanguage) continue;

      languageNames.push(foundLanguage.label);
    }

    const langs = languageNames.map((lang) => (
      <p className="is-size-6 has-text-weight mr-2" key={lang}>
        {lang}
      </p>
    ));

    return (

      <div className="mb-4">
        <div className="columns">
          <div className="column is-3 pl-0">
            <p
              className="has-text-grey"
            >
              {t('general:languages')}
            </p>

          </div>

          <div className="column">
            <div className="is-flex">
              {langs}
            </div>
          </div>
        </div>
      </div>

    );
  }, [languages]);

  const renderPlatform = () => {
    let icon = '';

    switch (platform) {
      case 'pc': icon = <i className="fas fa-desktop is-size-2" />; break;
      case 'ps': icon = <i className="fab fa-playstation is-size-2" />; break;
      case 'xbox': icon = <i className="fab fa-xbox is-size-2" />; break;
      case 'switch': icon = <img src={switch_icon} className="has-width-50 has-height-50" alt="" />; break;

      default: icon = null;
    }

    return (

      <div className="has-text-centered has-fullheight is-flex flex-direction-column">
        <p
          className="has-text-grey"
        >
          {t('general:platform')}
        </p>
        <div className="mt-5 is-flex has-content-centered flex-grow">
          { icon }
        </div>
      </div>

    );
  };

  const renderCard = () => {
    // const locale = getCurrentLocale();
    // const renderedPostedAt = DateTime.fromISO(postedAt).setZone(timezone).setLocale(locale).toRelative();

    const { game, mmr, mode } = rank;

    const {
      modes, name: gameName, icon: gameIcon,
    } = getSelectedGame(game);

    let modeData = modes.find((_mode) => _mode.name === mode);
    if (!modeData) modeData = { ranks: [], name: '' };

    let mmrRank = modeData.ranks.find((_rank) => _rank.minMmr <= mmr && _rank.maxMmr >= mmr);
    if (!mmrRank) mmrRank = { img: '', name: '' };

    const renderedTags = renderTags();
    const renderedPlatform = renderPlatform();

    const userMmr = mmrRank.name;
    const mmrImage = mmrRank.img;
    const modeName = modeData.name;

    const isCompetitive = engagement === 'competitive';

    return (
      <div className="is-fullwidth has-min-width-300 has-max-width-900 is-flex has-content-centered is-in-center">
        <div className="has-fullwidth">

          <div
            className="is-relative has-background-black-gradient br-5 pb-6 is-max-fullheight has-overflow-y-auto"
          >

            <div
              className="has-gradient-primary has-fullwidth is-relative"
              style={{ height: '170px', borderTopRightRadius: '5px', borderTopLeftRadius: '5px' }}
            />

            <div
              className="is-relative"
              style={{ marginTop: '-110px' }}
            >
              <div
                className="columns px-5"
              >
                <div className="column is-narrow">
                  <div className="has-width-250 has-height-400">

                    <PlayerboardCardDisplay img={cardPic} engagement={engagement} />

                  </div>

                  <div className="mt-6 has-width-250">
                    <p
                      className="has-text-grey"
                    >
                      {t('general:contact_info')}
                    </p>
                    <ContactDisplay contact={contact} displayType="row" showText={false} />
                  </div>

                </div>

                <div className="column">
                  <div className="is-flex has-content-centered-vertically">
                    <p
                      className="has-text-weight-semibold is-size-3-desktop is-size-4-tablet is-size-5-mobile has-text-standard"
                    >
                      {name}
                    </p>
                    <div className="ml-1">
                      <span className={`mx-2 mt-1 br-5 is-size-3 fi fi-${nationality}`} />
                    </div>
                  </div>

                  <div className="is-flex has-content-centered-vertically mt-1">
                    <p
                      className="is-size-5-desktop is-size-5-tablet is-size-6-mobile has-text-weight-semibold has-text-dark mt-1"
                    >
                      {t('general:looking_for_team')}
                    </p>
                  </div>

                  <div className="mt-5 px-5">

                    <div className="columns is-multiline has-text-centered">

                      <div className="column is-3">
                        <div className="has-text-centered has-fullheight is-flex flex-direction-column">
                          <p
                            className="has-text-grey"
                          >
                            {t('general:game')}
                          </p>
                          <div className="is-flex has-content-centered mt-5 flex-grow">
                            <img src={gameIcon} alt="" className="has-width-60 has-height-60" />
                          </div>
                        </div>

                      </div>

                      <div className="column is-3">
                        <div className="has-text-centered has-fullheight is-flex flex-direction-column">
                          <p
                            className="has-text-grey"
                          >
                            {t('general:mmr')}
                          </p>

                          <div className="is-flex has-content-centered mt-5 glex-grow">
                            <img src={mmrImage} alt="" className="has-width-60 has-height-60" />
                          </div>
                        </div>

                      </div>

                      <div className="column is-3">

                        <div className="has-text-centered has-fullheight is-flex flex-direction-column">
                          <p
                            className="has-text-grey"
                          >
                            {t('general:mode')}
                          </p>
                          <div className="is-flex has-content-centered mt-5 flex-grow">
                            <p className="has-text-weight-bold is-size-3">
                              {modeName}
                            </p>
                          </div>
                        </div>

                      </div>

                      <div className="column is-3">
                        { renderedPlatform }
                      </div>

                    </div>

                    <div className="mt-5">
                      <div className="columns">
                        <div className="column is-3 pl-0">
                          <p
                            className="has-text-grey"
                          >
                            {t('general:engagement')}
                          </p>

                        </div>

                        <div className="column">
                          <p>
                            { isCompetitive ? t('general:competitive') : t('general:just4fun') }
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mb-4 mt-1">
                      { renderedLanguages }
                    </div>

                    <DescriptionDisplay description={description} />

                    {
                      renderedTags.length > 0 && (
                        <div className="has-margin-top-auto">
                          <p className="has-text-grey">
                            { t('general:tags')}
                          </p>
                          <div className="columns is-multiline m-0">
                            { renderedTags }
                          </div>
                        </div>
                      )
                    }

                    <div className="mt-6">
                      <Link
                        to={`${PLAYER_PROFILE_DISPLAY}${playerId}`}
                        className="button has-gradient-primary has-text-weight-semibold is-size-5"
                      >
                        {t('general:player_profile')}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

    );
  };

  return (
    <div ref={ref} {...props}>
      { renderCard() }
    </div>
  );
});

PlayerDetails.propTypes = {
  index: PropTypes.number.isRequired,
};

export default PlayerDetails;
