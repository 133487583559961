import React, { useState, useRef, useEffect } from 'react';

function PanZoomWrapper({ children }) {
  const [isDragging, setIsDragging] = useState(false);

  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);

  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);

  const bracketRef = useRef(null);

  useEffect(() => {
    // if window resizes, move the bracket with the window
    const onResize = () => {
      setStartX(0);
      setTranslateX(0);
      if (bracketRef.current) {
        bracketRef.current.style.transform = `translateX(${0}px)`;
      }
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const onMouseDown = (e) => {
    e.preventDefault();

    setIsDragging(true);

    setStartX(e.clientX);
    setStartY(e.clientY);

    if (bracketRef.current) {
      bracketRef.current.style.cursor = 'grabbing';
    }

    return false;
  };

  const onMouseMove = (e) => {
    e.preventDefault();

    if (!isDragging) return;

    const dx = e.clientX - startX; // Calculate the difference from the start point
    let newTranslateX = translateX + dx; // Add the difference to the existing translation

    // Clamp the translation to the bounds of the container
    // if we are at the max, only allow dragging to the left
    const maxTranslateX = (bracketRef.current.scrollWidth - bracketRef.current.clientWidth) * -1;

    if (newTranslateX < maxTranslateX) {
      newTranslateX = maxTranslateX;
    }

    if (newTranslateX > 0) {
      newTranslateX = 0;
    }

    if (bracketRef.current) {
      bracketRef.current.style.transform = `translateX(${newTranslateX}px)`;
    }

    // body is scroll container
    const scrollContainer = document.querySelector('html');

    if (!scrollContainer) return;

    const dy = startY - e.clientY;
    // Adjust the scroll position of the scrollContainer
    scrollContainer.scrollTop += dy;

    // Update startY to the current Y position
    setStartY(e.clientY);
  };

  const onMouseUp = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isDragging) return;

    /* if (e.target.tagName === 'BUTTON' || e.target.tagName === 'DIV') {
      // get the button and prevent the click
      const button = e.target.closest('button');
      // if we found a button, prevent the click

    } */

    setIsDragging(false);
    const dx = e.clientX - startX;

    const maxTranslateX = (bracketRef.current.scrollWidth - bracketRef.current.clientWidth) * -1;

    if (translateX + dx < maxTranslateX) {
      setTranslateX(maxTranslateX);
    } else if (translateX + dx > 0) {
      setTranslateX(0);
    } else {
      setTranslateX(translateX + dx);
    }

    if (bracketRef.current) {
      bracketRef.current.style.cursor = 'grab'; // Reset cursor
    }

    setTranslateY(translateY + e.clientY - startY);
  };

  return (
    <div
      className="
      has-overflow-y-auto has-overflow-x-hidden is-relative dont-allow-selection
      "
      style={{ padding: '50px' }}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseUp}
      onMouseUp={onMouseUp}
      onTouchMove={onMouseMove}
      onTouchEnd={onMouseUp}
    >
      <div
        ref={bracketRef}
        onMouseDown={onMouseDown}
        onTouchStart={onMouseDown}
        style={{ cursor: 'grab' }}
      >
        { children }
      </div>
    </div>
  );
}

export default PanZoomWrapper;
