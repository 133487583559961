import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTournamentStaff } from 'components/tournaments/TournamentPage/TournamentEditor/TournamentStaffHandler';

/**
 * @name TournamentEditRoleButton
 * @description Button that opens a popup to edit a tournament staff's role, tournamentRole and ingameRole
 */
function TournamentEditRoleButton(props) {
  const { staffMember } = props;

  const { setStaffToEdit } = useTournamentStaff();

  const _setStaffToSetRoles = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setStaffToEdit(staffMember);
  };

  return (
    <div className="is-inline-block">
      <button
        type="button"
        className="button is-primary p-3 is-size-7"
        onClick={_setStaffToSetRoles}
      >
        <i className="fas fa-cog has-text-white" />
      </button>
    </div>
  );
}

TournamentEditRoleButton.propTypes = {
  staffMember: PropTypes.shape({
    playerId: PropTypes.string.isRequired,
    tournamentRole: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  }).isRequired,
};

export default TournamentEditRoleButton;
