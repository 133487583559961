import React, { useMemo } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';

// Components
import TournamentRegistration from './TournamentRegistration';
import TournamentCheckIn from './TournamentCheckIn';
import TournamentBracket from '../common/TournamentBracket';
import TournamentSeeding from './TournamentSeeding';
import TournamentResults from './TournamentResults';

const TournamentPhaseHandler = () => {
  const { currentPhase } = useTournament();

  const content = useMemo(() => {
    if (currentPhase === 'draft') return null;

    if (currentPhase === 'upcoming') return null;

    if (currentPhase === 'registration_open') {
      return <TournamentRegistration />;
    }

    if (currentPhase === 'check_in') {
      return <TournamentCheckIn />;
    }

    if (currentPhase === 'seeding') {
      return <TournamentSeeding />;
    }

    if (currentPhase === 'in_progress') {
      return <TournamentBracket />;
    }

    if (currentPhase === 'finished') {
      return <TournamentResults />;
    }

    return null;
  }, [currentPhase]);

  return content;
};

export default TournamentPhaseHandler;
