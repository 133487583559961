import React, { useMemo } from 'react';

// Hooks
import { useOrgaPage } from 'components/orgas/OrgaPage';
import { useTranslation } from 'react-i18next';

// Components
import OrgaRegionSelector from './OrgaRegionSelector';

const OrgaRegionHandler = () => {
  const { region, permissions } = useOrgaPage();
  const { canUpdateOrga } = permissions;

  const { t } = useTranslation(['general']);

  const renderedRegion = useMemo(() => {
    if (!canUpdateOrga) {
      return (
        <div>
          <p className="is-size-5 has-text-grey">
            { t('general:region') }
          </p>
          <p
            className="has-text-weight-semibold has-text-white break-word is-size-3-desktop is-size-4-touch is-inline-block"
          >
            { region.toUpperCase() }
          </p>
        </div>
      );
    }

    return (
      <OrgaRegionSelector />
    );
  }, [region]);

  return renderedRegion;
};

export default OrgaRegionHandler;
