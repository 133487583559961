import React, { useMemo } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import {
  setGameFilter, setTagsFilter, setEngagementFilter, setPlatformFilter, resetFilter, setNationalityFilter, setRankFilter,
} from 'store/playerboard';

// Components
import Popup from 'reactjs-popup';
import GameFilterDropdown from 'components/utils/games/GameFilterDropdown';
import MmrFilterDropdown from 'components/utils/games/MmrFilterDropdown';
import PlatformFilterDropdown from 'components/utils/PlatformFilterDropdown';
import NationalityFilterDropdown from 'components/utils/NationalityFilterDropdown';
import TagSelectorDropdown from 'components/utils/TagSelectorDropdown';
import ModeFilterDropdown from 'components/utils/games/ModeFilterDropdown';
import EngagementFilter from '../EngagementFilter';

function FilterPopup() {
  const { t } = useTranslation(['general']);

  const { isLoading, filter } = useSelector((state) => state.playerboard);

  const {
    rank, tags, languages, platform, engagement, nationality,
  } = filter;
  const { game, mmr, mode } = rank;

  const dispatch = useDispatch();

  const _setGameFilter = (_game) => {
    dispatch(setRankFilter({ game: _game, mmr: { start: -1 }, mode: '' }));
  };

  const _setModeFilter = (_mode) => {
    dispatch(setRankFilter({ ...rank, mode: _mode, mmr: { start: -1 } }));
  };

  const _setMmrFilter = (_mmr) => {
    dispatch(setRankFilter({ ...rank, mmr: { ...rank.mmr, start: _mmr } }));
  };

  const _setTagsFilter = (_tag) => {
    // if there is no tag, just set an empty array
    if (!_tag || _tag.value === '') {
      dispatch(setTagsFilter([]));
      return;
    }

    // otherwise, set the _tag as an array
    dispatch(setTagsFilter([_tag.value]));
  };

  const _setEngagementFilter = (_engagement) => {
    dispatch(setEngagementFilter(_engagement));
  };

  const _setPlatformFilter = (_platform) => {
    dispatch(setPlatformFilter(_platform));
  };

  const _setNationalityFilter = (_nationality) => {
    dispatch(setNationalityFilter(_nationality));
  };

  const _resetFilter = () => {
    dispatch(resetFilter());
  };

  const amountActiveFilters = useMemo(() => {
    let amount = 0;

    if (filter.rank.game) amount++;
    if (filter.tags.length !== 0) amount++;
    if (filter.languages.length !== 0) amount++;
    if (filter.platform) amount++;
    if (filter.engagement) amount++;
    if (filter.nationality) amount++;

    return amount;
  }, [filter]);

  return (
    <Popup
      position="top center"
      keepTooltipInside="#root"
      repositionOnResize
      closeOnDocumentClick
      closeOnEscape
      className="no-blur"
      trigger={(
        <div className="is-flex has-content-centered-vertically has-cursor-pointer dont-select-text">
          <i className="fas fa-sliders-h mr-3 is-size-5" />
          <p className="has-text-weight-semibold is-size-5">
            { `${t('general:filter')} (${amountActiveFilters})`}
          </p>
        </div>
        )}
    >
      {
        () => (
          <div className="">
            <div className="box has-max-width-600">
              <div className="is-flex has-content-centered-vertically">
                <p className="has-text-weight-semibold is-size-5">
                  { t('general:filter')}
                </p>
                <button
                  onClick={_resetFilter}
                  type="button"
                  className="cleanButton has-margin-left-auto"
                >
                  <i className="fas fa-redo-alt has-text-primary is-size-5" />
                </button>
              </div>
              <div className="columns is-multiline">

                <div className="column is-2-desktop has-min-width-250">
                  <GameFilterDropdown
                    game={game}
                    setGame={_setGameFilter}
                    filter={['NL']}
                    disabled={isLoading}
                  />
                </div>

                {
                    game && (
                      <>
                        <div className="column is-2-desktop has-min-width-250">
                          <ModeFilterDropdown
                            game={game}
                            mode={mode}
                            setMode={_setModeFilter}
                            disabled={isLoading}
                          />
                        </div>
                        <div className="column is-2-desktop has-min-width-250">
                          <PlatformFilterDropdown
                            game={game}
                            platform={platform}
                            setPlatform={_setPlatformFilter}
                            disabled={isLoading}
                          />
                        </div>
                      </>
                    )
                  }

                {
                    mode && (
                      <div className="column is-2-desktop has-min-width-250">
                        <MmrFilterDropdown
                          game={game}
                          mode={mode}
                          mmr={mmr.start}
                          setMmr={_setMmrFilter}
                          disabled={isLoading}
                        />
                      </div>
                    )
                  }

                <div className="column is-2-desktop has-min-width-250">
                  <NationalityFilterDropdown
                    nationality={nationality}
                    setNationality={_setNationalityFilter}
                    disabled={isLoading}
                  />

                </div>

                <div className="column is-2-desktop has-min-width-250">
                  <EngagementFilter
                    type={engagement}
                    setType={_setEngagementFilter}
                    disabled={isLoading}
                  />
                </div>

                <div className="column is-2-desktop has-min-width-250">
                  <TagSelectorDropdown
                    game={game}
                    tag={tags.length ? tags[0] : null}
                    setTag={_setTagsFilter}
                    disabled={isLoading}
                  />
                </div>

              </div>
            </div>
          </div>
        )
        }
    </Popup>
  );
}

export default FilterPopup;
