import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Constants
import { ORGA_PAGE } from 'constants/routes';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import { Link } from 'react-router-dom';
import DataDeletedNotification from '../../DataDeletedNotification';

function OrgaMemberLeftNotification(props) {
  const { notification } = props;
  const {
    orgaId, playerName, orgaName, dataDeleted,
  } = notification;

  const { t } = useTranslation(['general']);

  if (dataDeleted) {
    return (
      <DataDeletedNotification
        icon="fas fa-person-circle-minus"
        title={t('general:orga_member_left_but_orga_deleted')}
        message={t('general:orga_member_left_but_orga_deleted_message')}
      />
    );
  }

  return (
    <Link
      to={`${ORGA_PAGE}${orgaId}`}
    >
      <div>
        <div>

          <div className="columns">
            <div className="column px-0 is-3 is-flex has-content-centered">
              <i className="fas fa-person-circle-minus is-size-3 has-text-grey" />
            </div>

            <div className="column px-0 is-9 has-text-left">
              <div
                className="has-fullwidth"
              >
                <p
                  className="is-size-6 has-line-height-full has-text-white"
                >
                  { t('general:orga_member_left') }
                </p>
                {
                  playerName && orgaName && (
                    <p className="mt-2 has-text-grey-light has-line-height-full">
                      { t(
                        'general:orga_member_left_message',
                        { orga: orgaName, member: playerName },
                      )}
                    </p>
                  )
                }
              </div>

            </div>
          </div>
        </div>
      </div>

    </Link>
  );
}

OrgaMemberLeftNotification.propTypes = {
  notification: PropTypes.shape({
    orgaId: PropTypes.string,
    playerName: PropTypes.string,
    orgaName: PropTypes.string,
    dataDeleted: PropTypes.bool,
  }).isRequired,
};

export default OrgaMemberLeftNotification;
