import React, { useMemo } from 'react';

// Libraries
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import { Link } from 'react-router-dom';
import TeamCardPicDisplay from 'components/Teams/utils/TeamCardPicDisplay';

// Constants
import { HOT_SCRIMPS_REQUEST } from 'constants/routes';
import { getSelectedGame } from 'constants/games';

function SentRequestDisplay(props) {
  const { request } = props;
  const {
    team, publicId, status, startDate, game, mode,
  } = request;

  const { cardPic, name } = team;

  const { t } = useTranslation(['general', 'hotscrimps']);

  const { timezone, language } = useSelector((state) => state.session);

  const renderedStartTime = useMemo(() => {
    const currentTime = DateTime.fromISO(startDate).setZone(timezone).setLocale(language);

    return (
      `${currentTime.toLocaleString(DateTime.DATETIME_MED)}`
    );
  }, [startDate, timezone, language]);

  const renderedGameModeInfo = useMemo(() => {
    const gameData = getSelectedGame(game.tag, mode);

    return `${gameData.name} ${mode}`;
  }, [request]);

  return (
    <div>
      <Link
        to={`${HOT_SCRIMPS_REQUEST}/${publicId}`}
      >
        <div className="has-border-bottom-grey-dark">
          <div className="columns">
            <div className="column is-3 is-flex has-content-centered pr-0">
              <div className="has-width-70 has-height-70">
                <TeamCardPicDisplay cardPic={cardPic} />
              </div>
            </div>

            <div className="column has-text-left">
              <p
                className="is-size-5 abbreviate"
              >
                { name }
              </p>

              <div className="">
                <p className="has-text-grey-light is-size-">
                  { `${t('general:sent_at')}: ${renderedStartTime}` }
                </p>
              </div>

              <p className="has-text-grey-light is-size-">
                { renderedGameModeInfo }
              </p>

              <p className="has-text-grey-light is-size-7 mt-3">
                { `${t('general:status')}: ${t(`general:${status}`)}` }
              </p>
            </div>

          </div>
        </div>
      </Link>
    </div>
  );
}

SentRequestDisplay.propTypes = {
  request: PropTypes.shape({
    status: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    publicId: PropTypes.string.isRequired,
    game: PropTypes.shape({
      tag: PropTypes.string.isRequired,
    }).isRequired,
    mode: PropTypes.string.isRequired,
    team: PropTypes.shape({
      cardPic: PropTypes.string,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default SentRequestDisplay;
