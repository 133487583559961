import TeamCardPicDisplay from 'components/Teams/utils/TeamCardPicDisplay';

import React, { useMemo } from 'react';

// Libraries
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import { Link } from 'react-router-dom';

// Constants
import { HOT_SCRIMPS_REQUEST } from 'constants/routes';

function ReceivedRequest(props) {
  const { request } = props;
  const {
    team, createdAt, status, publicId,
  } = request;

  const { cardPic, name } = team;

  const { t } = useTranslation(['general', 'hotscrimps']);
  const { timezone, language } = useSelector((state) => state.session);

  const renderedTimeReceived = useMemo(() => {
    const currentTime = DateTime.fromISO(createdAt).setZone(timezone).setLocale(language);

    return (
      `${currentTime.toLocaleString(DateTime.DATETIME_MED)}`
    );
  }, [createdAt, timezone, language]);

  return (
    <div>
      <Link
        to={`${HOT_SCRIMPS_REQUEST}/${publicId}`}
      >
        <div className="has-border-bottom-grey-dark">
          <div className="columns">
            <div className="column is-3 is-flex has-content-centered pr-0">
              <div className="has-width-70 has-height-70">
                <TeamCardPicDisplay cardPic={cardPic} />
              </div>
            </div>

            <div className="column has-text-left">
              <p
                className="is-size-5 abbreviate"
              >
                { name }
              </p>

              <div className="">
                <p className="has-text-grey-light is-size-">
                  { `${t('general:received_at')}: ${renderedTimeReceived}` }
                </p>
              </div>

              <p className="has-text-grey-light is-size-7 mt-3">
                { `${t('general:status')}: ${t(`general:${status}`)}` }
              </p>
            </div>

          </div>
        </div>
      </Link>
    </div>
  );
}

ReceivedRequest.propTypes = {
  request: PropTypes.shape({
    team: PropTypes.shape({
      cardPic: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    createdAt: PropTypes.string.isRequired,
    publicId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
};

export default ReceivedRequest;
