import React, { useState, useEffect, useMemo } from 'react';

// Components
import Dropdown from 'components/utils/Dropdown';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import GAMES from 'constants/games';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

// Assets
import switch_icon from 'assets/images/icons/switch_icon.png';

function PlatformSelector(props) {
  const {
    game, platform, setPlatform, displayType,
  } = props;

  const { t } = useTranslation(['general']);

  const [isInit, setInit] = useState(true);

  useEffect(() => {
    if (isInit) {
      setInit(false);
    }
  }, []);

  useEffect(() => {
    // dont change game on first render
    if (isInit) return;
    // all games are at least available for pc, so if a game changes, set platform to pc
    setPlatform('pc');
  }, [game]);

  const getPlatformLabel = (_platform) => {
    let label = '';

    switch (_platform) {
      case 'pc':
        label = 'PC';
        break;
      case 'xbox':
        label = 'XBox';
        break;
      case 'ps':
        label = 'PlayStation';
        break;
      case 'switch':
        label = 'Switch';
        break;

      default:
        break;
    }

    return label;
  };

  const availablePlatforms = useMemo(() => {
    const selectedGame = GAMES.find((_game) => _game.tag === game);

    if (!selectedGame) return [];

    return selectedGame.platforms.map((_platform) => ({ label: getPlatformLabel(_platform), value: _platform }));
  }, [game]);

  const getPlatformIcon = (_platform) => {
    switch (_platform) {
      case 'pc': return <i className="fas fa-desktop is-size-4" />;
      case 'ps': return <i className="fab fa-playstation is-size-4" />;
      case 'xbox': return <i className="fab fa-xbox is-size-4" />;
      case 'switch': return <img src={switch_icon} className="has-width-25 has-height-25" alt="" />;

      default: return null;
    }
  };

  const _setPlatform = (_platform) => {
    if (availablePlatforms.length <= 1) return;

    stats.push(['trackEvent', 'Platform Selector', 'Platform selected']);

    setPlatform(_platform.value);
  };

  const selectedPlatform = useMemo(() => ({ label: getPlatformLabel(platform), value: platform }), [platform]);

  const renderedPlatforms = useMemo(() => {
    if (displayType === 'dropdown') {
      return (
        <div className="">
          <Dropdown
            items={availablePlatforms}
            onSelect={_setPlatform}
            value={selectedPlatform}
            disabled={availablePlatforms.length <= 1}
          />
        </div>
      );
    }

    const _platforms = availablePlatforms.map((_platform) => {
      const icon = getPlatformIcon(_platform.value);

      return (
        <div
          className="column is-narrow py-0 px-0"
          key={_platform.value}
        >
          <button
            className="cleanButton px-0 mr-3"
            type="button"
            onClick={() => _setPlatform(_platform)}
            key={_platform.value}
          >
            <p className={`has-text-weight-semibold has-text-white break-word is-size-3-desktop is-size-4-touch is-inline-block ${_platform.value === platform ? 'has-border-bottom-primary' : ''}`}>
              { icon }
            </p>

          </button>
        </div>
      );
    });

    return (
      <div className="columns is-mobile is-multiline">
        { _platforms }
      </div>
    );
  }, [platform, selectedPlatform, availablePlatforms, displayType]);

  return (
    <div className="field">

      <div className="">
        <p className="has-text-grey is-size-5 mb-2">
          {t('general:platform')}
        </p>
        { renderedPlatforms }
      </div>
    </div>
  );
}

PlatformSelector.propTypes = {
  game: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired,
  setPlatform: PropTypes.func.isRequired,
  displayType: PropTypes.string,
};

PlatformSelector.defaultProps = {
  displayType: 'dropdown',
};

export default PlatformSelector;
