import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import Popup from 'reactjs-popup';
import TournamentStaffDisplay from 'components/tournaments/TournamentPage/TournamentEditor/TournamentStaffHandler/TournamentStaffDisplay';
import TournamentPlayerDetailsDisplay from './TournamentPlayerDetailsDisplay';

function TournamentStaffDisplayPopup(props) {
  const { staffMember } = props;

  const [disabled, setDisabled] = useState(false);

  const {
    name, nationality, cardPic, tournamentRole, role, playerId, joinedAt,
  } = staffMember;

  return (
    <Popup
      trigger={(
        <div>
          <TournamentStaffDisplay staffMember={staffMember} />
        </div>
      )}
      position="top center"
      keepTooltipInside="#root"
      modal
      closeOnDocumentClick={!disabled}
      closeOnEscape={!disabled}
    >
      {
            (close) => (
              <TournamentPlayerDetailsDisplay
                playerId={playerId}
                close={close}
                setDisabled={setDisabled}
                disabled={disabled}
              />
            )
          }

    </Popup>

  );
}

TournamentStaffDisplayPopup.propTypes = {
  staffMember: PropTypes.shape({
    name: PropTypes.string.isRequired,
    cardPic: PropTypes.string.isRequired,
    tournamentRole: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    playerId: PropTypes.string.isRequired,
    joinedAt: PropTypes.string.isRequired,
  }).isRequired,
};

export default TournamentStaffDisplayPopup;
