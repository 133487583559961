import React, {
  useEffect, useState, useMemo, useLayoutEffect,
} from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Components
import { useFriendList } from 'components/friends/FriendListProvider';
import FriendRequestsDisplay from './FriendRequestsDisplay';
import ChatLayout from 'components/utils/ChatLayout';

import FriendDisplay from './FriendDisplay';

// Assets
import shrimp from 'assets/images/base/icon_no_text.png';

function FriendsList() {
  const { t } = useTranslation(['general']);

  const [selectedFriend, setSelectedFriend] = useState(null);

  const { playerId } = useSelector((state) => state.player.player);

  const {
    friends, loadFriendList,
  } = useFriendList();

  const selectFriend = (_playerId) => {
    const friend = friends.find((entry) => entry.playerId === _playerId);

    if (!friend) return;

    setSelectedFriend(friend);
  };

  const channelId = useMemo(() => {
    if (!selectedFriend) return null;

    // sort the ids so that the channel name is always the same
    const ids = [playerId, selectedFriend.playerId].sort();

    return `${ids[0]}:${ids[1]}`;
  }, [selectedFriend]);

  const renderedFriends = useMemo(() => friends.map((friend) => (
    <FriendDisplay
      friend={friend}
      key={friend.playerId}
      selectFriend={() => selectFriend(friend.playerId)}
    />
  )), [friends]);

  const resetSelection = () => {
    setSelectedFriend(null);
  };

  useLayoutEffect(() => {
    if (!selectedFriend) return;

    // check if selectedFriend is still in friends
    const friend = friends.find((entry) => entry.playerId === selectedFriend.playerId);

    if (!friend) {
      setSelectedFriend(null);
    }
  }, [friends]);

  useEffect(() => {
    loadFriendList();
  }, []);

  if (!selectedFriend) {
    return (
      <div
        className="pt-3 has-background-dark-blue has-text-weight-light is-flex flex-direction-column has-fullheight is-relative"
      >
        <div
          className="is-absolute has-text-centered has-fullwidth"
          style={{ bottom: -50 }}
        >
          <img
            alt="shrimp"
            src={shrimp}
            className=""
            style={{ opacity: 0.2 }}
          />
        </div>

        <div className="has-fullheight p-0">
          <div className="has-text-centered mt-1">
            <h2 className="has-text-standard is-size-5 has-text-weight-light">
              {t('general:friends')}
            </h2>
          </div>

          <div
            className="has-overflow-y-auto mt-3"
          >
            <div className="">
              { renderedFriends }
              <div className="mt-3">
                <FriendRequestsDisplay />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (

    <div
      className="pt-3 has-background-dark-blue has-text-weight-light is-flex flex-direction-column has-fullheight is-relative"
      style={{ maxHeight: 'calc(100vh - 71px)' }}
    >

      <div
        className="is-absolute has-text-centered has-fullwidth"
        style={{ bottom: -50 }}
      >
        <img
          alt="shrimp"
          src={shrimp}
          className=""
          style={{ opacity: 0.2 }}
        />
      </div>

      <div className="has-fullheight is-flex flex-direction-column p-0">
        <div className="has-text-centered mt-1">
          <h2 className="has-text-standard is-size-5 has-text-weight-light">
            {t('general:friends')}
          </h2>
        </div>

        <div
          className="mt-3"
        >
          <div className="">
            <FriendDisplay friend={selectedFriend} selectFriend={resetSelection} isSelected />
          </div>
        </div>
        <div className="is-flex is-relative has-flex-1 has-overflow-hidden pt-1">
          <ChatLayout channel="friends" id={channelId} />
        </div>
      </div>

    </div>

  );
}

export default FriendsList;
