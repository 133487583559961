import React, { useState } from 'react';

// Libraries
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

// Hooks
import { useTeamProfile } from 'components/Teams/TeamProfile';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import Popup from 'reactjs-popup';
import CardPicDisplay from 'components/utils/CardPicDisplay';
import TeamKickHandler from './TeamKickHandler';
import TeamRoleHandler from './TeamRoleHandler';
import TeamPlayerDetailsDisplay from './TeamPlayerDetailsDisplay';

function TeamPlayerDisplay(props) {
  const { index } = props;
  const { members: players } = useTeamProfile();
  const { timezone, language } = useSelector((state) => state.session);

  const [disabled, setDisabled] = useState(false);

  const { t } = useTranslation(['general']);

  const renderTime = (joinedAt) => {
    const date = DateTime.fromISO(joinedAt).setZone(timezone).setLocale(language);
    return date.toLocaleString(DateTime.DATE_SIMPLE);
  };

  if (!players[index]) return null;

  const {
    name, nationality, cardPic, teamRole, ingameRole, role, playerId, joinedAt, _id,
  } = players[index];

  const joinedAtDate = renderTime(joinedAt);

  return (
    <Popup
      trigger={(
        <div className="box p-0 has-cursor-pointer">
          <div
            className="columns is-multiline has-width-350"
          >
            <div className="column has-max-width-120 p-0">
              <div className="">
                <CardPicDisplay img={cardPic} />
              </div>

            </div>
            <div
              className="column"
            >
              <div className="is-flex flex-direction-column has-fullheight">
                <div>
                  <div className="is-flex">
                    <p
                      className="is-size-5 mr-2"
                    >
                      {name}
                    </p>
                    <span className={`nmt3 br-5 is-size-5 fi fi-${nationality}`} />
                  </div>

                  { role !== 'member' && (
                  <p className="is-size-7 has-text-warning">
                    {t(`general:${role}`)}
                  </p>
                  )}

                  { ingameRole && (
                  <p className="is-size-7 has-text-info">
                    {
                      ingameRole.charAt(0).toUpperCase() + ingameRole.slice(1)
                    }
                  </p>
                  )}

                  { teamRole && (
                  <p className="is-size-7 has-text-primary">
                    {t(`general:${teamRole}`)}
                  </p>
                  )}
                </div>

                <div className="has-margin-top-auto">
                  <p
                    className="is-size-7"
                  >
                    {`${t('general:joined_at')} ${joinedAtDate}`}
                  </p>
                </div>
              </div>

            </div>

            <div className="column has-text-right is-narrow">
              <TeamRoleHandler memberIndex={index} />
              <div className="mt-2">
                <TeamKickHandler memberIndex={index} />
              </div>
            </div>
          </div>
        </div>
      )}
      position="top center"
      keepTooltipInside="#root"
      modal
      closeOnDocumentClick={!disabled}
      closeOnEscape={!disabled}
    >
      {
            (close) => (
              <TeamPlayerDetailsDisplay
                playerId={playerId}
                close={close}
                setDisabled={setDisabled}
                disabled={disabled}
              />
            )
          }

    </Popup>

  );
}

TeamPlayerDisplay.propTypes = {
  index: PropTypes.number.isRequired,
};

export default TeamPlayerDisplay;
