import React, { useMemo } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

function QualificationsDisplay({ qualifications = [] }) {
  const { t } = useTranslation(['general']);

  const renderedQualifications = useMemo(() => qualifications.map((qualification) => (
    <div
      key={qualification.id}
      className=""
    >
      <p
        className="is-size-6 my-2"
      >
        {t(`general:${qualification}`)}
      </p>
    </div>
  )), [qualifications, t]);

  return (
    <div>
      <p className="has-text-weight-semibold is-size-4-desktop is-size-5-touch">
        {t('general:qualifications')}
      </p>

      {renderedQualifications && renderedQualifications.length > 0 ? renderedQualifications : (
        <p className="mt-2">
          {t('general:no_qualifications_yet')}
        </p>
      )}
    </div>
  );
}

export default QualificationsDisplay;
