import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';
// Circle Button
function RoundButton(props) {
  const {
    children, onClick, className, disabled,
  } = props;

  return (
    <button
      type="button"
      className={`${styles.button} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      { children }
    </button>
  );
}

RoundButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

RoundButton.defaultProps = {
  className: '',
  disabled: false,
};

export default RoundButton;
