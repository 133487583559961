import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// HOCs
import withAuthentication from 'components/hocs/withAuthentication';

// Components
import TriangleDisplay from 'components/utils/TriangleDisplay';

import PlayerDisplay from './PlayerDisplay';
import FilterSelector from './FilterSelector';

// Provider
import PlayerListProvider, { usePlayerList } from '../PlayerListProvider';

function PlayerList() {
  const {
    isLoading, showLoadMoreBtn, loadMorePlayers,
  } = usePlayerList();

  const { cardPic, name } = useSelector((state) => state.player.player);

  const { t } = useTranslation(['general', 'errors']);

  return (
    <div className="is-min-fullheight">

      <TriangleDisplay />

      <div
        className="columns is-centered is-mobile is-multiline m-0 px-0 is-min-fullheight"
      >

        <div className="column is-11-desktop is-11-tablet is-11-mobile">

          <div
            className="pt-0"
          >

            <div className="my-6">
              <h1
                className="is-outlined has-text-weight-semibold has-text-primary"
                style={{ fontSize: '65px' }}
              >
                {t('general:player_list')}
              </h1>
              <p
                className="is-size-4-desktop is-size-5-tablet is-size-5-mobile has-text-grey"
              >
                {t('general:players_description_short')}
              </p>
            </div>

            <div className="columns is-multiline">

              <div className="column has-min-width-450 has-text-right">
                <FilterSelector />
              </div>

            </div>

          </div>

          <div className="columns is-multiline">

            <div className="column is-flex flex-direction-column">
              <div className="flex-grow">

                <div>
                  <PlayerDisplay />

                  {
                      showLoadMoreBtn && (
                        <div className="has-text-centered">
                          <button
                            type="button"
                            onClick={loadMorePlayers}
                            disabled={isLoading}
                            className={`button has-text-white has-text-weight-semibold has-background-primary my-4 grow ${isLoading ? 'is-loading' : ''}`}
                          >
                            {t('general:load_more')}
                          </button>
                        </div>
                      )
                    }
                </div>

              </div>

            </div>

          </div>

        </div>
      </div>

    </div>

  );
}

/*
Player Entry

<div className="column is-narrow">

              <p className="mb-2 has-text-weight-semibold">
                { t('general:your_entry')}
              </p>
              <div className="box has-text-centered">
                <Link
                  to={PLAYER_PROFILE}
                  className="is-inline-block"
                >
                  <div
                    className="has-max-width-100 br-10 has-border-black-light boxshadow has-margin-auto"
                  >
                    <CardPicDisplay img={cardPic} />

                  </div>
                  <div className="is-flex has-content-centered mt-3">
                    <p
                      className="is-inline-block is-size-6 py-0 has-text-weight-semibold"
                    >
                      {name.toUpperCase()}
                    </p>
                  </div>
                </Link>
              </div>

            </div>

*/

// Wrap PlayerListProvider over the PlayerList to access the context
function PlayerListWrapper() {
  return <PlayerListProvider><PlayerList /></PlayerListProvider>;
}

export default PlayerListWrapper;
