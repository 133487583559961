import React from 'react';
import { Helmet } from 'react-helmet-async';

function Imprint() {
  return (
    <>
      <Helmet>
        <title>Scrimps - Impressum</title>
      </Helmet>
      <div className="is-fullheight">
        <div className="columns has-fullheight is-vcentered is-marginless is-centered is-mobile">
          <div className="column is-8 box br-20 px-4 py-4">
            <h1 className="has-text-centered has-text-weight-semibold is-size-4">
              Impressum
            </h1>
            <p>Florian Tinter</p>
            <p>MINKZ Gaming</p>
            <p>Neunerweg 6</p>
            <p>95138 Bad Steben</p>
            <p className="my-4">Deutschland</p>
            <p>Tel.: 015154757442</p>
            <p>E-Mail: weplay@minkz.de</p>
            <p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE323473597</p>
            <p>
              Plattform der EU-Kommission zur Online-Streitbeilegung:
              <a href="https://ec.europa.eu/odr">https://ec.europa.eu/odr</a>
            </p>
            <p>Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit.</p>
            <p className="my-6">
              <img
                style={{
                  float: 'left', height: '80px', width: 'auto', marginRight: '1em', marginTop: '-8px',
                }}
                src="https://www.it-recht-kanzlei.de/gfx/Logos/logo-fairness-160.png"
                alt=""
              />
              <strong>Mitglied der Initiative “Fairness im Handel”.</strong>
              <br />
              Nähere Informationen:
              <a href="https://www.fairness-im-handel.de" target="_blank" rel="noopener noreferrer" className="ml-1">
                https://www.fairness-im-handel.de
            </a>
            </p>
            <div className="mt-6">
              <p>Verantwortliche/r i.S.d. § 55 Abs. 2 RStV:</p>
              <p>Florian Tinter, Neunerweg 6, 95138 Bad Steben</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Imprint;
