export const LANDING = '/';

export const HOME = '/home';
export const PROFILE = '/profile';

export const CARD_CREATE = '/cards';
export const CARD_EDIT = '/cards/:id';

export const MATCHES = '/matches';
export const MATCHES_ID = '/matches/:id';
export const MATCHES_SEARCH = '/matches/search';
export const MATCHES_SEARCH_ID = '/matches/search/:id';

export const HOT_SCRIMPS = '/hotscrimps';
export const HOT_SCRIMPS_PAGE = '/hotscrimps/:id';
export const HOT_SCRIMPS_REQUEST = '/hotscrimps/request';
export const HOT_SCRIMPS_REQUEST_PAGE = '/hotscrimps/request/:id';

export const PLAYERS_LIST = '/player/list';
export const PLAYER_PROFILE = '/player/profile';
export const PLAYER_PROFILE_PAGE = '/player/:id';
export const PLAYER_PROFILE_DISPLAY = '/player/SCR-PID-';
export const PLAYER_PROFILE_DISPLAY_PAGE = '/player/SCR-PID-:id';

export const TEAMS_LIST = '/team/list';
export const TEAM_PROFILE_PAGE = '/team/:id';
export const TEAM_PROFILE = '/team/SCR-TID-';
export const TEAM_PROFILE_ID = '/team/SCR-TID-:id';
export const TEAM_ACCEPT_INVITE = '/team/invite/';
export const TEAM_ACCEPT_INVITE_PAGE = '/team/invite/:id';

export const ORGA_LIST = '/orga/list';
export const ORGA_PAGE_ID = '/orga/:id';
export const ORGA_PAGE = '/orga/SCR-OID-';
export const ORGA_ACCEPT_MEMBER_INVITE_PAGE = '/orga/invite/member/';
export const ORGA_MEMBER_INVITE_PAGE_ID = '/orga/invite/member/:id';
export const ORGA_TEAM_INVITE_PAGE = '/orga/invite/team/';
export const ORGA_TEAM_INVITE_PAGE_ID = '/orga/invite/team/:id';

export const RECIPE_LIST = '/recipes';
export const RECIPE_PAGE = '/recipe';
export const RECIPE_PAGE_ID = '/recipe/:id';

export const TOURNAMENT_LIST = '/tournaments';
export const TOURNAMENT_PAGE = '/tournament';
export const TOURNAMENT_PAGE_ID = '/tournament/:id';
export const TOURNAMENT_ACCEPT_STAFF_INVITE_PAGE = '/tournament/invite/staff/';
export const TOURNAMENT_ACCEPT_STAFF_INVITE_PAGE_ID = '/tournament/invite/staff/:id';
export const TOURNAMENT_TOS = '/tournaments/tos';

export const PINBOARD_LIST = '/pinboard';
export const PINBOARD_PAGE = '/pinboard';
export const PINBOARD_PAGE_ID = '/pinboard/:id';
export const PINBOARD_CREATE = '/pinboard/create';
export const PINBOARD_EDIT = '/pinboard/edit';
export const PINBOARD_EDIT_ID = '/pinboard/edit/:id';

export const ONBOARDING = '/welcome';

export const PLAYERBOARD = '/playerbowl';

export const LOGIN = '/login';

export const IMPRINT = '/imprint';
export const PRIVACY = '/privacy';
export const TOS = '/tos';
