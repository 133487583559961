import cs from 'assets/images/cards/games/cs.png';

// Icon
import csgo_icon from 'assets/images/games/csgo/csgo_icon.png';
import icon_white from 'assets/images/games/csgo/csgo_landing_icon.png';

// Rank images
import csgo_silver1 from 'assets/images/games/csgo/csgo_silver1.png';
import csgo_silver2 from 'assets/images/games/csgo/csgo_silver2.png';
import csgo_silver3 from 'assets/images/games/csgo/csgo_silver3.png';
import csgo_silver4 from 'assets/images/games/csgo/csgo_silver4.png';
import csgo_se from 'assets/images/games/csgo/csgo_se.png';
import csgo_sem from 'assets/images/games/csgo/csgo_sem.png';

import csgo_gold1 from 'assets/images/games/csgo/csgo_gold1.png';
import csgo_gold2 from 'assets/images/games/csgo/csgo_gold2.png';
import csgo_gold3 from 'assets/images/games/csgo/csgo_gold3.png';
import csgo_gold4 from 'assets/images/games/csgo/csgo_gold4.png';

import csgo_mg1 from 'assets/images/games/csgo/csgo_mg1.png';
import csgo_mg2 from 'assets/images/games/csgo/csgo_mg2.png';
import csgo_mge from 'assets/images/games/csgo/csgo_mge.png';

import csgo_dmg from 'assets/images/games/csgo/csgo_dmg.png';
import csgo_le from 'assets/images/games/csgo/csgo_le.png';
import csgo_lem from 'assets/images/games/csgo/csgo_lem.png';
import csgo_smfc from 'assets/images/games/csgo/csgo_smfc.png';
import csgo_global from 'assets/images/games/csgo/csgo_global.png';

import ranks from './ranks';

export default {
  name: 'Counter Strike',
  tag: 'CSGO',
  img: cs,
  icon: csgo_icon,
  iconWhite: icon_white,
  platforms: ['pc'],
  modes: [
    {
      name: '5v5',
      amountPlayers: 5,
      ranks,
    },
    {
      name: 'wingman',
      amountPlayers: 2,
      ranks,
    },
  ],
  tagsPerCategory: [
    {
      category: 'general',
      tags: ['Tournament'],
    },
    {
      category: 'properties',
      tags: ['IGL', 'Shotcall'],
    },
    {
      category: 'role',
      tags: ['Rifler', 'AWP', 'Main awp', 'Second AWP', 'Entryfragger', 'Support', 'IGL'],
    },
    {
      category: 'leagues',
      tags: ['99Damage', 'ESL', 'ESEA', 'FACEIT', 'Fast Cup'],
    },
  ],

  // TODO: old, remove later
  mmr: [
    'Unranked',
    'Silver I',
    'Silver II',
    'Silver III',
    'Silver IV',
    'Silver Elite',
    'Silver Elite Master',
    'Gold Nova I',
    'Gold Nova II',
    'Gold Nova III',
    'Gold Nova Master',
    'Master Guardian I',
    'Master Guardian II',
    'Master Guardian Elite',
    'DMG',
    'Legendary Eagle',
    'Legendary Eagle Master',
    'Supreme Master First Class',
    'Global Elite',
  ],

  mmrShort: [
    'UNRKD',
    'S I',
    'S II',
    'S III',
    'S IV',
    'SE',
    'SEM',
    'GN I',
    'GN II',
    'GN III',
    'GNM',
    'MG I',
    'MG II',
    'MGE',
    'DMG',
    'LE',
    'LEM',
    'SMFC',
    'GE',
  ],
  mmrImages: [
    csgo_silver1,

    csgo_silver1,
    csgo_silver2,
    csgo_silver3,
    csgo_silver4,
    csgo_se,
    csgo_sem,

    csgo_gold1,
    csgo_gold2,
    csgo_gold3,
    csgo_gold4,

    csgo_mg1,
    csgo_mg2,
    csgo_mge,

    csgo_dmg,
    csgo_le,
    csgo_lem,
    csgo_smfc,
    csgo_global,
  ],
};
