import React, { useState } from 'react';

// Libraries
import stats from 'analytics/matomo';
import PropTypes from 'prop-types';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useTeamProfile } from 'components/Teams/TeamProfile';
import { useTranslation } from 'react-i18next';

// Components
import Popup from 'reactjs-popup';
import SocialLinksEditor from 'components/utils/SocialLinksEditor';

function TeamContactEditor() {
  const { publicId, contact, setContact } = useTeamProfile();

  const [newContact, setNewContact] = useState(contact);
  const [status, setStatus] = useState('');
  const [disabled, setDisabled] = useState(false);

  const { t } = useTranslation(['general', 'errors']);
  const { post } = useAPI();

  const saveContact = async (close) => {
    try {
      setStatus('');
      setDisabled(true);

      const data = { contact: newContact };

      await post(`/teams/contact/${publicId}`, data);

      stats.push(['trackEvent', 'Team Profile', 'Contact set']);

      // dispatch(setPlayerContact(newContact));

      setContact(newContact);

      setDisabled(false);

      close();
    } catch (e) {
      setDisabled(false);
      setStatus(`${t('errors:save_contact_error')}!`);
    }
  };

  return (
    <Popup
      trigger={(
        <button
          type="button"
          className="button is-size-7 has-no-background grow_small has-text-grey has-border-dashed-grey
          "
          onClick={saveContact}
          disabled={disabled}
        >
          <i className="fas fa-plus mr-2" />
          {t('general:add')}
        </button>
      )}
      position="top center"
      keepTooltipInside="#root"
      repositionOnResize
      closeOnDocumentClick={!disabled}
      closeOnEscape={!disabled}
      modal
    >
      {
        (close) => (
          <div className="box has-width-400 p-0 is-max-fullheight has-overflow-y-auto">

            <div
              className="has-background-primary has-text-centered p-4 pb-6 is-relative br-5"
            >
              <div className="has-text-right">
                <button
                  type="button"
                  className=" cleanButton has-text-white"
                  onClick={close}
                  disabled={disabled}
                >
                  <i className="fas fa-times" />
                </button>
              </div>
              <p
                className="has-text-weight-semibold is-size-3 mx-3"
              >
                {t('general:social_links')}
              </p>

              <div
                className="br-100 has-background-primary is-absolute is-flex has-content-centered"
                style={{
                  left: 'calc(50% - 45px)', width: '90px', height: '85px', marginTop: '0px',
                }}
              >
                <i className="fas fa-id-badge is-size-2" />
              </div>

            </div>

            <div className="px-5 pb-5 pt-6 is-flex flex-direction-column has-content-centered">

              <div className="mt-2">
                <SocialLinksEditor contact={newContact} setContact={setNewContact} />
              </div>

              <div className="mt-5 has-text-right">
                <button
                  type="button"
                  className="button has-margin-auto is-primary grow_small has-text-weight-semibold"
                  onClick={() => saveContact(close)}
                  disabled={disabled}
                >
                  {t('general:save')}
                </button>

              </div>

              <p className="mt-4">
                { status }
              </p>

            </div>

          </div>
        )
        }
    </Popup>
  );
}

export default TeamContactEditor;
