import React, { useMemo, useState } from 'react';

// Hooks
import { useFriendList } from 'components/friends/FriendListProvider';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Components
import CardPicDisplay from 'components/utils/CardPicDisplay';
import { Link } from 'react-router-dom';

// Constants
import { PLAYER_PROFILE_DISPLAY } from 'constants/routes';

function FriendRequestsDisplay() {
  const { friendRequests, acceptFriendRequest, denyFriendRequest } = useFriendList();

  const { t } = useTranslation(['general']);

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const _acceptFriendRequest = async (playerId) => {
    try {
      setDisabled(true);
      await acceptFriendRequest(playerId);
      setDisabled(false);
    } catch (e) {
      setStatus(t('errors:server_error'));
      setDisabled(false);
    }
  };

  const _denyFriendRequest = async (playerId) => {
    try {
      setDisabled(true);
      await denyFriendRequest(playerId);
      setDisabled(false);
    } catch (e) {
      setStatus(t('errors:server_error'));
      setDisabled(false);
    }
  };

  const renderedRequests = useMemo(() => friendRequests.map((friend) => (
    <div
      key={friend.playerId}
      className="is-flex has-background-dark-blue has-border-darkest-light"
    >

      <Link
        to={`${PLAYER_PROFILE_DISPLAY}${friend.playerId}`}
        className="has-height-100 has-width-65 is-flex"
      >
        <CardPicDisplay img={friend.cardPic} />
      </Link>

      <div className="is-flex flex-direction-column p-2">
        <p
          className="has-text-weight-normal"
        >
          {friend.name}
        </p>

        <div className="is-flex has-margin-top-auto">
          <button
            type="button"
            className={`button is-success is-size-7 ${disabled ? 'is-loading' : ''}`}
            onClick={() => _acceptFriendRequest(friend.playerId)}
            disabled={disabled}
          >
            {t('general:accept')}
          </button>
          <button
            type="button"
            className={`button is-danger ml-3 is-size-7 ${disabled ? 'is-loading' : ''}`}
            onClick={() => _denyFriendRequest(friend.playerId)}
            disabled={disabled}
          >
            {t('general:decline')}
          </button>
        </div>
      </div>
    </div>
  )), [friendRequests, disabled]);

  if (!friendRequests.length) return null;

  return (
    <div className="">
      <div className="has-text-centered">
        <p className="mb-4">
          {t('general:received_friend_requests')}
        </p>
      </div>
      {renderedRequests}
    </div>
  );
}

export default FriendRequestsDisplay;
