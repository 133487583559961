// Rank images
import rl_unranked from 'assets/images/games/rl/rl_unranked.png';
import rl_bronze1 from 'assets/images/games/rl/rl_bronze1.png';
import rl_bronze2 from 'assets/images/games/rl/rl_bronze2.png';
import rl_bronze3 from 'assets/images/games/rl/rl_bronze3.png';
import rl_silver1 from 'assets/images/games/rl/rl_silver1.png';
import rl_silver2 from 'assets/images/games/rl/rl_silver2.png';
import rl_silver3 from 'assets/images/games/rl/rl_silver3.png';
import rl_gold1 from 'assets/images/games/rl/rl_gold1.png';
import rl_gold2 from 'assets/images/games/rl/rl_gold2.png';
import rl_gold3 from 'assets/images/games/rl/rl_gold3.png';
import rl_platinum1 from 'assets/images/games/rl/rl_platinum1.png';
import rl_platinum2 from 'assets/images/games/rl/rl_platinum2.png';
import rl_platinum3 from 'assets/images/games/rl/rl_platinum3.png';
import rl_diamond1 from 'assets/images/games/rl/rl_diamond1.png';
import rl_diamond2 from 'assets/images/games/rl/rl_diamond2.png';
import rl_diamond3 from 'assets/images/games/rl/rl_diamond3.png';
import rl_champion1 from 'assets/images/games/rl/rl_champion1.png';
import rl_champion2 from 'assets/images/games/rl/rl_champion2.png';
import rl_champion3 from 'assets/images/games/rl/rl_champion3.png';
import rl_gm1 from 'assets/images/games/rl/rl_gm1.png';
import rl_gm2 from 'assets/images/games/rl/rl_gm2.png';
import rl_gm3 from 'assets/images/games/rl/rl_gm3.png';
import rl_ssl from 'assets/images/games/rl/rl_ssl.png';

const ranks_3v3 = [
  {
    name: 'Unranked',
    shortName: 'UNRKD',
    class: 0,
    minMmr: 0,
    maxMmr: 0,
    img: rl_unranked,
  },
  {
    name: 'Bronze I',
    shortName: 'B I',
    class: 1,
    minMmr: 1,
    maxMmr: 170,
    img: rl_bronze1,
  },
  {
    name: 'Bronze II',
    shortName: 'B II',
    class: 2,
    minMmr: 171,
    maxMmr: 230,
    img: rl_bronze2,
  },
  {
    name: 'Bronze III',
    shortName: 'B III',
    class: 3,
    minMmr: 231,
    maxMmr: 294,
    img: rl_bronze3,
  },
  {
    name: 'Silver I',
    shortName: 'S I',
    class: 4,
    minMmr: 295,
    maxMmr: 354,
    img: rl_silver1,
  },
  {
    name: 'Silver II',
    shortName: 'S II',
    class: 5,
    minMmr: 355,
    maxMmr: 412,
    img: rl_silver2,
  },
  {
    name: 'Silver III',
    shortName: 'S III',
    class: 6,
    minMmr: 413,
    maxMmr: 474,
    img: rl_silver3,
  },
  {
    name: 'Gold I',
    shortName: 'G I',
    class: 7,
    minMmr: 475,
    maxMmr: 525,
    img: rl_gold1,
  },
  {
    name: 'Gold II',
    shortName: 'G II',
    class: 8,
    minMmr: 526,
    maxMmr: 586,
    img: rl_gold2,
  },
  {
    name: 'Gold III',
    shortName: 'G III',
    class: 9,
    minMmr: 587,
    maxMmr: 646,
    img: rl_gold3,
  },
  {
    name: 'Platinum I',
    shortName: 'P I',
    class: 10,
    minMmr: 647,
    maxMmr: 705,
    img: rl_platinum1,
  },
  {
    name: 'Platinum II',
    shortName: 'P II',
    class: 11,
    minMmr: 706,
    maxMmr: 765,
    img: rl_platinum2,
  },
  {
    name: 'Platinum III',
    shortName: 'P III',
    class: 12,
    minMmr: 766,
    maxMmr: 825,
    img: rl_platinum3,
  },
  {
    name: 'Diamond I',
    shortName: 'D I',
    class: 13,
    minMmr: 826,
    maxMmr: 900,
    img: rl_diamond1,
  },
  {
    name: 'Diamond II',
    shortName: 'D II',
    class: 14,
    minMmr: 907,
    maxMmr: 980,
    img: rl_diamond2,
  },
  {
    name: 'Diamond III',
    shortName: 'D III',
    class: 15,
    minMmr: 981,
    maxMmr: 1062,
    img: rl_diamond3,
  },
  {
    name: 'Champion I',
    shortName: 'C I',
    class: 16,
    minMmr: 1063,
    maxMmr: 1180,
    img: rl_champion1,
  },
  {
    name: 'Champion II',
    shortName: 'C II',
    class: 17,
    minMmr: 1181,
    maxMmr: 1299,
    img: rl_champion2,
  },
  {
    name: 'Champion III',
    shortName: 'C III',
    class: 18,
    minMmr: 1300,
    maxMmr: 1419,
    img: rl_champion3,
  },
  {
    name: 'Grand Champion I',
    shortName: 'GC I',
    class: 19,
    minMmr: 1420,
    maxMmr: 1559,
    img: rl_gm1,
  },
  {
    name: 'Grand Champion II',
    shortName: 'GC II',
    class: 20,
    minMmr: 1560,
    maxMmr: 1700,
    img: rl_gm2,
  },
  {
    name: 'Grand Champion III',
    shortName: 'GC III',
    class: 21,
    minMmr: 1701,
    maxMmr: 1859,
    img: rl_gm3,
  },
  {
    name: 'Supersonic Legend',
    shortName: 'SSL',
    class: 22,
    minMmr: 1860,
    maxMmr: 9999,
    img: rl_ssl,
  },
];

const ranks_2v2 = [
  {
    name: 'Unranked',
    shortName: 'UNRKD',
    class: 0,
    minMmr: 0,
    maxMmr: 0,
    img: rl_unranked,
  },
  {
    name: 'Bronze I',
    shortName: 'B I',
    class: 1,
    minMmr: 1,
    maxMmr: 172,
    img: rl_bronze1,
  },
  {
    name: 'Bronze II',
    shortName: 'B II',
    class: 2,
    minMmr: 173,
    maxMmr: 232,
    img: rl_bronze2,
  },
  {
    name: 'Bronze III',
    shortName: 'B III',
    class: 3,
    minMmr: 233,
    maxMmr: 294,
    img: rl_bronze3,
  },
  {
    name: 'Silver I',
    shortName: 'S I',
    class: 4,
    minMmr: 295,
    maxMmr: 350,
    img: rl_silver1,
  },
  {
    name: 'Silver II',
    shortName: 'S II',
    class: 5,
    minMmr: 351,
    maxMmr: 407,
    img: rl_silver2,
  },
  {
    name: 'Silver III',
    shortName: 'S III',
    class: 6,
    minMmr: 408,
    maxMmr: 469,
    img: rl_silver3,
  },
  {
    name: 'Gold I',
    shortName: 'G I',
    class: 7,
    minMmr: 470,
    maxMmr: 525,
    img: rl_gold1,
  },
  {
    name: 'Gold II',
    shortName: 'G II',
    class: 8,
    minMmr: 526,
    maxMmr: 584,
    img: rl_gold2,
  },
  {
    name: 'Gold III',
    shortName: 'G III',
    class: 9,
    minMmr: 585,
    maxMmr: 645,
    img: rl_gold3,
  },
  {
    name: 'Platinum I',
    shortName: 'P I',
    class: 10,
    minMmr: 646,
    maxMmr: 706,
    img: rl_platinum1,
  },
  {
    name: 'Platinum II',
    shortName: 'P II',
    class: 11,
    minMmr: 707,
    maxMmr: 765,
    img: rl_platinum2,
  },
  {
    name: 'Platinum III',
    shortName: 'P III',
    class: 12,
    minMmr: 766,
    maxMmr: 825,
    img: rl_platinum3,
  },
  {
    name: 'Diamond I',
    shortName: 'D I',
    class: 13,
    minMmr: 826,
    maxMmr: 900,
    img: rl_diamond1,
  },
  {
    name: 'Diamond II',
    shortName: 'D II',
    class: 14,
    minMmr: 901,
    maxMmr: 980,
    img: rl_diamond2,
  },
  {
    name: 'Diamond III',
    shortName: 'D III',
    class: 15,
    minMmr: 981,
    maxMmr: 1060,
    img: rl_diamond3,
  },
  {
    name: 'Champion I',
    shortName: 'C I',
    class: 16,
    minMmr: 1061,
    maxMmr: 1179,
    img: rl_champion1,
  },
  {
    name: 'Champion II',
    shortName: 'C II',
    class: 17,
    minMmr: 1180,
    maxMmr: 1299,
    img: rl_champion2,
  },
  {
    name: 'Champion III',
    shortName: 'C III',
    class: 18,
    minMmr: 1300,
    maxMmr: 1419,
    img: rl_champion3,
  },
  {
    name: 'Grand Champion I',
    shortName: 'GC I',
    class: 19,
    minMmr: 1420,
    maxMmr: 1559,
    img: rl_gm1,
  },
  {
    name: 'Grand Champion II',
    shortName: 'GC II',
    class: 20,
    minMmr: 1560,
    maxMmr: 1700,
    img: rl_gm2,
  },
  {
    name: 'Grand Champion III',
    shortName: 'GC III',
    class: 21,
    minMmr: 1701,
    maxMmr: 1858,
    img: rl_gm3,
  },
  {
    name: 'Supersonic Legend',
    shortName: 'SSL',
    class: 22,
    minMmr: 1859,
    maxMmr: 9999,
    img: rl_ssl,
  },
];

const ranks_1v1 = [
  {
    name: 'Unranked',
    shortName: 'UNRKD',
    class: 0,
    minMmr: 0,
    maxMmr: 0,
    img: rl_unranked,
  },
  {
    name: 'Bronze I',
    shortName: 'B I',
    class: 1,
    minMmr: 1,
    maxMmr: 151,
    img: rl_bronze1,
  },
  {
    name: 'Bronze II',
    shortName: 'B II',
    class: 2,
    minMmr: 152,
    maxMmr: 214,
    img: rl_bronze2,
  },
  {
    name: 'Bronze III',
    shortName: 'B III',
    class: 3,
    minMmr: 215,
    maxMmr: 272,
    img: rl_bronze3,
  },
  {
    name: 'Silver I',
    shortName: 'S I',
    class: 4,
    minMmr: 273,
    maxMmr: 334,
    img: rl_silver1,
  },
  {
    name: 'Silver II',
    shortName: 'S II',
    class: 5,
    minMmr: 335,
    maxMmr: 393,
    img: rl_silver2,
  },
  {
    name: 'Silver III',
    shortName: 'S III',
    class: 6,
    minMmr: 394,
    maxMmr: 454,
    img: rl_silver3,
  },
  {
    name: 'Gold I',
    shortName: 'G I',
    class: 7,
    minMmr: 455,
    maxMmr: 514,
    img: rl_gold1,
  },
  {
    name: 'Gold II',
    shortName: 'G II',
    class: 8,
    minMmr: 515,
    maxMmr: 574,
    img: rl_gold2,
  },
  {
    name: 'Gold III',
    shortName: 'G III',
    class: 9,
    minMmr: 575,
    maxMmr: 634,
    img: rl_gold3,
  },
  {
    name: 'Platinum I',
    shortName: 'P I',
    class: 10,
    minMmr: 635,
    maxMmr: 691,
    img: rl_platinum1,
  },
  {
    name: 'Platinum II',
    shortName: 'P II',
    class: 11,
    minMmr: 692,
    maxMmr: 754,
    img: rl_platinum2,
  },
  {
    name: 'Platinum III',
    shortName: 'P III',
    class: 12,
    minMmr: 755,
    maxMmr: 812,
    img: rl_platinum3,
  },
  {
    name: 'Diamond I',
    shortName: 'D I',
    class: 13,
    minMmr: 813,
    maxMmr: 864,
    img: rl_diamond1,
  },
  {
    name: 'Diamond II',
    shortName: 'D II',
    class: 14,
    minMmr: 865,
    maxMmr: 925,
    img: rl_diamond2,
  },
  {
    name: 'Diamond III',
    shortName: 'D III',
    class: 15,
    minMmr: 926,
    maxMmr: 983,
    img: rl_diamond3,
  },
  {
    name: 'Champion I',
    shortName: 'C I',
    class: 16,
    minMmr: 984,
    maxMmr: 1045,
    img: rl_champion1,
  },
  {
    name: 'Champion II',
    shortName: 'C II',
    class: 17,
    minMmr: 1046,
    maxMmr: 1112,
    img: rl_champion2,
  },
  {
    name: 'Champion III',
    shortName: 'C III',
    class: 18,
    minMmr: 1113,
    maxMmr: 1168,
    img: rl_champion3,
  },
  {
    name: 'Grand Champion I',
    shortName: 'GC I',
    class: 19,
    minMmr: 1169,
    maxMmr: 1225,
    img: rl_gm1,
  },
  {
    name: 'Grand Champion II',
    shortName: 'GC II',
    class: 20,
    minMmr: 1226,
    maxMmr: 1284,
    img: rl_gm2,
  },
  {
    name: 'Grand Champion III',
    shortName: 'GC III',
    class: 21,
    minMmr: 1285,
    maxMmr: 1353,
    img: rl_gm3,
  },
  {
    name: 'Supersonic Legend',
    shortName: 'SSL',
    class: 22,
    minMmr: 1353,
    maxMmr: 9999,
    img: rl_ssl,
  },
];

export {
  ranks_3v3,
  ranks_2v2,
  ranks_1v1,
};
