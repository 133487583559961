import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { Trans, useTranslation } from 'react-i18next';

function OrgaDeletedNotification(props) {
  const { notification } = props;
  const {
    data,
  } = notification;

  // const [isOpen, setOpen] = useState(false);

  const { t } = useTranslation(['general']);

  const orgaName = data && data.length !== 0 ? data[0] : '';

  return (
    <div>
      <div>

        <div className="columns">
          <div className="column px-0 is-3 is-flex has-content-centered">
            <i className="fas fa-users-slash is-size-3 has-text-grey" />
          </div>

          <div className="column px-0 is-9 has-text-left">
            <div
              className="has-fullwidth"
            >
              <p
                className="is-size-6 has-line-height-full has-text-white"
              >
                { t('general:orga_deleted') }
              </p>
              {
                  orgaName && (
                    <p className="mt-2 has-text-grey-light has-line-height-full">
                      { `${t('general:orga_deleted_message', { orga: orgaName })}` }
                    </p>
                  )
                }
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

OrgaDeletedNotification.propTypes = {
  notification: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default OrgaDeletedNotification;
