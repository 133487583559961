import React, { useState, useEffect } from 'react';

// Hooks
import { useSelector } from 'react-redux';
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

// Components
import TriangleDisplay from 'components/utils/TriangleDisplay';
import HotscrimpDisplay from './HotscrimpDisplay';
import FilterSelector from './FilterSelector';

const INITIAL_FILTER = {
  name: '',
  rank: {
    game: '',
    mmr: { start: -1 },
    mode: '',
  },
  date: '',
  platform: '',
};

function HotscrimpEntryList() {
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(INITIAL_FILTER);

  const [hotscrimps, setHotscrimps] = useState([]);

  const {
    region,
  } = useSelector((state) => state.session);

  const { isAuthenticated, isLoading: authLoading } = useAuth0();

  const { post } = useAPI();

  const { t } = useTranslation(['general', 'errors', 'hotscrimps']);

  const [status, setStatus] = useState('');

  const loadHotScrimps = async () => {
    try {
      if (authLoading) return;

      setStatus('');
      setLoading(true);

      const data = {
        filter: {
          ...filter,
          region,
        },
      };

      if (!isAuthenticated) {
        const _scrimps = await post('/hotscrimps/public', data);

        setHotscrimps(_scrimps);
        setLoading(false);
        return;
      }

      const _scrimps = await post('/hotscrimps', data);
      setHotscrimps(_scrimps);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setStatus(t('errors:data_load_error'));
      setLoading(false);
    }
  };

  useEffect(() => {
    loadHotScrimps();
  }, [filter, region]);

  const resetFilter = () => {
    setFilter(INITIAL_FILTER);
  };

  return (
    <div className="is-min-fullheight">

      <TriangleDisplay color="primary" />

      <div className="columns is-centered is-mobile is-multiline m-0 px-0 is-min-fullheight">

        <div className="column is-11-desktop is-11-tablet is-11-mobile">

          <div
            className="pt-0"

          >

            <div className="my-6">
              <h1
                className="is-outlined has-text-weight-semibold has-text-primary"
                style={{ fontSize: '65px' }}
              >
                {t('hotscrimps:hotscrimps')}
              </h1>
              <p
                className="is-size-4-desktop is-size-5-tablet is-size-5-mobile has-text-grey"
              >
                {t('hotscrimps:hotscrimps_description_short')}
              </p>
            </div>

            <div className="columns is-multiline">

              <div className="column has-min-width-450 is-flex">
                <div className="is-flex has-content-centered-vertically has-margin-left-auto">
                  <FilterSelector
                    isLoading={loading}
                    filter={filter}
                    setFilter={setFilter}
                    resetFilter={resetFilter}
                  />
                </div>
              </div>

            </div>

          </div>
          {
            loading ? (
              <p>
                { status || t('general:loading') }
              </p>
            ) : (
              <div className="columns is-multiline">
                <div className="column is-12-desktop is-12-tablet is-12-mobile">
                  <HotscrimpDisplay hotscrimps={hotscrimps} />
                </div>

              </div>
            )
          }
        </div>
      </div>
    </div>

  );
}

export default HotscrimpEntryList;
