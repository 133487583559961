import React, { useMemo, useEffect, useState } from 'react';

// Libraries
import PropTypes from 'prop-types';
import { getSelectedGame } from 'constants/games';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Constants
import { MAX_AMOUNT_TAGS } from 'constants/playerboard';

function TagSelector(props) {
  const {
    tags, setTags, game, disabled,
  } = props;

  const { language } = useSelector((state) => state.session);

  const { t } = useTranslation(['general', 'tags']);

  const [isInit, setInit] = useState(true);

  // reset tags when game changes
  useEffect(() => {
    // dont change anything on init, only when game really changes
    if (isInit) {
      setInit(false);
      return;
    }

    if (tags.length !== 0) {
      setTags([]);
    }
  }, [game]);

  const addTag = (newTag) => {
    const isAlreadyIncluded = tags.includes(newTag);

    if (isAlreadyIncluded) {
      const _tags = tags.filter((tag) => tag !== newTag);
      setTags(_tags);
      return;
    }

    if (tags.length >= MAX_AMOUNT_TAGS) return;

    setTags([...tags, newTag]);
  };

  const selectedGame = useMemo(() => getSelectedGame(game), [game]);

  const renderedCategories = useMemo(() => selectedGame.tagsPerCategory.map((categoryData) => {
    const { category, tags: allTags } = categoryData;

    const renderedTags = allTags.map((tag) => {
      const selectIndex = tags.indexOf(tag);
      const isSelected = selectIndex !== -1;

      let color = 'has-background-grey-light';
      if (isSelected) {
        if (selectIndex < 2) {
          color = 'has-background-primary';
        } else {
          color = 'has-background-hotscrimps';
        }
      }

      return (
        <div className="column is-narrow py-1 px-1" key={tag}>
          <button
            type="button"
            onClick={() => addTag(tag)}
            className={`has-no-border br-5 px-4 py-1 has-cursor-pointer has-text-white has-text-weight-semibold is-block ${color}`}
            disabled={disabled}
          >
            { tag }
          </button>
        </div>
      );
    });

    return (
      <div key={category} className="my-2">
        <p className="mb-1 has-text-grey is-size-6">
          { t(`tags:category_${category}`) }
        </p>
        <div className="is-flex has-max-fullwidth columns is-multiline is-mobile">
          { renderedTags }
        </div>
      </div>
    );
  }), [game, tags, disabled, language]);

  if (!game) return null;

  return (
    <div className="">
      <p
        className="has-text-grey is-size-5"
      >
        {t('general:tags')}
      </p>
      { renderedCategories }
    </div>
  );
}

TagSelector.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  setTags: PropTypes.func.isRequired,
  game: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

TagSelector.defaultProps = {
  disabled: false,
};

export default TagSelector;
