import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Assets
import competitiveIcon from 'assets/images/icons/engagement_competitive.svg';
import just4funIcon from 'assets/images/icons/engagement_just4fun.svg';

function EngagementSelector({ engagement, setEngagement }) {
  const { t } = useTranslation(['general']);

  const toggleEngagement = () => {
    if (engagement === 'competitive') {
      setEngagement('just4fun');
      return;
    }

    setEngagement('competitive');
  };

  const isCompetitive = engagement === 'competitive';

  return (
    <div>

      <div className="field is-inline-block">

        <div className="has-text-centered is-inline-block">

          <div className="is-flex has-content-centered">
            <p className="has-text-grey is-size-5">
              {t('general:engagement')}
            </p>
            <i className="fas fa-sync ml-2 has-text-grey br-5" />
          </div>

          <button
            type="button"
            className="button has-no-background has-margin-auto has-fullheight is-inline-block has-text-weight-semibold"
            onClick={toggleEngagement}
          >
            <div>
              <img
                alt=""
                className="has-width-100 has-height-100"
                src={isCompetitive ? competitiveIcon : just4funIcon}
              />
              <p
                className="has-text-grey-lighter"
              >
                { isCompetitive ? t('general:competitive') : t('general:just4fun') }
              </p>
            </div>
          </button>
        </div>

      </div>
    </div>

  );
}

EngagementSelector.propTypes = {
  engagement: PropTypes.string.isRequired,
  setEngagement: PropTypes.func.isRequired,
};

export default EngagementSelector;
