// Rank images
import csgo_silver1 from 'assets/images/games/csgo/csgo_silver1.png';
import csgo_silver2 from 'assets/images/games/csgo/csgo_silver2.png';
import csgo_silver3 from 'assets/images/games/csgo/csgo_silver3.png';
import csgo_silver4 from 'assets/images/games/csgo/csgo_silver4.png';
import csgo_se from 'assets/images/games/csgo/csgo_se.png';
import csgo_sem from 'assets/images/games/csgo/csgo_sem.png';

import csgo_gold1 from 'assets/images/games/csgo/csgo_gold1.png';
import csgo_gold2 from 'assets/images/games/csgo/csgo_gold2.png';
import csgo_gold3 from 'assets/images/games/csgo/csgo_gold3.png';
import csgo_gold4 from 'assets/images/games/csgo/csgo_gold4.png';

import csgo_mg1 from 'assets/images/games/csgo/csgo_mg1.png';
import csgo_mg2 from 'assets/images/games/csgo/csgo_mg2.png';
import csgo_mge from 'assets/images/games/csgo/csgo_mge.png';

import csgo_dmg from 'assets/images/games/csgo/csgo_dmg.png';
import csgo_le from 'assets/images/games/csgo/csgo_le.png';
import csgo_lem from 'assets/images/games/csgo/csgo_lem.png';
import csgo_smfc from 'assets/images/games/csgo/csgo_smfc.png';
import csgo_global from 'assets/images/games/csgo/csgo_global.png';

const basicRanks = [
  {
    name: 'Unranked',
    shortName: 'UNRKD',
    class: 0,
    minMmr: 0,
    maxMmr: 0,
    img: csgo_silver1,
  },
  {
    name: 'Silver I',
    shortName: 'S I',
    class: 1,
    minMmr: 1,
    maxMmr: 1,
    img: csgo_silver1,
  },
  {
    name: 'Silver II',
    shortName: 'S II',
    class: 2,
    minMmr: 2,
    maxMmr: 2,
    img: csgo_silver2,
  },
  {
    name: 'Silver III',
    shortName: 'S III',
    class: 3,
    minMmr: 3,
    maxMmr: 3,
    img: csgo_silver3,
  },
  {
    name: 'Silver IV',
    shortName: 'S IV',
    class: 4,
    minMmr: 4,
    maxMmr: 4,
    img: csgo_silver4,
  },
  {
    name: 'Silver Elite',
    shortName: 'SE',
    class: 5,
    minMmr: 5,
    maxMmr: 5,
    img: csgo_se,
  },
  {
    name: 'Silver Elite Master',
    shortName: 'SEM',
    class: 6,
    minMmr: 6,
    maxMmr: 6,
    img: csgo_sem,
  },
  {
    name: 'Gold Nova I',
    shortName: 'GN I',
    class: 7,
    minMmr: 7,
    maxMmr: 7,
    img: csgo_gold1,
  },
  {
    name: 'Gold Nova II',
    shortName: 'GN II',
    class: 8,
    minMmr: 8,
    maxMmr: 8,
    img: csgo_gold2,
  },
  {
    name: 'Gold Nova III',
    shortName: 'GN III',
    class: 9,
    minMmr: 9,
    maxMmr: 9,
    img: csgo_gold3,
  },
  {
    name: 'Gold Nova Master',
    shortName: 'GNM',
    class: 10,
    minMmr: 10,
    maxMmr: 10,
    img: csgo_gold4,
  },
  {
    name: 'Master Guardian I',
    shortName: 'MG I',
    class: 11,
    minMmr: 11,
    maxMmr: 11,
    img: csgo_mg1,
  },
  {
    name: 'Master Guardian II',
    shortName: 'MG II',
    class: 12,
    minMmr: 12,
    maxMmr: 12,
    img: csgo_mg2,
  },
  {
    name: 'Master Guardian Elite',
    shortName: 'MGE',
    class: 13,
    minMmr: 13,
    maxMmr: 13,
    img: csgo_mge,
  },
  {
    name: 'DMG',
    shortName: 'DMG',
    class: 14,
    minMmr: 14,
    maxMmr: 14,
    img: csgo_dmg,
  },
  {
    name: 'Legendary Eagle',
    shortName: 'LE',
    class: 15,
    minMmr: 15,
    maxMmr: 15,
    img: csgo_le,
  },
  {
    name: 'Legendary Eagle Master',
    shortName: 'LEM',
    class: 16,
    minMmr: 16,
    maxMmr: 16,
    img: csgo_lem,
  },
  {
    name: 'Supreme Master First Class',
    shortName: 'SMFC',
    class: 17,
    minMmr: 17,
    maxMmr: 17,
    img: csgo_smfc,
  },
  {
    name: 'Global Elite',
    shortName: 'GE',
    class: 18,
    minMmr: 18,
    maxMmr: 18,
    img: csgo_global,
  },
];

export default basicRanks;
