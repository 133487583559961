import React, { useState, useEffect } from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';

// HOCs
import { useAuth0 } from '@auth0/auth0-react';

// Actions
import { setPlayerboard, setLoading } from 'store/playerboard';

// Components
import TriangleDisplay from 'components/utils/TriangleDisplay';
import Popup from 'reactjs-popup';
import PlayerboardDisplay from './PlayerboardDisplay';
import FilterSelector from './FilterSelector';

function PlayerBoard() {
  const { region } = useSelector((state) => state.session);

  const {
    entries, filter, hasData, isLoading,
  } = useSelector((state) => state.playerboard);

  const dispatch = useDispatch();

  const { post } = useAPI();

  const { t } = useTranslation(['general', 'errors']);

  const { isAuthenticated, isLoading: authLoading } = useAuth0();

  const [status, setStatus] = useState('');

  const loadPlayerboard = async () => {
    try {
      if (authLoading) return;

      dispatch(setLoading(true));

      const data = {
        filter,
        postedAt: '',
      };

      if (!isAuthenticated) {
        // set region filter if user is not logged in
        data.filter = {
          ...data.filter,
          region,
        };

        const loadedBoard = await post('/playerboard/view', data);

        dispatch(setPlayerboard(loadedBoard));
      } else {
        const loadedBoard = await post('/playerboard', data);

        dispatch(setPlayerboard(loadedBoard));
      }

      dispatch(setLoading(false));
    } catch (e) {
      setStatus(t('errors:data_load_error'));
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    loadPlayerboard();
  }, [region, filter, authLoading, isAuthenticated]);

  if (!hasData || authLoading) {
    return (
      <div className="is-min-fullheight is-flex has-content-centered flex-direction-column">
        <p className="mt-2 has-text-weight-semibold is-size-5">
          { status || t('general:loading') }
        </p>
      </div>
    );
  }

  return (
    <div className="is-min-fullheight pb-6">
      <TriangleDisplay color="primary" />

      <div className="columns is-centered is-mobile is-multiline m-0 px-0 is-min-fullheight">

        <div className="column is-11-desktop is-11-tablet is-11-mobile">

          <div
            className="pt-0"
          >

            <div className="my-6">
              <h1
                className="is-outlined has-text-weight-semibold has-text-primary"
                style={{ fontSize: '65px' }}
              >
                {t('playerboard:playerboard')}
              </h1>
              <p
                className="is-size-4-desktop is-size-5-tablet is-size-5-mobile has-text-grey"
              >
                {t('playerboard:playerboard_description_short')}
              </p>
            </div>

            <div className="columns is-multiline is-flex">

              <div className="column has-min-width-450 is-flex has-text-right">
                <div className="is-flex has-content-centered has-margin-left-auto">
                  <div>
                    <Popup
                      position="top center"
                      keepTooltipInside="#root"
                      repositionOnResize
                      closeOnDocumentClick
                      closeOnEscape
                      className="no-blur"
                      trigger={(
                        <div className="is-flex has-content-centered-vertically has-cursor-pointer dont-select-text mr-5">
                          <i className="fas fa-info-circle is-size-5" />
                        </div>
                    )}
                    >
                      {
                      () => (
                        <div className="">
                          <div className="box has-max-width-600">
                            <div className="is-flex has-content-centered-vertically">
                              <p className="has-text-weight-semibold is-size-5">
                                { t('general:border_color_meaning')}
                              </p>

                            </div>
                            <div className="is-flex mt-4">
                              <i className="fas fa-square-full has-text-playerboard is-size-5" />
                              <p className="ml-3">
                                { t('general:your_entry')}
                              </p>
                            </div>
                            <div className="is-flex mt-4">
                              <i className="fas fa-square-full has-text-competitive is-size-5" />
                              <p className="ml-3">
                                { t('general:competitive')}
                              </p>
                            </div>
                            <div className="is-flex mt-4">
                              <i className="fas fa-square-full has-text-just4fun is-size-5" />
                              <p className="ml-3">
                                { t('general:just4fun')}
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                    }
                    </Popup>
                  </div>
                  <FilterSelector />
                </div>
              </div>

            </div>

          </div>

          <div className="columns is-multiline">

            <div className="column is-12-desktop is-12-tablet is-12-mobile">

              <div className="p-0">
                <PlayerboardDisplay />
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>

  );
}

/*
<div className="is-min-fullheight">
      <div className="columns is-centered is-mobile is-multiline m-0 p-0">
        <div className="column is-2-desktop is-2-tablet is-12-mobile has-min-width-300 p-0 is-min-fullheight">
          <PlayerboardSidebar />
        </div>

        <div className="column p-0">
          <div className="py-6 px-5">
            <p
              className="is-size-4 has-text-weight-semibold mb-4"
            >
              Player Bowl
            </p>
            {
                isLoading ? <p>Loading...</p> : <PlayerboardDisplay />
            }
          </div>
        </div>
      </div>
    </div>
*/

export default PlayerBoard;
