import React from 'react';

// Libaries
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Components
import OrgaCard from 'components/orgas/utils/OrgaCard';

function InvitationorgaDisplay(props) {
  const { orga } = props;
  const {
    createdAt,
    amountMembers,
  } = orga;

  const { t } = useTranslation(['general']);

  const { timezone, language } = useSelector((state) => state.session);

  const renderTime = (joinedAt) => {
    const date = DateTime.fromISO(joinedAt).setZone(timezone).setLocale(language);
    return date.toLocaleString(DateTime.DATE_SIMPLE);
  };

  return (
    <div className="">
      <div className="is-inline-block">
        <OrgaCard orgaData={orga} showDetailsButton={false} />
      </div>

      <p className="mt-4 is-size-7">
        { `${t('general:amount_members')} ${amountMembers}`}
      </p>

      <p className="is-size-7">
        { `${t('general:created_at')} ${renderTime(createdAt)}`}
      </p>
    </div>
  );
}

InvitationorgaDisplay.propTypes = {
  orga: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    cardPic: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    amountMembers: PropTypes.number.isRequired,
  }).isRequired,
};

export default InvitationorgaDisplay;
