import React, { useLayoutEffect } from 'react';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Libraries
import PropTypes from 'prop-types';

// Components
import {
  DiscordContact,
  SteamContact,
  RiotContact,
  BattleNetContact,
  EpicContact,
  TwitterContact,
  TagsggContact,
} from 'components/user/Contacts';

// Constants
import {
  DISCORD,
  STEAM,
  RIOT,
  BATTLENET,
  EPIC,
  TWITTER,
  TAGSGG,
} from 'constants/contacts';

function ContactInfoSelector(props) {
  const {
    game, contactInfo, setContactInfo,
  } = props;

  const { t } = useTranslation(['general']);

  const { contact } = useSelector((state) => state.player.player);

  useLayoutEffect(() => {
    // otherwise, get the ones from the profile
    const list = [
      { type: TAGSGG, id: contact.tagsgg },
      { type: DISCORD, id: contact.discord },
      { type: TWITTER, id: contact.twitter },
    ];

    if (game === 'CSGO') {
      list.push({ type: STEAM, id: contact.steam });
    }

    if (game === 'VAL' || game === 'LOL') {
      list.push({ type: RIOT, id: contact.riot });
    }

    if (game === 'OW') {
      list.push({ type: BATTLENET, id: contact.battlenet });
    }

    if (game === 'RL' || game === 'NL') {
      list.push({ type: EPIC, id: contact.epic });
      list.push({ type: STEAM, id: contact.steam });
    }

    if (game === 'RSS') {
      list.push({ type: STEAM, id: contact.steam });
    }

    // go through all the contact info we have and set it back if the current type still exists in the list
    for (let i = 0; i < contactInfo.length; i++) {
      const { type, id } = contactInfo[i];

      const index = list.findIndex((contactObj) => contactObj.type === type);

      // eslint-disable-next-line
      if(index === -1) continue;

      // if found, set it back
      list[index].id = id;
    }

    setContactInfo(list);
  }, [game]);

  const updateInfo = (type, id) => {
    const info = contactInfo.map((item) => {
      if (item.type !== type) {
        return item;
      }
      return { type, id };
    });

    setContactInfo(info);
  };

  const showContactList = () => contactInfo.map((item) => {
    const { type, id } = item;

    switch (type) {
      case 'tagsgg': {
        return (
          <TagsggContact
            key="tagsgg"
            value={id}
            setValue={updateInfo}
          />
        );
      }
      case 'discord': {
        return (
          <DiscordContact
            key="discord"
            value={id}
            setValue={updateInfo}
          />
        );
      }
      case 'riot': {
        return (
          <RiotContact
            key="riot"
            value={id}
            setValue={updateInfo}
          />
        );
      }
      case 'steam': {
        return (
          <SteamContact
            key="steam"
            value={id}
            setValue={updateInfo}
          />
        );
      }

      case 'battlenet': {
        return (
          <BattleNetContact
            key="battlenet"
            value={id}
            setValue={updateInfo}
          />
        );
      }

      case 'epic': {
        return (
          <EpicContact
            key="epic"
            value={id}
            setValue={updateInfo}
          />
        );
      }

      case 'twitter': {
        return (
          <TwitterContact
            key="twitter"
            value={id}
            setValue={updateInfo}
          />
        );
      }

      default:
        return null;
    }
  });

  return (
    <div>
      <div className="field">
        <div className="has-text-left">
          <h2 className="is-size-5-desktop is-size-5-tablet is-size-5-mobile mb-3 has-text-grey">
            {t('general:contact_info')}
          </h2>
        </div>
        <div className="is-flex flex-direction-column">
          { showContactList() }
        </div>
      </div>
    </div>
  );
}

ContactInfoSelector.propTypes = {
  game: PropTypes.string.isRequired,
  contactInfo: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })).isRequired,
  setContactInfo: PropTypes.func.isRequired,
};

export default ContactInfoSelector;
