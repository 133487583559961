import React, { useState, useEffect, useMemo } from 'react';

// Components
import Dropdown from 'components/utils/Dropdown';

// Constants
import GAMES, { GAME_LIST } from 'constants/games';

// Libraries
import PropTypes from 'prop-types';

function GameSelector(props) {
  const { game, setGame } = props;

  const [activeGame, setActiveGame] = useState(GAMES[0]);

  useEffect(() => {
    if (!game.tag) {
      return;
    }

    const gameSelected = GAMES.find((gameObj) => gameObj.tag === game.tag);

    const preparedRanks = {
      ...gameSelected,
      // set label and value for all ranks
      // label is the name of the game, value is its index in the array
      mmr: gameSelected.mmr.map((item, index) => ({
        label: item,
        value: index,
      })),
    };

    // Nitro League has ranks displayed the other way around
    if (gameSelected.tag === 'NL') {
      preparedRanks.mmr.sort((a, b) => b.value > a.value);
    }

    setActiveGame(preparedRanks);
  }, [game.tag]);

  const setGameActive = (newGame) => {
    const gameSelected = GAMES.find((gameObj) => gameObj.name === newGame.value);

    if (gameSelected === undefined || gameSelected.name === activeGame.name) {
      return;
    }

    const preparedRanks = {
      ...gameSelected,
      // set label and value for all ranks
      // label is the name of the game, value is its index in the array
      mmr: gameSelected.mmr.map((item, index) => ({
        label: item,
        value: index,
      })),
    };

    if (gameSelected.tag === 'NL') {
      preparedRanks.mmr.sort((a, b) => b.value > a.value);
    }

    setActiveGame(preparedRanks);

    // set game tag, reset mmr
    setGame({
      tag: gameSelected.tag,
      mmr: { start: -1, end: -1 },
    });
  };

  const setStartMMR = (start) => {
    const { value } = start;

    setGame({
      ...game,
      mmr: {
        ...game.mmr,
        start: value,
      },
    });
  };

  const setEndMMR = (end) => {
    const { value } = end;

    setGame({
      ...game,
      mmr: {
        ...game.mmr,
        end: value,
      },
    });
  };

  const isStartOptionDisabled = (option) => {
    if (game.mmr.end === -1) return false;

    return option.value > game.mmr.end;
  };

  const isEndOptionDisabled = (option) => {
    if (game.mmr.start === -1) return false;

    return option.value < game.mmr.start;
  };

  const startValue = () => {
    const ranks = activeGame.mmr;

    const rank = ranks.find((item) => item.value === game.mmr.start);

    if (!rank) return 'Wähle einen Rang aus';

    return rank;
  };

  const endValue = () => {
    const ranks = activeGame.mmr;

    const rank = ranks.find((item) => item.value === game.mmr.end);

    if (!rank) return 'Wähle einen Rang aus';

    return rank;
  };

  const showRanks = () => {
    const ranks = activeGame.mmr;

    return (
      <div className="mt-4">
        <p className="has-text-weight-semibold">Welche Ränge sollen gesucht werden?</p>
        <div className="columns is-multiline">

          <div className="column">
            <label className="label">
              Von
            </label>
            <div className="control">
              <Dropdown
                items={ranks}
                onSelect={setEndMMR}
                isOptionDisabled={isEndOptionDisabled}
                value={endValue()}
              />
            </div>
          </div>
          <div className="column">
            <label className="label">
              Bis
            </label>
            <div className="control">
              <Dropdown
                items={ranks}
                onSelect={setStartMMR}
                isOptionDisabled={isStartOptionDisabled}
                value={startValue()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="field">
      <label className="label">
        Nach welchem Spiel suchst du?
      </label>
      <div className="control">
        <Dropdown
          items={GAME_LIST}
          onSelect={setGameActive}
          value={activeGame.name}
        />
      </div>
      { showRanks() }
    </div>
  );
}

GameSelector.propTypes = {
  game: PropTypes.shape({
    tag: PropTypes.string.isRequired,
    mmr: PropTypes.shape({
      start: PropTypes.number.isRequired,
      end: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  setGame: PropTypes.func.isRequired,
};

export default GameSelector;
