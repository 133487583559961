import React, { useEffect, useState, useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useNavigate } from 'react-router-dom';

// Constants
import { TEAM_PROFILE } from 'constants/routes';

// Hooks
import { useSelector } from 'react-redux';

// Components
import TeamBasicsEditor from './TeamBasicsEditor';
import TeamDetailsEditor from './TeamDetailsEditor';
import TeamImageEditor from './TeamImageEditor';

function TeamCreator(props) {
  const { disabled, setDisabled, close } = props;

  const { teams } = useSelector((state) => state.teams);

  const [page, setPage] = useState('start');

  const navigate = useNavigate();

  useEffect(() => {
    if (page === 'done') {
      close();
      const newestTeam = teams[teams.length - 1];
      navigate(TEAM_PROFILE + newestTeam.publicId);
    }
  }, [page]);

  const renderedPage = useMemo(() => {
    switch (page) {
      case 'start':
        return (
          <TeamBasicsEditor
            disabled={disabled}
            setDisabled={setDisabled}
            onDone={() => setPage('details')}
          />
        );
      case 'details':
        return (
          <TeamDetailsEditor
            disabled={disabled}
            setDisabled={setDisabled}
            onDone={() => setPage('image')}
          />
        );

      case 'image':
        return (
          <TeamImageEditor
            disabled={disabled}
            setDisabled={setDisabled}
            onDone={() => setPage('done')}
          />
        );
      default:
        return null;
    }
  }, [page, disabled]);

  return (
    <div className="has-width-900 has-fullheight">
      <div className="columns is-centered" style={{ minHeight: '70vh' }}>
        <div className="column is-12-desktop is-12-tablet is-12-mobile p-0">
          { renderedPage }
        </div>
      </div>
    </div>
  );
}

TeamCreator.propTypes = {
  disabled: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
};

export default TeamCreator;
