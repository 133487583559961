import React, { useState, useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useNotifications } from 'components/Notifications/NotificationProvider';
import useAPI from 'components/hooks/useAPI';

// Components
import NotificationDateDisplay from './NotificationDateDisplay';
import AnnouncementNotification from './AnnouncementNotification';

// Hotscrimp Notification Components
import HotscrimpCreationNotification from './HotscrimpCreationNotification';
import HotscrimpDeletionNotification from './HotscrimpDeletionNotification';
import HotscrimpRequestCreationNotification from './HotscrimpRequestCreationNotification';
import HotscrimpRequestReceivedNotification from './HotscrimpRequestReceivedNotification';
import HotscrimpRequestDeclinedNotification from './HotscrimpRequestDeclinedNotification';
import HotscrimpRequestAcceptedNotification from './HotscrimpRequestAcceptedNotification';
import HotscrimpRequestGotDeclinedNotification from './HotscrimpRequestGotDeclinedNotification';
import HotscrimpRequestGotAcceptedNotification from './HotscrimpRequestGotAcceptedNotification';
import HotscrimpAbortedNotification from './HotscrimpAbortedNotification';
import OpenHotscrimpCancelledNotification from './OpenHotscrimpCancelledNotification';
import ActiveHotscrimpCancelledNotification from './ActiveHotscrimpCancelledNotification';

// Team Notification Components
import TeamMemberLeftNotification from './team/TeamMemberLeftNotification';
import TeamMemberJoinedNotification from './team/TeamMemberJoinedNotification';
import TeamMemberKickedNotification from './team/TeamMemberKickedNotification';
import PlayerKickedFromTeamNotification from './team/PlayerKickedFromTeamNotification';
import TeamDeletedNotification from './team/TeamDeletedNotification';

// Orga Notification Components
import OrgaMemberJoinedNotification from './orga/OrgaMemberJoinedNotification';
import OrgaMemberLeftNotification from './orga/OrgaMemberLeftNotification';
import OrgaMemberKickedNotification from './orga/OrgaMemberKickedNotification';
import PlayerKickedFromOrgaNotification from './orga/PlayerKickedFromOrgaNotification';
import OrgaDeletedNotification from './orga/OrgaDeletedNotification';
import OrgaTeamJoinedNotification from './orga/OrgaTeamJoinedNotification';

// Friends
import FriendRequestReceivedNotification from './FriendRequestReceivedNotification';
import OrgaTeamRemovedNotification from './orga/OrgaTeamRemovedNotification';
import TeamRemovedFromOrgaNotification from './team/TeamRemovedFromOrgaNotification';

// Tournament
import ParticipantKickedFromTournament from './tournament/ParticipantKickedFromTournament';
import ParticipantBannedFromTournament from './tournament/ParticipantBannedFromTournament';
import ParticipantDisqualifiedFromTournament from './tournament/ParticipantDisqualifiedFromTournament';
import ParticipantGotRegisteredForTournament from './tournament/ParticipantGotRegisteredForTournament';
import ParticipantCheckedInForTournament from './tournament/ParticipantCheckedInForTournament';
import TournamentPhaseCheckIn from './tournament/TournamentPhaseCheckIn';
import TournamentPhaseInProgress from './tournament/TournamentPhaseInProgress';
import TournamentPhaseSeeding from './tournament/TournamentPhaseSeeding';
import TournamentPhaseFinished from './tournament/TournamentPhaseFinished';
import TournamentMatchStarted from './tournament/TournamentMatchStarted';
import TournamentAdminCalled from './tournament/TournamentAdminCalled';
import TournamentStaffAdminCallReceived from './tournament/TournamentStaffAdminCallReceived';

function Notification(props) {
  const { notification, closeNotification } = props;
  const {
    read, createdAt, link, message, publicId, title,
  } = notification;

  const { post } = useAPI();
  const { updateNotification, deleteNotification } = useNotifications();

  const [disabled, setDisabled] = useState(false);

  const markAsRead = async () => {
    try {
      if (read) return;

      // first we update the ui, then we update the db
      updateNotification(publicId, { read: true });

      await post(`/notifications/read/${publicId}`);
    } catch (e) {
      console.log(e);
    }
  };

  const { del } = useAPI();

  const _deleteNotification = async () => {
    try {
      deleteNotification(publicId);
      await del(`/notifications/${publicId}`);

      // setDisabled(false);
    } catch (err) {
      console.log(err);
    }
  };

  const renderedNotification = useMemo(() => {
    let notificationComponent = null;

    switch (notification.type) {
      case 'announcement':
        notificationComponent = (
          <AnnouncementNotification
            notification={notification}
          />
        );
        break;
      case 'hotscrimpCreation':
        notificationComponent = (
          <HotscrimpCreationNotification
            notification={notification}
          />
        );
        break;
      case 'hotscrimpDeletion':
        notificationComponent = (
          <HotscrimpDeletionNotification
            notification={notification}
          />
        );
        break;
      case 'hotscrimpRequestCreation':
        notificationComponent = (
          <HotscrimpRequestCreationNotification
            notification={notification}
          />
        );
        break;
      case 'hotscrimpRequestReceived':
        notificationComponent = (
          <HotscrimpRequestReceivedNotification
            notification={notification}
          />
        );
        break;
      case 'hotscrimpRequestDeclined':
        notificationComponent = (
          <HotscrimpRequestDeclinedNotification
            notification={notification}
          />
        );
        break;
      case 'hotscrimpRequestGotDeclined':
        notificationComponent = (
          <HotscrimpRequestGotDeclinedNotification
            notification={notification}
          />
        );
        break;
      case 'hotscrimpRequestAccepted':
        notificationComponent = (
          <HotscrimpRequestAcceptedNotification
            notification={notification}
          />
        );
        break;
      case 'hotscrimpRequestGotAccepted':
        notificationComponent = (
          <HotscrimpRequestGotAcceptedNotification
            notification={notification}
          />
        );
        break;
      case 'hotscrimpAborted':
        notificationComponent = (
          <HotscrimpAbortedNotification
            notification={notification}
          />
        );
        break;

      case 'openHotscrimpCancelled':
        notificationComponent = (
          <OpenHotscrimpCancelledNotification
            notification={notification}
          />
        );
        break;

      case 'activeHotscrimpCancelled':
        notificationComponent = (
          <ActiveHotscrimpCancelledNotification
            notification={notification}
          />
        );
        break;

      // Team notifications
      case 'teamMemberJoined':
        notificationComponent = (
          <TeamMemberJoinedNotification
            notification={notification}
          />
        );
        break;
      case 'teamMemberLeft':
        notificationComponent = (
          <TeamMemberLeftNotification
            notification={notification}
          />
        );
        break;
      case 'teamMemberKicked':
        notificationComponent = (
          <TeamMemberKickedNotification
            notification={notification}
          />
        );
        break;
      case 'playerKickedFromTeam':
        notificationComponent = (
          <PlayerKickedFromTeamNotification
            notification={notification}
          />
        );
        break;
      case 'teamDeleted':
        notificationComponent = (
          <TeamDeletedNotification
            notification={notification}
          />
        );
        break;
      case 'teamRemovedFromOrga':
        notificationComponent = (
          <TeamRemovedFromOrgaNotification
            notification={notification}
          />
        );
        break;

      // Orga Notifications
      case 'orgaMemberJoined':
        notificationComponent = (
          <OrgaMemberJoinedNotification
            notification={notification}
          />
        );
        break;
      case 'orgaMemberLeft':
        notificationComponent = (
          <OrgaMemberLeftNotification
            notification={notification}
          />
        );
        break;
      case 'orgaMemberKicked':
        notificationComponent = (
          <OrgaMemberKickedNotification
            notification={notification}
          />
        );
        break;
      case 'playerKickedFromOrga':
        notificationComponent = (
          <PlayerKickedFromOrgaNotification
            notification={notification}
          />
        );
        break;
      case 'orgaDeleted':
        notificationComponent = (
          <OrgaDeletedNotification
            notification={notification}
          />
        );
        break;
      case 'orgaTeamJoined':
        notificationComponent = (
          <OrgaTeamJoinedNotification
            notification={notification}
          />
        );
        break;
      case 'orgaTeamRemoved':
        notificationComponent = (
          <OrgaTeamRemovedNotification
            notification={notification}
          />
        );
        break;

      case 'friendRequestReceived':
        notificationComponent = (
          <FriendRequestReceivedNotification
            notification={notification}
          />
        );
        break;

      case 'participantKickedFromTournament':
        notificationComponent = (
          <ParticipantKickedFromTournament
            notification={notification}
          />
        );
        break;

      case 'participantBannedFromTournament':
        notificationComponent = (
          <ParticipantBannedFromTournament
            notification={notification}
          />
        );
        break;

      case 'participantDisqualifiedFromTournament':
        notificationComponent = (
          <ParticipantDisqualifiedFromTournament
            notification={notification}
          />
        );
        break;

      case 'participantGotRegisteredForTournament':
        notificationComponent = (
          <ParticipantGotRegisteredForTournament
            notification={notification}
          />
        );
        break;

      case 'participantCheckedInForTournament':
        notificationComponent = (
          <ParticipantCheckedInForTournament
            notification={notification}
          />
        );
        break;

      case 'tournamentPhaseCheckIn':
        notificationComponent = (
          <TournamentPhaseCheckIn
            notification={notification}
          />
        );
        break;

      case 'tournamentPhaseSeeding':
        notificationComponent = (
          <TournamentPhaseSeeding
            notification={notification}
          />
        );
        break;

      case 'tournamentPhaseInProgress':
        notificationComponent = (
          <TournamentPhaseInProgress
            notification={notification}
          />
        );
        break;

      case 'tournamentPhaseFinished':
        notificationComponent = (
          <TournamentPhaseFinished
            notification={notification}
          />
        );
        break;

      case 'tournamentMatchStarted':
        notificationComponent = (
          <TournamentMatchStarted
            notification={notification}
          />
        );
        break;

      case 'tournamentAdminCalled':
        notificationComponent = (
          <TournamentAdminCalled
            notification={notification}
          />
        );
        break;

      case 'tournamentStaffAdminCallReceived':
        notificationComponent = (
          <TournamentStaffAdminCallReceived
            notification={notification}
          />
        );
        break;

      default:
        return null;
    }

    if (!notificationComponent) return null;

    let borderToShow = 'has-border-right-dark-very-thick';

    if (closeNotification) {
      borderToShow = '';
    } else if (!notification.read) {
      borderToShow = 'has-border-right-danger-very-thick';
    }

    return (
      <div
        key={notification.publicId}
        className={`has-border-bottom-dark-blue-light p-4 has-background-dark-blue ${borderToShow}`}
      >

        <div className="is-flex has-content-centered-vertically">
          <NotificationDateDisplay date={notification.createdAt} />

          {
            !closeNotification && (
              <button
                type="button"
                className="cleanButton has-text-grey has-margin-left-auto"
                onClick={() => _deleteNotification(notification.publicId)}
                disabled={disabled}
              >
                <i className="fas fa-times is-size-5" />
              </button>
            )
          }

        </div>

        <div className="">
          <div
            role="button"
            tabIndex={0}
            onKeyDown={markAsRead}
            onClick={markAsRead}
            className="cleanButton has-fullwidth has-text-standard p-0"
          >
            {notificationComponent}
          </div>
        </div>

      </div>

    );
  }, [notification]);

  return renderedNotification;
}

Notification.propTypes = {
  notification: PropTypes.shape({
    read: PropTypes.bool,
    createdAt: PropTypes.string,
    link: PropTypes.string,
    message: PropTypes.string,
    publicId: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  closeNotification: PropTypes.func,
};

export default Notification;
