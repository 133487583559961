import React from 'react';

// HOCs
import { withAuthenticationRequired } from '@auth0/auth0-react';

// not needed anymore, we have DataProvider now
// withAuthentication is now only a wrapper for the auth0 HOC
const withAuthentication = (Component, settings) => {
  function WithAuthentication(props) {
    return <Component {...props} />;
  }

  // const { hasCreatedPlayer } = useSelector((state) => state.player);
  // const navigate = useNavigate();

  /* useLayoutEffect(() => {
      const currentRoute = window.location.pathname;

      if (!hasCreatedPlayer && currentRoute !== ONBOARDING) {
        // navigate to onboarding if player has not been created yet
        navigate(ONBOARDING);
      }
    }, [hasCreatedPlayer]); */

  // not needed anymore, we have DataProvider now for loading data
  // withAuthentication is now only a wrapper for the auth0 HOC

  return withAuthenticationRequired(WithAuthentication, { ...settings });
};

export default withAuthentication;
