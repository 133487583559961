import React, { useMemo } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Assets
import shrimp from 'assets/images/base/icon_no_text.png';

// Components
import OrgaProvider, { useOrgas } from '../OrgaProvider';
import OrgaCreatorPopup from './OrgaCreatorPopup';
import { Link } from 'react-router-dom';
import { ORGA_PAGE } from 'constants/routes';
import CardPicDisplay from 'components/utils/CardPicDisplay';

function OrgaSidebar() {
  const { orgas } = useOrgas();

  const { t } = useTranslation(['general']);

  const renderedOrgas = useMemo(() => orgas.map((orga) => (
    <Link
      className="columns has-border-darkest-light"
      key={orga.publicId}
      to={`${ORGA_PAGE}${orga.publicId}`}
    >
      <div className="column is-narrow">
        <div className="has-width-70 has-height-70 br-5">
          <CardPicDisplay
            url="/images/orgas/card_pictures/"
            img={orga.cardPic}
            quadratic
          />
        </div>
      </div>

      <div className="column">
        <h2 className="has-text-standard is-size-5 has-text-weight-light">
          {orga.name}
        </h2>
      </div>
    </Link>
  )), [orgas]);

  return (
    <div
      className="pt-3 has-background-dark-blue has-text-weight-light is-flex flex-direction-column has-fullheight is-relative"
    >
      <div
        className="is-absolute has-text-centered has-fullwidth"
        style={{ bottom: -50 }}
      >
        <img
          alt="shrimp"
          src={shrimp}
          className=""
          style={{ opacity: 0.2 }}
        />
      </div>

      <div className="has-fullheight p-0">
        <div className="has-text-centered mt-1">
          <h2 className="has-text-standard is-size-5 has-text-weight-light">
            {t('general:orgas')}
          </h2>
        </div>

        <div className="has-text-centered mt-3">
          <OrgaCreatorPopup />
        </div>

        <div
          className="has-overflow-y-auto mt-3"
        >
          <div className="">
            { renderedOrgas }
          </div>
        </div>
      </div>
    </div>
  );
}

function OrgaWrapper() {
  return (
    <OrgaProvider>
      <OrgaSidebar />
    </OrgaProvider>
  );
}

export default OrgaWrapper;
