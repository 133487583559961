import React, { useState } from 'react';

// Libaries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';
import { useOrgaPage } from 'components/orgas/OrgaPage';

// Components
import Popup from 'reactjs-popup';
import OrgaBannerPicUploader from './OrgaBannerUploader';

function OrgaBannerPicUploadPopup() {
  const { publicId, bannerPic, setBannerPic } = useOrgaPage();

  const [disabled, setDisabled] = useState(false);

  const { t } = useTranslation(['general', 'errors']);

  return (
    <div>
      <Popup
        trigger={(
          <button
            type="button"
            className="button has-background-primary grow_small has-text-white"
          >
            <i className="fas fa-image mr-3" />
            {t('general:select_banner')}
          </button>
          )}
        position="top center"
        keepTooltipInside="#root"
        repositionOnResize
        closeOnDocumentClick={!disabled}
        closeOnEscape={false}
        modal
      >
        {
            (close) => (
              <PopupCloser
                close={close}
                bannerPic={bannerPic}
                setBannerPic={setBannerPic}
                disabled={disabled}
                setDisabled={setDisabled}
                publicId={publicId}
              />
            )
           }
      </Popup>
    </div>
  );
}

// Hacky way to get the popup to close without having to change the OrgaImageUploader component
function PopupCloser(props) {
  const {
    close, bannerPic, setBannerPic, disabled, setDisabled, publicId,
  } = props;

  const _setBannerPic = (pic) => {
    setBannerPic(pic);
    close();
  };

  const { t } = useTranslation(['general', 'errors']);

  return (
    <div className="box p-0 has-width-400">
      <div
        className="has-background-primary has-text-centered p-4 pb-6 is-relative br-5"
      >
        <div className="has-text-right">
          <button
            type="button"
            className=" cleanButton has-text-white"
            onClick={close}
            disabled={disabled}
          >
            <i className="fas fa-times" />
          </button>
        </div>
        <p
          className="has-text-weight-semibold is-size-3"
        >
          {t('general:banner')}
        </p>

        <div
          className="br-100 has-background-primary is-absolute is-flex has-content-centered"
          style={{
            left: 'calc(50% - 45px)', width: '90px', height: '85px', zIndex: '999',
          }}
        >
          <i className="fas fa-image is-size-2" />
        </div>

      </div>

      <div
        className="control px-6 py-6 has-background-black-gradient"
      >
        <div className="pt-4">
          <OrgaBannerPicUploader
            publicId={publicId}
            bannerPic={bannerPic}
            setBannerPic={_setBannerPic}
            disabled={disabled}
            setDisabled={setDisabled}
          />
        </div>
        <div className="has-fullwidth has-text-centered">
          <p
            className="has-text-weight-semiboldis-size-7 mt-3 mb-2"
          >
            {`${t('general:ideal_size')}: (1300x200px)`}
          </p>
        </div>

      </div>
    </div>

  );
}

PopupCloser.propTypes = {
  close: PropTypes.func.isRequired,
  bannerPic: PropTypes.string.isRequired,
  setBannerPic: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
  publicId: PropTypes.string.isRequired,
};

export default OrgaBannerPicUploadPopup;
