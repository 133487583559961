import React, { useState, useMemo, useEffect } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useAPI from 'components/hooks/useAPI';

// Constants
import { TEAM_PROFILE } from 'constants/routes';

// Actions
import { setTeams } from 'store/teams';

// Components
import { Link } from 'react-router-dom';
import TeamCard from '../../TeamCard';

function PlayerTeamList() {
  const { timezone, language } = useSelector((state) => state.session);

  const { t } = useTranslation(['general']);

  const { teams } = useSelector((state) => state.teams);

  const { get } = useAPI();

  const dispatch = useDispatch();

  useEffect(() => {
    const loadPlayerTeams = async () => {
      try {
        const _teams = await get('/teams');
        dispatch(setTeams(_teams));
      } catch (e) {
        console.log(e);
      }
    };

    loadPlayerTeams();
  }, []);

  const renderedEntries = useMemo(() => {
    if (teams.length === 0) {
      return (
        <div className="column is-narrow">
          <p>
            {t('general:no_teams_created_or_joined_yet')}
          </p>
        </div>
      );
    }

    return teams.map((teamData, index) => (
      <div
        className="column is-narrow"
        key={index}
      >
        <Link
          to={`${TEAM_PROFILE}${teamData.publicId}`}
          key={index}
        >
          <TeamCard teamData={teamData} />
        </Link>
      </div>
    ));
  }, [teams, language]);

  return (
    <div>
      <p className="has-text-weight-semibold mb-3">
        {t('general:your_teams')}
      </p>
      <div className="mb-6 mt-2 columns has-text-centered-mobile is-centered-mobile is-multiline">
        { renderedEntries }
      </div>

    </div>
  );
}

export default PlayerTeamList;
