import React, { useMemo } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Components
import AsyncNumberEditor from 'components/utils/inputs/AsyncNumberEditor';

const TournamentMaxGroupsEditor = () => {
  const {
    tournamentSettings, setMaxGroups, publicId, currentPhase,
  } = useTournament();
  const { maxGroups, tournamentType } = tournamentSettings;

  const { t } = useTranslation(['general', 'validation']);

  const { post } = useAPI();

  const renderedEditor = useMemo(() => {
    if (tournamentType === 'single_elimination' || tournamentType === 'double_elimination') return null;

    if (currentPhase !== 'draft') {
      return (
        <div className="">
          <p className="is-size-5 has-text-grey mb-2">
            {t('general:max_amount_groups')}
          </p>
          <div className="has-fullwidth">
            <p>
              {maxGroups === -1 ? t('general:unlimited') : maxGroups }
            </p>
          </div>
        </div>
      );
    }

    const saveMaxGroups = async (newMaxGroups) => {
      const data = { maxGroups: newMaxGroups };

      await post(`/tournaments/settings/maxGroups/${publicId}`, data);

      setMaxGroups(newMaxGroups);

      stats.push(['trackEvent', 'Tournament Editor', 'New max groups set']);
    };

    return (
      <AsyncNumberEditor
        label="max_amount_groups"
        number={maxGroups}
        onSave={saveMaxGroups}
      />
    );
  }, [currentPhase, maxGroups, publicId]);

  return renderedEditor;
};

export default TournamentMaxGroupsEditor;
