import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import { getRankFromGameMode } from 'constants/games';

// Hooks
import { useTranslation } from 'react-i18next';

function HotscrimpMmrDisplay(props) {
  const { game, mode, mmr } = props;

  const { t } = useTranslation(['general']);

  const renderedMmr = useMemo(() => {
    const rank = getRankFromGameMode(game.tag, mode, mmr, 'mmr');

    const { img, name: rankName } = rank;

    return (

      <div className="is-flex">

        <div className="has-text-centered">
          <img
            src={img}
            alt={rankName}
            className="has-width-120 has-height-120"
          />
          <p className="is-size-6 has-text-centered">
            { rankName + (game.tag === 'RL' ? (` (${mmr})`) : '') }
          </p>
        </div>

      </div>
    );
  }, [game.tag, mode, mmr]);

  return (
    <div className="has-fullwidth">
      { renderedMmr }
    </div>
  );
}

HotscrimpMmrDisplay.propTypes = {
  game: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }).isRequired,
  mode: PropTypes.string.isRequired,
  mmr: PropTypes.number.isRequired,
};

export default HotscrimpMmrDisplay;
