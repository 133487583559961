import React, { useMemo, useEffect } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';
import { useSelector } from 'react-redux';

// Components
import CardPicDisplay from 'components/utils/CardPicDisplay';
import ContactCopyDisplay from 'components/utils/ContactCopyDisplay';
import DescriptionDisplay from 'components/utils/DescriptionDisplay';
import { Link } from 'react-router-dom';

// Constants
import { PLAYER_PROFILE_DISPLAY, PLAYER_PROFILE } from 'constants/routes';

const TeamPlayerDetailsDisplay = React.forwardRef((props, ref) => {
  const {
    playerId, disabled, setDisabled, close,
  } = props;

  const { player } = useSelector((state) => state.player);

  const { t } = useTranslation(['general', 'tags']);

  const { get } = useAPI();

  const [playerData, setPlayerData] = React.useState(null);

  useEffect(() => {
    if (playerData) return;

    const loadPlayerData = async () => {
      try {
        setDisabled(true);
        const data = await get(`/player/${playerId}`);

        const { player: _playerData, isFriend } = data;

        setPlayerData(_playerData);
        setDisabled(false);
      } catch (e) {
        console.log(e);
        setDisabled(false);
      }
    };

    loadPlayerData();
  }, []);

  const renderedCard = useMemo(() => {
    // const locale = getCurrentLocale();
    // const renderedPostedAt = DateTime.fromISO(postedAt).setZone(timezone).setLocale(locale).toRelative();

    if (!playerData) {
      return (
        <div className="is-fullwidth has-min-width-300 has-max-width-700 is-flex has-content-centered is-in-center">
          <div className="has-fullwidth">

            <div
              className="is-relative has-background-black-gradient br-5 pb-6 is-max-fullheight has-overflow-y-auto"
            >

              <div
                className="has-gradient-primary has-fullwidth is-relative"
                style={{ height: '170px', borderTopRightRadius: '5px', borderTopLeftRadius: '5px' }}
              />

              <div
                className="is-relative p-6 is-flex has-content-centered"
                style={{ marginTop: '-0px' }}
              >
                <p>
                  {t('general:loading')}
                </p>
              </div>

            </div>

          </div>
        </div>
      );
    }

    const {
      name, contact, cardPic, description, nationality, hasProfilePrivate, playerId: publicId,
    } = playerData;

    const linkToProfile = player.playerId === publicId ? PLAYER_PROFILE : `${PLAYER_PROFILE_DISPLAY}${publicId}`;

    const hasContactInfo = Object.keys(contact).map((key) => contact[key]).filter((value) => value).length > 0;

    return (
      <div className="is-fullwidth has-min-width-300 has-max-width-700 is-flex has-content-centered is-in-center">
        <div className="has-fullwidth">

          <div
            className="is-relative has-background-black-gradient br-5 pb-6 is-max-fullheight has-overflow-y-auto"
          >

            <div
              className="has-gradient-primary has-fullwidth is-relative"
              style={{ height: '170px', borderTopRightRadius: '5px', borderTopLeftRadius: '5px' }}
            />

            <div
              className="is-relative"
              style={{ marginTop: '-110px' }}
            >

              <div
                className="columns px-5"
              >
                <div className="column is-narrow">
                  <CardPicDisplay img={cardPic} />
                </div>

                <div className="column">
                  <div className="is-flex has-content-centered-vertically">
                    <p
                      className="has-text-weight-semibold is-size-3-desktop is-size-4-tablet is-size-5-mobile"
                    >
                      {name}
                    </p>
                    <div className="ml-1">
                      <span className={`mx-2 mt-1 br-5 is-size-3 fi fi-${nationality}`} />
                    </div>
                  </div>

                  <div className="is-flex has-content-centered-vertically mt-1">
                    <p
                      className="is-size-5-desktop is-size-5-tablet is-size-6-mobile has-text-weight-semibold has-text-dark mt-1"
                    >
                      {t('general:quickview')}
                    </p>
                  </div>

                  <div className="mt-6">
                    <DescriptionDisplay description={description} />

                    {
                        hasContactInfo && (
                        <div>
                          <p
                            className="has-text-grey"
                          >
                            {t('general:contact_info')}
                          </p>
                          <ContactCopyDisplay contact={contact} />
                        </div>

                        )
                    }

                  </div>

                  {
                    hasProfilePrivate ? (
                      <div>
                        <p
                          className="has-text-grey"
                        >
                          {t('general:this_profile_is_private')}
                        </p>
                      </div>
                    )
                      : (
                        <div>
                          <Link
                            to={linkToProfile}
                            className="button is-primary mt-4"
                          >
                            {t('general:go_to_profile')}
                          </Link>
                        </div>
                      )
                  }
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>

    );
  }, [playerData]);

  return (
    <div ref={ref}>
      { renderedCard }
    </div>
  );
});

TeamPlayerDetailsDisplay.propTypes = {
  playerId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default TeamPlayerDetailsDisplay;
