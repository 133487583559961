import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useOrgaPage } from 'components/orgas/OrgaPage';

function OrgaKickButton(props) {
  const { member } = props;

  const { setMemberToKick } = useOrgaPage();

  const _setMemberToKick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setMemberToKick(member);
  };

  return (
    <div className="is-inline-block">
      <button
        type="button"
        className="button is-danger p-3 is-size-7"
        onClick={_setMemberToKick}
      >
        <i className="fas fa-trash has-text-white" />
      </button>
    </div>
  );
}

OrgaKickButton.propTypes = {
  member: PropTypes.shape({
    playerId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default OrgaKickButton;
