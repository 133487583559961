import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useOrgaPage } from 'components/orgas/OrgaPage';

function OrgaRemoveTeamButton(props) {
  const { team } = props;

  const { setTeamToRemove } = useOrgaPage();

  const _setTeamToRemove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setTeamToRemove(team);
  };

  return (
    <div className="is-inline-block">
      <button
        type="button"
        className="button is-danger p-3 is-size-7"
        onClick={_setTeamToRemove}
      >
        <i className="fas fa-trash has-text-white" />
      </button>
    </div>
  );
}

OrgaRemoveTeamButton.propTypes = {
  team: PropTypes.shape({
    publicId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default OrgaRemoveTeamButton;
