import React, { useMemo, useState } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Components
import PlatformSelector from 'components/utils/PlatformSelector';

function TournamentPlatformSelector() {
  const {
    currentPhase, game, setPlatform, publicId,
  } = useTournament();
  const { platform } = game;

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { t } = useTranslation(['general']);
  const { post } = useAPI();

  const saveNewPlatform = async (newPlatform) => {
    try {
      setDisabled(true);
      setStatus('');
      const data = { platform: newPlatform };

      await post(`/tournaments/platform/${publicId}`, data);

      setPlatform(newPlatform);

      stats.push(['trackEvent', 'Tournament Editor', 'New platform set']);

      setDisabled(false);
    } catch (e) {
      console.log(e);
      setDisabled(false);
      setStatus(t('errors:could_not_save_error'));
    }
  };

  const renderedPlatformSelector = useMemo(() => {
    if (currentPhase !== 'draft') {
      return (
        <div className="">
          <p className="is-size-5 has-text-grey">
            {t('general:platform')}
          </p>
          <div className="has-fullwidth">
            <p>
              {t(`general:${platform}`).toUpperCase()}
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="has-fullwidth">
        <div className="has-fullwidth">
          <PlatformSelector
            game={game.tag}
            platform={platform}
            setPlatform={saveNewPlatform}
            displayType="dropdown"
            disabled={disabled}
          />
        </div>
      </div>

    );
  }, [currentPhase, game, platform, disabled]);

  return (
    <div className="field">
      { renderedPlatformSelector }
      {
        status && (
        <p className="has-text-weight-semibold mt-4">
          { status }
        </p>
        )
      }
    </div>
  );
}

export default TournamentPlatformSelector;
