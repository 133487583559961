import React, { useState, useEffect, useMemo } from 'react';

// Components
import Dropdown from 'components/utils/Dropdown';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Constants
import { AVAILABLE_NATIONALITIES } from 'constants/user';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

function NationalityFilterDropdown({
  nationality, setNationality, disabled,
}) {
  const { language } = useSelector((state) => state.session);

  const { t } = useTranslation(['general']);

  const availableNationalities = useMemo(() => {
    const preparedNationalities = AVAILABLE_NATIONALITIES.map((_nationality) => ({
      label: _nationality.label,
      value: _nationality.value,
    }));

    return [
      { label: t('general:all_nationalities'), value: '' },
      ...preparedNationalities,
    ];
  }, [language]);

  const setNationalityActive = (newNationality) => {
    stats.push(['trackEvent', 'Nationality Filter', 'Nationality filter selected']);
    setNationality(newNationality.value);
  };

  const selectedValue = useMemo(() => availableNationalities.find((_nationality) => _nationality.value === nationality), [nationality, language]);

  // { nationality && <span className={`ml-1 mb-2 fi fi-${nationality}`} />}
  return (
    <div className="field">

      <div className="">

        <label className="label has-text-weight-semibold is-size-7">
          {t('general:nationality')}
        </label>
        <div className="has-fullwidth">

          <Dropdown
            items={availableNationalities}
            onSelect={setNationalityActive}
            value={selectedValue}
            disabled={disabled}
            isSearchable
          />
        </div>
      </div>
    </div>
  );
}

NationalityFilterDropdown.propTypes = {
  nationality: PropTypes.string.isRequired,
  setNationality: PropTypes.func.isRequired,

  disabled: PropTypes.bool,
};

NationalityFilterDropdown.defaultProps = {
  disabled: false,
};

export default NationalityFilterDropdown;
