import React, { useMemo } from 'react';

// Hooks
import { useOrgaPage } from 'components/orgas/OrgaPage';

// Components
import OrgaNameEditor from './OrgaNameEditor';

const OrgaNameHandler = () => {
  const { permissions, name } = useOrgaPage();
  const { canUpdateOrga } = permissions;

  const renderedName = useMemo(() => {
    if (!canUpdateOrga) {
      return (
        <p
          className="is-size-2 has-text-primary has-text-weight-bold"
        >
          { name }
        </p>
      );
    }

    return (
      <OrgaNameEditor />
    );
  }, [name]);

  return renderedName;
};

export default OrgaNameHandler;
