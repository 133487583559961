import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import Dropdown from 'components/utils/Dropdown';

// Constants
import { TOURNAMENT_PHASES } from 'constants/tournaments';

function CurrentPhaseSelector({ currentPhase, setCurrentPhase, disabled }) {
  const { t } = useTranslation(['general']);

  const _setCurrentPhase = (phase) => {
    setCurrentPhase(phase.value);
  };

  const availablePhases = useMemo(() => {
    const phasesWithoutDraft = TOURNAMENT_PHASES.filter((phase) => phase !== 'draft');

    const _phases = phasesWithoutDraft.map((phase) => ({
      value: phase,
      label: t(`general:${phase}`),
    }));

    return [
      { label: t('general:all'), value: '' },
      ..._phases,
    ];
  }, [t]);

  const selectedPhase = useMemo(() => {
    const phase = availablePhases.find((_phase) => _phase.value === currentPhase);
    return phase || { label: t('general:all'), value: '' };
  }, [availablePhases, currentPhase, t]);

  return (
    <div className="field">
      <label className="label has-text-weight-semibold is-size-7">
        {t('general:status')}
      </label>
      <div className="has-fullwidth">
        <Dropdown
          items={availablePhases}
          onSelect={_setCurrentPhase}
          value={selectedPhase.label}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

CurrentPhaseSelector.propTypes = {
  currentPhase: PropTypes.string.isRequired,
  setCurrentPhase: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

CurrentPhaseSelector.defaultProps = {
  disabled: false,
};

export default CurrentPhaseSelector;
