import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import CopyButtonSimple from 'components/utils/CopyButtonSimple';

function HotscrimpIdDisplay(props) {
  const { publicId } = props;

  const { t } = useTranslation(['gemeral']);

  return (

    <div
      className="cleanButton has-text-white has-text-left px-0"
    >

      <div className="is-flex has-content-centered-vertically mt-1">
        <CopyButtonSimple
          text={window.location.href}
          className="cleanButton has-text-white px-0"
        >
          <div className="has-text-left">
            <div className="">
              <p className="is-size-5-desktop is-size-6-touch">
                {t('general:scrim_id')}
              </p>

            </div>
            <div className="is-flex has-content-centered-vertically">
              <p className="mr-3 has-text-primary is-size-5">
                { `SCR-HID-${publicId}` }
              </p>
              <i className="fas fa-copy is-size-6" />
            </div>
          </div>
        </CopyButtonSimple>

      </div>

    </div>

  );
}

HotscrimpIdDisplay.propTypes = {
  publicId: PropTypes.string.isRequired,
};

export default HotscrimpIdDisplay;
