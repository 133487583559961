import React, { useMemo } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';

// Components
import TournamentCardPicDisplay from 'components/tournaments/utils/TournamentCardPicDisplay';
import { sanitizeUrl } from '@braintree/sanitize-url';

function TournamentPartnerDisplay() {
  const { info } = useTournament();

  const { organizer, mainSponsor, sponsors } = info;

  const { t } = useTranslation(['general']);

  const renderedOrganizer = useMemo(() => {
    if (!organizer) return null;

    const { name, cardPic, website } = organizer;

    let preparedUrl = website ? sanitizeUrl(website) : '#';
    if (!preparedUrl.startsWith('http')) preparedUrl = `https://${preparedUrl}`;

    if (!name && !cardPic) return null;

    if (name && cardPic) {
      return (
        <div className="is-flex has-content-end my-3 flex-direction-column">
          <p className="mb-3 has-text-grey">
            {t('general:organizer')}
          </p>
          <a
            href={preparedUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="is-block has-max-width-150"
          >
            <TournamentCardPicDisplay cardPic={cardPic} />
            <p className="has-text-centered mt-2">
              {name}
            </p>
          </a>

        </div>
      );
    }

    if (cardPic && !name) {
      <div className="is-flex has-content-end my-3 flex-direction-column">
        <p className="mb-3 has-text-grey">
          {t('general:organizer')}
        </p>
        {
            cardPic && (
            <a
              href={preparedUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="is-block has-max-width-150"
            >
              <TournamentCardPicDisplay cardPic={cardPic} />
            </a>
            )
        }
      </div>;
    }

    return (
      <div className="is-flex has-content-end my-3 flex-direction-column">
        <p className="mb-3 has-text-grey">
          {t('general:organizer')}
        </p>
        <a
          href={preparedUrl}
          target="_blank"
          rel="noopener noreferrer"
          className=""
        >
          <p className="has-text-centered">
            {name}
          </p>
        </a>
      </div>
    );
  }, [organizer]);

  const renderedMainSponsor = useMemo(() => {
    if (!mainSponsor) return null;

    const { name, cardPic, website } = mainSponsor;

    let preparedUrl = website ? sanitizeUrl(website) : '#';
    if (!preparedUrl.startsWith('http')) preparedUrl = `https://${preparedUrl}`;

    if (!name && !cardPic) return null;

    if (name && cardPic) {
      return (
        <div className="is-flex has-content-end my-3 flex-direction-column">
          <p className="mb-3 has-text-grey">
            {t('general:main_sponsor')}
          </p>
          <a
            href={preparedUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="is-block has-max-width-150"
          >
            <TournamentCardPicDisplay cardPic={cardPic} />
            <p className="has-text-centered mt-2">
              {name}
            </p>
          </a>
        </div>
      );
    }

    if (cardPic && !name) {
      <div className="is-flex has-content-end my-3 flex-direction-column">
        <p className="mb-3 has-text-grey">
          {t('general:main_sponsor')}
        </p>
        {
            cardPic && (
            <a
              href={preparedUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="is-block has-max-width-150"
              aria-label={t('general:main_sponsor')}
            >
              <TournamentCardPicDisplay cardPic={cardPic} />
            </a>
            )
        }
      </div>;
    }

    return (
      <div className="is-flex has-content-end my-3 flex-direction-column">
        <p className="mb-3 has-text-grey">
          {t('general:main_sponsor')}
        </p>
        <a
          href={preparedUrl}
          target="_blank"
          rel="noopener noreferrer"
          className=""
        >
          <p className="has-text-centered">
            {name}
          </p>
        </a>
      </div>
    );
  }, [mainSponsor]);

  const renderedSponsors = useMemo(() => sponsors.map((sponsor, index) => {
    if (!sponsor) return null;

    const { name, cardPic, website } = sponsor;

    let preparedUrl = website ? sanitizeUrl(website) : '#';
    if (!preparedUrl.startsWith('http')) preparedUrl = `https://${preparedUrl}`;

    if (!name && !cardPic) return null;

    if (name && cardPic) {
      return (
        <div className="is-flex has-content-end my-3 flex-direction-column" key={index}>
          <p className="mb-3 has-text-grey">
            {t('general:presented_by')}
          </p>
          <a
            href={preparedUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="is-block has-max-width-150"
          >
            <TournamentCardPicDisplay cardPic={cardPic} />
            <p className="has-text-centered mt-2">
              {name}
            </p>
          </a>
        </div>
      );
    }

    if (cardPic && !name) {
      <div className="is-flex has-content-end my-3 flex-direction-column">
        <p className="mb-3 has-text-grey">
          {t('general:presented_by')}
        </p>
        {
            cardPic && (
            <a
              href={preparedUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="is-block has-max-width-150"
              aria-label={t('general:presented_by')}
            >
              <TournamentCardPicDisplay cardPic={cardPic} />
            </a>
            )
        }
      </div>;
    }

    return (
      <div className="is-flex has-content-end my-3 flex-direction-column">
        <p className="mb-3 has-text-grey">
          {t('general:presented_by')}
        </p>
        <a
          href={preparedUrl}
          target="_blank"
          rel="noopener noreferrer"
          className=""
        >
          <p className="has-text-centered">
            {name}
          </p>
        </a>
      </div>
    );
  }), [sponsors]);

  return (
    <div className="is-flex flex-direction-column">
      { renderedOrganizer }
      { renderedMainSponsor }
      { renderedSponsors }
    </div>
  );
}

export default TournamentPartnerDisplay;
