import React, { useState, useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

// Hooks
import { useTranslation } from 'react-i18next';
import { useTeamProfile } from 'components/Teams/TeamProfile';
import useAPI from 'components/hooks/useAPI';

// Components
import Dropdown from 'components/utils/Dropdown';

// Constants
import { TEAM_ROLES } from 'constants/team';

function SetTeamRoleDropdown(props) {
  const { playerIndex, disabled, setDisabled } = props;

  const { t } = useTranslation(['general']);
  const { post } = useAPI();

  const {
    permissions, members, publicId,

    setTeamRole,
  } = useTeamProfile();
  const { canSetTeamRole } = permissions;

  const { playerId, teamRole } = members[playerIndex];

  const _setTeamRole = async (_role) => {
    try {
      setDisabled(true);

      const newRole = _role.value;

      const data = {
        teamId: publicId,
        playerId,
        teamRole: newRole,
      };

      await post('/teams/members/teamRole', data);

      setTeamRole(playerId, newRole);

      stats.push(['trackEvent', 'Team Profile', 'Set team role']);

      setDisabled(false);
    } catch (e) {
      console.log(e);
      setDisabled(false);
    }
  };

  const availableTeamRoles = useMemo(() => {
    const roles = TEAM_ROLES.map((role) => ({
      value: role.type,
      label: role.type ? t(`general:${role.type}`) : t('general:no_team_role'),
    }));

    return roles;
  }, [TEAM_ROLES]);

  const selectedRole = useMemo(() => {
    const role = availableTeamRoles.find((_role) => _role.value === teamRole);

    return role || { value: '', label: '' };
  }, [teamRole, availableTeamRoles]);

  if (!canSetTeamRole) return null;

  return (
    <div>
      <p
        className=""
      >
        {t('general:team_role')}
      </p>
      <div className="columns is-multiline">
        <div className="column px-0">
          <Dropdown
            items={availableTeamRoles}
            onSelect={_setTeamRole}
            value={selectedRole}
            disabled={disabled}
          />
        </div>

        <div className="column px-0 is-3 has-min-width-200 is-flex has-content-centered">
          {
            selectedRole.value && (
              <p className="is-size-7 has-border-grey p-1 br-5">
                { selectedRole.label }
              </p>
            )
          }
        </div>
      </div>
    </div>

  );
}

SetTeamRoleDropdown.propTypes = {
  playerIndex: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
};

export default SetTeamRoleDropdown;
