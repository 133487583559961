import React, { useMemo, useState } from 'react';

// Libaries
import { DateTime } from 'luxon';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Components
import EditTournamentRulePopup from './EditTournamentRulePopup';

// Constants
import { MAX_TOURNAMENT_RULES } from 'constants/tournaments';

function TournamentRulesEditor() {
  const { publicId, info, setInfo } = useTournament();
  const { rules } = info;

  const { t } = useTranslation(['general', 'errors']);
  const { timezone, language } = useSelector((state) => state.session);

  const [currentRules, setCurrentRules] = useState(rules);
  const [ruleToEdit, setRuleToEdit] = useState();

  const addRule = () => {
    const newRule = {
      name: '',
      content: '',
      publicId: '',
      updatedAt: '',
    };

    // setCurrentRules([...currentRules, newRule]);
    setRuleToEdit(newRule);
  };

  const updateRule = (rule) => {
    const oldRule = currentRules.find((r) => r.publicId === rule.publicId);

    if (oldRule) {
      const index = currentRules.indexOf(oldRule);
      const newRules = [...currentRules];
      newRules[index] = rule;
      setCurrentRules(newRules);
      setRuleToEdit();
      setInfo({ ...info, rules: newRules });
      return;
    }

    setCurrentRules([...currentRules, rule]);
    setRuleToEdit();
    setInfo({ ...info, rules: [...currentRules, rule] });
  };

  const removeRule = (rule) => {
    const newRules = currentRules.filter((r) => r.publicId !== rule.publicId);

    setCurrentRules(newRules);
    setRuleToEdit();
    setInfo({ ...info, rules: newRules });
  };

  const reset = () => {
    setRuleToEdit();
  };

  const renderedRules = useMemo(() => currentRules.map((rule) => {
    const renderedTime = DateTime.fromISO(rule.updatedAt).setZone(timezone).setLocale(language).toLocaleString(DateTime.DATETIME_SHORT);

    return (
      <div key={rule.publicId} className="column is-narrow">
        <button
          type="button"
          className="button grow_small is-block has-background-dark has-text-grey has-border-grey has-fullheight py-3 br-10 has-width-200 has-text-left"
          onClick={() => {
            setRuleToEdit(rule);
          }}
        >
          <p className="is-size-5 has-text-weight-bold break-word">
            {rule.name}
          </p>

          <p className="is-size-7 has-margin-top-auto">
            {t('general:updated_at')}
            {' '}
            {renderedTime}
          </p>
        </button>

      </div>
    );
  }), [currentRules, timezone, language]);

  return (
    <div>
      <EditTournamentRulePopup
        rule={ruleToEdit}
        onSave={updateRule}
        reset={reset}
        remove={removeRule}
      />

      <div className="columns is-multiline">

        { renderedRules }

        {
        (rules.length < MAX_TOURNAMENT_RULES) && (
          <div className="column is-narrow">
            <button
              type="button"
              className="button grow_small is-block has-no-background has-text-grey has-border-dashed-grey has-fullheight has-text-centered py-3 br-10 has-width-200"
              onClick={addRule}
            >
              <i className="fas fa-plus-circle" />
              <p>
                {t('general:add_rule')}
              </p>
            </button>
          </div>

        )
      }
      </div>

    </div>
  );
}

export default TournamentRulesEditor;
