import React from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Constants
import { MAX_TOURNAMENT_STREAMING_CLIP_LENGTH } from 'constants/tournaments';
import AsyncInputEditor from 'components/utils/inputs/AsyncInputEditor';

function TournamentStreamingClipEditor() {
  const { streaming, setStreaming, publicId } = useTournament();
  const { clip } = streaming;

  const { t } = useTranslation(['general', 'validation']);

  const { post } = useAPI();

  const saveTwitchClip = async (newTwitchClip) => {
    // extract the clip name from the URL if it's a full URL
    let clipName = newTwitchClip.split('/').pop();
    clipName = clipName.split('?')[0];

    // remove whitespace before and after
    clipName = clipName.trim();

    const data = { clip: clipName };

    await post(`/tournaments/streaming/clip/${publicId}`, data);
    setStreaming({ clip: clipName });

    stats.push(['trackEvent', 'Tournament Editor', 'Twitch Clip set']);
  };

  return (
    <AsyncInputEditor
      label="twitch_clip_name"
      text={clip}
      onSave={saveTwitchClip}
      maxLength={MAX_TOURNAMENT_STREAMING_CLIP_LENGTH}
    />
  );
}

export default TournamentStreamingClipEditor;
