import React, { useMemo, useState } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';

// Components
import GameSelector from 'components/utils/games/GameSelector';
import ModeSelector from 'components/utils/games/ModeSelector';
import MMrRangeWithToggle from 'components/utils/games/MmrRangeWithToggle';

function TournamentGameSettingsEditor() {
  const {
    game, setGame, currentPhase, publicId,
  } = useTournament();

  const [newGame, setNewGame] = useState(game);
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { post } = useAPI();
  const { t } = useTranslation(['general']);

  const saveGame = async () => {
    try {
      setDisabled(true);
      setStatus('');
      // game without platform
      const data = {
        game: {
          tag: newGame.tag,
          mode: newGame.mode,
          mmrRange: newGame.mmrRange,
        },
      };

      await post(`/tournaments/game/${publicId}`, data);

      setGame(newGame);

      stats.push(['trackEvent', 'Tournament Editor', 'New game set']);

      setDisabled(false);
    } catch (e) {
      console.log(e);
      setDisabled(false);
      setStatus(t('errors:could_not_save_error'));
    }
  };

  const setGameTag = (tag) => {
    setNewGame({
      ...newGame, tag, mode: '', mmrRange: { start: -1, end: -1 },
    });
  };

  const setMode = (mode) => {
    setNewGame({ ...newGame, mode, mmrRange: { start: -1, end: -1 } });
  };

  const setMmrRange = (mmrRange) => {
    setNewGame({ ...newGame, mmrRange });
  };

  const showSaveButton = useMemo(() => {
    if (game.tag === newGame.tag
      && game.mode === newGame.mode
      && game.mmrRange.start === newGame.mmrRange.start
      && game.mmrRange.end === newGame.mmrRange.end) {
      return false;
    }

    return true;
  }, [game, newGame]);

  const isValid = useMemo(() => {
    if (!newGame.tag || !newGame.mode) return false;

    return true;
  }, [newGame]);

  return (
    <div className="">
      <div className="columns">
        <div className="column is-narrow">
          <GameSelector
            game={newGame.tag}
            setGame={setGameTag}
            filter={['NL']}
            showGames="short"
            disabled={currentPhase !== 'draft'}
          />

          <div className="mt-5">
            <ModeSelector
              rank={{ mode: newGame.mode, game: newGame.tag }}
              setMode={setMode}
              disabled={currentPhase !== 'draft'}
            />
          </div>
        </div>

        <div className="column is-offset-1">

          <MMrRangeWithToggle
            rank={{ mode: newGame.mode, game: newGame.tag, mmr: newGame.mmrRange }}
            setMmr={setMmrRange}
            disabled={currentPhase !== 'draft'}
          />
        </div>

      </div>

      {
        showSaveButton && (
        <button
          type="button"
          className="button is-primary mt-4 has-text-weight-bold"
          onClick={saveGame}
          disabled={disabled || !isValid}
        >
          {t('general:save')}
        </button>
        )
      }

      {
        status && (
        <p className="has-text-weight-semibold mt-4">
          { status }
        </p>
        )
      }

    </div>
  );
}

export default TournamentGameSettingsEditor;
