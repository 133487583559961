import React, { useState, useEffect, useContext } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

const INITIAL_FILTER = {
  name: '',
  // engagement: '',
  nationality: '',
};

export const PlayerListContext = React.createContext({});

function PlayerListProvider(props) {
  const { children } = props;

  const { isAuthenticated } = useAuth0();

  const { t } = useTranslation(['errors']);

  const [showLoadMoreBtn, setShowLoadMoreBtn] = useState(false);
  const [status, setStatus] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [players, _setPlayers] = useState([]);
  const [filter, setNewFilter] = useState(INITIAL_FILTER);

  const { post } = useAPI();

  const setPlayers = (entries) => {
    _setPlayers(entries);
  };

  const addPlayers = (entries) => {
    _setPlayers([...players, ...entries]);
  };

  const loadPlayers = async () => {
    try {
      setIsLoading(true);

      const data = {
        filter,
        postedAt: '',
      };

      let loadedPlayers = [];

      if (!isAuthenticated) {
        loadedPlayers = await post('/player/list/public', data);
      } else {
        loadedPlayers = await post('/player/list', data);
      }

      if (loadedPlayers.length === 20) {
        setShowLoadMoreBtn(true);
      } else {
        setShowLoadMoreBtn(false);
      }

      setPlayers(loadedPlayers);

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setStatus(t('errors:data_load_error'));
      setIsLoading(false);
    }
  };

  const loadMorePlayers = async () => {
    try {
      setIsLoading(true);

      const data = {
        filter,
        postedAt: '',
      };

      if (players.length !== 0) {
        data.postedAt = players[players.length - 1].createdAt;
      }

      let loadedPlayers = [];

      if (!isAuthenticated) {
        loadedPlayers = await post('/player/list/public', data);
      } else {
        loadedPlayers = await post('/player/list', data);
      }

      if (loadedPlayers.length === 20) {
        setShowLoadMoreBtn(true);
      } else {
        setShowLoadMoreBtn(false);
      }

      if (players.length === 0) {
        setPlayers(loadedPlayers);
      } else {
        addPlayers(loadedPlayers);
      }

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setStatus(t('errors:data_load_error'));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadPlayers();
  }, [filter]);

  const setLoading = (_isLoading) => {
    setIsLoading(_isLoading);
  };

  const setNameFilter = (name) => {
    setNewFilter({
      ...filter,
      name,
    });
  };

  const setNationalityFilter = (nationality) => {
    setNewFilter({
      ...filter,
      nationality,
    });
  };

  const setEngagementFilter = (engagement) => {
    setNewFilter({
      ...filter,
      engagement,
    });
  };

  const resetFilter = () => {
    setNewFilter(INITIAL_FILTER);
  };

  const clearState = () => {
    setNewFilter(INITIAL_FILTER);
    _setPlayers([]);
  };

  return (
    <PlayerListContext.Provider
      value={{
        isLoading,
        players,
        filter,
        showLoadMoreBtn,
        status,

        setLoading,
        setPlayers,
        addPlayers,
        setNameFilter,
        setNationalityFilter,
        setEngagementFilter,
        resetFilter,
        loadMorePlayers,

        clearState,
      }}
    >
      {children}
    </PlayerListContext.Provider>
  );
}

export const usePlayerList = () => {
  const data = useContext(PlayerListContext);

  return data;
};

PlayerListProvider.propTypes = {
  children: PropTypes.node,
};

PlayerListProvider.defaultProps = {
  children: {},
};

export default PlayerListProvider;
