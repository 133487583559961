import React, { useMemo } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';

// Constants
import { TOURNAMENT_PHASES } from 'constants/tournaments';
import TournamentEditorHandler from './TournamentEditorHandler';

const TournamentStatusDisplay = () => {
  const { currentPhase } = useTournament();

  const { t } = useTranslation(['general']);

  const renderedStatus = useMemo(() => {
    if (!currentPhase) return null;

    const renderRegisterButton = (phaseIndex) => {
      let style = '';
      let text = '';

      const buttonPhase = 'registration_open';
      const indexButtonPhase = TOURNAMENT_PHASES.indexOf(buttonPhase);

      if (currentPhase === buttonPhase) {
        style = 'has-background-playerboard has-text-weight-bold';
        text = t('general:registration_open');
      } else if (phaseIndex > indexButtonPhase) {
        style = 'has-background-primary has-text-weight-bold';
        text = t('general:registered');
      } else {
        style = 'has-background-grey-darker has-text-grey';
        text = t('general:registration');
      }

      return (
        <div className="column">
          <div className={`button py-3 has-fullheight has-fullwidth ${style}`}>
            {text}
          </div>
        </div>
      );
    };

    const renderCheckInButton = (phaseIndex) => {
      let style = '';

      const buttonPhase = 'check_in';
      const indexButtonPhase = TOURNAMENT_PHASES.indexOf(buttonPhase);

      if (currentPhase === buttonPhase) {
        style = 'has-background-playerboard has-text-weight-bold';
      } else if (phaseIndex > indexButtonPhase) {
        style = 'has-background-primary has-text-weight-bold';
      } else {
        style = 'has-background-grey-darker has-text-grey';
      }

      return (
        <div className="column">
          <div className={`button py-3 has-fullheight has-fullwidth ${style}`}>
            {t(`general:${buttonPhase}`)}
          </div>
        </div>
      );
    };

    const renderSeedingButton = (phaseIndex) => {
      let style = '';

      const buttonPhase = 'seeding';
      const indexButtonPhase = TOURNAMENT_PHASES.indexOf(buttonPhase);

      if (currentPhase === buttonPhase) {
        style = 'has-background-playerboard has-text-weight-bold';
      } else if (phaseIndex > indexButtonPhase) {
        style = 'has-background-primary has-text-weight-bold';
      } else {
        style = 'has-background-grey-darker has-text-grey';
      }

      return (
        <div className="column">
          <div className={`button py-3 has-fullheight has-fullwidth ${style}`}>
            {t(`general:${buttonPhase}`)}
          </div>
        </div>
      );
    };

    const renderUpcomingButton = (phaseIndex) => {
      let style = '';

      const buttonPhase = 'upcoming';
      const indexButtonPhase = TOURNAMENT_PHASES.indexOf(buttonPhase);

      if (currentPhase === buttonPhase) {
        style = 'has-background-playerboard has-text-weight-bold';
      } else if (phaseIndex > indexButtonPhase) {
        style = 'has-background-primary has-text-weight-bold';
      } else {
        style = 'has-background-grey-darker has-text-grey';
      }

      return (
        <div className="column">
          <div className={`button py-3 has-fullheight has-fullwidth ${style}`}>
            {t(`general:${buttonPhase}`)}
          </div>
        </div>
      );
    };

    const renderInProgressButton = (phaseIndex) => {
      let style = '';
      let text = '';

      const buttonPhase = 'in_progress';
      const indexButtonPhase = TOURNAMENT_PHASES.indexOf(buttonPhase);

      if (currentPhase === buttonPhase) {
        style = 'has-background-playerboard has-text-weight-bold';
        text = t('general:in_progress');
      } else if (phaseIndex > indexButtonPhase) {
        style = 'has-background-primary has-text-weight-bold';
        text = t('general:in_progress');
      } else {
        style = 'has-background-grey-darker has-text-grey';
        text = t('general:started');
      }

      return (
        <div className="column">
          <div className={`button py-3 has-fullheight has-fullwidth ${style}`}>
            {text}
          </div>
        </div>
      );
    };

    const renderCompletedButton = (phaseIndex) => {
      let style = '';

      const buttonPhase = 'finished';

      if (currentPhase === buttonPhase) {
        style = 'has-background-primary has-text-weight-bold';
      } else {
        style = 'has-background-grey-darker has-text-grey';
      }

      return (
        <div className="column">
          <div className={`button py-3 has-fullheight has-fullwidth ${style}`}>
            {t(`general:${buttonPhase}`)}
          </div>
        </div>
      );
    };

    const phaseIndex = TOURNAMENT_PHASES.indexOf(currentPhase);

    return (
      <div className="columns">
        <TournamentEditorHandler />
        {renderUpcomingButton(phaseIndex)}
        {renderRegisterButton(phaseIndex)}
        {renderCheckInButton(phaseIndex)}
        {renderSeedingButton(phaseIndex)}
        {renderInProgressButton(phaseIndex)}
        {renderCompletedButton(phaseIndex)}
      </div>
    );
  }, [currentPhase, t]);

  return renderedStatus;
};

export default TournamentStatusDisplay;
