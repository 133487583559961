import React from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useOrgaPage } from 'components/orgas/OrgaPage';

// Components
import RegionSelectorEditor from 'components/utils/RegionSelectorEditor';

function OrgaRegionSelector() {
  const { publicId, region, setRegion } = useOrgaPage();

  const { post } = useAPI();

  const saveRegion = async (newRegion) => {
    try {
      const data = { region: newRegion };

      await post(`/orgas/region/${publicId}`, data);

      setRegion(newRegion);

      stats.push(['trackEvent', 'Orga Profile', 'New region set']);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="">
      <RegionSelectorEditor region={region} setRegion={saveRegion} />
    </div>
  );
}

export default OrgaRegionSelector;
