import React, { useEffect, useMemo } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import useAPI from 'components/hooks/useAPI';

// Components
import CardPicDisplay from 'components/utils/CardPicDisplay';
import GameOutcomeSelector from './GameOutcomeSelector';
import MatchAdminPanelHandler from './MatchAdminPanelHandler';
import AdminAlertButton from './AdminAlertButton';

function TournamentMatchHandler() {
  const { isAuthenticated } = useAuth0();

  const {
    publicId, sidebarContent, participant, participantPermissions, currentPhase,
    setSidebarContent,
  } = useTournament();

  const { canSetGameOutcome } = participantPermissions;
  const { data: match, type } = sidebarContent;

  const {
    participants, status, stage, result,
  } = match;

  const { t } = useTranslation(['genral']);
  const { post } = useAPI();

  const showAdminAlertButton = useMemo(() => {
    if (currentPhase !== 'in_progress') return false;

    if (match.status !== 'pending') return false;

    if (!canSetGameOutcome) return false;

    if (!participant.publicId) return false;

    const isInMatch = participants.some((p) => p.publicId === participant.publicId);

    if (!isInMatch) return false;

    return true;
  }, [participants, participant, currentPhase, match.status, canSetGameOutcome]);

  // on first render for each match, load the match data
  useEffect(() => {
    const loadMatchData = async () => {
      try {
        if (type !== 'match') return;

        const data = {
          publicId: match.publicId,
        };

        let content;

        if (isAuthenticated) {
          content = await post(`/tournaments/matches/load/${publicId}`, data);
        } else {
          content = await post(`/tournaments/matches/load/public/${publicId}`, data);
        }

        setSidebarContent({
          type: 'match',
          data: content,
        });
      } catch (e) {
        console.log(e);
      }
    };

    loadMatchData();
  }, [match.publicId, publicId, type, isAuthenticated]);

  const getParticipantData = () => {
    if (!participants) return [];

    let _participants = participants;

    if (_participants.length === 0) {
      _participants = [
        {
          cardPic: '',
          name: '',
          isDisqualified: false,
        },
        {
          cardPic: '',
          name: '',
          isDisqualified: false,
        },
      ];
    } else if (_participants.length === 1) {
      _participants.push({
        cardPic: '',
        name: '',
        isDisqualified: false,
      });
    }

    return _participants;
  };

  const renderParticipants = () => {
    const participantData = getParticipantData();

    return participantData.map((participant, index) => {
      const { gameResults } = result;

      // count how often the index appears in the gameResults array
      const wins = gameResults.filter((_result) => _result.participant === participant.publicId).length;

      return (
        <div
          key={participant.publicId}
          className={`columns py-2 ${index === 0 ? 'has-border-bottom-dark-blue-light' : ''}`}
        >
          <div className="column is-narrow has-width-40 p-0 is-flex has-content-centered">
            <CardPicDisplay
              img={participant.cardPic}
              url={participant.participantType === 'team' ? '/images/teams/card_pictures/' : undefined}
            />
          </div>
          <div className="column p-0 is-flex has-content-centered-vertically px-3 has-overflow-x-hidden">
            <p className={`is-size-5 ${participant.isDisqualified ? 'has-text-danger' : ''}`}>
              {`${participant.name}`}
            </p>
            {
            participant.isDisqualified
            && (
            <p className="has-text-danger ml-2 mt-1 is-size-7">
                {`(${t('general:disqualified')})`}
            </p>
            )
            }
          </div>
          <div className="column is-narrow has-text-right is-flex has-content-centered">
            <p
              className="has-text-weight-bold is-size-3 has-background-dark-blue px-3"
            >
              {wins}
            </p>
          </div>
        </div>
      );
    });
  };

  const renderWinner = () => {
    if (!result || !result.winner) return null;

    const participantData = participants.find((_participant) => _participant.publicId === result.winner);

    if (!participantData) return null;

    return (
      <div className="my-3 px-3">
        <p
          className="has-text-weight-bold has-text-centered mb-2"
        >
          {t('general:winner')}
        </p>
        <div className="columns">
          <div className="column is-narrow has-width-40 p-0 is-flex has-content-centered">
            <CardPicDisplay
              img={participantData.cardPic}
              url={participantData.participantType === 'team' ? '/images/teams/card_pictures/' : undefined}
            />
          </div>
          <div className="column p-0 is-flex has-content-centered-vertically px-3 has-overflow-x-hidden">
            <p className="has-text-weight-bold">
              {`${participantData.name}`}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderGameOutcomeSelector = () => {
    if (
      !canSetGameOutcome
      || currentPhase !== 'in_progress'
    ) return null;

    return (
      <GameOutcomeSelector
        match={match}
      />
    );
  };

  const renderStatus = () => {
    if (!status) return null;

    let color = 'grey-light';

    if (status === 'pending') color = 'playerboard';
    if (status === 'finished') color = 'danger';

    const displayText = status === 'pending' ? 'in_progress' : status;

    return (
      <div className="is-flex has-content-centered">
        <p className={`has-text-weight-bold br-100 px-5 has-background-${color}`}>
          {`${t(`general:${displayText}`)}`}
        </p>
      </div>
    );
  };

  if (!match || !match.result) {
    return (
      <div className="has-fullheight is-flex has-content-centered">
        <p>
          {`${t('general:loading')}`}
        </p>
      </div>
    );
  }

  return (
    <div className="pb-3">

      <div className="px-5 pb-3 has-text-centered">
        <p className="is-size-5 has-text-white">
          {`${t('general:match')} #${match.match}`}
        </p>
        <p
          className="is-size-6 has-text-grey-light mt-1"
        >
          {t(`general:${stage}`)}
        </p>
      </div>

      <div className="">
        { renderStatus() }
      </div>

      {
        showAdminAlertButton && (
          <div className="mt-5">
            <AdminAlertButton
              tournamentId={publicId}
              matchId={match.publicId}
            />
          </div>
        )
      }

      <div className="mt-5 has-background-darkest px-3">
        { renderParticipants() }
      </div>

      <div className="px-3">
        { renderGameOutcomeSelector() }
      </div>

      { renderWinner() }

      <MatchAdminPanelHandler />
    </div>
  );
}

export default TournamentMatchHandler;
