import React, { useEffect, useState } from 'react';

// Hooks
import { useLocation } from 'react-router-dom';

// Constants
import { IMPRINT, LANDING, PRIVACY } from 'constants/routes';

function DesktopOnlyNotice() {
  // TODO: dont show on landing or privacy
  const location = useLocation();
  const { pathname } = location;

  const [hideNotice, setHideNotice] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    let path = pathname;

    if (path !== '/') {
      // remove trailing slash if there is one
      path = path.replace(/\/$/, '');
    }

    const urls = [LANDING, PRIVACY, IMPRINT];

    if (urls.includes(path)) {
      setHideNotice(true);
      return;
    }

    setHideNotice(false);
  }, [pathname]);

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));

    return () => {
      window.removeEventListener('resize', () => setWidth(window.innerWidth));
    };
  }, []);

  if (width >= 1024 || hideNotice) return null;

  return (
    <div
      className="is-fixed has-fullwidth is-fullheight has-z-index-max is-flex"
    >
      <div className="has-background-primary has-fullwidth has-fullheight is-flex flex-direction-column has-content-centered px-5 py-6 has-text-centered">
        <p
          className="is-size-5 has-text-weight-semibold"
        >
          Scrimps is Desktop ONLY for the moment!
        </p>
        <p
          className="mt-2"
        >
          Please visit scrimps.wtf on a computer!
        </p>
      </div>
    </div>
  );
}

export default DesktopOnlyNotice;
