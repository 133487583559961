import React, {
  useState, useEffect, useContext, useMemo,
} from 'react';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

// Constants
import { HOT_SCRIMPS, TEAM_PROFILE } from 'constants/routes';

// Components
import CardPicDisplay from 'components/utils/CardPicDisplay';
import HotscrimpBreadcrumbs from 'components/HotScrimps/utils/display/HotscrimpsBreadcrumbs';
import HotscrimpInfoDisplay from 'components/HotScrimps/utils/display/HotscrimpInfoDisplay';
import HotscrimpDescriptionDisplay from 'components/HotScrimps/utils/display/HotscrimpDescriptionDisplay';
import HotscrimpMmrDisplay from 'components/HotScrimps/utils/display/HotscrimpMmrDisplay';
import HotscrimpPlayerDisplay from 'components/HotScrimps/utils/display/HotscrimpPlayerDisplay';
import HotscrimpIdDisplay from 'components/HotScrimps/utils/display/HotscrimpIdDisplay';
import HotscrimpRequestPopup from './HotscrimpRequestPopup';
import HotscrimpAuthPopup from './HotscrimpAuthPopup';
import { hasPermission } from 'constants/team';
import HotscrimpStatusHandler from './HotscrimpStatusHandler';
import SharePopup from 'components/utils/SharePopup';
import { getRankFromGameMode, getSelectedGame } from 'constants/games';
import { DateTime } from 'luxon';

const INITIAL_PERMISSIONS = {
  canCancelHotscrimp: false,
};

const HotscrimpContext = React.createContext({});

/**
 * ´The page to display a hotscrimp in all states, with all dll details
 *
 * @returns {React.Component} - The component
 */
function HotscrimpsDisplayPage() {
  const [hotscrimpTeam, setHotscrimpTeam] = useState({
    publicId: '',
    name: '',
    cardPic: '',
    averageMmrPlayers: -1,
    players: [],
  });
  const [requestTeam, setRequestTeam] = useState({
    publicId: '',
    name: '',
    cardPic: '',
    averageMmrPlayers: -1,
    players: [],
  });

  const [description, setDescription] = useState('');
  const [platform, setPlatform] = useState('');
  const [game, setGame] = useState();
  const [region, setRegion] = useState();
  const [startDate, setStartDate] = useState();
  const [mode, setMode] = useState('');

  const [mmrRange, setMmrRange] = useState({ start: -1, end: -1 });
  const [publicId, setPublicId] = useState('');
  const [status, setStatus] = useState('open');

  const [permissions, setPermissions] = useState(INITIAL_PERMISSIONS);

  const [showRequestPopup, setShowRequestPopup] = useState(false);
  const [showNoAuthPopup, setShowNoAuthPopup] = useState(false);

  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const { get } = useAPI();

  const navigate = useNavigate();
  const params = useParams();

  const { t } = useTranslation(['general', 'errors']);
  const { isAuthenticated, isLoading: isAuthLoading } = useAuth0();

  const { playerId } = useSelector((state) => state.player.player);

  const { timezone, language } = useSelector((state) => state.session);

  const hotscrimpShareText = useMemo(() => {
    if (!hotscrimpTeam.publicId) return '';

    const _game = getSelectedGame(game.tag);
    const _mode = getRankFromGameMode(game.tag, mode, hotscrimpTeam.averageMmrPlayers, 'mmr');

    const date = DateTime.fromISO(startDate, { zone: timezone, locale: language });

    return t('hotscrimps:hotscrimp_share_text', {
      team: hotscrimpTeam.name,
      date: date.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY),
      game: _game.name,
      mode,
      mmr: _mode.name,
      link: `${window.location.href}`,
    });
  }, [hotscrimpTeam]);

  const evaluatePermissions = (role, team) => {
    if (!role) return;

    const _canCancelHotscrimp = hasPermission(role, 'cancelHotscrimp');

    // TODO: team is either hotscrimpTeam or requestTeam, if new permissions are added, check if the player is in the correct team for the action

    setPermissions({
      canCancelHotscrimp: _canCancelHotscrimp,
    });
  };

  useEffect(() => {
    if (isAuthLoading || params.id === publicId) return;

    setError('');

    const loadHotscrimp = async () => {
      try {
        setLoading(true);

        const { id } = params;

        if (!id) {
          navigate(HOT_SCRIMPS);
        }

        let _hotscrimp;
        let _role;
        let _team;

        if (!isAuthenticated) {
          const response = await get(`/hotscrimps/public/${id}`);
          _hotscrimp = response.hotscrimp;
          _role = response.role;
          _team = response.team;
        } else {
          const response = await get(`/hotscrimps/${id}`);
          _hotscrimp = response.hotscrimp;
          _role = response.role;
          _team = response.team;
        }

        evaluatePermissions(_role, _team);

        setPublicId(_hotscrimp.publicId);
        setDescription(_hotscrimp.description);
        setHotscrimpTeam((defaults) => ({
          ...defaults,
          ..._hotscrimp.hotscrimpTeam,
        }));
        setGame((defaults) => ({
          ...defaults,
          ..._hotscrimp.game,
        }));
        setMmrRange((defaults) => ({
          ...defaults,
          ..._hotscrimp.mmrRange,
        }));
        setRegion(_hotscrimp.region);
        setMode(_hotscrimp.mode);
        setPlatform(_hotscrimp.platform);

        setStatus(_hotscrimp.status);

        if (_hotscrimp.requestTeam) {
          setRequestTeam((defaults) => ({
            ...defaults,
            ..._hotscrimp.requestTeam,
          }));
        }

        setStartDate(_hotscrimp.startDate);

        setLoading(false);
      } catch (e) {
        setError(t('errors:data_load_error'));
      }
    };

    loadHotscrimp();
  }, [params, publicId, isAuthenticated, isAuthLoading]);

  const openRequestPopup = () => {
    if (!isAuthenticated) {
      setShowNoAuthPopup(true);
      return;
    }

    if (status !== 'open') return;

    setShowRequestPopup(true);
  };

  const renderedBoxesForPlayers = useMemo(() => {
    const boxes = hotscrimpTeam.players.map((_, index) => (
      <button
        key={index}
        type="button"
        className="br-5 cleanButton has-border-dotted-grey has-fullwidth has-text-grey is-size-2 has-height-190 my-5"
        onClick={openRequestPopup}
      >
        ?
      </button>
    ));

    return boxes;
  }, [hotscrimpTeam.players]);

  if (isLoading) {
    return (
      <div className="is-min-fullheight is-flex has-content-centered flex-direction-column">
        <p className="mt-2 has-text-weight-semibold is-size-5">
          { error || t('general:loading') }
        </p>
      </div>
    );
  }

  return (
    <HotscrimpContext.Provider value={{
      region,
      startDate,
      game,
      description,
      mode,
      hotscrimpTeam,
      requestTeam,
      platform,
      mmrRange,
      publicId: params.id,
      permissions,
      showRequestPopup,
      showNoAuthPopup,
      status,

      setShowRequestPopup,
      setShowNoAuthPopup,
    }}
    >
      <div className="is-min-fullheight p-0 m-0 is-relative">
        <HotscrimpRequestPopup />
        <HotscrimpAuthPopup />

        <div className="has-background-primary is-size-5 has-fullwidth py-2 px-6">
          <HotscrimpBreadcrumbs game={game} />
        </div>

        <div className="has-fullwidth">

          <div className="p-6">

            <div className="mb-4">
              <SharePopup
                text={hotscrimpShareText}
                url={window.location.href}
              />
            </div>

            <div className="columns is-multiline">
              <div className="column is-narrow p-0 has-min-width-350 has-border-right-grey mr-5">
                <div className="mr-5">
                  <HotscrimpIdDisplay publicId={publicId} />

                </div>

                <div>
                  <HotscrimpStatusHandler />
                </div>
              </div>

              <div className="column">
                <HotscrimpInfoDisplay
                  startDate={startDate}
                  region={region}
                  platform={platform}
                  game={game}
                  mode={mode}
                  mmrRange={mmrRange}
                  publicId={params.id}
                />
              </div>
            </div>

            <div className="mb-6">
              <HotscrimpDescriptionDisplay description={description} />
            </div>

            <div className="has-text-centered mb-4">
              <p
                className="has-text-grey-lighter is-size-5"
              >
                {t('general:team')}
              </p>
            </div>

            <div className="columns is-centered is-multiline">

              <div className="column is-3 is-flex has-min-width-200">
                <div className="has-fullwidth has-fullheight is-flex flex-direction-column has-content-centered">

                  <div className="has-max-width-200">
                    <Link
                      to={`${TEAM_PROFILE}${hotscrimpTeam.publicId}`}
                    >
                      <CardPicDisplay
                        url="/images/teams/card_pictures/"
                        img={hotscrimpTeam.cardPic}
                        quadratic
                      />
                    </Link>
                  </div>

                  <div className="has-text-centered mt-4">
                    <Link
                      to={`${TEAM_PROFILE}${hotscrimpTeam.publicId}`}
                    >
                      <p
                        className="is-size-5"
                      >
                        {hotscrimpTeam.name}
                      </p>
                    </Link>

                  </div>
                </div>
              </div>

              <div className="column is-1 is-flex has-content-centered has-min-width-200">
                <p
                  className="is-size-2"
                >
                  {t('general:vs')}
                </p>
              </div>

              {
                status === 'open' ? (

                  <div className="column is-3 is-flex has-min-width-200">
                    <div className="has-fullwidth has-fullheight is-flex flex-direction-column has-content-centered">

                      <div className="has-width-200 flex-grow">
                        <button
                          type="button"
                          className="br-5 cleanButton has-border-dotted-grey has-fullwidth has-text-grey is-size-5 has-text-standard has-fullheight p-3"
                          onClick={openRequestPopup}
                        >
                          { status === 'open'
                            ? t('general:select_team')
                            : t('hotscrimps:hotscrimp_closed')}
                        </button>
                      </div>

                      <div className="has-text-centered mt-4">
                        <button
                          type="button"
                          className="cleanButton has-text-grey is-size-5 has-text-standard has-fullheight"
                          onClick={openRequestPopup}
                        >
                          { status === 'open'
                            ? t('general:select_team')
                            : t('hotscrimps:hotscrimp_closed')}
                        </button>

                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="column is-3 is-flex flex-direction-column has-content-centered has-min-width-200">

                    <div className="has-fullwidth has-fullheight has-max-width-200">
                      <Link
                        to={`${TEAM_PROFILE}${requestTeam.publicId}`}
                      >
                        <CardPicDisplay
                          url="/images/teams/card_pictures/"
                          img={requestTeam.cardPic}
                          quadratic
                        />
                      </Link>
                    </div>

                    <div className="has-text-centered mt-4">
                      <Link
                        to={`${TEAM_PROFILE}${requestTeam.publicId}`}
                      >
                        <p
                          className="is-size-5"
                        >
                          {requestTeam.name}
                        </p>
                      </Link>
                    </div>

                  </div>
                )
              }

            </div>

            <div className="my-6">
              <div className="has-text-centered mb-4">
                <p
                  className="has-text-grey-lighter is-size-5"
                >
                  {t('general:average_mmr')}
                </p>
              </div>
              <div className="columns is-centered is-mutliline">

                <div className="column is-3 has-min-width-200">

                  <div className="is-flex has-content-centered">
                    <div className="">
                      <HotscrimpMmrDisplay
                        game={game}
                        mode={mode}
                        mmr={hotscrimpTeam.averageMmrPlayers}
                      />
                    </div>
                  </div>
                </div>

                <div className="column is-1 has-min-width-200" />

                <div className="column is-3 has-min-width-200">

                  {
                    status !== 'open' ? (
                      <div className="is-flex has-fullwidth">
                        <div className="">
                          <HotscrimpMmrDisplay
                            game={game}
                            mode={mode}
                            mmr={requestTeam.averageMmrPlayers}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="has-fullwidth has-fullheight is-flex flex-direction-column has-content-centered">
                        <div className="has-width-200 flex-grow">
                          <button
                            type="button"
                            className="br-5 cleanButton has-border-dotted-grey has-fullwidth has-text-grey is-size-3 has-text-standard has-fullheight p-3"
                            onClick={openRequestPopup}
                          >
                            ?
                          </button>
                        </div>
                      </div>
                    )
                  }
                </div>

              </div>
            </div>

            <div className="">
              <div className="has-text-centered mb-4">
                <p className="has-text-grey-lighter is-size-5">
                  {t('general:players')}
                </p>
              </div>
              <div className="columns is-centered is-multiline">

                <div className="column is-4">

                  <div className="">
                    <HotscrimpPlayerDisplay players={hotscrimpTeam.players} />
                  </div>
                </div>

                <div className="column is-2 is-flex has-content-centered" />
                { status !== 'open' ? (
                  <div className="column is-narrow">
                    <HotscrimpPlayerDisplay players={requestTeam.players} />
                  </div>
                ) : (
                  <div className="column is-4">
                    { renderedBoxesForPlayers }
                  </div>
                )}
              </div>
            </div>

          </div>
        </div>
      </div>
    </HotscrimpContext.Provider>
  );
}

export const useHotscrimp = () => {
  const data = useContext(HotscrimpContext);

  return data;
};

export default HotscrimpsDisplayPage;
