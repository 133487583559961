import React, { useEffect, useMemo, useState } from 'react';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useTournament } from 'components/tournaments/TournamentPage';
import CardPicDisplay from 'components/utils/CardPicDisplay';
import AdminAlertSocketHandler from './AdminAlertSocketHandler';

function AdminAlertHandler() {
  const [adminAlerts, setAdminAlerts] = useState([]);

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { sidebarContent, publicId, setSidebarContent } = useTournament();
  const { t } = useTranslation(['general', 'errors']);
  const { get, post } = useAPI();

  const loadAdminAlerts = async () => {
    try {
      setDisabled(true);
      setStatus('');

      const _adminAlerts = await get(`/tournaments/admin-alerts/${publicId}`);

      setAdminAlerts(_adminAlerts);
      setDisabled(false);
    } catch (e) {
      setDisabled(false);
      setStatus(t('errors:load_data_error'));
    }
  };

  useEffect(() => {
    loadAdminAlerts();
  }, [publicId]);

  const solveAlert = async (alertId) => {
    try {
      setDisabled(true);
      setStatus('');

      const data = {
        alertId,
      };

      await post(`/tournaments/admin-alerts/solve/${publicId}`, data);

      setDisabled(false);
    } catch (e) {
      setDisabled(false);
      setStatus(t('errors:save_data_error'));
    }
  };

  const renderedAdminAlerts = useMemo(() => {
    const goToAdminAlert = (matchId) => {
      if (sidebarContent.type === 'match' && sidebarContent.data.publicId === matchId) return;

      setSidebarContent({
        type: 'match',
        data: { publicId: matchId },
      });
    };

    return adminAlerts.map((adminAlert, index) => {
      const {
        publicId: alertId, matchId, participant, solvedBy, createdAt,
      } = adminAlert;

      return (
        <div key={index} className="box my-2">
          <div className="columns">
            <div className="column">
              <p className="has-text-weight-bold mb-3">
                {`${t('general:dispute')} ${adminAlerts.length - index}`}
              </p>
            </div>
            <div className="column has-text-right">
              {
                solvedBy.name
                  ? (
                    <div className="is-flex mb-3 has-text-right has-content-centered-vertically has-content-right">

                      <p className="">
                        {`${t('general:solved_by')} ${solvedBy.name}`}
                      </p>
                      <i className="fas fa-check-circle ml-2 has-text-success" />
                    </div>
                  ) : (
                    <button
                      type="button"
                      onClick={() => solveAlert(alertId)}
                      className="button is-success has-height-30 is-small has-margin-left-auto"
                      disabled={disabled}
                    >
                      {t('general:mark_as_solved')}
                    </button>
                  )
              }
            </div>
          </div>
          <div
            className="columns has-height-48"
          >
            <div className="column is-narrow has-width-30 p-0 is-flex has-content-centered">
              <CardPicDisplay
                img={participant.cardPic}
                url={participant.participantType === 'team' ? '/images/teams/card_pictures/' : undefined}
              />
            </div>
            <div className="column py-0 is-flex has-content-centered-vertically px-3">
              <p>
                {`${participant.name}`}
              </p>
            </div>
            <div className="column py-0 is-flex has-content-centered-vertically">
              <button
                type="button"
                onClick={() => goToAdminAlert(matchId)}
                className="button is-primary has-height-30 is-small has-margin-left-auto"
              >
                {t('general:go_to_match')}
              </button>
            </div>
          </div>

        </div>
      );
    });
  }, [sidebarContent, adminAlerts, t]);

  return (
    <div>
      <AdminAlertSocketHandler setAlerts={setAdminAlerts} />
      {renderedAdminAlerts}

      {status && (
        <p>
          {status}
        </p>
      )}
    </div>
  );
}

export default AdminAlertHandler;
