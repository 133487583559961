import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';

// Components
import AsyncImageUploadPopup from 'components/utils/images/AsyncImageUploadPopup';

function TournamentBannerPicEditor() {
  const { publicId, info, setInfo } = useTournament();
  const { bannerPic } = info;

  const { t } = useTranslation(['general', 'errors']);
  const { post } = useAPI();

  const saveBannerPic = async (bannerPicObj) => {
    const formData = new FormData();
    formData.append('bannerPic', bannerPicObj);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
    };

    const urlToPic = await post(`/tournaments/bannerPic/${publicId}`, formData, config);
    setInfo({ ...info, bannerPic: urlToPic });
  };

  return (
    <AsyncImageUploadPopup
      label="banner"
      bestSize="1300x200"
      width={260}
      height={40}
      onSave={saveBannerPic}
      imgPath={bannerPic ? `/tournaments/banner_pictures/${bannerPic}` : ''}
    />
  );
}

export default TournamentBannerPicEditor;
