import React, { useState, useEffect, useMemo } from 'react';

// Libraries
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function HotscrimpDeletionNotification(props) {
  const { notification } = props;
  const {
    data,
  } = notification;

  const [isOpen, setOpen] = useState(false);

  const { timezone, language } = useSelector((state) => state.session);

  const { t } = useTranslation(['general']);

  const preparedHotscrimpDate = useMemo(() => {
    if (!data || data.length === 0) return null;

    const hotscrimpDate = data[1];

    const date = DateTime.fromJSDate(new Date(hotscrimpDate)).setZone(timezone).setLocale(language);
    return date.toLocaleString(DateTime.DATETIME_MED);
  }, [timezone, language, data]);

  if (!data || data.length === 0) return null;

  const teamName = data[0] || '';

  return (

    <div>

      <button
        type="button"
        onClick={() => setOpen(!isOpen)}
        className="cleanButton has-fullwidth has-text-standard p-0"
      >

        <div className="columns">
          <div className="column px-0 is-3 is-flex has-content-centered">
            <i className="fas fa-trash is-size-3 has-text-grey" />
          </div>

          <div className="column px-0 is-9 has-text-left">
            <div
              className="has-fullwidth"
            >
              <p
                className="is-size-6 has-line-height-full has-text-white"
              >
                { t('hotscrimps:hotscrimp_deleted') }
              </p>
              <p className="mt-2 has-text-grey-light has-line-height-full">
                {
                t(
                  'hotscrimps:hotscrimp_for_team_at_date_got_deleted',
                  { team: teamName, date: preparedHotscrimpDate },
                )
                }
              </p>
            </div>

          </div>
        </div>

      </button>

      {
          isOpen && (
            <div className="mx-1 mt-2 has-text-white has-text-left">
              <div>
                <p
                  className="has-text-weight-semibold"
                >
                  { t('hotscrimps:hotscrimp_deleted') }
                </p>
              </div>
              <div>
                <p className="is-size-7 has-text-grey-light mt-2">
                  { t(
                    'hotscrimps:hotscrimp_for_team_at_date_got_deleted',
                    { team: teamName, date: preparedHotscrimpDate },
                  )}
                </p>
              </div>

            </div>
          )
        }
    </div>

  );
}

HotscrimpDeletionNotification.propTypes = {
  notification: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default HotscrimpDeletionNotification;
