import React, { useMemo, useState } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Actions
import { setNationality } from 'store/player';

// Components
import Dropdown from 'components/utils/Dropdown';

// Constants
import { AVAILABLE_NATIONALITIES } from 'constants/user';
import Popup from 'reactjs-popup';

function NationalitySelector() {
  const dispatch = useDispatch();
  const { player } = useSelector((state) => state.player);
  const { nationality } = player;

  const [status, setStatus] = useState('');
  const [disabled, setDisabled] = useState(false);

  const { post } = useAPI();

  const { t } = useTranslation(['general']);

  const _setNationality = async (_nationality) => {
    try {
      setStatus('');
      setDisabled(true);

      const data = {
        nationality: _nationality.value,
      };

      await post('/player/nationality', data);

      dispatch(setNationality(_nationality.value));

      stats.push(['trackEvent', 'Player Profile', 'Nationality set']);

      setDisabled(false);
    } catch (e) {
      setDisabled(false);
      setStatus(t('errors:save_nationality_error'));
    }
  };

  const currentValue = useMemo(() => {
    const foundNationality = AVAILABLE_NATIONALITIES.find((_nationality) => _nationality.value === nationality);

    return foundNationality || { label: '', value: '' };
  }, [nationality]);

  return (
    <Popup
      trigger={(
        <div className="has-cursor-pointer">
          <span className={`mx-2 mt-0 br-5 is-size-3 fi fi-${currentValue.value}`} />
          <i className="fas fa-cog mt-2" />
        </div>
      )}
      position="top center"
      keepTooltipInside="#root"
      repositionOnResize
      closeOnDocumentClick={!disabled}
      closeOnEscape={!disabled}
      modal
      lockScroll
    >
      {
        (close) => (
          <div className="box p-0 is-max-fullheight">
            <div
              className="has-background-primary has-text-centered p-4 pb-6 is-relative br-5"
            >
              <div className="has-text-right">
                <button
                  type="button"
                  className=" cleanButton has-text-white"
                  onClick={close}
                  disabled={disabled}
                >
                  <i className="fas fa-times" />
                </button>
              </div>
              <p
                className="has-text-weight-semibold is-size-3 mt-"
              >
                {t('general:nationality')}
              </p>

              <div
                className="br-100 has-background-primary is-absolute is-flex has-content-centered"
                style={{
                  left: 'calc(50% - 45px)', width: '90px', height: '85px',
                }}
              >
                <span className={`is-size-2 br-5 fi fi-${currentValue.value}`} />
              </div>

            </div>

            <div className="p-5 has-background-black-gradient">
              <label className="label pt-5">
                {t('general:nationality')}
              </label>
              <div className="control">

                <div className="is-flex has-fullwidth has-min-width-250">
                  <span className={`mr-2 fi fi-${currentValue.value}`} />
                  <Dropdown
                    items={AVAILABLE_NATIONALITIES}
                    onSelect={_setNationality}
                    value={currentValue}
                    disabled={disabled}
                    className="has-fullwidth"
                    isSearchable
                  />

                </div>
                <p className=" mt-2">
                  { status }
                </p>
              </div>
            </div>
          </div>
        )
        }
    </Popup>

  );
}

export default NationalitySelector;
