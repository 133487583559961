import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import { TEAM_PROFILE } from 'constants/routes';

// Components
import { Link } from 'react-router-dom';
import DataDeletedNotification from '../../DataDeletedNotification';

function TeamMemberJoinedNotification(props) {
  const { notification } = props;
  const {
    teamId, playerName, teamName, dataDeleted,
  } = notification;

  const { t } = useTranslation(['general']);

  if (dataDeleted) {
    return (
      <DataDeletedNotification
        icon="fas fa-person-circle-plus"
        title={t('general:team_member_joined_but_team_deleted')}
        message={t('general:team_member_joined_but_team_deleted_message')}
      />
    );
  }

  return (
    <Link
      to={`${TEAM_PROFILE}${teamId}`}
    >
      <div>
        <div>

          <div className="columns">
            <div className="column px-0 is-3 is-flex has-content-centered">
              <i className="fas fa-person-circle-plus is-size-3 has-text-grey" />
            </div>

            <div className="column px-0 is-9 has-text-left">
              <div
                className="has-fullwidth"
              >
                <p
                  className="is-size-6 has-line-height-full has-text-white"
                >
                  { t('general:team_member_joined') }
                </p>
                {
                  playerName && teamName && (
                    <p className="mt-2 has-text-grey-light has-line-height-full">
                      { t('general:team_member_joined_message', { team: teamName, member: playerName }) }
                    </p>
                  )
                }
              </div>

            </div>
          </div>
        </div>
      </div>

    </Link>
  );
}

TeamMemberJoinedNotification.propTypes = {
  notification: PropTypes.shape({
    teamId: PropTypes.string,
    playerName: PropTypes.string,
    teamName: PropTypes.string,
    dataDeleted: PropTypes.bool,
  }).isRequired,
};

export default TeamMemberJoinedNotification;
