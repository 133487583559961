import React, { useMemo } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import ShareCopyButton from '../ShareCopyButton';

function TwitterButton(props) {
  const { text, url } = props;

  const _text = useMemo(() => encodeURIComponent(text), [text]);

  const { t } = useTranslation(['general']);

  return (
    <ShareCopyButton
      url={`https://twitter.com/intent/tweet?text=${_text}`}
      icon="fa-brands fa-x-twitter"
      indicator={t('general:share_twitter')}
      isLink
    />
  );
}

export default TwitterButton;
