import React, { useMemo } from 'react';

// Hooks
import { useOrgaPage } from 'components/orgas/OrgaPage';
import { useTranslation } from 'react-i18next';

// Components
import SocialLinkDisplay from 'components/utils/SocialLinkDisplay';

import OrgaContactEditor from './OrgaContactEditor';

const OrgaContactHandler = () => {
  const {
    permissions, contact,
  } = useOrgaPage();

  const { canUpdateOrga } = permissions;

  const { t } = useTranslation(['general']);

  const renderedContact = useMemo(() => {
    if (!contact) return null;

    // check if contact has any info
    const count = Object.values(contact).reduce((acc, curr) => {
      if (curr) return acc + 1;
      return acc;
    }, 0);

    if (count === 0 && !canUpdateOrga) return null;

    return (
      <div className="mt-5">
        <div className="is-flex has-content-centered-vertically">
          <p
            className="is-size-4 mr-4"
          >
            {t('general:social_links')}
          </p>
          {
          canUpdateOrga && (
            <div className="">
              <OrgaContactEditor />
            </div>
          )
        }
        </div>
        <SocialLinkDisplay contact={contact} showText={false} displayType="row" />

      </div>
    );
  }, [contact]);

  return renderedContact;
};

export default OrgaContactHandler;
