import React, { useMemo } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useTournamentRegistration } from 'components/tournaments/TournamentPage/TournamentPhaseHandler/TournamentRegistration';

// Libraries
import PropTypes from 'prop-types';
import { getRankFromGameMode, getSelectedModeFromGame } from 'constants/games';

// Components
import TournamentPlayerDisplay from './TournamentPlayerDisplay';

function TournamentPlayerSelector(props) {
  const {
    allPlayers, game, mode,
  } = props;

  const {
    selectedParticipants: players,
    setSelectedParticipants,
  } = useTournamentRegistration();

  const { t } = useTranslation(['general']);

  const maxAmountPlayers = useMemo(() => {
    const selectedMode = getSelectedModeFromGame(game.tag, mode);
    return selectedMode.amountPlayers;
  }, [game, mode]);

  const handlePlayerSelect = (playerId) => {
    const player = players.find((p) => p.playerId === playerId);

    if (player) {
      const newPlayers = players.filter((p) => p.playerId !== playerId);
      setSelectedParticipants(newPlayers);
      return;
    }

    if (players.length >= maxAmountPlayers) return;

    const playerData = allPlayers.find((p) => p.playerId === playerId);

    if (!playerData) return;

    if (players.length === 0) {
      const newPlayers = [...players, { playerId, name: playerData.name, role: 'captain' }];
      setSelectedParticipants(newPlayers);
      return;
    }

    const newPlayers = [...players, { playerId, name: playerData.name, role: '' }];
    setSelectedParticipants(newPlayers);
  };

  const renderedPlayers = useMemo(() => allPlayers.map((player) => {
    // if player is in players, set isSelected to true
    const isSelected = players.find((p) => p.playerId === player.playerId);

    let rank = player.ranks.find((r) => r.game === game.tag && r.mode === mode);

    if (!rank) {
      rank = { game: '', mode: '', mmr: -1 };
    }

    return (
      <TournamentPlayerDisplay
        key={player.playerId}
        player={player}
        isSelected={!!isSelected}
        togglePlayer={handlePlayerSelect}
        rank={rank}
      />
    );
  }), [players, allPlayers, game, mode]);

  const renderedAverageMmr = useMemo(() => {
    if (players.length === 0) return null;

    const allMmrs = [];

    for (let i = 0; i < players.length; i++) {
      const { playerId } = players[i];

      const player = allPlayers.find((p) => p.playerId === playerId);

      // eslint-disable-next-line no-continue
      if (!player) continue;

      const rank = player.ranks.find((r) => r.game === game.tag && r.mode === mode);

      // eslint-disable-next-line no-continue
      if (!rank) continue;

      allMmrs.push(rank.mmr);
    }

    const _averageMmr = Math.floor(allMmrs.reduce((a, b) => a + b, 0) / allMmrs.length);

    const rankData = getRankFromGameMode(game.tag, mode, _averageMmr, 'mmr');

    const { img, name } = rankData;

    return (
      <div>
        <p className="is-size-5 has-text-grey">
          {t('general:average_mmr')}
        </p>
        <p className="mt-2">
          { name + (game.tag === 'RL' ? ` (${_averageMmr})` : '') }
        </p>
        <img
          src={img}
          alt={name}
          className="has-height-100 has-width-100"
        />
      </div>
    );
  }, [players]);

  return (
    <div className="my-5">
      <p
        className="is-size-5 has-text-grey"
      >
        { `${t('general:player_selection')} (${players.length}/${maxAmountPlayers})`}
      </p>
      <div className="mt-3">
        {renderedPlayers}
      </div>

      <div className="mt-5">
        {renderedAverageMmr}
      </div>
    </div>
  );
}

TournamentPlayerSelector.propTypes = {
  allPlayers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    playerId: PropTypes.string.isRequired,
    nationality: PropTypes.string.isRequired,
    cardPic: PropTypes.string.isRequired,
    teamRole: PropTypes.string,
    ingameRole: PropTypes.string,
    role: PropTypes.string.isRequired,
    joinedAt: PropTypes.string.isRequired,
  })).isRequired,
  game: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }).isRequired,
  mode: PropTypes.string.isRequired,
};

export default TournamentPlayerSelector;
