import React from 'react';

// Libraries
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import CardPicDisplay from 'components/utils/CardPicDisplay';
import OrgaKickPlayerHandler from './OrgaKickPlayerHandler';
import OrgaRoleHandler from './OrgaRoleHandler';

function OrgaMemberDisplay(props) {
  const { member } = props;

  // const { timezone, language } = useSelector((state) => state.session);

  const { t } = useTranslation(['general']);

  /* const renderTime = (joinedAt) => {
    const date = DateTime.fromISO(joinedAt).setZone(timezone).setLocale(language);
    return date.toLocaleString(DateTime.DATE_SIMPLE);
  }; */

  const {
    name, nationality, cardPic, orgaRole, role, joinedAt,
  } = member;

  return (
    <div className="box p-0 has-cursor-pointer">
      <div
        className="columns is-multiline has-width-350"
      >
        <div className="column has-max-width-80 p-0">
          <div className="">
            <CardPicDisplay img={cardPic} />
          </div>

        </div>
        <div
          className="column"
        >
          <div className="is-flex flex-direction-column has-fullheight has-text-left">
            <div>
              <div className="is-flex">
                <p
                  className="is-size-5 mr-2"
                >
                  {name}
                </p>
                <span className={`nmt3 br-5 is-size-5 fi fi-${nationality}`} />
              </div>

              { role !== 'member' && (
              <p className="is-size-7 has-text-warning">
                {t(`general:${role}`)}
              </p>
              )}

              { orgaRole && (
              <p className="is-size-7 has-text-primary">
                {t(`general:${orgaRole}`)}
              </p>
              )}
            </div>

          </div>

        </div>

        <div className="column has-text-right is-narrow">
          <div className="mb-2">
            <OrgaRoleHandler member={member} />
          </div>
          <div className="">
            <OrgaKickPlayerHandler member={member} />
          </div>
        </div>
      </div>
    </div>
  );
}

OrgaMemberDisplay.propTypes = {
  member: PropTypes.shape({
    name: PropTypes.string.isRequired,
    nationality: PropTypes.string.isRequired,
    cardPic: PropTypes.string.isRequired,
    orgaRole: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    joinedAt: PropTypes.string.isRequired,
  }).isRequired,
};

export default OrgaMemberDisplay;
