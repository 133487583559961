import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import PinboardEditor from '../common/PinboardEditor';
import { Link } from 'react-router-dom';

// Constants
import { PINBOARD_LIST } from 'constants/routes';
import { withAuthenticationRequired } from '@auth0/auth0-react';

function PinboardCreatePage() {
  const { t } = useTranslation(['general']);
  return (
    <div className="is-min-fullheight mb-6 mt-5">

      <div className="columns is-centered">
        <div className="column is-11">
          <div>
            <Link
              to={PINBOARD_LIST}
              className="button has-fullwidth has-background-pinboard-red has-text-left has-text-weight-bold"
            >
              <i className="fas fa-square-caret-left has-text-white is-size-4 has-text-white mr-2" />
              {t('pinboard:back_to_pinboard')}
            </Link>
          </div>

          <h1 className="mt-6 is-size-1 has-text-primary has-text-weight-bold">
            {t('general:create_pinboard_entry')}
          </h1>

          <div className="mt-6">
            <PinboardEditor />
          </div>
        </div>
      </div>

    </div>
  );
}

export default withAuthenticationRequired(PinboardCreatePage);
