import React, { useState, useEffect, useMemo } from 'react';

// Components
import Dropdown from 'components/utils/Dropdown';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import GAMES, { getSelectedGame } from 'constants/games';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

function ModeFilterDropdown(props) {
  const { game, mode, setMode } = props;

  const { t } = useTranslation(['general']);

  const availableModes = useMemo(() => {
    const selectedGame = getSelectedGame(game);

    const preparedModes = selectedGame.modes.map((_mode) => ({
      label: _mode.name,
      value: _mode.name,
    }));

    return [
      { label: t('general:all_modes'), value: '' },
      ...preparedModes,
    ];
  }, [game]);

  const _setMode = (newMode) => {
    setMode(newMode.value);
  };

  const selectedMode = useMemo(() => {
    const _selectedMode = availableModes.find((_mode) => _mode.value === mode);

    return _selectedMode || null;
  }, [mode]);

  const renderedModeSelector = useMemo(() => (
    <div className="field">
      <label className="label has-text-weight-semibold is-size-7">
        {t('general:mode')}
      </label>
      <div className="has-fullwidth">
        <Dropdown
          items={availableModes}
          onSelect={_setMode}
          value={selectedMode}
        />
      </div>
    </div>

  ), [availableModes, mode]);

  return renderedModeSelector;
}

ModeFilterDropdown.propTypes = {
  game: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  setMode: PropTypes.func.isRequired,
};

export default ModeFilterDropdown;
