import React, { useMemo } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import { AVAILABLE_REGIONS } from 'constants/user';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

function RegionSelectorEditor(props) {
  const { region, setRegion, allowAllRegions } = props;

  const { t } = useTranslation(['general']);

  const _setRegion = async (newRegion) => {
    stats.push(['trackEvent', 'Navigation', 'Region set']);
    setRegion(newRegion);
  };

  const renderedRegions = useMemo(() => {
    let _regions = AVAILABLE_REGIONS;

    if (!allowAllRegions) {
      _regions = _regions.filter((_region) => _region.value !== '');
    }

    return _regions.map((_region) => (
      <div
        className="column is-narrow py-0 px-0"
        key={_region.value}
      >
        <button
          className="cleanButton px-0 mr-3"
          type="button"
          onClick={() => _setRegion(_region.value)}
          key={_region.value}
        >
          <p className={`has-text-weight-semibold has-text-white break-word is-size-3-desktop is-size-4-touch is-inline-block ${_region.value === region ? 'has-border-bottom-primary' : ''}`}>
            {t(`general:${_region.label}`)}
          </p>

        </button>
      </div>
    ));
  }, [region]);

  return (
    <div className="">
      <p className="is-size-5 has-text-grey">
        {t('general:region')}
      </p>
      <div className="columns is-mobile is-multiline">
        { renderedRegions }
      </div>
    </div>
  );
}

RegionSelectorEditor.propTypes = {
  region: PropTypes.string.isRequired,
  setRegion: PropTypes.func.isRequired,
  allowAllRegions: PropTypes.bool,
};

RegionSelectorEditor.defaultProps = {
  allowAllRegions: false,
};

export default RegionSelectorEditor;
