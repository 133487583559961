import React, {
  useState, useEffect, useContext, useCallback,
  useMemo,
} from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useSocket } from 'components/providers/SocketProvider';

export const OrgaContext = React.createContext({});

function OrgaProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);

  const [orgas, setOrgas] = useState([]);

  const { socket, isConnected } = useSocket();
  const { get } = useAPI();

  const loadOrgas = useCallback(async () => {
    try {
      setIsLoading(true);
      const _orgas = await get('/orgas');

      setOrgas(_orgas);

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  }, []);

  useEffect(() => {
    loadOrgas();
  }, []);

  useEffect(() => {
    const handleOrgaCreatedEvent = async () => {
      try {
        await loadOrgas();
      } catch (e) {
        console.log(e);
      }
    };

    const handleOrgaDeletedEvent = async (orgaId) => {
      try {
        const _orgas = orgas.filter((orga) => orga.publicId !== orgaId);
        setOrgas(_orgas);
      } catch (e) {
        console.log(e);
      }
    };

    const handlePlayerKickedFromOrgaEvent = async (orgaId) => {
      try {
        const _orgas = orgas.filter((orga) => orga.publicId !== orgaId);
        setOrgas(_orgas);
      } catch (e) {
        console.log(e);
      }
    };

    const handlePlayerLeftOrgaEvent = async (orgaId) => {
      try {
        const _orgas = orgas.filter((orga) => orga.publicId !== orgaId);
        setOrgas(_orgas);
      } catch (e) {
        console.log(e);
      }
    };

    const handleOrgaUpdateEvent = async () => {
      try {
        await loadOrgas();
      } catch (e) {
        console.log(e);
      }
    };

    if (!isConnected) return undefined;

    socket.on('orgaCreated', handleOrgaCreatedEvent);
    socket.on('orgaDeleted', handleOrgaDeletedEvent);
    socket.on('playerLeftOrga', handlePlayerLeftOrgaEvent);
    socket.on('playerKickedFromOrga', handlePlayerKickedFromOrgaEvent);
    socket.on('orgaUpdate', handleOrgaUpdateEvent);

    return () => {
      socket.off('orgaCreated');
      socket.off('orgaDeleted');
      socket.off('playerKickedFromOrga');
      socket.off('playerLeftOrga');
      socket.off('orgaUpdate');
    };
  }, [isConnected, get, socket]);

  const values = useMemo(() => ({
    orgas,
    isLoading,

  }), [

    isLoading,
    orgas,
  ]);

  return (
    <OrgaContext.Provider
      value={values}
    >
      {children}
    </OrgaContext.Provider>
  );
}

OrgaProvider.propTypes = {
  children: PropTypes.node,
};

OrgaProvider.defaultProps = {
  children: {},
};

export const useOrgas = () => {
  const data = useContext(OrgaContext);

  return data;
};

export default OrgaProvider;
