import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

function InputEditor(props) {
  const {
    label, text, setText, maxLength,
  } = props;
  const { t } = useTranslation(['general', 'errors']);

  const [status, setStatus] = useState('');

  const _setInput = (_text) => {
    setStatus('');

    if (_text.length >= maxLength) {
      setStatus(`${t('errors:max_length_reached_error')}!`);
      return;
    }

    setText(_text);
  };

  return (
    <div className="">
      <label className="has-text-grey is-size-5">
        {t(`general:${label}`)}
      </label>
      <input
        className="input is-size-6 has-text-weight-medium mt-2"
        type="text"
        value={text}
        placeholder={t(`general:${label}`)}
        onChange={(e) => _setInput(e.target.value)}
      />

      {
        status && (
        <p className=" mt-4">
            { status }
        </p>
        )
      }
    </div>
  );
}

InputEditor.propTypes = {
  text: PropTypes.string.isRequired,
  setText: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  label: PropTypes.string,
};

InputEditor.defaultProps = {
  maxLength: 140,
  label: '',
};

export default InputEditor;
