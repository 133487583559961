import React, { useState, useEffect } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

function TwitchStreamDisplay({ channel, vod, clip }) {
  const { t } = useTranslation(['general']);

  const [height, setHeight] = useState(0);

  useEffect(() => {
    // when resize
    const handleResize = () => {
      const contentWrapper = document.getElementById('twitchWrapper');

      if (!contentWrapper) return;

      const width = contentWrapper.clientWidth;
      // make height 16:9
      const _height = width / 1.777777777777778;
      setHeight(_height);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!channel && !vod && !clip) {
    return null;
  }

  const parent = window.location.hostname;

  let content = null;

  if (clip) {
    content = (
      <iframe
        title={t('general:twitch')}
        src={`https://clips.twitch.tv/embed?clip=${clip}&parent=${parent}`}
        height={height}
        width="100%"
        className="has-fullwidth"
        allowFullScreen
      />
    );
  }

  if (channel || vod) {
    content = (
      <iframe
        title={t('general:twitch')}
        src={`https://player.twitch.tv/?video=${vod}&channel=${channel}&parent=${parent}`}
        className="has-fullwidth"
        width="100%"
        height={height}
        allowFullScreen
      />
    );
  }

  return (
    <div id="" className="">

      <div className="columns">
        <div className="column p-0 has-fullheight" id="twitchWrapper">
          { content }
        </div>

        {
          channel && (
            <div className="column p-0 is-narrow has-width-418">
              <div
                className="has-overflow-y-auto"
                style={{ maxHeight: height, height: '100%' }}
              >
                <div
                  style={{ width: 400, height }}
                  className="is-flex"
                >
                  <iframe
                    title={t('general:chat')}
                    src={`https://www.twitch.tv/embed/${channel}/chat?darkpopout&parent=${parent}`}
                    width={400}
                    height={735}
                    className=""
                  />
                </div>
              </div>
            </div>
          )
        }

      </div>

    </div>
  );
}

/*
        {
          channel && (
            <div className="column p-0 is-narrow has-width-400">
              <iframe
                title={t('general:chat')}
                src={`https://www.twitch.tv/embed/${channel}/chat?darkpopout&parent=${parent}`}
                width="100%"
                height={735}
              />
            </div>
          )
        }
*/

TwitchStreamDisplay.propTypes = {
  channel: PropTypes.string.isRequired,
  vod: PropTypes.string.isRequired,
};

export default TwitchStreamDisplay;
