import React, { useState, useEffect } from 'react';

// Libraries
import PropTypes from 'prop-types';
import { addCard, updateCard, deleteCard } from 'store/cards';
import axios from 'axios';

// Constants
import { HOME } from 'constants/routes';
import { TYPE_PLAYER, TYPE_TEAM } from 'constants/cards';

// Hooks
import useCards from 'components/hooks/useCards';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Components
import NameCreator from './NameCreator';
import DescCreator from './DescCreator';
import TimeSelector from './TimeSelector';
import ContactInfoSelector from './ContactInfoSelector';
import GameSelector from './GameSelector';
import TypeSelector from './TypeSelector';
import SearchesSelector from './SearchesSelector';

const DEFAULT_TIME = {
  start: new Date(new Date().setHours(18, 0, 0, 0)),
  end: new Date(new Date().setHours(22, 0, 0, 0)),
  day: 0,
};

function CardCreator(props) {
  const { editIndex, save } = props;

  const { cards } = useCards();

  const [name, setName] = useState('');
  const [type, setType] = useState(TYPE_PLAYER);
  const [searches, setSearches] = useState(TYPE_TEAM);
  const [description, setDescription] = useState('');
  const [game, setGame] = useState({
    tag: 'RL',
    mmr: { start: -1, end: -1 },
  });
  const [hasActiveTime, setHasActiveTime] = useState(false);
  const [time, setTime] = useState(null);
  const [contactInfo, setContactInfo] = useState([]);

  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);
  const signal = axios.CancelToken.source();

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (editIndex !== -1) {
      const savedCard = cards[editIndex];

      setName(savedCard.name);
      setType(savedCard.type);
      setSearches(savedCard.searches[0]);
      setDescription(savedCard.description);
      setGame(savedCard.games[0]);

      if (savedCard.times && savedCard.times.length > 0) {
        const savedTime = savedCard.times[0];

        const start = new Date(savedTime.start);

        const savedStartTime = new Date();
        savedStartTime.setHours(start.getHours(), start.getMinutes(), 0, 0);

        const end = new Date(savedTime.end);

        const savedEndTime = new Date();
        savedEndTime.setHours(end.getHours(), end.getMinutes(), 0, 0);

        setTime({
          day: savedTime.day,
          start: savedStartTime,
          end: savedEndTime,
        });
      }

      let validTypes = [];

      const gameTag = savedCard.games[0].tag;

      switch (gameTag) {
        case 'CSGO': validTypes = ['tagsgg', 'discord', 'steam'];
          break;
        case 'VAL':
        case 'LOL': validTypes = ['tagsgg', 'discord', 'riot'];
          break;
        case 'OW': validTypes = ['tagsgg', 'discord', 'battlenet'];
          break;
        case 'NL':
        case 'RL': validTypes = ['tagsgg', 'discord', 'steam', 'epic'];
          break;
        default: return null;
      }

      let contacts = [...savedCard.contact || []];

      contacts = validTypes.map((contactType) => {
        const contactWithType = contacts.find((item) => item.type === contactType);

        if (contactWithType === undefined) {
          return { type: contactType, id: '' };
        }
        return contactWithType;
      });

      setContactInfo(contacts);
    }

    return () => {
      signal.cancel();
    };
  }, [editIndex]);

  const isInputValid = () => {
    if (name.length === 0) {
      setError('Bitte gib einen Namen an!');
      return false;
    }

    if (game.name === 0) {
      setError('Bitte gib ein Spiel an!');
      return false;
    }

    if (game.mmr.start === -1 || game.mmr.end === -1) {
      setError('Bitte gib an, welche Ränge gesucht werden sollen!');
      return false;
    }

    if (contactInfo.length === 0) {
      setError('Es ist ein Fehler aufgetreten. Bitte kontaktiere uns!');
      return false;
    }

    let contactFieldsEmpty = 0;
    for (let i = 0; i < contactInfo.length; i++) {
      if (contactInfo[i].id.length === 0) {
        contactFieldsEmpty++;
      }
    }

    if (contactFieldsEmpty === contactInfo.length) {
      setError('Bitte gib mindestens eine Kontaktmöglichkeit an!');
      return false;
    }

    // time is optional and only shown for teams vs teams, if it is not selected we dont need to validate it
    if (!time) {
      return true;
    }

    const startTime = time.start.getTime();
    const endTime = time.end.getTime();

    // should not happen as dates are filtered, but just to be sure
    if (startTime > endTime || endTime < startTime) {
      setError('Bitte gib gültige Zeiten an!');
      return false;
    }

    return true;
  };

  const removeCard = async () => {
    try {
      setDisabled(true);

      const token = await getAccessTokenSilently();

      const id = cards[editIndex]._id;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: signal.token,
        data: { id },
      };

      await axios.delete(`${process.env.REACT_APP_API}/cards/${id}`, config);

      dispatch(deleteCard(id));

      navigate(HOME);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }

      setDisabled(false);

      console.log(err);

      console.log(err.response);

      if (err.response) {
        setError(err.response.data);
        return;
      }

      if (!err.response || err.response.status === 500) {
        setError('Etwas hat nicht funktioniert. Bitte versuche es später noch einmal');
        return;
      }

      setError(err.response.data);
    }
  };

  const saveCard = async () => {
    try {
      if (!isInputValid()) {
        return;
      }

      setDisabled(true);

      // dont send contact fields with no value in it
      const contacts = contactInfo.filter((item) => item.id.length !== 0);

      const token = await getAccessTokenSilently();

      const data = {
        name,
        type,
        searches,
        description,
        games: [game],
        times: [],
        contact: contacts,
      };

      // if time has been selected, send it
      if (time) {
        data.times = [time];
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: signal.token,
      };

      if (editIndex !== -1) {
        const id = cards[editIndex]._id;
        data.id = id;

        const response = await axios.patch(`${process.env.REACT_APP_API}/cards`, data, config);

        const cardData = response.data;

        dispatch(updateCard({ id, card: cardData }));

        setDisabled(false);

        save();
      } else {
        const response = await axios.post(`${process.env.REACT_APP_API}/cards`, data, config);

        const cardData = response.data;

        dispatch(addCard(cardData));

        setDisabled(false);

        save();
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }

      setDisabled(false);

      console.log(err);

      console.log(err.response);

      if (err.response) {
        setError(err.response.data);
        return;
      }

      if (!err.response || err.response.status === 500) {
        setError('Etwas hat nicht funktioniert. Bitte versuche es später noch einmal');
        return;
      }

      setError(err.response.data);
    }
  };

  const toggleTime = () => {
    if (time) {
      setTime(null);
      return;
    }

    setTime(DEFAULT_TIME);
  };

  const showTimeSelection = () => (
    <>
      <div className="field">
        <input
          id="switch"
          type="checkbox"
          name="switchExample"
          className="switch"
          checked={!!time}
          onChange={toggleTime}
        />
        <label htmlFor="switch" className="has-text-weight-semibold">
          Erweitere Einstellungen (optional)
        </label>
      </div>

      {
        !!time
        && (
        <TimeSelector
          time={time}
          setTime={setTime}
          hasActiveTime={hasActiveTime}
          setHasActiveTime={setHasActiveTime}
        />
        )
      }
    </>
  );

  return (
    <div>
      <div className="columns is-centered is-vcentered is-marginless">
        <div className="column is-10 my-6">
          <div className="box boxshadow">
            <h1 className="is-size-3-desktop is-size-4-tablet is-size-5-mobile has-text-weight-semibold">
              {
                editIndex === -1
                  ? 'Erstelle deine Suchkarte'
                  : 'Bearbeite deine Suchkarte'
              }
            </h1>
            <div className="columns is-vcentered is-multiline">
              <div className="column is-6-desktop">
                <NameCreator name={name} setName={setName} />
                <div className="columns py-2">
                  <div className="column is-6 is-paddingless mr-2">
                    <TypeSelector type={type} setType={setType} />
                  </div>
                  <div className="column is-6 is-paddingless">
                    <SearchesSelector
                      searches={searches}
                      setSearches={setSearches}
                    />
                  </div>
                </div>
                <DescCreator
                  description={description}
                  setDescription={setDescription}
                />
                <GameSelector game={game} setGame={setGame} />
                {
                  type === TYPE_TEAM && searches === TYPE_TEAM
                  && showTimeSelection()
                }
              </div>
              <div className="column is-offset-1-desktop is-5-desktop is-12-tablet is-narrow has-border-left-bold-secondary">
                <ContactInfoSelector
                  game={game.tag}
                  contactInfo={contactInfo}
                  setContactInfo={setContactInfo}
                  isEdit={editIndex !== -1}
                />
              </div>
            </div>
            <button
              type="button"
              className="button has-background-primary is-rounded has-text-weight-semibold has-fullheight mt-3"
              onClick={saveCard}
              disabled={disabled}
            >
              Suchkarte speichern
            </button>
            <p className="has-text-weight-semibold has-text-danger mt-3">
              { error }
            </p>
            {
              editIndex !== -1
              && (
                <div className="has-border-danger-light px-3 py-2 mt-6">
                  <div className="columns">
                    <div className="column">
                      <h3 className="has-text-weight-semibold">
                        Suchkarte löschen
                      </h3>
                      <p>
                        Nachdem du deine Karte gelöscht hast, gibt es kein Zurück mehr. Sei vorsichtig.
                      </p>
                    </div>
                    <div className="column has-text-right">
                      <button
                        type="button"
                        onClick={removeCard}
                        disabled={disabled}
                        className="button has-fullheight is-danger has-text-weight-semibold is-outlined"
                      >
                        Suchkarte löschen
                      </button>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}

CardCreator.propTypes = {
  editIndex: PropTypes.number,
  save: PropTypes.func,
};

CardCreator.defaultProps = {
  editIndex: -1,
  save: () => {},
};

export default CardCreator;
