import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import ContactField from 'components/utils/ContactField';

function DiscordServerContact(props) {
  const { value = '', setValue } = props;
  const [error, setError] = useState('');

  const { t } = useTranslation(['contacts', 'validation']);

  const setAndValidate = (url) => {
    setError('');

    if (url.length > 128) {
      setError(t('validation:enter_valid_url_error'));
      return;
    }

    setValue('discordServer', url);
  };

  return (
    <ContactField
      icon="fab fa-discord"
      value={value}
      onChange={setAndValidate}
      error={error}
      label={t('contacts:discord')}
      placeholder="https://discord.gg/server"
    />
  );
}

DiscordServerContact.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
};

export default DiscordServerContact;
