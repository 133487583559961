import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import Dropdown from 'components/utils/Dropdown';

// Constants
import { PINBOARD_PROVIDER_TYPES } from 'constants/pinboard';

function ProviderTypeSelector({ providerType, setProviderType, disabled }) {
  const { t } = useTranslation(['general', 'pinboard']);

  const _setProviderType = (phase) => {
    setProviderType(phase.value);
  };

  const availableTypes = useMemo(() => {
    const types = PINBOARD_PROVIDER_TYPES.map((type) => ({
      value: type,
      label: t(`general:${type}`),
    }));

    return [
      { label: t('general:all'), value: '' },
      ...types,
    ];
  }, [t]);

  const selectedPhase = useMemo(() => {
    const phase = availableTypes.find((_phase) => _phase.value === providerType);
    return phase || { label: t('general:all'), value: '' };
  }, [availableTypes, providerType, t]);

  return (
    <div className="field">
      <label className="label has-text-weight-semibold is-size-7">
        {t('pinboard:provider_type')}
      </label>
      <div className="has-fullwidth">
        <Dropdown
          items={availableTypes}
          onSelect={_setProviderType}
          value={selectedPhase.label}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

ProviderTypeSelector.propTypes = {
  providerType: PropTypes.string.isRequired,
  setProviderType: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ProviderTypeSelector.defaultProps = {
  disabled: false,
};

export default ProviderTypeSelector;
