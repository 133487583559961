import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import Dropdown from 'components/utils/Dropdown';

// Constants
import { PINBOARD_SPECIAL_ICONS } from 'constants/pinboard';

function SpecialIconSelector({ specialIcon, setSpecialIcon, disabled }) {
  const { t } = useTranslation(['general', 'pinboard']);

  const _setSpecialIcon = (phase) => {
    setSpecialIcon(phase.value);
  };

  const availableTypes = useMemo(() => {
    const types = PINBOARD_SPECIAL_ICONS.map((type) => ({
      value: type,
      label: t(`general:${type}`),
    }));

    return [
      { label: t('general:all'), value: '' },
      ...types,
    ];
  }, [t]);

  const selectedPhase = useMemo(() => {
    const phase = availableTypes.find((_phase) => _phase.value === specialIcon);
    return phase || { label: t('general:all'), value: '' };
  }, [availableTypes, specialIcon, t]);

  return (
    <div className="field">
      <label className="label has-text-weight-semibold is-size-7">
        {t('pinboard:special_icons')}
      </label>
      <div className="has-fullwidth">
        <Dropdown
          items={availableTypes}
          onSelect={_setSpecialIcon}
          value={selectedPhase.label}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

SpecialIconSelector.propTypes = {
  specialIcon: PropTypes.string.isRequired,
  setSpecialIcon: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SpecialIconSelector.defaultProps = {
  disabled: false,
};

export default SpecialIconSelector;
