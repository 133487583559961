import React, {
  useEffect,
} from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSocket } from 'components/providers/SocketProvider';

function TournamentSeedingSocketHandler({ swapParticipants }) {
  const { socket, isConnected } = useSocket();

  useEffect(() => {
    if (!isConnected) return undefined;

    socket.on('tournamentBracketSwap', swapParticipants);

    return () => {
      socket.off('tournamentBracketSwap');
    };
  }, [isConnected, socket, swapParticipants]);

  return null;
}

TournamentSeedingSocketHandler.propTypes = {
  swapParticipants: PropTypes.func.isRequired,
};

export default TournamentSeedingSocketHandler;
