import React, { useState, useEffect } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Constants
import { getSelectedModeFromGame } from 'constants/games';
import { HOT_SCRIMPS } from 'constants/routes';

// Components
import HotscrimpPlayerSelector from 'components/HotScrimps/utils/HotscrimpPlayerSelector';
import Popup from 'reactjs-popup';
import DescriptionEditor from 'components/utils/inputs/DescriptionEditor';
import ModeSelector from 'components/utils/games/ModeSelector';
import MmrRangeSelector from 'components/utils/games/MmrRangeSelector';
import DateSelector from './DateSelector';
import TeamSelector from './TeamSelector';
import TeamInfoDisplay from './TeamInfoDisplay';

function CreateHotscrimpButton() {
  const { t } = useTranslation(['general', 'hotscrimps', 'validation']);

  const { post } = useAPI();

  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');

  const [team, setTeam] = useState();
  const [mode, setMode] = useState('');
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [mmrRange, setMmrRange] = useState({ start: -1, end: -1 });
  const [hasMmrRangeActive, setHasMmrRangeActive] = useState(false);

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const navigate = useNavigate();

  const reset = () => {
    setStatus('');
    setDate('');
    setMode('');
    setDescription('');
    setTeam();
    setSelectedPlayers([]);
    setMmrRange({ start: -1, end: -1 });
  };

  useEffect(() => {
    setMode('');
  }, [team]);

  useEffect(() => {
    setSelectedPlayers([]);
    setMmrRange({ start: -1, end: -1 });
  }, [team, mode]);

  const createHotscrimp = async (close) => {
    try {
      setStatus('');

      if (!team) {
        setStatus(t('validation:select_a_team_error'));
        return;
      }

      if (!mode) {
        setStatus(t('validation:select_a_mode_error'));
        return;
      }

      if (selectedPlayers.length === 0) {
        setStatus(t('validation:select_players_error'));
        return;
      }

      const { amountPlayers } = getSelectedModeFromGame(team.game.tag, mode);
      if (selectedPlayers.length !== amountPlayers) {
        setStatus(t('validation:select_players_error'));
        return;
      }

      if (!date) {
        setStatus(t('validation:enter_a_time_error'));
        return;
      }

      setDisabled(true);

      let preparedMmrRange = { ...mmrRange };

      if (!hasMmrRangeActive) {
        preparedMmrRange = { start: -1, end: -1 };
      }

      // on rocket league, the mmr selector sets the range to -1 if the user removed one of the numbers
      // so we check if one of the numbers is -1 and set it to 0
      if (mmrRange.end !== -1 && mmrRange.start > mmrRange.end) {
        preparedMmrRange = { ...mmrRange, start: 0 };
      }

      if (mmrRange.start !== -1 && mmrRange.end < mmrRange.start) {
        preparedMmrRange = { ...mmrRange, end: mmrRange.start };
      }

      const data = {
        startDate: date,
        teamId: team.publicId,
        mode,
        players: selectedPlayers,
        description,
        mmrRange: preparedMmrRange,
      };

      const publicId = await post('/hotscrimps/create', data);

      stats.push(['trackEvent', 'Hot Scrimps', 'Hot Scrimp created']);

      setDisabled(false);
      close();

      navigate(`${HOT_SCRIMPS}/${publicId}`);
    } catch (e) {
      const { data } = e.response;

      if (data === 'DUPLICATE_HOTSCRIMP_ENTRY') {
        setStatus(t('hotscrimps:duplicate_hotscrimp_entry_error'));
      } else if (data === 'TOO_MANY_HOTSCRIMPS') {
        setStatus(t('hotscrimps:too_many_hotscrimps_error'));
      } else {
        setStatus(t('hotscrimps:could_not_create_hotscrimp_error'));
      }

      setDisabled(false);
    }
  };

  return (
    <div className="">
      <Popup
        trigger={(
          <button
            type="button"
            className="button has-no-background grow_small has-text-grey has-border-dashed-grey"
          >
            <i className="fas fa-plus mr-2" />
            {t('general:create')}
          </button>
          )}
        position="top center"
        keepTooltipInside="#root"
        repositionOnResize
        closeOnDocumentClick={!disabled}
        closeOnEscape={!disabled}
        modal
        onClose={() => {
          reset();
        }}
      >
        {
            (close) => (
              <div className="is-fullwidth has-min-width-300 has-max-width-1000 is-flex has-content-centered is-in-center has-overflow-hidden box boxshadow has-background-black-gradient p-0">
                <div className="has-fullwidth is-max-fullheight has-overflow-y-auto has-min-height-500">
                  <div
                    className="has-text-centered"
                  >

                    <div
                      className="has-gradient-primary has-fullwidth is-relative py-6"
                    >

                      <div className="is-absolute" style={{ right: 20, top: 20, zIndex: '99' }}>

                        <div className="has-margin-left-auto">
                          <button
                            type="button"
                            onClick={close}
                            className="cleanButton has-text-white"
                          >
                            <i className="fas fa-times is-size-5" />
                          </button>
                        </div>
                      </div>

                      <p
                        className="is-size-2-desktop is-size-3-touch has-text-weight-semibold"
                      >
                        {t('hotscrimps:hotscrimps')}
                      </p>

                    </div>
                  </div>

                  <div className="br-0 p-6">

                    <div className="columns is-multiline is-mobile">

                      <div className="column is-narrow has-min-width-400">

                        <TeamSelector team={team} setTeam={setTeam} />

                        {
                            team && (
                              <div className="mt-4">
                                <TeamInfoDisplay
                                  name={team.name}
                                  cardPic={team.cardPic}
                                  game={team.game}
                                  region={team.region}
                                  platform={team.platform}
                                />
                                <ModeSelector
                                  rank={{ mode, game: team.game.tag }}
                                  setMode={setMode}
                                />

                                {
                                  mode && (
                                    <div>
                                      <div>
                                        <input
                                          type="checkbox"
                                          id="mmrRange"
                                          name="mmrRange"
                                          className="switch"
                                          checked={hasMmrRangeActive}
                                          onChange={() => setHasMmrRangeActive(!hasMmrRangeActive)}
                                        />
                                        <label
                                          htmlFor="mmrRange"
                                          className="checkbox"
                                        >

                                          {t('general:mmr_range')}
                                        </label>
                                      </div>
                                      {
                                        hasMmrRangeActive && (
                                          <MmrRangeSelector
                                            rank={{ mode, game: team.game.tag, mmr: mmrRange }}
                                            setMmr={setMmrRange}
                                          />
                                        )
                                      }
                                      <HotscrimpPlayerSelector
                                        game={team.game}
                                        mode={mode}
                                        allPlayers={team.members}
                                        players={selectedPlayers}
                                        setPlayers={setSelectedPlayers}
                                      />
                                    </div>
                                  )
                                }

                                <div className="mt-5">
                                  <DateSelector date={date} setDate={setDate} />
                                </div>
                              </div>
                            )
                          }

                      </div>

                      {
                          team && (
                            <div className="column is-12-mobile is-offset-1-desktop is-offset-1-tablet">

                              <div>
                                <DescriptionEditor
                                  description={description}
                                  setDescription={setDescription}
                                />
                              </div>
                            </div>
                          )
                        }

                    </div>

                    {
                        team && (
                          <div className="has-text-centered mt-6">
                            <button
                              type="button"
                              onClick={() => createHotscrimp(close)}
                              className={`button is-size-5 px-5 has-background-primary has-text-white has-text-weight-semibold ${disabled ? 'is-loading' : ''}`}
                            >
                              {t('general:create')}
                            </button>
                            <div className="has-text-centered">
                              <p
                                className="has-text-weight-semibold mt-3"
                              >
                                { status }
                              </p>
                            </div>
                          </div>
                        )
                      }

                  </div>

                </div>
              </div>
            )
           }
      </Popup>
    </div>
  );
}

export default CreateHotscrimpButton;
