import { configureStore } from '@reduxjs/toolkit';

import sessionReducer from './session';
import cardReducer from './cards';
import matchReducer from './matches';
import playerboardReducer from './playerboard';
import playerReducer from './player';
import teamReducer from './teams';

export default configureStore({
  reducer: {
    session: sessionReducer,
    cards: cardReducer,
    matches: matchReducer,
    playerboard: playerboardReducer,
    player: playerReducer,
    teams: teamReducer,
  },
});
