import React, { useState, useEffect, useMemo } from 'react';

// Components
import Dropdown from 'components/utils/Dropdown';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import GAMES, { getSelectedGame } from 'constants/games';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

function ModeSelector(props) {
  const { rank, setMode, disabled } = props;
  const { game, mode } = rank;

  const { t } = useTranslation(['general']);

  const availableModes = useMemo(() => {
    const selectedGame = getSelectedGame(game);

    return selectedGame.modes.map((_mode) => ({
      label: _mode.name,
      value: _mode.name,
    }));
  }, [game]);

  const _setMode = (newMode) => {
    setMode(newMode.value);
  };

  const selectedMode = useMemo(() => {
    const _selectedMode = availableModes.find((_mode) => _mode.value === mode);

    return _selectedMode || null;
  }, [mode]);

  const renderedModeSelector = useMemo(() => (
    <div className="">
      <p className="is-size-5 has-text-grey mb-2">
        {t('general:mode')}
      </p>
      <div className="has-width-200 has-margin-left-auto">
        <Dropdown
          items={availableModes}
          onSelect={_setMode}
          value={selectedMode}
          disabled={disabled}
        />
      </div>
    </div>

  ), [availableModes, mode, disabled]);

  return (
    <div className="field is-inline-block">
      { renderedModeSelector }
    </div>
  );
}

ModeSelector.propTypes = {
  rank: PropTypes.shape({
    game: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
  }).isRequired,
  setMode: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ModeSelector.defaultProps = {
  disabled: false,
};

export default ModeSelector;
