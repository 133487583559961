import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import ColorContent from 'components/utils/ColorContent';

// Constants
const DAYS = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];

function TimeDisplay(props) {
  const { times } = props;

  if (times.length === 0) return null;

  const time = times[0];

  const startTime = new Date(time.start).toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit' });
  const endTime = new Date(time.end).toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit' });

  const day = DAYS[time.day];

  return (
    <div>
      <ColorContent className="has-max-width-70-percent">
        <p>{day}</p>
        <p>{ `${startTime} - ${endTime}` }</p>
      </ColorContent>
    </div>
  );
}

TimeDisplay.propTypes = {
  times: PropTypes.arrayOf(PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
    day: PropTypes.number.isRequired,
  })).isRequired,
};

export default TimeDisplay;
