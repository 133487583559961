import React, { useState, useMemo } from 'react';

// Hooks
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

// Components
// Notifications
import NotificationSidebar from 'components/Notifications/NotificationSidebar';
import { useNotifications } from 'components/Notifications/NotificationProvider';

// Hotscrimps
import HotscrimpsSidebar from 'components/HotScrimps/HotscrimpsSidebar';
import HotscrimpsProvider from 'components/HotScrimps/HotscrimpsProvider';

// Components
import LogoBottomDisplay from 'components/utils/LogoBottomDisplay';
import FriendsList from 'components/friends/FriendsList';
import OrgaSidebar from 'components/orgas/OrgaSidebar';

function SocialSidebar() {
  const { showSidebar } = useSelector((state) => state.session);

  const [content, setContent] = useState('hotscrimps');
  const { notifications } = useNotifications();

  const renderedContent = useMemo(() => {
    if (content === 'notification') return <NotificationSidebar />;

    if (content === 'hotscrimps') return <HotscrimpsSidebar />;

    if (content === 'orgas') return <OrgaSidebar />;

    if (content === 'chat') return <FriendsList />;

    return null;
  }, [content]);

  const amountUnreadNotifications = useMemo(() => {
    if (!notifications) return 0;

    return notifications.filter((notification) => !notification.read).length;
  }, [notifications]);

  if (!showSidebar) return null;

  return (
    <div className="column p-0 is-narrow has-fullwidth has-max-width-350 has-fullheight">
      <div className="is-fixed">
        <div
          className="is-fullheight has-width-350 has-background-dark p-0 has-border-darkest-light"
        >
          <div className="is-flex flex-direction-column has-fullheight has-fullwidth has-background-black-ter is-relative">

            { renderedContent }

            <div className="has-margin-top-auto has-border-darkest-light is-relative">
              <div>

                <div className="has-background-dark pt-2 pb-2 is-relative" style={{ zIndex: 100 }}>
                  <div className="columns is-mobile">

                    <div className="column has-text-centered">
                      <button
                        type="button"
                        className="cleanButton"
                        onClick={() => setContent('hotscrimps')}
                      >
                        <i className="fas fa-gamepad has-text-grey is-size-4" />
                      </button>
                    </div>

                    <div className="column has-text-centered">
                      <button
                        type="button"
                        className="cleanButton"
                        onClick={() => setContent('orgas')}
                      >
                        <i className="fas fa-users has-text-grey is-size-4" />
                      </button>
                    </div>

                    <div className="column has-text-centered">
                      <button
                        type="button"
                        className="cleanButton"
                        onClick={() => setContent('chat')}
                      >
                        <i className="fas fa-user-tag has-text-grey is-size-4" />
                      </button>
                    </div>

                    <div className="column has-text-centered">
                      <button
                        type="button"
                        className="cleanButton is-relative"
                        onClick={() => setContent('notification')}
                      >
                        <p
                          className="is-absolute br-100 has-background-danger has-text-white px-2 has-text-weight-semibold"
                          style={{ right: '-7px', top: '-7px' }}
                        >
                          { amountUnreadNotifications }
                        </p>
                        <i className="fas fa-bell has-text-grey is-size-4" />
                      </button>
                    </div>
                  </div>
                </div>

                <LogoBottomDisplay />

              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  );
}

// Wrap SocialSidebarWrapper over the SocialSidebar to access the notifications
function SocialSidebarWrapper() {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) return null;

  return (
    <HotscrimpsProvider>
      <SocialSidebar />
    </HotscrimpsProvider>
  );
}

export default SocialSidebarWrapper;
