import React, { useMemo } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import Markdown from 'react-markdown';

function Recipe(props) {
  const { recipe } = props;

  const {
    name,
    thumbnail,
    ingredients,
    suggestions,
    steps,
    images,
    videos,
  } = recipe;

  const { t } = useTranslation(['general', 'errors']);

  const renderedIngredients = useMemo(() => {
    if (!ingredients || ingredients.length === 0) return null;

    return ingredients.map((ingredient, index) => (
      <li>
        <Markdown>
          {ingredient}
        </Markdown>
      </li>
    ));
  }, [ingredients]);

  const renderedSteps = useMemo(() => {
    if (!steps || steps.length === 0) return null;

    return steps.map((step, index) => (
      <li>
        <Markdown>
          {step}
        </Markdown>
      </li>
    ));
  }, [steps]);

  const renderedSuggestions = useMemo(() => {
    if (!suggestions || suggestions.length === 0) return null;

    return suggestions.map((suggestion, index) => (
      <li>
        <Markdown>
          {suggestion}
        </Markdown>
      </li>
    ));
  }, [steps]);

  return (
    <div>

      <h3 className="is-size-4 has-text-standard mb-6 has-text-weight-light">
        <i className="fas fa-play mr-4 has-text-primary" />
        {name}
      </h3>

      {
        renderedIngredients && (
          <div>
            <p className="has-text-weight-bold mb-5">
              {`${t('general:ingredients')}:`}
            </p>
            <div className="content has-text-white-ter">
              <ul className="has-list-style-normal">
                {renderedIngredients}
              </ul>
            </div>
          </div>
        )
      }

      {
        renderedSteps && (
          <div className="mt-6">
            <p className="has-text-weight-bold mb-5">
              {`${t('general:recipe_preparation')}:`}
            </p>
            <div className="content">
              <ol>
                {renderedSteps}
              </ol>
            </div>
          </div>
        )
      }

      {
        renderedSuggestions && (
          <div>
            <p className="has-text-weight-bold mb-5">
              {`${t('general:suggestions')}:`}
            </p>
            <div className="content has-text-white-ter">
              <ul className="has-list-style-normal">
                {renderedSuggestions}
              </ul>
            </div>
          </div>
        )
      }

    </div>
  );
}

export default Recipe;
