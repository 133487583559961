import React, { useMemo, useState } from 'react';

// Libraries
import stats from 'analytics/matomo';
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import { ALL_LANGUAGES } from 'constants/user';

// Components
import Dropdown from 'components/utils/Dropdown';

function LanguageSelector(props) {
  const { languages, setLanguages } = props;

  const { t } = useTranslation(['general']);

  const _setLanguages = async (_languages) => {
    const values = _languages.map((_language) => _language.value);

    if (values.length > 2) {
      values.shift();
    }

    setLanguages(values);

    stats.push(['trackEvent', 'Player Bowl Editor', 'Languages set']);
  };

  const currentValue = useMemo(() => {
    const allLanguages = [];
    for (let i = 0; i < languages.length; i++) {
      const _language = languages[i];

      const foundLanguage = ALL_LANGUAGES.find((_lang) => _lang.value === _language);

      // eslint-disable-next-line
      if (!foundLanguage) continue;

      allLanguages.push(foundLanguage);
    }

    return allLanguages;
  }, [languages]);

  const renderedFlags = useMemo(() => languages.map((language) => (
    <span className={`ml-2 fi fi-${language}`} key={language} />
  )), [languages]);

  return (
    <div className="field my-3">

      <div className="">
        <p className="has-text-grey is-size-5">
          {t('general:languages')}
        </p>

        <div className="has-margin-left-auto">
          <Dropdown
            items={ALL_LANGUAGES}
            onSelect={_setLanguages}
            value={currentValue}
            isMulti
            isSearchable
            className="has-width-200"
          />

        </div>
      </div>
    </div>

  );
}

LanguageSelector.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  setLanguages: PropTypes.func.isRequired,
};

export default LanguageSelector;
