export const MAX_CARD_NAME_LENGTH = 60;
export const MAX_CARD_DESC_LENGTH = 140;

export const TYPE_PLAYER = 'PLAYER';
export const TYPE_TEAM = 'TEAM';

export const MAX_AMOUNT_CARDS = 10;

export const TYPES = [
  { label: 'Spieler', value: TYPE_PLAYER },
  { label: 'Team', value: TYPE_TEAM },
];
