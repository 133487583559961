import rss from 'assets/images/cards/games/rss.png';

// Icons
import rss_icon from 'assets/images/games/rss/rss_icon.png';
import icon_white from 'assets/images/games/rss/rss_landing_icon.png';

// Rank images
import rss_copper4 from 'assets/images/games/rss/rss_copper4.png';
import rss_copper3 from 'assets/images/games/rss/rss_copper3.png';
import rss_copper2 from 'assets/images/games/rss/rss_copper2.png';
import rss_copper1 from 'assets/images/games/rss/rss_copper1.png';
import rss_bronze4 from 'assets/images/games/rss/rss_bronze4.png';
import rss_bronze3 from 'assets/images/games/rss/rss_bronze3.png';
import rss_bronze2 from 'assets/images/games/rss/rss_bronze2.png';
import rss_bronze1 from 'assets/images/games/rss/rss_bronze1.png';
import rss_silver4 from 'assets/images/games/rss/rss_silver4.png';
import rss_silver3 from 'assets/images/games/rss/rss_silver3.png';
import rss_silver2 from 'assets/images/games/rss/rss_silver2.png';
import rss_silver1 from 'assets/images/games/rss/rss_silver1.png';
import rss_gold4 from 'assets/images/games/rss/rss_gold4.png';
import rss_gold3 from 'assets/images/games/rss/rss_gold3.png';
import rss_gold2 from 'assets/images/games/rss/rss_gold2.png';
import rss_gold1 from 'assets/images/games/rss/rss_gold1.png';
import rss_platinum3 from 'assets/images/games/rss/rss_platinum3.png';
import rss_platinum2 from 'assets/images/games/rss/rss_platinum2.png';
import rss_platinum1 from 'assets/images/games/rss/rss_platinum1.png';
import rss_diamond from 'assets/images/games/rss/rss_diamond.png';
import rss_champion from 'assets/images/games/rss/rss_champion.png';

import ranks from './ranks';

export default {
  name: 'Rainbow Six Siege',
  tag: 'RSS',
  img: rss,
  icon: rss_icon,
  iconWhite: icon_white,
  platforms: ['pc', 'xbox', 'ps'],
  modes: [
    {
      name: '5v5',
      amountPlayers: 5,
      ranks,
    },
  ],
  tagsPerCategory: [
    {
      category: 'general',
      tags: ['Tournament'],
    },
    {
      category: 'properties',
      tags: ['IGL', 'Shotcall'],
    },
    {
      category: 'attackers',
      tags: [
        'Ace',
        'Ash',
        'Maverick',
        'Sledge',
        'Thatcher',
        'Zofia',
        'Hibana',
        'Thermite',
        'Iana',
        'Flores',
        'Jackal',
        'Nomad',
        'Osa',
        'Capitao',
        'Gridlock',
        'Lion',
        'Twitch',
        'Ying',
        'Zero',
        'Buck',
        'Amaru',
        'Dokkaebi',
        'Nokk',
        'Blackbeard',
        'Finka',
        'Glaz',
        'Kali',
        'Fuze',
        'IQ',
        'Warden',
      ],
    },
    {
      category: 'defenders',
      tags: [
        'Mozzie',
        'Kaid',
        'Maestro',
        'Mira',
        'Smoke',
        'Mute',
        'Valkyrie',
        'Pulse',
        'Clash',
        'Echo',
        'Ela',
        'Goyo',
        'Jager',
        'Lesion',
        'Melusi',
        'Thunderbird',
        'Bandit',
        'Nokk',
        'Alibi',
        'Wamai',
        'Castle',
        'Doc',
        'Rook',
        'Frost',
        'Caveria',
        'Oryx',
        'Kapkan',
        'Blitz',
        'F Tier',
        'Montagne',
        'Fuze',
        'Caveira',
      ],
    },
    {
      category: 'leagues',
      tags: ['GSA League ', 'ESL'],
    },
  ],

  mmr: [
    'Unranked',
    'Copper IV',
    'Copper III',
    'Copper II',
    'Copper I',
    'Bronze IV',
    'Bronze III',
    'Bronze II',
    'Bronze I',
    'Silver IV',
    'Silver III',
    'Silver II',
    'Silver I',
    'Gold IV',
    'Gold III',
    'Gold II',
    'Gold I',
    'Platin III',
    'Platin II',
    'Platin I',
    'Diamond',
    'Champion',
  ],
  mmrShort: [
    'UNRKD',
    'C IV',
    'C III',
    'C II',
    'C I',
    'B IV',
    'B III',
    'B II',
    'B I',
    'S IV',
    'S III',
    'S II',
    'S I',
    'G IV',
    'G III',
    'G II',
    'G I',
    'P III',
    'P II',
    'P I',
    'DIA',
    'CHAMP',
  ],
  mmrImages: [
    rss_copper4,
    rss_copper4,
    rss_copper3,
    rss_copper2,
    rss_copper1,
    rss_bronze4,
    rss_bronze3,
    rss_bronze2,
    rss_bronze1,
    rss_silver4,
    rss_silver3,
    rss_silver2,
    rss_silver1,
    rss_gold4,
    rss_gold3,
    rss_gold2,
    rss_gold1,
    rss_platinum3,
    rss_platinum2,
    rss_platinum1,
    rss_diamond,
    rss_champion,
  ],
};
