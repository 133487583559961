// Rank images
import lol_iron from 'assets/images/games/lol/lol_iron.png';
import lol_bronze from 'assets/images/games/lol/lol_bronze.png';
import lol_silver from 'assets/images/games/lol/lol_silver.png';
import lol_gold from 'assets/images/games/lol/lol_gold.png';
import lol_platinum from 'assets/images/games/lol/lol_platinum.png';
import lol_diamond from 'assets/images/games/lol/lol_diamond.png';
import lol_master from 'assets/images/games/lol/lol_master.png';
import lol_gm from 'assets/images/games/lol/lol_gm.png';
import lol_challenger from 'assets/images/games/lol/lol_challenger.png';

const ranks = [
  {
    name: 'Unranked',
    shortName: 'UNRKD',
    class: 0,
    minMmr: 0,
    maxMmr: 0,
    img: lol_iron,
  },
  {
    name: 'Iron IV',
    shortName: 'IR IV',
    class: 1,
    minMmr: 1,
    maxMmr: 1,
    img: lol_iron,
  },
  {
    name: 'Iron III',
    shortName: 'IR III',
    class: 2,
    minMmr: 2,
    maxMmr: 2,
    img: lol_iron,
  },
  {
    name: 'Iron II',
    shortName: 'IR II',
    class: 3,
    minMmr: 3,
    maxMmr: 3,
    img: lol_iron,
  },
  {
    name: 'Iron I',
    shortName: 'IR I',
    class: 4,
    minMmr: 4,
    maxMmr: 4,
    img: lol_iron,
  },
  {
    name: 'Bronze IV',
    shortName: 'B IV',
    class: 5,
    minMmr: 5,
    maxMmr: 5,
    img: lol_bronze,
  },
  {
    name: 'Bronze III',
    shortName: 'B III',
    class: 6,
    minMmr: 6,
    maxMmr: 6,
    img: lol_bronze,
  },
  {
    name: 'Bronze II',
    shortName: 'B II',
    class: 7,
    minMmr: 7,
    maxMmr: 7,
    img: lol_bronze,
  },
  {
    name: 'Bronze I',
    shortName: 'B I',
    class: 8,
    minMmr: 8,
    maxMmr: 8,
    img: lol_bronze,
  },
  {
    name: 'Silver IV',
    shortName: 'S IV',
    class: 9,
    minMmr: 9,
    maxMmr: 9,
    img: lol_silver,
  },
  {
    name: 'Silver III',
    shortName: 'S III',
    class: 10,
    minMmr: 10,
    maxMmr: 10,
    img: lol_silver,
  },
  {
    name: 'Silver II',
    shortName: 'S II',
    class: 11,
    minMmr: 11,
    maxMmr: 11,
    img: lol_silver,
  },
  {
    name: 'Silver I',
    shortName: 'S I',
    class: 12,
    minMmr: 12,
    maxMmr: 12,
    img: lol_silver,
  },
  {
    name: 'Gold IV',
    shortName: 'G IV',
    class: 13,
    minMmr: 13,
    maxMmr: 13,
    img: lol_gold,
  },
  {
    name: 'Gold III',
    shortName: 'G III',
    class: 14,
    minMmr: 14,
    maxMmr: 14,
    img: lol_gold,
  },
  {
    name: 'Gold II',
    shortName: 'G II',
    class: 15,
    minMmr: 15,
    maxMmr: 15,
    img: lol_gold,
  },
  {
    name: 'Gold I',
    shortName: 'G I',
    class: 16,
    minMmr: 16,
    maxMmr: 16,
    img: lol_gold,
  },
  {
    name: 'Platinum IV',
    shortName: 'P IV',
    class: 17,
    minMmr: 17,
    maxMmr: 17,
    img: lol_platinum,
  },
  {
    name: 'Platinum III',
    shortName: 'P III',
    class: 18,
    minMmr: 18,
    maxMmr: 18,
    img: lol_platinum,
  },
  {
    name: 'Platinum II',
    shortName: 'P II',
    class: 19,
    minMmr: 19,
    maxMmr: 19,
    img: lol_platinum,
  },
  {
    name: 'Platinum I',
    shortName: 'P I',
    class: 20,
    minMmr: 20,
    maxMmr: 20,
    img: lol_platinum,
  },
  {
    name: 'Diamond IV',
    shortName: 'D IV',
    class: 21,
    minMmr: 21,
    maxMmr: 21,
    img: lol_diamond,
  },
  {
    name: 'Diamond III',
    shortName: 'D III',
    class: 22,
    minMmr: 22,
    maxMmr: 22,
    img: lol_diamond,
  },
  {
    name: 'Diamond II',
    shortName: 'D II',
    class: 23,
    minMmr: 23,
    maxMmr: 23,
    img: lol_diamond,
  },
  {
    name: 'Diamond I',
    shortName: 'D I',
    class: 24,
    minMmr: 24,
    maxMmr: 24,
    img: lol_diamond,
  },
  {
    name: 'Master',
    shortName: 'MSTR',
    class: 25,
    minMmr: 25,
    maxMmr: 25,
    img: lol_master,
  },
  {
    name: 'Grandmaster',
    shortName: 'GMR',
    class: 26,
    minMmr: 26,
    maxMmr: 26,
    img: lol_gm,
  },
  {
    name: 'Challenger',
    shortName: 'CHL',
    class: 27,
    minMmr: 27,
    maxMmr: 27,
    img: lol_challenger,
  },
];

export default ranks;
