export const MAX_ORGA_NAME_LENGTH = 100;
export const MAX_ORGA_DESCRIPTION_LENGTH = 500;

export const MAX_ORGA_DETAILS_NAME_LENGTH = 100;
export const MAX_ORGA_DETAILS_LINE1_LENGTH = 100;
export const MAX_ORGA_DETAILS_LINE2_LENGTH = 100;
export const MAX_ORGA_DETAILS_ZIP_LENGTH = 100;
export const MAX_ORGA_DETAILS_COUNTRY_LENGTH = 100;
export const MAX_ORGA_DETAILS_STATE_LENGTH = 100;
export const MAX_ORGA_DETAILS_PHONE_LENGTH = 100;

// export const ORGA_TYPES = ['company', 'club', 'education'];

export const ORGA_TYPES = {
  company: {
    name: 'company',
    subcategories: ['gameStudio', 'hardwareManufacturer', 'softwareCreator', 'bootcamp', 'coaching', 'publisher', 'agency', 'eSportOrg', 'performanceCenter', 'gamingLounge', 'appearal', 'tournamentOrganizer', 'transportation', 'internetElectronics', 'economyIndustry', 'fairExhibition'],
  },
  club: {
    name: 'club',
    subcategories: [],
  },
  association: {
    name: 'association',
    subcategories: [],
  },
  education: {
    name: 'education',
    subcategories: ['middleSchool', 'highSchool', 'university', 'college'],
  },
};

export const ROLES = [
  {
    type: 'owner',
    permissions: ['inviteMember', 'inviteTeam', 'removeAdmin', 'removeMember', 'updateOrga', 'deleteOrga', 'changePermissions', 'changeOrgaRole', 'removeTeam'],
  },
  {
    type: 'admin',
    permissions: ['inviteMember', 'inviteTeam', 'removeMember', 'updateOrga', 'leave'],
  },
  {
    type: 'member',
    permissions: ['leave'],
  },
];

export const ORGA_ROLES = [
  {
    type: '',
  },
  {
    type: 'manager',
  },
  {
    type: 'player',
  },
];

export const getPermissionsForRole = (role) => {
  const roleObj = ROLES.find((r) => r.type === role);

  if (!roleObj) throw new Error('Role not found');

  return roleObj.permissions;
};

export const hasPermission = (role, permission) => {
  const permissions = getPermissionsForRole(role);

  return permissions.includes(permission);
};
