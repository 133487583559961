import React, { useEffect, useMemo, useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import useAPI from 'components/hooks/useAPI';

// Components
import TournamentCreatorPopup from './TournamentCreatorPopup';
import EnteredTournamentList from './EnteredTournamentList';
import TournamentCardDisplay from './TournamentCardDisplay';

// Assets
import headerImg from 'assets/images/backgrounds/banner_tournament.png';
import TournamentFilter from './TournamentFilter';

function TournamentList() {
  const { isAuthenticated } = useAuth0();
  const { t } = useTranslation(['general', 'errors']);
  const { post } = useAPI();

  const [tournaments, setTournaments] = useState([]);

  const [showLoadMoreBtn, setShowLoadMoreBtn] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const [filter, setFilter] = useState({
    region: '',
    game: {
      tag: '',
      mode: '',
    },
    tournamentType: '',
    currentPhase: '',
  });

  const resetFilter = () => {
    setFilter({
      region: '',
      game: {
        tag: '',
        mode: '',
      },
      tournamentType: '',
      currentPhase: '',
    });
  };

  const loadTournaments = async (createdAt, addToOther = false) => {
    try {
      setStatus('');
      setDisabled(true);

      const data = {
        filter,
        createdAt: '',
      };

      if (createdAt) {
        data.createdAt = createdAt;
      }

      let _tournaments = [];

      if (!isAuthenticated) {
        _tournaments = await post('/tournaments/list/public', data);
      } else {
        _tournaments = await post('/tournaments/list', data);
      }

      if (_tournaments.length === 20) {
        setShowLoadMoreBtn(true);
      } else {
        setShowLoadMoreBtn(false);
      }

      if (addToOther) {
        setTournaments([...tournaments, ..._tournaments]);
      } else {
        setTournaments(_tournaments);
      }

      setDisabled(false);
    } catch (e) {
      console.log(e);
      setStatus(t('errors:data_load_error'));
      setDisabled(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const loadMoreTournaments = () => {
    let latestTournament = tournaments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[tournaments.length - 1];

    if (!latestTournament) {
      latestTournament = {
        createdAt: '',
      };
    }

    console.log('latestTournament.createdAt', latestTournament);

    loadTournaments(latestTournament.createdAt, true);
  };

  useEffect(() => {
    loadTournaments('');
  }, [filter]);

  const renderedTournamentCards = useMemo(() => tournaments.map((tournament, index) => (
    <TournamentCardDisplay
      tournament={tournament}
      key={`${tournament.publicId}-${index}`}
    />
  )), [tournaments]);

  return (
    <div className="is-min-fullheight is-relative pt-5 px-6">

      <div
        className="columns is-centered is-mobile is-multiline m-0 px-0 is-min-fullheight"
      >

        <div className="column is-12-desktop is-12-tablet is-12-mobile p-0">

          <div className="mb-6">
            <img
              src={headerImg}
              alt={t('general:tournaments')}
              className="has-fullwidth"
            />
          </div>

          <div className="columns is-multiline">

            <div className="column px-0 has-min-width-450 has-text-right is-flex">
              <div className="has-margin-left-auto is-flex has-content-centered">
                {
                  isAuthenticated && (
                    <div className="mr-4">
                      <TournamentCreatorPopup />
                    </div>
                  )
                }
              </div>
            </div>

          </div>

          <div className="columns is-multiline">

            <div className="column is-flex flex-direction-column">
              <div className="flex-grow">

                <div>

                  {
                    isAuthenticated && (
                      <div className="mx-4 mb-6">
                        <EnteredTournamentList />
                      </div>
                    )
                  }

                  <div className="mt-0">
                    <div className="columns">
                      <div className="column">
                        <h2 className="is-size-3 has-text-standard has-text-weight-light">
                          <i className="fas fa-play mr-4 has-text-primary" />
                          {t('general:find_your_tournament')}
                        </h2>
                      </div>
                      <div className="column has-text-right">
                        <TournamentFilter
                          filter={filter}
                          setFilter={setFilter}
                          resetFilter={resetFilter}
                          isLoading={disabled}
                        />
                      </div>
                    </div>

                    <div className="columns is-centered m-0">
                      <div className="column p-0 is-narrow has-width-85" />
                      <div className="column is-2 has-text-centered">
                        <p>
                          {t('general:title')}
                        </p>
                      </div>
                      <div className="column is-1 has-text-centered">
                        <p>
                          {t('general:start')}
                        </p>
                      </div>
                      <div className="column is-2 has-text-centered">
                        <p>
                          {t('general:type')}
                        </p>
                      </div>
                      <div className="column is-2 has-text-centered">
                        <p>
                          {t('general:game')}
                        </p>
                      </div>
                      <div className="column is-2 has-text-centered">
                        <p>
                          {t('general:mode')}
                        </p>
                      </div>
                      <div className="column has-text-centered">
                        <p>
                          {t('general:status')}
                        </p>
                      </div>
                    </div>
                    {renderedTournamentCards}
                  </div>

                  {
                      showLoadMoreBtn && (
                        <div className="has-text-centered">
                          <button
                            type="button"
                            onClick={loadMoreTournaments}
                            disabled={disabled}
                            className={`button has-text-white has-text-weight-semibold has-background-primary my-4 grow ${disabled ? 'is-loading' : ''}`}
                          >
                            {t('general:load_more')}
                          </button>
                        </div>
                      )
                    }
                </div>

              </div>

            </div>

          </div>

        </div>
      </div>

    </div>

  );
}

export default TournamentList;
