import React from 'react';

// Hooks
import { useOrgaPage } from 'components/orgas/OrgaPage';

// Components
import LeaveOrgaButton from './LeaveOrgaButton';

function OrgaLeaveHandler() {
  const { permissions } = useOrgaPage();
  const { canLeaveOrga } = permissions;

  if (!canLeaveOrga) return null;

  return <LeaveOrgaButton />;
}

export default OrgaLeaveHandler;
