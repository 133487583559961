import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import CardPicDisplay from 'components/utils/CardPicDisplay';

function TournamentCardPicDisplay(props) {
  const { cardPic } = props;

  return (
    <CardPicDisplay url="/images/tournaments/card_pictures/" img={cardPic} quadratic />
  );
}

TournamentCardPicDisplay.propTypes = {
  cardPic: PropTypes.string,
};

TournamentCardPicDisplay.defaultProps = {
  cardPic: '',
};

export default TournamentCardPicDisplay;
