import React, { useEffect, useState } from 'react';

// Libaries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';
import { useTournament } from 'components/tournaments/TournamentPage';

// Components
import Popup from 'reactjs-popup';
import InputEditor from 'components/utils/inputs/InputEditor';
import ReactQuill from 'react-quill';

// Constants
import { MAX_TOURNAMENT_RULE_NAME_LENGTH, MAX_TOURNAMENT_RULE_CONTENT_LENGTH } from 'constants/tournaments';

function EditTournamentRulePopup(props) {
  const { publicId } = useTournament();
  const {
    rule, onSave, reset, remove,
  } = props;

  const [currentRule, setCurrentRule] = useState({
    name: '',
    content: '',
    publicId: '',
    updatedAt: '',
  });

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { post } = useAPI();
  const { t } = useTranslation(['general', 'errors']);

  useEffect(() => {
    if (rule) {
      setCurrentRule(rule);
    }
  }, [rule]);

  const saveRule = async () => {
    try {
      setDisabled(true);
      setStatus('');

      const data = {
        rule: {
          name: currentRule.name,
          content: currentRule.content,
          publicId: currentRule.publicId || '',
        },

      };

      const _rule = await post(`/tournaments/rule/${publicId}`, data);

      onSave(_rule);
    } catch (e) {
      console.log(e);
      setDisabled(false);
      setStatus(`${t('errors:save_data_error')}`);
    }
  };

  const deleteRule = async () => {
    try {
      setDisabled(true);
      setStatus('');

      const data = {
        rulePublicId: currentRule.publicId,
      };

      await post(`/tournaments/rule/delete/${publicId}`, data);

      remove(currentRule);
    } catch (e) {
      console.log(e);
      setDisabled(false);
      setStatus(`${t('errors:could_not_delete_error')}`);
    }
  };

  const setRule = (content) => {
    setStatus('');

    if (content.length > MAX_TOURNAMENT_RULE_CONTENT_LENGTH) {
      setStatus(t('errors:character_limit_exceeded_error'));
    }

    setCurrentRule({ ...currentRule, content });
  };

  return (
    <Popup
      open={!!rule}
      position="top center"
      keepTooltipInside="#root"
      repositionOnResize
      closeOnDocumentClick
      closeOnEscape
      modal
      onClose={() => {
        setDisabled(false);
        reset();
      }}
    >
      {
        rule && (
          (close) => (
            <div
              style={{ height: '80vh' }}
              className="is-fullwidth has-min-width-300 has-max-width-1000 has-overflow-hidden box boxshadow has-background-black-gradient p-0 is-flex flex-direction-column"
            >
              <div
                className="has-background-primary has-text-centered p-4 pb-6 is-relative br-5"
              >
                <div className="has-text-right">
                  <button
                    type="button"
                    className=" cleanButton has-text-white"
                    onClick={close}
                    aria-label={t('general:close')}
                  >
                    <i className="fas fa-times" />
                  </button>
                </div>
                <p
                  className="has-text-weight-semibold is-size-3"
                >
                  {t('general:tournament_rule')}
                </p>

                <div
                  className="br-100 has-background-primary is-absolute is-flex has-content-centered"
                  style={{
                    left: 'calc(50% - 45px)', width: '90px', height: '85px', zIndex: '999',
                  }}
                >
                  <i className="fas fa-image is-size-2" />
                </div>

              </div>

              <div
                className="control px-6 py-6 flex-grow has-overflow-y-auto"
              >

                {
                    currentRule.publicId && (
                      <div className="has-text-right">
                        <button
                          type="button"
                          className="button is-danger grow_small has-text-weight-semibold mb-2"
                          onClick={deleteRule}
                          disabled={disabled}
                        >
                          {t('general:delete')}
                        </button>
                      </div>
                    )
                  }
                <div className="">
                  <InputEditor
                    label="name"
                    text={currentRule.name}
                    setText={(name) => setCurrentRule({ ...currentRule, name })}
                    maxLength={MAX_TOURNAMENT_RULE_NAME_LENGTH}
                  />
                </div>

                <div className="mt-6">
                  <ReactQuill
                    theme="snow"
                    value={currentRule.content}
                    onChange={setRule}
                  />
                </div>

                <div className="mt-5 has-text-centered">
                  <button
                    type="button"
                    className="button is-primary grow_small has-text-weight-semibold"
                    onClick={saveRule}
                    disabled={disabled || !currentRule.name || !currentRule.content || currentRule.content.length > MAX_TOURNAMENT_RULE_CONTENT_LENGTH}
                  >
                    {t('general:save')}
                  </button>
                  {
                        status && (
                        <p className="mt-4">
                            { status }
                        </p>
                        )
                    }
                </div>
              </div>

            </div>
          )
        )
    }
    </Popup>
  );
}

EditTournamentRulePopup.propTypes = {
  rule: PropTypes.shape({
    name: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }),
  onSave: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
};

EditTournamentRulePopup.defaultProps = {
  rule: null,
};

export default EditTournamentRulePopup;
