import React, { useMemo } from 'react';

// Hooks
import { useHotscrimps } from 'components/HotScrimps/HotscrimpsProvider';
import { useTranslation } from 'react-i18next';

// Components
import PendingHotscrimp from './PendingHotscrimp';
import SentRequestsList from './SentRequestsList';

function PendingHotscrimpList() {
  const { pendingHotscrimps, sentRequests } = useHotscrimps();

  const { t } = useTranslation(['general', 'hotscrimps']);

  const renderedPending = useMemo(
    () => pendingHotscrimps.map((hotscrimp) => (
      <PendingHotscrimp
        hotscrimp={hotscrimp}
        key={hotscrimp.publicId}
      />
    )),
    [pendingHotscrimps],
  );

  return (
    <div className="has-overflow-y-auto has-fullheight">
      <div>
        {
          pendingHotscrimps.length > 0 && (
            <div className="">
              <div className="has-text-centered">
                <p className="has-text-grey-light mt-2">
                  {t('hotscrimps:hotscrimps_of_your_teams')}
                </p>
              </div>
              { renderedPending }
            </div>
          )
        }

        <div>
          {
            sentRequests.length > 0 && (
              <div>
                <SentRequestsList />
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default PendingHotscrimpList;
