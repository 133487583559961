// Rank images
import rss_copper4 from 'assets/images/games/rss/rss_copper4.png';
import rss_copper3 from 'assets/images/games/rss/rss_copper3.png';
import rss_copper2 from 'assets/images/games/rss/rss_copper2.png';
import rss_copper1 from 'assets/images/games/rss/rss_copper1.png';
import rss_bronze4 from 'assets/images/games/rss/rss_bronze4.png';
import rss_bronze3 from 'assets/images/games/rss/rss_bronze3.png';
import rss_bronze2 from 'assets/images/games/rss/rss_bronze2.png';
import rss_bronze1 from 'assets/images/games/rss/rss_bronze1.png';
import rss_silver4 from 'assets/images/games/rss/rss_silver4.png';
import rss_silver3 from 'assets/images/games/rss/rss_silver3.png';
import rss_silver2 from 'assets/images/games/rss/rss_silver2.png';
import rss_silver1 from 'assets/images/games/rss/rss_silver1.png';
import rss_gold4 from 'assets/images/games/rss/rss_gold4.png';
import rss_gold3 from 'assets/images/games/rss/rss_gold3.png';
import rss_gold2 from 'assets/images/games/rss/rss_gold2.png';
import rss_gold1 from 'assets/images/games/rss/rss_gold1.png';
import rss_platinum3 from 'assets/images/games/rss/rss_platinum3.png';
import rss_platinum2 from 'assets/images/games/rss/rss_platinum2.png';
import rss_platinum1 from 'assets/images/games/rss/rss_platinum1.png';
import rss_diamond from 'assets/images/games/rss/rss_diamond.png';
import rss_champion from 'assets/images/games/rss/rss_champion.png';

const ranks = [
  {
    name: 'Unranked',
    shortName: 'UNRKD',
    class: 0,
    minMmr: 0,
    maxMmr: 0,
    img: rss_copper4,
  },
  {
    name: 'Copper IV',
    shortName: 'C IV',
    class: 1,
    minMmr: 1,
    maxMmr: 1,
    img: rss_copper4,
  },
  {
    name: 'Copper III',
    shortName: 'C III',
    class: 2,
    minMmr: 2,
    maxMmr: 2,
    img: rss_copper3,
  },
  {
    name: 'Copper II',
    shortName: 'C II',
    class: 3,
    minMmr: 3,
    maxMmr: 3,
    img: rss_copper2,
  },
  {
    name: 'Copper I',
    shortName: 'C I',
    class: 4,
    minMmr: 4,
    maxMmr: 4,
    img: rss_copper1,
  },
  {
    name: 'Bronze IV',
    shortName: 'B IV',
    class: 5,
    minMmr: 5,
    maxMmr: 5,
    img: rss_bronze4,
  },
  {
    name: 'Bronze III',
    shortName: 'B III',
    class: 6,
    minMmr: 6,
    maxMmr: 6,
    img: rss_bronze3,
  },
  {
    name: 'Bronze II',
    shortName: 'B II',
    class: 7,
    minMmr: 7,
    maxMmr: 7,
    img: rss_bronze2,
  },
  {
    name: 'Bronze I',
    shortName: 'B I',
    class: 8,
    minMmr: 8,
    maxMmr: 8,
    img: rss_bronze1,
  },
  {
    name: 'Silver IV',
    shortName: 'S IV',
    class: 9,
    minMmr: 9,
    maxMmr: 9,
    img: rss_silver4,
  },
  {
    name: 'Silver III',
    shortName: 'S III',
    class: 10,
    minMmr: 10,
    maxMmr: 10,
    img: rss_silver3,
  },
  {
    name: 'Silver II',
    shortName: 'S II',
    class: 11,
    minMmr: 11,
    maxMmr: 11,
    img: rss_silver2,
  },
  {
    name: 'Silver I',
    shortName: 'S I',
    class: 12,
    minMmr: 12,
    maxMmr: 12,
    img: rss_silver1,
  },
  {
    name: 'Gold IV',
    shortName: 'G IV',
    class: 13,
    minMmr: 13,
    maxMmr: 13,
    img: rss_gold4,
  },
  {
    name: 'Gold III',
    shortName: 'G III',
    class: 14,
    minMmr: 14,
    maxMmr: 14,
    img: rss_gold3,
  },
  {
    name: 'Gold II',
    shortName: 'G II',
    class: 15,
    minMmr: 15,
    maxMmr: 15,
    img: rss_gold2,
  },
  {
    name: 'Gold I',
    shortName: 'G I',
    class: 16,
    minMmr: 16,
    maxMmr: 16,
    img: rss_gold1,
  },
  {
    name: 'Platin III',
    shortName: 'P III',
    class: 17,
    minMmr: 17,
    maxMmr: 17,
    img: rss_platinum3,
  },
  {
    name: 'Platin II',
    shortName: 'P II',
    class: 18,
    minMmr: 18,
    maxMmr: 18,
    img: rss_platinum2,
  },
  {
    name: 'Platin I',
    shortName: 'P I',
    class: 19,
    minMmr: 19,
    maxMmr: 19,
    img: rss_platinum1,
  },
  {
    name: 'Diamond',
    shortName: 'DIA',
    class: 20,
    minMmr: 20,
    maxMmr: 20,
    img: rss_diamond,
  },
  {
    name: 'Champion',
    shortName: 'CHAMP',
    class: 21,
    minMmr: 21,
    maxMmr: 21,
    img: rss_champion,
  },
];

export default ranks;
