import React, { useMemo, useState } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Constants
import { TOURNAMENT_TYPES } from 'constants/tournaments';

// Components
import Dropdown from 'components/utils/Dropdown';

function TournamentTypeSelector() {
  const {
    currentPhase, tournamentSettings, setTournamentType, publicId,
  } = useTournament();
  const {
    tournamentType,
  } = tournamentSettings;

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { t } = useTranslation(['general']);
  const { post } = useAPI();

  const saveNewTournamentType = async (newTournamentType) => {
    try {
      setDisabled(true);
      setStatus('');
      const data = { tournamentType: newTournamentType.value };

      await post(`/tournaments/settings/tournamentType/${publicId}`, data);

      setTournamentType(newTournamentType.value);

      stats.push(['trackEvent', 'Tournament Editor', 'New tournament type set']);

      setDisabled(false);
    } catch (e) {
      console.log(e);
      setDisabled(false);
      setStatus(t('errors:save_error'));
    }
  };

  const availableTournamentTypes = useMemo(() => TOURNAMENT_TYPES.map((_tournamentType) => ({
    label: t(`general:${_tournamentType}`),
    value: _tournamentType,
  })), [TOURNAMENT_TYPES]);

  const selectedTournamentType = useMemo(() => {
    const _selectedTournamentType = availableTournamentTypes.find((_tournamentType) => _tournamentType.value === tournamentType);

    return _selectedTournamentType || null;
  }, [availableTournamentTypes, tournamentType]);

  const renderedTournamentTypeSelector = useMemo(() => {
    if (currentPhase !== 'draft') {
      return (
        <div className="">
          <p className="is-size-5 has-text-grey mb-2">
            {t('general:tournament_type')}
          </p>
          <div className="has-fullwidth">
            <p>
              {t(`general:${tournamentType}`)}
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="has-fullwidth">
        <p className="is-size-5 has-text-grey mb-2">
          {t('general:tournament_type')}
        </p>
        <div className="has-fullwidth">
          <Dropdown
            items={availableTournamentTypes}
            onSelect={saveNewTournamentType}
            value={selectedTournamentType}
            disabled
          />
        </div>
      </div>

    );
  }, [currentPhase, availableTournamentTypes, tournamentType, disabled, selectedTournamentType, t]);

  return (
    <div className="field">
      { renderedTournamentTypeSelector }
      {
        status && (
        <p className="has-text-weight-semibold mt-4">
          { status }
        </p>
        )
      }
    </div>
  );
}

export default TournamentTypeSelector;
