import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

function AsyncTextareaEditor(props) {
  const {
    text, label, maxLength, onSave,
  } = props;

  const { t } = useTranslation(['general', 'errors']);

  const [newText, setNewText] = useState(text);
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const _setText = (_text) => {
    setStatus('');

    if (_text.length >= maxLength) {
      setStatus(`${t('errors:max_length_reached_error')}!`);
      return;
    }

    setNewText(_text);
  };

  const saveText = async () => {
    try {
      setStatus('');
      setDisabled(true);

      await onSave(newText);

      setDisabled(false);
      setStatus(`${t('general:saved_successfully')}`);
    } catch (e) {
      console.log(e);
      setDisabled(false);
      setStatus(`${t('errors:could_not_save_error')}`);
    }
  };

  const showSaveButton = newText !== text;

  return (
    <div className="">
      <div className="is-flex">
        <div className="is-inline-block flex-grow has-min-width-100 has-text-left">
          <div className="control">
            <label className="has-text-grey has-text-weight-normal is-size-5">
              {t(`general:${label}`)}
            </label>
            <textarea
              className="textarea br-5 no-shadow has-border p-3 has-fixed-size is-size-6 is-paddingless is-inline has-fullheight mt-2"
              type="text"
              value={newText}
              placeholder=""
              disabled={disabled}
              onChange={(e) => _setText(e.target.value)}
            />
          </div>

        </div>
        {
        showSaveButton && (
        <div className="mx-2 has-text-left has-margin-top-auto">
          <button
            type="button"
            className="button is-block is-primary grow_small has-text-weight-semibold"
            onClick={saveText}
            disabled={disabled}
          >
            {t('general:save')}
          </button>
        </div>
        )
      }
      </div>

      {
        status && (
          <p className="has-text-weight-semibold mt-4">
            { status }
          </p>
        )
      }
    </div>
  );
}

AsyncTextareaEditor.propTypes = {
  text: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  label: PropTypes.string,
};

AsyncTextareaEditor.defaultProps = {
  label: 'text',
};

export default AsyncTextareaEditor;
