import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';

function RecipeImgDisplay(props) {
  const {
    id, src, alt, className, style,
  } = props;

  const { language } = useSelector((state) => state.session);

  const basePath = `/recipes/${language}/${id}/`;

  return (
    <img
      src={`${basePath}${src}`}
      alt={alt}
      style={style}
      className={className}
    />
  );
}

RecipeImgDisplay.propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

RecipeImgDisplay.defaultProps = {
  alt: '',
  className: '',
  style: {},
};

export default RecipeImgDisplay;
