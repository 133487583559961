import React, { useState } from 'react';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { setPlayer } from 'store/player';

// Libraries
import stats from 'analytics/matomo';
import PropTypes from 'prop-types';

// Assets
import onboardingImg from 'assets/images/base/onboarding_player_details.png';

// Conponents
import NationalitySelector from './NationalitySelector';
// import EngagementSelector from './EngagementSelector';
import DescriptionEditor from './DescriptionEditor';

function PlayerDetailsEditor(props) {
  const { onDone } = props;

  const [nationality, setNationality] = useState('de');
  // const [engagement, setEngagement] = useState('just4fun');
  const [description, setDescription] = useState('');

  const { player } = useSelector((state) => state.player);

  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState('');

  const { post } = useAPI();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const savePlayer = async () => {
    try {
      setError('');
      setDisabled(true);

      const {
        name, region, contact, hasProfilePrivate,
      } = player;

      const data = {
        name,
        description,
        region,
        nationality,
        // engagement,
        contact,
        hasProfilePrivate,
      };

      await post('/player/profile', data);

      dispatch(setPlayer(data));

      stats.push(['trackEvent', 'Player Onboarding', 'Profile set']);

      setDisabled(false);

      onDone();
    } catch (e) {
      setDisabled(false);
      setError(t('errors:could_not_save_error'));
    }
  };

  /*
              <div className="mt-3">
                <EngagementSelector engagement={engagement} setEngagement={setEngagement} />
              </div>
  */

  return (

    <div className="box p-0 has-text-centered has-min-fullheight">

      <div className="columns has-fullheight is-multiline">

        <div
          className="column is-5-desktop has-min-width-300 is-5-tablet p-0"
        >
          <div
            className="has-fullheight has-text-left"
            style={{ backgroundImage: `url(${onboardingImg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
          >
            <div className="p-5">
              <h1
                className="is-size-1-dekstop is-size-2-tablet has-text-weight-semibold"
              >
                {t('general:onboarding')}
              </h1>
              <p
                className="is-size-1-desktop is-size-2-desktop has-text-grey"
              >
                {t('general:player_details')}
              </p>
            </div>
          </div>
        </div>

        <div className="column p-0">
          <div className="field p-6 is-flex flex-direction-column has-fullheight is-relative">

            <div
              style={{
                position: 'absolute',
                width: '0px',
                height: '0px',
                right: '0px',
                top: '0px',

                // borderRadius: '5px',

                borderTop: '75px solid #086842',
                borderLeft: '75px solid transparent',
              }}
            />

            <div>
              <h2
                className="has-text-weight-normal is-size-3-desktop"
              >
                {`${t('general:your_first_player_details')}`}
              </h2>
              <p className="mt-5 has-text-grey-lighter">
                {`${t('general:player_details_explanation')}.`}
              </p>
            </div>

            <div className="has-margin-auto has-fullwidth has-max-width-350 mt-6">

              <NationalitySelector nationality={nationality} setNationality={setNationality} />

              <div className="mt-3">
                <DescriptionEditor description={description} setDescription={setDescription} />
              </div>

              <button
                type="button"
                className="button is-size-5-desktop is-primary grow_small has-text-weight-semibold mt-5 boxshadow"
                style={{ backgroundColor: '#086842' }}
                onClick={savePlayer}
                disabled={disabled}
              >
                {t('general:continue')}
              </button>

              <div className="has-text-centered">
                <button
                  type="button"
                  className="cleanButton has-text-white has-text-weight-semibold mt-5"
                  onClick={onDone}
                  disabled={disabled}
                >
                  {t('general:skip')}
                </button>
              </div>
              {
                error && (
                  <p className="has-text-weight-semibold has-text-white mt-4">
                    {error}
                  </p>
                )
              }
            </div>

          </div>
        </div>

      </div>

    </div>

  );
}

PlayerDetailsEditor.propTypes = {
  onDone: PropTypes.func.isRequired,
};

export default PlayerDetailsEditor;
