import React, { useMemo, useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Libraries
import PropTypes from 'prop-types';

// Components
import ParticipantDragHandler from './ParticipantDragHandler';

// Shows the match and draws the lines to the next match, if it exists
function SeedingMatchDisplay(props) {
  const {
    match: matchData, roundId, positionX, positionY, matchNumber: displayMatchNumber,
  } = props;

  const { t } = useTranslation(['general']);

  const {
    participants,
  } = matchData;

  // super hacky way to force a rerender when the participants change
  const _participantsToCheck = [participants[0], participants[1]];

  const participantData = useMemo(() => {
    let _participants = participants;

    if (_participants.length === 0) {
      _participants = [
        {
          cardPic: '',
          name: '',
        },
        {
          cardPic: '',
          name: '',
        },
      ];
    } else if (_participants.length === 1) {
      _participants.push({
        cardPic: '',
        name: '',
      });
    }

    return _participants;
  }, [participants, _participantsToCheck]);

  const renderedParticipants = participantData.map((participant, index) => {
    const draggableId = index === 0 ? `${roundId}_${displayMatchNumber}_0` : `${roundId}_${displayMatchNumber}_1`;

    return (
      <ParticipantDragHandler
        participant={participant}
        id={draggableId}
        isFirst={index === 0}
        key={draggableId}
      />

    );
  });

  return (
    <div
      className="has-height-125 has-width-250 is-absolute is-flex flex-direction-column"
      style={{ top: `${positionY}px`, left: `${positionX}px` }}
    >
      <div className="has-text-centered has-text-grey mb-1">
        <p className="">
          {`${t('general:match')} ${displayMatchNumber}`}
        </p>
      </div>
      <div className="box p-0 m-0 is-flex flex-direction-column flex-grow has-overflow-hidden">
        {renderedParticipants}
      </div>
    </div>
  );
}

SeedingMatchDisplay.propTypes = {
  match: PropTypes.shape({
    participants: PropTypes.arrayOf({
      cardPic: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    result: PropTypes.arrayOf({
      score: PropTypes.number.isRequired,
    }).isRequired,
    round: PropTypes.number.isRequired,
    match: PropTypes.number.isRequired,
  }).isRequired,
  roundId: PropTypes.number.isRequired,
};

export default SeedingMatchDisplay;
