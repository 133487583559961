import React from 'react';
// Hooks
import { useHotscrimp } from 'components/HotScrimps/HotscrimpsDisplayPage';
import { useTranslation } from 'react-i18next';

// Components
import LoginPopup from 'components/utils/LoginPopup';
import Popup from 'reactjs-popup';

function HotscrimpAuthPopup() {
  const {
    showNoAuthPopup, setShowNoAuthPopup,
  } = useHotscrimp();

  const { t } = useTranslation(['general']);

  return (
    <Popup
      position="top center"
      keepTooltipInside="#root"
      closeOnDocumentClick
      closeOnEscape
      modal
      open={showNoAuthPopup}
      onClose={() => setShowNoAuthPopup(false)}
    >
      {
        () => (
          <LoginPopup
            text={t('general:login_to_create_a_request')}
            close={() => setShowNoAuthPopup(false)}
          />
        )
      }
    </Popup>
  );
}

export default HotscrimpAuthPopup;
