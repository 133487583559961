import React, { useMemo, useEffect, useState } from 'react';

// Libraries
import PropTypes from 'prop-types';
import { getSelectedGame } from 'constants/games';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Constants
import { MAX_AMOUNT_TAGS } from 'constants/playerboard';
import Dropdown from '../Dropdown';

function TagSelectorDropdown(props) {
  const {
    tag, setTag, game, disabled,
  } = props;

  const { language } = useSelector((state) => state.session);

  const { t } = useTranslation(['general', 'tags']);

  const [isInit, setInit] = useState(true);

  // reset tags when game changes
  useEffect(() => {
    // dont change anything on init, only when game really changes
    if (isInit) {
      setInit(false);
      return;
    }

    setTag();
  }, [game]);

  const _setTag = (newTag) => {
    if (tag === newTag) return;

    setTag(newTag);
  };

  const selectedGame = useMemo(() => getSelectedGame(game), [game]);

  const preparedTags = useMemo(() => {
    if (!game) return [];

    const _preparedTags = [
      { label: t('general:all_tags'), value: '' },
    ];

    const _tagsWithCategory = selectedGame.tagsPerCategory.map((categoryData) => {
      const { category, tags: allTags } = categoryData;

      const renderedTags = allTags.map((_tag) => ({ value: _tag, label: _tag }));

      return {
        label: t(`tags:category_${category}`),
        options: renderedTags,
      };
    });

    return [..._preparedTags, ..._tagsWithCategory];
  }, [game, tag, disabled, language]);

  const activeTag = useMemo(() => {
    if (!tag) return preparedTags[0];

    let _activeTag = '';

    preparedTags.forEach((_categories) => {
      const { options } = _categories;

      // first element does not have options, so just skip it
      if (!options) return;

      const foundTag = options.find((_tag) => _tag.value === tag);

      if (foundTag) _activeTag = foundTag.value;
    });

    return _activeTag;
  }, [tag, game]);

  if (!game) return null;

  return (
    <div className="field">

      <div className="">
        <p className="has-text-weight-semibold is-size-7 mb-1">
          {t('general:tags')}
        </p>
        <div className="has-width-200">
          <Dropdown
            items={preparedTags}
            onSelect={_setTag}
            value={activeTag}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
}

TagSelectorDropdown.propTypes = {
  tag: PropTypes.string,
  setTag: PropTypes.func.isRequired,
  game: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

TagSelectorDropdown.defaultProps = {
  disabled: false,
  tag: null,
};

export default TagSelectorDropdown;
