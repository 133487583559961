import React from 'react';

// Libaries
import PropTypes from 'prop-types';

// Components
import FilterPopup from './FilterPopup';

function FilterSelector(props) {
  const {
    isLoading, filter, setFilter, resetFilter,
  } = props;

  return (
    <div className="">

      <div className="has-text-right is-flex has-content-centered-vertically">
        <div className="has-margin-left-auto">
          <FilterPopup
            isLoading={isLoading}
            filter={filter}
            setFilter={setFilter}
            resetFilter={resetFilter}
          />
        </div>
        <button
          onClick={resetFilter}
          type="button"
          className="cleanButton ml-3"
        >
          <i className="fas fa-redo-alt has-text-primary is-size-5" />
        </button>
      </div>

    </div>
  );
}

FilterSelector.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  filter: PropTypes.shape({}).isRequired,
  setFilter: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,

};

export default FilterSelector;
