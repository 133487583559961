import React, { useState, useEffect } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useOrgaPage } from 'components/orgas/OrgaPage';
import CopyButtonSimple from 'components/utils/CopyButtonSimple';

function OrgaIdDisplay() {
  const { publicId } = useOrgaPage();

  const { t } = useTranslation(['gemeral']);

  return (

    <div
      className="cleanButton has-text-white has-text-left"
    >

      <div className="is-flex has-content-centered-vertically mt-1">
        <CopyButtonSimple
          text={window.location.href}
          className="cleanButton has-text-white"
        >
          <div className="has-text-left">
            <div className="">
              <p className="is-size-4-desktop is-size-5-touch">
                ORG-ID
              </p>

            </div>
            <div className="is-flex has-content-centered-vertically">
              <p className="mr-3 has-text-primary is-size-4">
                { `SCR-OID-${publicId}` }
              </p>
              <i className="fas fa-copy is-size-6" />
            </div>
          </div>
        </CopyButtonSimple>

      </div>

    </div>

  );
}

export default OrgaIdDisplay;
