import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useOrgaPage } from 'components/orgas/OrgaPage';

/**
 * @name OrgaEditRoleButton
 * @description Button that opens a popup to edit a orga member's role, orgaRole and ingameRole
 */
function OrgaEditRoleButton(props) {
  const { member } = props;

  const { setMemberToSetRoles } = useOrgaPage();

  const _setMemberToSetRoles = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setMemberToSetRoles(member);
  };

  return (
    <div className="is-inline-block">
      <button
        type="button"
        className="button is-primary p-3 is-size-7"
        onClick={_setMemberToSetRoles}
      >
        <i className="fas fa-cog has-text-white" />
      </button>
    </div>
  );
}

OrgaEditRoleButton.propTypes = {
  member: PropTypes.shape({
    playerId: PropTypes.string.isRequired,
    orgaRole: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  }).isRequired,
};

export default OrgaEditRoleButton;
