import React from 'react';

// Libraries
import { Link } from 'react-router-dom';

// hocs
import withAuthentication from 'components/hocs/withAuthentication';

// hooks
import useCards from 'components/hooks/useCards';

// constants
import { MATCHES, CARD_CREATE, MATCHES_SEARCH } from 'constants/routes';
import { MAX_AMOUNT_CARDS } from 'constants/cards';

import CardCreator from 'components/cards/CardCreator';
import MatchCard from 'components/matches/MatchCard';
import { Helmet } from 'react-helmet-async';

function CardContainer() {
  const { cards } = useCards();

  if (cards.length === 0) {
    return <CardCreator />;
  }

  const showCards = () => {
    const allCards = cards.map((card, index) => (
      <div className="column is-narrow" key={card._id}>
        <MatchCard
          topElements={(
            <div className="columns is-marginless is-mobile">
              <div className="column pb-2">
                <Link
                  to={`${MATCHES}/${index}`}
                  className="button is-primary is-rounded has-text-white has-text-weight-semibold has-fullwidth"
                >
                  Matches
                </Link>
              </div>
              <div className="column has-text-right pb-2">
                <Link
                  to={`${CARD_CREATE}/${index}`}
                >
                  <i className="fas fa-cog has-text-secondary is-size-5" />
                </Link>
              </div>
            </div>
          )}
          data={cards[index]}
        >
          <div className="has-text-centered">
            <Link
              to={`${MATCHES_SEARCH}/${index}`}
              className="button has-background-secondary has-text-weight-semibold my-2 is-rounded"
            >
              Jetzt slippen
            </Link>
          </div>
        </MatchCard>
      </div>
    ));

    return (
      <div className="columns is-multiline is-centered is-mobile">
        { allCards }
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Scrimps - Deine Suchkarten</title>
      </Helmet>

      <div className="px-2 py-3 is-min-fullheight">
        <div className="mb-2">
          <h1 className="has-text-weight-semiboldis-size-2-desktop is-size-3-tablet is-size-4-mobile">
            { `Deine Suchkarten (${cards.length}/${MAX_AMOUNT_CARDS})`}
          </h1>
        </div>
        { cards.length < MAX_AMOUNT_CARDS
          && (
          <div className="my-3">
            <Link
              to={CARD_CREATE}
              className="button has-background-primary is-rounded has-text-weight-semibold"
            >
              Neue Suchkarte erstellen
            </Link>
          </div>
          )}
        { showCards() }
      </div>
    </>
  );
}

export default withAuthentication(CardContainer);
