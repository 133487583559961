import React, { useMemo } from 'react';

// Libraries
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import { getCurrentLocale } from 'locales/i18n';
import { getSelectedGame } from 'constants/games';

// Assets
import defaultAvatar from 'assets/images/profile/default_avatar.png';
import switch_icon from 'assets/images/icons/switch_icon.png';

// Styles
import styles from './styles.module.scss';

const PlayerCard = React.forwardRef(({ open, ...props }, ref) => {
  const { index } = props;

  const { timezone, language } = useSelector((state) => state.session);
  const { entries, userEntry } = useSelector((state) => state.playerboard);

  const { t } = useTranslation(['general', 'tags']);

  const entry = index === -1 ? userEntry : entries[index];
  const {
    cardPic, name, languages, rank, platform, tags, postedAt, nationality, engagement,
  } = entry;

  const renderTags = () => {
    const _tags = [];

    for (let i = 0; i < Math.min(tags.length, 2); i++) {
      const tag = tags[i];
      // { t(`tags:tag_${tag}`)}
      _tags.push(
        <div className="my-1 mx-1" key={i}>
          <div className="has-background-primary has-text-weight-normal is-inline-block br-5 px-1 py-1 boxshadow is-size-7">
            <p>
              { tag }
            </p>
          </div>
        </div>,
      );
    }

    return _tags;
  };

  const renderPlatform = () => {
    switch (platform) {
      case 'pc': return <i className="fas fa-desktop is-size-4" />;
      case 'ps': return <i className="fab fa-playstation is-size-4" />;
      case 'xbox': return <i className="fab fa-xbox is-size-4" />;
      case 'switch': return <img src={switch_icon} className="has-width-25 has-height-25" alt="" />;

      default: return null;
    }
  };

  const renderMMrImage = (mmrImage) => {
    if (!mmrImage) return null;

    return (
      (
        <div className="boxshadow has-max-width-40 has-height-40 p-1 has-background-primary br-5">
          <img src={mmrImage} alt="" className="has-fullwidth br-5 has-fullheight has-border" />
        </div>
      )
    );
  };

  const getGlowBorder = () => {
    if (index === -1) return 'has-playerboard-userentry-border';

    if (engagement === 'competitive') return 'has-playerboard-competitive-border';

    return 'has-playerboard-just4fun-border';
  };

  const renderCard = () => {
    // const locale = getCurrentLocale();
    // const renderedPostedAt = DateTime.fromISO(postedAt).setZone(timezone).setLocale(locale).toRelative();

    const { game, mmr, mode } = rank;

    const { modes, icon: gameIcon } = getSelectedGame(game);

    const urlToPic = cardPic ? `${process.env.REACT_APP_CDN_URL}/images/card_pictures/${cardPic}` : defaultAvatar;

    let modeData = modes.find((_mode) => _mode.name === mode);
    if (!modeData) modeData = { ranks: [] };

    let mmrRank = modeData.ranks.find((_rank) => _rank.minMmr <= mmr && _rank.maxMmr >= mmr);
    if (!mmrRank) mmrRank = { img: '', name: '' };

    const renderedTags = renderTags();
    const renderedMmrImage = renderMMrImage(mmrRank.img);
    const renderedMmrName = mmrRank.name;

    const glowBorder = getGlowBorder();
    const renderedPlatform = renderPlatform();

    return (
      <button
        type="button"
        className="cleanButton is-size-6 has-text-left grow p-2"
      >
        <div
          id={styles.card}
          className={`br-10 has-text-white has-width-180 has-height-300 has-overflow-hidden is-flex flex-direction-column ${glowBorder}`}
          style={{ backgroundImage: `url(${urlToPic})` }}
        >

          <div className="columns m-0 is-mobile">
            <div className="column is-narrow has-text-centered p-0">
              <p
                className="break-word has-background-primary has-text-white py-1 px-4 has-text-weight-semibold br-5"
                style={{ borderTopLeftRadius: '5px', borderBottomLeftRadius: '0', borderTopRightRadius: '0px' }}
              >
                LFT
              </p>
            </div>

            <div className="column is-narrow has-margin-left-auto p-0">
              <div
                className="has-text-white has-background-primary px-2 pb-1 pt-2 has-text-weight-semibold br-5 has-text-centered"
                style={{
                  borderTopRightRadius: '5px',
                  borderBottomRightRadius: '0',
                  borderTopLeftRadius: '0px',
                }}
              >
                <img src={gameIcon} alt="" className="has-width-32 has-height-32 br-10" />
                <p
                  className="has-text-centered p-1"
                >
                  { renderedPlatform }
                </p>
              </div>

            </div>

          </div>

          <div className="has-margin-top-auto mb-5">
            { renderedTags }
          </div>

          <div
            className="is-relative pt-3 pb-1 has-background-blur"
          >

            <div
              className="mt-2 is-absolute has-fullwidth"
              style={{ top: '-30px' }}
            >
              <div className="mx-3 is-flex has-content-centered-vertically">
                <span
                  className={`is-size-4 br-5 boxshadow is-block has-fullheight fi fi-${nationality}`}
                />
                <div className="has-margin-left-auto has-text-centered">
                  { renderedMmrImage }
                </div>
              </div>
            </div>

            <div className="py-2 px-3 mt-1">
              <div className="is-flex has-content-centered-vertically">
                <p
                  className="is-size-7 has-text-weight-semibold mr-2 has-text-standard"
                >
                  { name }
                </p>

              </div>
              <p
                className="has-text-grey-white has-text-weight-normal is-size-7"
              >
                { renderedMmrName }
              </p>
            </div>

          </div>

        </div>
      </button>
    );
  };

  return (
    <div ref={ref} {...props} className="">
      { renderCard() }
    </div>
  );
});

PlayerCard.propTypes = {
  index: PropTypes.number.isRequired,
};

export default PlayerCard;
