import React, { useState, useMemo, useContext } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useTournament } from 'components/tournaments/TournamentPage';

// Components
import TournamentInviteStaffButton from './TournamentInviteStaffButton';
import TournamentStaffDisplayPopup from './TournamentStaffDisplayPopup';
import TournamentStaffListPopup from './TournamentStaffListPopup';
import TournamentStaffEditRolePopup from './TournamentStaffEditRolePopup';
import TournamentStaffKickPopup from './TournamentStaffKickPopup';

const StaffContext = React.createContext({});

function TournamentStaffHandler() {
  const { staff, staffPermissions } = useTournament();
  const { canInviteStaff } = staffPermissions;

  const { t } = useTranslation(['general']);

  const [staffToKick, setStaffToKick] = useState(null);
  const [staffToEdit, setStaffToEdit] = useState(null);

  const renderedStaff = useMemo(() => staff.map((staffMember, index) => (
    <div
      key={index}
      className="column is-narrow"
    >
      <TournamentStaffDisplayPopup key={staffMember.publicId} staffMember={staffMember} />
    </div>
  )), [staff]);

  return (
    <StaffContext.Provider value={{
      staffToKick,
      staffToEdit,

      setStaffToKick,
      setStaffToEdit,
    }}
    >
      <div>
        <TournamentStaffEditRolePopup />
        <TournamentStaffKickPopup />
        <div className="is-inline-flex has-content-centered">
          <p className="is-size-4 mr-4">
            { t('general:staff')}
          </p>

          {
          canInviteStaff && (
            <TournamentInviteStaffButton />
          )
        }

          <div className="mx-2">
            <TournamentStaffListPopup />
          </div>

        </div>

        <div className="mt-5">
          <div className="columns is-multiline">
            {renderedStaff}
          </div>
        </div>
      </div>
    </StaffContext.Provider>
  );
}

export const useTournamentStaff = () => {
  const data = useContext(StaffContext);

  return data;
};

export default TournamentStaffHandler;
