import React, { useMemo } from 'react';

// Libaries
import { getCurrentLocale } from 'locales/i18n';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Components
import Dropdown from 'components/utils/Dropdown';
import stats from 'analytics/matomo';

function DateFilter(props) {
  const { isLoading, date: currentDate, setDateFilter } = props;

  const {
    timezone, language,
  } = useSelector((state) => state.session);

  const { t } = useTranslation(['general']);

  const setDay = (date) => {
    const current = currentDate ? DateTime.fromISO(currentDate).setZone(timezone) : DateTime.now().setZone(timezone);

    // if we have no date, just set the value
    if (!date.value) {
      stats.push(['trackEvent', 'Hot Scrimps', 'Set day']);

      setDateFilter('');
      return;
    }

    const newDate = DateTime.fromISO(date.value).setZone(timezone);

    // compare them, only set the new date if its not the same
    const isTheSame = newDate.year === current.year
    && newDate.month === current.month
    && newDate.day === current.day;

    // dont return if a currentDate does not exist (if its just ''), because current will be the same day as today
    if (isTheSame && currentDate) return;

    stats.push(['trackEvent', 'Hot Scrimps', 'Set day']);

    setDateFilter(newDate.toJSDate().toISOString());
  };

  const preparedDays = useMemo(() => {
    const days = [];

    // first "day" should be all days, so no value
    days.push({ label: t('all_days'), value: '' });

    const locale = getCurrentLocale();

    // convert current filter date to timezone
    // const filterDate = filter.date ? DateTime.fromISO(filter.date, { locale }).setZone(timezone) : DateTime.now({ locale }).setZone(timezone);

    for (let i = 0; i < 8; i++) {
      // get the next 8 days in that timezone
      let date = DateTime.now().setZone(timezone).setLocale(locale);

      // If it is not the current day, set time to midnight
      if (i !== 0) {
        date = date.startOf('day').plus({ days: i });
      }

      let display = date.toLocaleString(DateTime.DATE_SHORT);

      if (i === 0) display = t('general:today');
      if (i === 1) display = t('general:tomorrow');
      if (i === 2) display = t('general:day_after_tomorrow');

      days.push({ label: display, value: date });
    }

    return days;
  }, [currentDate, language, timezone]);

  // get a json date, extract its time values and convert it to the timezone
  // then, set the time values back
  const selectedDate = useMemo(() => {
    if (!currentDate) return preparedDays[0];

    const _currentDate = DateTime.fromISO(currentDate, { zone: timezone });

    const _selectedDay = preparedDays.find((_date) => {
      const timedzonedDate = DateTime.fromISO(_date.value, { zone: timezone });

      const isActive = _currentDate.hasSame(timedzonedDate, 'year')
    && _currentDate.hasSame(timedzonedDate, 'month')
    && _currentDate.hasSame(timedzonedDate, 'day');

      return isActive;
    });

    return _selectedDay;
  }, [currentDate, timezone, language]);

  return (

    <div className="has-max-width-300">
      <div className="">
        <label className="label has-text-weight-semibold is-size-7">
          {t('general:date')}
        </label>
        <div className="control">
          <Dropdown
            items={preparedDays}
            onSelect={setDay}
            value={selectedDate}
            disabled={isLoading}
          />
        </div>
      </div>
    </div>

  );
}

DateFilter.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired,
  setDateFilter: PropTypes.func.isRequired,
};

export default DateFilter;
