import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// Libraries
import copy from 'copy-to-clipboard';

import {
  DISCORD,
  STEAM,
  RIOT,
  BATTLENET,
  EPIC,
  TWITTER,
  TAGSGG,
} from 'constants/contacts';

import { getSelectedGame } from 'constants/games';

import ColorContent from 'components/utils/ColorContent';
import CopyButton from 'components/utils/CopyButton';

// Assets
import tagsIcon from 'assets/images/icons/tagsgg.png';

// Styles
import LinkButton from 'components/utils/LinkButton';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

// Hooks

function ContactCard(props) {
  const { t } = useTranslation('general');
  const { data, close } = props;

  const { games, game, contact } = data;

  const selectedGame = useMemo(() => {
    if (!contact) return;

    const { tag } = (games && games.length) ? games[0] : game;

    getSelectedGame(tag);
  }, [tag]);

  if (!contact) {
    return null;
  }
  const { tag } = (games && games.length) ? games[0] : game;

  const showImage = () => (
    <img src={selectedGame.img} alt="" className="br-10-top my-0 flip" />
  );

  const showContactInfo = () => contact.map((info, index) => {
    let icon = '';

    if (!info.id) return null;

    switch (info.type) {
      case DISCORD:
        icon = 'fab fa-discord has-text-grey';
        break;
      case STEAM:
        icon = 'fab fa-steam';
        break;
      case EPIC:
        icon = 'fab fa-delicious has-text-grey';
        break;
      case RIOT:
        icon = 'fas fa-fist-raised has-text-danger';
        break;
      case BATTLENET:
        icon = 'fab fa-battle-net has-text-info';
        break;
      case TWITTER:
        icon = 'fab fa-twitter has-text-info';
        break;
      case TAGSGG:
        icon = '';
        break;

      default: return null;
    }

    return (
      <ColorContent
        className="has-max-width-90-percent has-margin-auto my-3"
        key={info.id}
      >
        <div className="is-flex">
          {
            info.type === TAGSGG
              ? (
                <>
                  <img alt="" src={tagsIcon} className="has-width-40 has-height-40" />
                  <div className="has-margin-left-auto">
                    <LinkButton text={info.id} />
                  </div>
                </>
              )
              : (
                <>
                  <i className={`is-size-2 ${icon}`} />
                  <div className="has-margin-left-auto">
                    <CopyButton text={info.id} />
                  </div>
                </>
              )
          }
        </div>
        <p className="has-text-right break-word has-text-weight-semibold">{info.id}</p>
      </ColorContent>
    );
  });

  return (
    <div id={styles.card} className="box br-10">
      { showImage() }
      <div className="px-4 pb-2 is-flex has-flex-1 flex-direction-column">
        <h2 className="has-text-centered has-text-weight-semibold is-size-5 mt-3">
          {t('general:contact_info')}
        </h2>
        <div className="has-fullwidth">
          { showContactInfo() }
        </div>
        <div className="has-margin-top-auto">
          <button
            type="button"
            className="button has-background-secondary has-fullwidth is-rounded has-text-weight-semibold is-block my-3 grow_small"
            onClick={close}
          >
            {t('general:go_back')}
          </button>

        </div>
      </div>
    </div>
  );
}

ContactCard.propTypes = {
  close: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
};

ContactCard.defaultProps = {

};

export default ContactCard;
