import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import { Link } from 'react-router-dom';
import CardPicDisplay from 'components/utils/CardPicDisplay';
import GameIconDisplay from 'components/utils/games/GameIconDisplay';
import PlatformDisplay from 'components/utils/PlatformDisplay';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import { PINBOARD_PAGE } from 'constants/routes';

// Assets
import paidSvg from 'assets/images/icons/paid.svg';
import unpaidSvg from 'assets/images/icons/unpaid.svg';
import competitivePng from 'assets/images/icons/engagement_competitive.png';
import justForFunPng from 'assets/images/icons/engagement_just4fun.png';
import rivalRushPng from 'assets/images/branded/rival_rush.png';
import fragsterLeagueSvg from 'assets/images/branded/fragster_league_logo.svg';
import destinyLeaguePng from 'assets/images/branded/destiny_league.png';

function PinboardEntryDisplay({
  entry,
  isPreview = false,
}) {
  const {
    publicId, entryType, providerType, specialIcons, ambitionTypes, qualification, game, platform, updatedAt, title, subTitle, logoPic,
  } = entry;

  const { t } = useTranslation(['general']);

  const wasUpdatedShortly = useMemo(() => {
    const updatedAtDate = new Date(updatedAt);
    const now = new Date();
    const diff = now - updatedAtDate;
    const diffInHours = diff / (1000 * 60 * 60);

    return diffInHours < 24;
  }, [updatedAt]);

  const renderedAmbitionTypes = useMemo(() => ambitionTypes.map((ambitionType) => {
    let icon = '';
    switch (ambitionType) {
      case 'just_for_fun':
        icon = justForFunPng;
        break;
      case 'competitive':
        icon = competitivePng;
        break;
      case 'paid':
        icon = paidSvg;
        break;
      case 'unpaid':
        icon = unpaidSvg;
        break;
      default:
        return null;
    }

    return (
      <div
        key={ambitionType}
        className="has-background-darkest py-1 px-2 has-text-white has-text-weight-semibold is-flex has-content-centered br-100 is-size-7 has-height-30 m-1"
      >
        {
            icon && (
            <img
              src={icon}
              alt={ambitionType}
              className="has-width-20 has-height-20 is-block mr-1"
            />
            )
        }
        <p>
          {t(`pinboard:${ambitionType}`)}
        </p>
      </div>
    );
  }), [ambitionTypes, t]);

  const renderedSpecialIcons = useMemo(() => specialIcons.map((specialIcon) => {
    switch (specialIcon) {
      case 'rival_rush':
        return (
          <img
            key={specialIcon}
            src={rivalRushPng}
            alt={specialIcon}
            className=""
          />
        );
      case 'fragster_league':
        return (
          <img
            key={specialIcon}
            src={fragsterLeagueSvg}
            alt={specialIcon}
            className=""
          />
        );
      case 'destiny_league':
        return (
          <img
            key={specialIcon}
            src={destinyLeaguePng}
            alt={specialIcon}
            className=""
          />
        );
      default:
        return null;
    }
  }), [specialIcons]);

  const renderedEntryType = useMemo(() => {
    let entryTypeString = entryType === 'offer' ? t('pinboard:offers') : t('pinboard:searches');
    entryTypeString = entryTypeString.toLowerCase();

    return (
      <p
        className={`has-text-weight-light ${entryType === 'offer' ? 'has-text-hotscrimps' : 'has-text-pinboard-green'}`}
      >
        {
          `${t(`general:${providerType}`)} ${entryTypeString}:`
        }
      </p>
    );
  }, [t, entryType, providerType]);

  let link = `${PINBOARD_PAGE}/${publicId}`;

  if (isPreview) {
    link = '';
  }

  return (
    <Link
      to={link}
      className="has-min-height-135"
    >
      <div className="box p-1">
        <div className="columns is-multiline">

          <div className="column is-6">
            { renderedEntryType }
            <p
              className="abbreviate-text has-text-white has-text-weight-medium is-size-5"
            >
              { title }
            </p>
            {
                subTitle && (
                <p
                  className="has-text-white has-text-weight-light is-size-7 mt-1"
                >
                  {subTitle }
                </p>
                )
            }
          </div>
          <div className="column">
            <div
              className="is-flex flex-wrap has-content-end has-fullheight"
            >
              {renderedAmbitionTypes}
              { qualification && (
                <div
                  key={qualification}
                  className="has-background-darkest py-1 px-2 has-text-white has-text-weight-semibold is-flex has-content-centered br-100 is-size-7 has-height-30 m-1"
                >
                  <i className="fas fa-check-circle has-text-playerboard mr-1" />
                  <p>
                    {t(`general:${qualification}`)}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="column is-1">
            <div className="is-flex has-content-centered has-fullheight">
              {renderedSpecialIcons}
            </div>
          </div>

          <div className="column is-narrow is-flex flex-direction-column">
            {
                wasUpdatedShortly && (
                <div className="has-text-centered">
                  <p className="has-text-pinboard-green">
                    {t('general:fresh').toUpperCase()}
                  </p>
                </div>
                )
            }

            <div className="is-flex has-items-bottom flex-grow">
              {
                game && game.tag && (
                  <div className="has-width-25 is-flex mr-1">
                    <GameIconDisplay
                      game={game.tag}
                      iconType="white"
                    />
                  </div>
                )
              }
              {
                platform && (
                  <div className="has-width-25 is-flex ml-4">
                    <PlatformDisplay platform={platform} />
                  </div>
                )
              }
            </div>
          </div>

          <div className="column is-1">
            { logoPic && (
            <CardPicDisplay
              img={logoPic}
              url="/images/pinboard/logo_pictures/"
            />
            )}
          </div>

        </div>
      </div>
    </Link>
  );
}

PinboardEntryDisplay.propTypes = {
  entry: PropTypes.shape({
    publicId: PropTypes.string,
    entryType: PropTypes.string,
    providerType: PropTypes.string,
    consumerType: PropTypes.string,
    specialIcons: PropTypes.arrayOf(PropTypes.string),
    ambitionTypes: PropTypes.arrayOf(PropTypes.string),
    qualification: PropTypes.string,
    game: PropTypes.shape({
      tag: PropTypes.string,
    }),
    platform: PropTypes.string,
    updatedAt: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    logoPic: PropTypes.string,
  }).isRequired,
  isPreview: PropTypes.bool,
};

export default PinboardEntryDisplay;
