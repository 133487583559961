import React, { useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useOrgaPage } from 'components/orgas/OrgaPage';
import useAPI from 'components/hooks/useAPI';

// Components
import Popup from 'reactjs-popup';
import CopyButtonSimple from 'components/utils/CopyButtonSimple';

// Constants
import { ORGA_TEAM_INVITE_PAGE } from 'constants/routes';

function OrgaTeamInviteButton() {
  const { t } = useTranslation(['general']);

  const { publicId } = useOrgaPage();

  const [disabled, setDisabled] = useState(false);
  const [inviteLink, setInviteLink] = useState(null);

  const [status, setStatus] = useState('');

  const { post } = useAPI();

  const getInviteLink = async () => {
    try {
      setDisabled(true);

      const link = await post(`/orgas/invite/team/${publicId}`);
      setInviteLink(`${window.location.origin}${ORGA_TEAM_INVITE_PAGE}${link}`);

      setDisabled(false);
    } catch (e) {
      setDisabled(false);
      setStatus(t('errors:data_load_error'));
      console.log(e);
    }
  };

  return (

    <Popup
      trigger={(
        <div className="is-inline-block">
          <button
            type="button"
            className="button is-size-7 has-no-background grow_small has-text-grey has-border-dashed-grey "
          >
            <i className="fas fa-plus mr-2" />
            { t('general:invite')}
          </button>
        </div>
        )}
      position="top center"
      keepTooltipInside="#root"
      repositionOnResize
      closeOnDocumentClick={!disabled}
      closeOnEscape={!disabled}
      onOpen={getInviteLink}
      modal
    >
      {
          (close) => (
            <div className="box p-0 is-max-fullheight has-max-width-400">
              <div
                className="has-background-primary has-text-centered p-4 pb-6 is-relative br-5"
              >
                <div className="has-text-right">
                  <button
                    type="button"
                    className=" cleanButton has-text-white"
                    onClick={close}
                    disabled={disabled}
                  >
                    <i className="fas fa-times" />
                  </button>
                </div>
                <p
                  className="has-text-weight-semibold is-size-3 mt-"
                >
                  {t('general:invite')}
                </p>

                <div
                  className="br-100 has-background-primary is-absolute is-flex has-content-centered"
                  style={{
                    left: 'calc(50% - 45px)', width: '90px', height: '85px',
                  }}
                >
                  <span className="is-size-3 br-5 fas fa-user-plus" />
                </div>

              </div>

              <div className="p-5 has-background-black-gradient pb-6 has-text-centered">
                <label className="label pt-6 has-text-weight-semibold">
                  {t('general:send_this_link_to_invite_teams')}
                </label>
                <div className="control mt-5">

                  <div className="has-fullwidth has-text-centered has-min-width-250">
                    {
                        inviteLink ? (
                          <div className="has-border-dashed-grey has-border-dark p-3 is-flex has-content-centered br-5">
                            <p
                              className="abbreviate-text has-text-standard pr-3"
                            >
                              {inviteLink}
                            </p>
                            <CopyButtonSimple
                              text={inviteLink}
                              className="button is-primary"
                            >
                              <i className="fas fa-copy" />
                            </CopyButtonSimple>
                          </div>
                        )
                          : (
                            <p className="is-size-7">
                              { status || t('general:loading') }
                            </p>
                          )
                      }
                  </div>
                </div>

              </div>
            </div>
          )
        }
    </Popup>
  );
}

export default OrgaTeamInviteButton;
