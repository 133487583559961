import React, { useState } from 'react';

// Libaries
import stats from 'analytics/matomo';
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';
import { useTeamProfile } from 'components/Teams/TeamProfile';

// Constants
import { MAX_TEAM_NAME_LENGTH } from 'constants/team';

const regex = new RegExp(/^[\u00C0-\u017FA-Za-z0-9-_. ]+$/);

function TeamNameEditor() {
  const { publicId, name, setName } = useTeamProfile();

  const { t } = useTranslation(['general', 'validation']);

  const { post } = useAPI();

  const [newName, setNewName] = useState(name);
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const _setName = (_name) => {
    setStatus('');

    if (_name.length > MAX_TEAM_NAME_LENGTH) {
      setStatus(t('validation:choose_shorter_name_error'));
      return;
    }

    setNewName(_name);
  };

  const saveName = async () => {
    try {
      if (regex.test(newName) === false) {
        setStatus(t('validation:enter_valid_name_error'));
        return;
      }
      setStatus('');
      setDisabled(true);

      const data = { name: newName };

      await post(`/teams/name/${publicId}`, data);

      // dispatch(setName(newName));

      setName(newName);

      stats.push(['trackEvent', 'Team Profile', 'New name set']);

      setDisabled(false);
      setStatus(t('general:saved_successfully'));
    } catch (e) {
      setDisabled(false);

      setStatus(t('errors:save_new_name_error'));
    }
  };

  const showSaveButton = newName && newName !== name;

  return (
    <div>
      <div className="is-flex">
        <div className="is-inline-block has-min-width-250 has-text-left">
          <div className="control">
            <input
              className="input is-size-4 has-text-primary has-text-weight-bold"
              type="text"
              value={newName}
              placeholder="1337 gang"
              disabled={disabled}
              onChange={(e) => _setName(e.target.value)}
            />
          </div>
        </div>
        {
        showSaveButton && (
        <div className="has-text-left is-flex mx-2 has-content-centered">
          <button
            type="button"
            className="button is-block is-primary grow_small has-text-weight-semibold"
            onClick={saveName}
            disabled={disabled}
          >
            {t('general:save')}
          </button>

        </div>
        )
      }

      </div>
      { status && (
      <p className="has-text-weight-semibold mt-4">
        { status }
      </p>
      )}
    </div>

  );
}

export default TeamNameEditor;
