import React, {
  useState, useEffect, useContext, useCallback,
  useMemo,
} from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useSocket } from 'components/providers/SocketProvider';
import { useAuth0 } from '@auth0/auth0-react';

// Components
import { ToastContainer, toast } from 'react-toastify';
import Notification from '../Notification';

// Css
import 'react-toastify/dist/ReactToastify.css';

export const NotificationContext = React.createContext({});

function ToastNotification({ notification, closeToast }) {
  return <Notification notification={notification} closeNotification={closeToast} />;
}

function NotificationProvider({ children = {} }) {
  const [notifications, setNotifications] = useState([]);

  const { isAuthenticated } = useAuth0();
  const { socket, isConnected } = useSocket();
  const { get } = useAPI();

  useEffect(() => {
    const loadNotification = async (notificationId) => {
      try {
        const _notification = await get(`/notifications/${notificationId}`);

        setNotifications((_notifications) => [_notification, ..._notifications]);

        toast(<ToastNotification notification={_notification} />);
      } catch (e) {
        console.log(e);
      }
    };
    if (!isConnected || !isAuthenticated) return undefined;

    socket.on('notification', loadNotification);

    return () => {
      socket.off('notification');
    };
  }, [isConnected, isAuthenticated, get, socket]);

  useEffect(() => {
    const loadNotifications = async () => {
      try {
        const _notifications = await get('/notifications');

        setNotifications(_notifications);
      } catch (e) {
        console.log(e);
      }
    };

    if (!isAuthenticated) return;

    loadNotifications();
  }, [isAuthenticated]);

  const deleteAllNotifications = useCallback(() => {
    setNotifications([]);
  }, []);

  const markAllNotificationsAsRead = useCallback(() => {
    setNotifications((prevNotifications) => prevNotifications.map((notification) => ({
      ...notification,
      read: true,
    })));
  }, []);

  const deleteNotification = useCallback((publicId) => {
    setNotifications((prevNotifications) => prevNotifications.filter((notification) => notification.publicId !== publicId));
  }, []);

  const updateNotification = useCallback((publicId, data) => {
    setNotifications((prevNotifications) => prevNotifications.map((notification) => {
      if (notification.publicId === publicId) {
        return {
          ...notification,
          ...data,
        };
      }
      return notification;
    }));
  }, []);

  const values = useMemo(() => ({
    notifications,

    deleteNotification,
    updateNotification,
    deleteAllNotifications,
    markAllNotificationsAsRead,
  }), [
    notifications,
    deleteNotification,
    updateNotification,
    deleteAllNotifications,
    markAllNotificationsAsRead,
  ]);

  return (
    <NotificationContext.Provider
      value={values}
    >
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {children}
    </NotificationContext.Provider>
  );
}

NotificationProvider.propTypes = {
  children: PropTypes.node,
};

export const useNotifications = () => {
  const data = useContext(NotificationContext);

  return data;
};

export default NotificationProvider;
