import React, { useMemo } from 'react';

// Libraries
import regionToLabel from 'utils/regionToLabel';
import { getSelectedGame } from 'constants/games';
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';
import PlatformDisplay from 'components/utils/PlatformDisplay';
import CardPicDisplay from 'components/utils/CardPicDisplay';

function TeamInfoDisplay(props) {
  const {
    game, region, platform, name, cardPic,
  } = props;

  const { t } = useTranslation(['general']);

  const renderedName = useMemo(() => (
    <div>
      <p className="has-text-grey is-size-5">
        {t('general:name')}
      </p>
      <p className="is-size-5">
        {name}
      </p>
    </div>
  ), [name]);

  const renderedGame = useMemo(() => {
    const _game = getSelectedGame(game.tag);
    const renderedImage = _game ? _game.icon : '';

    return (

      <div>
        <p
          className="has-text-grey is-size-5"
        >
          {t('general:game')}
        </p>
        <img
          className="has-fullwidth has-fullheight has-max-width-50 has-max-height-50 mt-2"
          src={renderedImage}
          alt={game.tag}
        />
      </div>
    );
  });

  const renderedRegion = useMemo(() => {
    const _region = regionToLabel(region);

    return (
      <div className="">
        <p
          className="has-text-grey is-size-5"
        >
          {t('general:region')}
        </p>
        <p className="is-size-5">
          {_region}
        </p>
      </div>
    );
  }, [region]);

  const renderedPlatform = useMemo(() => (
    <div>
      <p className="has-text-grey is-size-5 mb-2">
        {t('general:platform')}
      </p>
      <PlatformDisplay platform={platform} />
    </div>
  ), [platform]);

  return (
    <div>

      <div
        className="has-max-width-200"
      >
        <CardPicDisplay quadratic img={cardPic} url="/images/teams/card_pictures/" />
      </div>

      <div className="my-2">
        { renderedName }
      </div>

      { renderedGame }

      <div className="my-2">
        { renderedPlatform }
      </div>

      { renderedRegion }

    </div>
  );
}

TeamInfoDisplay.propTypes = {
  game: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }).isRequired,
  region: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cardPic: PropTypes.string.isRequired,
};

export default TeamInfoDisplay;
