import React, {
  useState, useEffect, useContext, useMemo,
} from 'react';

// Libraries
import PropTypes from 'prop-types';
import { io } from 'socket.io-client';

// Hooks
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

export const SocketContext = React.createContext({});

function SocketProvider({ children = {} }) {
  const { hasCreatedPlayer } = useSelector((state) => state.player);

  const [socket, setSocket] = useState();
  const [isConnected, setIsConnected] = useState(false);

  const {
    getAccessTokenSilently, isAuthenticated,
  } = useAuth0();

  useEffect(() => () => {
    if (socket) {
      socket.disconnect();
    }
  }, [socket]);

  useEffect(() => {
    // If we have a socket, disconnect it
    if (socket) {
      console.log('Disconnect!');
      socket.disconnect();
      setSocket(null);
    }

    const createConnection = async () => {
      try {
        let query = {};

        if (isAuthenticated) {
          const token = await getAccessTokenSilently();
          query = {
            token,
          };
        }

        const _socket = io(process.env.REACT_APP_SOCKET, {
          reconnectionDelay: 2000,
          query,
        });

        console.log('Connecting to socket...');

        _socket.on('connect', () => {
          console.log('Connected to socket');
          setSocket(_socket);
          setIsConnected(true);
        });

        _socket.on('disconnect', () => {
          console.log('Disconnected from socket');
          setIsConnected(false);
        });
      } catch (e) {
        console.log(e);
      }
    };

    createConnection();
  }, [isAuthenticated, hasCreatedPlayer]);

  const contextValue = useMemo(() => ({
    socket,
    isConnected,
  }), [socket, isConnected]);

  return (
    <SocketContext.Provider
      value={contextValue}
    >
      {children}
    </SocketContext.Provider>
  );
}

SocketProvider.propTypes = {
  children: PropTypes.node,
};

export const useSocket = () => {
  const data = useContext(SocketContext);

  return data;
};

export default SocketProvider;
