import React from 'react';

// Libaries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';
import TournamentCardPicDisplay from 'components/tournaments/utils/TournamentCardPicDisplay';

function InvitationTournamentDisplay(props) {
  const { tournament } = props;
  const {
    title, subtitle, cardPic,
  } = tournament;

  const { t } = useTranslation(['general']);

  return (
    <div className="my-5">
      <div className="is-inline-block">
        {
          title && (
            <p
              className="has-text-weight-bold is-size-4"
            >
              {title}
            </p>
          )
        }
        {
          subtitle && (
            <p className="mt-2">
              {subtitle}
            </p>
          )
        }
        <div className="mt-2">
          <TournamentCardPicDisplay cardPic={cardPic} />
        </div>
      </div>

    </div>
  );
}

InvitationTournamentDisplay.propTypes = {
  tournament: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default InvitationTournamentDisplay;
