import React, { useEffect, useMemo, useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

// HOCs
import withAuthentication from 'components/hocs/withAuthentication';

// Constants
import { ORGA_PAGE } from 'constants/routes';

// Components
import Popup from 'reactjs-popup';
import { Link } from 'react-router-dom';
import LoginPopup from 'components/utils/LoginPopup';
import TriangleDisplay from 'components/utils/TriangleDisplay';
import FilterSelector from './FilterSelector';
import OrgaCard from 'components/orgas/utils/OrgaCard';

const INITIAL_FILTER = {
  name: '',
  region: '',
};

function OrgaList() {
  const [orgas, setOrgas] = useState([]);
  const [filter, setFilter] = useState(INITIAL_FILTER);

  const [isLoading, setIsLoading] = useState(false);
  const [showLoadMoreBtn, setShowLoadMoreBtn] = useState(false);

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { isAuthenticated } = useAuth0();

  const { t } = useTranslation(['general', 'errors']);
  const { language } = useSelector((state) => state.session);
  const { post } = useAPI();

  const addOrgas = (entries) => {
    setOrgas([...orgas, ...entries]);
  };

  const loadOrgas = async () => {
    try {
      setIsLoading(true);

      const data = {
        filter,
        createdAt: '',
      };

      let loadedOrgas = [];

      if (!isAuthenticated) {
        loadedOrgas = await post('/orgas/list/public', data);
      } else {
        loadedOrgas = await post('/orgas/list', data);
      }

      if (loadedOrgas.length === 20) {
        setShowLoadMoreBtn(true);
      } else {
        setShowLoadMoreBtn(false);
      }

      setOrgas(loadedOrgas);

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setStatus(t('errors:data_load_error'));
      setIsLoading(false);
    }
  };

  const loadMoreOrgas = async () => {
    try {
      setIsLoading(true);

      const data = {
        filter,
        createdAt: '',
      };

      if (orgas.length !== 0) {
        data.createdAt = orgas[orgas.length - 1].createdAt || '';
      }

      let loadedOrgas = [];

      if (!isAuthenticated) {
        loadedOrgas = await post('/orgas/list/public', data);
      } else {
        loadedOrgas = await post('/orgas/list', data);
      }

      if (loadedOrgas.length === 20) {
        setShowLoadMoreBtn(true);
      } else {
        setShowLoadMoreBtn(false);
      }

      if (orgas.length === 0) {
        setOrgas(loadedOrgas);
      } else {
        addOrgas(loadedOrgas);
      }

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setStatus(t('errors:data_load_error'));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadOrgas();
  }, [filter]);

  const setLoading = (_isLoading) => {
    setIsLoading(_isLoading);
  };

  const resetFilter = () => {
    setFilter(INITIAL_FILTER);
  };

  const renderedOrgas = useMemo(() => orgas.map((orgaData, index) => {
    if (!isAuthenticated) {
      return (
        <Popup
          trigger={(
            <div
              key={index}
              className="column is-narrow has-cursor-pointer"
            >
              <OrgaCard orgaData={orgaData} />
            </div>
        )}
          position="top center"
          keepTooltipInside="#root"
          closeOnDocumentClick
          closeOnEscape
          modal
          key={index}
        >
          {(close) => (<LoginPopup close={close} text={t('general:login_to_see_orga_info')} />)}
        </Popup>
      );
    }

    return (
      <div
        className="column is-narrow"
        key={index}
      >
        <Link
          to={`${ORGA_PAGE}${orgaData.publicId}`}
          key={index}
        >
          <OrgaCard orgaData={orgaData} />
        </Link>
      </div>

    );
  }), [orgas, language]);

  return (
    <div className="is-min-fullheight is-relative">

      <TriangleDisplay />

      <div
        className="columns is-centered is-mobile is-multiline m-0 px-0 is-min-fullheight"
      >

        <div className="column is-11-desktop is-11-tablet is-11-mobile">

          <div
            className="pt-0"
          >

            <div className="my-6">
              <h1
                className="is-outlined has-text-weight-semibold has-text-primary"
                style={{ fontSize: '65px' }}
              >
                {t('general:orgas')}
              </h1>
              <p
                className="is-size-4-desktop is-size-5-tablet is-size-5-mobile has-text-grey"
              >
                {t('general:orgas_description_short')}
              </p>
            </div>

            <div className="columns is-multiline">

              <div className="column px-0 has-min-width-450 has-text-right is-flex">

                <div className="has-margin-left-auto is-flex has-content-centered">
                  <FilterSelector
                    filter={filter}
                    setFilter={setFilter}
                    resetFilter={resetFilter}
                    disabled={disabled}
                  />
                </div>
              </div>

            </div>

          </div>

          <div className="columns is-multiline">

            <div className="column is-flex flex-direction-column">
              <div className="flex-grow">

                <div>

                  <div className="mt-0">
                    <div className="columns is-multiline">
                      { renderedOrgas }
                    </div>
                  </div>

                  {
                      showLoadMoreBtn && (
                        <div className="has-text-centered">
                          <button
                            type="button"
                            onClick={loadMoreOrgas}
                            disabled={isLoading}
                            className={`button has-text-white has-text-weight-semibold has-background-primary my-4 grow ${isLoading ? 'is-loading' : ''}`}
                          >
                            {t('general:load_more')}
                          </button>
                        </div>
                      )
                    }
                </div>

              </div>

            </div>

          </div>

        </div>
      </div>

    </div>

  );
}

export default withAuthentication(OrgaList);
