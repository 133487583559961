import React, {
  useEffect, useState, useContext,
} from 'react';

// HOCs
import withAuthentication from 'components/hocs/withAuthentication';

// Hooks
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Constants
import { TEAMS_LIST } from 'constants/routes';
import { hasPermission } from 'constants/team';

// Components
import Loading from 'components/utils/Loading';
import { useAuth0 } from '@auth0/auth0-react';
import Popup from 'reactjs-popup';
import LoginPopup from 'components/utils/LoginPopup';
import TeamIdDisplay from './TeamIdDisplay';
import TeamAchievementDisplay from './TeamAchievementDisplay';
import TeamPlayerHandler from './TeamPlayerHandler';
import TeamContactHandler from './TeamContactHandler';
import TeamBreadcrumbs from './TeamBreadcrumbs';
import TeamDescriptionHandler from './TeamDescriptionHandler';
import TeamPlatformHandler from './TeamPlatformHandler';
import TeamRegionHandler from './TeamRegionHandler';
import TeamCardPicHandler from './TeamCardPicHandler';
import TeamNameHandler from './TeamNameHandler';
import TeamDeletionHandler from './TeamDeletionHandler';
import TeamLeaveHandler from './TeamLeaveHandler';
// import TeamHotscrimpHandler from './TeamHotscrimpHandler';

const TeamProfileContext = React.createContext({});

const INITIAL_PERMISSIONS = {
  canUpdateTeam: false,
  canInvite: false,
  canDeleteTeam: false,
  canLeaveTeam: false,
  canKickAdmin: false,
  canKickMember: false,
  canCreateHotscrimp: false,
};

function TeamProfile() {
  const [hasData, setHasData] = useState(false);
  const [error, setError] = useState('');

  const params = useParams();
  const navigate = useNavigate();

  const [permissions, setPermissions] = useState(INITIAL_PERMISSIONS);

  const [nationalities, setNationalities] = useState([]);
  const [description, setDescription] = useState('');
  const [contact, setContact] = useState({});
  const [name, setName] = useState('');
  const [cardPic, setCardPic] = useState('');
  const [achievements, setAchievements] = useState([]);
  const [members, setMembers] = useState([]);
  const [game, setGame] = useState('');
  const [publicId, setPublicId] = useState('');
  const [region, setRegion] = useState('');
  const [platform, setPlatform] = useState('');

  const { player } = useSelector((state) => state.player);
  const { playerId } = player;

  const { t } = useTranslation(['general']);

  const { get } = useAPI();

  const { isAuthenticated } = useAuth0();

  const evaluatePermissions = (role) => {
    if (!role) return;

    const _canUpdateTeam = hasPermission(role, 'updateTeam');
    const _canInvite = hasPermission(role, 'invite');
    const _canDeleteTeam = hasPermission(role, 'deleteTeam');
    const _canLeaveTeam = hasPermission(role, 'leave');
    const _canKickAdmin = hasPermission(role, 'removeAdmin');
    const _canKickMember = hasPermission(role, 'removeMember');
    const _canSetPermissions = hasPermission(role, 'changePermissions');
    const _canSetTeamRole = hasPermission(role, 'changeTeamRole');
    const _canSetIngameRole = hasPermission(role, 'changeIngameRole');

    setPermissions({
      canUpdateTeam: _canUpdateTeam,
      canInvite: _canInvite,
      canDeleteTeam: _canDeleteTeam,
      canLeaveTeam: _canLeaveTeam,
      canKickAdmin: _canKickAdmin,
      canKickMember: _canKickMember,
      canSetPermissions: _canSetPermissions,
      canSetTeamRole: _canSetTeamRole,
      canSetIngameRole: _canSetIngameRole,
    });
  };

  // get teamId from url
  useEffect(() => {
    const loadTeam = async (id) => {
      try {
        let _team;

        if (isAuthenticated) {
          _team = await get(`/teams/${id}`);

          // check user permission
          const user = _team.members.find((member) => member.playerId === playerId);
          if (user) evaluatePermissions(user.role);
        } else {
          _team = await get(`/teams/public/${id}`);
        }

        // set data
        setName(_team.name);
        setNationalities(_team.nationalities);
        setDescription(_team.description);
        setContact(_team.contact);
        setCardPic(_team.cardPic);
        setAchievements(_team.achievements);
        setMembers(_team.members);
        setGame(_team.game);
        setPublicId(_team.publicId);
        setRegion(_team.region);
        setPlatform(_team.platform);

        setHasData(true);
      } catch (e) {
        console.log(e);
        setError(t('errors:team_not_found'));
      }
    };

    const { id } = params;

    if (!id || !id.startsWith('SCR-TID-')) {
      navigate(TEAMS_LIST);
      return;
    }

    const extractedId = id.replace('SCR-TID-', '');

    loadTeam(extractedId);
  }, []);

  const kickPlayer = async (id) => {
    const _members = members.filter((member) => member.playerId !== id);
    setMembers(_members);
  };

  const setIngameRole = async (id, role) => {
    const _members = members.map((member) => {
      if (member.playerId === id) {
        return {
          ...member,
          ingameRole: role,
        };
      }
      return member;
    });
    setMembers(_members);
  };

  const setTeamRole = async (id, role) => {
    const _members = members.map((member) => {
      if (member.playerId === id) {
        return {
          ...member,
          teamRole: role,
        };
      }
      return member;
    });
    setMembers(_members);
  };

  const setPermissionRole = async (id, role) => {
    const _members = members.map((member) => {
      if (member.playerId === id) {
        return {
          ...member,
          role,
        };
      }
      return member;
    });
    setMembers(_members);
  };

  if (!hasData) return <Loading error={error} />;

  return (
    <TeamProfileContext.Provider value={{
      name,
      nationalities,
      description,
      contact,
      cardPic,
      achievements,
      members,
      game,
      publicId,
      region,
      platform,
      permissions,

      setName,
      setNationalities,
      setDescription,
      setContact,
      setCardPic,
      setAchievements,
      setMembers,
      setGame,
      setPlatform,
      setRegion,
      setIngameRole,
      setTeamRole,
      setPermissionRole,

      kickPlayer,
    }}
    >
      <div className="is-min-fullheight">

        <div className="has-background-primary is-size-5 has-fullwidth py-2 px-6">
          <TeamBreadcrumbs />
        </div>

        <div
          className="columns is-centered is-mobile is-multiline m-0 px-0 is-min-fullheight"
        >

          <div className="column is-11-desktop is-11-tablet is-11-mobile">

            <div
              className="pt-0 mb-6"
            >

              <div className="columns is-mobile is-multiline">

                <div className="column">
                  <div className="mb-5">
                    <h1
                      className="is-outlined has-text-weight- has-text-white is-size-2"
                    >
                      {t('general:team')}
                    </h1>
                    <TeamNameHandler />
                  </div>

                  <div>
                    <TeamIdDisplay />
                  </div>

                  <TeamDescriptionHandler />

                  <div className="mt-6">
                    <TeamPlayerHandler />
                  </div>

                </div>

                <div className="column is-4">
                  <div className="mt-4">

                    <div className="is-inline-block has-max-width-200">
                      <TeamCardPicHandler />
                    </div>

                    {
                      isAuthenticated ? (
                        <TeamContactHandler />
                      ) : (
                        <Popup
                          trigger={(
                            <div className="">
                              <button
                                type="button"
                                className="button grow is-primary is-size-6 mt-5"
                              >
                                <i className="fas fa-user mr-2" />
                                {t('general:social_links')}
                              </button>

                            </div>
                        )}
                          position="top center"
                          keepTooltipInside="#contentWrapper"
                          repositionOnResize
                          closeOnDocumentClick
                          modal
                        >
                          {(close) => (<LoginPopup close={close} />)}
                        </Popup>
                      )
                    }

                    <div className="mt-5">
                      <TeamAchievementDisplay />
                    </div>

                    <div className="mt-5 mb-5">
                      <TeamRegionHandler />
                    </div>

                    <TeamPlatformHandler />
                  </div>
                </div>

              </div>

              <TeamDeletionHandler />
              <TeamLeaveHandler />

            </div>

          </div>
        </div>

      </div>
    </TeamProfileContext.Provider>
  );
}

export const useTeamProfile = () => {
  const data = useContext(TeamProfileContext);

  return data;
};

export default withAuthentication(TeamProfile);
