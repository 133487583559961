import React, { useMemo } from 'react';

// Hooks
import { useTeamProfile } from 'components/Teams/TeamProfile';
import { useTranslation } from 'react-i18next';

// Components
import PlatformDisplay from 'components/utils/PlatformDisplay';
import TeamPlatformSelector from './TeamPlatformSelector';

const TeamPlatformHandler = () => {
  const { permissions, platform } = useTeamProfile();
  const { canUpdateTeam } = permissions;

  const { t } = useTranslation(['general']);

  const renderedPlatform = useMemo(() => {
    if (!platform) return null;

    if (!canUpdateTeam) {
      return (
        <div>
          <p className="is-size-5 has-text-grey mb-2">
            { t('general:platform') }
          </p>
          <PlatformDisplay platform={platform} />
        </div>
      );
    }

    return (
      <div>
        <TeamPlatformSelector />
      </div>
    );
  }, [platform]);

  return renderedPlatform;
};

export default TeamPlatformHandler;
