import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

import {
  DISCORD,
  DISCORD_SERVER,
  STEAM,
  RIOT,
  BATTLENET,
  EPIC,
  TWITTER,
  TAGSGG,
  EMAIL,
  YOUTUBE,
  TWITCH,
  WEBSITE,
  XCOM,
  INSTAGRAM,
} from 'constants/contacts';

// Components
import CopyButton from 'components/utils/CopyButton';

// Assets
import tagsIcon from 'assets/images/icons/tagsgg.png';
import epicIcon from 'assets/images/icons/epic_games.png';
import riotIcon from 'assets/images/icons/riot_games.png';

function ContactCopyDisplay({ contact }) {
  const contactDisplay = useMemo(() => Object.keys(contact).map((type) => ({ type, id: contact[type] })), [contact]);

  const showContactInfo = () => contactDisplay.map((info, index) => {
    let icon = '';
    let img = '';
    let id = '';

    if (!info.id) return null;

    switch (info.type) {
      case DISCORD:
        icon = 'fab fa-discord has-text-white is-size-3';
        break;
      case DISCORD_SERVER:
        icon = 'fab fa-discord has-text-white is-size-3';
        break;
      case STEAM:
        icon = 'fab fa-steam has-text-white is-size-3';
        break;
      case EPIC:
        img = epicIcon;
        break;
      case RIOT:
        img = riotIcon;
        break;
      case BATTLENET:
        icon = 'fab fa-battle-net is-size-3 has-text-white';
        break;
      case TWITTER:
        icon = 'fab fa-twitter has-text-white is-size-3';
        break;
      case TAGSGG:
        img = tagsIcon;
        id = `https://tags.gg/${info.id}`;
        break;
      case EMAIL:
        icon = 'fas fa-envelope has-text-white is-size-3';
        break;
      case YOUTUBE:
        icon = 'fab fa-youtube has-text-white is-size-3';
        break;
      case TWITCH:
        icon = 'fab fa-twitch has-text-white is-size-3';
        break;
      case WEBSITE:
        icon = 'fas fa-globe has-text-white is-size-3';
        break;
      case XCOM:
        icon = 'fas fa-x has-text-white is-size-3';
        break;
      case INSTAGRAM:
        icon = 'fab fa-instagram has-text-white is-size-3';
        break;
      default: return null;
    }

    return (
      <div className="mx-1 my-3 is-flex align-items-center" key={index}>
        <CopyButton text={id || info.id} icon={icon || img} showText />
      </div>
    );
  });

  return (
    <div
      className="is-inline-flex flex-direction-column my-3"
    >
      {showContactInfo()}
    </div>
  );
}

ContactCopyDisplay.propTypes = {
  contact: PropTypes.shape({}).isRequired,
};

export default ContactCopyDisplay;
