import React, { useState } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Constants
import { MAX_TOURNAMENT_STREAMING_CHANNEL_LENGTH } from 'constants/tournaments';
import AsyncInputEditor from 'components/utils/inputs/AsyncInputEditor';

function TournamentStreamingChannelEditor() {
  const { streaming, setStreaming, publicId } = useTournament();
  const { channel } = streaming;

  const { t } = useTranslation(['general', 'validation']);

  const { post } = useAPI();

  const saveTwitchChannel = async (newTwitchChannel) => {
    // extract the channel name from the URL if it's a full URL
    let channelName = newTwitchChannel.split('/').pop();
    channelName = channelName.split('?')[0];

    // remove whitespace before and after
    channelName = channelName.trim();

    const data = { channel: channelName };

    await post(`/tournaments/streaming/channel/${publicId}`, data);
    setStreaming({ channel: channelName });

    stats.push(['trackEvent', 'Tournament Editor', 'Twitch Channel set']);
  };

  return (
    <AsyncInputEditor
      label="twitch_channel_name"
      text={channel}
      onSave={saveTwitchChannel}
      maxLength={MAX_TOURNAMENT_STREAMING_CHANNEL_LENGTH}
    />
  );
}

export default TournamentStreamingChannelEditor;
