import csgo from './csgo';
import lol from './lol';
import nl from './nl';
import ow from './ow';
import rl from './rl';
import rss from './rss';
import val from './val';

// const GAMES = [csgo, lol, ow, rl, nl, val, rss];
const GAMES = [rl, nl, ow, lol, val, csgo, rss];

export const GAME_LIST = GAMES.map((game) => game.name);

export const GAME_TAGS = GAMES.map((game) => game.tag);

export const GAME_TAGS_WITH_NAME = GAMES.map((game) => ({
  name: game.name,
  tag: game.tag,
}));

export const getSelectedGame = (tag) => {
  const selectedGame = GAMES.find((_game) => _game.tag === tag);

  if (!selectedGame) {
    // return default values if not found
    return {
      name: '',
      tag: '',
      img: '',
      icon: '',
      iconWhite: '',
      platforms: [],
      tagsPerCategory: [],
      mmr: [],
      mmrShort: [],
      mmrImages: [],
      modes: [],
      ranks: [],
    };
  }

  return selectedGame;
};

export const getSelectedModeFromGame = (tag, mode) => {
  const selectedGame = getSelectedGame(tag);

  const { modes } = selectedGame;

  const selectedMode = modes.find((_mode) => _mode.name === mode);

  return selectedMode || { name: '', amountPlayers: 0, ranks: [] };
};

export const getRankFromGameMode = (game, mode, rank, type = 'class') => {
  const defaultData = {
    name: '',
    shortName: '',
    class: -1,
    minMmr: -1,
    maxMmr: -1,
    img: '',
  };

  const selectedMode = getSelectedModeFromGame(game, mode);

  if (type === 'mmr') {
    const selectedRank = selectedMode.ranks.find((_rank) => rank >= _rank.minMmr && rank <= _rank.maxMmr);

    return selectedRank || defaultData;
  }

  const selectedRank = selectedMode.ranks.find((_rank) => {
    if (type === 'class') return _rank.class === rank;
    if (type === 'name') return _rank.name === rank;
    return false;
  });

  return selectedRank || defaultData;
};

export default GAMES;
