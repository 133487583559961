import React from 'react';

// Hooks
import { useOrgaPage } from 'components/orgas/OrgaPage';

// Components
import CardPicDisplay from 'components/utils/CardPicDisplay';
import OrgaCardPicUploadPopup from './OrgaCardPicUploadPopup';

function OrgaCardPicHandler() {
  const { permissions, cardPic } = useOrgaPage();
  const { canUpdateOrga } = permissions;

  return (
    <div>
      <CardPicDisplay quadratic img={cardPic} url="/images/orgas/card_pictures/" />
      {
        canUpdateOrga && (
        <div className="mt-5">
          <OrgaCardPicUploadPopup />
        </div>
        )
      }
    </div>
  );
}

export default OrgaCardPicHandler;
