import { useEffect } from 'react';

// Hooks
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const wrapper = document.getElementById('contentWrapper');

    if (!wrapper) return;

    wrapper.scrollTop = 0;
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default ScrollToTop;
