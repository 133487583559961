import React from 'react';
import { useSelector } from 'react-redux';

const useCards = () => {
  const { cards, hasData } = useSelector((state) => state.cards);

  return {
    cards,
    hasData,
  };
};

export default useCards;
