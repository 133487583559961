import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';

// Components
import TournamentKickButton from './TournamentKickButton';

function TournamentKickPlayerHandler(props) {
  const { staffMember } = props;

  const { staffPermissions } = useTournament();
  const { canKickAdmin, canKickStaffMember } = staffPermissions;

  const renderedButton = useMemo(() => {
    const { role } = staffMember;

    if (role === 'creator') return null;

    if (role === 'admin' && !canKickAdmin) return null;

    if (role === 'staffMember' && !canKickStaffMember) return null;

    return <TournamentKickButton staffMember={staffMember} />;
  }, [staffMember, canKickAdmin, canKickStaffMember]);

  return renderedButton;
}

TournamentKickPlayerHandler.propTypes = {
  staffMember: PropTypes.shape({
    role: PropTypes.string.isRequired,
  }).isRequired,
};

export default TournamentKickPlayerHandler;
