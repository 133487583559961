import val from 'assets/images/cards/games/val.png';

// Icons
import val_icon from 'assets/images/games/val/val_icon.png';
import icon_white from 'assets/images/games/val/valorant_landing_icon.png';

// Rank images
import val_iron1 from 'assets/images/games/val/val_iron1.png';
import val_iron2 from 'assets/images/games/val/val_iron2.png';
import val_iron3 from 'assets/images/games/val/val_iron3.png';
import val_bronze1 from 'assets/images/games/val/val_bronze1.png';
import val_bronze2 from 'assets/images/games/val/val_bronze2.png';
import val_bronze3 from 'assets/images/games/val/val_bronze3.png';
import val_silver1 from 'assets/images/games/val/val_silver1.png';
import val_silver2 from 'assets/images/games/val/val_silver2.png';
import val_silver3 from 'assets/images/games/val/val_silver3.png';
import val_gold1 from 'assets/images/games/val/val_gold1.png';
import val_gold2 from 'assets/images/games/val/val_gold2.png';
import val_gold3 from 'assets/images/games/val/val_gold3.png';
import val_plat1 from 'assets/images/games/val/val_plat1.png';
import val_plat2 from 'assets/images/games/val/val_plat2.png';
import val_plat3 from 'assets/images/games/val/val_plat3.png';
import val_dia1 from 'assets/images/games/val/val_dia1.png';
import val_dia2 from 'assets/images/games/val/val_dia2.png';
import val_dia3 from 'assets/images/games/val/val_dia3.png';
import val_asc1 from 'assets/images/games/val/val_asc1.png';
import val_asc2 from 'assets/images/games/val/val_asc2.png';
import val_asc3 from 'assets/images/games/val/val_asc3.png';
import val_imm1 from 'assets/images/games/val/val_imm1.png';
import val_imm2 from 'assets/images/games/val/val_imm2.png';
import val_imm3 from 'assets/images/games/val/val_imm3.png';
import val_radiant from 'assets/images/games/val/val_radiant.png';

import ranks from './ranks';

export default {
  name: 'Valorant',
  tag: 'VAL',
  img: val,
  icon: val_icon,
  iconWhite: icon_white,
  platforms: ['pc'],
  modes: [
    {
      name: '5v5',
      amountPlayers: 5,
      ranks,
    },
  ],
  tagsPerCategory: [
    {
      category: 'general',
      tags: ['Tournament', 'Matchmaking'],
    },
    {
      category: 'properties',
      tags: ['IGL', 'Shotcall'],
    },
    {
      category: 'role',
      tags: ['Sentinel', 'Duelist', 'Initiator', 'Controller'],
    },
    {
      category: 'leagues',
      tags: ['Project V', 'Challengers', 'Regional', 'Gamechangers', 'Qualifier', 'Masters', 'Championship'],
    },
  ],

  mmr: [
    'Unranked',
    'Iron I',
    'Iron II',
    'Iron III',
    'Bronze I',
    'Bronze II',
    'Bronze III',
    'Silver I',
    'Silver II',
    'Silver III',
    'Gold I',
    'Gold II',
    'Gold III',
    'Platinum I',
    'Platinum II',
    'Platinum III',
    'Diamond I',
    'Diamond II',
    'Diamond III',
    'Ascendant I',
    'Ascendant II',
    'Ascendant III',
    'Immortal I',
    'Immortal II',
    'Immortal III',
    'Radiant',
  ],
  mmrShort: [
    'UNRKD',
    'IR I',
    'IR II',
    'IR III',
    'B I',
    'B II',
    'B III',
    'S I ',
    'S II',
    'S III',
    'G I',
    'G II',
    'G III',
    'P I',
    'P II',
    'P III',
    'D I',
    'D II',
    'D III',
    'ASC I',
    'ASC II',
    'ASC III',
    'IMM I',
    'IMM II',
    'IMM III',
    'RAD',
  ],
  mmrImages: [
    val_iron1,
    val_iron1,
    val_iron2,
    val_iron3,
    val_bronze1,
    val_bronze2,
    val_bronze3,
    val_silver1,
    val_silver2,
    val_silver3,
    val_gold1,
    val_gold2,
    val_gold3,
    val_plat1,
    val_plat2,
    val_plat3,
    val_dia1,
    val_dia2,
    val_dia3,
    val_asc1,
    val_asc2,
    val_asc3,
    val_imm1,
    val_imm2,
    val_imm3,
    val_radiant,
  ],
};
