import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cards: [],
  hasData: false,
};

export const cardSlice = createSlice({
  name: 'card',
  initialState,
  reducers: {
    setCards: (state, action) => ({
      ...state,
      cards: action.payload || [],
      hasData: true,
    }),
    setMatches: (state, action) => ({
      ...state,
      cards: state.cards.map((card) => {
        if (card._id !== action.payload.id) {
          return card;
        }

        return {
          ...card,
          matches: action.payload.matches,
        };
      }),
    }),
    addCard: (state, action) => ({
      ...state,
      cards: [...state.cards, action.payload],
    }),
    updateCard: (state, action) => ({
      ...state,
      cards: state.cards.map((card) => {
        if (card._id !== action.payload.id) {
          return card;
        }

        return action.payload.card;
      }),
    }),
    deleteCard: (state, action) => ({
      ...state,
      cards: state.cards.filter((card) => card._id !== action.payload),
    }),
    clearCards: () => initialState,
  },
});

export const {
  setCards, addCard, clearCards, updateCard, setMatches, deleteCard,
} = cardSlice.actions;

export default cardSlice.reducer;
