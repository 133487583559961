import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import TriangleDisplay from 'components/utils/TriangleDisplay';

// Constants
import TeamImageUploader from 'components/Teams/TeamImageUploader';

// Actions
import { setTeamCardPic } from 'store/teams';

// Assets
import coverImg from 'assets/images/base/onboarding_player_picture.png';

function TeamImageEditor(props) {
  const { disabled, setDisabled, onDone } = props;

  const { t } = useTranslation(['general', 'validation', 'errors']);

  const dispatch = useDispatch();

  const { teams } = useSelector((state) => state.teams);
  const teamIndex = teams.length - 1;

  const { publicId, cardPic } = teams[teamIndex];

  const setCardPic = (urlToPic) => {
    dispatch(setTeamCardPic({ cardPic: urlToPic, id: publicId }));
    onDone();
  };

  return (

    <div className="box p-0 has-fullheight">

      <div className="columns has-fullheight is-multiline">

        <div
          className="column is-5-desktop has-min-width-300 is-5-tablet p-0"
        >
          <div
            className="has-fullheight has-text-left"
            style={{ backgroundImage: `url(${coverImg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
          >
            <div className="p-5">
              <h1
                className="is-size-2-dekstop is-size-2-tablet has-text-weight-semibold"
              >
                {t('general:profile_picture')}
              </h1>
            </div>
          </div>
        </div>

        <div className="column p-0">

          <div className="field pt-3 px-5 pb-6 is-flex flex-direction-column has-fullheight is-relative has-background-black-gradient has-overflow-y-auto is-max-fullheight-80">
            <TriangleDisplay />

            <p className="is-size-5">
              {t('general:profile_picture')}
            </p>

            <div className="has-fullheight is-flex has-content-centered">
              <TeamImageUploader
                disabled={disabled}
                setDisabled={setDisabled}
                setCardPic={setCardPic}
                cardPic={cardPic}
                id={publicId}
              />
            </div>

            <div className="has-text-centered">
              <button
                type="button"
                className="cleanButton has-text-white has-text-weight-semibold mt-5"
                onClick={onDone}
                disabled={disabled}
              >
                {t('general:skip')}
              </button>
            </div>

          </div>

        </div>
      </div>

    </div>

  );
}

TeamImageEditor.propTypes = {
  disabled: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
};

export default TeamImageEditor;
