import React, { useState } from 'react';

// Libraries
import stats from 'analytics/matomo';
import PropTypes from 'prop-types';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Components
import GameSelector from 'components/utils/games/GameSelector';

// Actions
import { addTeam } from 'store/teams';
import TriangleDisplay from 'components/utils/TriangleDisplay';
import PlatformSelector from 'components/utils/PlatformSelector';
import NameEditor from './NameEditor';

// Assets
import coverImg from 'assets/images/base/onboarding_welcome_thumbnail.jpg';

function TeamBasicsEditor(props) {
  const { disabled, setDisabled, onDone } = props;
  /*   const {
    timezone, region: userRegion,
  } = useSelector((state) => state.session); */

  const { t } = useTranslation(['general', 'validation', 'errors']);

  const dispatch = useDispatch();

  const { post } = useAPI();

  const [name, setName] = useState('');
  const [platform, setPlatform] = useState('pc');
  const [game, setGame] = useState({
    tag: 'RL',
  });

  const [status, setStatus] = useState('');

  const reset = () => {
    setName('');
    setStatus('');
    setGame({
      tag: 'RL',
    });
  };

  /*   useEffect(() => () => {
    // clean status on unmount
    reset();
  }, []); */

  const createTeam = async () => {
    try {
      setStatus('');

      if (name.length < 3) {
        setStatus(t('validation:team_name_at_least_3_characters'));
        return;
      }

      setDisabled(true);

      const data = {
        game,
        name,
        platform,
      };

      const team = await post('/teams', data);

      stats.push(['trackEvent', 'Team List', 'New team created']);

      dispatch(addTeam(team));

      setDisabled(false);
      onDone();
    } catch (e) {
      console.log(e);

      setStatus(t('errors:could_not_create_team_error'));

      setDisabled(false);
    }
  };

  return (

    <div className="box p-0 has-fullheight">

      <div className="columns has-fullheight is-multiline">

        <div
          className="column is-5-desktop has-min-width-300 is-5-tablet p-0"
        >
          <div
            className="has-fullheight has-text-left"
            style={{ backgroundImage: `url(${coverImg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
          >
            <div className="p-5">
              <h1
                className="is-size-2-desktop is-size-2-tablet has-text-weight-semibold"
              >
                {t('general:create_team')}
              </h1>
            </div>
          </div>
        </div>

        <div className="column p-0">

          <div className="field p-3 is-flex flex-direction-column has-fullheight is-relative has-background-black-gradient pb-6">

            <div className="is-relative">
              <TriangleDisplay />
            </div>

            <div className="is-relative mt-6">

              <div className="columns is-multiline">
                <div className="column is-12">
                  <p className="mb-5 is-size-4">
                    {t('general:create_your_team')}
                  </p>
                  <NameEditor name={name} setName={setName} disabled={disabled} />
                </div>
                <div className="column is-12 mt-5">
                  <GameSelector
                    game={game}
                    setGame={(_game) => setGame({ tag: _game })}
                    filter={['NL']}
                    showGames="full"
                  />
                </div>
                <div className="column is-12 mt-5">
                  <PlatformSelector platform={platform} setPlatform={setPlatform} game={game.tag} />
                </div>
              </div>
            </div>

            <div className="is-inline-block has-text-centered has-margin-top-auto">
              <button
                type="button"
                onClick={() => createTeam()}
                disabled={disabled}
                className={`button is-size-5 px-5 has-background-primary has-text-white has-text-weight-semibold is-inline-block mt-6 ${disabled ? 'is-loading' : ''}`}
              >
                {t('general:create')}
              </button>
            </div>
            {
                status && (
                  <div className="has-text-centered mt-5">
                    <p className=" has-text-white">
                      {status}
                    </p>
                  </div>
                )
              }
          </div>

        </div>
      </div>

    </div>

  );
}

TeamBasicsEditor.propTypes = {
  disabled: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
};

export default TeamBasicsEditor;
