import React, { useMemo } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';

const TournamentAdminPanelHandler = () => {
  const { staffPermissions, setContentToShow, currentPhase } = useTournament();
  const { canUpdateTournamentInfo } = staffPermissions;

  const { t } = useTranslation(['general']);

  const renderedButton = useMemo(() => {
    if (!canUpdateTournamentInfo) return null;

    return (
      <button
        type="button"
        className="button has-fullheight has-fullwidth has-text-weight-bold is-danger"
        onClick={() => setContentToShow('admin')}
      >
        {t('general:you_are_tournament_admin_go_to_admin_panel')}
      </button>
    );
  }, [currentPhase, canUpdateTournamentInfo]);

  return renderedButton;
};

export default TournamentAdminPanelHandler;
