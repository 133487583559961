import React from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import DeleteTournamentButton from './DeleteTournamentButton';

function TournamentDeletionHandler() {
  const { currentPhase, staffPermissions } = useTournament();
  const { canDeleteTournament } = staffPermissions;

  if (currentPhase !== 'draft' || !canDeleteTournament) return null;

  return <DeleteTournamentButton />;
}

export default TournamentDeletionHandler;
