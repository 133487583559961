import React, {
  useEffect, useLayoutEffect, useMemo, useRef,
} from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useHotscrimps } from 'components/HotScrimps/HotscrimpsProvider';

// Styles
import styles from './styles.module.scss';

// Components
import ActiveHotscrimpsList from './ActiveHotscrimpsList';
import HistoricHotscrimpList from './HistoricHotscrimpsList';
import PendingHotscrimpsList from './PendingHotscrimpsList';
import CreateHotscrimpButton from './CreateHotscrimpButton';

// Assets
import shrimp from 'assets/images/base/icon_no_text.png';

function HotscrimpsSidebar() {
  const { t } = useTranslation(['general']);

  const wrapper = useRef();

  const {
    selectedMode, setSelectedMode,
  } = useHotscrimps();

  useLayoutEffect(() => {
    setSelectedMode('active');
  }, []);

  const renderedContent = useMemo(() => {
    switch (selectedMode) {
      case 'active':
        return <ActiveHotscrimpsList />;
      case 'pending':
        return <PendingHotscrimpsList />;
      case 'historic':
        return <HistoricHotscrimpList />;

      default:
        return null;
    }
  }, [selectedMode]);

  return (
    <div
      className="has-background-dark-blue has-text-weight-light is-flex flex-direction-column has-fullheight is-relative"
    >

      <div
        className="is-absolute has-text-centered has-fullwidth"
        style={{ bottom: -50 }}
      >
        <img
          alt="shrimp"
          src={shrimp}
          className=""
          style={{ opacity: 0.2 }}
        />
      </div>
      <div className="is-relative">
        <div className="has-text-centered mb-4 mt-3">
          <h2 className="has-text-standard is-size-5 has-text-weight-light">
            {t('general:scrims')}
          </h2>
          <p
            className="has-text-grey is-size-6"
          >
            {t('general:your_accepted_matches')}
          </p>
        </div>

        <div className="has-text-centered mb-3">
          <CreateHotscrimpButton />
        </div>

        <div className="columns is-centered is-multiline px-2 pb-3">
          <div className="column px-1">
            <button
              type="button"
              className={`button has-text-weight-light has-fullwidth has-text-standard has-text-white has-height-30 ${selectedMode === 'pending' ? 'has-gradient-primary' : 'has-background-grey-blue'}`}
              onClick={() => setSelectedMode('pending')}
            >
              {t('general:pending')}
            </button>
          </div>
          <div className="column px-1">

            <button
              type="button"
              className={`button has-text-weight-light has-fullwidth has-text-standard has-text-white has-height-30 ${selectedMode === 'active' ? 'has-gradient-primary' : 'has-background-grey-blue'}`}
              onClick={() => setSelectedMode('active')}
            >
              {t('general:active')}
            </button>
          </div>
          <div className="column px-1">
            <button
              type="button"
              className={`button has-text-weight-light has-fullwidth has-text-standard has-text-white has-height-30 ${selectedMode === 'historic' ? 'has-gradient-primary' : 'has-background-grey-blue'}`}
              onClick={() => setSelectedMode('historic')}
            >
              {t('general:history')}
            </button>
          </div>

        </div>

        <div
          id={styles.contentWrapper}
          className="flex-grow"

        >
          <div className="has-fullheight">
            { renderedContent }
          </div>
        </div>

      </div>
    </div>
  );
}

export default HotscrimpsSidebar;
