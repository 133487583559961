import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import Dropdown from 'components/utils/Dropdown';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import { AVAILABLE_REGIONS } from 'constants/user';

function RegionSelector(props) {
  const { t } = useTranslation(['general']);

  const { region, setRegion, allowAllRegions } = props;

  const _setRegion = (newRegion) => {
    const regionSelected = AVAILABLE_REGIONS.find((_region) => _region.value === newRegion.value);

    if (regionSelected === undefined || regionSelected.value === region) {
      return;
    }

    setRegion(regionSelected.value);
  };

  // useMemo is used for later use. currently all the data comes from AVAILABLE_REGIONS
  const preparedRegions = useMemo(() => {
    if (allowAllRegions) {
      return AVAILABLE_REGIONS;
    }

    return AVAILABLE_REGIONS.filter((_region) => _region.value !== '');
  }, [allowAllRegions]);

  const activeRegion = useMemo(() => preparedRegions.find((_region) => _region.value === region), [region]);

  return (
    <div className="field">

      <div className="">
        <p className="has-text-grey is-size-5 mb-2">
          {t('general:region')}
        </p>
        <div className="">
          <Dropdown
            items={preparedRegions}
            onSelect={_setRegion}
            value={activeRegion}
          />
        </div>
      </div>
    </div>
  );
}

RegionSelector.propTypes = {
  region: PropTypes.string.isRequired,
  setRegion: PropTypes.func.isRequired,
  allowAllRegions: PropTypes.bool,
};

RegionSelector.defaultProps = {
  allowAllRegions: false,
};

export default RegionSelector;
