import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import Dropdown from 'components/utils/Dropdown';

// Constants
import { AVAILABLE_NATIONALITIES } from 'constants/user';

function NationalitySelector(props) {
  const { nationality, setNationality } = props;

  const { t } = useTranslation(['general']);

  const currentValue = useMemo(() => {
    const foundNationality = AVAILABLE_NATIONALITIES.find((_nationality) => _nationality.value === nationality);

    return foundNationality || { label: '', value: '' };
  }, [nationality]);

  return (
    <div>
      <label className="label has-text-left is-size-7">
        {t('general:nationality')}
      </label>
      <div className="control">

        <div className="is-flex has-fullwidth">
          <Dropdown
            items={AVAILABLE_NATIONALITIES}
            onSelect={({ value }) => setNationality(value)}
            value={currentValue}
            className="has-fullwidth"
            isSearchable
          />
          <span className={`ml-3 is-size-3 fi fi-${currentValue.value}`} />
        </div>
      </div>
    </div>

  );
}

NationalitySelector.propTypes = {
  nationality: PropTypes.string.isRequired,
  setNationality: PropTypes.func.isRequired,
};

export default NationalitySelector;
