import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import Dropdown from 'components/utils/Dropdown';
import MmrNumberSelector from '../../MmrNumberSelector';

function MmrDropdown(props) {
  const { allRanks, rank, setMmr } = props;
  const { game, mode, mmr } = rank;

  const { t } = useTranslation(['general']);

  const setNumberMmr = (_mmr) => {
    setMmr(_mmr);
  };

  const _setMmr = (_mmr) => {
    setMmr(_mmr.value);
  };

  const availableRanks = useMemo(() => {
    const _ranks = allRanks.map((_rank) => ({
      label: _rank.name,
      value: _rank.minMmr,
      minMmr: _rank.minMmr,
      maxMmr: _rank.maxMmr,
    }));

    return _ranks;
  }, [allRanks]);

  const selectedRank = useMemo(() => {
    if (mmr === -1) {
      return availableRanks[0];
    }
    // mmr ranks have minMmr and maxMmr, we need to find the one that matches our current mmr
    const mmrRank = availableRanks.find((_rank) => _rank.minMmr <= mmr && _rank.maxMmr >= mmr);

    if (mmrRank === undefined) {
      return availableRanks[0];
    }

    return mmrRank;
  }, [allRanks, mmr]);

  return (
    <div className="field is-inline-block">
      <div className="">
        <p className="is-size-5 has-text-grey">
          {t('general:mmr')}
        </p>
        <div className="is-flex">
          <div className="has-width-200 has-margin-left-auto">
            <Dropdown
              items={availableRanks}
              onSelect={_setMmr}
              value={selectedRank}
              disabled={allRanks.length === 0}
            />
          </div>
          {
            game === 'RL' && (
            <div className="ml-2">
              <MmrNumberSelector
                mmr={mmr}
                setMmr={setNumberMmr}
                maxMmr={9999}
                disabled={allRanks.length === 0}
              />
            </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

MmrDropdown.propTypes = {
  rank: PropTypes.shape({
    game: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    mmr: PropTypes.number.isRequired,
  }).isRequired,
  setMmr: PropTypes.func.isRequired,
  allRanks: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    minMmr: PropTypes.number.isRequired,
    maxMmr: PropTypes.number.isRequired,
  })).isRequired,
};

export default MmrDropdown;
