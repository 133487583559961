export const DISCORD = 'discord';
export const DISCORD_SERVER = 'discordServer';
export const STEAM = 'steam';
export const RIOT = 'riot';
export const BATTLENET = 'battlenet';
export const EPIC = 'epic';
export const TWITTER = 'twitter';
export const TAGSGG = 'tagsgg';
export const INSTAGRAM = 'instagram';
export const TIKTOK = 'tiktok';
export const WEBSITE = 'website';
export const EMAIL = 'email';
export const XCOM = 'xcom';
export const YOUTUBE = 'youtube';
export const TWITCH = 'twitch';
