import React, { useMemo } from 'react';

// Components
import Dropdown from 'components/utils/Dropdown';

// Hooks
import { useTranslation } from 'react-i18next';

// Libraries
import stats from 'analytics/matomo';
import PropTypes from 'prop-types';
import { TOURNAMENT_TYPES } from 'constants/tournaments';

function TournamentTypeSelector(props) {
  const { tournamentType, setTournamentType } = props;

  const { t } = useTranslation(['general']);

  /*
  TOURNAMENT_TYPES.map((_type) => ({
    label: t(`general:${_type}`),
    value: _type,
  })
  */
  const availableTournamentTypes = useMemo(() => TOURNAMENT_TYPES.map((_type) => ({
    label: t(`general:${_type}`),
    value: _type,
  })), [t]);

  const _setTournamentType = (newTournamentType) => {
    stats.push(['trackEvent', 'Tournament Creator', 'Tournament type set', newTournamentType.value]);
    setTournamentType(newTournamentType.value);
  };

  const selectedTournamentType = useMemo(() => {
    const _selectedTournamentType = availableTournamentTypes.find((_type) => _type.value === tournamentType);

    return _selectedTournamentType || null;
  }, [tournamentType]);

  const renderedTournamentTypeSelector = useMemo(() => (
    <div className="">
      <p className="is-size-5 has-text-grey">
        {t('general:tournament_type')}
      </p>
      <div className="has-width-200 has-margin-left-auto">
        <Dropdown
          items={availableTournamentTypes}
          onSelect={_setTournamentType}
          value={selectedTournamentType}
        />
      </div>
    </div>

  ), [availableTournamentTypes, tournamentType]);

  return (
    <div className="field is-inline-block">
      { renderedTournamentTypeSelector }
    </div>
  );
}

TournamentTypeSelector.propTypes = {
  tournamentType: PropTypes.string.isRequired,
  setTournamentType: PropTypes.func.isRequired,
};

export default TournamentTypeSelector;
