import React, { useState, useEffect } from 'react';

// Libraries
// import copy from 'copy-to-clipboard';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function PlayerIdDisplay() {
  const { t } = useTranslation(['gemeral']);
  const { playerId } = useSelector((state) => state.player.player);

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) return undefined;

    const timeout = setTimeout(() => {
      setCopied(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);

  const copyInfo = () => {
    // copy(playerId);
    navigator.clipboard.writeText(`SCR-PID-${playerId}`);
    setCopied(true);
  };

  return (

    <button
      type="button"
      onClick={copyInfo}
      className="cleanButton has-text-white"
    >
      <div className="is-flex has-content-centered-vertically">
        <p className="has-text-weight-semibold is-size-4-desktop is-size-5-touch mr-2">
          {t('general:player_id')}
        </p>
        <i className="fas fa-copy is-size-5" />
        <div className="is-relative">
          { copied && (
          <div
            className="is-absolute has-max-z-index box boxshadow has-text-weight-semibold is-size-7 px-2 py-1 br7 has-text-white has-border-black-light"
            style={{ top: '-14px', left: '12px' }}
          >
            <p>
              {`${t('general:copied')}!`}
            </p>
          </div>
          )}

        </div>
      </div>

      <p className="mt-2 is-size-6">
        { `SCR-PID-${playerId}` }
      </p>

    </button>

  );
}

export default PlayerIdDisplay;
