import React, { useState, useEffect, useMemo } from 'react';

// Components
import Dropdown from 'components/utils/Dropdown';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Constants
import GAMES from 'constants/games';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

function GameFilterDropdown({
  game, setGame, filter, disabled,
}) {
  const { language } = useSelector((state) => state.session);

  const { t } = useTranslation(['general']);

  const availableGames = useMemo(() => {
    let games = GAMES;

    if (filter.length !== 0) {
      games = GAMES.filter((_game) => !filter.includes(_game.tag));
    }

    const preparedGames = games.map((_game) => ({
      label: _game.name,
      value: _game.tag,
    }));

    return [
      { label: t('general:all_games'), value: '' },
      ...preparedGames,
    ];
  }, [language]);

  const setGameActive = (newGame) => {
    stats.push(['trackEvent', 'Game Filter', 'Game filter selected']);
    // set game tag, reset mmr
    setGame({
      tag: newGame.value,
      mmr: { start: -1 },
    });
  };

  const selectedValue = useMemo(() => availableGames.find((_game) => _game.value === game.tag), [game.tag, language]);

  return (
    <div className="field">

      <div className="">

        <label className="label has-text-weight-semibold is-size-7">
          {t('general:game')}
        </label>
        <div className="has-fullwidth">
          <Dropdown
            items={availableGames}
            onSelect={setGameActive}
            value={selectedValue}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
}

GameFilterDropdown.propTypes = {
  game: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }).isRequired,
  setGame: PropTypes.func.isRequired,

  filter: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
};

GameFilterDropdown.defaultProps = {
  filter: [],
  disabled: false,
};

export default GameFilterDropdown;
