import React from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import useAPI from 'components/hooks/useAPI';

// Components
import RegionSelectorEditor from 'components/utils/RegionSelectorEditor';
import { useTeamProfile } from 'components/Teams/TeamProfile';

function TeamRegionSelector() {
  const { publicId, region, setRegion } = useTeamProfile();

  const { post } = useAPI();

  // const [status, setStatus] = useState('');

  const saveRegion = async (newRegion) => {
    try {
      // setStatus('');

      const data = { region: newRegion };

      await post(`/teams/region/${publicId}`, data);

      // dispatch(setRegion(newRegion));
      setRegion(newRegion);

      stats.push(['trackEvent', 'Team Profile', 'New region set']);

      // setStatus(`${t('general:saved_successfully')}`);
    } catch (e) {
      console.log(e);
      // setStatus(`${t('errors:save_new_region_error')}.`);
    }
  };

  return (
    <div className="">
      <RegionSelectorEditor region={region} setRegion={saveRegion} />
    </div>
  );
}

export default TeamRegionSelector;
