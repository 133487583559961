// Main image
import ow from 'assets/images/cards/games/ow.png';

// Icons
import ow_icon from 'assets/images/games/ow/ow_icon.png';
import icon_white from 'assets/images/games/ow/ow_landing_icon.png';

// Rank images
import ow_bronze from 'assets/images/games/ow/ow_bronze.png';
import ow_silver from 'assets/images/games/ow/ow_silver.png';
import ow_gold from 'assets/images/games/ow/ow_gold.png';
import ow_platin from 'assets/images/games/ow/ow_platin.png';
import ow_diamond from 'assets/images/games/ow/ow_diamond.png';
import ow_master from 'assets/images/games/ow/ow_master.png';
import ow_gm from 'assets/images/games/ow/ow_gm.png';
import ow_top500 from 'assets/images/games/ow/ow_top500.png';

import ranks from './ranks';

export default {
  name: 'Overwatch',
  tag: 'OW',
  img: ow,
  icon: ow_icon,
  iconWhite: icon_white,
  platforms: ['pc', 'switch'],
  modes: [
    {
      name: '5v5',
      amountPlayers: 5,
      ranks,
    },
  ],
  tagsPerCategory: [
    {
      category: 'general',
      tags: ['Tournament'],
    },
    {
      category: 'properties',
      tags: ['IGL', 'Shotcall'],
    },
    {
      category: 'role',
      tags: ['DPS', 'Hitscan', 'Projectile', 'Tank', 'Support'],
    },
    {
      category: 'leagues',
      tags: ['Open Division', 'Contenders', 'Overwatch League'],
    },
  ],

  mmr: [
    'Unranked',
    'Bronze',
    'Silver',
    'Gold',
    'Platinum',
    'Diamond',
    'Master',
    'Grandmaster',
    'Top 500',
  ],
  mmrShort: [
    'UNRKD',
    'Bronze',
    'Silver',
    'Gold',
    'Platinum',
    'Diamond',
    'Master',
    'Grandmaster',
    'Top 500',
  ],
  mmrImages: [
    ow_bronze,
    ow_bronze,
    ow_silver,
    ow_gold,
    ow_platin,
    ow_diamond,
    ow_master,
    ow_gm,
    ow_top500,
  ],
};
