import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import Dropdown from 'components/utils/Dropdown';

function InvitationTeamSelector(props) {
  const { teams, team, setTeam } = props;

  const { t } = useTranslation(['general']);

  const availableTeams = useMemo(() => teams.map((_team) => ({
    label: _team.name,
    value: _team.publicId,
  })), [teams]);

  const selectedTeam = useMemo(() => availableTeams.find((_team) => _team.value === team), [team]);

  const selectTeam = (_team) => {
    setTeam(_team.value);
  };

  return (
    <div>
      <p>
        {t('general:team')}
      </p>
      <Dropdown
        items={availableTeams}
        value={selectedTeam}
        onSelect={selectTeam}
      />
    </div>
  );
}

InvitationTeamSelector.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    publicId: PropTypes.string.isRequired,
  })).isRequired,
  team: PropTypes.string.isRequired,
  setTeam: PropTypes.func.isRequired,
};

export default InvitationTeamSelector;
