import React, { useState, useEffect } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { setCookieSettings } from 'store/session';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { PRIVACY } from 'constants/routes';

function TwitchCookieHandler() {
  const [height, setHeight] = useState(0);

  const dispatch = useDispatch();
  const { t } = useTranslation(['general']);

  const allowTwitchCookies = () => {
    dispatch(setCookieSettings({ twitchCookies: true }));
  };

  useEffect(() => {
    // when resize
    const handleResize = () => {
      const contentWrapper = document.getElementById('contentWrapper');
      const width = contentWrapper.clientWidth;
      // make height 16:9
      const _height = width / 1.777777777777778;
      setHeight(_height);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div id="" className="has-fullwidth" style={{ height }}>
      <div
        className="has-fullwidth has-fullheight has-border-grey br-5 is-flex has-content-centered flex-direction-column"
      >
        <p className="mb-4 has-text-grey is-size-5 px-6 has-text-centered">
          {t('general:twitch_cookie_message')}
        </p>
        <Link
          to={PRIVACY}
          className="has-text-underlined has-text-centered mb-4"
        >
          {t('general:privacy_policy')}
        </Link>
        <button
          type="button"
          onClick={allowTwitchCookies}
          className="button is-primary"
        >
          {t('general:allow_twitch_cookies')}
        </button>
      </div>

    </div>
  );
}

export default TwitchCookieHandler;
