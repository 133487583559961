import React, { useMemo } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
// Components
import Popup from 'reactjs-popup';
import GameFilterDropdown from 'components/utils/games/GameFilterDropdown';
// import MmrFilterDropdown from 'components/utils/games/MmrFilterDropdown';
// import PlatformFilterDropdown from 'components/utils/PlatformFilterDropdown';
import ModeFilterDropdown from 'components/utils/games/ModeFilterDropdown';
import CurrentPhaseSelector from './CurrentPhaseSelector';
import TournamentTypeSelector from './TournamentTypeSelector';

function TournamentFilter({
  filter, setFilter, resetFilter, disabled,
}) {
  const { t } = useTranslation(['general']);

  const {
    region,
    game,
    tournamentType,
    currentPhase,
  } = filter;

  const { tag, mode } = game;

  const _setGameTagFilter = (_tag) => {
    setFilter({ ...filter, game: { ...game, tag: _tag } });
  };

  const _setModeFilter = (_mode) => {
    setFilter({ ...filter, game: { ...game, mode: _mode } });
  };

  /*   const _setMmrFilter = (_mmr) => {
    setFilter({ ...filter, game: { ...game, mmr: _mmr } });
  }; */

  const _setCurrentPhaseFilter = (_currentPhase) => {
    setFilter({ ...filter, currentPhase: _currentPhase });
  };

  const _setRegionFilter = (_region) => {
    setFilter({ ...filter, region: _region });
  };

  const _setTournamentTypeFilter = (_tournamentType) => {
    setFilter({ ...filter, tournamentType: _tournamentType });
  };

  const amountActiveFilters = useMemo(() => {
    let amount = 0;

    if (region) amount += 1;
    if (game.tag) amount += 1;
    if (game.mode) amount += 1;
    if (tournamentType) amount += 1;
    if (currentPhase) amount += 1;

    return amount;
  }, [filter]);

  return (

    <div className="">

      <div className="has-text-right is-flex has-content-centered-vertically">
        <div className="has-margin-left-auto">
          <Popup
            position="top center"
            keepTooltipInside="#root"
            repositionOnResize
            closeOnDocumentClick
            closeOnEscape
            className="no-blur"
            trigger={(
              <div className="is-flex has-content-centered-vertically has-cursor-pointer dont-select-text">
                <i className="fas fa-sliders-h mr-3 is-size-5" />
                <p className="has-text-weight-semibold is-size-5">
                  { `${t('general:filter')} (${amountActiveFilters})`}
                </p>
              </div>
        )}
          >
            {
                () => (
                  <div className="">
                    <div className="box has-max-width-600">
                      <div className="is-flex has-content-centered-vertically">
                        <p className="has-text-weight-semibold is-size-5">
                          { t('general:filter')}
                        </p>
                        <button
                          onClick={resetFilter}
                          type="button"
                          className="cleanButton has-margin-left-auto"
                        >
                          <i className="fas fa-redo-alt has-text-primary is-size-5" />
                        </button>
                      </div>
                      <div className="columns is-multiline">
                        <div className="column is-2-desktop has-min-width-250">
                          <CurrentPhaseSelector
                            currentPhase={currentPhase}
                            setCurrentPhase={_setCurrentPhaseFilter}
                            disabled={disabled}
                          />
                        </div>

                        <div className="column is-2-desktop has-min-width-250">
                          <TournamentTypeSelector
                            tournamentType={tournamentType}
                            setTournamentType={_setTournamentTypeFilter}
                            disabled={disabled}
                          />
                        </div>

                        <div className="column is-2-desktop has-min-width-250">
                          <GameFilterDropdown
                            game={tag}
                            setGame={_setGameTagFilter}
                            filter={['NL']}
                            disabled={disabled}
                          />
                        </div>

                        {
                            game.tag && (
                            <div className="column is-2-desktop has-min-width-250">
                              <ModeFilterDropdown
                                game={tag}
                                mode={mode}
                                setMode={_setModeFilter}
                                disabled={disabled}
                              />
                            </div>
                            )
                        }

                      </div>
                    </div>
                  </div>
                )
            }
          </Popup>
        </div>
        <button
          onClick={resetFilter}
          type="button"
          className="cleanButton ml-3"
        >
          <i className="fas fa-redo-alt has-text-primary is-size-5" />
        </button>
      </div>

    </div>

  );
}

/*
<div>
      <div className="is-flex has-content-centered my-3 has-border-top-grey-lightpt-2">
        <p className="has-text-weight-semibold">
          { t('general:filter')}
        </p>
        <button
          onClick={_resetFilter}
          type="button"
          className="cleanButton has-margin-left-auto is-size-7 has-text-danger has-text-underlined"
        >
          {t('general:reset')}
        </button>
      </div>

      <NationalityFilterDropdown
        nationality={nationality}
        setNationality={_setNationalityFilter}
        disabled={disabled}
      />

      <EngagementFilter
        type={engagement}
        setType={_setEngagementFilter}
        disabled={disabled}
      />

      <GameFilterDropdown
        game={game}
        setGame={_setGameFilter}
        filter={['NL']}
        disabled={disabled}
      />

      <MmrFilterDropdown
        game={game}
        setFilter={_setGameFilter}
        disabled={disabled}
      />

      <PlatformFilterDropdown
        game={game.tag}
        platform={platform}
        setPlatform={_setPlatformFilter}
        disabled={disabled}
      />

      <TagSelector
        game={game.tag}
        tags={tags}
        setTags={_setTagsFilter}
        disabled={disabled}
      />

    </div>
*/

export default TournamentFilter;
