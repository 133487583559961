import React, { useState, useEffect } from 'react';

// Hooks
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Constants
import { HOME } from 'constants/routes';

// Components
import withAuthentication from 'components/hocs/withAuthentication';
import MatchCard from 'components/matches/MatchCard';
import MatchDisplay from 'components/matches/MatchDisplay';

function MatchDisplayContainer() {
  const [cardIndex, setCardIndex] = useState(-1);
  const { cards } = useSelector((state) => state.cards);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id === undefined) {
      return;
    }
    const index = parseInt(id, 10);

    if (Number.isNaN(index)) {
      navigate(HOME);
      return;
    }

    const selectedCard = cards[index];

    if (!selectedCard) {
      navigate(HOME);
      return;
    }

    setCardIndex(index);
  }, []);

  if (cardIndex === -1) {
    return null;
  }

  return (
    <div className="columns is-min-fullheight is-centered is-vcentered pb-6">
      <div className="column is-10 py-5">
        <div className="">
          <h2 className="is-size-2-desktop is-size-4-tablet is-size-4-mobile">
            Deine Suchkarte:
          </h2>
          <div className="columns is-centered">
            <div className="column is-narrow">
              <MatchCard data={cards[cardIndex]} showAnimation={false} />
            </div>
          </div>
          <h2 className="is-size-2-desktop is-size-4-tablet is-size-4-mobile">
            Deine Matches:
          </h2>
          <MatchDisplay index={cardIndex} />
        </div>
      </div>
    </div>
  );
}

export default withAuthentication(MatchDisplayContainer);
