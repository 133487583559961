import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react';

// Libraries
// import copy from 'copy-to-clipboard';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Constants
import { MAX_AMOUNT_RANKS } from 'constants/player';
import { setPlayer } from 'store/player';
import GAMES from 'constants/games';
import RankEntryEditor from './RankEntryEditor';

function RanksEditor() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['gemeral', 'validation', 'errors']);
  const { ranks } = useSelector((state) => state.player.player);

  const [status, setStatus] = useState('');
  const [disabled, setDisabled] = useState(false);

  const [newRanks, setNewRanks] = useState(ranks);

  const { post } = useAPI();

  const saveRanks = async () => {
    try {
      // check if ranks are valid, if not, show error message
      const invalidRank = newRanks.find((rank) => rank.mode === '' || rank.mmr === -1 || rank.game === '');

      if (invalidRank) {
        setStatus(t('validation:fill_in_all_fields_error'));
        return;
      }

      setDisabled(true);

      const player = await post('/player/ranks', { ranks: newRanks });
      dispatch(setPlayer(player));

      setDisabled(false);
      setStatus(t('general:saved_successfully'));
    } catch (err) {
      setDisabled(false);
      setStatus(t('errors:could_not_save_error'));
    }
  };

  const addRank = () => {
    if (newRanks.length >= MAX_AMOUNT_RANKS) return;

    setNewRanks([...newRanks, { mode: '', mmr: 0, game: 'RL' }]);
  };

  const removeRank = useCallback((index) => {
    setNewRanks((prevRanks) => {
      const _newRanks = prevRanks.filter((_, i) => i !== index);
      return _newRanks;
    });
  }, [newRanks]);

  const updateRank = (index, rank) => {
    setNewRanks((prevRanks) => {
      const _newRanks = [...prevRanks];
      _newRanks[index] = rank;

      return _newRanks;
    });
  };

  const renderedRanks = useMemo(() => newRanks.map((rank, index) => (
    <div className="is-flex" key={index}>
      <RankEntryEditor
        allRanks={newRanks}
        index={index}
        rank={rank}
        setRank={updateRank}
      />
      <button
        type="button"
        className="cleanButton has-text-danger mt-5 pt-2 ml-4"
        onClick={() => removeRank(index)}
      >
        <i className="fas fa-times" />
      </button>
    </div>
  )), [newRanks]);

  const hasChange = useMemo(() => {
    if (newRanks.length !== ranks.length) return true;

    for (let i = 0; i < newRanks.length; i++) {
      if (newRanks[i].game !== ranks[i].game) return true;
      if (newRanks[i].mode !== ranks[i].mode) return true;
      if (newRanks[i].mmr !== ranks[i].mmr) return true;
    }

    return false;
  }, [newRanks, ranks]);

  const hasAllGameModes = useMemo(() => {
    for (let i = 0; i < GAMES.length; i++) {
      const { modes, tag } = GAMES[i];

      for (let j = 0; j < modes.length; j++) {
        const found = newRanks.find((rank) => rank.game === tag && rank.mode === modes[j].name);
        if (!found) return false;
      }
    }

    return true;
  }, [newRanks]);

  return (
    <div>
      <div className="is-flex has-content-centered-vertically mb-5">
        <h3
          className="has-text-weight-semibold is-size-4-desktop is-size-5-touch has-text-standard"
        >
          {t('general:mmr_preferences')}
        </h3>

        {
          !hasAllGameModes && (
            <button
              type="button"
              className="button has-no-background grow_small has-text-grey has-border-dashed-grey ml-3"
              onClick={addRank}
              disabled={disabled}
            >
              <i className="fas fa-plus mr-2" />
              {t('general:add_more')}
            </button>
          )
        }
      </div>

      <div>
        { renderedRanks }
      </div>

      {
        hasChange && (
          <button
            type="button"
            className="button has-text-weight-semibold grow is-primary mt-5"
            onClick={saveRanks}
          >
            {t('general:save')}
          </button>
        )
      }

      {
        status && (
          <p className="has-text-weight-semibold mt-5">
            {status}
          </p>
        )
      }

    </div>
  );
}

export default RanksEditor;
