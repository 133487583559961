import React, { useState, useMemo, useEffect } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';

import ForceWinHandler from './ForceWinHandler';
import GameOverwriteHandler from './GameOverwriteHandler';

function MatchAdminPanel() {
  const { sidebarContent, publicId } = useTournament();

  const { data: match } = sidebarContent;

  const { t } = useTranslation(['general']);

  const renderedContent = useMemo(() => {
    if (!match) return null;

    if (match.status === 'upcoming') {
      return (
        <p className="px-3 mt-3 has-text-centered">
          {t('general:match_has_not_started')}
        </p>
      );
    }

    if (match.status === 'finished') {
      return (
        <p className="px-3 mt-3 has-text-centered">
          {t('general:match_has_ended')}
        </p>
      );
    }

    return (
      <div className="pt-2">
        <GameOverwriteHandler />

        <div className="mt-5 px-3">
          <ForceWinHandler />
        </div>
      </div>
    );
  }, [match.status]);

  return (
    <div className="mt-5">
      <div className="has-background-danger px-2 br-5 has-text-centered">
        <p className="has-text-weight-bold">
          {t('general:admin_panel')}
        </p>
      </div>

      <div className="">
        {renderedContent}
      </div>
    </div>
  );
}

export default MatchAdminPanel;
