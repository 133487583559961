import React, { useLayoutEffect, useMemo, useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useTournament } from 'components/tournaments/TournamentPage';

// Components
import TournamentMatchHandler from './TournamentMatchHandler';
import TournamentChatHandler from './TournamentChatHandler';
import TournamentSidebarSocketHandler from './TournamentSidebarSocketHandler';
import TournamentGlobalChatHandler from './TournamentGlobalChatHandler';

function TournamentSidebar() {
  const {
    hideMatchSidebar, sidebarContent, staffPermissions, participant, streaming,
  } = useTournament();

  // either 'details' or 'chat'
  const [pageToShow, setPageToShow] = useState('details');
  const { t } = useTranslation(['general']);

  useLayoutEffect(() => {
    if (sidebarContent.type === 'match') {
      setPageToShow('details');
    }
  }, [sidebarContent.data, sidebarContent.type]);

  const canJoinChat = useMemo(() => {
    const { participants: matchParticipants } = sidebarContent.data;

    if (!matchParticipants || matchParticipants.length === 0) return false;

    if (staffPermissions.canManageMatches) {
      return true;
    }

    if (!participant.publicId) return false;

    const isInMatch = matchParticipants.some((p) => p.publicId === participant.publicId);

    if (!isInMatch) {
      return false;
    }

    return true;
  }, [participant, sidebarContent, staffPermissions]);

  if (!sidebarContent.type) return null;

  let content;

  if (pageToShow === 'chat') content = <TournamentChatHandler />;
  if (pageToShow === 'details' || !canJoinChat) {
    content = (
      <>
        <TournamentSidebarSocketHandler />
        <TournamentMatchHandler />
      </>
    );
  }
  if (sidebarContent.type === 'global_chat') content = <TournamentGlobalChatHandler />;

  const showTabButtons = sidebarContent.type === 'match';

  return (
    <div
      id="tournamentSidebar"
      className=" p-0 is-narrow has-width-350 has-fullheight"
      style={{ zIndex: 1000 }}
    >
      <div className="is-fixed">
        <div
          className="is-fullheight has-width-350 has-background-dark-blue p-0 has-border-darkest-light"
        >
          <div
            className="is-flex flex-direction-column has-fullheight has-fullwidth is-relative"
          >

            <div className="has-text-right">
              <button
                type="button"
                className="cleanButton"
                onClick={hideMatchSidebar}
                aria-label={t('general.close')}
              >
                <i className="fas fa-times has-text-grey is-size-4 py-3 px-3" />
              </button>
            </div>

            <div
              className="is-relative has-fullheight has-overflow-y-auto"
              style={{ zIndex: 1000 }}
            >
              { content }
            </div>

            {
              showTabButtons && (
                <div className="has-margin-top-auto">
                  <div className="is-relative">
                    <div className="has-background-dark pt-2 pb-2 is-relative" style={{ zIndex: 100 }}>
                      <div className="columns is-mobile">

                        <div className="column has-text-centered">
                          <button
                            type="button"
                            className="cleanButton"
                            onClick={() => setPageToShow('details')}
                            aria-label={t('general.details')}
                          >
                            <i className="fas fa-gamepad has-text-grey is-size-4" />
                          </button>
                        </div>

                        {
                          canJoinChat && (
                            <div className="column has-text-centered">
                              <button
                                type="button"
                                className="cleanButton"
                                onClick={() => setPageToShow('chat')}
                                aria-label={t('general.chat')}
                              >
                                <i className="fas fa-comments has-text-grey is-size-4" />
                              </button>
                            </div>
                          )
                        }

                      </div>
                    </div>
                  </div>
                </div>
              )
            }

          </div>

        </div>
      </div>
    </div>
  );
}

/*
<div
                      style={{ zIndex: 10 }}
                      className="is-relative"
                    >
                      <LogoBottomDisplay />
                    </div>
*/

export default TournamentSidebar;
