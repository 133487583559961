import React, { useState, useEffect, useMemo } from 'react';

// Components
import Dropdown from 'components/utils/Dropdown';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import GAMES from 'constants/games';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

// Components
import { Range } from 'react-range';
import GameSelector from '../../games/GameSelector';
import MmrSelector from '../../games/MmrSelector';
import ModeSelector from '../../games/ModeSelector';

function RankSelector({
  rank, setRank, type, filter, showGames,
}) {
  const { game, mmr, mode } = rank;

  const _setGame = (_game) => {
    setRank({ game: _game, mmr: -1, mode: '' });
  };

  const _setMmr = (_mmr) => {
    setRank({ ...rank, mmr: _mmr });
  };

  const _setMode = (_mode) => {
    setRank({ ...rank, mode: _mode });
  };

  return (
    <div className="field">
      <GameSelector
        game={game}
        setGame={_setGame}
        filter={filter}
        showGames={showGames}
      />
      {
        game && (
          <ModeSelector rank={rank} setMode={_setMode} />
        )
      }
      { mode && (
        <MmrSelector rank={rank} setMmr={_setMmr} />
      )}
    </div>
  );
}

RankSelector.propTypes = {
  rank: PropTypes.shape({
    game: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    mmr: PropTypes.number.isRequired,
  }).isRequired,
  setRank: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,

  showGames: PropTypes.string,
  filter: PropTypes.arrayOf(PropTypes.string),
};

RankSelector.defaultProps = {
  filter: [],
  showGames: 'dropdown',
};

export default RankSelector;
