import React, { useMemo } from 'react';

// Hooks
import { useOrgaPage } from 'components/orgas/OrgaPage';

// Components
import OrgaDetailsDisplay from './OrgaDetailsDisplay';
import OrgaDetailsEditor from './OrgaDetailsEditor';

const OrgaDetailsHandler = () => {
  const { permissions, orgaDetails } = useOrgaPage();
  const { canUpdateOrga } = permissions;

  const renderedName = useMemo(() => {
    if (canUpdateOrga) {
      return (
        <>
          <OrgaDetailsEditor />
          <div className="mt-3">
            <OrgaDetailsDisplay />
          </div>
        </>
      );
    }

    return (
      <OrgaDetailsDisplay />
    );
  }, [orgaDetails]);

  return renderedName;
};

export default OrgaDetailsHandler;
