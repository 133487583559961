import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

// Hooks
import { useTranslation } from 'react-i18next';
import { useTournament } from 'components/tournaments/TournamentPage';
import useAPI from 'components/hooks/useAPI';
import { useTournamentStaff } from 'components/tournaments/TournamentPage/TournamentEditor/TournamentStaffHandler';

// Components
import Dropdown from 'components/utils/Dropdown';

// Constants
import { STAFF_TOURNAMENT_ROLES } from 'constants/tournaments';

function SetTournamentRoleDropdown(props) {
  const {
    playerId, tournamentRole, disabled, setDisabled,
  } = props;

  const { t } = useTranslation(['general']);
  const { post } = useAPI();

  const {
    staffPermissions, publicId,

    setStaffMember,
  } = useTournament();
  const { staffToEdit, setStaffToEdit } = useTournamentStaff();

  const { canSetStaffTournamentRole } = staffPermissions;

  const _setTournamentRole = async (_role) => {
    try {
      setDisabled(true);

      const newRole = _role.value;

      const data = {
        playerId,
        tournamentRole: newRole,
      };

      await post(`/tournaments/staff/tournamentRole/${publicId}`, data);

      const updatedStaff = {
        ...staffToEdit,
        tournamentRole: newRole,
      };

      // set for the tournament staff list
      setStaffMember(updatedStaff);
      // set for the current popup
      setStaffToEdit(updatedStaff);

      stats.push(['trackEvent', 'Tournament Staff', 'Set tournament role']);

      setDisabled(false);
    } catch (e) {
      console.log(e);
      setDisabled(false);
    }
  };

  const availableTournamentRoles = useMemo(() => {
    const roles = STAFF_TOURNAMENT_ROLES.map((role) => ({
      value: role.type,
      label: role.type ? t(`general:${role.type}`) : t('general:no_tournament_role'),
    }));

    return roles;
  }, [STAFF_TOURNAMENT_ROLES]);

  const selectedRole = useMemo(() => {
    const role = availableTournamentRoles.find((_role) => _role.value === tournamentRole);

    return role || { value: '', label: '' };
  }, [tournamentRole, availableTournamentRoles]);

  if (!canSetStaffTournamentRole) return null;

  return (
    <div>
      <p
        className=""
      >
        {t('general:tournament_role')}
      </p>
      <div className="columns is-multiline">
        <div className="column px-0">
          <Dropdown
            items={availableTournamentRoles}
            onSelect={_setTournamentRole}
            value={selectedRole}
            disabled={disabled}
          />
        </div>

        <div className="column px-0 is-3 has-min-width-200 is-flex has-content-centered">
          {
            selectedRole.value && (
              <p className="is-size-7 has-border-grey p-1 br-5">
                { selectedRole.label }
              </p>
            )
          }
        </div>
      </div>
    </div>

  );
}

SetTournamentRoleDropdown.propTypes = {
  playerId: PropTypes.string.isRequired,
  tournamentRole: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
};

export default SetTournamentRoleDropdown;
