import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

function ColorContent(props) {
  const { color, children, className } = props;

  return (
    <div
      className={`${styles.outerBox} ${className}`}
      style={{ backgroundColor: color }}
    >
      { children }
    </div>
  );
}

ColorContent.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ColorContent.defaultProps = {
  color: '',
  className: '',
};

export default ColorContent;
