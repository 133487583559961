import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

function DescriptionDisplay(props) {
  const { description } = props;

  const { t } = useTranslation(['general']);

  return useMemo(() => {
    const hasDescription = description && description.length > 0;

    if (!hasDescription) {
      return null;
    }

    return (
      <div className="has-max-height-300 has-fullwidth has-overflow-y-auto mb-5">
        <p
          className="has-text-grey"
        >
          { t('general:description') }
        </p>
        <p className="has-border is-size-6 has-text-standard">
          { description }
        </p>
      </div>
    );
  }, [description]);
}

DescriptionDisplay.propTypes = {
  description: PropTypes.string,
};

DescriptionDisplay.defaultProps = {
  description: '',
};

export default DescriptionDisplay;
