import React, { useMemo } from 'react';

// Hooks
import { useOrgaPage } from 'components/orgas/OrgaPage';
import { useTranslation } from 'react-i18next';

function OrgaDetailsDisplay() {
  const { orgaDetails } = useOrgaPage();

  const { t } = useTranslation(['general']);

  const { address, name, phone } = orgaDetails;
  const {
    line1, line2, zipCode, city, country, state,
  } = address;

  const renderedAddress = useMemo(() => {
    if (!name && !line1 && !line2 && !zipCode && !city && !country && !state) return null;

    return (
      <div className="mb-3">
        <h3 className="is-size-5 mb-2">
          {t('general:address')}
        </h3>
        {
            name && (
            <p className="is-size-5 has-text-grey">
              {name}
            </p>
            )
        }

        {
            line1 && (
            <p className="is-size-5 has-text-grey">
              {line1}
            </p>
            )
        }

        {
            line2 && (
            <p className="is-size-5 has-text-grey">
              {line2}
            </p>
            )
        }

        {
            (zipCode || city) && (
            <p className="is-size-5 has-text-grey">
              {zipCode}
              {' '}
              {city}
            </p>
            )
        }

        {
            state && (
            <p className="is-size-5 has-text-grey">
              {state}
            </p>
            )
        }
      </div>
    );
  }, [address, name]);

  const renderedPhone = useMemo(() => {
    if (!phone) return null;

    if (phone.length < 3) return `+${phone}`;

    // add + to phone number and split into 3 digit chunks after the first digit
    // make sure if works for any phone number length
    const formattedPhone = `+${phone.slice(0, 2)} ${phone.slice(2).match(/\d{1,3}/g).join(' ')}`;

    return formattedPhone;
  }, [phone]);

  return (
    <div>

      {
        country && (
        <div className="mb-3">
          <h3 className="is-size-5 mb-2">
            {t('general:location')}
          </h3>
          <div className={`fi fi-${country} is-size-3`} />
        </div>
        )
      }

      { renderedAddress }

      {
        renderedPhone && (
        <div>
          <h3 className="is-size-5 mb-2">
            {t('general:phone')}
          </h3>

          <p className="is-size-5 has-text-grey">
            {renderedPhone}
          </p>
        </div>
        )
      }
    </div>
  );
}

export default OrgaDetailsDisplay;
