import React, { useState, useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

// Hooks
import { useTranslation } from 'react-i18next';
import { useOrgaPage } from 'components/orgas/OrgaPage';
import useAPI from 'components/hooks/useAPI';

// Components
import Dropdown from 'components/utils/Dropdown';

// Constants
import { ORGA_ROLES } from 'constants/orgas';

function SetOrgaRoleDropdown(props) {
  const {
    playerId, orgaRole, disabled, setDisabled,
  } = props;

  const { t } = useTranslation(['general']);
  const { post } = useAPI();

  const {
    permissions, publicId, memberToSetRoles,

    setOrgaRole, setMemberToSetRoles,
  } = useOrgaPage();
  const { canSetOrgaRole } = permissions;

  const _setOrgaRole = async (_role) => {
    try {
      setDisabled(true);

      const newRole = _role.value;

      const data = {
        orgaId: publicId,
        playerId,
        orgaRole: newRole,
      };

      await post('/orgas/members/orgaRole', data);

      setOrgaRole(playerId, newRole);
      setMemberToSetRoles({
        ...memberToSetRoles,
        orgaRole: newRole,
      });

      stats.push(['trackEvent', 'Orga Profile', 'Set orga role']);

      setDisabled(false);
    } catch (e) {
      console.log(e);
      setDisabled(false);
    }
  };

  const availableOrgaRoles = useMemo(() => {
    const roles = ORGA_ROLES.map((role) => ({
      value: role.type,
      label: role.type ? t(`general:${role.type}`) : t('general:no_orga_role'),
    }));

    return roles;
  }, [ORGA_ROLES]);

  const selectedRole = useMemo(() => {
    const role = availableOrgaRoles.find((_role) => _role.value === orgaRole);

    return role || { value: '', label: '' };
  }, [orgaRole, availableOrgaRoles]);

  if (!canSetOrgaRole) return null;

  return (
    <div>
      <p
        className=""
      >
        {t('general:orga_role')}
      </p>
      <div className="columns is-multiline">
        <div className="column px-0">
          <Dropdown
            items={availableOrgaRoles}
            onSelect={_setOrgaRole}
            value={selectedRole}
            disabled={disabled}
          />
        </div>

        <div className="column px-0 is-3 has-min-width-200 is-flex has-content-centered">
          {
            selectedRole.value && (
              <p className="is-size-7 has-border-grey p-1 br-5">
                { selectedRole.label }
              </p>
            )
          }
        </div>
      </div>
    </div>

  );
}

SetOrgaRoleDropdown.propTypes = {
  playerId: PropTypes.string.isRequired,
  orgaRole: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
};

export default SetOrgaRoleDropdown;
