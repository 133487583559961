import React, { useState, useEffect, useMemo } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';
import { useNotifications } from '../NotificationProvider';

// Components
import Notification from '../Notification';

// Styles
import styles from './styles.module.scss';

// Assets
import shrimp from 'assets/images/base/icon_no_text.png';

function NotificationSidebar() {
  const { t } = useTranslation(['general']);

  const { notifications, markAllNotificationsAsRead, deleteAllNotifications } = useNotifications();
  const { del, post } = useAPI();

  const [disabled, setDisabled] = useState(false);

  const markAllAsRead = async () => {
    try {
      // if all are already read, no need to clear
      if (!notifications.some((notification) => !notification.read)) return;

      setDisabled(true);
      await post('/notifications/read');
      markAllNotificationsAsRead();
      setDisabled(false);
    } catch (e) {
      setDisabled(false);
      console.log(e);
    }
  };

  const clearAll = async () => {
    try {
      if (notifications.length === 0) return;

      setDisabled(true);
      await del('/notifications');
      deleteAllNotifications();
      setDisabled(false);
    } catch (e) {
      setDisabled(false);
      console.log(e);
    }
  };

  const renderedNotifications = useMemo(() => notifications.map((notification) => <Notification notification={notification} key={notification.publicId} />), [notifications]);

  return (
    <div
      className="mt-3 has-background-dark-blue has-text-weight-light is-flex flex-direction-column is-relative has-fullheight"

    >

      <div>
        <div className="has-text-centered mt-1">
          <h2 className="has-text-standard is-size-5 has-text-weight-light">
            {t('general:notifications')}
          </h2>
          <p className="has-text-grey">
            {t('general:messages')}
          </p>
        </div>

        <div className="is-flex my-2 mx-2">
          <button
            type="button"
            className="button is-size-7 cleanButton has-text-grey-light"
            onClick={markAllAsRead}
            disabled={disabled}
          >
            {t('general:mark_all_as_read')}
          </button>
          <button
            type="button"
            className="cleanButton has-text-grey-light has-margin-left-auto is-size-7"
            onClick={clearAll}
            disabled={disabled}
          >
            {t('general:clear_all')}
          </button>
        </div>

        <div
          id={styles.contentWrapper}
          className="has-overflow-y-auto flex-grow"
        >
          <div id={styles.content}>
            {renderedNotifications}
          </div>
        </div>
      </div>

      <div
        className="is-absolute has-text-centered has-fullwidth"
        style={{ bottom: -50 }}
      >
        <img
          alt="shrimp"
          src={shrimp}
          className=""
          style={{ opacity: 0.2 }}
        />
      </div>
    </div>
  );
}

export default NotificationSidebar;
