import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

import MatchCard from 'components/matches/MatchCard';
import ContactCard from 'components/matches/ContactCard';

import styles from './styles.module.scss';

function FlipCard(props) {
  const [height, setHeight] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);

  const { data, children } = props;

  const child1 = useRef();
  const child2 = useRef();

  useEffect(() => {
    if (!child1.current || !child2.current) return;
    // set height to highest element
    if (child1.current.scrollHeight > child2.current.scrollHeight) {
      setHeight(child1.current.scrollHeight);
    } else {
      setHeight(child2.current.scrollHeight);
    }
  }, [child1, child2]);

  const toggleFlip = () => {
    stats.push(['trackEvent', 'SearchCard', 'Flip']);
    setIsFlipped(!isFlipped);
  };

  return (
    <div
      className={`${styles.card} ${(isFlipped ? styles.flipped : '')}`}
      style={{ height }}
    >
      <div className={`has-fullheight ${styles.front}`} ref={child1}>
        <div className="columns is-centered has-fullheight is-mobile">
          <div className="column is-paddingless">
            <MatchCard data={data} showAnimation={false}>
              <button
                type="button"
                className="button has-background-secondary has-fullwidth is-rounded has-text-weight-semibold my-3 grow_small"
                onClick={toggleFlip}
              >
                Jetzt kontaktieren
              </button>
              { children }
            </MatchCard>
          </div>
        </div>
      </div>
      <div className={`has-fullheight ${styles.back}`} ref={child2}>
        <div className="columns is-centered has-fullheight">
          <ContactCard data={data} close={toggleFlip} />
        </div>
      </div>
    </div>
  );
}

FlipCard.propTypes = {
  // close: PropTypes.func,
  data: PropTypes.shape({}).isRequired,
  children: PropTypes.node,
};

FlipCard.defaultProps = {
  // close: () => {},
  children: undefined,
};

export default FlipCard;
