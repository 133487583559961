import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTournamentRegistration } from 'components/tournaments/TournamentPage/TournamentPhaseHandler/TournamentRegistration';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Components
import TournamentTeamSelector from './TournamentTeamSelector';
import TournamentPlayerSelector from './TournamentPlayerSelector';
import TournamentPlayerRoleSelector from './TournamentPlayerRoleSelector';

// Constants
import { getSelectedModeFromGame } from 'constants/games';

function TournamentTeamRegistrationHandler(props) {
  const { close } = props;

  const [team, setTeam] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const {
    selectedParticipants, setSelectedParticipants, setTeamId,
  } = useTournamentRegistration();
  const { game, publicId, setParticipant } = useTournament();

  const { post } = useAPI();
  const { t } = useTranslation(['general']);

  const _selectTeams = (_team) => {
    setTeam(_team);
    setTeamId(_team.publicId);

    setSelectedParticipants([]);
  };

  const isValid = () => {
    const { amountPlayers } = getSelectedModeFromGame(game.tag, game.mode);

    if (selectedParticipants.length !== amountPlayers) {
      setStatus(t('errors:insufficient_amount_players_error'));
      return false;
    }

    const { mmrRange } = game;

    if (mmrRange.start !== -1) {
      const allMmrs = [];

      for (let i = 0; i < selectedParticipants.length; i++) {
        const { playerId } = selectedParticipants[i];

        const player = team.members.find((p) => p.playerId === playerId);

        // eslint-disable-next-line no-continue
        if (!player) continue;

        const rank = player.ranks.find((r) => r.game === game.tag && r.mode === game.mode);

        // eslint-disable-next-line no-continue
        if (!rank) continue;

        allMmrs.push(rank.mmr);
      }

      const averageMmr = Math.floor(allMmrs.reduce((a, b) => a + b, 0) / allMmrs.length);

      // check if the average mmr is within the mmr range
      if (averageMmr < mmrRange.start || averageMmr > mmrRange.end) {
        setStatus(t('errors:avg_mmr_not_in_mmr_range_error'));
        return false;
      }
    }

    // check if one is captain
    const captain = selectedParticipants.find((p) => p.role === 'captain');
    if (!captain) {
      setStatus(t('errors:tournament_missing_captain_error'));
      return false;
    }

    return true;
  };

  const registerTeam = async () => {
    try {
      if (!isValid()) return;

      setStatus('');
      setDisabled(true);

      const _selectedParticipants = selectedParticipants.map((p) => ({
        playerId: p.playerId,
        role: p.role,
      }));

      const participant = await post(`/tournaments/registration/team/${publicId}`, {
        teamId: team.publicId,
        participants: _selectedParticipants,
      });

      setDisabled(false);
      close();

      const isInTeam = participant.players.find((player) => player.playerId === participant.playerId);

      if (isInTeam) {
        setParticipant(participant);
      }
      // setStatus(t('general:registration_successful'));
    } catch (err) {
      setDisabled(false);

      if (err.response && err.response.status === 409) {
        setStatus(t('errors:max_participants_error'));
        return;
      }

      setStatus(t('errors:team_registration_error'));
    }
  };

  return (
    <div>
      <TournamentTeamSelector setTeam={_selectTeams} />

      {
        team && (
          <div>
            <TournamentPlayerSelector
              game={game}
              mode={game.mode}
              allPlayers={team.members}
            />
            <TournamentPlayerRoleSelector />
            {
              status && (
                <p
                  className="has-text-centered has-text-white has-text-weight-semibold mt-6"
                >
                  {status}
                </p>
              )
            }
            <div className="has-text-centered">
              <button
                type="button"
                className={`button is-primary mt-6 ${disabled ? 'is-loading' : ''}`}
                onClick={registerTeam}
                disabled={disabled}
              >
                {t('general:register_for_tournament')}
              </button>
            </div>
          </div>
        )
      }
    </div>
  );
}

TournamentTeamRegistrationHandler.propTypes = {
  close: PropTypes.func.isRequired,
};

export default TournamentTeamRegistrationHandler;
