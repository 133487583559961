import ow_bronze from 'assets/images/games/ow/ow_bronze.png';
import ow_silver from 'assets/images/games/ow/ow_silver.png';
import ow_gold from 'assets/images/games/ow/ow_gold.png';
import ow_platin from 'assets/images/games/ow/ow_platin.png';
import ow_diamond from 'assets/images/games/ow/ow_diamond.png';
import ow_master from 'assets/images/games/ow/ow_master.png';
import ow_gm from 'assets/images/games/ow/ow_gm.png';
import ow_top500 from 'assets/images/games/ow/ow_top500.png';

const ranks = [
  {
    name: 'Unranked',
    shortName: 'UNRKD',
    class: 0,
    minMmr: 0,
    maxMmr: 0,
    img: ow_bronze,
  },
  {
    name: 'Bronze',
    shortName: 'BRNZ',
    class: 1,
    minMmr: 1,
    maxMmr: 1,
    img: ow_bronze,
  },
  {
    name: 'Silver',
    shortName: 'SLVR',
    class: 2,
    minMmr: 2,
    maxMmr: 2,
    img: ow_silver,
  },
  {
    name: 'Gold',
    shortName: 'GOLD',
    class: 3,
    minMmr: 3,
    maxMmr: 3,
    img: ow_gold,
  },
  {
    name: 'Platinum',
    shortName: 'PLTN',
    class: 4,
    minMmr: 4,
    maxMmr: 4,
    img: ow_platin,
  },
  {
    name: 'Diamond',
    shortName: 'DMND',
    class: 5,
    minMmr: 5,
    maxMmr: 5,
    img: ow_diamond,
  },
  {
    name: 'Master',
    shortName: 'MSTR',
    class: 6,
    minMmr: 6,
    maxMmr: 6,
    img: ow_master,
  },
  {
    name: 'Grandmaster',
    shortName: 'GM',
    class: 7,
    minMmr: 7,
    maxMmr: 7,
    img: ow_gm,
  },
  {
    name: 'Top 500',
    shortName: 'TOP500',
    class: 8,
    minMmr: 8,
    maxMmr: 8,
    img: ow_top500,
  },
];

export default ranks;
