import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTeamProfile } from 'components/Teams/TeamProfile';

// Components

import TeamInviteButton from './TeamInviteButton';
import TeamPlayerDisplay from './TeamPlayerDisplay';

function TeamPlayerHandler() {
  const { members: players, permissions } = useTeamProfile();
  const { canInvite } = permissions;

  const { t } = useTranslation(['general']);

  const { timezone, language } = useSelector((state) => state.session);

  const renderedPlayers = useMemo(() => players.map((player, index) => (
    <div
      key={index}
      className="column is-narrow"
    >
      <TeamPlayerDisplay key={player._id} index={index} />
    </div>
  )), [players, timezone, language]);

  return (
    <div>
      <div className="is-inline-flex has-content-centered">
        <p className="is-size-4 mr-4">
          { t('general:players')}
        </p>
        {
          canInvite && (
            <TeamInviteButton />
          )
        }

      </div>

      <div className="mt-5">
        <div className="columns is-multiline">
          {renderedPlayers}
        </div>
      </div>
    </div>
  );
}

export default TeamPlayerHandler;
