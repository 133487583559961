import React, { useMemo } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Libraries
import { getSelectedModeFromGame } from 'constants/games';
import PropTypes from 'prop-types';

// Components
import { Range } from 'react-range';
import MmrNumberSelector from '../MmrNumberSelector';
import MmrDropdown from './MmrDropdown';

function MmrSelector(props) {
  const { rank, setMmr, displayType } = props;
  const { game, mode, mmr } = rank;

  const { t } = useTranslation(['general']);

  const ranks = useMemo(() => {
    const modeObj = getSelectedModeFromGame(game, mode);

    return modeObj.ranks;
  }, [game, mode]);

  const selectedRank = useMemo(() => {
    if (mmr === -1) {
      return ranks[0];
    }
    // mmr ranks have minMmr and maxMmr, we need to find the one that matches our current mmr
    const mmrRank = ranks.find((_rank) => _rank.minMmr <= mmr && _rank.maxMmr >= mmr);

    if (mmrRank === undefined) {
      return ranks[0];
    }

    return mmrRank;
  }, [ranks, mmr]);

  const _setMmr = (values) => {
    const rankClass = values[0];
    const averageMmrForThatClass = Math.floor((ranks[rankClass].minMmr + ranks[rankClass].maxMmr) / 2);

    setMmr(averageMmrForThatClass);
  };

  const renderedRanks = useMemo(() => {
    if (displayType === 'dropdown') {
      return (
        <MmrDropdown
          allRanks={ranks}
          rank={rank}
          setMmr={setMmr}
        />
      );
    }

    return (
      <div className="mt-4 has-max-width-350">
        <div className="">
          <p className="has-text-grey is-size-5">
            {t('general:mmr')}
          </p>
          <div className="is-flex">
            <div className="has-width-250 has-gradient-primary px-5 br-5 mt-2 has-fullheight">
              <Range
                step={1}
                min={0}
                max={ranks.length - 1}
                values={selectedRank.class >= ranks.length ? [] : [selectedRank.class]}
                allowOverlap={false}
                onChange={(values) => _setMmr(values)}
                renderTrack={({ props: _props, children }) => (
                  <div className="flex-grow">
                    <div
                      {..._props}
                      style={{
                        // eslint-disable-next-line
                    ..._props.style,
                        height: '25px',
                        width: '100%',
                        borderRadius: '5px',
                      }}
                      className="px-3 "
                    >
                      { children }
                    </div>
                  </div>
                )}
                renderThumb={({ props: _props, isDragged }) => (
                  <div
                    {..._props}
                    style={{
                    // eslint-disable-next-line
                    ..._props.style,

                      height: '25px',
                      width: '50px',
                      borderRadius: '4px',
                      backgroundColor: '#FFF',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid #ddd',
                    }}
                  >
                    <div
                      style={{
                        height: '16px',
                        width: '5px',
                      }}
                    />
                  </div>
                )}
              />

            </div>

            {
            game === 'RL' && (
            <div className="ml-3">
              <MmrNumberSelector
                mmr={mmr}
                setMmr={setMmr}
                maxMmr={9999}
              />
            </div>
            )
          }
          </div>

        </div>
        <div className="is-flex mt-3">
          <div className="is-flex has-content-centered">
            <p className="has-text-weight-semiboldhas-text-right">
              { selectedRank.name }
            </p>
            { selectedRank.class !== -1
              && (
              <img
                className="has-width-40 has-height-40 ml-2"
                src={selectedRank.img}
                alt=""
              />
              )}
          </div>
        </div>
      </div>
    );
  }, [rank]);

  return renderedRanks;
}

MmrSelector.propTypes = {
  rank: PropTypes.shape({
    game: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    mmr: PropTypes.number.isRequired,
  }).isRequired,
  setMmr: PropTypes.func.isRequired,
  displayType: PropTypes.string,
};

MmrSelector.defaultProps = {
  displayType: 'slider',
};

export default MmrSelector;
