import React, { useMemo, useState } from 'react';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// Libraries
import stats from 'analytics/matomo';
import PropTypes from 'prop-types';
import { setHasAcceptedTerms } from 'store/session';

// Assets
import onboardingImg from 'assets/images/base/onboarding_welcome_thumbnail.jpg';

// Components
import ReactMarkdown from 'react-markdown';

function AcceptTermsHandler(props) {
  const { onDone } = props;

  const [showTerms, setShowTerms] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState('');

  const { post } = useAPI();
  const { t } = useTranslation(['terms']);

  const dispatch = useDispatch();

  const renderedTerms = useMemo(() => {
    const sections = t('terms:tos:sections', { returnObjects: true });

    const allSections = sections.map((section, index) => (
      <div
        className="has-text-justified"
        key={index}
      >
        <h2 className="is-size-4 has-text-weight-bold mt-2">
          {section.title}
        </h2>
        <ReactMarkdown>{section.content}</ReactMarkdown>
      </div>
    ));

    return (
      <div className="has-fullheight has-overflow-hidden py-3">
        <div className="has-max-height-45-vh has-overflow-y-auto">
          {allSections}
        </div>
      </div>
    );
  }, [t]);

  const acceptTerms = async () => {
    try {
      setDisabled(true);
      setError('');

      await post('/users/acceptTerms');

      dispatch(setHasAcceptedTerms(true));

      stats.push(['trackEvent', 'Onboarding', 'User accepted terms']);

      setDisabled(false);

      onDone();
    } catch (e) {
      setDisabled(false);

      setError(t('errors:an_error_occured_error'));
    }
  };

  return (

    <div className="box p-0 has-text-centered has-fullheight">

      <div className="columns has-fullheight is-multiline">

        <div
          className="column is-5-desktop has-min-width-300 is-5-tablet p-0"
        >
          <div
            className="has-fullheight has-text-left"
            style={{ backgroundImage: `url(${onboardingImg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
          >
            <div className="p-5">
              <h1
                className="is-size-1-dekstop is-size-2-tablet has-text-weight-semibold"
              >
                {t('general:onboarding')}
              </h1>
              <p
                className="is-size-4-desktop is-size-4-desktop has-text-grey break-word"
              >
                {t('general:tos')}
              </p>
            </div>
          </div>
        </div>

        <div className="column">
          <div className="field p-6 is-flex flex-direction-column has-fullheight is-relative">

            <div
              style={{
                position: 'absolute',
                width: '0px',
                height: '0px',
                right: '-11px',
                top: '-11px',

                // borderRadius: '5px',

                borderTop: '75px solid #F27118',
                borderLeft: '75px solid transparent',

              }}
            />

            <div className="is-flex flex-direction-column has-fullheight has-content-centered">

              { renderedTerms}

              <div className="field has-text-centered ">
                <button
                  type="button"
                  className="button is-primary is-size-5 has-text-weight-semibold"
                  onClick={acceptTerms}
                  disabled={disabled}
                >
                  {t('general:accept')}
                </button>
              </div>

              <div className="field">
                <p className="is-size-5 has-text-danger">
                  {error}
                </p>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>

  );
}

AcceptTermsHandler.propTypes = {
  onDone: PropTypes.func.isRequired,
};

export default AcceptTermsHandler;
