import React, { useEffect, useState, useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';
import ReceivedRequest from './ReceivedRequest';

function ReceivedRequestsList(props) {
  const { publicId } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [receivedRequests, setReceivedRequests] = useState([]);

  const { t } = useTranslation(['general', 'hotscrimps']);
  const { get } = useAPI();

  useEffect(() => {
    const loadReceivedRequests = async () => {
      try {
        setIsLoading(true);

        const _requests = await get(`/hotscrimps/requests/list/${publicId}`);

        setReceivedRequests(_requests);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    };

    loadReceivedRequests();
  }, [publicId]);

  const renderedRequests = useMemo(() => receivedRequests.map((request, index) => (
    <ReceivedRequest
      request={request}
      key={index}
    />
  )), [receivedRequests]);

  if (isLoading) {
    return (
      <div className="m-2 has-text-centered is-size-7">
        <p>
          {t('general:loading')}
        </p>
      </div>
    );
  }

  if (receivedRequests.length === 0) {
    return (
      <div className="m-2 has-text-centered is-size-7">
        <p>
          {t('general:no_requests_received_yet')}
        </p>
      </div>
    );
  }

  return (
    <div>
      <p className="mx-2 mt-3 is-size-7">
        {`${t('general:received_requests')}:`}
      </p>
      { renderedRequests }
    </div>
  );
}

ReceivedRequestsList.propTypes = {
  publicId: PropTypes.string.isRequired,
};

export default ReceivedRequestsList;
