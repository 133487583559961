import React, {
  useEffect, useState, useContext,
} from 'react';

// HOCs
import withAuthentication from 'components/hocs/withAuthentication';

// Hooks
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Constants
import { ORGA_LIST } from 'constants/routes';
import { hasPermission } from 'constants/orgas';

// Components
import Loading from 'components/utils/Loading';
import OrgaCardPicHandler from './OrgaCardPicHandler';
import OrgaNameHandler from './OrgaNameHandler';
import OrgaIdDisplay from './OrgdaIdDisplay';
import OrgaDescriptionHandler from './OrgaDescriptionHandler';
import OrgaAchievementDisplay from './OrgaAchievementDisplay';
import OrgaRegionHandler from './OrgaRegionHandler';
import OrgaBannerPicHandler from './OrgaBannerPicHandler';
import OrgaDetailsHandler from './OrgaDetailsHandler';
import OrgaContactHandler from './OrgaContactHandler';
import OrgaMemberHandler from './OrgaMemberHandler';
import OrgaLeaveHandler from './OrgaLeaveHandler';
import OrgaTeamsHandler from './OrgaTeamsHandler';
import OrgaDeletionHandler from './OrgaDeletionHandler';
import OrgaMemberRolePopup from './OrgaMemberRolePopup';
import OrgaMemberKickPopup from './OrgaMemberKickPopup';
import OrgaRemoveTeamPopup from './OrgaRemoveTeamPopup';
// import OrgaHotscrimpHandler from './OrgaHotscrimpHandler';

const OrgaPageContext = React.createContext({});

const INITIAL_PERMISSIONS = {
  canUpdateOrga: false,
  canInviteMember: false,
  canInviteTeam: false,
  canDeleteOrga: false,
  canLeaveOrga: false,
  canKickAdmin: false,
  canKickMember: false,
  canSetPermissions: false,
  canSetOrgaRole: false,
  canRemoveTeam: false,
};

const INITIAL_ORGA_DETAILS = {
  name: '',
  phone: '',
  address: {
    line1: '',
    line2: '',
    zipCode: '',
    city: '',
    country: '',
    state: '',
  },
};

function OrgaPage() {
  const [hasData, setHasData] = useState(false);
  const [error, setError] = useState('');

  const params = useParams();
  const navigate = useNavigate();

  const [permissions, setPermissions] = useState(INITIAL_PERMISSIONS);
  const [orgaDetails, setOrgaDetails] = useState(INITIAL_ORGA_DETAILS);
  const [orgaType, setOrgaType] = useState('');
  const [orgaSubType, setOrgaSubType] = useState('');
  const [description, setDescription] = useState('');
  const [contact, setContact] = useState({});
  const [name, setName] = useState('');
  const [cardPic, setCardPic] = useState('');
  const [bannerPic, setBannerPic] = useState('');
  const [achievements, setAchievements] = useState([]);
  const [members, setMembers] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [publicId, setPublicId] = useState('');
  const [region, setRegion] = useState('');
  const [teams, setTeams] = useState('');

  const [memberToKick, setMemberToKick] = useState('');
  const [memberToSetRoles, setMemberToSetRoles] = useState('');
  const [teamToRemove, setTeamToRemove] = useState('');

  const { player } = useSelector((state) => state.player);
  const { playerId } = player;

  const { t } = useTranslation(['general']);

  const { get } = useAPI();

  const reset = () => {
    setHasData(false);
    setPermissions(INITIAL_PERMISSIONS);
    setOrgaDetails(INITIAL_ORGA_DETAILS);
    setDescription('');
    setContact({});
    setName('');
    setCardPic('');
    setBannerPic('');
    setAchievements([]);
    setMembers([]);
    setSponsors([]);
    setPublicId('');
    setRegion('');
    setTeams('');
    setOrgaType('');
    setOrgaSubType('');
  };

  const evaluatePermissions = (role) => {
    if (!role) return;

    const _canUpdateOrga = hasPermission(role, 'updateOrga');
    const _canInviteMember = hasPermission(role, 'inviteMember');
    const _canInviteTeam = hasPermission(role, 'inviteTeam');
    const _canDeleteOrga = hasPermission(role, 'deleteOrga');
    const _canLeaveOrga = hasPermission(role, 'leave');
    const _canKickAdmin = hasPermission(role, 'removeAdmin');
    const _canKickMember = hasPermission(role, 'removeMember');
    const _canSetPermissions = hasPermission(role, 'changePermissions');
    const _canSetOrgaRole = hasPermission(role, 'changeOrgaRole');
    const _canRemoveTeam = hasPermission(role, 'removeTeam');

    setPermissions({
      canUpdateOrga: _canUpdateOrga,
      canInviteMember: _canInviteMember,
      canInviteTeam: _canInviteTeam,
      canDeleteOrga: _canDeleteOrga,
      canLeaveOrga: _canLeaveOrga,
      canKickAdmin: _canKickAdmin,
      canKickMember: _canKickMember,
      canSetPermissions: _canSetPermissions,
      canSetOrgaRole: _canSetOrgaRole,
      canRemoveTeam: _canRemoveTeam,
    });
  };

  // get orgaId from url
  useEffect(() => {
    const loadOrga = async (id) => {
      try {
        const _orga = await get(`/orgas/${id}`);

        // check user permission
        const user = _orga.members.find((member) => member.playerId === playerId);
        if (user) evaluatePermissions(user.role);

        // set data
        setName(_orga.name);
        setDescription(_orga.description);
        setContact(_orga.contact);
        setCardPic(_orga.cardPic);
        setAchievements(_orga.achievements);
        setMembers(_orga.members);
        setPublicId(_orga.publicId);
        setRegion(_orga.region);
        setTeams(_orga.teams);
        setSponsors(_orga.sponsors);
        setBannerPic(_orga.bannerPic);
        setOrgaDetails(_orga.orgaDetails);
        setOrgaType(_orga.orgaType);
        setOrgaSubType(_orga.orgaSubType);

        setHasData(true);
      } catch (e) {
        console.log(e);
        setError(t('errors:orga_not_found'));
      }
    };

    const { id } = params;

    if (!id || !id.startsWith('SCR-OID-')) {
      navigate(ORGA_LIST);
      return;
    }

    const extractedId = id.replace('SCR-OID-', '');

    reset();
    loadOrga(extractedId);
  }, [params.id]);

  const kickPlayer = async (id) => {
    const _members = members.filter((member) => member.playerId !== id);
    setMembers(_members);
  };

  const removeTeam = async (id) => {
    // teams is an array of objects in which the key is the game tag
    // and the value is an array of teams
    const _teamData = teams.map((teamData) => {
      const { game, teams: _teams } = teamData;
      const updatedTeams = _teams.filter((team) => team.publicId !== id);
      return {
        game,
        teams: updatedTeams,
      };
    });

    // remove empty game entries
    const _teams = _teamData.filter((teamData) => teamData.teams.length > 0);

    setTeams(_teams);
  };

  const setIngameRole = async (id, role) => {
    const _members = members.map((member) => {
      if (member.playerId === id) {
        return {
          ...member,
          ingameRole: role,
        };
      }
      return member;
    });
    setMembers(_members);
  };

  const setOrgaRole = async (id, role) => {
    const _members = members.map((member) => {
      if (member.playerId === id) {
        return {
          ...member,
          orgaRole: role,
        };
      }
      return member;
    });
    setMembers(_members);
  };

  const setPermissionRole = async (id, role) => {
    const _members = members.map((member) => {
      if (member.playerId === id) {
        return {
          ...member,
          role,
        };
      }
      return member;
    });
    setMembers(_members);
  };

  if (!hasData) return <Loading error={error} />;

  return (
    <OrgaPageContext.Provider value={{
      name,
      description,
      contact,
      cardPic,
      bannerPic,
      achievements,
      members,
      publicId,
      region,
      permissions,
      sponsors,
      teams,
      orgaDetails,

      memberToKick,
      memberToSetRoles,
      teamToRemove,

      setName,
      setDescription,
      setContact,
      setCardPic,
      setAchievements,
      setMembers,
      setRegion,
      setIngameRole,
      setOrgaRole,
      setPermissionRole,
      setBannerPic,
      setSponsors,
      setTeams,
      setOrgaDetails,

      setMemberToKick,
      setMemberToSetRoles,
      setTeamToRemove,

      kickPlayer,
      removeTeam,

    }}
    >
      <div className="is-min-fullheight">

        <OrgaMemberRolePopup />
        <OrgaMemberKickPopup />
        <OrgaRemoveTeamPopup />

        <div className="">
          <OrgaBannerPicHandler />
        </div>

        <div
          className="columns is-centered is-mobile is-multiline m-0 px-0 is-min-fullheight"
        >

          <div className="column is-11-desktop is-11-tablet is-11-mobile">

            <div
              className="pt-0 mb-6"
            >

              <div className="columns is-mobile is-multiline">

                <div className="column">
                  <div className="mb-5">
                    <div className="is-inline-flex box mb-3 py-1 px-2 has-background-primary ">
                      <p className="is-size-5">
                        {t(`general:${orgaType}`)}
                      </p>
                      { orgaSubType && (
                        <p className="is-size-5 pl-3">
                          {`/ ${t(`general:${orgaSubType}`)}`}
                        </p>
                      )}
                    </div>
                    <h1
                      className="is-outlined has-text-weight- has-text-white is-size-2"
                    >
                      {t('general:organisation')}
                    </h1>
                    <OrgaNameHandler />
                  </div>

                  <div>
                    <OrgaIdDisplay />
                  </div>

                  <div className="mt-5">
                    <OrgaContactHandler />
                  </div>

                  <div className="mt-5">
                    <OrgaAchievementDisplay />
                  </div>

                  <div className="mt-6">
                    <OrgaMemberHandler />
                  </div>

                  <div className="mt-6">
                    <OrgaTeamsHandler />
                  </div>

                </div>

                <div className="column is-4 is-relative">
                  <div className="mt-4 ">

                    <div
                      className="is-absolute"
                      style={{ top: '-150px', left: '30px' }}
                    >
                      <div className="is-inline-block has-max-width-200">
                        <OrgaCardPicHandler />
                      </div>
                    </div>

                    <div
                      className=""
                      style={{ marginTop: '150px' }}
                    >
                      <OrgaDetailsHandler />

                      <div className="mt-5">
                        <OrgaRegionHandler />
                      </div>

                      <OrgaDescriptionHandler />

                    </div>

                  </div>
                </div>

              </div>

              <div className="mt-6">
                <OrgaDeletionHandler />
                <OrgaLeaveHandler />
              </div>

            </div>

          </div>
        </div>

      </div>
    </OrgaPageContext.Provider>
  );
}

export const useOrgaPage = () => {
  const data = useContext(OrgaPageContext);

  return data;
};

export default withAuthentication(OrgaPage);
