import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasData: false,
  isLoading: true,
  hasCreatedPlayer: false,

  player: {
    name: '',
    nationality: 'de',
    cardPic: '',
    engagement: 'just4fun',
    playerId: '',
    description: '',
    hasProfilePrivate: false,

    ranks: [],
    qualifications: [],
    achievements: [],

    contact: {
      discord: '',
      steam: '',
      riot: '',
      epic: '',
      battlenet: '',
      twitter: '',
      tagsgg: '',
    },
  },

};

export const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },

    setPlayer: (state, action) => ({
      ...state,

      hasData: true,
      isLoading: false,
      hasCreatedPlayer: !!action.payload.name,

      player: {
        ...state.player,
        ...action.payload,
      },
    }),

    setPlayerContact: (state, action) => {
      state.player.contact = {
        ...state.player.contact,
        ...action.payload,
      };
    },

    setName: (state, action) => {
      state.player.name = action.payload;
    },

    setNationality: (state, action) => {
      state.player.nationality = action.payload;
    },

    setCardPic: (state, action) => {
      state.player.cardPic = action.payload;
    },

    setEngagement: (state, action) => {
      state.player.engagement = action.payload;
    },

    setDescription: (state, action) => {
      state.player.description = action.payload;
    },

    setRanks: (state, action) => {
      state.player.ranks = action.payload;
    },

    setProfilePrivate: (state, action) => {
      state.player.hasProfilePrivate = action.payload;
    },

    setContact: (state, action) => {
      const contact = { ...state.player.contact };
      contact[action.payload.type] = action.payload.name;

      state.player.contact = contact;
    },
    addContact: (state, action) => ({
      ...state,
      contact: [...state.contact, action.payload],
    }),
    removeContact: (state, action) => ({
      ...state,
      contact: state.contact.map((contact) => contact.id !== action.payload),
    }),

    clearState: () => initialState,
  },
});

export const {
  setPlayer,
  clearState,
  setLoading,

  addContact,
  removeContact,
  setContact,
  setPlayerContact,

  setName,
  setNationality,
  setCardPic,
  setEngagement,
  setDescription,
  setRanks,
  setProfilePrivate,

} = playerSlice.actions;

export default playerSlice.reducer;
