import React, { useMemo } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Components
import AsyncNumberEditor from 'components/utils/inputs/AsyncNumberEditor';

const TournamentMaxParticipantsEditor = () => {
  const {
    tournamentSettings, setMaxParticipants, publicId, currentPhase,
  } = useTournament();
  const { maxParticipants } = tournamentSettings;

  const { t } = useTranslation(['general', 'validation']);

  const { post } = useAPI();

  const renderedEditor = useMemo(() => {
    if (currentPhase !== 'draft') {
      return (
        <div className="">
          <p className="is-size-5 has-text-grey mb-2">
            {t('general:max_participants')}
          </p>
          <div className="has-fullwidth">
            <p>
              {maxParticipants === -1 ? t('general:unlimited') : maxParticipants }
            </p>
          </div>
        </div>
      );
    }

    const saveMaxParticipants = async (newMaxParticipants) => {
      const data = { maxParticipants: newMaxParticipants };

      await post(`/tournaments/settings/maxParticipants/${publicId}`, data);

      setMaxParticipants(newMaxParticipants);

      stats.push(['trackEvent', 'Tournament Editor', 'New max participants set']);
    };

    return (
      <AsyncNumberEditor
        label="max_amount_participants"
        number={maxParticipants}
        onSave={saveMaxParticipants}
      />
    );
  }, [currentPhase, maxParticipants, publicId, t]);

  return renderedEditor;
};

export default TournamentMaxParticipantsEditor;
