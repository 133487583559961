import { AVAILABLE_REGIONS } from 'constants/user';

const regionToLabel = (region, allRegions = AVAILABLE_REGIONS) => {
  const regionFound = allRegions.find((item) => item.value === region);

  if (!regionFound) return '';

  return regionFound.label;
};

export default regionToLabel;
