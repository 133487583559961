import React, { useState, useEffect, useMemo } from 'react';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';

// Components
import TournamentCardDisplay from '../TournamentCardDisplay';

function EnteredTournamentList() {
  const [tournaments, setTournaments] = useState([]);

  const { get } = useAPI();
  const { t } = useTranslation(['general']);

  useEffect(() => {
    const loadTournaments = async () => {
      try {
        const _tournaments = await get('/player/tournaments/entries');

        setTournaments(_tournaments);
      } catch (e) {
        console.log(e);
      }
    };

    loadTournaments();
  }, []);

  const renderedEntries = useMemo(() => tournaments.map((tournament) => (
    <TournamentCardDisplay
      tournament={tournament}
      key={tournament.publicId}
    />
  )), [tournaments]);

  return (
    <div>
      <h2 className="is-size-3 has-text-standard has-text-weight-light">
        <i className="fas fa-play mr-4 has-text-primary" />
        {t('general:entered_tournaments')}
      </h2>
      <div className="columns is-centered">
        <div className="column p-0 is-narrow has-width-85" />
        <div className="column is-2 has-text-centered">
          <p>
            {t('general:title')}
          </p>
        </div>
        <div className="column is-1 has-text-centered">
          <p>
            {t('general:start')}
          </p>
        </div>
        <div className="column is-2 has-text-centered">
          <p>
            {t('general:type')}
          </p>
        </div>
        <div className="column is-2 has-text-centered">
          <p>
            {t('general:game')}
          </p>
        </div>
        <div className="column is-2 has-text-centered">
          <p>
            {t('general:mode')}
          </p>
        </div>
        <div className="column has-text-centered">
          <p>
            {t('general:status')}
          </p>
        </div>
      </div>
      { renderedEntries }
    </div>
  );
}

export default EnteredTournamentList;
