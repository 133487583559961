import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

function TriangleDisplay({ color }) {
  const hexColor = useMemo(() => {
    if (color === 'primary') return '#54B33D';

    if (color === 'playerboard') return '#3DB2FF';

    if (color === 'hotscrimps') return '#ff672b';

    return 'black';
  }, [color]);

  return (
    <div
      style={{
        position: 'absolute',
        width: '0px',
        height: '0px',
        right: '-11px',
        top: '-11px',

        // borderRadius: '5px',

        borderTop: `75px solid ${hexColor}`,
        borderLeft: '75px solid transparent',

      }}
    />
  );
}

TriangleDisplay.propTypes = {
  color: PropTypes.string,
};

TriangleDisplay.defaultProps = {
  color: 'primary',
};

export default TriangleDisplay;
