import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Assets
import switch_icon from 'assets/images/icons/switch_icon.png';

function PlatformDisplay(props) {
  const { platform } = props;

  const renderedPlatform = useMemo(() => {
    switch (platform) {
      case 'pc': return <i className="fas fa-desktop is-size-4" />;
      case 'ps': return <i className="fab fa-playstation is-size-4" />;
      case 'xbox': return <i className="fab fa-xbox is-size-4" />;
      case 'switch': return <img src={switch_icon} className="has-width-25 has-height-25" alt="" />;

      default: return null;
    }
  }, [platform]);

  return renderedPlatform;
}

PlatformDisplay.propTypes = {
  platform: PropTypes.string.isRequired,
};

export default PlatformDisplay;
