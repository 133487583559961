import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import ContactField from 'components/utils/ContactField';

function TwitterContact(props) {
  const { value, setValue } = props;
  const [error, setError] = useState('');

  const { t } = useTranslation(['contacts', 'validation']);

  const setAndValidate = (name) => {
    setError('');

    // only alphanumeric and _
    const regex = /^[a-z0-9_]+$/i;

    if (name && !regex.test(name)) {
      setError(t('validation:enter_valid_name_error'));
      return;
    }

    if (name.length > 15) {
      setError(t('validation:choose_shorter_name_error'));
      return;
    }

    setValue('twitter', name);
  };

  return (
    <ContactField
      icon="fab fa-twitter has-text-info"
      value={value}
      onChange={setAndValidate}
      error={error}
      label="Twitter"
      placeholder="elonmusk"
    />
  );
}

TwitterContact.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
};

TwitterContact.defaultProps = {
  value: '',
};

export default TwitterContact;
