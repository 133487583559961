import React from 'react';

// Libaries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import CardPicDisplay from 'components/utils/CardPicDisplay';

function InvitationplayerDisplay(props) {
  const { player } = props;

  const { t } = useTranslation(['general']);

  const { name, cardPic } = player;

  return (
    <div
      className="columns is-multiline has-max-width-350"
    >
      <div className="column has-max-width-120">
        <CardPicDisplay img={cardPic} />
      </div>
      <div
        className="column"
      >
        <div className="">
          <p
            className="is-size-5 mr-2"
          >
            {`${name}`}
          </p>
        </div>

      </div>

    </div>
  );
}

InvitationplayerDisplay.propTypes = {
  player: PropTypes.shape({
    name: PropTypes.string.isRequired,
    cardPic: PropTypes.string.isRequired,
  }).isRequired,
  team: PropTypes.shape({
    name: PropTypes.string.isRequired,
    game: PropTypes.shape({
      tag: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default InvitationplayerDisplay;
