import React, { useState, useMemo } from 'react';

// Hooks
import { useSelector } from 'react-redux';
import { usePlayerList } from 'components/Players/PlayerListProvider';
import { useAuth0 } from '@auth0/auth0-react';

// Constants
import { PLAYER_PROFILE } from 'constants/routes';

// Components
import Popup from 'reactjs-popup';
import { Link } from 'react-router-dom';
import PlayerCard from './PlayerCard';
import PlayerDetails from './PlayerDetails';

function PlayerDisplay() {
  const { players } = usePlayerList();

  const { isAuthenticated } = useAuth0();

  const { timezone, language } = useSelector((state) => state.session);

  const [isOpen, setOpen] = useState(false);

  const renderedEntries = useMemo(() => players.map((_, index) => (
    <Popup
      trigger={(
        <div className="column is-narrow my-3">
          <PlayerCard index={index} />
        </div>
      )}
      position="top center"
      keepTooltipInside="#root"
      closeOnDocumentClick
      closeOnEscape
      modal
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      key={index}
    >
      {
        () => (
          <PlayerDetails index={index} />
        )
      }

    </Popup>

  )), [players, language, isOpen]);

  return (
    <div>
      <div className="mb-6 mt-2 columns has-text-centered-mobile is-centered-mobile is-multiline">
        {
          isAuthenticated && (
            <div className="column is-narrow my-3">
              <Link
                to={PLAYER_PROFILE}
              >
                <PlayerCard index={-1} />
              </Link>
            </div>
          )
        }
        { renderedEntries }
      </div>

    </div>
  );
}

export default PlayerDisplay;
