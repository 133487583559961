import React from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';

function TournamentGlobalChatButton() {
  const { t } = useTranslation(['general']);
  const { currentPhase, participant, setSidebarContent } = useTournament();

  if (currentPhase !== 'in_progress' && currentPhase !== 'check_in' && currentPhase !== 'registration_open') return null;
  if (!participant.publicId) return null;

  const showChat = () => {
    setSidebarContent({
      type: 'global_chat',
      data: {},
    });
  };

  return (
    <div className="has-text-right">
      <button
        className="button is-primary"
        type="button"
        onClick={showChat}
      >
        <i className="fas fa-comments fa-1x mr-2" />
        {t('general:global_tournament_chat')}
      </button>
    </div>
  );
}

export default TournamentGlobalChatButton;
