import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import TournamentCardPicDisplay from 'components/tournaments/utils/TournamentCardPicDisplay';
import { Link } from 'react-router-dom';

// Constants
import { TOURNAMENT_PAGE } from 'constants/routes';
import { getSelectedGame } from 'constants/games';
import { useSelector } from 'react-redux';

function TournamentCardDisplay(props) {
  const {
    tournament,
  } = props;

  const {
    publicId, title, tournamentType, cardPic, currentPhase, game, startDate,
  } = tournament;

  const { t } = useTranslation(['tournaments']);
  const { language, timezone } = useSelector((state) => state.session);

  let color = 'has-background-warning';

  if (currentPhase === 'registration_open') {
    color = 'has-background-info';
  } else if (currentPhase === 'finished') {
    color = 'has-background-danger';
  } else if (currentPhase === 'in_progress') {
    color = 'has-background-success';
  } else if (currentPhase === 'check_in' || currentPhase === 'seeding') {
    color = 'has-background-primary';
  } else if (currentPhase === 'upcoming') {
    color = 'has-background-grey-light';
  }

  const renderedStartDate = useMemo(() => {
    if (!startDate) return null;

    const _date = DateTime.fromISO(startDate).setZone(timezone).setLocale(language);

    return _date.toLocaleString(DateTime.DATETIME_SHORT);
  }, [startDate, language, timezone]);

  const renderedGameIcon = useMemo(() => {
    const gameData = getSelectedGame(game.tag);

    return (
      <div className="is-flex has-content-centered has-fullheight has-text-centered">
        <img
          src={gameData.icon}
          alt={gameData.name}
          className="has-width-48 has-height-48"
        />
      </div>
    );
  }, [game]);

  return (
    <Link
      to={`${TOURNAMENT_PAGE}/${publicId}`}
      className="button p-0 is-block has-fullwidth has-min-width-300 my-3"
    >
      <div className="columns">
        <div className="column p-0 is-narrow has-width-85">
          <div className="has-fullheight is-flex">
            <TournamentCardPicDisplay cardPic={cardPic} />
          </div>
        </div>

        <div className="column is-2 has-background-dark">
          <div className="is-flex has-content-centered has-fullheight has-text-centered">
            <p
              className="is-size-6 has-text-weight-semibold break-word has-fullwidth"
            >
              {title}
            </p>
          </div>
        </div>

        <div className="column is-1 has-background-dark">
          <div className="is-flex has-content-centered has-fullheight has-text-centered">
            <p className="has-text-grey-lighter break-word is-size-7">
              {renderedStartDate}
            </p>
          </div>
        </div>

        <div className="column is-2 has-background-dark">
          <div className="is-flex has-content-centered has-fullheight has-text-centered">
            <p className="has-text-grey-lighter">
              {t(`general:${tournamentType}`)}
            </p>
          </div>
        </div>

        <div className="column is-2 has-background-dark">
          <div className="is-flex has-content-centered has-fullheight has-text-centered">
            {renderedGameIcon}
          </div>
        </div>

        <div className="column is-2 has-background-dark">
          <div className="is-flex has-content-centered has-fullheight has-text-centered">
            <p className="has-text-grey-lighter">
              { game.mode }
            </p>
          </div>
        </div>

        <div className="column p-0">
          <div className={`is-flex has-fullheight has-content-centered br-5-right ${color}`}>
            <p
              className="is-size-6 has-text-white px-4 py-0 "
            >
              {t(`general:${currentPhase}`)}
            </p>
          </div>
        </div>

      </div>
    </Link>
  );
}

TournamentCardDisplay.propTypes = {
  tournament: PropTypes.shape({
    publicId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    tournamentType: PropTypes.string.isRequired,
    cardPic: PropTypes.string,
    currentPhase: PropTypes.string.isRequired,
  }).isRequired,
};

export default TournamentCardDisplay;
