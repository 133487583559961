import React from 'react';

// Hooks
import { useHotscrimp } from 'components/HotScrimps/HotscrimpsRequestPage';
import { useTranslation } from 'react-i18next';

// Components
import HotscrimpRequestStateChanger from './HotscrimpRequestStateChanger';

function HotscrimpRequestStatusHandler() {
  const { permissions, status } = useHotscrimp();
  const { canAcceptHotscrimpRequest, canDeclineHotscrimpRequest } = permissions;

  const { t } = useTranslation(['hotscrimp']);

  const showStateChanger = canAcceptHotscrimpRequest && canDeclineHotscrimpRequest && status === 'pending';

  return (
    <div className="my-3">
      <p className="is-size-5">
        {`${t('general:status')}: ${t(`general:${status}`)}`}
      </p>
      {
        showStateChanger && (
        <HotscrimpRequestStateChanger />
        )
      }
    </div>
  );
}

export default HotscrimpRequestStatusHandler;
