import React, { useEffect, useMemo, useState } from 'react';

// Libaries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Components
import Popup from 'reactjs-popup';
import ReactQuill from 'react-quill';

// Constants
import { DateTime } from 'luxon';

function TournamentRuleDisplayPopup(props) {
  const {
    rule,
  } = props;

  const { language, timezone } = useSelector((state) => state.session);
  const { t } = useTranslation(['general', 'errors']);

  const renderedTime = useMemo(() => {
    const time = DateTime.fromISO(rule.updatedAt).setZone(timezone).setLocale(language).toLocaleString(DateTime.DATETIME_SHORT);

    return time;
  }, [rule.updatedAt, language, timezone]);

  return (
    <Popup
      trigger={(
        <button
          type="button"
          className="button is-block has-background-dark has-text-grey has-border-grey-thin has-fullheight px-2 py-1 br-10 has-width-200 has-text-left"
        >
          <p className="is-size-7 has-text-weight-bold break-word">
            {rule.name}
          </p>
          <p className="is-size-7 has-margin-top-auto">
            {renderedTime}
          </p>
        </button>
    )}
      position="top center"
      keepTooltipInside="#root"
      repositionOnResize
      closeOnDocumentClick
      closeOnEscape
      modal
    >
      {
        rule && (
          (close) => (
            <div
              style={{ height: '80vh' }}
              className="is-fullwidth has-min-width-300 has-max-width-1000 has-overflow-hidden box boxshadow has-background-black-gradient p-0 is-flex flex-direction-column"
            >
              <div
                className="has-background-primary has-text-centered p-4 pb-6 is-relative br-5"
              >
                <div className="has-text-right">
                  <button
                    type="button"
                    className=" cleanButton has-text-white"
                    onClick={close}
                  >
                    <i className="fas fa-times" />
                  </button>
                </div>
                <p
                  className="has-text-weight-semibold is-size-3"
                >
                  {t('general:tournament_rule')}
                </p>

                <div
                  className="br-100 has-background-primary is-absolute is-flex has-content-centered"
                  style={{
                    left: 'calc(50% - 45px)', width: '90px', height: '85px', zIndex: '999',
                  }}
                >
                  <i className="fas fa-image is-size-2" />
                </div>

              </div>

              <div
                className="control px-6 py-6 flex-grow has-overflow-y-auto"
              >

                <div className="">
                  <p className="has-text-weight-bold is-size-5">
                    { rule.name }
                  </p>
                </div>

                <div className="mt-2">
                  <ReactQuill
                    theme="snow"
                    value={rule.content}
                    modules={{
                      toolbar: false,
                    }}
                    readOnly
                  />
                </div>
              </div>

            </div>
          )
        )
    }
    </Popup>
  );
}

TournamentRuleDisplayPopup.propTypes = {
  rule: PropTypes.shape({
    name: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }),
};

TournamentRuleDisplayPopup.defaultProps = {
  rule: null,
};

export default TournamentRuleDisplayPopup;
