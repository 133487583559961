import React, { useMemo } from 'react';

// Libraries
import stats from 'analytics/matomo';
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import Dropdown from 'components/utils/Dropdown';
import { useSelector } from 'react-redux';

function EngagementFilter(props) {
  const { language } = useSelector((state) => state.session);

  const { t } = useTranslation(['general']);

  const { type, setType, disabled } = props;

  const availableTypes = [
    { label: t('general:all'), value: '' },
    { label: 'just4fun', value: 'just4fun' },
    { label: 'competitive', value: 'competitive' },
  ];

  const _setType = (_type) => {
    stats.push(['trackEvent', 'Player bowl search type', 'Search type filter set']);
    setType(_type.value);
  };

  const selectedValue = useMemo(() => availableTypes.find((_type) => _type.value === type), [type, language]);

  return (
    <div className="field">

      <div className="">

        <label className="label has-text-weight-semibold is-size-7">
          {t('general:type')}
        </label>
        <div className="has-fullwidth">
          <Dropdown
            items={availableTypes}
            onSelect={_setType}
            value={selectedValue}
            disabled={disabled}
          />

        </div>
      </div>
    </div>
  );
}

EngagementFilter.propTypes = {
  type: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

EngagementFilter.defaultProps = {
  disabled: false,
};

export default EngagementFilter;
