import React from 'react';
import PropTypes from 'prop-types';
// import { de } from 'date-fns/locale/de';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// registerLocale('de', de);

function TimeSelector(props) {
  const { time, setTime } = props;

  const setDay = (index) => {
    setTime({
      ...time,
      day: index,
    });
  };

  const setStartTime = (date) => {
    setTime({
      ...time,
      start: date,
    });
  };

  const setEndTime = (date) => {
    setTime({
      ...time,
      end: date,
    });
  };

  const filterStartTime = (startTime) => startTime.getTime() < time.end.getTime();

  const filterEndTime = (endTime) => endTime.getTime() > time.start.getTime();

  const showButtons = () => {
    const days = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];

    return days.map((day, index) => (
      <div className="column is-narrow" key={day}>
        <button
          type="button"
          className={`button has-fullwidth has-text-weight-semibold${time.day === index ? ' has-background-primary' : ''}`}
          onClick={() => setDay(index)}
        >
          { day }
        </button>
      </div>
    ));
  };

  return (
    <>
      <div className="field">
        <label className="label">
          Wann habt ihr Zeit zu spielen?
        </label>
        <div className="control">
          <div className="columns is-multiline">
            { showButtons() }
          </div>
          <div />
        </div>
      </div>
      <div className="field">
        <label className="label">
          In welcher Zeitspanne könnt ihr spielen?
        </label>
        <div className="control">
          <div className="columns">
            <div className="column is-narrow">
              <p>Von</p>
              <DatePicker
                selected={time.start}
                onChange={(date) => setStartTime(date)}
                showTimeSelect
                showTimeSelectOnly
                disabledKeyboardNavigation
                timeIntervals={15}
                timeCaption="Zeit"
                dateFormat="HH:mm"
                locale="de"
                timeFormat="HH:mm"
                filterTime={filterStartTime}
                className="input"
              />
            </div>
            <div className="column is-narrow">
              <p>Bis</p>
              <DatePicker
                selected={time.end}
                onChange={(date) => setEndTime(date)}
                showTimeSelect
                showTimeSelectOnly
                disabledKeyboardNavigation
                timeIntervals={15}
                timeCaption="Zeit"
                dateFormat="HH:mm"
                locale="de"
                timeFormat="HH:mm"
                filterTime={filterEndTime}
                className="input"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

TimeSelector.propTypes = {
  time: PropTypes.shape({
    day: PropTypes.number.isRequired,
    start: PropTypes.instanceOf(Date).isRequired,
    end: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
  setTime: PropTypes.func.isRequired,
};

export default TimeSelector;
