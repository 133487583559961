import React from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Constants
import { MAX_TOURNAMENT_STREAMING_VOD_LENGTH } from 'constants/tournaments';
import AsyncInputEditor from 'components/utils/inputs/AsyncInputEditor';

function TournamentStreamingVodEditor() {
  const { streaming, setStreaming, publicId } = useTournament();
  const { vod } = streaming;

  const { t } = useTranslation(['general', 'validation']);

  const { post } = useAPI();

  const saveTwitchVod = async (newTwitchVod) => {
    // the url looks like https://www.twitch.tv/videos/2114118194?collection=Dq3F9QhBxBc-pA, we need the number after videos/ to get the vod name
    let vodName = newTwitchVod.split('/').pop();
    vodName = vodName.split('?')[0];

    // remove whitespace before and after
    vodName = vodName.trim();

    const data = { vod: vodName };

    await post(`/tournaments/streaming/vod/${publicId}`, data);
    setStreaming({ vod: vodName });

    stats.push(['trackEvent', 'Tournament Editor', 'Twitch Vod set']);
  };

  return (
    <AsyncInputEditor
      label="twitch_vod_name"
      text={vod}
      onSave={saveTwitchVod}
      maxLength={MAX_TOURNAMENT_STREAMING_VOD_LENGTH}
    />
  );
}

export default TournamentStreamingVodEditor;
