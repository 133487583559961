import React, { useMemo } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useOrgaPage } from 'components/orgas/OrgaPage';

// Components
import OrgaTeamInviteButton from './OrgaTeamInviteButton';
import OrgaTeamHandler from './OrgaTeamHandler';
import { getSelectedGame } from 'constants/games';
import OrgaTeamListPopup from './OrgaTeamListPopup';

function OrgaTeamsHandler() {
  const { teams: teamData, permissions } = useOrgaPage();
  const { canInviteTeam } = permissions;

  const { t } = useTranslation(['general']);

  const { timezone, language } = useSelector((state) => state.session);

  // teams are sorted by game
  const renderedTeams = useMemo(() => teamData.map((data, gameIndex) => {
    const { game, teams } = data;

    const gameData = getSelectedGame(game.tag);

    const _renderedTeams = teams.map((team, index) => (
      <div
        key={index}
        className="column is-6"
      >
        <OrgaTeamHandler key={team._id} team={team} />
      </div>
    ));

    return (
      <div key={gameIndex}>
        <div>
          <p className="">
            { gameData.name }
          </p>
        </div>

        <div className="mt-2 columns is-multiline">
          { _renderedTeams}
        </div>
      </div>
    );
  }), [teamData, timezone, language]);

  return (
    <div>
      <div className="is-inline-flex has-content-centered">
        <p className="is-size-4 mr-4">
          { t('general:teams')}
        </p>

        {
          canInviteTeam && (
            <OrgaTeamInviteButton />
          )
        }

        {
          teamData.length !== 0 && (
            <div className="mx-2">
              <OrgaTeamListPopup />
            </div>
          )
        }

      </div>

      <div className="mt-2">
        <div className="">
          {renderedTeams}
        </div>
      </div>
    </div>
  );
}

export default OrgaTeamsHandler;
