import React, { useState, useEffect, useContext } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

const INITIAL_FILTER = {
  name: '',
  game: {
    tag: '',
  },
  platform: '',
  region: '',
};

export const TeamListContext = React.createContext({});

// TeamListProvider is only for viewing different teams than the ones a player is in
// There is a seperate redux store for the teams a player is in
function AllTeamsProvider(props) {
  const { children } = props;
  const { t } = useTranslation(['errors']);

  const { isAuthenticated } = useAuth0();

  const [showLoadMoreBtn, setShowLoadMoreBtn] = useState(false);
  const [status, setStatus] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [teams, _setTeams] = useState([]);
  const [filter, setNewFilter] = useState(INITIAL_FILTER);

  const { post } = useAPI();

  const setTeams = (entries) => {
    _setTeams(entries);
  };

  const addTeams = (entries) => {
    _setTeams([...teams, ...entries]);
  };

  const loadTeams = async () => {
    try {
      setIsLoading(true);

      const data = {
        filter,
        createdAt: '',
      };

      let loadedTeams = [];

      if (!isAuthenticated) {
        loadedTeams = await post('/teams/list/public', data);
      } else {
        loadedTeams = await post('/teams/list', data);
      }

      if (loadedTeams.length === 20) {
        setShowLoadMoreBtn(true);
      } else {
        setShowLoadMoreBtn(false);
      }

      setTeams(loadedTeams);

      setIsLoading(false);
    } catch (e) {
      setStatus(t('errors:data_load_error'));
      setIsLoading(false);
    }
  };

  const loadMoreTeams = async () => {
    try {
      setIsLoading(true);

      const data = {
        filter,
        createdAt: '',
      };

      if (teams.length !== 0) {
        data.createdAt = teams[teams.length - 1].createdAt;
      }

      let loadedTeams = [];

      if (!isAuthenticated) {
        loadedTeams = await post('/teams/list/public', data);
      } else {
        loadedTeams = await post('/teams/list', data);
      }

      if (loadedTeams.length === 20) {
        setShowLoadMoreBtn(true);
      } else {
        setShowLoadMoreBtn(false);
      }

      if (teams.length === 0) {
        setTeams(loadedTeams);
      } else {
        addTeams(loadedTeams);
      }

      setIsLoading(false);
    } catch (e) {
      setStatus(t('errors:data_load_error'));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadTeams();
  }, [filter]);

  const setLoading = (_isLoading) => {
    setIsLoading(_isLoading);
  };

  const setNameFilter = (name) => {
    setNewFilter({
      ...filter,
      name,
    });
  };

  const setRegionFilter = (region) => {
    setNewFilter({
      ...filter,
      region,
    });
  };

  const setGameFilter = (game) => {
    setNewFilter({
      ...filter,
      game,
    });
  };

  const setNationalityFilter = (nationality) => {
    setNewFilter({
      ...filter,
      nationality,
    });
  };

  const setEngagementFilter = (engagement) => {
    setNewFilter({
      ...filter,
      engagement,
    });
  };

  const setPlatformFilter = (platform) => {
    setNewFilter({
      ...filter,
      platform,
    });
  };

  const resetFilter = () => {
    setNewFilter(INITIAL_FILTER);
  };

  const clearState = () => {
    setNewFilter(INITIAL_FILTER);
    _setTeams([]);
  };

  return (
    <TeamListContext.Provider
      value={{
        isLoading,
        teams,
        filter,
        showLoadMoreBtn,
        status,

        setLoading,
        setTeams,
        addTeams,
        setNameFilter,
        setNationalityFilter,
        setEngagementFilter,
        setRegionFilter,
        setPlatformFilter,
        setGameFilter,
        resetFilter,
        loadMoreTeams,

        clearState,
      }}
    >
      {children}
    </TeamListContext.Provider>
  );
}

export const useAllTeamsList = () => {
  const data = useContext(TeamListContext);

  return data;
};

AllTeamsProvider.propTypes = {
  children: PropTypes.node,
};

AllTeamsProvider.defaultProps = {
  children: {},
};

export default AllTeamsProvider;
