import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import { Link } from 'react-router-dom';
import CardPicDisplay from 'components/utils/CardPicDisplay';

// Constants
import { PLAYER_PROFILE_DISPLAY } from 'constants/routes';

function FriendDisplay(props) {
  const { friend, selectFriend, isSelected } = props;

  return (
    <button
      type="button"
      className="cleanButton has-fullwidth has-text-white has-text-left is-size-6 p-0 has-border-darkest-light mt-0 is-flex"
      onClick={selectFriend}
    >
      <div className="has-fullwidth">
        <div
          key={friend.playerId}
          className="columns is-flex"
        >

          <div className="column is-narrow p-0">
            <Link
              to={`${PLAYER_PROFILE_DISPLAY}${friend.playerId}`}
              className="has-width-65 is-flex"
              onClick={(e) => e.stopPropagation()}
            >
              <CardPicDisplay img={friend.cardPic} />
            </Link>
          </div>

          <div className="column px-5">
            <p
              className="is-size-5"
            >
              {friend.name}
            </p>
          </div>

          <div className="column is-2 is-flex has-content-centered pl-0">
            <div
              className="cleanButton has-cursor-pointer has-text-grey is-size-3"
            >
              <i className={`fas fa-angle-${isSelected ? 'left' : 'down'}`} />
            </div>
          </div>
        </div>
      </div>
    </button>
  );
}

FriendDisplay.propTypes = {
  friend: PropTypes.shape({
    playerId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    cardPic: PropTypes.string.isRequired,
  }).isRequired,
  selectFriend: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,

};

export default FriendDisplay;
