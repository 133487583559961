import React, { useMemo } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useOrgaPage } from 'components/orgas/OrgaPage';

// Components
import OrgaInviteButton from './OrgaInviteButton';
import OrgaMemberDisplayPopup from './OrgaMemberDisplayPopup';
import OrgaMemberListPopup from './OrgaMemberListPopup';

function OrgaMemberHandler() {
  const { members, permissions } = useOrgaPage();
  const { canInviteMember } = permissions;

  const { t } = useTranslation(['general']);

  const { timezone, language } = useSelector((state) => state.session);

  const renderedMembers = useMemo(() => members.map((member, index) => (
    <div
      key={index}
      className="column is-narrow"
    >
      <OrgaMemberDisplayPopup key={member._id} member={member} />
    </div>
  )), [members, timezone, language]);

  return (
    <div>
      <div className="is-inline-flex has-content-centered">
        <p className="is-size-4 mr-4">
          { t('general:members')}
        </p>

        {
          canInviteMember && (
            <OrgaInviteButton />
          )
        }

        <div className="mx-2">
          <OrgaMemberListPopup />
        </div>

      </div>

      <div className="mt-5">
        <div className="columns is-multiline">
          {renderedMembers}
        </div>
      </div>
    </div>
  );
}

export default OrgaMemberHandler;
