import React, { useEffect, useRef } from 'react';

// Hooks
import { Link, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setCookieSettings } from 'store/session';

// Libraries
import stats from 'analytics/matomo';

// constants
import { PRIVACY } from 'constants/routes.js';

import styles from './styles.module.scss';
import useAPI from 'components/hooks/useAPI';

function CookieHandler() {
  const { t } = useTranslation(['general']);
  const { isAuthenticated } = useAuth0();
  const hasMounted = useRef(false);

  const location = useLocation();

  const { cookies } = useSelector((state) => state.session);

  const { showBanner } = cookies;
  const dispatch = useDispatch();

  const { post } = useAPI();

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }

    if (!isAuthenticated) return;

    const data = { ...cookies };
    delete data.showBanner;

    const setCookies = async () => {
      try {
        await post('/users/cookies', { cookies: data });
      } catch (e) {
        console.log(e);
      }
    };

    setCookies();
  }, [isAuthenticated, cookies.analyticsCookies, cookies.twitchCookies]);

  if (isAuthenticated && location.pathname !== PRIVACY) {
    return null;
  }

  const allowAllCookies = () => {
    stats.push(['rememberConsentGiven']);

    // only send event if the value actually changes
    if (cookies.analyticsCookies === false) {
      stats.push(['trackEvent', 'Cookies', 'Allow all']);
    }

    dispatch(setCookieSettings({ showBanner: false, analyticsCookies: true }));

    localStorage.setItem('analyticsCookies', 'true');
  };

  const hideCookieBox = () => {
    stats.push(['trackEvent', 'Cookies', 'Hide Settings']);
    // Hide Cookie Box
    dispatch(setCookieSettings({ showBanner: false }));

    localStorage.setItem('analyticsCookies', 'false');
  };

  return (
    showBanner
      && (
        <div>
          <div id={styles.background} />
          <div id={styles.wrapper} className="columns is-marginless is-vcentered is-centered is-mobile">
            <div className="column is-6-desktop is-6-tablet is-10-mobile py-6 px-5 box boxshadow">
              <div>
                <p className="is-size-6 is-inline">
                  { t('general:cookie_notice')}
                </p>
                <Link
                  to={PRIVACY}
                  className="is-inline has-text-primary ml-2"
                  onClick={hideCookieBox}
                >
                  {t('general:to_privacy_policy')}
                </Link>
              </div>
              <div className="columns is-marginless has-fullwidth is-mobile is-centered mt-6">
                <div className="column is-paddingless has-text-right has-text-centered-desktop mt-6">
                  <button
                    className="button is-primary has-fullwidth br10 has-text-weight-semibold has-text-white has-fullheight"
                    type="button"
                    onClick={() => allowAllCookies()}
                  >
                    {t('general:allow_cookies')}
                  </button>
                </div>
              </div>
              <div className="has-text-centered mt-3">
                <button
                  className="button is-size-7"
                  type="button"
                  onClick={hideCookieBox}
                >
                  {t('general:reject_cookies')}
                </button>
              </div>
            </div>
          </div>
        </div>
      )
  );
}

export default CookieHandler;
