import React, { useMemo } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';

// Components
import TournamentParticipantsList from 'components/tournaments/TournamentPage/common/TournamentParticipantsList';
import TournamentPhaseChanger from './TournamentPhaseChanger';
import TournamentSeeding from 'components/tournaments/TournamentPage/TournamentPhaseHandler/TournamentSeeding';

function AdminTournamentHandler() {
  const { currentPhase } = useTournament();

  const renderedContentForPhase = useMemo(() => {
    switch (currentPhase) {
      case 'registration_open':
        return <TournamentParticipantsList />;
      case 'check_in':
        return <TournamentParticipantsList />;
      case 'seeding':
        return <TournamentSeeding />;
      case 'in_progress':
        return <TournamentParticipantsList />;
      default:
        return null;
    }
  }, [currentPhase]);

  return (
    <div className="">
      <div className="mb-5">
        <TournamentPhaseChanger />
      </div>

      { renderedContentForPhase }
    </div>
  );
}

export default AdminTournamentHandler;
