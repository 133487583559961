import React, { useState } from 'react';

// Libraries
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Constants
import { TEAM_PROFILE } from 'constants/routes';

// Components
import CardPicDisplay from 'components/utils/CardPicDisplay';
import { Link } from 'react-router-dom';
import OrgaRemoveTeamHandler from './OrgaRemoveTeamHandler';

function OrgaTeamHandler(props) {
  const { team } = props;
  // const { timezone, language } = useSelector((state) => state.session);

  // const { t } = useTranslation(['general']);

  /* const renderTime = (joinedAt) => {
    const date = DateTime.fromISO(joinedAt).setZone(timezone).setLocale(language);
    return date.toLocaleString(DateTime.DATE_SIMPLE);
  }; */

  const {
    name, cardPic, joinedAt, publicId,
  } = team;

  // const joinedAtDate = renderTime(joinedAt);

  return (
    <div className="columns has-max-width-350 box py-0 pl-0 pr-3">
      <div className="column p-0">
        <Link
          to={TEAM_PROFILE + publicId}
        >
          <div className="p-0 has-cursor-pointer">
            <div
              className="columns is-multiline"
            >
              <div className="column is-narrow p-0">
                <div style={{
                  width: 80,
                  height: 80,
                }}
                >
                  <CardPicDisplay
                    quadratic
                    img={cardPic}
                    url="/images/teams/card_pictures/"
                  />
                </div>

              </div>
              <div
                className="column has-background-dark p-2"
                style={{
                  borderTopRightRadius: '5px', borderBottomRightRadius: '5px', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px',
                }}
              >
                <div className="is-flex flex-direction-column has-fullheight">
                  <div>
                    <div className="is-flex">
                      <p
                        className="is-size-5 mr-2 has-text-primary"
                      >
                        {name}
                      </p>
                    </div>

                  </div>

                </div>

              </div>

            </div>
          </div>
        </Link>
      </div>

      <div className="column is-narrow p-0 pt-3">
        <OrgaRemoveTeamHandler team={team} />
      </div>
    </div>

  );
}

OrgaTeamHandler.propTypes = {
  team: PropTypes.shape({
    name: PropTypes.string.isRequired,
    cardPic: PropTypes.string.isRequired,
    joinedAt: PropTypes.string.isRequired,
    publicId: PropTypes.string.isRequired,
  }).isRequired,
};

export default OrgaTeamHandler;
