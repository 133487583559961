import React from 'react';
import PropTypes from 'prop-types';

function ContactField(props) {
  const {
    icon, value, onChange, error, label, placeholder, img, imgClass,
  } = props;

  return (
    <div className="field has-fullwidth has-max-width-300 my-3">
      <div className="is-flex has-content-centered-vertically">
        {
          img
            ? <img src={img} alt="" className={imgClass || 'has-width-40 has-height-40 has-margin-top-auto'} />
            : <i className={`is-size-2 has-width-40 has-height-40 has-margin-top-auto ${icon}`} />
        }
        <div className="ml-5 has-fullwidth">
          <div className="control">
            <input
              className="input has-no-background has-border-green-dark"
              type="text"
              value={value}
              placeholder={placeholder}
              onChange={(e) => onChange(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div>
        <p
          className="has-text-weight-semibold mt-2"
        >
          {error}
        </p>
      </div>
    </div>
  );
}

ContactField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  img: PropTypes.string,
  icon: PropTypes.string,
  imgClass: PropTypes.string,
};

ContactField.defaultProps = {
  placeholder: 'Tag',
  img: '',
  icon: '',
  imgClass: '',
};

export default ContactField;
