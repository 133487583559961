import React, { useEffect, useMemo, useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import MmrRangeSelector from '../MmrRangeSelector';

function MMrRangeWithToggle(props) {
  const { rank, setMmr, disabled } = props;
  const { game, mode, mmr } = rank;

  const [hasMmrRangeActive, setHasMmrRangeActive] = useState(false);

  const uniqueIdForToggle = useMemo(() => `${new Date().getTime()}`, []);

  useEffect(() => {
    if (mmr.start !== -1 && mmr.end !== -1) {
      setHasMmrRangeActive(true);
    } else {
      setHasMmrRangeActive(false);
    }
  }, [mode]);

  const toggleMmrRange = () => {
    if (hasMmrRangeActive) {
      setMmr({ start: -1, end: -1 });
    } else {
      setMmr({ start: 0, end: 0 });
    }

    setHasMmrRangeActive(!hasMmrRangeActive);
  };

  const { t } = useTranslation(['general']);

  if (!mode) return null;

  return (
    <div>
      <div className="is-relative">
        <input
          type="checkbox"
          id={uniqueIdForToggle}
          name={uniqueIdForToggle}
          className="switch is-rtl"
          checked={hasMmrRangeActive}
          onChange={toggleMmrRange}
          disabled={disabled}
        />
        <label
          htmlFor={uniqueIdForToggle}
          className="checkbox"
        >
          <p className="ml-2 is-size-5 has-text-grey">
            {t('general:mmr_range')}
          </p>

        </label>
      </div>
      {
        hasMmrRangeActive && (
        <MmrRangeSelector
          rank={{ mode, game, mmr }}
          setMmr={setMmr}
          disabled={disabled}
        />
        )
    }
    </div>
  );
}

MMrRangeWithToggle.propTypes = {
  rank: PropTypes.shape({
    game: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    mmr: PropTypes.shape({
      start: PropTypes.number.isRequired,
      end: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  setMmr: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

MMrRangeWithToggle.defaultProps = {
  disabled: false,
};

export default MMrRangeWithToggle;
