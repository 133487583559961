import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

// Assets
import defaultAvatar from 'assets/images/profile/default_avatar.png';

function PlayerboardCardDisplay(props) {
  const { img, url, engagement } = props;

  const urlToPic = `${process.env.REACT_APP_CDN_URL}${url}${img}`;

  const src = useMemo(() => {
    if (img) return urlToPic;

    return defaultAvatar;
  }, [img]);

  const glowBorder = useMemo(() => {
    if (!engagement) return '';

    if (engagement === 'competitive') return 'has-playerboard-competitive-border';

    return 'has-playerboard-just4fun-border';
  }, [engagement]);

  return (
    <div
      className={`br-10 has-text-white has-fullwidth has-fullheight has-overflow-hidden is-flex flex-direction-column ${glowBorder}`}
    >
      <img
        src={src}
        className="has-max-fullheight has-max-fullwidth"
        alt=""
      />
    </div>
  );
}

PlayerboardCardDisplay.propTypes = {
  img: PropTypes.string,
  url: PropTypes.string,
  engagement: PropTypes.string,
};

PlayerboardCardDisplay.defaultProps = {
  img: '',
  url: '/images/card_pictures/',
  engagement: '',
};

export default PlayerboardCardDisplay;
