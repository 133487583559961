import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function Loading({
  screenHeight = true,
  error = '',
}) {
  const { t } = useTranslation(['general']);

  return (
    <div className={`hero ${screenHeight ? 'is-min-fullheight' : 'has-fullheight'}`}>
      <div className="hero-body">
        <div className="container has-text-centered">
          {
              error
                ? (
                  <p className="has-text-centered has-text-danger has-text-weight-semibold is-size-4-desktop is-size-5-touch">
                    {error}
                  </p>
                )
                : (
                  <p className="is-size-7">
                    {`${t('general:loading')}`}
                  </p>
                )
          }
        </div>
      </div>
    </div>
  );
}

Loading.propTypes = {
  error: PropTypes.string,
  screenHeight: PropTypes.bool,
};

export default Loading;
