import React, {
  useEffect, useContext, useMemo,
} from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSocket } from 'components/providers/SocketProvider';
import { useTournament } from 'components/tournaments/TournamentPage';
import useAPI from 'components/hooks/useAPI';

export const TournamentSocketContext = React.createContext({});

function TournamentSidebarSocketHandler({ children }) {
  const {
    publicId, sidebarContent, setSidebarContent,
  } = useTournament();

  const { data: match, type } = sidebarContent;

  const { socket, isConnected } = useSocket();
  const { post } = useAPI();

  useEffect(() => {
    if (!socket) return undefined;

    const loadMatchData = async () => {
      try {
        if (type !== 'match') return;

        const data = {
          publicId: match.publicId,
        };

        const content = await post(`/tournaments/matches/load/${publicId}`, data);

        setSidebarContent({
          type: 'match',
          data: content,
        });
      } catch (e) {
        console.log(e);
      }
    };

    if (!isConnected) {
      socket.off('tournamentMatchUpdated', loadMatchData);
      return undefined;
    }

    socket.emit('joinRoom', `tournament_match_${match.publicId}`);
    socket.on('tournamentMatchUpdated', loadMatchData);

    return () => {
      socket.emit('leaveRoom', `tournament_match_${match.publicId}`);
      socket.off('tournamentMatchUpdated', loadMatchData); // Remove the event listener
    };
  }, [match.publicId, type, socket, isConnected]);

  return null;
}
export default TournamentSidebarSocketHandler;
