import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import { getSelectedGame } from 'constants/games';

// Assets
import defaultAvatar from 'assets/images/profile/default_avatar_quadratic.png';

// Styles
import PlatformDisplay from 'components/utils/PlatformDisplay';

const TeamCard = React.forwardRef(({ open, ...props }, ref) => {
  const { teamData, showDetailsButton } = props;

  const { language } = useSelector((state) => state.session);

  const { t } = useTranslation(['general', 'tags']);

  const {
    cardPic, name, game, isVerified, platform,
  } = teamData;

  const renderedCard = useMemo(() => {
    // const locale = getCurrentLocale();
    // const renderedPostedAt = DateTime.fromISO(postedAt).setZone(timezone).setLocale(locale).toRelative();

    const urlToPic = cardPic ? `${process.env.REACT_APP_CDN_URL}/images/teams/card_pictures/${cardPic}` : defaultAvatar;

    const { icon: gameIcon } = getSelectedGame(game.tag);

    return (
      <div
        className="is-size-6 has-text-left grow_small p-2"
      >
        <div
          className="br-5 has-overflow-hidden has-text-white has-width-500 has-min-width-300 has-border-primary-glow"
        >
          <div className="columns is-mobile is-multiline">
            <div className="column p-0 is-narrow is-flex">
              <img src={urlToPic} alt="" className="has-width-150 has-height-150" />
            </div>

            <div className="column p-0 is-flex flex-direction-column has-background-grey-gradient">

              <div className="has-fullheight has-fullwidth">

                <div className="columns is-multiline">
                  <div className="column py-5 px-5">
                    <p
                      className="is-size-5 has-text-weight-semibold mr-2 mb-1"
                    >
                      { name }
                    </p>
                  </div>
                  <div className="column is-narrow p-0">
                    <div className="has-text-centered has-background-primary px-2 pb-1 pt-2">
                      <img src={gameIcon} alt="" className="has-width-32 has-height-32 br-10 is-block mb-3" />
                      <PlatformDisplay platform={platform} />
                    </div>
                  </div>
                </div>

                {
                  isVerified && (
                    <div className="is-inline-block has-border-primary">
                      <p
                        className="is-size-7"
                      >
                        {t('general:verified')}
                      </p>
                    </div>
                  )
                }
              </div>

              {
                showDetailsButton && (
                  <div className="has-margin-top-auto has-text-right">
                    <div
                      className="has-background-primary has-text-white is-inline-block py-1 px-3"
                      style={{ borderTopLeftRadius: '5px', borderBottomRightRadius: '5px' }}
                    >
                      <p>
                        {t('general:details')}
                      </p>
                    </div>
                  </div>
                )
              }
            </div>
          </div>

        </div>
      </div>
    );
  }, [teamData, language]);

  return (
    <div ref={ref} className="">
      { renderedCard }
    </div>
  );
});

TeamCard.propTypes = {
  teamData: PropTypes.shape({
    name: PropTypes.string,
    cardPic: PropTypes.string,
    game: PropTypes.shape({
      tag: PropTypes.string,
    }),
  }).isRequired,
  showDetailsButton: PropTypes.bool,
};

TeamCard.defaultProps = {
  showDetailsButton: true,
};

export default TeamCard;
