import React, {
  useEffect,
} from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSocket } from 'components/providers/SocketProvider';
import { useTournament } from 'components/tournaments/TournamentPage';
import useAPI from 'components/hooks/useAPI';

function AdminAlertSocketHandler({ setAlerts }) {
  const {
    publicId,
  } = useTournament();

  const { socket, isConnected } = useSocket();
  const { post } = useAPI();

  useEffect(() => {
    if (!socket) return undefined;

    const loadAdminAlert = async (alertId) => {
      try {
        const data = {
          alertId,
        };
        const alert = await post(`/tournaments/admin-alerts/load/${publicId}`, data);

        setAlerts((prevAlerts) => {
          // if it exists, update, otherwise add
          const index = prevAlerts.findIndex((a) => a.publicId === alert.publicId);

          if (index !== -1) {
            prevAlerts[index] = alert;
            return [...prevAlerts];
          }

          return [alert, ...prevAlerts];
        });
      } catch (e) {
        console.log(e);
      }
    };

    if (!isConnected) {
      socket.off('tournamentAdminAlertSolved', loadAdminAlert);
      socket.off('tournamentAdminAlertCreated', loadAdminAlert);
      return undefined;
    }

    socket.on('tournamentAdminAlertSolved', loadAdminAlert);
    socket.on('tournamentAdminAlertCreated', loadAdminAlert);

    return () => {
      socket.off('tournamentAdminAlertSolved', loadAdminAlert);
      socket.off('tournamentAdminAlertCreated', loadAdminAlert);
    };
  }, [socket, isConnected]);

  return null;
}

AdminAlertSocketHandler.propTypes = {
  setAlerts: PropTypes.func.isRequired,
};

export default AdminAlertSocketHandler;
