import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

// Hooks
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';
import { useTeamProfile } from 'components/Teams/TeamProfile';

// Components
import PlatformSelector from 'components/utils/PlatformSelector';

function TeamPlatformSelector() {
  const {
    game, publicId, platform, setPlatform,
  } = useTeamProfile();

  const { post } = useAPI();
  const { t } = useTranslation(['general', 'errors']);

  // const [status, setStatus] = useState('');

  const savePlatform = async (newPlatform) => {
    try {
      // setStatus('');

      const data = { platform: newPlatform };

      await post(`/teams/platform/${publicId}`, data);

      // dispatch(setPlatform(newPlatform));
      setPlatform(newPlatform);

      stats.push(['trackEvent', 'Team Profile', 'New platform set']);

      // setStatus(`${t('general:saved_successfully')}`);
    } catch (e) {
      console.log(e);
      // setStatus(`${t('errors:save_new_platform_error')}.`);
    }
  };

  return (
    <div className="">
      <PlatformSelector
        game={game.tag}
        platform={platform}
        setPlatform={savePlatform}
        displayType="column"
      />
    </div>
  );
}

export default TeamPlatformSelector;
