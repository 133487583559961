import React from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';

function TournamentPrizePoolDisplay() {
  const { info } = useTournament();
  const { prizePool } = info;

  const { t } = useTranslation(['general']);

  if (!prizePool) return null;

  return (
    <div className="mt-5">
      <p className="is-size-4-desktop is-size-5-touch">
        {t('general:prize_pool')}
      </p>
      <div className="field">
        <p>
          { prizePool }
        </p>
      </div>
    </div>
  );
}

export default TournamentPrizePoolDisplay;
