import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

function HotscrimpDescriptionDisplay(props) {
  const { description } = props;

  const { t } = useTranslation(['general']);

  if (!description) return null;

  return (
    <div>
      <p
        className="has-text-weight-semibold is-size-4 mb-1"
      >
        {t('general:remark')}
      </p>
      <p>{description}</p>
    </div>
  );
}

HotscrimpDescriptionDisplay.propTypes = {
  description: PropTypes.string.isRequired,
};

export default HotscrimpDescriptionDisplay;
