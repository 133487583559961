import React, { useState } from 'react';

// Hooks
import { useHotscrimp } from 'components/HotScrimps/HotscrimpsRequestPage';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';
import { useNavigate } from 'react-router-dom';

// Constants
import { HOT_SCRIMPS } from 'constants/routes';

function HotscrimpRequestStateChanger() {
  const {
    hotscrimpTeam, publicId, status, setStatus,
  } = useHotscrimp();

  const { playerId } = useSelector((state) => state.player.player);

  const [disabled, setDisabled] = useState(false);

  const { t } = useTranslation(['hotscrimp']);
  const { post } = useAPI();

  const navigate = useNavigate();

  const acceptHotscrimpRequest = async () => {
    try {
      setDisabled(true);

      const idToAcceptedSite = await post(`/hotscrimps/requests/accept/${publicId}`);

      setStatus('accepted');

      setDisabled(false);

      navigate(`${HOT_SCRIMPS}/${idToAcceptedSite}`);
    } catch (e) {
      setDisabled(false);
    }
  };

  const declineHotscrimpRequest = async () => {
    try {
      setDisabled(true);

      const newStatus = await post(`/hotscrimps/requests/decline/${publicId}`);
      setStatus(newStatus);

      setDisabled(false);
    } catch (e) {
      setDisabled(false);
    }
  };

  return (
    <div className="">

      <div className="columns">
        <div className="column has-text-centered">
          <button
            type="button"
            className="button is-primary has-fullwidth"
            onClick={acceptHotscrimpRequest}
            disabled={disabled}
          >
            {t('general:accept')}
          </button>
        </div>
        <div className="column has-text-centered">
          <button
            type="button"
            className="button is-danger has-fullwidth"
            onClick={declineHotscrimpRequest}
            disabled={disabled}
          >
            {t('general:decline')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default HotscrimpRequestStateChanger;
