import React, { useMemo } from 'react';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import ReactMarkdown from 'react-markdown';

function TournamentTOS() {
  const { t } = useTranslation(['terms']);

  const renderedTerms = useMemo(() => {
    const sections = t('terms:tournament_terms:sections', { returnObjects: true });

    return sections.map((section, index) => (
      <div key={index}>
        <h2 className="is-size-4 has-text-weight-bold mt-2">{section.title}</h2>
        <ReactMarkdown>{section.content}</ReactMarkdown>
      </div>
    ));
  }, [t]);

  return (
    <>
      <Helmet>
        <title>Scrimps - Turnierregeln</title>
      </Helmet>
      <div className="mx-5 my-3 box is-min-fullheight">
        <h1 className="is-size-3 has-text-weight-bold">
          {t('terms:tournament_terms:title')}
        </h1>

        <div>
          {renderedTerms }
        </div>
      </div>
    </>
  );
}

export default TournamentTOS;
