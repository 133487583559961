import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import getCardTypeLabel from 'utils/getCardTypeLabel';

// Components
import Dropdown from 'components/utils/Dropdown';

// Constants
import { TYPE_PLAYER, TYPE_TEAM } from 'constants/cards';

const TYPES = [
  { label: 'Spieler', value: TYPE_PLAYER },
  { label: 'Team', value: TYPE_TEAM },
];

function TypeSelector(props) {
  const { type, setType } = props;

  const currentValue = useMemo(() => {
    if (type === TYPE_PLAYER) return 'Spieler';

    return 'Team';
  }, [type]);

  const _setType = (newType) => {
    setType(newType.value);
  };

  return (
    <div className="field">
      <label className="label">
        Ich bin ein
      </label>
      <div className="control">
        <Dropdown
          items={TYPES}
          onSelect={_setType}
          value={currentValue}
        />
      </div>
    </div>
  );
}

TypeSelector.propTypes = {
  type: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
};

export default TypeSelector;
