import React, { useMemo, useState } from 'react';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import Popup from 'reactjs-popup';
import PlayerboardEditor from 'components/Playerboard/PlayerboardEditor';
import PlayerCard from '../PlayerCard';

function CreatedPlayerboardDisplay() {
  const { timezone, language } = useSelector((state) => state.session);
  const { hasCreatedEntry } = useSelector((state) => state.playerboard.userEntry);

  const { t } = useTranslation();

  const [disabled, setDisabled] = useState(false);

  const renderedEntry = useMemo(() => (
    <Popup
      trigger={(
        <div className="">
          { !hasCreatedEntry ? (
            <div className="py-2 has-fullheight">
              <div className="cleanButton br-15 has-text-white has-width-180 has-height-300 has-overflow-hidden is-flex has-content-centered has-text-weight-semibold has-text-grey grow has-border-dashed-grey flex-direction-column">
                <i className="fas fa-plus-circle is-size-3 mb-4" />
                {t('general:create')}
              </div>
            </div>
          )
            : <PlayerCard index={-1} />}
        </div>
      )}
      position="top center"
      keepTooltipInside="#root"
      closeOnDocumentClick={!disabled}
      closeOnEscape={!disabled}
      modal
    >
      {
        (close) => (
          <PlayerboardEditor
            disabled={disabled}
            setDisabled={setDisabled}
            close={close}
          />
        )

      }
    </Popup>

  ), [hasCreatedEntry, timezone, language, disabled]);

  return (
    <div className="column is-narrow my-3">
      { renderedEntry }
    </div>
  );
}

export default CreatedPlayerboardDisplay;
