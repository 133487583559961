import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import Dropdown from 'components/utils/Dropdown';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import { AVAILABLE_REGIONS } from 'constants/user';

function RegionFilterDropdown(props) {
  const { t } = useTranslation(['general']);

  const { region, setRegion, disabled } = props;

  // useMemo is used for later use. currently all the data comes from AVAILABLE_REGIONS
  // const preparedRegions = useMemo(() => AVAILABLE_REGIONS, []);

  const availableRegions = useMemo(() => {
    const regions = AVAILABLE_REGIONS.map((_region) => ({
      value: _region.value,
      label: t(`general:${_region.label}`),
    }));

    return regions;
  }, [AVAILABLE_REGIONS]);

  const _setRegion = (newRegion) => {
    const regionSelected = availableRegions.find((_region) => _region.value === newRegion.value);

    if (regionSelected === undefined || regionSelected.value === region) {
      return;
    }

    setRegion(regionSelected.value);
  };

  const activeRegion = useMemo(() => availableRegions.find((_region) => _region.value === region), [region]);

  return (

    <div className="field">

      <div className="">

        <label className="label has-text-weight-semibold is-size-7">
          {t('general:region')}
        </label>
        <div className="has-fullwidth">
          <Dropdown
            items={availableRegions}
            onSelect={_setRegion}
            value={activeRegion}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
}

RegionFilterDropdown.propTypes = {
  region: PropTypes.string.isRequired,
  setRegion: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default RegionFilterDropdown;
