import React, { useMemo, useState } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';
import { DateTime } from 'luxon';

// Components
import DatePicker from 'react-datepicker';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function DateSelector(props) {
  const { date, setDate } = props;

  const { timezone } = useSelector((state) => state.session);

  const { t } = useTranslation(['general']);

  // You can schedule hot scrimps
  const [isScheduled, setScheduled] = useState(false);

  const filterStartTime = (startTime) => {
    let now = DateTime.now().setZone(timezone);

    const { hour, minute } = now;

    now = now.toJSDate();
    now.setHours(hour);
    now.setMinutes(minute);

    return startTime.getTime() > now.getTime();
  };

  const _setDate = (_date) => {
    // if _date is an invalid date, skip
    if (Number.isNaN(_date.getTime())) {
      return;
    }

    const hours = _date.getHours();
    const minutes = _date.getMinutes();
    const day = _date.getDate();
    // month starts at 0
    const month = _date.getMonth() + 1;
    const year = _date.getFullYear();

    _date = DateTime.fromJSDate(_date).setZone(timezone);
    _date = _date.set({
      hour: hours, minute: minutes, day, month, year,
    });

    setDate(_date.toJSDate().toISOString());
  };

  const _setScheduled = (_isScheduled) => {
    setDate(undefined);
    setScheduled(_isScheduled);

    // its the other way around because we toggle it, so we still have the old value
    if (isScheduled) {
      stats.push(['trackEvent', 'Hot Scrimps Creator', 'Set date today']);
    } else {
      stats.push(['trackEvent', 'Hot Scrimps Creator', 'Set date scheduled']);
    }
  };

  const addDays = (_date, amount) => {
    _date = DateTime.fromJSDate(_date).setZone(timezone);

    _date = _date.set({
      hour: 0, minute: 0, second: 0, millisecond: 0,
    });
    _date = _date.plus({ days: amount });

    const {
      hour, minute, day, month, year,
    } = _date;

    const jsDate = _date.toJSDate();

    jsDate.setDate(day);
    // js dates start from 0 up to 11
    jsDate.setMonth(month - 1);
    jsDate.setHours(hour);
    jsDate.setMinutes(minute);
    jsDate.setFullYear(year);

    return jsDate;
  };

  const isInPast = (_date) => {
    const startDate = addDays(new Date(), 1);

    return _date >= startDate;
  };

  // includeDateIntervals={dates}

  // get a json date, extract its time values and convert it to the timezone
  // then, set the time values back
  const selectedDate = useMemo(() => {
    if (!date) return undefined;

    const timedzonedDate = DateTime.fromISO(date, { zone: timezone });
    const {
      hour, minute, month, day, year,
    } = timedzonedDate;

    const jsDate = timedzonedDate.toJSDate();

    jsDate.setDate(day);
    // js dates start from 0 up to 11
    jsDate.setMonth(month - 1);
    jsDate.setHours(hour);
    jsDate.setMinutes(minute);
    jsDate.setFullYear(year);

    return jsDate;
  }, [date]);

  return (

    <div className="mt-5">
      <p
        className=" is-size-5 has-text-grey mb-3"
      >
        {t('general:when')}
      </p>
      <div className="field is-flex has-margin-left-auto has-margin-top-auto">

        <button
          type="button"
          className={`button px-5 has-text-weight-semibold ${!isScheduled ? 'is-primary' : 'has-no-background has-border-green-dark'}`}
          onClick={() => _setScheduled(false)}
        >
          {t('general:today')}
        </button>
        <button
          type="button"
          className={`button mx-2 has-text-weight-semibold ${isScheduled ? 'is-primary' : 'has-no-background has-border-green-dark'}`}
          onClick={() => _setScheduled(true)}
        >
          {t('general:planned')}
        </button>
      </div>
      {
        isScheduled
          ? (
            <div className="">
              <p className="mb-1 has-text-grey is-size-5">
                {t('general:date')}
              </p>
              <div className="has-width-200">
                <DatePicker
                  selected={selectedDate}
                  onChange={(_date) => _setDate(_date)}
                  showTimeSelect
                  disabledKeyboardNavigation
                  filterDate={isInPast}
                  timeIntervals={15}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  timeCaption={t('general:time')}
                  dateFormat="Pp"
                  timeFormat="p"
                  className={`is-inline-block has-text-white has-text-weight-semibold is-size-5 p-0 ${selectedDate ? 'cleanButton' : 'input has-no-background has-border-green-dark is-size-6'}`}
                />
              </div>
            </div>
          )
          : (
            <div className="">
              <p className="mb-1 has-text-grey is-size-5">
                {t('general:time')}
              </p>
              <div className="is-flex has-width-200">
                <DatePicker
                  selected={selectedDate}
                  onChange={(_date) => _setDate(_date)}
                  showTimeSelect
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  disabledKeyboardNavigation
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption={t('general:time')}
                  dateFormat="p"
                  timeFormat="p"
                  filterTime={filterStartTime}
                  className={`is-inline-block has-text-white has-text-weight-semibold is-size-5 p-0 ${selectedDate ? 'cleanButton ' : 'input has-no-background has-border-green-dark is-size-6'}`}
                />

              </div>
            </div>
          )
      }
    </div>

  );
}

DateSelector.propTypes = {
  date: PropTypes.string,
  setDate: PropTypes.func.isRequired,
};

DateSelector.defaultProps = {
  date: undefined,
};

export default DateSelector;
