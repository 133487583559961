import React, { useMemo } from 'react';

// Hooks
import { useTeamProfile } from 'components/Teams/TeamProfile';
import { useTranslation } from 'react-i18next';

// Components
import TeamRegionSelector from './TeamRegionSelector';

const TeamRegionHandler = () => {
  const { region, permissions } = useTeamProfile();
  const { canUpdateTeam } = permissions;

  const { t } = useTranslation(['general']);

  const renderedRegion = useMemo(() => {
    if (!canUpdateTeam) {
      return (
        <div>
          <p className="is-size-5 has-text-grey">
            { t('general:region') }
          </p>
          <p
            className="has-text-weight-semibold has-text-white break-word is-size-3-desktop is-size-4-touch is-inline-block"
          >
            { region.toUpperCase() }
          </p>
        </div>
      );
    }

    return (
      <TeamRegionSelector />
    );
  }, [region]);

  return renderedRegion;
};

export default TeamRegionHandler;
