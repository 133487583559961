import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

function AsyncNumberEditor(props) {
  const {
    number, label, min, max, onSave, placeholder,
  } = props;

  const { t } = useTranslation(['general', 'errors']);

  const [newNumber, setNewNumber] = useState(number);
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const _setNumber = (_number) => {
    setStatus('');

    const intNumber = parseInt(_number, 10);

    if (_number === '') {
      setNewNumber(-1);
      return;
    }

    if (Number.isNaN(intNumber)) return;

    if (intNumber < min) {
      setStatus(`${t('errors:number_error')}!`);
      return;
    }

    if (intNumber > max) {
      setStatus(`${t('errors:number_error')}!`);
      return;
    }

    setNewNumber(intNumber);
  };

  const saveNumber = async () => {
    try {
      setStatus('');
      setDisabled(true);

      await onSave(newNumber);

      setDisabled(false);
      setStatus(`${t('general:saved_successfully')}`);
    } catch (e) {
      console.log(e);
      setDisabled(false);
      setStatus(`${t('errors:could_not_save_error')}`);
    }
  };

  const showSaveButton = newNumber !== number;

  return (
    <div className="">
      <div className="">
        <div className="is-inline-block has-fullwidth has-text-left">
          <div className="columns">
            <div className="column px-0">
              <div className="mt-1">
                <label className="has-text-grey has-text-weight-normal is-size-5">
                  {t(`general:${label}`)}
                </label>
              </div>
            </div>
            <div className="column px-0 is-narrow has-max-width-120">
              <input
                className="input is-size-6 has-text-weight-medium"
                type="text"
                value={newNumber === -1 ? '' : `${newNumber}`}
                placeholder={newNumber === -1 ? t('general:unlimited') : `${newNumber}`}
                disabled={disabled}
                onChange={(e) => _setNumber(e.target.value)}
              />
            </div>
          </div>

        </div>
        {
        showSaveButton && (
        <div className="has-text-left has-margin-top-auto">
          <button
            type="button"
            className="button is-block is-primary grow_small has-text-weight-semibold"
            onClick={saveNumber}
            disabled={disabled}
          >
            {t('general:save')}
          </button>
        </div>
        )
      }
      </div>

      {
        status && (
          <p className="has-text-weight-semibold mt-4">
            { status }
          </p>
        )
      }
    </div>
  );
}

AsyncNumberEditor.propTypes = {
  number: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  label: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
};

AsyncNumberEditor.defaultProps = {
  label: 'number',
  min: -999999999,
  max: 999999999,
};

export default AsyncNumberEditor;
