import React, { useState } from 'react';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { setPlayer } from 'store/player';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Libraries
import stats from 'analytics/matomo';
import PropTypes from 'prop-types';

// Assets
import onboardingImg from 'assets/images/base/onboarding_welcome_thumbnail.jpg';

function PlayerCreator(props) {
  const { onDone } = props;

  const [name, setName] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState('');

  const { post } = useAPI();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setNameAndValidate = (_name) => {
    setName(_name);
  };

  const createPlayer = async () => {
    try {
      if (!name) return;

      setDisabled(true);
      setError('');

      const data = { name };
      const player = await post('/player', data);

      dispatch(setPlayer(player));

      stats.push(['trackEvent', 'Onboarding', 'User created Player']);

      setDisabled(false);

      onDone();
    } catch (e) {
      setDisabled(false);

      if (e.response && e.response.data === 'NAME_NOT_UNIQUE') {
        setError(t('errors:display_name_already_in_use'));
        return;
      }
      setError(t('errors:an_error_occured_error'));
    }
  };

  return (

    <div className="box p-0 has-text-centered has-fullheight">

      <div className="columns has-fullheight is-multiline">

        <div
          className="column is-5-desktop has-min-width-300 is-5-tablet p-0"
        >
          <div
            className="has-fullheight has-text-left"
            style={{ backgroundImage: `url(${onboardingImg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
          >
            <div className="p-5">
              <h1
                className="is-size-1-dekstop is-size-2-tablet has-text-weight-semibold"
              >
                {t('general:onboarding')}
              </h1>
              <p
                className="is-size-1-desktop is-size-2-desktop has-text-grey"
              >
                {t('general:player_name')}
              </p>
            </div>
          </div>
        </div>

        <div className="column">
          <div className="field p-6 is-flex flex-direction-column has-fullheight is-relative">

            <div
              style={{
                position: 'absolute',
                width: '0px',
                height: '0px',
                right: '-11px',
                top: '-11px',

                // borderRadius: '5px',

                borderTop: '75px solid #F27118',
                borderLeft: '75px solid transparent',

              }}
            />

            <div>
              <h2
                className="has-text-weight-normal is-size-3-desktop"
              >
                {`${t('general:select_a_player_name')}!`}
              </h2>
              <p className="mt-6 has-text-grey-lighter">
                {`${t('general:player_name_rules')}.`}
              </p>
            </div>

            <div className="has-margin-auto has-fullwidth has-max-width-350">
              <div className="control has-text-left">
                <label className="label is-size-7 has-text-weight-semibold">
                  { `${t('general:player_name')}:`}
                </label>
                <input
                  className="input"
                  type="text"
                  value={name}
                  placeholder={t('general:name')}
                  disabled={disabled}
                  onChange={(e) => setNameAndValidate(e.target.value)}
                />
              </div>
              <button
                type="button"
                className="button has-background-hotscrimps is-size-5-desktop is-primary grow_small has-text-weight-semibold mt-6 boxshadow"
                onClick={createPlayer}
                disabled={disabled || name.length === 0}
              >
                {t('general:create')}
              </button>
              {
                error && (
                  <p className="has-text-weight-semibold has-text-white mt-3">
                    {error}
                  </p>
                )
              }
            </div>

          </div>
        </div>

      </div>

    </div>

  );
}

PlayerCreator.propTypes = {
  onDone: PropTypes.func.isRequired,
};

export default PlayerCreator;
