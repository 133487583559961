import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useTournament } from 'components/tournaments/TournamentPage';

// Components
import CopyButtonSimple from 'components/utils/CopyButtonSimple';

function TournamentIdDisplay() {
  const { publicId } = useTournament();

  const { t } = useTranslation(['gemeral']);

  return (

    <div
      className="cleanButton has-text-white has-text-left"
    >
      <div className="is-flex has-content-centered-vertically mt-1">
        <CopyButtonSimple
          text={window.location.href}
          className="cleanButton has-text-white"
        >
          <div className="has-text-left">
            <div className="">
              <p className="is-size-4-desktop is-size-5-touch">
                {t('general:tournament_id')}
              </p>

            </div>
            <div className="is-flex has-content-centered-vertically">
              <p className="mr-3 has-text-primary is-size-4">
                { `SCR-TMT-${publicId}` }
              </p>
              <i className="fas fa-copy is-size-6" />
            </div>
          </div>
        </CopyButtonSimple>
      </div>
    </div>

  );
}

export default TournamentIdDisplay;
