import React, { useMemo, useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';
import { useNavigate } from 'react-router-dom';

// Constants
import { ORGA_PAGE } from 'constants/routes';

// Components
import TriangleDisplay from 'components/utils/TriangleDisplay';
import RegionSelectorEditor from 'components/utils/RegionSelectorEditor';
import NameEditor from './NameEditor';
import OrgaSubTypeSelector from './OrgaSubTypeSelector';
import OrgaTypeSelector from './OrgaTypeSelector';

// Assets
import coverImg from 'assets/images/base/onboarding_welcome_thumbnail.jpg';

function OrgaCreator(props) {
  const { disabled, setDisabled, close } = props;

  const [name, setName] = useState('');
  const [orgaType, setOrgaType] = useState('company');
  const [orgaSubType, setOrgaSubType] = useState('');
  const [region, setRegion] = useState('eu');

  const [status, setStatus] = useState('');

  const isValid = useMemo(() => {
    if (!name) return false;

    if (orgaType === 'club' || orgaType === 'association') return true;

    if (!orgaSubType) return false;

    return true;
  }, [name, orgaType, orgaSubType]);

  const { t } = useTranslation(['general', 'validation']);
  const { post } = useAPI();

  const navigate = useNavigate();

  const createOrga = async () => {
    try {
      setDisabled(true);

      const data = {
        name,
        orgaType,
        orgaSubType,
        region,
      };

      const publicId = await post('/orgas', data);

      setDisabled(false);
      close();
      navigate(`${ORGA_PAGE}${publicId}`);
    } catch (e) {
      setStatus(t('errors:could_not_save_error'));
      setDisabled(false);
    }
  };

  return (
    <div className="is-fullwidth has-min-width-300 has-max-width-1000 is-flex has-content-centered is-in-center has-overflow-hidden box boxshadow has-background-black-gradient p-0">
      <div className="columns has-fullheight has-fullwidth is-multiline">

        <div
          className="column is-5-desktop has-min-width-300 is-5-tablet p-0"
        >
          <div
            className="has-fullheight has-text-left"
            style={{ backgroundImage: `url(${coverImg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
          >
            <div className="p-5">
              <h1
                className="is-size-2-desktop is-size-2-tablet has-text-weight-semibold"
              >
                {t('general:create_orga')}
              </h1>
            </div>
          </div>
        </div>

        <div className="column p-0">

          <div className="field p-3 is-flex flex-direction-column has-fullheight is-relative has-background-black-gradient pb-6">

            <div className="is-relative">
              <TriangleDisplay />
            </div>

            <div className="is-relative mt-6">

              <div className="columns is-multiline">
                <div className="column is-12">
                  <p className="mb-5 is-size-4">
                    {t('general:create_your_orga')}
                  </p>
                  <NameEditor name={name} setName={setName} disabled={disabled} />
                </div>
                <div className="column is-12">
                  <OrgaTypeSelector
                    orgaType={orgaType}
                    setOrgaType={setOrgaType}
                    disabled={disabled}
                  />
                  <div className="mt-3">
                    <OrgaSubTypeSelector
                      orgaType={orgaType}
                      orgaSubType={orgaSubType}
                      setOrgaSubType={setOrgaSubType}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className="column is-12">
                  <RegionSelectorEditor region={region} setRegion={setRegion} />
                </div>
              </div>
            </div>

            <div className="is-inline-block has-text-centered has-margin-top-auto">
              <button
                type="button"
                onClick={createOrga}
                disabled={disabled || !isValid}
                className={`button is-size-5 px-5 has-background-primary has-text-white has-text-weight-semibold is-inline-block mt-6 ${disabled ? 'is-loading' : ''}`}
              >
                {t('general:create')}
              </button>
            </div>
            {
                status && (
                  <div className="has-text-centered mt-5">
                    <p className=" has-text-white">
                      {status}
                    </p>
                  </div>
                )
              }
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrgaCreator;
