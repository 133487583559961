import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useTournament } from 'components/tournaments/TournamentPage';
import { useSelector } from 'react-redux';

// Components
import TwitchStreamDisplay from './TwitchStreamDisplay';
import TwitchCookieHandler from './TwitchCookieHandler';

function TournamentStreamHandler() {
  const { t } = useTranslation(['general']);
  const {
    streaming,
  } = useTournament();
  const { channel, vod, clip } = streaming;

  const { cookies } = useSelector((state) => state.session);

  if (!channel && !vod && !clip) {
    return null;
  }

  if (!cookies.twitchCookies) {
    return (
      <div>
        <h2 className="is-size-3 has-text-standard has-text-weight-light mb-2">
          <i className="fas fa-play mr-4 has-text-primary" />
          {t('general:stream')}
        </h2>
        <TwitchCookieHandler />
      </div>
    );
  }

  return (
    <div>
      <h2 className="is-size-3 has-text-standard has-text-weight-light mb-2">
        <i className="fas fa-play mr-4 has-text-primary" />
        {t('general:stream')}
      </h2>
      <TwitchStreamDisplay channel={channel} vod={vod} clip={clip} />
    </div>
  );
}

export default TournamentStreamHandler;
