import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import {
  de as dateDE,
  enUS as dateEN,
  fr as dateFR,
  es as dateES,
} from 'date-fns/locale';

import LanguageDetector from 'i18next-browser-languagedetector';
import { setDefaultLocale, registerLocale } from 'react-datepicker';

import * as de from './de';
import * as en from './en';
import * as jp from './jp';
import * as fr from './fr';
import * as es from './es';

registerLocale('de', dateDE);
registerLocale('en', dateEN);
registerLocale('fr', dateFR);
registerLocale('es', dateES);

/* registerLocale('de', dateDE);
registerLocale('en', dateEN) */

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  de,
  us: en,
  jp,
  fr,
  es,
};

i18n
  /* .use(LanguageDetector) */
  .use(initReactI18next) // passes i18n down to react-i18next
  // .use(LanguageDetector)
  .init({

    detection: {
      order: ['navigator', 'htmlTag'],
    },

    resources,

    fallbackLng: 'us',
    caches: ['localStorage'],

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

i18n.on('languageChanged', (lang) => {
  setDefaultLocale(lang);
});

export function getCurrentLocale() {
  const currentLanguage = i18n.language || window.localStorage.i18nextLng || 'us';

  if (currentLanguage === 'jp') return 'ja-JP';

  if (currentLanguage === 'us' || currentLanguage === 'gb') return 'en';

  return currentLanguage;
}

export const AVAILABLE_LANGUAGES = [
  { value: 'de', label: 'Deutsch' },
  { value: 'en', label: 'English' },
  { value: 'jp', label: '日本語' },
  { value: 'fr', label: 'Français' },
  { value: 'es', label: 'Español' },
];

export default i18n;
