import React, { useState } from 'react';

// Constants
import { MAX_CARD_DESC_LENGTH } from 'constants/cards';

// Libraries
import PropTypes from 'prop-types';

function DescCreator(props) {
  const { description, setDescription } = props;
  const [error, setError] = useState('');

  const setDescriptionAndValidate = (text) => {
    if (text.length > MAX_CARD_DESC_LENGTH) {
      setError('Maximale Länge einer Beschreibung erreicht!');
      return;
    }

    setError('');
    setDescription(text);
  };

  return (
    <div className="field">
      <label className="label">
        Beschreibung (optional)
      </label>
      <div className="control">
        <input
          className="input"
          type="text"
          value={description}
          placeholder="Beschreibung der Karte"
          onChange={(e) => setDescriptionAndValidate(e.target.value)}
        />
        <p className="has-text-weight-semibold has-text-danger">
          {error}
        </p>
      </div>
    </div>
  );
}

DescCreator.propTypes = {
  description: PropTypes.string.isRequired,
  setDescription: PropTypes.func.isRequired,
};

export default DescCreator;
