import React, { useMemo, useState } from 'react';

// Libaries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import { ORGA_TYPES } from 'constants/orgas';
import Dropdown from 'components/utils/Dropdown';

const regex = new RegExp(/^[\u00C0-\u017FA-Za-z0-9-_. ]+$/);

function OrgaTypeSelector(props) {
  const { t } = useTranslation(['general', 'validation']);

  const [status, setStatus] = useState('');

  const { orgaType, setOrgaType, disabled } = props;

  const availableTypes = useMemo(() => {
    const categories = Object.keys(ORGA_TYPES);

    return categories.map((type) => ({
      label: t(`general:${type}`),
      value: type,
    }));
  }, [ORGA_TYPES]);

  const _setOrgaType = (_orgaType) => {
    setOrgaType(_orgaType.value);
  };

  const selectedType = useMemo(() => availableTypes.find((type) => type.value === orgaType), [orgaType, availableTypes]);

  return (
    <div>
      <div className="">
        <div className="is-inline-block has-min-width-250 has-text-left">
          <label className="label has-text-weight-normal has-text-grey is-size-5">
            {t('general:type')}
          </label>
          <div className="control">
            <Dropdown
              items={availableTypes}
              onSelect={_setOrgaType}
              disabled={disabled}
              value={selectedType}
            />
          </div>
        </div>
        { status && (
        <p className="has-text-weight-semibold mt-4">
          { status }
        </p>
        )}
      </div>
    </div>

  );
}

OrgaTypeSelector.propTypes = {
  orgaType: PropTypes.string.isRequired,
  setOrgaType: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default OrgaTypeSelector;
