import React, { useMemo, useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';

// Components
import TournamentOrganizerCardPicEditor from './TournamentOrganizerCardPicEditor';
import InputEditor from 'components/utils/inputs/InputEditor';

function TournamentOrganizerEditor() {
  const { publicId, info, setInfo } = useTournament();
  const { organizer } = info;
  const { name, website } = organizer;

  const { t } = useTranslation(['general', 'errors']);
  const { post } = useAPI();

  const [newName, setNewName] = useState(name);
  const [newWebsite, setNewWebsite] = useState(website);

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const saveOrganizer = async () => {
    try {
      setStatus('');
      setDisabled(true);

      const data = {
        name: newName,
        website: newWebsite,
      };

      await post(`/tournaments/organizer/${publicId}`, data);
      setInfo({ ...info, organizer: { ...organizer, name: newName, website: newWebsite } });

      setDisabled(false);
    } catch (e) {
      console.log(e);
      setDisabled(false);
      setStatus(`${t('errors:could_not_save_error')}`);
    }
  };

  const showSaveButton = useMemo(() => {
    if (name === newName && website === newWebsite) {
      return false;
    }

    return true;
  }, [name, newName, website, newWebsite]);

  return (
    <div className="has-max-width-200 has-fullheight is-flex flex-direction-column">
      <p
        className="mb-1 has-text-grey is-size-5"
      >
        {t('general:organizer')}
      </p>

      <div className="flex-grow">
        <TournamentOrganizerCardPicEditor />
      </div>

      <div className="my-4">
        <InputEditor
          label="name"
          text={newName}
          setText={setNewName}
        />
      </div>

      <InputEditor
        label="url"
        text={newWebsite}
        setText={setNewWebsite}
      />

      {
        showSaveButton && (
        <div className="has-text-left mt-4">
          <button
            type="button"
            className="button is-block is-primary grow_small has-text-weight-semibold"
            onClick={saveOrganizer}
            disabled={disabled}
          >
            {t('general:save')}
          </button>
        </div>
        )
      }

      {
        status && (
          <p className="has-text-weight-semibold mt-4">
            { status }
          </p>
        )
      }

    </div>
  );
}

export default TournamentOrganizerEditor;
