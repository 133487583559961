import React from 'react';

// Hooks
import { useTeamProfile } from 'components/Teams/TeamProfile';

// Components
import DeleteTeamButton from './DeleteTeamButton';

function TeamDeletionHandler() {
  const { permissions } = useTeamProfile();
  const { canDeleteTeam } = permissions;

  if (!canDeleteTeam) return null;

  return <DeleteTeamButton />;
}

export default TeamDeletionHandler;
