import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTeamProfile } from 'components/Teams/TeamProfile';

// Components
import TeamKickButton from './TeamKickButton';

function TeamKickHandler(props) {
  const { memberIndex } = props;

  const { permissions, members } = useTeamProfile();
  const { canKickAdmin, canKickMember } = permissions;

  const renderedButton = useMemo(() => {
    const member = members[memberIndex];

    if (!member) return null;

    const { role } = member;

    if (role === 'owner') return null;

    if (role === 'admin' && !canKickAdmin) return null;

    if (role === 'member' && !canKickMember) return null;

    return <TeamKickButton memberIndex={memberIndex} />;
  }, [members]);

  return renderedButton;
}

TeamKickHandler.propTypes = {
  memberIndex: PropTypes.number.isRequired,
};

export default TeamKickHandler;
