import React, { useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';
import { useTournament } from 'components/tournaments/TournamentPage';

// Components
import { Link } from 'react-router-dom';

// Constants
import { TOURNAMENT_TOS } from 'constants/routes';

function TournamentPublishButton() {
  const { publicId, setCurrentPhase } = useTournament();

  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { t } = useTranslation(['general']);
  const { post } = useAPI();

  const publishTournament = async () => {
    try {
      const phase = await post(`/tournaments/phase/next/${publicId}`);
      setCurrentPhase(phase);
    } catch (err) {
      setStatus(t('errors:server_error'));
    }
  };

  return (
    <section className="my-6">
      <div className="is-flex has-content-centered">
        <div className="box has-fullwidth has-max-width-700 p-5">
          <div className="is-flex">
            <div>
              <button
                type="button"
                className="cleanButton is-size-3 p-0 has-text-grey"
                onClick={() => setHasAcceptedTerms(!hasAcceptedTerms)}
              >
                {
                  hasAcceptedTerms ? (<i className="fas fa-check-square" />) : (<i className="fas fa-square" />)
                }
              </button>
            </div>

            <div className="mx-3">
              <span className="is-size-6">
                {t('general:tournament_disclaimer_intro')}
                <Link
                  to={TOURNAMENT_TOS}
                  className="has-text-playerboard mx-1"
                >
                  {t('general:special_regulations_for_tournaments')}
                </Link>

              </span>
              <p className="mt-3">
                {t('general:tournament_disclaimer_end')}
              </p>
            </div>
          </div>

        </div>
      </div>

      <div className="has-text-centered mt-6">
        <button
          className="button is-size-2 has-background-playerboard has-text-weight-normal"
          type="button"
          disabled={disabled || !hasAcceptedTerms}
          onClick={publishTournament}
        >
          {t('general:publish')}
        </button>
      </div>

    </section>
  );
}

export default TournamentPublishButton;
