import React, { useMemo } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';

// Components
import BasicSeedingDisplay from './BasicSeedingDisplay';
import TournamentSeedingEditor from './TournamentSeedingEditor';

const TournamentSeeding = () => {
  const { staffPermissions } = useTournament();
  const { canManageBracket } = staffPermissions;

  const renderedContent = useMemo(() => {
    if (!canManageBracket) return <BasicSeedingDisplay />;

    return <TournamentSeedingEditor />;
  }, [canManageBracket]);

  return renderedContent;
};

export default TournamentSeeding;
