import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

// Hooks
import { useTranslation } from 'react-i18next';
import { useOrgaPage } from 'components/orgas/OrgaPage';
import useAPI from 'components/hooks/useAPI';

// Components
import Dropdown from 'components/utils/Dropdown';

// Constants
import { ROLES } from 'constants/orgas';

function SetPermissionDropdown(props) {
  const {
    playerId, role, disabled, setDisabled,
  } = props;

  const { t } = useTranslation(['general']);
  const { post } = useAPI();

  const {
    permissions, publicId, memberToSetRoles,

    setPermissionRole, setMemberToSetRoles,
  } = useOrgaPage();
  const { canSetPermissions } = permissions;

  const _setPermissionRole = async (_role) => {
    try {
      setDisabled(true);

      const newRole = _role.value;

      const data = {
        orgaId: publicId,
        playerId,
        role: newRole,
      };

      await post('/orgas/members/role/', data);

      setPermissionRole(playerId, newRole);
      setMemberToSetRoles({
        ...memberToSetRoles,
        role: newRole,
      });

      stats.push(['trackEvent', 'Orga Profile', 'Set permission role']);

      setDisabled(false);
    } catch (e) {
      console.log(e);
      setDisabled(false);
    }
  };

  const availablePermissionRoles = useMemo(() => {
    const allRoles = ROLES.map((_role) => ({
      value: _role.type,
      label: t(`general:${_role.type}`),
    }));

    return allRoles.filter(({ value }) => value !== 'owner');
  }, []);

  const selectedRole = useMemo(() => {
    if (role === 'owner') {
      return {
        value: 'owner',
        label: t('general:owner'),
      };
    }

    const _role = availablePermissionRoles.find(({ value }) => value === role);

    return _role;
  }, [role, availablePermissionRoles]);

  // TODO: if the currently looked at player is the owner, show different dropdown
  if (!canSetPermissions || role === 'owner') return null;

  return (
    <div>
      <p
        className=""
      >
        {t('general:permission')}
      </p>
      <div className="columns is-multiline">
        <div className="column px-0">
          <Dropdown
            items={availablePermissionRoles}
            onSelect={_setPermissionRole}
            value={selectedRole}
            disabled={disabled}
          />
        </div>

        <div className="column px-0 is-3 has-min-width-200 is-flex has-content-centered">
          {
            selectedRole.value && (
              <p className="is-size-7 has-border-grey p-1 br-5">
                { selectedRole.label }
              </p>
            )
          }
        </div>
      </div>
    </div>

  );
}

SetPermissionDropdown.propTypes = {
  playerId: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
};

export default SetPermissionDropdown;
