import React, { useMemo } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';

// Components
import TournamentPublishButton from './TournamentPublishButton';

const TournamentPublishHandler = () => {
  const { currentPhase } = useTournament();

  const renderedPublishButton = useMemo(() => {
    if (currentPhase !== 'draft') return null;

    return <TournamentPublishButton />;
  }, [currentPhase]);
  return renderedPublishButton;
};

export default TournamentPublishHandler;
