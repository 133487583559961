import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import { MAX_DESCRIPTION_LENGTH } from 'constants/player';

function DescriptionEditor(props) {
  const { description, setDescription } = props;

  const { t } = useTranslation(['general', 'errors']);

  const [status, setStatus] = useState('');

  const _setDescription = (_description) => {
    setStatus('');

    if (_description.length >= MAX_DESCRIPTION_LENGTH) {
      setStatus(`${t('errors:max_length_reached_error')}!`);
      return;
    }

    setDescription(_description);
  };

  return (
    <div className="">
      <label className="label has-text-left is-size-7">
        {t('general:description')}
      </label>
      <textarea
        onChange={(event) => _setDescription(event.target.value)}
        type="text"
        value={description}
        rows={2}
        placeholder={t('general:description')}
        onKeyPress={(e) => {
          if (e.key === 'Enter') e.preventDefault();
        }}
        className="textarea br-5 no-shadow has-border p-3 has-fixed-size is-size-6 is-paddingless is-inline has-fullheight has-fullwidth"
      />
      <p
        className="my-2 has-text-weight-semibold is-size-7"
      >
        { status }
      </p>
    </div>
  );
}

DescriptionEditor.propTypes = {
  description: PropTypes.string.isRequired,
  setDescription: PropTypes.func.isRequired,
};

export default DescriptionEditor;
