import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import ContactField from 'components/utils/ContactField';

function SteamContact(props) {
  const { value, setValue } = props;
  const [error, setError] = useState('');

  const { t } = useTranslation(['contacts', 'validation']);

  const setAndValidate = (code) => {
    /* const regex = /^[0-9]*$/; */

    setError('');

    if (code.length > 64) {
      setError(t('validation:enter_valid_steam_friend_code_error'));
      return;
    }

    /* if (regex.test(code) === false) {
      setError('Nur Zahlen sind für den Freundescode erlaubt!');
    } */

    setValue('steam', code);
  };

  return (
    <ContactField
      icon="fab fa-steam"
      value={value}
      onChange={setAndValidate}
      error={error}
      label={t('contacts:steam_friend_code')}
      placeholder="12345678"
    />
  );
}

SteamContact.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
};

SteamContact.defaultProps = {
  value: '',
};

export default SteamContact;
