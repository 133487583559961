import React, { useState, useEffect } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import AnnouncementDetailView from './AnnouncementDetailView';

function AnnouncementNotification(props) {
  const { notification } = props;

  const [isOpen, setOpen] = useState(false);

  const {
    read, createdAt, link, message, publicId, title,
  } = notification;

  return (

    <div>
      <div>

        <button
          type="button"
          onClick={() => setOpen(!isOpen)}
          className="cleanButton has-fullwidth has-text-standard p-0"
        >

          <div className="columns">
            <div className="column px-0 is-3 is-flex has-content-centered">
              <i className="fas fa-bullhorn is-size-3 has-text-grey" />
            </div>

            <div className="column px-0 is-9 has-text-left">
              <div className="has-fullwidth">
                <p
                  className="is-size-6 has-line-height-full has-text-white"
                >
                  { title }
                </p>
                <p className="mt-2 has-text-grey-light has-line-height-full">
                  { message }
                </p>
              </div>

            </div>
          </div>

        </button>

        {
          isOpen && (
            <AnnouncementDetailView notification={notification} />
          )
        }
      </div>
    </div>

  );
}

AnnouncementNotification.propTypes = {
  notification: PropTypes.shape({
    read: PropTypes.bool,
    createdAt: PropTypes.string,
    link: PropTypes.string,
    message: PropTypes.string,
    publicId: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default AnnouncementNotification;
