import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import store from './store';
import { LOGIN } from './constants/routes';

import './styles/main.scss';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(

  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin + LOGIN,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    }}
    cacheLocation="localstorage"
  >
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>,

);
