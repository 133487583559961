import React, { useState, useEffect, useRef } from 'react';

// Libaries
import PropTypes from 'prop-types';

// Hooks
import { useDropzone } from 'react-dropzone';
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';

// Assets
import defaultAvatar from 'assets/images/profile/default_avatar_quadratic.png';

// Components
import AvatarEditor from 'react-avatar-editor';

/**
 * Drag & Drop Zone to upload images
 */
function OrgaCardPicUploader(props) {
  const {
    publicId, cardPic, setCardPic, disabled, setDisabled,
  } = props;

  const [imgUrl, setImgUrl] = useState(defaultAvatar);

  const [error, setError] = useState('');

  const ref = useRef();

  const { t } = useTranslation(['general', 'errors']);
  const { post } = useAPI();

  useEffect(() => {
    if (cardPic) {
      setImgUrl(`${process.env.REACT_APP_CDN_URL}/images/orgas/card_pictures/${cardPic}`);
    }

    return () => {
      URL.revokeObjectURL(imgUrl);
    };
  }, [cardPic]);

  const getCroppedImage = () => {
    if (!ref || !ref.current) {
      throw new Error('Canvas not ready');
    }

    const canvas = ref.current.getImage();

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        // returning an error
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }

        resolve(blob);
      }, 'image/png');
    });
  };

  const saveCardPic = async () => {
    try {
      setError('');
      setDisabled(true);

      const image = await getCroppedImage();

      const formData = new FormData();
      formData.append('cardPic', image);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      };

      const urlToPic = await post(`/orgas/picture/${publicId}`, formData, config);
      setCardPic(urlToPic);

      setDisabled(false);
    } catch (e) {
      setDisabled(false);

      console.log(e);

      setError(t('errors:save_profile_picture_error'));
    }
  };

  const _setCardPic = (file) => {
    // if file has been rejected, data will be empty
    if (file.length === 0) return;

    setError('');

    URL.revokeObjectURL(imgUrl);
    const url = URL.createObjectURL(file[0]);
    setImgUrl(url);
  };

  const onDropRejected = () => {
    setError(t('errors:invalid_profile_picture_error'));
  };

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
    },
    noDrag: true,
    onDrop: _setCardPic,
    maxFiles: 1,
    // max 2 MB
    maxSize: 2097152,
    onDropRejected,
  });

  const hasUploadedImage = imgUrl.startsWith('blob:');

  return (
    <div
      className="control has-fullwidth"
    >
      <div className="is-flex has-content-centered">
        <div className="has-border-grey is-inline-flex has-overflow-hidden p-0 br-10">
          {
          hasUploadedImage
            ? (
              <AvatarEditor
                image={imgUrl}
                width={200}
                height={200}
                disabled={!hasUploadedImage}
                border={hasUploadedImage ? 10 : 0}
                color={[205, 205, 205, 0.5]} // RGBA
                scale={1}
                rotate={0}
                ref={ref}
              />
            )
            : <img src={imgUrl} alt="" style={{ width: '200px', height: '200px' }} />
        }
        </div>
      </div>

      <div {...getRootProps({
        className: 'has-cursor-pointer has-text-centered',
      })}
      >

        <input {...getInputProps()} />

        <div className="has-text-centered has-fullwidth">
          {
            hasUploadedImage
              ? (
                <button
                  type="button"
                  className="cleanButton grow_small has-text-white has-text-weight-semibold mt-4"
                  style={{ backgroundColor: '#086842' }}
                  disabled={disabled}
                >
                  {t('general:select_different_profile_picture')}
                </button>
              )
              : (
                <button
                  type="button"
                  className="button is-size-5-desktop has-background-primary grow_small has-text-weight-semibold mt-5 boxshadow"
                  style={{ backgroundColor: '#086842' }}
                  disabled={disabled}
                >
                  {t('general:select_profile_picture')}
                </button>
              )
            }
        </div>
      </div>
      {
        hasUploadedImage
          && (
          <div className="has-fullwidth has-text-centered">
            <button
              type="button"
              className="button is-size-5-desktop has-background-primary grow_small has-text-weight-semibold mt-5 boxshadow"
              style={{ backgroundColor: '#086842' }}
              disabled={disabled}
              onClick={saveCardPic}
            >
              {t('general:save')}
            </button>
          </div>
          )
      }
      {
        error && (
          <div className="has-text-centered">
            <p className=" has-text-white mt-4 is-size-7">
              {error}
            </p>
          </div>
        )
      }
    </div>
  );
}

OrgaCardPicUploader.propTypes = {
  disabled: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
  cardPic: PropTypes.string.isRequired,
  setCardPic: PropTypes.func.isRequired,
  publicId: PropTypes.string.isRequired,
};

export default OrgaCardPicUploader;
