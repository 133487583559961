import React, {
  useEffect, useMemo, useState, createContext, useContext,
} from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

// Components
import TournamentParticipantHandler from './TournamentParticipantHandler';
import TournamentParticipantBanPopup from './TournamentParticipantBanPopup';
import TournamentParticipantKickPopup from './TournamentParticipantKickPopup';
import TournamentParticipantDisqualifyPopup from './TournamentParticipantDisqualifyPopup';

const TournamentsParticipantsListContext = createContext();

// This component is for not logged in users, logged in users, participants and admins.
// Depending on the user, the list will be different.
// Admins will load ALL available participants, while normal users only see up to 20 participants at once
function TournamentsParticipantsList() {
  const { isAuthenticated } = useAuth0();
  const {
    currentPhase, publicId, staffPermissions,
    participant, participants,
    setParticipants, removeParticipant,
  } = useTournament();

  const [participantToKick, setParticipantToKick] = useState();
  const [participantToBan, setParticipantToBan] = useState();
  const [participantToDisqualify, setParticipantToDisqualify] = useState();

  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { post } = useAPI();
  const { t } = useTranslation(['general']);

  const loadParticipants = async (loadNew = false) => {
    try {
      setDisabled(true);
      setStatus('');

      let joinedAt = '';

      if (!loadNew) {
        joinedAt = participants.length !== 0 ? participants[participants.length - 1].joinedAt : '';
      }

      const data = {
        joinedAt,
      };

      let _participants = [];

      if (!isAuthenticated) {
        _participants = await post(`/tournaments/participants/list/public/${publicId}`, data);
      } else {
        _participants = await post(`/tournaments/participants/list/${publicId}`, data);
      }

      // we load max 20 participants at a time
      if (_participants.length === 20) {
        setShowLoadMoreButton(true);
      } else {
        setShowLoadMoreButton(false);
      }

      if (loadNew) {
        setParticipants(_participants);
      } else {
        setParticipants((prevState) => [...prevState, ..._participants]);
      }

      setDisabled(false);
    } catch (e) {
      console.log(e);
      setStatus(t('general:data_loading_error'));
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (currentPhase === 'draft' || currentPhase === 'upcoming') return;

    // if (participants.length !== 0) return;

    loadParticipants(true);
  }, [currentPhase]);

  // the user if they are registered
  const renderedParticipant = useMemo(() => {
    if (!participant.publicId) return null;

    return (
      <div className="">
        <p className="mb-3">
          {t('general:you_are_registered_as')}
        </p>
        <TournamentParticipantHandler
          participant={participant}
        />
      </div>
    );
  }, [participant, t]);

  const renderedParticipants = useMemo(() => participants.map((_participant, index) => (
    <TournamentParticipantHandler
      key={index}
      participant={_participant}
    />
  )), [participants]);

  const renderPopups = () => {
    if (currentPhase === 'seeding' || currentPhase === 'finished') return null;

    if (currentPhase === 'in_progress') {
      return <TournamentParticipantDisqualifyPopup />;
    }

    return (
      <>
        <TournamentParticipantKickPopup />
        <TournamentParticipantBanPopup />
      </>
    );
  };

  const values = useMemo(() => ({
    currentPhase,

    publicId,
    staffPermissions,

    participantToKick,
    setParticipantToKick,

    participantToBan,
    setParticipantToBan,

    participantToDisqualify,
    setParticipantToDisqualify,

    removeParticipant,
  }), [
    currentPhase,
    publicId,
    staffPermissions,
    participantToKick,
    participantToBan,
    participantToDisqualify,
    removeParticipant,
  ]);

  return (
    <TournamentsParticipantsListContext.Provider
      value={values}
    >
      <div className="">

        { renderPopups() }

        { renderedParticipant }
        <div className="mt-6">
          { renderedParticipants.length !== 0 && (
            <p className="mb-3">
              {t('general:participants')}
            </p>
          )}
          <div className="columns is-multiline">
            {renderedParticipants}
          </div>
        </div>

        {showLoadMoreButton && (
        <button
          type="button"
          className={`button is-primary has-fullwidth mt-5 ${disabled ? 'is-loading' : ''}`}
          onClick={() => loadParticipants()}
          disabled={disabled}
        >
          {t('general:load_more')}
        </button>
        )}

        {status && (
        <div className="has-text-weight-bold mt-5">
          {status}
        </div>
        )}
      </div>
    </TournamentsParticipantsListContext.Provider>
  );
}

export const useTournamentsParticipantsList = () => {
  const data = useContext(TournamentsParticipantsListContext);

  return data;
};

export default TournamentsParticipantsList;
