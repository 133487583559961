import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import { HOT_SCRIMPS } from 'constants/routes';

// Components
import { Link } from 'react-router-dom';
import DataDeletedNotification from '../DataDeletedNotification';

function HotscrimpCreationNotification(props) {
  const { notification } = props;
  const {
    hotscrimpId, dataDeleted, playerName,
  } = notification;

  const { t } = useTranslation(['general']);

  if (dataDeleted) {
    return (
      <DataDeletedNotification
        icon="fas fa-gamepad"
        title={t('hotscrimps:created_hotscrimp_got_deleted')}
        message=""
      />
    );
  }

  return (
    <Link
      to={`${HOT_SCRIMPS}/${hotscrimpId}`}
    >
      <div>
        <div>

          <div className="columns">
            <div className="column px-0 is-3 is-flex has-content-centered">
              <i className="fas fa-gamepad is-size-3 has-text-grey" />
            </div>

            <div className="column px-0 is-9 has-text-left">
              <div
                className="has-fullwidth"
              >
                <p
                  className="is-size-6 has-line-height-full has-text-white"
                >
                  { t('hotscrimps:hotscrimp_created') }
                </p>
                <p className="mt-2 has-text-grey-light has-line-height-full">
                  { t('hotscrimps:hotscrimp_created_message', { playerName }) }
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>

    </Link>
  );
}

HotscrimpCreationNotification.propTypes = {
  notification: PropTypes.shape({
    hotscrimpId: PropTypes.string,
    dataDeleted: PropTypes.bool,
    playerName: PropTypes.string,
  }).isRequired,
};

export default HotscrimpCreationNotification;
