import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import { getRankFromGameMode } from 'constants/games';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';

// Components
import CardPicDisplay from 'components/utils/CardPicDisplay';
import Popup from 'reactjs-popup';

function ParticipantDisplay(props) {
  const {
    participant, showBorder, isActive, isOver, showMmr,
  } = props;

  const { t } = useTranslation(['general']);
  const { game } = useTournament();

  const renderedMmr = useMemo(() => {
    if (!showMmr) return null;

    const rank = getRankFromGameMode(game.tag, game.mode, participant.mmr, 'mmr');

    if (!rank || !rank.img) return null;

    if (game.tag === 'RL') {
      // show mmr number on hover
      return (
        <div className="has-margin-left-auto">
          <Popup
            trigger={(
              <img
                src={rank.img}
                alt="rank"
                className="image has-width-24 has-height-24"
              />
            )}
            position="top center"
            on="hover"
          >
            <div className="box has-background-dark p-2 is-flex">
              <p className="has-text-centered">
                {`${t('general:mmr')}: ${participant.mmr}`}
              </p>
            </div>
          </Popup>
        </div>
      );
    }

    return (
      <div className="has-margin-left-auto">
        <img
          src={rank.img}
          alt="rank"
          className="image has-width-24 has-height-24"
        />
      </div>
    );
  }, [showMmr, participant, game.tag, game.mode]);

  return (
    <div
      className={`columns is-relative is-mobile dont-select-text ${isOver ? 'has-background-grey-darker' : 'has-background-dark'} ${isActive ? 'has-cursor-grab' : ''}`}
      style={{ position: 'fixed' }}
    >
      {
        isActive && (
        <div
          className="column is-narrow is-flex has-content-centered"
        >
          <i
            className="fas fa-bars"
          />
        </div>
        )
        }
      <div className="column p-0">
        <div
          key={participant.publicId}
          className={`columns has-height-48 ${showBorder ? 'has-border-bottom-grey-dark' : ''}`}
        >
          <div className="column is-narrow has-width-30 p-0 is-flex has-content-centered">
            <CardPicDisplay
              img={participant.cardPic}
              url={participant.participantType === 'team' ? '/images/teams/card_pictures/' : undefined}
            />
          </div>
          <div className="column p-0 is-flex has-content-centered-vertically px-3">
            <p>
              {`${participant.name}`}
            </p>
            { renderedMmr }

          </div>
        </div>
      </div>
    </div>

  );
}

ParticipantDisplay.propTypes = {
  participant: PropTypes.shape({
    cardPic: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  showBorder: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  isOver: PropTypes.bool.isRequired,
  showMmr: PropTypes.bool,
};

ParticipantDisplay.defaultProps = {
  showMmr: false,
};

export default ParticipantDisplay;
