import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
// Libraries
import stats from 'analytics/matomo';
import PropTypes from 'prop-types';

// Assets
import onboardingImg from 'assets/images/base/onboarding_player_picture.png';

// Components
import ImageUploader from './ImageUploader';

function PlayerImageEditor(props) {
  const { onDone } = props;

  const { t } = useTranslation();

  return (

    <div className="box p-0 has-text-centered has-fullheight">

      <div className="columns has-fullheight is-multiline">

        <div
          className="column is-5-desktop has-min-width-300 is-5-tablet p-0"
        >
          <div
            className="has-fullheight has-text-left"
            style={{ backgroundImage: `url(${onboardingImg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
          >
            <div className="p-5">
              <h1
                className="is-size-1-dekstop is-size-2-tablet has-text-weight-semibold"
                style={{ color: '#1189C8' }}
              >
                {t('general:onboarding')}
              </h1>
              <p
                className="is-size-1-desktop is-size-2-desktop has-text-grey"
              >
                {t('general:player_picture')}
              </p>
            </div>
          </div>
        </div>

        <div className="column p-0">
          <div className="field p-6 is-flex flex-direction-column has-fullheight is-relative">

            <div
              style={{
                position: 'absolute',
                width: '0px',
                height: '0px',
                right: '0px',
                top: '0px',

                // borderRadius: '5px',

                borderTop: '75px solid #1189C8',
                borderLeft: '75px solid transparent',

              }}
            />

            <div>
              <h2
                className="has-text-weight-normal is-size-3-desktop"
              >
                {`${t('general:choose_your_player_picture')}`}
              </h2>
              <p className="mt-5 has-text-grey-lighter">
                {`${t('general:player_picture_upload_or_skip')}.`}
              </p>
            </div>

            <div className="has-margin-auto">
              <ImageUploader onUploaded={onDone} />
            </div>

            <div className="has-text-centered">
              <button
                type="button"
                className="cleanButton has-text-white has-text-weight-semibold mt-5"
                onClick={onDone}
              >
                {t('general:skip')}
              </button>
            </div>

          </div>
        </div>

      </div>

    </div>

  );
}

PlayerImageEditor.propTypes = {
  onDone: PropTypes.func.isRequired,
};

export default PlayerImageEditor;
