import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import Popup from 'reactjs-popup';
import CopyButtonSimple from '../CopyButtonSimple';
import CopyInfoButton from './CopyInfoButton';
import TwitterButton from './TwitterButton';

function SharePopup(props) {
  const {
    text, url, shareOptions, indicator,
  } = props;

  const { t } = useTranslation(['general']);

  const renderedShareOptions = useMemo(() => {
    if (!shareOptions) return null;

    const options = shareOptions.map((option, index) => {
      let shareButton = null;
      switch (option) {
        case 'info':
          shareButton = <CopyInfoButton text={text} url={url} />;
          break;
        case 'twitter':
          shareButton = <TwitterButton text={text} url={url} />;
          break;
        /* case 'facebook':
          shareButton = <FacebookButton text={text} url={url} />;
          break */
        default:
          break;
      }

      return (
        <div className="column is-3" key={index}>
          { shareButton }
        </div>
      );
    });

    return (
      <div className="columns is-centered is-multiline is-mobile">
        {options}
      </div>
    );
  }, [text, url, shareOptions]);

  return (
    <Popup
      trigger={(
        <div>
          <button
            type="button"
            className="button has-no-background grow_small has-text-grey has-border-dashed-grey"
          >
            <i className="fas fa-share-from-square" />
            <p
              className="ml-2 is-si"
            >
              {t('general:share')}
            </p>
          </button>

        </div>
        )}
      position="top center"
      keepTooltipInside="#root"
      repositionOnResize
      modal
      closeOnDocumentClick
      closeOnEscape
    >
      {
        (close) => (
          <div className="box p-0 is-max-fullheight has-max-width-400">
            <div
              className="has-background-primary has-text-centered p-4 pb-6 is-relative br-5"
            >
              <div className="has-text-right">
                <button
                  type="button"
                  className=" cleanButton has-text-white"
                  onClick={close}
                >
                  <i className="fas fa-times" />
                </button>
              </div>
              <p
                className="has-text-weight-semibold is-size-3 mt-"
              >
                {t('general:share')}
              </p>

              <div
                className="br-100 has-background-primary is-absolute is-flex has-content-centered"
                style={{
                  left: 'calc(50% - 45px)', width: '90px', height: '85px',
                }}
              >
                <span className="is-size-3 br-5 fas fa-share-from-square" />
              </div>

            </div>

            <div className="p-5 has-background-black-gradient pb-6 has-text-centered">

              <div className="mt-6">
                { renderedShareOptions }
              </div>

              <div className="control mt-5 has-text-left">

                <p className="mb-2">
                  {t('general:copy_link')}
                </p>
                <div className="has-fullwidth has-text-centered has-min-width-250">
                  <div className="has-border-dashed-grey has-border-dark p-3 is-flex has-content-centered br-5">
                    <i className="fas fa-link mr-2" />
                    <p
                      className="abbreviate-text has-text-standard pr-3"
                    >
                      {url}
                    </p>
                    <CopyButtonSimple
                      text={url}
                      className="button is-primary"
                    >
                      <i className="fas fa-copy" />
                    </CopyButtonSimple>
                  </div>
                </div>
              </div>

            </div>
          </div>
        )
        }
    </Popup>
  );
}

SharePopup.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  shareOptions: PropTypes.arrayOf(PropTypes.string),
  indicator: PropTypes.string,
};

SharePopup.defaultProps = {
  text: '',
  url: '',
  shareOptions: ['info', 'twitter'],
  indicator: '',
};

export default SharePopup;
