import React, { useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import Popup from 'reactjs-popup';
import TournamentCreator from './TournamentCreator';

function TournamentCreatorPopup() {
  const { t } = useTranslation(['general', 'validation', 'errors']);

  const [disabled, setDisabled] = useState(false);

  return (
    <div className="has-text-right">
      <Popup
        trigger={(
          <button
            type="button"
            className="button has-no-background grow_small has-text-grey has-border-dashed-grey"
          >
            <i className="fas fa-shield mr-2" />
            {t('general:create_tournament')}
          </button>
          )}
        position="top center"
        keepTooltipInside="#root"
        repositionOnResize
        closeOnDocumentClick={!disabled}
        closeOnEscape={false}
        modal

      >
        {
            (close) => (
              <TournamentCreator
                disabled={disabled}
                setDisabled={setDisabled}
                close={close}
              />
            )
           }
      </Popup>
    </div>
  );
}

export default TournamentCreatorPopup;
