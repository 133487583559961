import React from 'react';

// Hooks
import { useHotscrimp } from 'components/HotScrimps/HotscrimpsDisplayPage';
import { useTranslation } from 'react-i18next';

// Components
import HotscrimpCancelPopup from './HotscrimpCancelPopup';
import HotscrimpDeleteHistoricPopup from './HotscrimpDeleteHistoricPopup';

function HotscrimpStatusHandler() {
  const { permissions, status } = useHotscrimp();
  const { canCancelHotscrimp } = permissions;

  const { t } = useTranslation(['hotscrimp']);

  const showDeleteHistoricPopup = canCancelHotscrimp && (status === 'finished' || status === 'cancelled');
  const showCancelPopup = canCancelHotscrimp && status !== 'finished' && status !== 'cancelled';

  return (
    <div className="my-3">
      <p className="is-size-5">
        {`${t('general:status')}: ${t(`general:${status}`)}`}
      </p>
      {
        showCancelPopup && (
        <HotscrimpCancelPopup />
        )
      }
      {
        showDeleteHistoricPopup && (
        <HotscrimpDeleteHistoricPopup />
        )
      }
    </div>
  );
}

export default HotscrimpStatusHandler;
