import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePlayerList } from 'components/Players/PlayerListProvider';

// Components
import { getSelectedGame } from 'constants/games';

// Assets
import defaultAvatar from 'assets/images/profile/default_avatar.png';

// Styles
import styles from './styles.module.scss';

const PlayerCard = React.forwardRef(({ open, ...props }, ref) => {
  const { index } = props;

  const { timezone, language } = useSelector((state) => state.session);
  const { player } = useSelector((state) => state.player);

  const { t } = useTranslation(['general', 'tags']);

  const { players } = usePlayerList();

  const playerData = index === -1 ? player : players[index];
  if (!playerData) return null;

  const {
    cardPic, name, languages, game, platform, tags, nationality, engagement,
  } = playerData;

  const getGlowBorder = () => {
    if (index === -1) return 'has-playerboard-userentry-border';

    return 'has-border-primary-glow';

    if (engagement === 'competitive') return 'has-playerboard-competitive-border';

    return 'has-playerboard-just4fun-border';
  };

  const renderCard = () => {
    // const locale = getCurrentLocale();
    // const renderedPostedAt = DateTime.fromISO(postedAt).setZone(timezone).setLocale(locale).toRelative();

    const urlToPic = cardPic ? `${process.env.REACT_APP_CDN_URL}/images/card_pictures/${cardPic}` : defaultAvatar;

    const glowBorder = getGlowBorder();

    return (
      <button
        type="button"
        className="cleanButton is-size-6 has-text-left grow p-2"
      >
        <div
          id={styles.card}
          className={`br-10 has-text-white has-width-180 has-height-300 has-overflow-hidden is-flex flex-direction-column ${glowBorder}`}
          style={{ backgroundImage: `url(${urlToPic})` }}
        >

          <div
            className="is-relative pt-3 pb-1 has-margin-top-auto has-background-blur"
            style={{ borderBottomRightRadius: '5px' }}
          >
            <div
              className="mt-2 is-absolute has-fullwidth"
              style={{ top: '-20px' }}
            >
              <div className="mx-3 is-flex has-content-centered-vertically">
                <span
                  className={`is-size-4 br-5 boxshadow is-block has-fullheight fi fi-${nationality}`}
                />
              </div>
            </div>

            <div className="py-2 px-3 mt-1">
              <div className="is-flex has-content-centered-vertically">
                <p
                  className="is-size-7 has-text-weight-semibold mr-2 mb-1 has-text-standard"
                >
                  { name }
                </p>

              </div>
            </div>

          </div>

        </div>
      </button>
    );
  };

  return (
    <div ref={ref} {...props} className="">
      { renderCard() }
    </div>
  );
});

PlayerCard.propTypes = {
  index: PropTypes.number.isRequired,
};

export default PlayerCard;
