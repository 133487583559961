import React, { useEffect, useMemo, useState } from 'react';

// Libaries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import { ORGA_TYPES } from 'constants/orgas';

// Components
import Dropdown from 'components/utils/Dropdown';

function OrgaSubTypeSelector(props) {
  const { t } = useTranslation(['general', 'validation']);

  const {
    orgaType, orgaSubType, setOrgaSubType, disabled,
  } = props;

  useEffect(() => {
    setOrgaSubType('');
  }, [orgaType]);

  const availableSubTypes = useMemo(() => {
    if (!orgaType || !ORGA_TYPES[orgaType]) return [];

    const categories = ORGA_TYPES[orgaType].subcategories;

    return categories.map((type) => ({
      label: t(`general:${type}`),
      value: type,
    }));
  }, [orgaType]);

  const _setOrgaSubType = (_orgaSubType) => {
    setOrgaSubType(_orgaSubType.value);
  };

  const selectedSubType = useMemo(() => availableSubTypes.find((type) => type.value === orgaSubType), [orgaSubType, availableSubTypes]);

  return (
    <div>
      <div className="">
        <div className="is-inline-block has-min-width-250 has-text-left">
          <label className="label has-text-weight-normal has-text-grey is-size-5">
            {t('general:subtype')}
          </label>
          <div className="control">
            <Dropdown
              items={availableSubTypes}
              onSelect={_setOrgaSubType}
              disabled={disabled || !availableSubTypes.length}
              value={selectedSubType}
            />
          </div>
        </div>
      </div>
    </div>

  );
}

OrgaSubTypeSelector.propTypes = {
  orgaType: PropTypes.string.isRequired,
  orgaSubType: PropTypes.string.isRequired,
  setOrgaSubType: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default OrgaSubTypeSelector;
