import React, { useMemo } from 'react';

// Libraries
/* import de from 'date-fns/locale/de'; */
import stats from 'analytics/matomo';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import DatePicker from 'react-datepicker';
import { getCurrentLocale } from 'locales/i18n';

// TODO: when we have more languages, adapt it to the current locale
/* registerLocale(locale, de); */

function TimeFilter(props) {
  const { isLoading, date, setDateFilter } = props;

  const {
    timezone,
  } = useSelector((state) => state.session);

  const { t } = useTranslation(['general']);

  const dispatch = useDispatch();

  const _setDateFilter = (_date) => {
    _date = DateTime.fromJSDate(_date, { locale: getCurrentLocale() });

    // save the date with the time
    const {
      hour, minute, day, month, year,
    } = _date;

    // THEN, set the zone
    _date = _date.setZone(timezone);

    // and copy the values back
    _date = _date.set({
      hour, minute, day, month, year,
    });

    const dateAsISO = _date.toJSDate().toISOString();

    if (dateAsISO === date) return;

    stats.push(['trackEvent', 'Hot Scrimps Filter', 'Set time filter']);

    setDateFilter(dateAsISO);
  };

  const filterStartTime = (startTime) => {
    let now = DateTime.now().setZone(timezone);

    const { hour, minute } = now;

    now = now.toJSDate();
    now.setHours(hour);
    now.setMinutes(minute);

    return startTime.getTime() > now.getTime();
  };

  const resetTime = () => {
    // get current date
    const now = DateTime.now().setZone(timezone);
    // get filter date
    const currentDate = DateTime.fromISO(date, { locale: getCurrentLocale() }).setZone(timezone);

    // if the date is for today, just completely reset the date filter
    if (currentDate.hasSame(now, 'day')) {
      dispatch(setDateFilter(''));
      return;
    }

    // if not, set the filter to the current day but with time at midnight
    let resetDate = currentDate.set({
      hour: 0, minute: 0,
    });

    resetDate = resetDate.toJSDate().toISOString();

    setDateFilter(resetDate);

    stats.push(['trackEvent', 'Hot Scrimps Filter', 'Reset time filter']);
  };

  // get a json date, extract its time values and convert it to the timezone
  // then, set the time values back
  const selectedDate = useMemo(() => {
    if (!date) return undefined;

    const timedzonedDate = DateTime.fromISO(date, { zone: timezone });
    const {
      hour, minute, day, month, year,
    } = timedzonedDate;

    const _date = timedzonedDate.toJSDate();
    _date.setHours(hour);
    _date.setMinutes(minute);
    _date.setDate(day);
    _date.setMonth(month - 1);
    _date.setFullYear(year);

    return _date;
  }, [date, timezone]);

  return (

    <div className="has-max-width-300">
      <div className="is-flex">
        <label className="label has-text-weight-semibold is-size-7 mb-1">
          {t('general:time')}
        </label>
        {
          date !== ''
          && (
          <button
            type="button"
            className="cleanButton ml-2"
            onClick={resetTime}
          >
            <i className="fas fa-times has-text-danger" />
          </button>
          )
      }
      </div>
      <div className="control">
        <DatePicker
          selected={selectedDate}
          onChange={(_date) => {
            _setDateFilter(_date);
          }}
          showTimeSelect
          showTimeSelectOnly
          disabledKeyboardNavigation
          timeIntervals={15}
          timeCaption={t('general:time')}
          dateFormat="p"
          timeFormat="p"
          disabled={isLoading}
          filterTime={filterStartTime}
          className="input has-text-weight-semibold has-max-width-100 has-text-centered"
        />

      </div>

    </div>

  );
}

TimeFilter.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired,
  setDateFilter: PropTypes.func.isRequired,
};

export default TimeFilter;
