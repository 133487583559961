import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import getCardTypeLabel from 'utils/getCardTypeLabel';

// Components
import Dropdown from 'components/utils/Dropdown';

// Constants
import { TYPE_PLAYER, TYPE_TEAM } from 'constants/cards';

const TYPES = [
  { label: 'Spieler', value: TYPE_PLAYER },
  { label: 'Teams', value: TYPE_TEAM },
];

function SearchesSelector(props) {
  const { searches, setSearches } = props;

  const currentValue = useMemo(() => {
    if (searches === TYPE_PLAYER) return 'Spieler';

    return 'Teams';
  }, [searches]);

  const _setSearching = (newType) => {
    setSearches(newType.value);
  };

  return (
    <div className="field">
      <label className="label">
        Ich suche nach
      </label>
      <div className="control">
        <Dropdown
          items={TYPES}
          onSelect={_setSearching}
          value={currentValue}
        />
      </div>
    </div>
  );
}

SearchesSelector.propTypes = {
  searches: PropTypes.string.isRequired,
  setSearches: PropTypes.func.isRequired,
};

export default SearchesSelector;
