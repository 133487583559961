import React from 'react';

// Hooks
import { useTeamProfile } from 'components/Teams/TeamProfile';

// Components
import LeaveTeamButton from './LeaveTeamButton';

function TeamLeaveHandler() {
  const { permissions } = useTeamProfile();
  const { canLeaveTeam } = permissions;

  if (!canLeaveTeam) return null;

  return <LeaveTeamButton />;
}

export default TeamLeaveHandler;
