import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import Dropdown from 'components/utils/Dropdown';

// Constants
import { AVAILABLE_NATIONALITIES } from 'constants/user';

function NationalitySelector(props) {
  const { nationalities, setNationalities, maxAmount } = props;

  const { t } = useTranslation(['general']);

  const _setNationalities = async (_nationalities) => {
    const values = _nationalities.map((_language) => _language.value);

    if (values.length > maxAmount) {
      values.shift();
    }

    setNationalities(values);
  };

  const currentValues = useMemo(() => {
    // const foundNationality = AVAILABLE_NATIONALITIES.find((_nationality) => _nationality.value === nationalities);

    const result = AVAILABLE_NATIONALITIES.filter((data) => nationalities.some((input) => input === data.value));

    return result || [];
  }, [nationalities]);

  return (
    <div className="field">

      <div className="">
        <p className="has-text-grey is-size-5 mb-2">
          {t('general:nationality')}
        </p>

        <div className="has-margin-left-auto">
          <Dropdown
            items={AVAILABLE_NATIONALITIES}
            onSelect={_setNationalities}
            value={currentValues}
            isMulti={maxAmount > 1}
            isSearchable
            className=""
          />

        </div>
      </div>
    </div>

  );
}

NationalitySelector.propTypes = {
  nationalities: PropTypes.arrayOf(PropTypes.string).isRequired,
  setNationalities: PropTypes.func.isRequired,
  maxAmount: PropTypes.number,
};

NationalitySelector.defaultProps = {
  maxAmount: 1,
};

export default NationalitySelector;
