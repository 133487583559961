import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';
import { useFriendList } from 'components/friends/FriendListProvider';

function FriendshipHandler(props) {
  const { playerId, isFriend } = props;

  const { removeFriend } = useFriendList();

  const { t } = useTranslation(['general']);

  const [status, setStatus] = useState('');
  const [disabled, setDisabled] = useState(false);

  const { get, del } = useAPI();

  const _removeFriend = async () => {
    try {
      setDisabled(true);

      await del(`/player/friends/${playerId}`);
      removeFriend(playerId);

      setStatus(t('general:friend_removed'));
      setDisabled(false);
    } catch (e) {
      console.log(e);
      setStatus(t('errors:server_error'));
      setDisabled(false);
    }
  };

  const addFriend = async () => {
    try {
      setDisabled(true);

      await get(`/player/friends/request/${playerId}`);

      setStatus(t('general:friend_request_sent'));
      setDisabled(false);
    } catch (e) {
      if (e.response.status === 409) {
        setStatus(t('general:friend_request_already_sent'));
        setDisabled(false);
        return;
      }
      setStatus(t('errors:server_error'));
      setDisabled(false);
    }
  };

  return (
    <div>
      {
        isFriend ? (
          <button
            type="button"
            className={`button is-danger ${disabled ? 'is-loading' : ''}`}
            disabled={disabled}
            onClick={_removeFriend}
          >
            {t('general:remove_friend')}
          </button>
        ) : (
          <button
            type="button"
            className={`button is-primary ${disabled ? 'is-loading' : ''}`}
            disabled={disabled}
            onClick={addFriend}
          >
            {t('general:add_friend')}
          </button>
        )
    }

      {
        status && (
        <p className="mt-3 has-text-weight-semibold">
            { status }
        </p>
        )
    }
    </div>
  );
}

FriendshipHandler.propTypes = {
  playerId: PropTypes.string.isRequired,
  isFriend: PropTypes.bool.isRequired,
};

export default FriendshipHandler;
