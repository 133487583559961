import React, { useMemo } from 'react';

// Libraries
import { DateTime } from 'luxon';
import { getRankFromGameMode, getSelectedGame } from 'constants/games';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import TournamentCardPicDisplay from 'components/tournaments/utils/TournamentCardPicDisplay';
import TournamentInfoBar from './TournamentInfoBar';
import TournamentStatusDisplay from './TournamentStatusDisplay';
import TournamentIdDisplay from './TournamentIdDisplay';
import TournamentAdminPanelHandler from './TournamentAdminPanelHandler';
import TournamentBannerPicDisplay from './TournamentBannerPicDisplay';
import TournamentRuleDisplay from './TournamentRuleDisplay';
import TournamentPrizePoolDisplay from './TournamentPrizePoolDisplay';
import TournamentGlobalChatButton from './TournamentGlobalChatButton';

function TournamentInfoHeader() {
  const {
    info, game, deadlines, tournamentSettings, currentPhase,
  } = useTournament();

  const { t } = useTranslation(['general']);

  const {
    title, subtitle, cardPic, description,
  } = info;
  const { tournamentType } = tournamentSettings;

  const { language, timezone } = useSelector((state) => state.session);

  const renderedTitle = useMemo(() => {
    if (!title) return null;

    return (
      <div>
        <h1
          className="has-text-primary has-text-weight-bold is-size-1 break-word"
        >
          { title }
        </h1>
      </div>
    );
  }, [title]);

  const renderedSubtitle = useMemo(() => {
    if (!subtitle) return null;

    return (
      <div className="mt-1">
        <h2
          className="has-text-white has-text-weight-medium break-word is-size-4 has-text-standard"
        >
          { subtitle }
        </h2>
      </div>
    );
  }, [subtitle]);

  const renderedTournamentStart = useMemo(() => {
    if (!deadlines || !deadlines.tournament || !deadlines.tournament.start) return null;

    const startDate = deadlines.tournament.start;

    const date = DateTime.fromISO(startDate, { zone: timezone, locale: language });
    const localDate = date.toLocaleString({
      year: 'numeric', month: '2-digit', day: '2-digit',
    });

    return (
      <p className="is-size-4 has-text-grey">
        { localDate }
      </p>
    );
  }, [deadlines, language, timezone]);

  const renderedLogo = useMemo(() => {
    if (!cardPic) return null;

    return (
      <div className="column has-max-width-200">
        <TournamentCardPicDisplay cardPic={cardPic} />
      </div>
    );
  }, [cardPic]);

  const renderedDescription = useMemo(() => {
    if (!description) return null;

    return (
      <div>
        <p className="mt-2 break-word">
          { description }
        </p>
      </div>
    );
  }, [description]);

  const renderedGameMode = useMemo(() => {
    if (!game.tag) return null;

    const gameData = getSelectedGame(game.tag);

    const renderedGame = (
      <div className="is-flex flex-direction-column has-text-centered has-fullheight">
        <h3 className="is-size-6 mb-3 has-text-weight-light has-text-standard">
          { t('general:game') }
        </h3>
        <div className="flex-grow is-flex has-content-centered">
          <img
            src={gameData.icon}
            alt={gameData.name}
            className="image has-width-64 has-height-64"
          />
        </div>
      </div>
    );

    if (!game.mode) return renderedGame;

    const uppercasedMode = game.mode.charAt(0).toUpperCase() + game.mode.slice(1);

    const renderedMode = (
      <div className="is-flex flex-direction-column has-text-centered has-fullheight">
        <h3 className="is-size-6 mb-3 has-text-weight-light has-text-standard">
          { t('general:mode') }
        </h3>
        <div className="flex-grow is-flex has-content-centered">
          <h4
            className="is-size-4 has-text-weight-semibold has-text-standard"
          >
            { uppercasedMode }
          </h4>
        </div>
      </div>
    );

    return (
      <div className="columns is-centered is-multiline">
        <div className="column is-narrow">
          { renderedGame }
        </div>
        <div className="column is-narrow">
          { renderedMode }
        </div>
      </div>
    );
  }, [game.tag]);

  const renderedMmrRange = useMemo(
    () => {
      if (!game.mmrRange) return null;

      const { start, end } = game.mmrRange;

      if (start === -1 || end === -1) return null;

      const startRank = getRankFromGameMode(game.tag, game.mode, start, 'mmr');
      const endRank = getRankFromGameMode(game.tag, game.mode, end, 'mmr');

      if (game.tag === 'RL') {
        return (
          <div className="mt-3">
            <p className="is-size-6 mb-3 has-text-weight-light has-text-standard">
              {t('general:mmr_range')}
            </p>
            <p className="is-size-5 has-text-grey">
              { `${startRank.name} (${start}) - ${endRank.name} (${end})` }
            </p>
          </div>
        );
      }

      return (
        <div className="mt-3 has-text-centered">
          <p className="is-size-6 mb-3 has-text-weight-light has-text-standard">
            {t('general:mmr_range')}
          </p>
          <p className="is-size-4 has-text-weight-semibold has-text-standard">
            { `${startRank.name} - ${endRank.name}` }
          </p>
        </div>
      );
    },
    [game],
  );

  return (
    <div>
      {
        currentPhase !== 'draft' && (
          <TournamentAdminPanelHandler />
        )
      }
      <div className="px-6 pt-6">
        <TournamentBannerPicDisplay />

        <TournamentGlobalChatButton />

        { renderedTitle }
        { renderedSubtitle }

        <div className="mt-5">
          <div className="columns">
            { renderedLogo }
            <div className="column is-9">
              { renderedTournamentStart }
              { renderedDescription }
            </div>
            <div className="column is-narrow">
              { renderedGameMode }
              { renderedMmrRange }
            </div>
          </div>
        </div>

        <div className="mt-5">
          <div className="columns">
            <div className="column">
              <TournamentIdDisplay />

              <TournamentPrizePoolDisplay />
            </div>
            <div className="column">
              <TournamentRuleDisplay />
            </div>
          </div>
        </div>

        <div className="mt-6">
          <TournamentInfoBar />
        </div>

        <div className="mt-5">
          <TournamentStatusDisplay />
        </div>
      </div>
    </div>

  );
}

export default TournamentInfoHeader;
