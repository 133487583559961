import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Components
import DataDeletedNotification from '../DataDeletedNotification';
import { DateTime } from 'luxon';

function ActiveHotscrimpCancelledNotification(props) {
  const { notification } = props;
  const {
    dataDeleted, hotscrimpTeamName, requestTeamName, startDate, teamThatCancelled,
  } = notification;

  const { t } = useTranslation(['general']);

  const { language, timezone } = useSelector((state) => state.session);

  const cancelledByTeam = teamThatCancelled === hotscrimpTeamName ? hotscrimpTeamName : requestTeamName;

  const date = useMemo(() => {
    if (!startDate) return null;

    const _date = DateTime.now().setZone(timezone).setLocale(language);

    return _date.toLocaleString(DateTime.DATE_SHORT);
  }, [startDate]);

  if (dataDeleted) {
    return (
      <DataDeletedNotification
        icon="fas fa-square-minus"
        title={t('hotscrimps:active_hotscrimp_cancelled')}
        message=""
      />
    );
  }

  return (

    <div>
      <div>

        <div className="columns">
          <div className="column px-0 is-3 is-flex has-content-centered">
            <i className="fas fa-square-minus is-size-3 has-text-grey" />
          </div>

          <div className="column px-0 is-9 has-text-left">
            <div
              className="has-fullwidth"
            >
              <p
                className="is-size-6 has-line-height-full has-text-white"
              >
                { t('hotscrimps:active_hotscrimp_cancelled') }
              </p>
              <p className="mt-2 has-text-grey-light has-line-height-full">
                { t('hotscrimps:active_hotscrimp_cancelled_message', {
                  hotscrimpTeamName, requestTeamName, date, cancelledBy: cancelledByTeam,
                }) }
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

ActiveHotscrimpCancelledNotification.propTypes = {
  notification: PropTypes.shape({
    dataDeleted: PropTypes.bool,
    hotscrimpTeamName: PropTypes.string,
    requestTeamName: PropTypes.string,
    startDate: PropTypes.string,
    teamThatCancelled: PropTypes.string,
  }).isRequired,
};

export default ActiveHotscrimpCancelledNotification;
