import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

import {
  DISCORD,
  TIKTOK,
  INSTAGRAM,
  TWITTER,
  TAGSGG,
  WEBSITE,
  EMAIL,
} from 'constants/contacts';

// Components
import LinkButton from 'components/utils/LinkButton';

// Assets
import tagsIcon from 'assets/images/icons/tagsgg.png';

function SocialLinkDisplay(props) {
  const { contact, showText, displayType } = props;

  const contactDisplay = useMemo(() => Object.keys(contact).map((type) => ({ type, id: contact[type] })), [contact]);

  const showContactInfo = () => contactDisplay.map((info, index) => {
    let icon = '';
    let img = '';
    let id = '';

    if (!info.id) return null;

    switch (info.type) {
      case DISCORD:
        icon = 'fab fa-discord has-text-white is-size-3';
        id = `https://discord.gg/${info.id}`;
        break;
      case TIKTOK:
        icon = 'fab fa-tiktok has-text-white is-size-3';
        id = `https://tiktok.com/${info.id}`;
        break;
      case INSTAGRAM:
        icon = 'fab fa-instagram has-text-white is-size-3';
        id = `https://instagram.com/${info.id}`;
        break;
      case TWITTER:
        icon = 'fab fa-twitter has-text-white is-size-3';
        id = `https://twitter.com/${info.id}`;
        break;
      case TAGSGG:
        img = tagsIcon;
        id = `https://tags.gg/${info.id}`;
        break;
      case EMAIL:
        icon = 'fas fa-envelope has-text-white is-size-3';
        id = `mailto:${info.id}`;
        break;

      default: return null;
    }

    return (
      <div className="mx-1 my-3 is-flex align-items-center" key={index}>
        <LinkButton url={id || info.id} img={icon || img} showText={showText} />
      </div>
    );
  });

  return (
    <div
      className={`is-inline-flex align-items-center my-3 ${displayType === 'column' ? 'flex-direction-column' : ''}`}
    >
      {showContactInfo()}
    </div>
  );
}

SocialLinkDisplay.propTypes = {
  contact: PropTypes.shape({}).isRequired,
  displayType: PropTypes.string,
  showText: PropTypes.bool,
};

SocialLinkDisplay.defaultProps = {
  displayType: 'column',
  showText: true,
};

export default SocialLinkDisplay;
