import React, { useEffect, useMemo, useState } from 'react';

// Hooks
import { useOrgaPage } from 'components/orgas/OrgaPage';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Components
import OrgaMemberDisplay from 'components/orgas/OrgaPage/OrgaMemberHandler/OrgaMemberDisplay';
import { Link } from 'react-router-dom';
import { PLAYER_PROFILE_DISPLAY } from 'constants/routes';

function OrgaMemberList(props) {
  const { disabled, setDisabled, close } = props;

  const { members, publicId } = useOrgaPage();

  const [memberList, setMemberList] = useState([]);
  const [filter, setFilter] = useState({});
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);

  const [status, setStatus] = useState('');

  const { post } = useAPI();

  const { t } = useTranslation(['general']);

  const loadMemberList = async () => {
    try {
      setStatus('');
      setDisabled(true);

      const data = {
        filter: {
          ...filter,
          joinedAt: memberList.length ? memberList[memberList.length - 1].joinedAt : '',
        },
        orgaId: publicId,
      };
      const list = await post('/orgas/members/list', data);

      if (list.length === 20) {
        setShowLoadMoreButton(true);
      } else {
        setShowLoadMoreButton(false);
      }

      setMemberList(list);
      setDisabled(false);
    } catch (err) {
      setDisabled(false);
      setStatus(t('errors:data_load_error'));
      console.log(err);
    }
  };

  useEffect(() => {
    loadMemberList();
  }, [members]);

  const renderedMembers = useMemo(() => memberList.map((member, index) => (
    <div
      key={index}
      className="column is-6"
    >
      <Link
        to={`${PLAYER_PROFILE_DISPLAY}${member.playerId}`}
      >
        <OrgaMemberDisplay key={member.playerId} member={member} />

      </Link>
    </div>
  )), [memberList]);

  return (
    <div className="is-fullwidth has-min-width-300 has-max-width-800 is-flex has-content-centered is-in-center has-overflow-hidden box boxshadow has-background-black-gradient p-0">
      <div className="has-fullwidth is-max-fullheight has-overflow-y-auto has-min-height-500">

        <div
          className="has-text-centered"
        >

          <div
            className="has-gradient-primary has-fullwidth is-relative py-6"
          >
            <div className="is-absolute" style={{ right: 20, top: 20, zIndex: '99' }}>

              <div className="has-margin-left-auto">
                <button
                  type="button"
                  onClick={close}
                  className="cleanButton has-text-white"
                >
                  <i className="fas fa-times is-size-5" />
                </button>
              </div>
            </div>

            <p
              className="is-size-2-desktop is-size-3-touch has-text-weight-semibold"
            >
              {t('general:all_members')}
            </p>
          </div>

          {
                  status ? (
                    <div className="mt-5">
                      <p className="is-size-7">
                        {status}
                      </p>
                    </div>
                  ) : (
                    <div className="p-5">
                      <div className="columns is-multiline">
                        {renderedMembers}
                      </div>
                    </div>
                  )
        }
          {
            showLoadMoreButton && (
            <button
              type="button"
              className="button mt-3 is-primary has-text-weight-bold"
              onClick={loadMemberList}
            >
              {t('general:load_more')}
            </button>
            )
        }

        </div>

      </div>
    </div>
  );
}

export default OrgaMemberList;
