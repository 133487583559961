import React, { useMemo } from 'react';

// Components
import Dropdown from 'components/utils/Dropdown';

// Hooks
import { useTranslation } from 'react-i18next';

function PinboardDropdown({
  label, description,
  value, setValue, availableValues, isMulti,
}) {
  const { t } = useTranslation(['general', 'pinboard']);

  const preparedValues = useMemo(() => availableValues.map((_value) => ({ value: _value, label: t(`pinboard:${_value}`) !== _value ? t(`pinboard:${_value}`) : t(`general:${_value}`) })), [availableValues, t]);

  const preparedValue = useMemo(() => {
    if (isMulti) {
      return value.map((_value) => preparedValues.find((preparedValue) => preparedValue.value === _value));
    }

    return preparedValues.find((preparedValue) => preparedValue.value === value);
  }, [preparedValues, value]);

  const prepareValue = (newValue) => {
    if (isMulti) {
      return setValue(newValue.map((_newValue) => _newValue.value));
    }
    setValue(newValue.value);
  };

  return (
    <div>
      <div className="columns is-vcentered">
        <div className="column">
          <p className="has-text-weight-bold is-size-5">
            {t(`pinboard:${label}`) }
          </p>
          <p className="mt-2">
            {t(`pinboard:${description}`)}
          </p>
        </div>
        <div className="column">
          <div>
            <Dropdown
              items={preparedValues}
              value={preparedValue}
              onSelect={prepareValue}
              isMulti={isMulti}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PinboardDropdown;
