import React, { useState, useEffect } from 'react';

// Libraries
import stats from 'analytics/matomo';
import { DateTime } from 'luxon';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';

// Actions
import { addTeam } from 'store/teams';

// Components
import Popup from 'reactjs-popup';
import TeamCreator from './TeamCreator';

function CreateTeamButton() {
  const {
    timezone, region: userRegion,
  } = useSelector((state) => state.session);

  const { t } = useTranslation(['general', 'validation', 'errors']);

  const [disabled, setDisabled] = useState(false);

  return (
    <div className="has-text-right">
      <Popup
        trigger={(
          <button
            type="button"
            className="button has-no-background grow_small has-text-grey has-border-dashed-grey"
          >
            <i className="fas fa-plus mr-2" />
            {t('general:create')}
          </button>
          )}
        position="top center"
        keepTooltipInside="#root"
        repositionOnResize
        closeOnDocumentClick={!disabled}
        closeOnEscape={false}
        modal

      >
        {
            (close) => (
              <TeamCreator disabled={disabled} setDisabled={setDisabled} close={close} />
            )
           }
      </Popup>
    </div>
  );
}

/*
<div className="is-fullwidth has-min-width-300 has-max-width-700 is-flex has-content-centered is-in-center has-overflow-hidden box boxshadow has-background-black-gradient p-0">
                  <div className="has-fullwidth is-max-fullheight has-overflow-y-auto">
                    <div
                      className="has-text-centered"
                    >

                      <div
                        className="has-gradient-primary has-fullwidth is-relative py-6"
                      >

                        <div className="is-absolute" style={{ right: 20, top: 20, zIndex: '99' }}>

                          <div className="has-margin-left-auto">
                            <button
                              type="button"
                              onClick={close}
                              className="cleanButton has-text-white"
                            >
                              <i className="fas fa-times is-size-5" />
                            </button>
                          </div>
                        </div>

                        <p
                          className="is-size-2-desktop is-size-3-touch has-text-weight-semibold"
                        >
                          {t('general:create_team')}
                        </p>

                      </div>
                    </div>
                    <div className="br-0 p-6">

                      <div className="columns is-multiline">
                        <div className="column is-12">
                          <NameEditor name={name} setName={setName} disabled={disabled} />
                        </div>
                        <div className="column is-12">
                          <GameSelector
                            game={game}
                            setGame={setGame}
                            filter={['NL']}
                            showGames="full"
                          />
                        </div>
                      </div>

                      <div className="has-text-centered mt-6">
                        <button
                          type="button"
                          onClick={() => createTeam(close)}
                          className={`button is-size-5 px-5 has-background-primary has-text-white has-text-weight-semibold ${disabled ? 'is-loading' : ''}`}
                        >
                          {t('general:create')}
                        </button>
                        <div className="has-text-centered">
                          <p
                            className="has-text-weight-semibold mt-4"
                          >
                            { status }
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

*/

export default CreateTeamButton;
