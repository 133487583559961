import React from 'react';

// Hooks
import { useOrgaPage } from 'components/orgas/OrgaPage';

// Components
import DeleteOrgaButton from './DeleteOrgaButton';

function OrgaDeletionHandler() {
  const { permissions } = useOrgaPage();
  const { canDeleteOrga } = permissions;

  if (!canDeleteOrga) return null;

  return <DeleteOrgaButton />;
}

export default OrgaDeletionHandler;
