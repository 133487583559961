import React, { useEffect, useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import GAMES, { getSelectedGame } from 'constants/games';
import MmrSelector from 'components/utils/games/MmrSelector';
import RankGameSelector from './RankGameSelector';
import RankModeSelector from './RankModeSelector';

function RankEntryEditor(props) {
  const {
    allRanks, index, setRank,
  } = props;

  const rank = allRanks[index];
  const { game, mmr, mode } = rank;

  const selectedGame = useMemo(() => getSelectedGame(game), [game]);

  // only show games that have modes that have not yet been selected
  const availableGames = useMemo(() => {
    // a game is available if there are modes that are not in the ranks for that game
    const _availableGames = GAMES.filter((_game) => {
      const currentModes = allRanks.filter((_rank) => _rank.game === _game.tag).map((_rank) => _rank.mode);

      const allModes = _game.modes.map((_mode) => _mode.name);

      const _availableModes = allModes.filter((_mode) => !currentModes.includes(_mode));

      return _availableModes.length !== 0;
    });

    return _availableGames;
  }, [allRanks, game]);

  const allModes = useMemo(() => selectedGame.modes.map((_mode) => _mode.name), [game]);

  // only show modes that have not yet been selected
  const availableModes = useMemo(() => {
    const gameTag = selectedGame.tag;

    if (allModes.length === 0) return [];

    // filter allModes to only include modes that are not in allRanks
    const _availableModes = allModes.filter((_mode) => !allRanks.some((_rank) => _rank.game === gameTag && _rank.mode === _mode));

    return _availableModes;
  }, [game, allRanks]);

  const setMmr = (_mmr) => {
    setRank(index, { ...rank, mmr: _mmr });
  };

  const setMode = (_mode) => {
    setRank(index, { ...rank, mode: _mode, mmr: 0 });
  };

  const setGame = (_game) => {
    setRank(index, {
      ...rank, game: _game, mmr: -1, mode: '',
    });
  };

  return (
    <div className="is-flex has-content-centered-vertically my-2 flex-wrap">
      <RankGameSelector
        availableGames={availableGames}
        game={game}
        setGame={setGame}
      />

      <div className="mx-3">
        <RankModeSelector
          allModes={allModes}
          availableModes={availableModes}
          game={game}
          mode={mode}
          setMode={setMode}
        />
      </div>

      <MmrSelector
        rank={rank}
        setMmr={setMmr}
        displayType="dropdown"
      />

    </div>
  );
}

RankEntryEditor.propTypes = {
  rank: PropTypes.shape({
    game: PropTypes.string.isRequired,
    mmr: PropTypes.number.isRequired,
    mode: PropTypes.string.isRequired,
  }).isRequired,
  setRank: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  allRanks: PropTypes.arrayOf(PropTypes.shape({
    game: PropTypes.string.isRequired,
    mmr: PropTypes.number.isRequired,
    mode: PropTypes.string.isRequired,
  }).isRequired).isRequired,
};

export default RankEntryEditor;
