import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

function DescriptionEditor(props) {
  const { description, setDescription, maxLength } = props;
  const { t } = useTranslation(['general', 'errors']);

  const [status, setStatus] = useState('');

  const _setDescription = (_description) => {
    setStatus('');

    if (_description.length >= maxLength) {
      setStatus(`${t('errors:max_length_reached_error')}!`);
      return;
    }

    setDescription(_description);
  };

  return (
    <div className="">
      <label className="has-text-grey is-size-5">
        {t('general:description')}
      </label>
      <textarea
        onChange={(event) => _setDescription(event.target.value)}
        type="text"
        value={description}
        rows={3}
        placeholder={t('general:description')}
        onKeyPress={(e) => {
          if (e.key === 'Enter') e.preventDefault();
        }}
        className="textarea br-5 no-shadow has-border p-3 has-fixed-size is-size-6 is-paddingless is-inline has-fullheight has-fullwidth mt-2"
      />

      {
        status && (
        <p className=" mt-4">
            { status }
        </p>
        )
      }
    </div>
  );
}

DescriptionEditor.propTypes = {
  description: PropTypes.string.isRequired,
  setDescription: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
};

DescriptionEditor.defaultProps = {
  maxLength: 140,
};

export default DescriptionEditor;
