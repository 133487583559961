import React, { useMemo, useState } from 'react';

// Components
import Dropdown from 'components/utils/Dropdown';

// Hooks
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import useAPI from 'components/hooks/useAPI';

// Constants
import { AVAILABLE_REGIONS } from 'constants/user';

// Libraries
import stats from 'analytics/matomo';

// Actions
import { setRegion } from 'store/session';

function NavigationRegionSelector() {
  const { t } = useTranslation(['general']);

  const { isAuthenticated } = useAuth0();

  const dispatch = useDispatch();
  const { region, language } = useSelector((state) => state.session);

  const [status, setStatus] = useState('');
  const [disabled, setDisabled] = useState(false);

  const { post } = useAPI();

  const _setRegion = async (newRegion) => {
    try {
      const regionSelected = AVAILABLE_REGIONS.find((_region) => _region.value === newRegion.value);

      if (regionSelected === undefined || regionSelected.value === region.value) {
        return;
      }

      // if the user is not authenticated, just change it locally
      if (!isAuthenticated) {
        dispatch(setRegion(regionSelected.value));
        stats.push(['trackEvent', 'Navigation', 'Region set']);
        return;
      }

      setStatus('');
      setDisabled(true);

      const data = {
        region: regionSelected.value,
      };

      await post('/users/region', data);

      dispatch(setRegion(regionSelected.value));

      stats.push(['trackEvent', 'Profile', 'Region set']);

      setDisabled(false);
    } catch (e) {
      setDisabled(false);
      setStatus(t('errors:save_region_error'));
    }
  };

  // useMemo is used for later use. currently all the data comes from AVAILABLE_REGIONS
  const preparedRegions = useMemo(() => {
    const regions = AVAILABLE_REGIONS.map((_region) => ({
      ..._region,
      label: t(`general:${_region.label}`),
    }));

    return regions;
  }, [language]);

  const activeRegion = useMemo(() => preparedRegions.find((_region) => _region.value === region), [region]);

  return (
    <div className="is-flex has-content-centered">
      <i className="fas fa-globe mr-3 has-text-primary" />
      <div className="control has-width-150">
        <Dropdown
          items={preparedRegions}
          onSelect={_setRegion}
          value={activeRegion}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default NavigationRegionSelector;
