import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useOrgaPage } from 'components/orgas/OrgaPage';

// Components
import OrgaKickButton from './OrgaKickButton';

function OrgaKickPlayerHandler(props) {
  const { member } = props;

  const { permissions } = useOrgaPage();
  const { canKickAdmin, canKickMember } = permissions;

  const renderedButton = useMemo(() => {
    const { role } = member;

    if (role === 'owner') return null;

    if (role === 'admin' && !canKickAdmin) return null;

    if (role === 'member' && !canKickMember) return null;

    return <OrgaKickButton member={member} />;
  }, [member]);

  return renderedButton;
}

OrgaKickPlayerHandler.propTypes = {
  member: PropTypes.shape({
    role: PropTypes.string.isRequired,
  }).isRequired,
};

export default OrgaKickPlayerHandler;
