import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import Popup from 'reactjs-popup';
import OrgaMemberDisplay from 'components/orgas/OrgaPage/OrgaMemberHandler/OrgaMemberDisplay';
import OrgaPlayerDetailsDisplay from './OrgaPlayerDetailsDisplay';

function OrgaMemberDisplayPopup(props) {
  const { member } = props;

  const [disabled, setDisabled] = useState(false);

  const {
    name, nationality, cardPic, orgaRole, role, playerId, joinedAt,
  } = member;

  return (
    <Popup
      trigger={(
        <div>
          <OrgaMemberDisplay member={member} />
        </div>
      )}
      position="top center"
      keepTooltipInside="#root"
      modal
      closeOnDocumentClick={!disabled}
      closeOnEscape={!disabled}
    >
      {
            (close) => (
              <OrgaPlayerDetailsDisplay
                playerId={playerId}
                close={close}
                setDisabled={setDisabled}
                disabled={disabled}
              />
            )
          }

    </Popup>

  );
}

OrgaMemberDisplayPopup.propTypes = {
  member: PropTypes.shape({
    name: PropTypes.string.isRequired,
    nationality: PropTypes.string.isRequired,
    cardPic: PropTypes.string.isRequired,
    orgaRole: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    playerId: PropTypes.string.isRequired,
    joinedAt: PropTypes.string.isRequired,
  }).isRequired,
};

export default OrgaMemberDisplayPopup;
