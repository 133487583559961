import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import TournamentTypeSelector from './TournamentTypeSelector';
import TournamentVisibilityToggle from './TournamentVisibilityToggle';
import TournamentMaxParticipantsEditor from './TournamentMaxParticipantsEditor';
import TournamentMaxGroupsEditor from './TournamentMaxGroupsEditor';
import TournamentBestOfMatchesSelector from './TournamentBestOfMatchesSelector';
import TournamentBestOfFinalsSelector from './TournamentBestOfFinalsSelector';
import TournamentThirdPlaceMatchToggle from './TournamentThirdPlaceMatchToggle';

// <TournamentAccessToggle />
function TournamentSettingsEditor() {
  return (
    <div>

      <div className="columns is-multiline">
        <div className="column">
          <TournamentTypeSelector />

          <TournamentMaxParticipantsEditor />
          <TournamentMaxGroupsEditor />
        </div>
        <div className="column is-offset-1">

          <TournamentVisibilityToggle />

          <TournamentBestOfMatchesSelector />
          <TournamentBestOfFinalsSelector />
          <TournamentThirdPlaceMatchToggle />
        </div>
      </div>
    </div>
  );
}

export default TournamentSettingsEditor;
