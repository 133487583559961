import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import TeamCardPicDisplay from 'components/Teams/utils/TeamCardPicDisplay';
import { DateTime } from 'luxon';

// Hooks
import { useSelector } from 'react-redux';

// Constants
import { getSelectedGame } from 'constants/games';
import { Link } from 'react-router-dom';
import { HOT_SCRIMPS } from 'constants/routes';

function HistoricHotscrimp(props) {
  const { hotscrimp, selectHotscrimp, isSelected } = props;

  const { timezone, language } = useSelector((state) => state.session);

  const {
    startDate, publicId, team,
  } = hotscrimp;

  const { cardPic, name } = team;

  const renderedTimeInfo = useMemo(() => {
    const currentTime = DateTime.fromISO(startDate).setZone(timezone).setLocale(language);

    return (
      `${currentTime.toLocaleString(DateTime.DATETIME_MED)}`
    );
  }, [hotscrimp, timezone, language]);

  const renderedGameModeInfo = useMemo(() => {
    const { game, mode } = hotscrimp;

    const gameData = getSelectedGame(game.tag, mode);

    return `${gameData.name} ${mode}`;
  }, [hotscrimp]);

  return (
    <button
      type="button"
      className="cleanButton has-fullwidth has-text-white has-text-left is-size-6 p-0 has-background-dark-blue"
      onClick={selectHotscrimp}
    >
      <div className="has-border-darkest-light has-height-100">
        <div className="columns">
          <div className="column is-3 is-flex has-content-centered pr-0">
            <div className="has-width-70 has-height-70">
              <TeamCardPicDisplay cardPic={cardPic} />
            </div>
          </div>
          <div className="column">
            <p
              className="is-size-5 abbreviate"
            >
              { name }
            </p>

            <p className="has-text-grey-light is-size-">
              { renderedTimeInfo }
            </p>

            <p className="has-text-grey-light is-size-">
              { renderedGameModeInfo }
            </p>
          </div>
          <div className="column is-2 is-flex has-content-centered pl-0">
            <button
              className="cleanButton has-cursor-pointer has-text-grey is-size-3"
              type="button"
            >
              <i className={`fas fa-angle-${isSelected ? 'left' : 'down'}`} />
            </button>
          </div>
        </div>
      </div>
    </button>
  );
}

HistoricHotscrimp.propTypes = {
  hotscrimp: PropTypes.shape({
    status: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    publicId: PropTypes.string.isRequired,
    game: PropTypes.shape({
      tag: PropTypes.string.isRequired,
    }).isRequired,
    mode: PropTypes.string.isRequired,
    team: PropTypes.shape({
      cardPic: PropTypes.string,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  selectHotscrimp: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,

};

HistoricHotscrimp.defaultProps = {
  isSelected: false,
};

export default HistoricHotscrimp;
