import React, { useMemo } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';

// Components
import TournamentRuleDisplayPopup from './TournamentRuleDisplayPopup';

function TournamentRuleDisplay() {
  const { info } = useTournament();
  const { rules } = info;

  const { t } = useTranslation(['general']);

  const renderedRules = useMemo(() => {
    if (!rules || rules.length === 0) return null;

    return rules.map((rule) => (
      <div key={rule.publicId} className="column is-narrow">
        <TournamentRuleDisplayPopup rule={rule} />
      </div>
    ));
  }, [rules]);

  if (!renderedRules) return null;

  return (
    <div>
      <p>
        {t('general:rules')}
      </p>
      <div className="columns is-multiline">
        {renderedRules}
      </div>
    </div>
  );
}

export default TournamentRuleDisplay;
