import React, { useState } from 'react';

// Libaries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import { MAX_ORGA_NAME_LENGTH } from 'constants/orgas';

const regex = new RegExp(/^[\u00C0-\u017FA-Za-z0-9-_#. ]+$/);

function TitleEditor(props) {
  const { t } = useTranslation(['general', 'validation']);

  const [status, setStatus] = useState('');

  const { title, setTitle, disabled } = props;

  const _setTitle = (_title) => {
    setStatus('');

    if (_title.length === 0) {
      setTitle(_title);
      return;
    }

    if (regex.test(_title) === false) {
      setStatus(t('validation:enter_valid_title_error'));
      return;
    }

    if (_title.length > MAX_ORGA_NAME_LENGTH) {
      setStatus(t('validation:choose_shorter_title_error'));
      return;
    }

    setTitle(_title);
  };

  return (
    <div>
      <div className="">
        <div className="is-inline-block has-min-width-250 has-text-left">
          <label className="label has-text-weight-normal has-text-grey is-size-5">
            {t('general:title')}
          </label>
          <div className="control">
            <input
              className="input"
              type="text"
              value={title}
              placeholder=""
              disabled={disabled}
              onChange={(e) => _setTitle(e.target.value)}
            />
          </div>
        </div>
        <p className="has-text-weight-semibold mt-4">
          { status }
        </p>
      </div>
    </div>

  );
}

TitleEditor.propTypes = {
  title: PropTypes.string.isRequired,
  setTitle: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default TitleEditor;
