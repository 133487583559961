import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

function FriendRequestReceivedNotification(props) {
  const { notification } = props;
  const {
    name,
  } = notification;

  const [isOpen, setOpen] = useState(false);

  const { t } = useTranslation(['general']);

  return (

    <div>

      <button
        type="button"
        onClick={() => setOpen(!isOpen)}
        className="cleanButton has-fullwidth has-text-standard p-0"
      >

        <div className="columns">
          <div className="column px-0 is-3 is-flex has-content-centered">
            <i className="fas fa-user-plus is-size-3 has-text-grey" />
          </div>

          <div className="column px-0 is-9 has-text-left">
            <div
              className="has-fullwidth"
            >
              <p
                className="is-size-6 has-line-height-full has-text-white"
              >
                { t('general:friend_request_received') }
              </p>
              <p className="mt-2 has-text-grey-light has-line-height-full">
                { t(
                  'general:friend_request_received_message',
                  { name },
                )}
              </p>
            </div>

          </div>
        </div>

      </button>
    </div>

  );
}

FriendRequestReceivedNotification.propTypes = {
  notification: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default FriendRequestReceivedNotification;
