import React from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import BannerPicDisplay from 'components/utils/BannerPicDisplay';

function TournamentBannerPicDisplay() {
  const { info } = useTournament();
  const { bannerPic } = info;

  if (!bannerPic) return null;

  return (
    <div className="br-5 has-overflow-hidden mb-5">
      <BannerPicDisplay img={bannerPic} url="/images/tournaments/banner_pictures/" />
    </div>
  );
}

export default TournamentBannerPicDisplay;
