import React, { useState } from 'react';

// Hooks
import { useHotscrimp } from 'components/HotScrimps/HotscrimpsDisplayPage';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';
import { useNavigate } from 'react-router-dom';
import { HOT_SCRIMPS } from 'constants/routes';

// Components
import Popup from 'reactjs-popup';

function HotscrimpDeleteHistoricPopup() {
  const {
    publicId,
  } = useHotscrimp();

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { t } = useTranslation(['hotscrimps', 'general']);
  const { del } = useAPI();
  const navigate = useNavigate();

  const deleteHistoricHotscrimp = async () => {
    try {
      setDisabled(true);

      await del(`/hotscrimps/${publicId}`);

      setDisabled(false);

      navigate(`${HOT_SCRIMPS}`);
    } catch (e) {
      setDisabled(false);
      setStatus(t('errors:server_error'));
    }
  };

  return (
    <Popup
      trigger={(
        <div className="my-2">
          <button
            type="button"
            className="button is-danger has-width-200"
          >
            {t('general:delete')}
          </button>
        </div>
      )}
      position="top center"
      keepTooltipInside="#contentWrapper"
      repositionOnResize
      modal
      closeOnEscape={!disabled}
      closeOnDocumentClick={!disabled}
    >
      {(close) => (
        <div className="is-fullwidth has-min-width-300 has-max-width-700 is-flex has-content-centered is-in-center has-overflow-hidden box boxshadow has-background-black-gradient p-0">
          <div className="has-fullwidth is-max-fullheight has-overflow-y-auto has-min-height-500">
            <div
              className="has-text-centered"
            >
              <div
                className="has-gradient-primary has-fullwidth is-relative py-6"
              >

                <div className="is-absolute" style={{ right: 20, top: 20, zIndex: '99' }}>

                  <div className="has-margin-left-auto">
                    <button
                      type="button"
                      onClick={close}
                      className="cleanButton has-text-white"
                    >
                      <i className="fas fa-times is-size-5" />
                    </button>
                  </div>
                </div>

                <p
                  className="is-size-2-desktop is-size-3-touch has-text-weight-semibold"
                >
                  {t('hotscrimps:hotscrimp_delete_historic_popup_title')}
                </p>

              </div>
            </div>

            <div className="has-text-centered">
              <p className="mt-6 is-size-4">
                {t('hotscrimps:hotscrimp_delete_historic_popup_description')}
              </p>
              <button
                type="button"
                className={`button is-primary mt-5 ${disabled ? 'is-loading' : ''}`}
                onClick={deleteHistoricHotscrimp}
                disabled={disabled}
              >
                {t('hotscrimps:delete_hotscrimp')}
              </button>

              {status && (
                <p className="mt-6 has-text-">
                  {status}
                </p>
              )}

            </div>
          </div>
        </div>
      )}
    </Popup>
  );
}

export default HotscrimpDeleteHistoricPopup;
