import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import GameSelector from 'components/utils/games/GameSelector';

// Constants
import GAMES from 'constants/games';

function RankGameSelector(props) {
  const { availableGames, game, setGame } = props;

  const filter = useMemo(() => {
    // get all games that are not in available Games
    const filteredGames = GAMES.filter((gameObj) => {
      const gameInRanks = availableGames.find((_game) => _game.tag === gameObj.tag);
      return !gameInRanks;
    });

    const _filter = filteredGames.map((gameObj) => gameObj.tag);
    _filter.push('NL');

    return _filter;
  }, [availableGames, game]);

  return (
    <div>
      <GameSelector game={game} setGame={setGame} filter={filter} showGames="short" />
    </div>
  );
}

RankGameSelector.propTypes = {
  game: PropTypes.string.isRequired,
  setGame: PropTypes.func.isRequired,
  availableGames: PropTypes.arrayOf(PropTypes.shape({
    tag: PropTypes.string.isRequired,
  })).isRequired,
};

export default RankGameSelector;
