import React, { useMemo, useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useTournament } from 'components/tournaments/TournamentPage';

// Libraries
import PropTypes from 'prop-types';

// Components
import CardPicDisplay from 'components/utils/CardPicDisplay';

// Shows the match and draws the lines to the next match, if it exists
function MatchDisplay(props) {
  const {
    match: matchData,
    positionX,
    positionY,
    matchNumber: displayMatchNumber,
  } = props;

  const [clickTime, setClickTime] = useState(0);

  const { t } = useTranslation(['general']);

  const { participant, sidebarContent, setSidebarContent } = useTournament();

  const {
    participants, result, round: roundNumber, status: matchStatus, bestOf,
  } = matchData;

  const showMatchInSidebar = (e) => {
    // calculate click time, to only trigger if its actually a click, not a drag
    const time = new Date().getTime();

    if (time - clickTime >= 150) {
      e.preventDefault();
      return;
    }

    if (sidebarContent.type === 'match' && sidebarContent.data.publicId === matchData.publicId) {
      return;
    }

    setSidebarContent({
      type: 'match',
      data: { publicId: matchData.publicId },
    });
  };

  const participantData = useMemo(() => {
    let _participants = participants;

    if (_participants.length === 0) {
      _participants = [
        {
          cardPic: '',
          name: '',
          isDisqualified: false,
        },
        {
          cardPic: '',
          name: '',
          isDisqualified: false,
        },
      ];
    } else if (_participants.length === 1) {
      _participants.push({
        cardPic: '',
        name: '',
        isDisqualified: false,
      });
    }

    return _participants;
  }, [participants]);

  const renderedParticipants = useMemo(() => participantData.map((_participant, index) => {
    const gamesWon = result.gameResults.filter((_result) => _result.participant === _participant.publicId).length;

    if (!_participant.publicId) {
      return (
        <div
          key={index}
          className={`columns has-height-48 ${index === 0 ? 'has-border-bottom-grey-dark' : ''}`}
        />
      );
    }

    return (
      <div
        key={_participant.publicId}
        className={`columns has-height-48 ${index === 0 ? 'has-border-bottom-grey-dark' : ''}`}
      >
        <div className="column is-narrow has-width-30 p-0 is-flex has-content-centered">
          <CardPicDisplay
            img={_participant.cardPic}
            url={_participant.participantType === 'team' ? '/images/teams/card_pictures/' : undefined}
          />
        </div>
        <div className="column p-0 is-flex has-content-centered-vertically px-3 abbreviate-text">
          <p className={_participant.isDisqualified ? 'has-text-danger' : ''}>
            {`${_participant.name}`}
          </p>
        </div>
        <div className="column is-2 py-0 is-flex has-content-centered mx-1">
          <p className="has-text-weight-bold has-background-darkest px-3 py-2 br-5">
            {`${gamesWon}`}
          </p>

        </div>
      </div>
    );
  }), [participantData]);

  const matchBorder = useMemo(() => {
    const userPlaysInMatch = participants.some((_participant) => _participant.publicId === participant.publicId);

    if (matchStatus === 'pending') {
      if (userPlaysInMatch) return 'has-border-playerboard-glow';

      return 'has-border-primary-glow';
    }

    return 'has-border-darker';
  }, [matchStatus, participant, participants]);

  const matchName = useMemo(() => {
    const stageToDisplay = ['quarterfinal', 'semifinal', 'final', 'grand_final', 'third_place'];

    if (stageToDisplay.includes(matchData.stage)) {
      if (matchData.stage === 'third_place') {
        return t('general:match_third_place');
      }

      if (matchData.stage === 'grand_final') {
        if (matchData.status === 'finished' && matchData.participants.length === 0) {
          return t('general:grand_final_not_needed');
        }

        if (matchData.status === 'upcoming') {
          return t('general:grand_final_if_needed');
        }

        return t('general:grand_final');
      }

      return t(`general:${matchData.stage}`);
    }

    return `${t('general:match')} ${displayMatchNumber}`;
  }, [displayMatchNumber, roundNumber, t, matchData]);

  return (
    <div
      data-testid="match-display"
      className="has-height-155 has-width-250 is-absolute"
      style={{ top: `${positionY}px`, left: `${positionX}px` }}
    >
      <div className="has-text-centered has-text-grey mb-1">
        <p className="has-text-white">
          {matchName}
        </p>
        <p className="">
          {`${t('general:best_of')} ${bestOf}`}
        </p>
      </div>
      <div
        className={`br-7 has-height-96 has-overflow-hidden ${matchBorder}`}
      >
        <button
          type="button"
          className="cleanButton has-fullwidth p-0 m-0"
          onMouseDown={() => setClickTime(new Date().getTime())}
          onClick={showMatchInSidebar}
        >
          <div className="box p-0 m-0">
            {renderedParticipants}
          </div>
        </button>
      </div>
    </div>

  );
}

MatchDisplay.propTypes = {
  match: PropTypes.shape({
    participants: PropTypes.arrayOf(PropTypes.shape({})),
    result: PropTypes.shape({}),
    round: PropTypes.number,
    match: PropTypes.number,
    status: PropTypes.string,
  }).isRequired,
};

export default MatchDisplay;
