import React, { useMemo } from 'react';

// Hooks
import { useOrgaPage } from 'components/orgas/OrgaPage';
import { useTranslation } from 'react-i18next';

// Components
import OrgaDescriptionEditor from './OrgaDescriptionEditor';

const OrgaDescriptionHandler = () => {
  const { permissions, description } = useOrgaPage();
  const { canUpdateOrga } = permissions;

  const { t } = useTranslation(['general']);

  const renderedDescription = useMemo(() => {
    if (!description && !canUpdateOrga) return null;

    if (!canUpdateOrga) {
      return (
        <div className="my-5">
          <p
            className="is-size-4-desktop is-size-5-touch has-text-white"
          >
            { t('general:intro') }
          </p>
          <p className="has-text-standard">
            { description }
          </p>
        </div>
      );
    }

    return (
      <div className="my-5 has-max-width-400">
        <OrgaDescriptionEditor />
      </div>
    );
  }, [description]);

  return renderedDescription;
};

export default OrgaDescriptionHandler;
