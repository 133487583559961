import React, { useMemo } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';

// Components
import MatchAdminPanel from './MatchAdminPanel';

const MatchAdminPanelHandler = () => {
  const { staffPermissions } = useTournament();
  const { canManageMatches } = staffPermissions;

  const renderedContent = useMemo(() => {
    if (!canManageMatches) return null;

    return <MatchAdminPanel />;
  }, [canManageMatches]);

  return renderedContent;
};

export default MatchAdminPanelHandler;
