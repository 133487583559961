import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Constants
import GAMES, { getSelectedGame } from 'constants/games';
import { TYPE_PLAYER } from 'constants/cards';

// Components
import ColorContent from 'components/utils/ColorContent';

// Assets
import ow from 'assets/images/cards/games/ow.png';
import cs from 'assets/images/cards/games/cs.png';
import lol from 'assets/images/cards/games/lol.png';
import rl from 'assets/images/cards/games/rl.png';
import val from 'assets/images/cards/games/val.png';
import nl from 'assets/images/cards/games/nl.png';
import TimeDisplay from './TimeDisplay';

// Styles
import styles from './styles.module.scss';

function MatchCard(props) {
  const {
    data, children, topElements, showAnimation, showCardType,
  } = props;

  const {
    name, description, games, times, type, searches,
  } = data;

  const { mmr, tag } = games[0];

  // find the selected game via the tag prop
  const selectedGame = useMemo(() => getSelectedGame(tag), [tag]);

  const cardType = useMemo(() => {
    if (type === TYPE_PLAYER) return 'Spieler';

    return 'Team';
  }, [type]);

  const searchesFor = useMemo(() => {
    const _searchesFor = searches[0];

    if (_searchesFor === TYPE_PLAYER) return 'Spieler';

    return 'Team';
  }, [searches]);

  // if there is no game, dont show card
  if (!selectedGame) {
    return null;
  }

  const { mmr: gameRanks } = selectedGame;

  const startRank = gameRanks[mmr.start];
  const endRank = gameRanks[mmr.end];

  const showImage = () => (
    <img src={selectedGame.img} alt="" className="br-10-top" />
  );

  return (
    <div id={styles.card} className={`box br-22 ${showAnimation ? 'pull' : ''}`}>
      <div className="my-0 is-relative">
        { showImage() }
        {
             showCardType
             && (
             <div
               id={styles.typeIndicator}
               className="has-background-secondary"
             >
               <p className="is-inline mr-2">
                 {cardType}
               </p>
               <i className="fas fa-search is-inline mr-2" />
               <p className="is-inline">
                 { searchesFor}
               </p>
             </div>
             )
        }
      </div>
      <div className="px-4 pb-4 is-flex has-flex-1 flex-direction-column">
        { topElements }
        <ColorContent>
          <p className="has-text-weight-semibold is-size-5">
            { name }
          </p>
        </ColorContent>
        {
             description
             && (
               <div>
                 <ColorContent
                   className="has-max-width-90-percent"
                 >
                   <p>
                     { description }
                   </p>
                 </ColorContent>
               </div>
             )
        }
        <div>
          <ColorContent className="has-max-width-80-percent">
            <p>{ `${endRank} - ${startRank}` }</p>
          </ColorContent>
        </div>
        {
          times
          && <TimeDisplay times={times} />
        }
        <div className="has-margin-top-auto py-3">
          { children }
        </div>
      </div>
    </div>
  );
}

MatchCard.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    searches: PropTypes.arrayOf(PropTypes.string).isRequired,
    games: PropTypes.arrayOf(PropTypes.shape({
      tag: PropTypes.string.isRequired,
      mmr: PropTypes.shape({
        start: PropTypes.number.isRequired,
        end: PropTypes.number.isRequired,
      }),
    })),
    times: PropTypes.arrayOf(PropTypes.shape({
      day: PropTypes.number.isRequired,
      start: PropTypes.string.isRequired,
      end: PropTypes.string.isRequired,
    })),
  }).isRequired,
  children: PropTypes.node,
  topElements: PropTypes.node,

  showAnimation: PropTypes.bool,
  showCardType: PropTypes.bool,
};

MatchCard.defaultProps = {
  children: null,
  topElements: null,

  showAnimation: true,
  showCardType: true,
};

export default MatchCard;
