import React, { useMemo, useState } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';
import { useAuth0 } from '@auth0/auth0-react';

// Actions
import { setTimezone } from 'store/session';

// Components
import Dropdown from 'components/utils/Dropdown';

// Constants
import { AVAILABLE_TIMEZONES } from 'constants/user';

function TimezoneSelector() {
  const dispatch = useDispatch();
  const { timezone } = useSelector((state) => state.session);

  const { isAuthenticated } = useAuth0();

  const [status, setStatus] = useState('');
  const [disabled, setDisabled] = useState(false);

  const { post } = useAPI();

  const { t } = useTranslation(['general']);

  const _setTimezone = async (_timezone) => {
    try {
      // if the user is not authenticated, just change it locally
      if (!isAuthenticated) {
        dispatch(setTimezone(_timezone.value));
        stats.push(['trackEvent', 'Navigation', 'Timezone set']);
        return;
      }

      setStatus('');
      setDisabled(true);

      const data = {
        timezone: _timezone.value,
      };

      await post('/users/timezone', data);

      dispatch(setTimezone(_timezone.value));

      stats.push(['trackEvent', 'Profile', 'Timezone set']);

      setDisabled(false);
    } catch (e) {
      setDisabled(false);
      setStatus(t('errors:save_timezone_error'));
    }
  };

  const currentValue = useMemo(() => AVAILABLE_TIMEZONES.find((_zone) => _zone.value === timezone), [timezone]);

  return (
    <div className="is-flex has-content-centered">
      <i className="fas fa-clock mr-3 has-text-primary" />
      <div className="control has-width-150">
        <Dropdown
          items={AVAILABLE_TIMEZONES}
          onSelect={_setTimezone}
          value={currentValue}
          disabled={disabled}
        />
      </div>
    </div>

  );
}

export default TimezoneSelector;
