import React, { useMemo, useState, useEffect } from 'react';

// Libraries
import stats from 'analytics/matomo';
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Constants
import GAMES, { getSelectedModeFromGame } from 'constants/games';

// Components
import Dropdown from 'components/utils/Dropdown';

function MmrFilterDropdown(props) {
  const {
    game, mode, mmr, setMmr, disabled,
  } = props;

  const {
    language,
  } = useSelector((state) => state.session);

  const { t } = useTranslation(['general']);

  const ranks = useMemo(() => {
    if (!game || !mode) {
      return [];
    }

    const modeData = getSelectedModeFromGame(game, mode);

    const allRanks = modeData.ranks.map((item) => ({
      label: item.name,
      value: item.minMmr,
    }));

    return [{ label: t('general:all_ranks'), value: -1 }, ...allRanks];
  }, [game.tag, language]);

  const setMMRFilter = (_mmr) => {
    const { value } = _mmr;

    if (value === mmr) return;

    setMmr(value);
  };

  const activeMMRValue = useMemo(() => {
    const rank = ranks.find((item) => item.value === mmr);

    if (!rank) return t('general:choose_a_rank');

    return rank;
  }, [mmr, ranks, language]);

  return (

    <div className="field">
      <div className="">
        <label className="label has-text-weight-semibold is-size-7">
          {t('general:rank_select_start')}
        </label>
        <div className="control">
          <Dropdown
            items={ranks}
            onSelect={setMMRFilter}
            value={activeMMRValue}
            disabled={disabled || game.tag === ''}
          />
        </div>
      </div>
    </div>

  );
}

MmrFilterDropdown.propTypes = {
  game: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  mmr: PropTypes.number.isRequired,
  setMmr: PropTypes.func.isRequired,

  disabled: PropTypes.bool,
};

MmrFilterDropdown.defaultProps = {
  disabled: false,
};

export default MmrFilterDropdown;
