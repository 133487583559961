import React, { useMemo } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';

const TournamentEditorHandler = () => {
  const { staffPermissions, setContentToShow, currentPhase } = useTournament();
  const { canUpdateTournamentInfo } = staffPermissions;

  const { t } = useTranslation(['general']);

  const renderedSetupButton = useMemo(() => {
    if (!canUpdateTournamentInfo) return null;
    return (
      <div className="column is-2">
        <button
          type="button"
          className={`button has-fullheight has-fullwidth has-text-weight-bold ${currentPhase === 'draft' ? 'has-background-playerboard' : 'has-background-primary'}`}
          onClick={() => setContentToShow('setup')}
        >
          {t('general:setup')}
        </button>
      </div>
    );
  }, [currentPhase, canUpdateTournamentInfo]);

  return renderedSetupButton;
};

export default TournamentEditorHandler;
