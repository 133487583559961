import React, { useState, useEffect } from 'react';

// Libraries
// import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';

function CopyButton(props) {
  const { text, icon, showText } = props;

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) return undefined;

    const timeout = setTimeout(() => {
      setCopied(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);

  const copyInfo = () => {
    navigator.clipboard.writeText(text);
    // copy(text);
    setCopied(true);
  };

  const iconDisplay = !icon.startsWith('fa') ? <img src={icon} alt="" className="has-min-width-40 has-height-40" /> : <i className={`${icon} has-min-width-40 has-height-40`} />;

  return (
    <div className="is-relative is-flex has-content-centered">
      <button
        type="button"
        onClick={copyInfo}
        className="cleanButton has-height-40 has-text-white is-flex has-content-centered has-text-standard"
      >
        {
          !copied ? iconDisplay : <i className="fas fa-check is-size-3 has-min-width-40" />
      }

        { showText && (
        <p
          className="mx-3 has-text-standard"
        >
          {text}
        </p>
        )}

      </button>

    </div>

  );
}

CopyButton.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
  showText: PropTypes.bool,
};

CopyButton.defaultProps = {
  icon: 'fas fa-copy has-text-white',
  showText: true,
};

export default CopyButton;
