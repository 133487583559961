import React from 'react';

// Hooks
import { useDraggable, useDroppable } from '@dnd-kit/core';

// Libraries
import PropTypes from 'prop-types';
import { CSS } from '@dnd-kit/utilities';

// Components
import ParticipantDisplay from '../../utils/ParticipantDisplay';

// Shows the match and draws the lines to the next match, if it exists
function ParticipantDragHandler(props) {
  const { participant, id, isFirst } = props;

  const hasActiveParticipant = participant.name !== '';

  const { isOver, setNodeRef: setDroppableRef } = useDroppable({
    id: `${id}_drop`,
    data: { participant },
  });

  const {
    isDragging, attributes, listeners, setNodeRef, transform,
  } = useDraggable({
    id,
    data: { participant },
  });

  const style = {
    // Outputs `translate3d(x, y, 0)`
    transform: CSS.Transform.toString(transform),
    position: 'relative',
    touchAction: 'none',
    opacity: isDragging ? '0' : '1',
  };

  const _listeners = hasActiveParticipant ? listeners : {};
  const _attributes = hasActiveParticipant ? attributes : {};

  /* if (isDragging) {
    return (
      <div className="has-fullwidth has-fullheight flex-grow" />
    );
  } */

  return (
    <div>
      <div ref={hasActiveParticipant ? setDroppableRef : null}>
        <div
          ref={hasActiveParticipant ? setNodeRef : null}
          {..._listeners}
          {..._attributes}
          style={style}
        >
          <ParticipantDisplay
            participant={participant}
            showBorder={isFirst}
            isActive={hasActiveParticipant}
            isOver={isOver}
            showMmr
          />
        </div>
      </div>
    </div>

  );
}

ParticipantDragHandler.propTypes = {
  participant: PropTypes.shape({
    cardPic: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
  isFirst: PropTypes.bool.isRequired,
};

export default ParticipantDragHandler;
