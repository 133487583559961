import React, { useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useOrgaPage } from 'components/orgas/OrgaPage';

// Components
import Popup from 'reactjs-popup';
import OrgaTeamList from './OrgaTeamList';

function OrgaTeamListPopup() {
  const { teamToRemove } = useOrgaPage();

  const [disabled, setDisabled] = useState(true);
  const { t } = useTranslation(['general']);

  if (teamToRemove) return null;

  return (
    <div>
      <Popup
        trigger={(
          <button
            type="button"
            className="button is-size-7 has-no-background grow_small has-text-grey has-border-dashed-grey "
          >
            <i className="fas fa-list mr-2" />
            { t('general:show_all')}
          </button>
        )}
        position="top center"
        keepTooltipInside="#root"
        repositionOnResize
        closeOnDocumentClick={!disabled}
        closeOnEscape={!disabled}
        modal
      >
        { (close) => (
          <OrgaTeamList
            disabled={disabled}
            setDisabled={setDisabled}
            close={close}
          />
        )}
      </Popup>
    </div>
  );
}

export default OrgaTeamListPopup;
