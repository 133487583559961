const AVAILABLE_ENGAGEMENTS = [
  { value: 'just4fun', label: 'Just 4 Fun' },
  { value: 'competitive', label: 'Competitive' },
];

const AVAILABLE_QUALIFICATIONS = ['coach_the_coaches'];

const MAX_DISPLAY_NAME_LENGTH = 20;

const MAX_DESCRIPTION_LENGTH = 140;

const MAX_AMOUNT_RANKS = 10;

module.exports = {
  AVAILABLE_QUALIFICATIONS,
  AVAILABLE_ENGAGEMENTS,

  MAX_DESCRIPTION_LENGTH,
  MAX_DISPLAY_NAME_LENGTH,
  MAX_AMOUNT_RANKS,
};
