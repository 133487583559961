import React, { useEffect, useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';
import { useAuth0 } from '@auth0/auth0-react';

// Components
import { Link, useNavigate, useParams } from 'react-router-dom';
import PinboardEntryPageDisplay from './PinboardEntryPageDisplay';

// Constants
import { PINBOARD_LIST } from 'constants/routes';

function PinboardDisplayPage() {
  const { t } = useTranslation(['general']);

  const [entry, setEntry] = useState();

  const navigate = useNavigate();
  const params = useParams();
  const { isAuthenticated } = useAuth0();

  const { get } = useAPI();

  useEffect(() => {
    const { id } = params;

    if (!id) {
      navigate(PINBOARD_LIST);
      return;
    }

    const loadPinboardEntry = async () => {
      try {
        let _entry = null;

        if (!isAuthenticated) {
          _entry = await get(`/pinboard/public/${id}`);
        } else {
          _entry = await get(`/pinboard/${id}`);
        }

        setEntry(_entry);
      } catch (error) {
        navigate(PINBOARD_LIST);
        console.error(error);
      }
    };

    loadPinboardEntry();
  }, []);

  return (
    <div className="is-min-fullheight mb-6 mt-5">

      <div className="columns is-centered">
        <div className="column is-11">
          <div>
            <Link
              to={PINBOARD_LIST}
              className="button has-fullwidth has-background-pinboard-red has-text-left has-text-weight-bold"
            >
              <i className="fas fa-square-caret-left has-text-white is-size-4 has-text-white mr-2" />
              {t('pinboard:back_to_pinboard')}
            </Link>
          </div>

          <div className="mt-6">
            {
                !entry ? (
                  <div className="has-text-centered">
                    <i className="fas fa-spinner fa-spin fa-2x has-text-primary" />
                  </div>
                ) : (
                  <PinboardEntryPageDisplay entry={entry} />
                )
            }
          </div>
        </div>
      </div>

    </div>
  );
}

export default PinboardDisplayPage;
