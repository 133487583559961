import React, {
  useContext, useEffect, useLayoutEffect, useState,
} from 'react';

import useAPI from 'components/hooks/useAPI';
import { useSelector } from 'react-redux';
import { useSocket } from '../SocketProvider';

export const ChatContext = React.createContext({});

function ChatProvider({ children }) {
  const { playerId } = useSelector((state) => state.player.player);

  const { socket, isConnected } = useSocket();
  const { post } = useAPI();

  const sendMessage = async (channel, message) => {
    if (!socket) return;

    socket.emit('chat', { channel, message });
  };

  const fetchChannelHistory = async (channelName, timestamp) => {
    try {
      if (!socket || !channelName) return [];

      const data = {
        channel: channelName,
        // timestamp of oldest message
        timestamp: timestamp || '',
      };

      const historyMessages = await post('/chat/history/', data);

      return historyMessages;
    } catch (error) {
      console.error('Error fetching channel history:', error);
      return [];
    }
  };

  return (
    <ChatContext.Provider
      value={{
        sendMessage,
        fetchChannelHistory,
        socket,
        isConnected,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}

export const useChat = () => {
  const data = useContext(ChatContext);

  return data;
};

export const useChatChannel = (type, publicIds) => {
  const {
    socket,
    sendMessage,
    fetchChannelHistory,
    isConnected,
  } = useChat();

  if (!Array.isArray(publicIds)) publicIds = [publicIds];
  const channelName = `${type}:${publicIds.join(':')}`;

  const [messages, setMessages] = useState([]);

  const setNewMessage = (message) => {
    if (message.channel !== channelName) return;

    setMessages((prevMessages) => [...prevMessages, message]);
  };

  useLayoutEffect(() => {
    if (!socket) return undefined;

    if (!isConnected) {
      socket.off('chat', setNewMessage);
      return undefined;
    }

    /*     socket.emit('leaveChat', channelName);
    socket.off('chat', setNewMessage); */

    socket.emit('joinChat', channelName);
    socket.on('chat', setNewMessage);

    return () => {
      socket.emit('leaveChat', channelName);
      socket.off('chat', setNewMessage);
    };
  }, [channelName, socket, isConnected]);

  const loadHistory = async () => {
    const lastTimestamp = messages.length > 0 ? messages[0].createdAt : undefined;

    const msgs = await fetchChannelHistory(channelName, lastTimestamp);
    setMessages((prevMessages) => [...msgs, ...prevMessages]);
  };

  useEffect(() => {
    loadHistory();
  }, [channelName]);

  const loadMoreMessages = async () => loadHistory();

  const _sendMessage = async (message) => sendMessage(channelName, message);

  return {
    messages,
    sendMessage: _sendMessage,
    loadMoreMessages,
  };
};

export default ChatProvider;
