import lol from 'assets/images/cards/games/lol.png';

// Icons
import lol_icon from 'assets/images/games/lol/lol_icon.png';
import icon_white from 'assets/images/games/lol/lol_landing_icon.png';

// Rank images
import lol_iron from 'assets/images/games/lol/lol_iron.png';
import lol_bronze from 'assets/images/games/lol/lol_bronze.png';
import lol_silver from 'assets/images/games/lol/lol_silver.png';
import lol_gold from 'assets/images/games/lol/lol_gold.png';
import lol_platinum from 'assets/images/games/lol/lol_platinum.png';
import lol_diamond from 'assets/images/games/lol/lol_diamond.png';
import lol_master from 'assets/images/games/lol/lol_master.png';
import lol_gm from 'assets/images/games/lol/lol_gm.png';
import lol_challenger from 'assets/images/games/lol/lol_challenger.png';

import ranks from './ranks';

export default {
  name: 'League of Legends',
  tag: 'LOL',
  img: lol,
  icon: lol_icon,
  iconWhite: icon_white,
  platforms: ['pc'],
  modes: [
    {
      name: '5v5',
      amountPlayers: 5,
      ranks,
    },
  ],
  tagsPerCategory: [
    {
      category: 'general',
      tags: ['Tournament'],
    },
    {
      category: 'properties',
      tags: ['IGL'],
    },
    {
      category: 'role',
      tags: ['Toplane', 'Midlane', 'Botlane', 'Jungler', 'Support'],
    },
    {
      category: 'leagues',
      tags: ['Prime League'],
    },
  ],
  mmr: [
    'Unranked',
    'Iron IV',
    'Iron III',
    'Iron II',
    'Iron I',
    'Bronze IV',
    'Bronze III',
    'Bronze II',
    'Bronze I',
    'Silver IV',
    'Silver III',
    'Silver II',
    'Silver I',
    'Gold IV',
    'Gold III',
    'Gold II',
    'Gold I',
    'Platinum IV',
    'Platinum III',
    'Platinum II',
    'Platinum I',
    'Diamond IV',
    'Diamond III',
    'Diamond II',
    'Diamond I',
    'Master',
    'Grandmaster',
    'Challenger',
  ],
  mmrShort: [
    'UNRKD',
    'IR IV',
    'IR III',
    'IR II',
    'IR I',
    'B IV',
    'B III',
    'B II',
    'B I',
    'S IV',
    'S III',
    'S II',
    'S I',
    'G IV',
    'G III',
    'G II',
    'G I',
    'P IV',
    'P III',
    'P II',
    'P I',
    'DIA IV',
    'DIA III',
    'DIA II',
    'DIA I',
    'MASTER',
    'GM',
    'CHALLENGER',
  ],
  mmrImages: [
    // unranked
    lol_iron,

    lol_iron,
    lol_iron,
    lol_iron,
    lol_iron,

    lol_bronze,
    lol_bronze,
    lol_bronze,
    lol_bronze,

    lol_silver,
    lol_silver,
    lol_silver,
    lol_silver,

    lol_gold,
    lol_gold,
    lol_gold,
    lol_gold,

    lol_platinum,
    lol_platinum,
    lol_platinum,
    lol_platinum,

    lol_diamond,
    lol_diamond,
    lol_diamond,
    lol_diamond,

    lol_master,
    lol_gm,
    lol_challenger,
  ],
};
