import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { usePlayerList } from 'components/Players/PlayerListProvider';

// Components
import FilterPopup from './FilterPopup';
import NameFilter from './NameFilter';

function FilterSelector() {
  const { t } = useTranslation(['general']);

  const {
    filter,
    isLoading,

    setNameFilter,
    // setEngagementFilter,
    setNationalityFilter,
    resetFilter,
    setLoading,
  } = usePlayerList();

  const { name, engagement, nationality } = filter;

  return (

    <div className="">

      <div className="has-text-right is-flex has-content-centered-vertically">
        <div className="has-margin-left-auto">
          <FilterPopup />
        </div>
        <div className="ml-4 mr-3">
          <NameFilter />
        </div>
        <button
          onClick={resetFilter}
          type="button"
          className="cleanButton"
        >
          <i className="fas fa-redo-alt has-text-primary is-size-5" />
        </button>
      </div>

    </div>

  );
}

export default FilterSelector;
