import React, { useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import Popup from 'reactjs-popup';
import OrgaCreator from './OrgaCreator';

function OrgaCreatorPopup() {
  const [disabled, setDisabled] = useState(false);

  const { t } = useTranslation(['general']);

  return (
    <div className="">
      <Popup
        trigger={(
          <button
            type="button"
            className="button has-no-background grow_small has-text-grey has-border-dashed-grey"
          >
            <i className="fas fa-plus mr-2" />
            {t('general:create')}
          </button>
          )}
        position="top center"
        keepTooltipInside="#root"
        repositionOnResize
        closeOnDocumentClick={!disabled}
        closeOnEscape={!disabled}
        modal
      >
        {(close) => (
          <OrgaCreator disabled={disabled} setDisabled={setDisabled} close={close} />
        )}
      </Popup>
    </div>
  );
}

export default OrgaCreatorPopup;
