import React, { useMemo } from 'react';

// Libraries
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { getRankFromGameMode } from 'constants/games';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import CardPicDisplay from 'components/utils/CardPicDisplay';
import Popup from 'reactjs-popup';

function TournamentPlayerDisplay(props) {
  const {
    player, togglePlayer, isSelected, rank,
  } = props;

  const { game, mode, mmr } = rank;

  const canBeSelected = mmr !== -1;

  const { player: loggedInPlayer } = useSelector((state) => state.player);
  const { timezone, language } = useSelector((state) => state.session);

  const { t } = useTranslation(['general']);

  const renderTime = (joinedAt) => {
    const date = DateTime.fromISO(joinedAt).setZone(timezone).setLocale(language);
    return date.toLocaleString(DateTime.DATE_SIMPLE);
  };

  const {
    name, nationality, cardPic, teamRole, ingameRole, role, playerId, joinedAt,
  } = player;

  const joinedAtDate = renderTime(joinedAt);

  const _togglePlayer = () => {
    if (!canBeSelected) return;

    togglePlayer(playerId);
  };

  const showStatus = () => {
    if (!canBeSelected) {
      return (
        <Popup
          trigger={<i className="fas fa-exclamation-triangle has-text-danger" />}
          position="top center"
          on={['hover', 'click']}
          closeOnDocumentClick
        >
          <div className="box">
            <p className="is-size-6">
              {t('general:player_missing_mmr_warning')}
            </p>
          </div>
        </Popup>

      );
    }

    if (isSelected) {
      return (
        <i className="fas fa-check-square" />
      );
    }

    return (
      <i className="fas fa-square" />
    );
  };

  const renderedMmr = useMemo(() => {
    if (!canBeSelected) return null;

    const { img, name: rankName } = getRankFromGameMode(game, mode, mmr, 'mmr');

    return (
      <div>
        <p
          className="has-text-grey"
        >
          {t('general:mmr')}
        </p>
        <div className="is-flex has-content-centered-vertically">
          <img
            src={img}
            alt={rankName}
            className="has-width-50 has-height-50"
          />
          <p className="is-size-7 has-margin-left-auto has-text-right">
            { rankName }
          </p>

        </div>
      </div>
    );
  }, [game, mode, mmr]);

  return (
    <button
      type="button"
      className="box has-text-left has-no-border has-cursor-pointer p-2"
      onClick={_togglePlayer}
    >
      <div
        className="columns is-multiline has-width-350"
      >
        <div className="column has-max-width-120">
          <div className="">
            <CardPicDisplay img={cardPic} />
          </div>

        </div>
        <div
          className="column"
        >
          <div className="is-flex">
            <p
              className="is-size-6 mr-2"
            >
              {name + (loggedInPlayer.playerId === playerId ? (` (${t('general:you')})`) : '')}
            </p>
            <span className={`nmt3 br-5 is-size-5 fi fi-${nationality}`} />
          </div>

          { role !== 'member' && (
          <p className="is-size-7 has-text-warning">
            {t(`general:${role}`)}
          </p>
          )}

          { ingameRole && (
          <p className="is-size-7 has-text-info">
            {ingameRole}
          </p>
          )}

          { teamRole && (
          <p className="is-size-7 has-text-primary">
            {t(`general:${teamRole}`)}
          </p>
          )}

          <p
            className="is-size-7"
          >
            {`${t('general:joined_at')} ${joinedAtDate}`}
          </p>

          <div className="mt-2">
            { renderedMmr }
          </div>
        </div>

        <div className="column has-text-right is-narrow">
          <div
            className="cleanButton is-size-4 p-0 has-text-primary"
          >
            { showStatus() }
          </div>
        </div>
      </div>
    </button>
  );
}

TournamentPlayerDisplay.propTypes = {
  player: PropTypes.shape({
    name: PropTypes.string.isRequired,
    playerId: PropTypes.string.isRequired,
    nationality: PropTypes.string.isRequired,
    cardPic: PropTypes.string.isRequired,
    teamRole: PropTypes.string,
    ingameRole: PropTypes.string,
    role: PropTypes.string.isRequired,
    joinedAt: PropTypes.string.isRequired,
  }).isRequired,

  rank: PropTypes.shape({
    game: PropTypes.string,
    mode: PropTypes.string,
    mmr: PropTypes.number,
  }).isRequired,

  togglePlayer: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default TournamentPlayerDisplay;
