import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTeamProfile } from 'components/Teams/TeamProfile';

// Components
import TeamEditRoleButton from './TeamEditRoleButton';

function TeamRoleHandler(props) {
  const { memberIndex } = props;

  const { permissions, members } = useTeamProfile();
  const { canSetPermissions, canSetTeamRole, canSetIngameRole } = permissions;

  const renderedButton = useMemo(() => {
    const member = members[memberIndex];

    if (!member) return null;

    if (!canSetPermissions && !canSetTeamRole && !canSetIngameRole) return null;

    return <TeamEditRoleButton memberIndex={memberIndex} />;
  }, [members, memberIndex]);

  return renderedButton;
}

TeamRoleHandler.propTypes = {
  memberIndex: PropTypes.number.isRequired,
};

export default TeamRoleHandler;
