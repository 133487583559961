import React, { useMemo, useState } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';
import Dropdown from 'components/utils/Dropdown';

function ForceWinHandler() {
  const { sidebarContent, publicId } = useTournament();

  const { t } = useTranslation(['general']);

  const { data: match } = sidebarContent;
  const { participants, status: matchStatus } = match;
  const { gameResults, currentGameOutcome } = match.result;

  const [matchWinner, setMatchWinner] = useState('');

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { post } = useAPI();

  const forceWinMatch = async () => {
    try {
      setDisabled(true);
      setStatus('');

      const data = {
        winnerId: matchWinner,
        matchId: match.publicId,
      };

      await post(`/tournaments/matches/forceWin/${publicId}`, data);

      setDisabled(false);
    } catch (err) {
      console.log(err);
      setStatus(t('general:data_save_error'));
      setDisabled(false);
    }
  };

  const dropdownData = participants.map((participant) => ({
    value: participant.publicId,
    label: participant.name,
  }));

  const selectedWinner = useMemo(() => {
    if (!matchWinner) return '';

    return participants.find((p) => p.publicId === matchWinner).name;
  }, [matchWinner]);

  return (
    <div className="pb-6">
      <p className="has-text-centered mb-3">
        {t('general:force_win')}
      </p>
      <Dropdown
        items={dropdownData}
        onSelect={(value) => setMatchWinner(value.value)}
        value={selectedWinner}
      />
      <div className="has-text-centered">
        <button
          type="button"
          className="button has-text-weight-bold py-0 has-text-white is-primary mt-3"
          onClick={forceWinMatch}
          disabled={disabled}
        >
          {t('general:force_win_whole_match')}
        </button>
      </div>
      {
        status && (
        <p className="is-size-7 has-text-centered mt-2">
          {status}
        </p>
        )
      }
    </div>
  );
}

export default ForceWinHandler;
