import React, { useEffect, useMemo, useState } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';
import { useAuth0 } from '@auth0/auth0-react';

// Components
import TeamCardPicDisplay from 'components/Teams/utils/TeamCardPicDisplay';
import CardPicDisplay from 'components/utils/CardPicDisplay';
import TournamentsParticipantsList from 'components/tournaments/TournamentPage/common/TournamentParticipantsList';

function TournamentCheckIn() {
  const { t } = useTranslation(['tournaments']);

  const { isAuthenticated } = useAuth0();

  const {
    publicId, participantPermissions,
    participant, checkInCount,

    setCheckInCount, setParticipant,
  } = useTournament();

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { get, post } = useAPI();

  const {
    cardPic, name, joinedAt, participantType, participantPublicId,
  } = participant;
  const isTeam = participantType === 'team';

  const loadCheckInAmount = async () => {
    try {
      let _checkInCount = {
        amountCheckedIn: 0,
        amountTotal: 0,
      };

      if (!isAuthenticated) {
        _checkInCount = await get(`/tournaments/participants/check-in/public/${publicId}`);
      } else {
        _checkInCount = await get(`/tournaments/participants/check-in/${publicId}`);
      }

      setCheckInCount(_checkInCount);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadCheckInAmount();
  }, []);

  const checkIn = async () => {
    try {
      setDisabled(true);
      setStatus('');

      await post(`/tournaments/participants/check-in/${publicId}`);

      setDisabled(false);
      setParticipant((prevState) => ({ ...prevState, isCheckedIn: true }));
    } catch (err) {
      setDisabled(false);
      setStatus(t('general:generic_error'));

      console.log(err);
    }
  };

  const checkOut = async () => {
    try {
      setDisabled(true);
      setStatus('');

      await post(`/tournaments/participants/check-out/${publicId}`);

      setDisabled(false);
      setParticipant((prevState) => ({ ...prevState, isCheckedIn: false }));
    } catch (err) {
      setDisabled(false);
      setStatus(t('general:generic_error'));

      console.log(err);
    }
  };

  const renderedButton = useMemo(() => {
    if (!participantPermissions.canCheckIn) return null;

    if (participant.isCheckedIn) {
      return (
        <button
          type="button"
          className={`button mt-2 has-text-weight-semibold is-block has-margin-auto is-danger ${disabled ? 'is-loading' : ''}`}
          onClick={checkOut}
          disabled={disabled}
        >
          {t('general:check_out')}
        </button>
      );
    }

    return (
      <button
        type="button"
        className={`button mt-2 is-block has-margin-auto is-primary ${disabled ? 'is-loading' : ''}`}
        onClick={checkIn}
        disabled={disabled}
      >
        {t('general:check_in')}
      </button>
    );
  }, [participant.isCheckedIn, participantPermissions.canCheckIn, disabled, t]);

  const renderedCheckInAmount = checkInCount.amountTotal > 0 && (
    <p className="has-text-weight-bold has-text-right">
      {`${t('general:participants_checked_in')}: ${checkInCount.amountCheckedIn} / ${checkInCount.amountTotal}`}
    </p>
  );

  if (!participant.publicId) {
    return (
      <div className="box is-flex flex-direction-column has-content-centered py-6 mx-6">
        <p className="is-size-4 has-text-weight-bold">
          {t('general:check_in_in_progress')}
        </p>
        <p className="mt-2 mb-5">
          {`${t('general:please_stand_by')}...`}
        </p>

        { renderedCheckInAmount }
      </div>
    );
  }

  return (
    <div className="px-6">
      <div className="columns">
        <div className="column">
          <p className="mb-3">
            {t('general:check_in_open')}
          </p>
        </div>
        <div className="column">
          { renderedCheckInAmount }
        </div>
      </div>
      <div className="box p-1">

        <div className="columns">
          <div className="column is-2 has-max-width-80">
            {
                isTeam ? (
                  <TeamCardPicDisplay cardPic={cardPic} />
                ) : (
                  <CardPicDisplay img={cardPic} />
                )
              }
          </div>
          <div className="column is-flex has-content-centered-vertically">
            <p className="has-text-weight-bold">
              { name }
            </p>
          </div>

        </div>
      </div>

      <div className="has-text-centered">

        {
            participant.isCheckedIn && (
              <p className="has-text-weight-bold is-primary button mt-5">
                {t('general:you_are_checked_in')}
              </p>
            )
          }

        { renderedButton }

        {
            status && (
              <p className="has-text-weight-bold mt-2">
                {status}
              </p>
            )
        }
      </div>

      <TournamentsParticipantsList />
    </div>
  );
}

export default TournamentCheckIn;
