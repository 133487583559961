import React, { useState, useEffect } from 'react';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

// HOCs
import withAuthentication from 'components/hocs/withAuthentication';

// Constants
import { PLAYERS_LIST } from 'constants/routes';

// Components
import CardPicDisplay from 'components/utils/CardPicDisplay';
import DescriptionDisplay from 'components/utils/DescriptionDisplay';
import ContactCopyDisplay from 'components/utils/ContactCopyDisplay';
import PlayerIdDisplay from '../PlayerProfile/PlayerIdDisplay';
import FriendshipHandler from './FriendshipHandler';
import { useFriendList } from 'components/friends/FriendListProvider';

function PlayerDisplayProfile() {
  const [player, setPlayer] = useState();
  const [isFriend, setIsFriend] = useState(false);

  const { friends } = useFriendList();

  const { get } = useAPI();

  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation(['general', 'errors']);

  const [isLoading, setLoading] = useState(true);
  const [status, setStatus] = useState('');

  useEffect(() => {
    // check if the player is a friend
    if (!player) return;

    const { playerId } = player;

    const _isFriend = friends.find((entry) => entry.playerId === playerId);

    if (_isFriend) {
      setIsFriend(true);
    } else {
      setIsFriend(false);
    }
  }, [friends]);

  const loadPlayer = async () => {
    try {
      setLoading(true);

      const { id } = params;

      if (!id || !id.startsWith('SCR-PID-')) {
        navigate(PLAYERS_LIST);
      }

      const extractedId = id.replace('SCR-PID-', '');
      const { player: _player, isFriend: _isFriend } = await get(`/player/${extractedId}`);

      setPlayer(_player);
      setIsFriend(_isFriend);

      setLoading(false);
    } catch (e) {
      setStatus(t('errors:data_load_error'));
    }
  };

  useEffect(() => {
    loadPlayer();
  }, [params.id]);

  if (isLoading) {
    return (
      <div className="is-min-fullheight is-flex has-content-centered flex-direction-column">
        <p className="mt-2 has-text-weight-semibold is-size-5">
          { status || t('general:loading') }
        </p>
      </div>
    );
  }

  const {
    name, cardPic, nationality, description, contact, hasProfilePrivate, playerId,
  } = player;

  return (
    <div className="is-min-fullheight p-0 m-0 is-relative">
      <div
        className="has-gradient-primary has-fullwidth is-relative"
        style={{ height: '170px' }}
      />

      <div className="columns is-centered is-mobile is-multiline m-0 px-0 is-min-fullheight">

        <div className="column is-10-desktop is-12-tablet is-11-mobile p-0">

          <div
            className="is-relative"
          >

            <div
              className="is-relative mb-6"
              style={{ marginTop: '-110px' }}
            >
              <div
                className="columns px-6"
              >
                <div className="column is-narrow">
                  <CardPicDisplay img={cardPic} />
                </div>

                <div className="column">
                  <div className="is-flex has-content-centered-vertically">
                    <p
                      className="has-text-weight-semibold is-size-3-desktop is-size-4-tablet is-size-5-mobile"
                    >
                      {name}
                    </p>
                    <div className="ml-1">
                      <div className="">
                        <span className={`mx-2 mt-0 br-5 is-size-3 fi fi-${nationality}`} />
                      </div>
                    </div>
                  </div>
                  <p
                    className="is-size-5-desktop is-size-5-tablet is-size-6-mobile has-text-weight-semibold has-text-dark mt-1"
                  >
                    {t('general:player_profile')}
                  </p>

                  <div className="mt-2">
                    <div className="columns">
                      <div className="column">
                        <div className="mt-5">
                          <FriendshipHandler playerId={playerId} isFriend={isFriend} />
                        </div>
                        {
                          hasProfilePrivate && (
                            <div className="mt-5">
                              <p className="has-text-grey is-size-5">
                                {t('general:this_profile_is_private')}
                              </p>
                            </div>
                          )
                        }
                      </div>
                      <div className="column is-narrow has-text-right has-height-200" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div
            className="px-6 pb-6"
          >

            <div className="px-3">

              {
                !hasProfilePrivate && (
                <div className="">

                  <PlayerIdDisplay />

                  <div className="my-6">
                    <p
                      className="has-text-grey"
                    >
                      {t('general:contact_info')}
                    </p>
                    <ContactCopyDisplay contact={contact} />

                  </div>

                  <DescriptionDisplay description={description} />

                </div>
                )
              }
            </div>

          </div>
        </div>
      </div>
    </div>

  );
}

// <EngagementSelector />

export default withAuthentication(PlayerDisplayProfile);
