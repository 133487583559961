import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';
import { usePlayerList } from 'components/Players/PlayerListProvider';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

// Constants
import { PLAYER_PROFILE_DISPLAY, PLAYER_PROFILE } from 'constants/routes';

// Components
import CardPicDisplay from 'components/utils/CardPicDisplay';
import ContactCopyDisplay from 'components/utils/ContactCopyDisplay';
import DescriptionDisplay from 'components/utils/DescriptionDisplay';
import { Link } from 'react-router-dom';

const PlayerDetails = React.forwardRef(({ open, ...props }, ref) => {
  const { index } = props;

  const { isAuthenticated } = useAuth0();

  const { player } = useSelector((state) => state.player);
  const { players } = usePlayerList();

  const { t } = useTranslation(['general', 'tags']);

  const {
    cardPic, name, nationality, description, contact, playerId: publicId, hasProfilePrivate,
  } = players[index];

  /*
<p
    className="has-margin-left-auto has-text-weight-semibold is-size-5-desktop is-size-5-tablet is-size-6-mobile"
  >
    {engagement}
  </p>
  */

  const renderedCard = useMemo(() => {
    // const locale = getCurrentLocale();
    // const renderedPostedAt = DateTime.fromISO(postedAt).setZone(timezone).setLocale(locale).toRelative();

    const linkToProfile = player.playerId === publicId ? PLAYER_PROFILE : `${PLAYER_PROFILE_DISPLAY}${publicId}`;

    const hasContactInfo = Object.keys(contact).map((key) => contact[key]).filter((value) => value).length > 0;

    return (
      <div className="is-fullwidth has-min-width-300 has-max-width-700 is-flex has-content-centered is-in-center">
        <div className="has-fullwidth">

          <div
            className="is-relative has-background-black-gradient br-5 pb-6 is-max-fullheight has-overflow-y-auto"
          >

            <div
              className="has-gradient-primary has-fullwidth is-relative"
              style={{ height: '170px', borderTopRightRadius: '5px', borderTopLeftRadius: '5px' }}
            />

            <div
              className="is-relative"
              style={{ marginTop: '-110px' }}
            >
              <div
                className="columns px-5"
              >
                <div className="column is-narrow">
                  <CardPicDisplay img={cardPic} />
                </div>

                <div className="column">
                  <div className="is-flex has-content-centered-vertically">
                    <p
                      className="has-text-weight-semibold is-size-3-desktop is-size-4-tablet is-size-5-mobile has-text-standard"
                    >
                      {name}
                    </p>
                    <div className="ml-1">
                      <span className={`mx-2 mt-1 br-5 is-size-3 fi fi-${nationality}`} />
                    </div>
                  </div>

                  <div className="is-flex has-content-centered-vertically mt-1">
                    <p
                      className="is-size-5-desktop is-size-5-tablet is-size-6-mobile has-text-weight-semibold has-text-dark mt-1"
                    >
                      {t('general:player_profile')}
                    </p>
                  </div>

                  {
                    hasProfilePrivate
                      ? (
                        <div className="mt-5 pt-2">
                          <p className="has-text-grey">
                            {t('general:this_profile_is_private')}
                          </p>
                        </div>
                      )
                      : (
                        <div>
                          <div className="mt-6">
                            <DescriptionDisplay description={description} />

                            {
                              hasContactInfo && (
                                <div>
                                  <p
                                    className="has-text-grey"
                                  >
                                    {t('general:contact_info')}
                                  </p>
                                  <ContactCopyDisplay contact={contact} />
                                </div>

                              )
                            }
                          </div>

                          {
                            isAuthenticated && (
                              <div>
                                <Link
                                  to={linkToProfile}
                                  className="button is-primary mt-4"
                                >
                                  {t('general:go_to_profile')}
                                </Link>
                              </div>
                            )
                          }
                        </div>
                      )
                  }
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

    );
  }, [cardPic, contact, description, hasProfilePrivate, name, nationality, player.playerId, publicId, t, isAuthenticated]);

  return (
    <div ref={ref} {...props}>
      { renderedCard }
    </div>
  );
});

PlayerDetails.propTypes = {
  index: PropTypes.number.isRequired,
};

export default PlayerDetails;
