import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

// Assets
import defaultBanner from 'assets/images/profile/default_banner.png';

function BannerPicDisplay(props) {
  const { img, url } = props;

  const urlToPic = `${process.env.REACT_APP_CDN_URL}${url}${img}`;

  const src = useMemo(() => {
    if (img) return urlToPic;

    return defaultBanner;
  }, [img, url]);

  return (
    <div className="has-overflow-hidden p-0 is-flex has-content-centered">
      <img
        src={src}
        className="has-fullwidth has-fullheight"
        alt=""
      />
    </div>
  );
}

BannerPicDisplay.propTypes = {
  img: PropTypes.string,
  url: PropTypes.string,
};

BannerPicDisplay.defaultProps = {
  img: '',
  url: '/images/banner_pictures/',
};

export default BannerPicDisplay;
