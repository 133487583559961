import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import {
  DiscordContact,
  TwitterContact,
  TagsggContact,
  TikTokContact,
  InstagramContact,
} from 'components/user/Contacts';

function SocialLinksEditor(props) {
  const { contact, setContact } = props;

  const { t } = useTranslation(['general']);

  const contactList = useMemo(() => {
    const _contactList = [
      {
        type: 'discord',
        component: DiscordContact,
      },
      {
        type: 'twitter',
        component: TwitterContact,
      },
      {
        type: 'tagsgg',
        component: TagsggContact,
      },
      {
        type: 'tiktok',
        component: TikTokContact,
      },
      {
        type: 'instagram',
        component: InstagramContact,
      },
    ];

    return _contactList;
  }, []);

  const _setContact = (type, name) => {
    const _newContact = {
      ...contact,
    };

    _newContact[type] = name;

    setContact(_newContact);
  };

  return (
    <div className="field">
      <p className="is-size-5 has-text-grey">
        {t('general:social_links')}
      </p>
      <div className="is-flex flex-direction-column">
        {contactList.map((_contact) => {
          const Component = _contact.component;

          return (
            <div className="field has-fullwidth has-max-width-300 my-1" key={_contact.type}>
              <Component
                value={contact[_contact.type]}
                setValue={_setContact}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

SocialLinksEditor.propTypes = {
  contact: PropTypes.shape({}).isRequired,
  setContact: PropTypes.func.isRequired,
};

export default SocialLinksEditor;
