import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_FILTER = {
  name: '',
  rank: {
    game: '',
    mmr: { start: -1 },
    mode: '',
  },
  tags: [],
  languages: [],
  // region is only used when user is not logged in
  region: '',
  platform: '',
  engagement: '',
  nationality: '',
};

const initialState = {
  userEntry: {
    hasCreatedEntry: false,
    name: '',
    description: '',
    // TODO: after updating playerboard
    game: {
      tag: 'RL',
      mmr: {
        start: 0,
      },
    },
    rank: {
      game: 'RL',
      mmr: 0,
      mode: '',
    },
    engagement: 'just4fun',
    region: 'eu',
    nationality: 'us',
    platform: 'pc',
    tags: [],
    languages: [],
    contact: [],
    cardPic: '',
  },
  entries: [],
  filter: INITIAL_FILTER,
  hasData: false,
  isLoading: false,
};

export const playerboardSlice = createSlice({
  name: 'playerboard',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setPlayerboard: (state, action) => {
      state.hasData = true;
      const { entries, userEntry } = action.payload;

      state.entries = entries;
      state.userEntry = {
        ...initialState.userEntry,
        ...userEntry,
      };
    },
    addEntries: (state, action) => {
      state.entries = [...state.entries, ...action.payload];
    },
    setUserEntry: (state, action) => {
      state.userEntry = {
        ...initialState.userEntry,
        ...action.payload,
      };
    },
    deleteUserEntry: (state) => {
      /* state.userEntry = {
        ...initialState.userEntry,
      }; */
      state.userEntry.hasCreatedEntry = false;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setRankFilter: (state, action) => {
      state.filter.rank = {
        ...state.filter.rank,
        ...action.payload,
      };
      // reset tags if game changes
      state.filter.tags = [];
      // reset platform
      state.filter.platform = '';
    },
    setGameFilter: (state, action) => {
      state.filter.rank = {
        ...state.filter.rank,
        game: action.payload,
      };
      // reset tags if game changes
      state.filter.tags = [];
      // reset platform
      state.filter.platform = '';
    },

    setMmrFilter: (state, action) => {
      state.filter.rank = {
        ...state.filter.rank,
        mmr: { start: action.payload },
      };
    },
    setNameFilter: (state, action) => {
      state.filter.name = action.payload;
    },
    setTagsFilter: (state, action) => {
      state.filter.tags = action.payload;
    },
    setNationalityFilter: (state, action) => {
      state.filter.nationality = action.payload;
    },
    setEngagementFilter: (state, action) => {
      state.filter.engagement = action.payload;
    },
    setPlatformFilter: (state, action) => {
      state.filter.platform = action.payload;
    },
    setRegionFilter: (state, action) => {
      state.filter.region = action.payload;
    },
    resetFilter: (state) => {
      state.filter = INITIAL_FILTER;
    },
    clearState: () => initialState,
  },
});

export const {
  setPlayerboard,
  setUserEntry,
  deleteUserEntry,

  addEntries,

  setFilter,
  setGameFilter,
  setNameFilter,
  setTagsFilter,
  setPlatformFilter,
  setNationalityFilter,
  setEngagementFilter,
  setRegionFilter,
  setRankFilter,
  setMmrFilter,

  resetFilter,

  setLoading,

  clearState,
} = playerboardSlice.actions;

export default playerboardSlice.reducer;
