import React from 'react';

function NotFound() {
  return (
    <div className="hero is-min-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">
          <p className="has-text-centered has-text-danger has-text-weight-semibold is-size-4">
            Seite wurde nicht gefunden
          </p>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
