import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import TournamentRegistrationDeadlineEditor from './TournamentRegistrationDeadlineEditor';
import TournamentCheckInDeadlineEditor from './TournamentCheckInDeadlineEditor';
import TournamentDeadlineEditor from './TournamentDeadlineEditor';

function TournamentDeadlinesEditor() {
  const { t } = useTranslation(['general']);

  return (
    <div>
      <TournamentRegistrationDeadlineEditor />

      <div className="mt-">
        <TournamentCheckInDeadlineEditor />
      </div>

      <div className="mt-">
        <TournamentDeadlineEditor />
      </div>

      <div className="field">
        <button
          type="button"
          className="button is-primary mt-5"
          disabled
        >
          {`${t('general:activate_automated_time_control')} - (${t('general:coming_soon')})`}
        </button>
      </div>
    </div>
  );
}

export default TournamentDeadlinesEditor;
