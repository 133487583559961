import React, { useMemo, useState } from 'react';

// Libraries
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function HotscrimpAbortedNotification(props) {
  const { notification } = props;
  const {
    teamName, startDate,
  } = notification;

  const [isOpen, setOpen] = useState(false);

  const { t } = useTranslation(['hotscrimps']);

  const { language, timezone } = useSelector((state) => state.session);

  const date = useMemo(() => {
    if (!startDate) return null;

    const _date = DateTime.now().setZone(timezone).setLocale(language);

    return _date.toLocaleString(DateTime.DATE_SHORT);
  }, [startDate]);

  return (
    <div>

      <div className="columns">
        <div className="column px-0 is-3 is-flex has-content-centered">
          <i className="fas fa-trash is-size-3 has-text-grey" />
        </div>

        <div className="column px-0 is-9 has-text-left">
          <div
            className="has-fullwidth"
          >
            <p
              className="is-size-6 has-line-height-full has-text-white"
            >
              { t('hotscrimps:hotscrimp_aborted') }
            </p>
            <p className="mt-2 has-text-grey-light has-line-height-full">
              { t('hotscrimps:hotscrimp_aborted_message', { team: teamName, date }) }
            </p>
          </div>

        </div>
      </div>

    </div>
  );
}

HotscrimpAbortedNotification.propTypes = {
  notification: PropTypes.shape({
    startDate: PropTypes.string,
    teamName: PropTypes.string,
  }).isRequired,
};

export default HotscrimpAbortedNotification;
