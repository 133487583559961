import React, { useMemo } from 'react';

// Hooks
import { useHotscrimps } from 'components/HotScrimps/HotscrimpsProvider';
import { useTranslation } from 'react-i18next';

// Components
import SentRequestDisplay from './SentRequestDisplay';

function SentRequestsList() {
  const { sentRequests } = useHotscrimps();

  const { t } = useTranslation(['general']);

  const renderedRequests = useMemo(() => sentRequests.map((request, index) => (
    <SentRequestDisplay
      request={request}
      key={index}
    />
  )), [sentRequests]);

  return (
    <div className="has-text-centered">
      <p className="has-text-grey-light mt-2">
        {t('general:sent_requests')}
      </p>

      <div>
        { renderedRequests }
      </div>
    </div>
  );
}

export default SentRequestsList;
