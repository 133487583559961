import React, { useMemo } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { usePlayerList } from 'components/Players/PlayerListProvider';

// Components
import Popup from 'reactjs-popup';
import EngagementSelector from 'components/utils/EngagementSelector';
import NationalityFilterDropdown from 'components/utils/NationalityFilterDropdown';

function FilterPopup() {
  const { t } = useTranslation(['general']);

  const {
    filter,
    isLoading,
    resetFilter,
    // setEngagementFilter,
    setNationalityFilter,
  } = usePlayerList();

  const { name, engagement, nationality } = filter;

  const amountActiveFilters = useMemo(() => {
    let amount = 0;

    if (filter.name) amount++;
    if (filter.nationality) amount++;
    // if (filter.engagement) amount++;

    return amount;
  }, [filter]);

  return (
    <Popup
      position="top center"
      keepTooltipInside="#root"
      repositionOnResize
      closeOnDocumentClick
      closeOnEscape
      className="no-blur"
      trigger={(
        <div className="is-flex has-content-centered-vertically has-cursor-pointer dont-select-text">
          <i className="fas fa-sliders-h mr-3 is-size-5" />
          <p className="has-text-weight-semibold is-size-5">
            { `${t('general:filter')} (${amountActiveFilters})`}
          </p>
        </div>
        )}
    >
      {
        () => (
          <div className="">
            <div className="box">
              <div className="is-flex has-content-centered-vertically">
                <p className="has-text-weight-semibold is-size-5">
                  { t('general:filter')}
                </p>
                <button
                  onClick={resetFilter}
                  type="button"
                  className="cleanButton has-margin-left-auto"
                >
                  <i className="fas fa-redo-alt has-text-primary is-size-5" />
                </button>
              </div>
              <div className="columns is-multiline">

                <div className="column is-2-desktop has-min-width-250">
                  <NationalityFilterDropdown
                    nationality={nationality}
                    setNationality={setNationalityFilter}
                    disabled={isLoading}
                  />

                </div>

              </div>
            </div>
          </div>
        )
        }
    </Popup>
  );
}

/*
<div className="column is-2-desktop has-min-width-250">
                    <EngagementSelector
                      engagement={engagement}
                      setEngagement={setEngagementFilter}
                      disabled={isLoading}
                    />
                  </div>
*/

export default FilterPopup;
