import React, {
  useState, useEffect, useContext,
} from 'react';

// HOCs
import withAuthentication from 'components/hocs/withAuthentication';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

// Constants
import { HOT_SCRIMPS, TEAM_PROFILE } from 'constants/routes';
import { hasPermission } from 'constants/team';

// Components
import CardPicDisplay from 'components/utils/CardPicDisplay';
import HotscrimpBreadcrumbs from 'components/HotScrimps/utils/display/HotscrimpsBreadcrumbs';
import HotscrimpInfoDisplay from 'components/HotScrimps/utils/display/HotscrimpInfoDisplay';
import HotscrimpDescriptionDisplay from 'components/HotScrimps/utils/display/HotscrimpDescriptionDisplay';
import HotscrimpMmrDisplay from 'components/HotScrimps/utils/display/HotscrimpMmrDisplay';
import HotscrimpPlayerDisplay from 'components/HotScrimps/utils/display/HotscrimpPlayerDisplay';
import HotscrimpIdDisplay from 'components/HotScrimps/utils/display/HotscrimpIdDisplay';
import HotscrimpRequestStatusHandler from './HotscrimpRequestStatusHandler';

const HotscrimpRequestContext = React.createContext({});

const INITIAL_PERMISSIONS = {
  canAcceptHotscrimpRequest: false,
  canDeclineHotscrimpRequest: false,
};

function HotscrimpsRequestPage() {
  const [hotscrimpTeam, setHotscrimpTeam] = useState({
    publicId: '',
    name: '',
    cardPic: '',
    averageMmrPlayers: -1,
    players: [],
  });
  const [requestTeam, setRequestTeam] = useState({
    publicId: '',
    name: '',
    cardPic: '',
    averageMmrPlayers: -1,
    players: [],
  });

  const [permissions, setPermissions] = useState(INITIAL_PERMISSIONS);

  const [description, setDescription] = useState('');
  const [platform, setPlatform] = useState('');
  const [game, setGame] = useState();
  const [region, setRegion] = useState();
  const [startDate, setStartDate] = useState();
  const [mode, setMode] = useState('');
  const [mmrRange, setMmrRange] = useState({ start: -1, end: -1 });
  const [publicId, setPublicId] = useState('');
  const [status, setStatus] = useState('pending');

  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const { get } = useAPI();

  const navigate = useNavigate();
  const params = useParams();

  const { t } = useTranslation(['general', 'errors']);
  const { isAuthenticated, isLoading: isAuthLoading } = useAuth0();

  const evaluatePermissions = (role) => {
    if (!role) return;

    const _canAcceptHotscrimpRequest = hasPermission(role, 'acceptHotscrimpRequest');
    const _canDeclineHotscrimpRequest = hasPermission(role, 'declineHotscrimpRequest');

    setPermissions({
      canAcceptHotscrimpRequest: _canAcceptHotscrimpRequest,
      canDeclineHotscrimpRequest: _canDeclineHotscrimpRequest,
    });
  };

  useEffect(() => {
    if (isAuthLoading || params.id === publicId) return;

    setError('');

    const loadHotscrimpRequest = async () => {
      try {
        setLoading(true);

        const { id } = params;

        if (!id) {
          navigate(HOT_SCRIMPS);
        }

        const { request, role } = await get(`/hotscrimps/requests/${id}`);

        evaluatePermissions(role);

        setPublicId(id);
        setDescription(request.description);
        setGame(request.game);
        setRegion(request.region);
        setMode(request.mode);
        setPlatform(request.platform);
        setMmrRange(request.mmrRange);
        setStartDate(request.startDate);
        setStatus(request.status);

        setHotscrimpTeam(request.hotscrimpTeam);
        setRequestTeam(request.requestTeam);

        setLoading(false);
      } catch (e) {
        console.log(e);
        setError(t('errors:page_not_found_error'));
      }
    };

    loadHotscrimpRequest();
  }, [params, isAuthenticated, isAuthLoading]);

  if (isLoading) {
    return (
      <div className="is-min-fullheight is-flex has-content-centered flex-direction-column">
        <p className="mt-2 has-text-weight-semibold is-size-5">
          { error || t('general:loading') }
        </p>
      </div>
    );
  }

  return (
    <HotscrimpRequestContext.Provider value={{
      region,
      startDate,
      game,
      description,
      mode,
      platform,
      mmrRange,
      publicId: params.id,
      permissions,
      status,

      setStatus,
    }}
    >
      <div className="is-min-fullheight p-0 m-0 is-relative">

        <div className="has-background-primary is-size-5 has-fullwidth py-2 px-6">
          <HotscrimpBreadcrumbs game={game} />
        </div>

        <div className="has-fullwidth">

          <div className="p-6">

            <div className="columns is-multiline">
              <div className="column is-narrow p-0 has-min-width-350 has-border-right-grey mr-5">
                <div className="mr-5">
                  <HotscrimpIdDisplay publicId={publicId} />

                  <div>
                    <HotscrimpRequestStatusHandler />
                  </div>
                </div>
              </div>

              <div className="column">
                <HotscrimpInfoDisplay
                  startDate={startDate}
                  region={region}
                  platform={platform}
                  game={game}
                  mode={mode}
                  mmrRange={mmrRange}
                  publicId={params.id}
                />
              </div>
            </div>

            <div className="mb-6">
              <HotscrimpDescriptionDisplay
                description={description}
              />
            </div>

            <div className="mb-6">
              <p className="has-text-grey is-size-4">
                {t('general:teams')}
              </p>
            </div>

            <div className="columns is-centered is-multiline">

              <div className="column is-3 is-flex flex-direction-column has-content-centered">

                <div className="has-text-centered">
                  <Link
                    to={`${TEAM_PROFILE}${hotscrimpTeam.publicId}`}
                  >
                    <p
                      className="is-size-4"
                    >
                      {hotscrimpTeam.name}
                    </p>
                  </Link>

                </div>

              </div>

              <div className="column is-2 is-flex flex-direction-column has-content-centered">

                <div className="has-fullwidth has-fullheight has-max-width-200">

                  <Link
                    to={`${TEAM_PROFILE}${hotscrimpTeam.publicId}`}
                  >
                    <CardPicDisplay
                      url="/images/teams/card_pictures/"
                      img={hotscrimpTeam.cardPic}
                      quadratic
                    />
                  </Link>
                </div>

              </div>

              <div className="column is-2 is-flex has-content-centered">
                <p
                  className="is-size-3"
                >
                  {t('general:vs')}
                </p>
              </div>

              <div className="column is-2 is-flex flex-direction-column has-content-centered">

                <div className="has-fullwidth has-fullheight has-max-width-200">

                  <Link
                    to={`${TEAM_PROFILE}${requestTeam.publicId}`}
                  >
                    <CardPicDisplay
                      url="/images/teams/card_pictures/"
                      img={requestTeam.cardPic}
                      quadratic
                    />
                  </Link>
                </div>

              </div>

              <div className="column is-3 is-flex flex-direction-column has-content-centered">

                <div className="has-text-centered">
                  <Link
                    to={`${TEAM_PROFILE}${requestTeam.publicId}`}
                  >
                    <p
                      className="is-size-4"
                    >
                      {requestTeam.name}
                    </p>
                  </Link>

                </div>

              </div>

            </div>

            <div className="my-6">

              <p className="has-text-grey is-size-4">
                {t('general:average_mmr')}
              </p>
              <div className="columns is-centered is-mutliline">

                <div className="column is-4 is-flex">
                  <div className="is-flex has-fullwidth has-margin-top-auto has-text-right">
                    <div className="has-margin-left-auto">
                      <HotscrimpMmrDisplay
                        game={game}
                        mode={mode}
                        mmr={hotscrimpTeam.averageMmrPlayers}
                      />
                    </div>
                  </div>
                </div>

                <div className="column is-2 is-flex has-content-centered" />

                <div className="column is-4 is-flex">
                  <div className="has-margin-top-auto">
                    <HotscrimpMmrDisplay
                      game={game}
                      mode={mode}
                      mmr={requestTeam.averageMmrPlayers}
                    />
                  </div>
                </div>

              </div>
            </div>

            <div className="">
              <p className="has-text-grey is-size-4">
                {t('general:players')}
              </p>
              <div className="columns is-centered is-multiline">

                <div className="column is-4">
                  <div className="">
                    <HotscrimpPlayerDisplay players={hotscrimpTeam.players} />
                  </div>
                </div>

                <div className="column is-2 is-flex has-content-centered" />

                <div className="column is-4">
                  <div className="">
                    <HotscrimpPlayerDisplay players={requestTeam.players} />
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </HotscrimpRequestContext.Provider>
  );
}

export const useHotscrimp = () => {
  const data = useContext(HotscrimpRequestContext);

  return data;
};

export default withAuthentication(HotscrimpsRequestPage);
