import React, {
  useState, useEffect, useMemo, useLayoutEffect,
} from 'react';

// Libraries
import axios from 'axios';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';

// Constants
import { RECIPE_LIST } from 'constants/routes';

// Components
import Markdown from 'react-markdown';
import RecipeImgDisplay from '../RecipeImgDisplay';
import Recipe from './Recipe';
import Testimonial from './Testimonial';

function RecipePage() {
  const [recipe, setRecipe] = useState(null);

  const { id } = useParams();

  const { language } = useSelector((state) => state.session);
  const { t } = useTranslation(['general', 'errors']);

  // TODO: go to recipe list on language change
  const navigate = useNavigate();

  useLayoutEffect(() => {
    // on init, dont do anything
    if (!recipe) return;

    // on language change, go to recipe list
    navigate(RECIPE_LIST);
  }, [language]);

  useEffect(() => {
    const loadRecipe = async () => {
      try {
        const _recipe = await axios.get(`/recipes/${language}/${id}/index.json`);
        setRecipe(_recipe.data);
      } catch (e) {
        console.error(e);
        setRecipe(null);
        navigate(RECIPE_LIST);
      }
    };

    setRecipe(null);
    loadRecipe();
  }, []);

  const renderedSubRecipes = useMemo(() => {
    if (!recipe || !recipe.subRecipes) return null;

    return recipe.subRecipes.map((subRecipe, index) => (
      <div className="mt-6 pt-6">
        <Recipe recipe={subRecipe} key={index} />
      </div>
    ));
  }, [recipe]);

  if (!recipe) return null;

  return (
    <div className="is-min-fullheight is-relative pt-6 has-text-weight-light">

      <div className="columns is-centered">
        <div className="column is-11">

          <div
            className="mb-6"
          >
            <h1 className="is-size-1 has-text-standard has-text-weight-bold has-text-bright-green">
              {recipe.name}
            </h1>
            <h2 className="is-size-5 has-text-standard has-text-weight-light">
              { recipe.subtitle }
            </h2>
          </div>

          <div className="columns is-vcentered is-centered">
            <div className="column is-6">
              <Markdown className="mt-2 is-size-5">
                { recipe.description }
              </Markdown>
            </div>
            <div className="column is-offset-1-desktop">
              <RecipeImgDisplay
                id={recipe.id}
                src={recipe.thumbnail}
                alt="Rezept"
                className="has-fullwidth has-max-width-400"
              />

            </div>
          </div>

          <div className="mt-6 pt-6">
            <Recipe recipe={recipe.mainRecipe} />
          </div>

          { renderedSubRecipes }

        </div>
      </div>

    </div>
  );
}

/*
 <div className="mt-6 pt-6">
            <Testimonial id={id} testimonial={recipe.testimonial} />
          </div>
*/

export default RecipePage;
