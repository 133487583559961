import React, {
  useState, useEffect, useMemo, useLayoutEffect,
} from 'react';

// Libraries
import axios from 'axios';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Constants
import { RECIPE_PAGE } from 'constants/routes';

// Styles
import styles from './styles.module.scss';
import RecipeImgDisplay from '../RecipeImgDisplay';

function RecipeList() {
  const [recipes, setRecipes] = useState([]);

  const { language } = useSelector((state) => state.session);
  const { t } = useTranslation(['general', 'errors']);

  useLayoutEffect(() => {
    const loadRecipes = async () => {
      try {
        const _recipes = await axios.get(`/recipes/${language}/recipes.json`);
        setRecipes(_recipes.data || []);
      } catch (e) {
        console.error(e);
        setRecipes([]);
      }
    };

    setRecipes([]);
    loadRecipes();
  }, [language]);

  const renderedRecipes = useMemo(() => {
    // idk why, but we need !recipes.map, otherwise we get an error
    if (!recipes || recipes.length === 0 || !recipes.map) return null;

    return recipes.map((recipe, index) => (
      <div className="column is-narrow" key={index}>
        <Link
          to={`${RECIPE_PAGE}/${recipe.id}`}
          className="is-flex flex-direction-column has-width-230 has-fullheight"
        >
          <RecipeImgDisplay id={recipe.id} src={recipe.thumbnail} alt="Rezept" />

          <div className="box is-flex flex-direction-column flex-grow pt-4">
            <div className="mb-2">
              <h2 className="has-text-weight-light has-text-standard is-size-6">
                {recipe.name}
              </h2>

            </div>

            <div className="has-border-top-primary has-margin-top-auto pt-3">
              <p className="is-size-7 has-text-weight-light">
                {recipe.description}
              </p>
            </div>
          </div>
        </Link>
      </div>
    ));
  }, [recipes]);

  return (
    <div className="is-min-fullheight is-relative has-text-weight-light">

      <div className="columns">
        <div className="column is-4 p-6 is-flex is-relative">
          <div className="has-margin-top-auto">
            <div className="has-height-200" />
            <p className="has-text-grey is-size-5 has-text-weight-normal">
              {t('general:digitally_delicious')}
            </p>
            <h1
              className="is-outlined has-text-weight-semibold has-text-white has-text-standard"
              style={{ fontSize: '48px', zIndex: 10 }}
            >
              {t('general:grill_n_shrimp')}
            </h1>
            <p
              className="has-text-white mt-3"
            >
              {t('general:grill_n_shrimp_description')}
            </p>
          </div>
        </div>
        <div className="column p-0">
          <div id={styles.imageWrapper} />
        </div>
      </div>

      <div className="px-6 mt-6 pt-6">
        <div
          className="mb-6"
        >
          <h2 className="is-size-3 has-text-standard has-text-weight-light">
            <i className="fas fa-play mr-4 has-text-primary" />
            {t('general:taste_meets_digital')}
          </h2>
        </div>

        <div className="columns is-multiline">
          { renderedRecipes }
        </div>
      </div>

    </div>
  );
}

export default RecipeList;
