import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import ContactField from 'components/utils/ContactField';

function TikTokContact(props) {
  const { value, setValue } = props;
  const [error, setError] = useState('');

  const { t } = useTranslation(['contacts', 'validation']);

  const setAndValidate = (name) => {
    setError('');

    if (name.length > 64) {
      setError(t('validation:enter_valid_tiktok_error'));
      return;
    }

    setValue('tiktok', name);
  };

  return (
    <ContactField
      icon="fab fa-tiktok has-text-white"
      value={value}
      onChange={setAndValidate}
      error={error}
      label="TikTok"
      placeholder="crazydude"
    />
  );
}

TikTokContact.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
};

TikTokContact.defaultProps = {
  value: '',
};

export default TikTokContact;
