import React, { useState } from 'react';

// Hooks
import { useOrgaPage } from 'components/orgas/OrgaPage';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Constants
import {
  MAX_ORGA_DETAILS_COUNTRY_LENGTH,
  MAX_ORGA_DETAILS_LINE1_LENGTH,
  MAX_ORGA_DETAILS_LINE2_LENGTH,
  MAX_ORGA_DETAILS_NAME_LENGTH,
  MAX_ORGA_DETAILS_PHONE_LENGTH,
  MAX_ORGA_DETAILS_STATE_LENGTH,
  MAX_ORGA_DETAILS_ZIP_LENGTH,
} from 'constants/orgas';

// Components
import Popup from 'reactjs-popup';
import OrgaCountrySelector from './OrgaCountrySelector';

function OrgaDetailsEditor() {
  const { orgaDetails, setOrgaDetails, publicId } = useOrgaPage();

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const [newDetails, setNewDetails] = useState(orgaDetails);

  const { post } = useAPI();

  const { address, name, phone } = newDetails;

  const {
    line1, line2, zipCode, city, country, state,
  } = address;

  const { t } = useTranslation(['general']);

  const setName = (_name) => {
    if (_name.length > MAX_ORGA_DETAILS_NAME_LENGTH) return;

    setNewDetails({ ...newDetails, name: _name });
  };

  const setPhone = (_phone) => {
    if (_phone.length > MAX_ORGA_DETAILS_PHONE_LENGTH) return;

    setNewDetails({ ...newDetails, phone: _phone });
  };

  const setLine1 = (_line1) => {
    if (_line1.length > MAX_ORGA_DETAILS_LINE1_LENGTH) return;

    setNewDetails({ ...newDetails, address: { ...newDetails.address, line1: _line1 } });
  };

  const setLine2 = (_line2) => {
    if (_line2.length > MAX_ORGA_DETAILS_LINE2_LENGTH) return;

    setNewDetails({ ...newDetails, address: { ...newDetails.address, line2: _line2 } });
  };

  const setZipCode = (_zipCode) => {
    if (_zipCode.length > MAX_ORGA_DETAILS_ZIP_LENGTH) return;

    setNewDetails({ ...newDetails, address: { ...newDetails.address, zipCode: _zipCode } });
  };

  const setCity = (_city) => {
    if (_city.length > MAX_ORGA_DETAILS_NAME_LENGTH) return;

    setNewDetails({ ...newDetails, address: { ...newDetails.address, city: _city } });
  };

  const setCountry = (_country) => {
    if (_country.length > MAX_ORGA_DETAILS_COUNTRY_LENGTH) return;

    setNewDetails({ ...newDetails, address: { ...newDetails.address, country: _country } });
  };

  const setState = (_state) => {
    if (_state.length > MAX_ORGA_DETAILS_STATE_LENGTH) return;

    setNewDetails({ ...newDetails, address: { ...newDetails.address, state: _state } });
  };

  const saveOrgaDetails = async (close) => {
    try {
      setDisabled(true);
      setStatus('');

      const data = {
        orgaDetails: {
          name: newDetails.name,
          phone: newDetails.phone,
          address: newDetails.address,
        },
      };

      await post(`/orgas/details/${publicId}`, data);

      setOrgaDetails(newDetails);

      setDisabled(false);
      close();
    } catch (e) {
      setDisabled(false);
      setStatus(t('errors:save_orga_details_error'));
    }
  };

  return (
    <Popup
      trigger={(
        <button
          type="button"
          className="button is-size-7 has-no-background grow_small has-text-grey has-border-dashed-grey
          "
        >
          <i className="fas fa-plus mr-2" />
          {t('general:edit_details')}
        </button>
      )}
      position="top center"
      keepTooltipInside="#root"
      repositionOnResize
      closeOnDocumentClick={!disabled}
      closeOnEscape={!disabled}
      modal
    >
      {
        (close) => (
          <div className="box has-width-400 p-0 is-max-fullheight has-overflow-y-auto">

            <div
              className="has-background-primary has-text-centered p-4 pb-6 is-relative br-5"
            >
              <div className="has-text-right">
                <button
                  type="button"
                  className=" cleanButton has-text-white"
                  onClick={close}
                  disabled={disabled}
                >
                  <i className="fas fa-times" />
                </button>
              </div>
              <p
                className="has-text-weight-semibold is-size-3 mx-3"
              >
                {t('general:details')}
              </p>

              <div
                className="br-100 has-background-primary is-absolute is-flex has-content-centered"
                style={{
                  left: 'calc(50% - 45px)', width: '90px', height: '85px', marginTop: '0px',
                }}
              >
                <i className="fas fa-id-badge is-size-2" />
              </div>

            </div>

            <div className="px-5 pb-5 pt-6 is-flex flex-direction-column has-content-centered">

              <div className="mt-3">
                <div className="field">
                  <label className="label">
                    {t('general:name')}
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder={t('general:name')}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <div className="field">
                  <label className="label">
                    {t('general:street')}
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder={t('general:street')}
                      value={line1}
                      onChange={(e) => setLine1(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <div className="field">
                  <label className="label">
                    {`${t('general:street')} 2`}
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder={`${t('general:street')} 2`}
                      value={line2}
                      onChange={(e) => setLine2(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <div className="field">
                  <label className="label">
                    {t('general:zip_code')}
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder={t('general:zip_code')}
                      value={zipCode}
                      onChange={(e) => setZipCode(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <div className="field">
                  <label className="label">
                    {t('general:city')}
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Night City"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <div className="field">
                  <label className="label">
                    {t('general:state')}
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder={t('general:state')}
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <OrgaCountrySelector country={country} setCountry={setCountry} />
              </div>

              <div className="mt-3">
                <div className="field">
                  <label className="label">
                    {t('general:phone')}
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder={t('general:phone')}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />

                  </div>
                </div>
              </div>

              <div className="mt-5 has-text-right">
                <button
                  type="button"
                  className="button has-margin-auto is-primary grow_small has-text-weight-semibold"
                  onClick={() => saveOrgaDetails(close)}
                  disabled={disabled}
                >
                  {t('general:save')}
                </button>

              </div>

              <p className="mt-4">
                { status }
              </p>

            </div>

          </div>
        )
        }
    </Popup>
  );
}

export default OrgaDetailsEditor;
