import React, { useMemo, useState } from 'react';

// Libraries
import { getSelectedGame } from 'constants/games';
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { HOT_SCRIMPS } from 'constants/routes';
import { Link } from 'react-router-dom';

// Components
import { DateTime } from 'luxon';
import TeamCardPicDisplay from 'components/Teams/utils/TeamCardPicDisplay';
import ReceivedRequestsList from './ReceivedRequestsList';

function PendingHotscrimp(props) {
  const { hotscrimp } = props;

  const { t } = useTranslation(['general']);

  const { timezone, language } = useSelector((state) => state.session);

  const [showReceivedRequests, setShowReceivedRequests] = useState(false);

  const {
    startDate, publicId, team,
  } = hotscrimp;

  const { cardPic, name } = team;

  const renderedTimeInfo = useMemo(() => {
    const currentTime = DateTime.fromISO(startDate).setZone(timezone).setLocale(language);

    return (
      `${currentTime.toLocaleString(DateTime.DATETIME_MED)}`
    );
  }, [hotscrimp, timezone, language]);

  const renderedGameModeInfo = useMemo(() => {
    const { game, mode } = hotscrimp;

    const gameData = getSelectedGame(game.tag, mode);

    return `${gameData.name} ${mode}`;
  }, [hotscrimp]);

  return (
    <div className="has-border-bottom-grey-dark">
      <Link
        to={`${HOT_SCRIMPS}/${publicId}`}
      >
        <div>
          <div className="columns">
            <div className="column is-3 is-flex has-content-centered pr-0">
              <div className="has-width-70 has-height-70">
                <TeamCardPicDisplay cardPic={cardPic} />
              </div>
            </div>
            <div className="column">
              <p
                className="is-size-5 abbreviate"
              >
                { name }
              </p>

              <p className="has-text-grey-light is-size-">
                { renderedTimeInfo }
              </p>

              <p className="has-text-grey-light is-size-">
                { renderedGameModeInfo }
              </p>
            </div>
          </div>

        </div>
      </Link>
      <div className="mb-3">
        <div className="has-text-centered">
          <button
            type="button"
            className="button has-background-grey-dark is-size-7 mx-2"
            onClick={() => setShowReceivedRequests(!showReceivedRequests)}
          >
            {showReceivedRequests ? t('general:hide_requests') : t('general:show_requests')}
          </button>
        </div>

        <div>
          {showReceivedRequests && (
          <ReceivedRequestsList publicId={publicId} />
          )}
        </div>

      </div>
    </div>
  );
}

PendingHotscrimp.propTypes = {
  hotscrimp: PropTypes.shape({
    publicId: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    game: PropTypes.shape({
      tag: PropTypes.string.isRequired,
    }).isRequired,
    mode: PropTypes.string.isRequired,
    team: PropTypes.shape({
      cardPic: PropTypes.string,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default PendingHotscrimp;
