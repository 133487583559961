import React from 'react';

// Libraries
import { Link } from 'react-router-dom';

// constants
import * as routes from 'constants/routes';

// styles
import { useTranslation } from 'react-i18next';
import LogoBottomDisplay from 'components/layout/Footer/LogoBottomDisplay';
import CopyButtonSimple from 'components/utils/CopyButtonSimple';
import styles from './styles.module.scss';

function Footer() {
  const { t } = useTranslation(['general']);

  const currentYear = new Date().getFullYear();

  return (
    <div className="br-5 is-relative mt-6" style={{ backgroundColor: '#121217' }}>
      <LogoBottomDisplay />

      <div className="pt-5 pb-3 px-4">
        <div className="has-text-centered mb-3">

          <div className="is-flex has-content-centered">

            <Link
              to={routes.TOS}
              className="is-size-6 has-text-grey"
            >
              {t('general:tos')}
            </Link>

            <Link
              to={routes.TOURNAMENT_TOS}
              className="is-size-6 has-text-grey ml-5"
            >
              {t('general:tournament_tos')}
            </Link>

            <Link
              to={routes.PRIVACY}
              className="is-size-6 has-text-grey ml-5"
            >
              {t('general:privacy_policy')}
            </Link>

            <Link
              to={routes.IMPRINT}
              className="is-size-6 has-text-grey ml-5"
            >
              {t('general:imprint')}
            </Link>

          </div>
        </div>

        <div className="columns is-marginless is-mobile is-multiline">
          <div className="column is-narrow has-width-250">
            <div className="is-flex has-content-centered-vertically has-fullheight">
              <a
                href="https://twitter.com/scrimpsWTF"
                target="_blank"
                rel="noreferrer"
                className="cleanButton br-5 grow_small"
                aria-label="X.com"
              >
                <i className="fab fa-x has-text-grey is-size-5" />
              </a>

              <a
                href="https://discord.gg/sNqJGBZBmr"
                target="_blank"
                rel="noreferrer"
                className="cleanButton br-5 grow_small ml-3"
                aria-label="Discord"
              >
                <i className="fab fa-discord has-text-grey is-size-5" />
              </a>

            </div>

          </div>

          <div className="column has-text-centered is-size-7 is-flex has-content-centered">

            <div className="has-margin-top-auto">
              <a href="https://minkz.de" target="_blank" rel="noreferrer" className="has-text-primary">
                {`© MINKZ ${currentYear} | All rights reserved`}
              </a>
              <p className="has-margin-left-auto">
                {`${t('general:version')} 3.0.0`}
              </p>
            </div>

          </div>

          <div className="column is-narrow has-width-250 is-flex has-content-centered-vertically has-content-right">
            <CopyButtonSimple
              text={window.location.origin}
              className="button is-primary has-text-weight-semibold"
            >
              {t('general:invite_a_friend')}
            </CopyButtonSimple>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Footer;
