import React, { useState } from 'react';

// Libraries
import stats from 'analytics/matomo';
import PropTypes from 'prop-types';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Components

// Assets
import coverImg from 'assets/images/base/onboarding_player_details.png';

// Actions
import { updateTeam } from 'store/teams';

import TriangleDisplay from 'components/utils/TriangleDisplay';
import DescriptionEditor from 'components/utils/inputs/DescriptionEditor';

// COnstants
import { MAX_TEAM_DESCRIPTION_LENGTH, MAX_TEAM_NATIONALITIES } from 'constants/team';
import NationalitySelector from 'components/utils/NationalitySelector';
import SocialLinksEditor from 'components/utils/SocialLinksEditor';

// Assets

function TeamDetailsEditor(props) {
  const { disabled, setDisabled, onDone } = props;
  /*   const {
    timezone, region: userRegion,
  } = useSelector((state) => state.session); */

  const { t } = useTranslation(['general', 'validation', 'errors']);

  const { teams } = useSelector((state) => state.teams);
  const newlyCreatedTeam = teams[teams.length - 1];
  const {
    name, publicId, game, platform, contact: teamContact,
  } = newlyCreatedTeam;

  const dispatch = useDispatch();

  const { patch } = useAPI();

  const [contact, setContact] = useState(teamContact);
  const [nationalities, setNationalities] = useState([]);
  const [description, setDescription] = useState('');

  const [status, setStatus] = useState('');

  const saveTeamDetails = async () => {
    try {
      setStatus('');
      setDisabled(true);

      const data = {
        name,
        game,
        contact,
        nationalities,
        description,
        platform,
      };

      const team = await patch(`/teams/${publicId}`, data);

      stats.push(['trackEvent', 'Team Creator', 'Team details set']);

      dispatch(updateTeam(team));

      setDisabled(false);
      onDone();
    } catch (e) {
      console.log(e);

      setStatus(t('errors:could_not_save_error'));

      setDisabled(false);
    }
  };

  return (

    <div className="box p-0 has-fullheight">

      <div className="columns has-fullheight is-multiline">

        <div
          className="column is-5-desktop has-min-width-300 is-5-tablet p-0"
        >
          <div
            className="has-fullheight has-text-left"
            style={{ backgroundImage: `url(${coverImg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
          >
            <div className="p-5">
              <h1
                className="is-size-2-dekstop is-size-2-tablet has-text-weight-semibold"
              >
                {t('general:team_details')}
              </h1>
            </div>
          </div>
        </div>

        <div className="column p-0">

          <div className="field pt-3 px-5 pb-6 is-flex flex-direction-column has-fullheight is-relative has-background-black-gradient has-overflow-y-auto is-max-fullheight-80">
            <TriangleDisplay />

            <div className="is-relative">

              <div className="columns is-multiline">
                <div className="column is-12">
                  <p className="mb-5 is-size-5">
                    {t('general:team_details')}
                  </p>
                  <DescriptionEditor description={description} setDescription={setDescription} maxLength={MAX_TEAM_DESCRIPTION_LENGTH} />
                </div>
                <div className="column is-12">
                  <NationalitySelector
                    nationalities={nationalities}
                    setNationalities={setNationalities}
                    maxAmount={MAX_TEAM_NATIONALITIES}
                  />
                </div>

                <div className="column is-12">
                  <SocialLinksEditor contact={contact} setContact={setContact} />
                </div>
              </div>
            </div>

            <div className="is-inline-block has-text-centered mt-5">
              <button
                type="button"
                onClick={saveTeamDetails}
                disabled={disabled}
                className={`button is-size-5 px-5 has-background-primary has-text-white has-text-weight-semibold is-inline-block ${disabled ? 'is-loading' : ''}`}
              >
                {t('general:continue')}
              </button>
            </div>
            {
                status && (
                  <div className="has-text-centered mt-5">
                    <p className=" has-text-white">
                      {status}
                    </p>
                  </div>
                )
              }

            <div className="has-text-centered">
              <button
                type="button"
                className="cleanButton has-text-white has-text-weight-semibold mt-5"
                onClick={onDone}
              >
                {t('general:skip')}
              </button>
            </div>
          </div>

        </div>
      </div>

    </div>

  );
}

TeamDetailsEditor.propTypes = {
  disabled: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
};

export default TeamDetailsEditor;
