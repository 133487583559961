import React, { useState } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useTournament } from 'components/tournaments/TournamentPage';

// Constants
import { MAX_TOURNAMENT_DESCRIPTION_LENGTH } from 'constants/tournaments';

// Components
import AsyncTextareaEditor from 'components/utils/inputs/AsyncTextareaEditor';

function TournamentDescriptionEditor() {
  const { info, setInfo, publicId } = useTournament();
  const { description } = info;

  const { post } = useAPI();

  const saveDescription = async (newDescription) => {
    const data = { description: newDescription };

    await post(`/tournaments/description/${publicId}`, data);

    setInfo({ description: newDescription });

    stats.push(['trackEvent', 'Tournament Editor', 'New description set']);
  };

  return (
    <AsyncTextareaEditor
      label="description"
      text={description}
      onSave={saveDescription}
      maxLength={MAX_TOURNAMENT_DESCRIPTION_LENGTH}
    />
  );
}

export default TournamentDescriptionEditor;
