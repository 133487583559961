import React, { useState } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';

// HOCs
import withAuthentication from 'components/hocs/withAuthentication';

// Actions
import { setProfile } from 'store/session';

// Components

import TriangleDisplay from 'components/utils/TriangleDisplay';
import DeleteAccountPopup from './DeleteAccountPopup';

// Actions

function UserProfile() {
  const {
    email, name, contact,
  } = useSelector((state) => state.session);

  const { t } = useTranslation(['general', 'validation']);

  return (
    <div className="is-min-fullheight pb-6">
      <TriangleDisplay />

      <div
        className="columns is-centered is-mobile is-multiline m-0 px-0 is-min-fullheight"
      >

        <div className="column is-11-desktop is-11-tablet is-11-mobile">

          <div
            className="pt-6 pb-6"
          >

            <div className="columns is-multiline">

              <div className="column is-narrow">
                <p
                  className="is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-weight-semibold"
                >
                  {t('general:your_account')}
                </p>
                <p
                  className="is-size-4-desktop is-size-5-tablet is-size-5-mobile has-text-grey"
                >
                  {t('general:your_data')}
                </p>

              </div>

            </div>

          </div>

          <div className="columns is-multiline">

            <div className="column is-flex flex-direction-column is-4">

              <p className="mb-2 has-text-weight-semibold">
                { t('general:personal_data')}
              </p>

              <div className="box has-fullheight">
                <div className="is-flex">
                  <p>
                    {`${t('general:email')}:`}
                  </p>
                  <p className="ml-3 has-text-weight-semibold">
                    {email}
                  </p>

                </div>
                <div className="mt-6">
                  <DeleteAccountPopup />
                </div>

              </div>

            </div>

            <div className="column is-flex flex-direction-column is-3" />

          </div>

        </div>
      </div>

    </div>
  );
}

export default withAuthentication(UserProfile);
