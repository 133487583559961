import React, { useState, useMemo, useEffect } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';
import AdminAlertButton from '../AdminAlertButton';

function GameOutcomeSelector(props) {
  const { match } = props;

  const { publicId, participant, participantPermissions } = useTournament();
  const { canSetGameOutcome } = participantPermissions;

  const { t } = useTranslation(['general']);

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { post } = useAPI();

  const { participants, status: matchStatus } = match;
  const { gameResults, currentGameOutcome } = match.result;

  useEffect(() => {
    setDisabled(false);
  }, [match]);

  const setOutcome = async (outcome) => {
    try {
      setDisabled(true);
      setStatus('');

      const data = {
        outcome,
        matchId: match.publicId,
      };

      await post(`/tournaments/matches/outcome/${publicId}`, data);

      setDisabled(false);
    } catch (err) {
      console.log(err);

      setStatus(t('general:an_error_occurred'));
      setDisabled(false);
    }
  };

  const isPartOfMatch = useMemo(() => {
    if (!participant) return false;

    return participants.some((p) => p.publicId === participant.publicId);
  }, [participant, participants]);

  const renderResults = () => {
    if (matchStatus === 'upcoming') {
      return (
        <div className="has-text-centered mt-2">
          <p>
            {t('general:waiting_for_opponent')}
          </p>
        </div>
      );
    }

    if (!isPartOfMatch) {
      return gameResults.map((gameData, gameIndex) => {
        const { participant: winnerId } = gameData;

        const winnerName = participants.find((p) => p.publicId === winnerId).name;

        return (
          <div
            key={gameIndex}
            className=""
          >
            <div className="columns">
              <div className="column is-3 px-1 is-flex has-content-centered-vertically">
                <p className="has-text-grey-light">
                  {`${t('general:game')} ${gameIndex + 1}`}
                </p>
              </div>
              <div className="column px-1">
                <button
                  type="button"
                  className="button has-height-25 has-text-weight-bold has-fullwidth py-0 is-primary has-text-white"
                >
                  {winnerName}
                </button>
              </div>
            </div>
          </div>
        );
      });
    }

    return gameResults.map((gameData, gameIndex) => {
      const { participant: winnerId } = gameData;

      // check if player is winner
      const isPlayerWinner = winnerId === participant.publicId;

      return (
        <div
          key={gameIndex}
          className=""
        >
          <div className="columns">
            <div className="column is-3 px-1 is-flex has-content-centered-vertically">
              <p className="has-text-grey-light">
                {`${t('general:game')} ${gameIndex + 1}`}
              </p>
            </div>
            <div className="column px-1">
              <button
                type="button"
                className={`button has-height-25 is-uppercase has-text-weight-bold has-fullwidth py-0 has-text-white ${isPlayerWinner ? 'is-primary' : 'is-danger'}`}
              >
                {isPlayerWinner ? t('general:win') : t('general:loss')}
              </button>
            </div>
          </div>
        </div>
      );
    });
  };

  const resetOutcome = async () => {
    try {
      setDisabled(true);
      setStatus('');

      const data = {
        publicId: match.publicId,
      };

      await post(`/tournaments/matches/outcome/reset/${publicId}`, data);

      setDisabled(false);
    } catch (err) {
      console.log(err);
    }
  };

  const renderSelector = () => {
    if (
      !canSetGameOutcome
      || !isPartOfMatch
      || matchStatus === 'finished'
      || matchStatus === 'upcoming'
    ) return null;

    const outcomeData = currentGameOutcome.find((_outcomeData) => _outcomeData.participant === participant.publicId);

    if (outcomeData) {
      const isWin = outcomeData.outcome === 'win';

      if (matchStatus === 'dispute') {
        return (
          <div className="box has-background-darkest mt-3">
            <p className="has-text-centered has-text-weight-bold">
              {`${t('general:dispute')}!`}
            </p>

            <div className="mt-4">
              <p>
                {t('general:change_result')}
              </p>

              <div className="columns">
                <div className="column is-3 px-1 is-flex has-content-centered-vertically">
                  <p className="has-text-grey-light">
                    {`${t('general:game')} ${gameResults.length + 1}`}
                  </p>
                </div>
                <div className="column is-flex px-1">
                  <button
                    type="button"
                    className={`button has-height-25 is-uppercase has-text-weight-bold has-fullwidth has-border-dashed-white py-0 has-text-white is-primary ${isWin ? 'is-primary' : 'is-danger'}`}
                  >
                    {isWin ? t('general:win') : t('general:loss')}
                  </button>
                  <button
                    type="button"
                    className="cleanButton ml-2"
                    onClick={resetOutcome}
                    disabled={disabled}
                    aria-label={t('general:reset')}
                  >
                    <i className="fas fa-rotate-right has-text-grey is-size-5" />
                  </button>
                </div>
              </div>
            </div>

            <p className="has-text-centered my-2">
              {t('general:or')}
            </p>

            <div className="">
              <AdminAlertButton
                tournamentId={publicId}
                matchId={match.publicId}
              />
            </div>
          </div>
        );
      }

      return (
        <div>
          <div className="columns">
            <div className="column is-3 px-1 is-flex has-content-centered-vertically">
              <p className="has-text-grey-light">
                {`${t('general:game')} ${gameResults.length + 1}`}
              </p>
            </div>
            <div className="column is-flex px-1">
              <button
                type="button"
                className={`button has-height-25 is-uppercase has-text-weight-bold has-fullwidth has-border-dashed-white py-0 has-text-white is-primary ${isWin ? 'is-primary' : 'is-danger'}`}
              >
                {isWin ? t('general:win') : t('general:loss')}
              </button>
              <button
                type="button"
                className="cleanButton ml-2"
                onClick={resetOutcome}
                disabled={disabled}
                aria-label={t('general:reset')}
              >
                <i className="fas fa-rotate-right has-text-grey is-size-5" />
              </button>
            </div>
          </div>
          <p className="has-text-centered is-size-7">
            {`${t('general:waiting_for_opponent')}...`}
          </p>
        </div>
      );
    }

    return (
      <div>
        <div className="columns">
          <div className="column is-3 px-1 is-flex has-content-centered-vertically">
            <p className="has-text-grey-light">
              {`${t('general:game')} ${gameResults.length + 1}`}
            </p>
          </div>
          <div className="column px-1">
            <button
              type="button"
              className={`button has-height-25 is-uppercase has-text-weight-bold has-fullwidth py-0 has-text-white is-primary ${disabled ? 'is-loading' : ''}`}
              onClick={() => setOutcome('win')}
              disabled={disabled}
            >
              {t('general:win')}
            </button>
          </div>
          <div className="column px-1">
            <button
              type="button"
              className={`button has-height-25 is-uppercase has-text-weight-bold has-fullwidth py-0 is-danger ${disabled ? 'is-loading' : ''}`}
              onClick={() => setOutcome('loss')}
              disabled={disabled}
            >
              {t('general:loss')}
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="mb-2">
        <p className="has-text-centered mt-3 is-size-7">
          {`${t('general:best_of')} ${match.bestOf}`}
        </p>
      </div>

      { (isPartOfMatch || gameResults.length > 0) && (
        <p className="has-text-centered">
          {t('general:result')}
        </p>
      )}

      { renderResults() }
      { renderSelector() }

    </div>
  );
}

GameOutcomeSelector.propTypes = {
  match: PropTypes.shape({
    publicId: PropTypes.string,
    participants: PropTypes.arrayOf(PropTypes.shape({
      publicId: PropTypes.string,
    })),
    result: PropTypes.shape({
      gameResults: PropTypes.arrayOf(PropTypes.shape({
        participant: PropTypes.string,
        method: PropTypes.string,
      })),
      currentGameOutcome: PropTypes.arrayOf(PropTypes.shape({
        participant: PropTypes.string,
        outcome: PropTypes.string,
      })),
    }),
    status: PropTypes.string,
    bestOf: PropTypes.number,
  }).isRequired,
};

export default GameOutcomeSelector;
