import React, { useMemo } from 'react';

// Libraries
import { getRankFromGameMode, getSelectedGame, getSelectedModeFromGame } from 'constants/games';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';

// Components
import TournamentGameSettingsEditor from './TournamentGameSettingsEditor';

const TournamentGameSettingsHandler = () => {
  const {
    game, currentPhase,
  } = useTournament();

  const { t } = useTranslation(['general']);

  const renderedContent = useMemo(() => {
    if (currentPhase !== 'draft') {
    // TODO: Add a read-only version of the game settings

      const gameData = getSelectedGame(game.tag);
      const modeData = getSelectedModeFromGame(game.tag, game.mode);

      const uppercasedMode = modeData.name.charAt(0).toUpperCase() + modeData.name.slice(1);

      const { start, end } = game.mmrRange;

      const hasMmrRange = start !== -1 && end !== -1;

      let renderedMmrRange = null;

      if (hasMmrRange) {
        const startRank = getRankFromGameMode(game.tag, game.mode, start, 'mmr');
        const endRank = getRankFromGameMode(game.tag, game.mode, end, 'mmr');

        if (game.tag === 'RL') {
          renderedMmrRange = (
            <div className="mt-2">
              <p className="is-size-5 has-text-grey">
                {t('general:mmr_range')}
              </p>
              <p className="is-size-5 has-text-weight-bold">
                { `${startRank.name} (${start}) - ${endRank.name} (${end})` }
              </p>
            </div>
          );
        } else {
          renderedMmrRange = (
            <div className="mt-2">
              <p className="is-size-5 has-text-grey">
                {t('general:mmr_range')}
              </p>
              <p className="is-size-5 has-text-weight-bold">
                { `${startRank.name} - ${endRank.name}` }
              </p>
            </div>
          );
        }
      }

      return (
        <div>
          <div>
            <p className="is-size-5 has-text-grey">
              {t('general:game')}
            </p>
            <p className="is-size-5 has-text-weight-bold">
              {gameData.name}
            </p>
          </div>
          <div className="mt-2">
            <p className="is-size-5 has-text-grey">
              {t('general:mode')}
            </p>
            <p className="is-size-5 has-text-weight-bold">
              {uppercasedMode}
            </p>
          </div>

          { renderedMmrRange }
        </div>
      );
    }

    return (
      <TournamentGameSettingsEditor />
    );
  }, [game, currentPhase]);

  return renderedContent;
};

export default TournamentGameSettingsHandler;
