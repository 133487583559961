import React, { useMemo } from 'react';

// Hooks
import { useTeamProfile } from 'components/Teams/TeamProfile';
import { useTranslation } from 'react-i18next';

// Components

import TeamDescriptionEditor from './TeamDescriptionEditor';

const TeamDescriptionHandler = () => {
  const { permissions, description } = useTeamProfile();
  const { canUpdateTeam } = permissions;

  const { t } = useTranslation(['general']);

  const renderedDescription = useMemo(() => {
    if (!description && !canUpdateTeam) return null;

    if (!canUpdateTeam) {
      return (
        <div className="my-5">
          <p
            className="is-size-4-desktop is-size-5-touch has-text-white"
          >
            { t('general:description') }
          </p>
          <p className="has-text-standard">
            { description }
          </p>
        </div>
      );
    }

    return (
      <div className="my-5 has-max-width-400">
        <TeamDescriptionEditor />
      </div>
    );
  }, [description]);

  return renderedDescription;
};

export default TeamDescriptionHandler;
