import React from 'react';

// Hooks
import { useOrgaPage } from 'components/orgas/OrgaPage';

// Components
import OrgaBannerPicUploadPopup from './OrgaBannerPicUploadPopup';
import BannerPicDisplay from 'components/utils/BannerPicDisplay';

function OrgaBannerPicHandler() {
  const { permissions, bannerPic } = useOrgaPage();
  const { canUpdateOrga } = permissions;

  return (
    <div>
      <div className="has-border-primary-bottom-super-thick">
        <BannerPicDisplay img={bannerPic} url="/images/orgas/banner_pictures/" />
      </div>
      {
        canUpdateOrga && (
        <div className="mt-5 has-text-centered">
          <OrgaBannerPicUploadPopup />
        </div>
        )
      }
    </div>
  );
}

export default OrgaBannerPicHandler;
