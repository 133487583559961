import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useOrgaPage } from 'components/orgas/OrgaPage';

// Components
import OrgaEditRoleButton from './OrgaEditRoleButton';

function OrgaRoleHandler(props) {
  const { member } = props;

  const { permissions } = useOrgaPage();
  const { canSetPermissions, canSetOrgaRole, canSetIngameRole } = permissions;

  const renderedButton = useMemo(() => {
    if (!canSetPermissions && !canSetOrgaRole && !canSetIngameRole) return null;

    return <OrgaEditRoleButton member={member} />;
  }, [member]);

  return renderedButton;
}

OrgaRoleHandler.propTypes = {
  memberIndex: PropTypes.number.isRequired,
};

export default OrgaRoleHandler;
