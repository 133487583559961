import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';

// Components
import AsyncImageUploadPopup from 'components/utils/images/AsyncImageUploadPopup';

function TournamentOrganizerCardPicEditor() {
  const { publicId, info, setInfo } = useTournament();
  const { organizer } = info;
  const { cardPic } = organizer;

  const { t } = useTranslation(['general', 'errors']);
  const { post } = useAPI();

  const saveOrganizerCardPic = async (cardPicObj) => {
    const formData = new FormData();
    formData.append('cardPic', cardPicObj);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
    };

    const cardPicImg = await post(`/tournaments/organizer/cardPic/${publicId}`, formData, config);
    setInfo({ ...info, organizer: { ...organizer, cardPic: cardPicImg } });
  };

  return (
    <div className="has-width-200 has-min-height-200 has-fullheight">
      <AsyncImageUploadPopup
        label="organizer_logo"
        width={200}
        height={200}
        onSave={saveOrganizerCardPic}
        imgPath={cardPic ? `/tournaments/card_pictures/${cardPic}` : ''}
      />
    </div>
  );
}

export default TournamentOrganizerCardPicEditor;
