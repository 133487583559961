import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTournamentsParticipantsList } from 'components/tournaments/TournamentPage/common/TournamentParticipantsList';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import CardPicDisplay from 'components/utils/CardPicDisplay';
import TeamCardPicDisplay from 'components/Teams/utils/TeamCardPicDisplay';
import { Link } from 'react-router-dom';

// Constants
import { PLAYER_PROFILE, PLAYER_PROFILE_DISPLAY, TEAM_PROFILE } from 'constants/routes';
import { getRankFromGameMode } from 'constants/games';
import { useTournament } from 'components/tournaments/TournamentPage';
import Popup from 'reactjs-popup';

function TournamentParticipantHandler(props) {
  const { participant } = props;

  const {
    cardPic, name, players, participantType, publicId, participantPublicId, isCheckedIn, isDisqualified, mmr,
  } = participant;

  const { t } = useTranslation(['general']);
  const { playerId } = useSelector((state) => state.player.player);

  const { game } = useTournament();

  const {
    currentPhase,
    staffPermissions,
    setParticipantToKick, setParticipantToBan, setParticipantToDisqualify,
  } = useTournamentsParticipantsList();

  const renderedParticipantButtons = useMemo(() => {
    const { canKickParticipant, canBanParticipant, canDisqualifyParticipant } = staffPermissions;

    if (currentPhase === 'seeding' || currentPhase === 'finished') return null;

    if (currentPhase === 'in_progress' && canDisqualifyParticipant) {
      if (isDisqualified) return null;

      return (
        <div className="is-flex has-content-centered has-fullheight">
          <button
            className="button is-danger p-3 is-size-7 mx-1"
            type="button"
            aria-label={t('general:disqualify')}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setParticipantToDisqualify({ name, publicId });
            }}
          >
            <i className="fas fa-user-slash" />
          </button>
        </div>
      );
    }

    if (!canKickParticipant && !canBanParticipant) return null;

    return (
      <div className="is-flex has-content-centered-vertically p-0 has-fullheight">
        {
          canKickParticipant && (
            <button
              className="button is-danger p-3 is-size-7 mx-1"
              type="button"
              aria-label={t('general:kick')}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setParticipantToKick({ name, publicId });
              }}
            >
              <i className="fas fa-user-slash" />
            </button>
          )
        }
        {
          canBanParticipant && (
            <button
              className="button is-danger p-3 is-size-7 mx-1"
              type="button"
              aria-label={t('general:ban')}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setParticipantToBan({ name, publicId });
              }}
            >
              <i className="fas fa-ban" />
            </button>
          )
        }
      </div>
    );
  }, [staffPermissions, participant, name, publicId, currentPhase, isDisqualified, t]);

  const isTeam = participantType === 'team';

  const link = useMemo(() => {
    if (isTeam) {
      return `${TEAM_PROFILE}${participantPublicId}`;
    }

    if (participantPublicId === playerId) {
      return PLAYER_PROFILE;
    }

    return `${PLAYER_PROFILE_DISPLAY}${participantPublicId}`;
  }, [participantPublicId, isTeam, playerId]);

  const renderedPlayers = useMemo(() => {
    if (!players) return null;

    const _players = players.map((player, index) => (
      <div className="column is-narrow" key={index}>
        <div className="columns">
          <div className="column is-narrow has-width-40 p-1 is-flex has-content-centered">
            <CardPicDisplay img={player.cardPic} />
          </div>
          <div className="column p-1 has-width-100">
            <p className="break-word">
              {player.name}
            </p>

            { player.role && (
              <div>
                <p className="is-size-7">
                  {t(`general:${player.role}`)}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

    ));

    return (
      <div className="column py-0">
        <div className="columns is-multiline">
          {_players}
        </div>
      </div>
    );
  }, [players]);

  const renderedMmr = useMemo(() => {
    const rank = getRankFromGameMode(game.tag, game.mode, mmr, 'mmr');

    if (!rank || !rank.img) return null;

    if (game.tag === 'RL') {
      // show mmr number on hover
      return (
        <div className="column is-narrow pl-0 py-0">
          <Popup
            trigger={(
              <div className="has-text-centered has-fullheight is-flex flex-direction-column has-content-centered">
                <p className="has-text-grey-light">
                  {`${t('general:avg_mmr')}`}
                </p>
                <img
                  src={rank.img}
                  alt="rank"
                  className="image has-width-40 has-height-40 has-margin-auto mt-1"
                />
              </div>
            )}
            position="top center"
            on="hover"
          >
            <div className="box has-background-dark p-2 is-flex">
              <p className="has-text-centered">
                {`${t('general:mmr')}: ${mmr}`}
              </p>
            </div>
          </Popup>
        </div>
      );
    }

    return (
      <div className="column is-narrow">
        <div className="has-fullheight is-flex flex-direction-column has-content-centered">
          <div>
            <p className="has-text-grey-light">
              {`${t('general:avg_mmr')}`}
            </p>
            <img
              src={rank.img}
              alt="rank"
              className="image has-width-40 has-height-40 has-margin-auto mt-1"
            />
          </div>
        </div>
      </div>
    );
  }, [mmr, game.tag, game.mode, t]);

  return (
    <Link
      to={link}
      className="column is-12 p-1"
    >
      <div className="">
        <div className="box p-0">
          <div className="columns is-multiline">
            <div className={`column is-narrow has-max-width-${isTeam ? '100 p-2' : '45 p-0'}`}>
              {
                isTeam ? (
                  <TeamCardPicDisplay cardPic={cardPic} />
                ) : (
                  <CardPicDisplay img={cardPic} />
                )
              }
            </div>
            <div className="column">
              <p className="has-text-weight-bold break-word">
                {name}
              </p>

              {
                isTeam && (
                  <div className="columns is-multiline">
                    {renderedPlayers}
                  </div>
                )
              }

            </div>

            { renderedMmr }

            <div className="column is-2 py-0 is-flex has-content-centered">
              {isCheckedIn && (
                <span className="tag has-background-playerboard has-text-weight-bold has-text-white is-size-6">
                  {t('general:checked_in')}
                </span>
              )}
            </div>

            {
              currentPhase === 'in_progress' && isDisqualified && (
                <div className="column is-2 py-0 is-flex has-content-centered">
                  <span className="tag has-background-danger has-text-weight-bold has-text-white is-size-6">
                    {t('general:disqualified')}
                  </span>
                </div>
              )
            }

            <div className="column is-narrow">
              {renderedParticipantButtons}
            </div>

          </div>

        </div>

      </div>
    </Link>
  );
}

TournamentParticipantHandler.propTypes = {
  participant: PropTypes.shape({
    cardPic: PropTypes.string,
    name: PropTypes.string,
    players: PropTypes.arrayOf(PropTypes.shape({})),
    participantType: PropTypes.string,
    publicId: PropTypes.string,
    participantPublicId: PropTypes.string,
    isCheckedIn: PropTypes.bool,
    isDisqualified: PropTypes.bool,
  }).isRequired,
};

export default TournamentParticipantHandler;
