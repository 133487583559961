import React from 'react';

// HOCs
import withAuthentication from 'components/hocs/withAuthentication';

// Hooks
import { useTranslation } from 'react-i18next';

function Home() {
  const { t } = useTranslation();

  return (
    <div className="is-min-fullheight pb-6">

      <div className="columns is-centered is-mobile is-multiline m-0 px-0 is-min-fullheight">

        <div className="column is-10-desktop is-10-tablet is-11-mobile">

          <div
            className="pt-6 pb-6"
          >
            <div className="box boxshadow br-100 has-text-weight-semibold is-inline-flex has-content-centered py-2 px-4">
              <i className="fas fa-search" />
              <p
                className="ml-2 is-size-5 has-line-height-full"
              >
                {t('general:home')}
              </p>
            </div>

            <div className="">
              <p
                className="is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-weight-semibold"
              >
                {t('general:home')}
              </p>
              <p
                className="is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-grey"
              >
                {t('general:home_description_short')}
              </p>
            </div>

            <div className="columns is-multiline">

              <div className="column is-9-desktop is-9-tablet is-12-mobile">
                Filter
              </div>

              <div className="column">
                Create
              </div>

            </div>

          </div>

          <div className="columns is-multiline">

            <div className="column is-9-desktop is-9-tablet is-12-mobile">

              <p className="mb-2 has-text-weight-semibold">
                { t('general:overview')}
              </p>
              <div className="box p-0">
                Home
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
  );
}

export default withAuthentication(Home);
