import React, { useState, useMemo } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import { useAllTeamsList } from 'components/Teams/AllTeamsProvider';

// Components
import { TEAM_PROFILE } from 'constants/routes';
import { Link } from 'react-router-dom';
import LoginPopup from 'components/utils/LoginPopup';
import Popup from 'reactjs-popup';
import TeamCard from '../../TeamCard';

function AllTeamsDisplay() {
  const { teams } = useAllTeamsList();

  const { isAuthenticated } = useAuth0();

  const { timezone, language } = useSelector((state) => state.session);

  const { t } = useTranslation(['general']);

  const renderedEntries = useMemo(() => teams.map((teamData, index) => {
    if (!isAuthenticated) {
      return (
        <Popup
          trigger={(
            <div
              key={index}
              className="column is-narrow has-cursor-pointer"
            >
              <TeamCard teamData={teamData} />
            </div>
      )}
          position="top center"
          keepTooltipInside="#root"
          closeOnDocumentClick
          closeOnEscape
          modal
          key={index}
        >
          {
        (close) => (<LoginPopup close={close} text={t('general:login_to_see_team_info')} />)
      }

        </Popup>
      );
    }

    return (
      <div
        className="column is-narrow"
        key={index}
      >
        <Link
          to={`${TEAM_PROFILE}${teamData.publicId}`}
          key={index}
        >
          <TeamCard teamData={teamData} />
        </Link>
      </div>

    );
  }), [teams, language]);

  return (
    <div>
      <p className="has-text-weight-semibold mb-3">
        {t('general:all_teams')}
      </p>
      <div className="columns has-text-centered-mobile is-centered-mobile is-multiline">
        { renderedEntries }
      </div>

    </div>
  );
}

export default AllTeamsDisplay;
