import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import PlayerDisplay from './PlayerDisplay';

function HotscrimpPlayerDisplay(props) {
  const { players } = props;

  const renderedPlayers = useMemo(() => {
    const _players = players.map((player, index) => (
      <div
        className="my-5 has-margin-left-auto"
        key={index}
      >
        <PlayerDisplay player={player} />
      </div>
    ));

    return _players;
  }, [players]);

  return (
    <div className="is-flex flex-direction-column">
      <div className="is-flex flex-direction-column">
        {renderedPlayers}
      </div>
    </div>
  );
}

HotscrimpPlayerDisplay.propTypes = {
  players: PropTypes.arrayOf(PropTypes.shape({

  })).isRequired,
};

export default HotscrimpPlayerDisplay;
