import React, { useMemo } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

function AchievementsDisplay({ achievements = [] }) {
  const { t } = useTranslation(['general']);

  const renderedAchievements = useMemo(() => achievements.map((achievement) => (
    <div
      key={achievement.id}
      className=""
    >
      <p
        className="is-size-6 my-2"
      >
        {t(`general:${achievement}`)}
      </p>
    </div>
  )), [achievements, t]);

  return (
    <div>
      <p className="has-text-weight-semibold is-size-4-desktop is-size-5-touch">
        {t('general:achievements')}
      </p>

      {renderedAchievements && renderedAchievements.length > 0 ? renderedAchievements : (
        <p className="mt-2">
          {t('general:no_achievements_yet')}
        </p>
      )}
    </div>
  );
}

export default AchievementsDisplay;
