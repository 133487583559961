import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import CardPicDisplay from 'components/utils/CardPicDisplay';

function TeamCardPicDisplay(props) {
  const { cardPic } = props;

  return <CardPicDisplay quadratic img={cardPic} url="/images/teams/card_pictures/" />;
}

TeamCardPicDisplay.propTypes = {
  cardPic: PropTypes.string.isRequired,
};

export default TeamCardPicDisplay;
