import React, { useState } from 'react';

// Libraries
import { getSelectedModeFromGame } from 'constants/games';

// Hooks
import { useHotscrimp } from 'components/HotScrimps/HotscrimpsDisplayPage';
import { useAuth0 } from '@auth0/auth0-react';
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Constants
import { HOT_SCRIMPS_REQUEST } from 'constants/routes';

// Components
import Popup from 'reactjs-popup';
import HotscrimpPlayerSelector from 'components/HotScrimps/utils/HotscrimpPlayerSelector';

import TeamSelector from './TeamSelector';

/**
 * Displays the popup to create a hotscrimp request
 * @returns {React.Component} - The component
 */
function HotscrimpRequestPopup() {
  const {
    game, mmrRange, mode, showRequestPopup, setShowRequestPopup, publicId, hotscrimpTeam,
  } = useHotscrimp();
  const { isAuthenticated } = useAuth0();

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const [team, setTeam] = useState();
  const [selectedPlayers, setSelectedPlayers] = useState([]);

  const { t } = useTranslation(['hotscrimps', 'error']);
  const navigate = useNavigate();
  const { post } = useAPI();

  const resetData = () => {
    setShowRequestPopup(false);

    setSelectedPlayers([]);
    setTeam();
    setStatus('');
  };

  const sendRequest = async () => {
    try {
      const { amountPlayers } = getSelectedModeFromGame(game.tag, mode);

      if (selectedPlayers.length !== amountPlayers) {
        setStatus(t('errors:insufficient_amount_players_error'));
        return;
      }

      if (mmrRange.start !== -1) {
        const allMmrs = [];

        for (let i = 0; i < selectedPlayers.length; i++) {
          const playerId = selectedPlayers[i];

          const player = team.members.find((p) => p.playerId === playerId);

          // eslint-disable-next-line no-continue
          if (!player) continue;

          const rank = player.ranks.find((r) => r.game === game.tag && r.mode === mode);

          // eslint-disable-next-line no-continue
          if (!rank) continue;

          allMmrs.push(rank.mmr);
        }

        const averageMmr = Math.floor(allMmrs.reduce((a, b) => a + b, 0) / allMmrs.length);

        // check if the average mmr is within the mmr range
        if (averageMmr < mmrRange.start || averageMmr > mmrRange.end) {
          setStatus(t('errors:avg_mmr_not_in_mmr_range_error'));
          return;
        }
      }

      const hotscrimpPlayers = hotscrimpTeam.players;
      const hotscrimpPlayerIds = hotscrimpPlayers.map((p) => p.playerId);
      const allPlayers = [...selectedPlayers, ...hotscrimpPlayerIds];

      // check if there are any duplicate players
      if (allPlayers.length !== new Set(allPlayers).size) {
        setStatus(t('errors:duplicate_players_error'));
        return;
      }

      setDisabled(true);

      const data = {
        hotscrimpId: publicId,
        teamId: team.publicId,
        players: selectedPlayers,
      };

      const id = await post('/hotscrimps/requests/create', data);

      setDisabled(false);

      navigate(`${HOT_SCRIMPS_REQUEST}/${id}`);
    } catch (e) {
      setDisabled(false);

      // if status code is 409, it means that the request already exists
      if (e.response && e.response.status === 409) {
        setStatus(t('hotscrimps:hotscrimp_request_already_exists'));
        return;
      }

      if (e.response && e.response.status === 429) {
        setStatus(t('hotscrimps:hotscrimp_request_has_been_closed'));
        return;
      }

      setStatus(t('errors:could_not_send_request_error'));

      console.error(e);
    }
  };

  if (!isAuthenticated) return null;

  return (
    <Popup
      position="top center"
      keepTooltipInside="#contentWrapper"
      repositionOnResize
      modal
      open={showRequestPopup}
      onClose={resetData}
      closeOnEscape={!disabled}
      closeOnDocumentClick={!disabled}
    >
      {(close) => (
        <div className="is-fullwidth has-min-width-300 has-max-width-700 is-flex has-content-centered is-in-center has-overflow-hidden box boxshadow has-background-black-gradient p-0">
          <div className="has-fullwidth is-max-fullheight has-overflow-y-auto has-min-height-500">
            <div
              className="has-text-centered"
            >
              <div
                className="has-gradient-primary has-fullwidth is-relative py-6"
              >

                <div className="is-absolute" style={{ right: 20, top: 20, zIndex: '99' }}>

                  <div className="has-margin-left-auto">
                    <button
                      type="button"
                      onClick={close}
                      className="cleanButton has-text-white"
                    >
                      <i className="fas fa-times is-size-5" />
                    </button>
                  </div>
                </div>

                <p
                  className="is-size-2-desktop is-size-3-touch has-text-weight-semibold"
                >
                  {t('general:select_team')}
                </p>

              </div>
            </div>

            <div className="p-6">
              <TeamSelector team={team} setTeam={setTeam} />

              {
                team && (
                  <div>
                    <HotscrimpPlayerSelector
                      game={game}
                      mode={mode}
                      allPlayers={team.members}
                      players={selectedPlayers}
                      setPlayers={setSelectedPlayers}
                    />
                    {
                      status && (
                        <p
                          className="has-text-centered has-text-white has-text-weight-semibold mt-6"
                        >
                          {status}
                        </p>
                      )
                    }
                    <div className="has-text-centered">
                      <button
                        type="button"
                        className={`button is-primary mt-6 ${disabled ? 'is-loading' : ''}`}
                        onClick={sendRequest}
                        disabled={disabled}
                      >
                        {t('general:send_request')}
                      </button>
                    </div>

                  </div>
                )
              }
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
}

export default HotscrimpRequestPopup;
