import React from 'react';

// Libraries
import PropTypes from 'prop-types';

function MmrNumberSelector(props) {
  const {
    mmr, setMmr, maxMmr, disabled,
  } = props;

  const _setMmr = (value) => {
    if (!value) {
      setMmr(-1);
      return;
    }

    const intValue = parseInt(value, 10);

    // dont allow negative values
    if (Number.isNaN(intValue) || intValue < 0 || intValue > maxMmr) {
      return;
    }

    setMmr(intValue);
  };

  return (
    <div>
      <input
        className="input has-width-100"
        type="input"
        value={mmr <= 0 ? '' : mmr}
        onChange={(e) => _setMmr(e.target.value)}
        disabled={disabled}
      />
    </div>
  );
}

MmrNumberSelector.propTypes = {
  mmr: PropTypes.number.isRequired,
  setMmr: PropTypes.func.isRequired,
  maxMmr: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

MmrNumberSelector.defaultProps = {
  disabled: false,
};

export default MmrNumberSelector;
