import React, { useMemo } from 'react';

// Libraries
import { getSelectedGame } from 'constants/games';

const GameIconDisplay = ({ game, iconType = 'normal', className = '' }) => {
  const renderedGame = useMemo(() => {
    if (!game) return null;

    const gameData = getSelectedGame(game);

    if (!gameData) return null;

    let renderedImage = gameData.icon;

    if (iconType === 'white') {
      renderedImage = gameData.iconWhite;
    }

    return (
      <img
        className={className}
        src={renderedImage}
        alt={game}
      />
    );
  }, [game, className]);

  return renderedGame;
};

export default GameIconDisplay;
