import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function ShareCopyButton(props) {
  const {
    text, icon, isLink, url, indicator,
  } = props;

  const [copied, setCopied] = useState(false);

  const copyInfo = () => {
    // copy(playerId);
    navigator.clipboard.writeText(`${text}`);
    setCopied(true);
  };

  useEffect(() => {
    if (!copied) return undefined;

    const timeout = setTimeout(() => {
      setCopied(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);

  return (
    <div>
      {
      isLink ? (
        <a
          className="button has-border-grey-light br-5 has-no-background has-text-white is-size-5 has-width-50 has-height-50"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className={`${copied ? 'fas fa-check' : icon}`} />
        </a>
      ) : (
        <button
          type="button"
          className="button has-border-grey-light has-hover br-5 has-no-background has-text-white is-size-5 has-width-50 has-height-50"
          onClick={copyInfo}
        >
          <i className={`${copied ? 'fas fa-check' : icon}`} />
        </button>
      )
      }

      {
      indicator && (
        <p
          className="is-size-7 mt-2"
        >
          {indicator}
        </p>
      )
  }
    </div>
  );
}

export default ShareCopyButton;
