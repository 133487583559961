import React, { useEffect, useMemo, useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Libraries
import { getSelectedModeFromGame } from 'constants/games';
import PropTypes from 'prop-types';

// Components
import { Range } from 'react-range';
import MmrNumberSelector from '../MmrNumberSelector';

function MmrRangeSelector(props) {
  const { rank, setMmr, disabled } = props;
  const { game, mode, mmr } = rank;

  const [tempStartMmr, setTempStartMmmr] = useState(mmr.start);
  const [tempEndMmr, setTempEndMmmr] = useState(mmr.end);

  useEffect(() => {
    setTempStartMmmr(mmr.start);
  }, [mmr.start]);

  useEffect(() => {
    setTempEndMmmr(mmr.end);
  }, [mmr.end]);

  const { t } = useTranslation(['general']);

  const ranks = useMemo(() => {
    const modeObj = getSelectedModeFromGame(game, mode);

    return modeObj.ranks;
  }, [game, mode]);

  const getRank = (mmrValue) => {
    if (mmrValue === -1) {
      return ranks[0];
    }
    // mmr ranks have minMmr and maxMmr, we need to find the one that matches our current mmr
    const mmrRank = ranks.find((_rank) => _rank.minMmr <= mmrValue && _rank.maxMmr >= mmrValue);

    if (mmrRank === undefined) {
      return ranks[0];
    }

    return mmrRank;
  };

  const startRank = useMemo(() => getRank(mmr.start), [ranks, mmr.start]);
  const endRank = useMemo(() => getRank(mmr.end), [ranks, mmr.end]);

  const _setMmr = (values) => {
    const startRankClass = values[0];
    // get the average mmr for the start rank for that rank class
    const startMmr = Math.floor((ranks[startRankClass].minMmr + ranks[startRankClass].maxMmr) / 2);

    const endRankClass = values[1];
    const endMmr = Math.floor((ranks[endRankClass].minMmr + ranks[endRankClass].maxMmr) / 2);

    setMmr({ start: startMmr, end: endMmr });
  };

  const setStartMmr = (value) => {
    // if value is valid, set it to the state
    if (value <= mmr.end && value <= 9999) {
      // if the temp end value is valid with the new start value, set both to the state
      if (tempEndMmr >= value && tempEndMmr <= 9999) {
        setMmr({ start: value, end: tempEndMmr });
        return;
      }

      // otherwise, just set the start value and keep the old end value
      setMmr({ ...mmr, start: value });
    }

    setTempStartMmmr(value);
  };

  const setEndMmr = (value) => {
    if (value >= mmr.start && value <= 9999) {
      if (tempStartMmr <= value) {
        setMmr({ start: tempStartMmr, end: value });
        return;
      }
      setMmr({ ...mmr, end: value });
    }

    setTempEndMmmr(value);
  };

  const renderedRanks = useMemo(() => {
    if (!startRank || !endRank) return null;

    return (
      <div className="mt-4 has-max-width-350">
        <div className="">
          <p className="has-text-grey is-size-5">
            {t('general:mmr_range')}
          </p>
          <div className="is-flex">
            <div className="has-width-250 has-gradient-primary px-5 br-5 mt-2 has-fullheight">

              <Range
                step={1}
                min={0}
                max={ranks.length - 1}
                values={[startRank.class, endRank.class]}
                allowOverlap={false}
                disabled={disabled}
                onChange={(values) => _setMmr(values)}
                renderTrack={({ props: _props, children }) => (
                  <div className="flex-grow">
                    <div
                      {..._props}
                      style={{
                        // eslint-disable-next-line
                  ..._props.style,
                        height: '25px',
                        width: '100%',
                        borderRadius: '5px',
                      }}
                    >
                      { children }
                    </div>
                  </div>
                )}
                renderThumb={({ props: _props, isDragged }) => (
                  <div
                    {..._props}
                    style={{
                      // eslint-disable-next-line
                ..._props.style,
                      height: '25px',
                      width: '50px',
                      borderRadius: '4px',
                      backgroundColor: '#FFF',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid #ddd',
                    }}
                  >
                    <div
                      style={{
                        height: '16px',
                        width: '5px',
                      }}
                    />
                  </div>
                )}
              />
            </div>

          </div>

        </div>

        <div className="">
          <div className="is-flex has-content-centered-vertically">

            <div>
              <p className="is-size-6 has-text-grey mt-4">
                {t('general:from')}
              </p>

              <div className="mx-1 is-flex flex-direction-column align-items-center">
                <p className="">
                  { startRank.name }
                </p>
                { startRank.class !== -1
              && (
              <img
                className="has-width-40 has-height-40 ml-2"
                src={startRank.img}
                alt=""
              />
              )}
              </div>
            </div>

            {
            game === 'RL' && (
            <div className="mx-4">
              <MmrNumberSelector
                mmr={tempStartMmr}
                setMmr={setStartMmr}
                maxMmr={9999}
              />
            </div>
            )
          }
          </div>

          <div className="is-flex has-content-centered-vertically">
            <div>
              <p className="is-size-6 has-text-grey mt-4">
                {t('general:to')}
              </p>

              <div className="mx-1 is-flex flex-direction-column align-items-center">
                <p className="">
                  { endRank.name }
                </p>
                { endRank.class !== -1
              && (
              <img
                className="has-width-40 has-height-40 ml-2"
                src={endRank.img}
                alt=""
              />
              )}
              </div>
            </div>

            {
            game === 'RL' && (
            <div className="mx-4">
              <MmrNumberSelector
                mmr={tempEndMmr}
                setMmr={setEndMmr}
                maxMmr={9999}
              />
            </div>
            )
          }
          </div>
        </div>

      </div>
    );
  }, [rank, tempStartMmr, tempEndMmr, disabled]);

  return renderedRanks;
}

MmrRangeSelector.propTypes = {
  rank: PropTypes.shape({
    game: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    mmr: PropTypes.shape({
      start: PropTypes.number.isRequired,
      end: PropTypes.number.isRequired,
    }),
  }).isRequired,
  setMmr: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

MmrRangeSelector.defaultProps = {
  disabled: false,
};

export default MmrRangeSelector;
