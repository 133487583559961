import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Constants
import { PINBOARD_EDIT } from 'constants/routes';

// Hooks
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Assets
import paidSvg from 'assets/images/icons/paid.svg';
import unpaidSvg from 'assets/images/icons/unpaid.svg';
import competitivePng from 'assets/images/icons/engagement_competitive.png';
import justForFunPng from 'assets/images/icons/engagement_just4fun.png';
import rivalRushPng from 'assets/images/branded/rival_rush.png';
import fragsterLeagueSvg from 'assets/images/branded/fragster_league_logo.svg';
import destinyLeaguePng from 'assets/images/branded/destiny_league.png';

// Components
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import LoginPopup from 'components/utils/LoginPopup';
import ContactCopyDisplay from 'components/utils/ContactCopyDisplay';
import CardPicDisplay from 'components/utils/CardPicDisplay';
import PlatformDisplay from 'components/utils/PlatformDisplay';
import GameIconDisplay from 'components/utils/games/GameIconDisplay';
import { DateTime } from 'luxon';

function PinboardEntryPageDisplay({ entry }) {
  const {
    entryType, providerType, specialIcons, ambitionTypes, qualification, game, platform, title, subTitle, description, logoPic, contact,
    updatedAt,
  } = entry;

  const { isAuthenticated } = useAuth0();
  const { t } = useTranslation(['general']);
  const { playerId } = useSelector((state) => state.player.player);
  const { timezone, language } = useSelector((state) => state.session);

  const renderedTime = useMemo(() => {
    const currentTime = DateTime.fromISO(updatedAt).setZone(timezone).setLocale(language);

    return (
      <p className="has-text-grey-lighter is-size-7 mt-6">
        {currentTime.toLocaleString(DateTime.DATETIME_MED)}
      </p>
    );
  }, [updatedAt, timezone, language]);

  const renderedAmbitionTypes = useMemo(() => ambitionTypes.map((ambitionType) => {
    let icon = '';
    switch (ambitionType) {
      case 'just_for_fun':
        icon = justForFunPng;
        break;
      case 'competitive':
        icon = competitivePng;
        break;
      case 'paid':
        icon = paidSvg;
        break;
      case 'unpaid':
        icon = unpaidSvg;
        break;
      default:
        break;
    }

    return (
      <div
        key={ambitionType}
        className="column is-narrow p-0 is-flex align-items-center"
      >
        <div
          className="has-background-darkest py-1 px-2 has-text-white has-text-weight-semibold is-inline-flex has-content-centered br-100 is-size-7 mr-1"
        >
          {
            icon && (
            <img
              src={icon}
              alt={ambitionType}
              className="has-width-30 has-height-30 is-block mr-2"
            />
            )
          }
          <p>
            {t(`pinboard:${ambitionType}`)}
          </p>
        </div>
      </div>
    );
  }), [ambitionTypes, t]);

  const renderedSpecialIcons = useMemo(() => specialIcons.map((specialIcon) => {
    let renderedIcon = null;

    switch (specialIcon) {
      case 'rival_rush':
        renderedIcon = (
          <img
            src={rivalRushPng}
            alt={specialIcon}
            className="has-width-120"
          />
        );
        break;
      case 'fragster_league':
        renderedIcon = (
          <img
            src={fragsterLeagueSvg}
            alt={specialIcon}
            className="has-width-120"
          />
        );
        break;
      case 'destiny_league':
        renderedIcon = (
          <img
            src={destinyLeaguePng}
            alt={specialIcon}
            className="has-width-120"
          />
        );
        break;
      default:
        return null;
    }

    return (
      <div
        key={specialIcon}
        className="column is-narrow py-0 is-flex has-content-centered"
      >
        {renderedIcon}
      </div>
    );
  }), [specialIcons]);

  const renderedEntryType = useMemo(() => {
    let entryTypeString = entryType === 'offer' ? t('pinboard:offers') : t('pinboard:searches');
    entryTypeString = entryTypeString.toLowerCase();

    return (
      <p
        className={`has-text-weight-light my-2 is-size-5 ${entryType === 'offer' ? 'has-text-hotscrimps' : 'has-text-pinboard-green'}`}
      >
        {
        `${t(`general:${providerType}`)} ${entryTypeString}:`
        }
      </p>
    );
  }, []);

  const hasContactData = contact ? Object.values(contact).some((value) => value) : false;

  const isCreator = playerId !== '' && playerId === entry.playerId;

  return (
    <div>

      <div className="columns is-multiline">
        <div className="column is-9 px-0">

          {isCreator && (
            <div className="has-text-">
              <Link
                to={`${PINBOARD_EDIT}/${entry.publicId}`}
                className="button has-background-pinboard-red has-text-left has-text-weight-bold"
              >
                <i className="fas fa-edit has-text-white is-size-5 has-text-white mr-2" />
                {t('pinboard:edit_entry')}
              </Link>
            </div>
          )}

          { renderedTime}

          { renderedEntryType }

          <h1 className="title">
            {title}
          </h1>

          {
            subTitle && (
              <h2
                className="subtitle mt-2"
              >
                {subTitle}
              </h2>
            )
          }

          {
            renderedSpecialIcons.length > 0 && (
            <div className="mt-5">
              <div className="columns is-multiline">
                {renderedSpecialIcons}
              </div>
            </div>
            )
          }

          <div className="mt-3">
            <div className="columns is-multiline">
              {
                game && game.tag && (
                  <div className="column is-narrow is-flex align-items-center">
                    <div className="has-width-25 is-flex align-items-center">
                      <GameIconDisplay
                        game={game.tag}
                        iconType="white"
                      />
                    </div>
                  </div>
                )
              }
              {
                platform && (
                  <div className="column is-narrow align-items-center">
                    <div className="has-width-25 is-flex align-items-center">
                      <PlatformDisplay platform={platform} />
                    </div>
                  </div>
                )
              }
              {renderedAmbitionTypes}
              { qualification && (
              <div
                key={qualification}
                className="column is-narrow p-0 is-flex align-items-center"
              >
                <div
                  className="has-background-darkest py-1 px-2 has-text-white has-text-weight-semibold br-100 is-size-7 mr-1"
                >
                  <div className="has-height-30 is-inline-flex has-content-centered">
                    <i className="fas fa-check-circle has-text-playerboard mr-1 is-size-5" />
                    <p>
                      {t(`general:${qualification}`)}
                    </p>
                  </div>
                </div>
              </div>
              )}
            </div>
          </div>

          {
            description && (
              <ReactQuill
                theme="snow"
                className="my-6"
                value={description}
                modules={{
                  toolbar: false,
                }}
                readOnly
              />

            )
          }
        </div>

        {
          logoPic && (
            <div className="column is-3 px-0">
              <CardPicDisplay
                img={logoPic}
                url="/images/pinboard/logo_pictures/"
              />
            </div>
          )
        }
      </div>

      {
        hasContactData && isAuthenticated && (
          <div className="columns is-multiline">
            <div className="column is-3">
              <p className="has-text-weight-bold">
                {t('general:contact_info')}
              </p>
            </div>
            <div className="column">
              <ContactCopyDisplay contact={contact} />
            </div>
          </div>
        )
      }
      {
        !isAuthenticated && (
        <div className="mt-6">
          <LoginPopup isEmbed />
        </div>
        )
      }
    </div>
  );
}

PinboardEntryPageDisplay.propTypes = {
  entry: PropTypes.shape({
    publicId: PropTypes.string,
    entryType: PropTypes.string,
    providerType: PropTypes.string,
    consumerType: PropTypes.string,
    specialIcons: PropTypes.arrayOf(PropTypes.string),
    ambitionTypes: PropTypes.arrayOf(PropTypes.string),
    qualification: PropTypes.string,
    game: PropTypes.shape({
      tag: PropTypes.string,
    }),
    platform: PropTypes.string,
    updatedAt: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    description: PropTypes.string,
    logoPic: PropTypes.string,
    contact: PropTypes.shape({
      discord: PropTypes.string,
      discordServer: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      website: PropTypes.string,
      youtube: PropTypes.string,
      twitch: PropTypes.string,
      instagram: PropTypes.string,
    }),
  }).isRequired,
};

export default PinboardEntryPageDisplay;
