import React, { useState } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';

function TournamentDeregisterHandler() {
  const { participant, publicId, participantPermissions } = useTournament();
  const { canDeregister } = participantPermissions;

  const { post } = useAPI();

  const { t } = useTranslation(['tournaments']);

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const deregisterFromTournament = async () => {
    try {
      setDisabled(true);
      setStatus('');

      await post(`/tournaments/registration/deregister/${publicId}`);

      setDisabled(false);
    } catch (e) {
      setDisabled(false);
      setStatus(t('errors:generic_error'));
    }
  };

  if (!participant.publicId || !canDeregister) return null;

  return (
    <div>
      <button
        type="button"
        className="button is-danger is-block has-margin-auto"
        onClick={deregisterFromTournament}
        disabled={disabled}
      >
        {t('general:deregister_from_tournament')}
      </button>
      {status && (
        <p className="has-text-danger has-text-centered mt-2">
          {status}
        </p>
      )}
    </div>
  );
}

export default TournamentDeregisterHandler;
