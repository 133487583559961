import React from 'react';

// Hooks
import { useTeamProfile } from 'components/Teams/TeamProfile';

// Components
import CardPicDisplay from 'components/utils/CardPicDisplay';
import TeamImageUploadPopup from './TeamImageUploadPopup';

function TeamCardPicHandler() {
  const { permissions, cardPic } = useTeamProfile();
  const { canUpdateTeam } = permissions;

  return (
    <div>
      <CardPicDisplay quadratic img={cardPic} url="/images/teams/card_pictures/" />
      {
        canUpdateTeam && (
        <div className="mt-5">
          <TeamImageUploadPopup />
        </div>
        )
      }
    </div>
  );
}

export default TeamCardPicHandler;
