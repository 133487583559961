import React, { useLayoutEffect, useState } from 'react';

// Hooks
import { useSelector } from 'react-redux';

// Libraries
import PropTypes from 'prop-types';

// Components
import {
  DiscordContact,
  SteamContact,
  RiotContact,
  BattleNetContact,
  EpicContact,
  TagsggContact,
} from 'components/user/Contacts';

// Constants
import {
  DISCORD,
  STEAM,
  RIOT,
  BATTLENET,
  EPIC,
  TAGSGG,
} from 'constants/contacts';

function ContactInfoSelector(props) {
  const {
    isEdit, game, contactInfo, setContactInfo,
  } = props;

  const { contact } = useSelector((state) => state.session);

  const [initDone, setInitDone] = useState(false);

  useLayoutEffect(() => {
    if (isEdit && !initDone) {
      setInitDone(true);
      return;
    }

    const list = [
      { type: TAGSGG, id: contact.tagsgg },
      { type: DISCORD, id: contact.discord },
    ];

    if (game === 'CSGO') {
      list.push({ type: STEAM, id: contact.steam });
    }

    if (['VAL', 'LOL'].includes(game)) {
      list.push({ type: RIOT, id: contact.riot });
    }

    if (game === 'OW') {
      list.push({ type: BATTLENET, id: contact.battlenet });
    }

    if (game === 'RL' || game === 'NL') {
      list.push({ type: EPIC, id: contact.epic });
      list.push({ type: STEAM, id: contact.steam });
    }

    setContactInfo(list);
  }, [game]);

  const updateInfo = (type, id) => {
    const info = contactInfo.map((item) => {
      if (item.type !== type) {
        return item;
      }
      return { type, id };
    });

    setContactInfo(info);
  };

  const showContactList = () => contactInfo.map((item) => {
    const { type, id } = item;

    switch (type) {
      case 'discord': {
        return (
          <DiscordContact
            key="discord"
            value={id}
            setValue={updateInfo}
          />
        );
      }
      case 'riot': {
        return (
          <RiotContact
            key="riot"
            value={id}
            setValue={updateInfo}
          />
        );
      }
      case 'steam': {
        return (
          <SteamContact
            key="steam"
            value={id}
            setValue={updateInfo}
          />
        );
      }

      case 'battlenet': {
        return (
          <BattleNetContact
            key="battlenet"
            value={id}
            setValue={updateInfo}
          />
        );
      }

      case 'epic': {
        return (
          <EpicContact
            key="epic"
            value={id}
            setValue={updateInfo}
          />
        );
      }

      case 'tagsgg': {
        return (
          <TagsggContact
            key="tagsgg"
            value={id}
            setValue={updateInfo}
          />
        );
      }

      default:
        return null;
    }
  });

  return (
    <div>
      <div className="field">
        <h2 className="is-size-4-desktop is-size-5-tablet is-size-6-mobile mb-3">
          Kontaktmöglichkeiten
        </h2>
        <div className="control">
          { showContactList() }
        </div>
      </div>
    </div>
  );
}

ContactInfoSelector.propTypes = {
  contactInfo: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })).isRequired,
  setContactInfo: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

export default ContactInfoSelector;
