import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTournamentStaff } from 'components/tournaments/TournamentPage/TournamentEditor/TournamentStaffHandler';

function TournamentKickButton(props) {
  const { staffMember } = props;

  const { setStaffToKick } = useTournamentStaff();

  const _setMemberToKick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setStaffToKick(staffMember);
  };

  return (
    <div className="is-inline-block">
      <button
        type="button"
        className="button is-danger p-3 is-size-7"
        onClick={_setMemberToKick}
      >
        <i className="fas fa-trash has-text-white" />
      </button>
    </div>
  );
}

TournamentKickButton.propTypes = {
  staffMember: PropTypes.shape({
    playerId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default TournamentKickButton;
