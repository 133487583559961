import React, { useMemo, useState } from 'react';

// Libraries
import stats from 'analytics/matomo';
import { DateTime } from 'luxon';

// Components
import DatePicker from 'react-datepicker';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

function TournamentRegistrationDeadlineEditor() {
  const { deadlines, setRegistrationDeadline, publicId } = useTournament();
  const { registration } = deadlines;

  const [newDate, setNewDate] = useState({
    start: registration.start,
    end: registration.end,
  });
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { language, timezone } = useSelector((state) => state.session);

  const { t } = useTranslation(['general']);
  const { post } = useAPI();

  const prepareDate = (_date) => {
    // if _date is an invalid date, skip
    if (Number.isNaN(_date.getTime())) {
      return undefined;
    }

    const hours = _date.getHours();
    const minutes = _date.getMinutes();
    const day = _date.getDate();
    // month starts at 0
    const month = _date.getMonth() + 1;
    const year = _date.getFullYear();

    _date = DateTime.fromJSDate(_date).setZone(timezone).setLocale(language);
    _date = _date.set({
      hour: hours, minute: minutes, day, month, year,
    });

    return _date.toJSDate().toISOString();
  };

  const setStartDate = (_date) => {
    const newStartDate = prepareDate(_date);
    setNewDate({
      ...newDate,
      start: newStartDate,
    });
  };

  const setEndDate = (_date) => {
    const newEndDate = prepareDate(_date);
    setNewDate({
      ...newDate,
      end: newEndDate,
    });
  };

  const selectedStartDate = useMemo(() => {
    if (!newDate.start) return undefined;

    const timedzonedDate = DateTime.fromISO(newDate.start, { zone: timezone });
    const {
      hour, minute, month, day, year,
    } = timedzonedDate;

    const jsDate = timedzonedDate.toJSDate();

    jsDate.setDate(day);
    // js dates start from 0 up to 11
    jsDate.setMonth(month - 1);
    jsDate.setHours(hour);
    jsDate.setMinutes(minute);
    jsDate.setFullYear(year);

    return jsDate;
  }, [newDate.start, timezone]);

  const selectedEndDate = useMemo(() => {
    if (!newDate.end) return undefined;

    const timedzonedDate = DateTime.fromISO(newDate.end, { zone: timezone });
    const {
      hour, minute, month, day, year,
    } = timedzonedDate;

    const jsDate = timedzonedDate.toJSDate();

    jsDate.setDate(day);
    // js dates start from 0 up to 11
    jsDate.setMonth(month - 1);
    jsDate.setHours(hour);
    jsDate.setMinutes(minute);
    jsDate.setFullYear(year);

    return jsDate;
  }, [newDate.end, timezone]);

  const addDays = (_date, amount) => {
    _date = DateTime.fromJSDate(_date).setZone(timezone);

    _date = _date.set({
      hour: 0, minute: 0, second: 0, millisecond: 0,
    });
    _date = _date.plus({ days: amount });

    const {
      hour, minute, day, month, year,
    } = _date;

    const jsDate = _date.toJSDate();

    jsDate.setDate(day);
    // js dates start from 0 up to 11
    jsDate.setMonth(month - 1);
    jsDate.setHours(hour);
    jsDate.setMinutes(minute);
    jsDate.setFullYear(year);

    // console.log(_date.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS));

    return jsDate;
  };

  const isInPast = (_date) => {
    const startDate = addDays(new Date(), 1);

    return _date >= startDate;
  };

  const saveDeadline = async () => {
    try {
      const isValid = newDate.start && newDate.end && newDate.start < newDate.end;

      if (!isValid) {
        // TODO: improve error message
        setStatus(t('errors:invalid_date_range_error'));
        return;
      }

      setStatus('');
      setDisabled(true);

      const data = {
        registration: {
          start: newDate.start,
          end: newDate.end,
        },
      };

      await post(`/tournaments/deadlines/registration/${publicId}`, data);

      stats.push(['trackEvent', 'Tournament Editor', 'Registration deadline changed']);

      setRegistrationDeadline(newDate);
      setDisabled(false);
    } catch (e) {
      console.log(e);
      setStatus(t('errors:could_not_save_error'));
      setDisabled(false);
    }
  };

  const showSaveButton = useMemo(() => {
    if (newDate.start === registration.start && newDate.end === registration.end) return false;

    return true;
  }, [registration, newDate]);

  return (
    <div className="">
      <div className="">
        <div className="columns">
          <div className="column is-narrow px-0">
            <p className="mb-1 has-text-grey is-size-5">
              {t('general:registration_start')}
            </p>
            <DatePicker
              selected={selectedStartDate}
              onChange={(_date) => setStartDate(_date)}
              disabledKeyboardNavigation
              selectsStart
              showTimeSelect
              timeCaption={t('general:time')}
              dateFormat="Pp"
              timeFormat="p"
              locale={language}
              startDate={selectedStartDate}
              endDate={selectedEndDate}
              filterDate={isInPast}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              className="is-inline-block has-text-white has-text-weight-semibold is-size-5 input"
            />
          </div>

          <div className="column is-offset-1 is-narrow is-flex pr-0">
            <div className="has-margin-top-auto has-margin-left-auto">
              <p className="mb-1 has-text-grey is-size-5">
                {t('general:registration_end')}
              </p>
              <DatePicker
                selected={selectedEndDate}
                onChange={(_date) => setEndDate(_date)}
                disabledKeyboardNavigation
                selectsEnd
                showTimeSelect
                timeCaption={t('general:time')}
                dateFormat="Pp"
                timeFormat="p"
                locale={language}
                startDate={selectedStartDate}
                endDate={selectedEndDate}
                minDate={selectedStartDate}
                filterDate={isInPast}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                className="is-inline-block has-text-white has-text-weight-semibold is-size-5  input"
              />
            </div>
          </div>
        </div>

        {
            showSaveButton && (
            <button
              type="button"
              className="button is-block is-primary grow_small has-text-weight-semibold mt-4"
              onClick={saveDeadline}
              disabled={disabled}
            >
              {t('general:save')}
            </button>
            )
        }

        {
        status && (
          <p className="has-text-weight-semibold mt-4">
            { status }
          </p>
        )
      }

      </div>
    </div>

  );
}

export default TournamentRegistrationDeadlineEditor;
