import React from 'react';

// Components
import TournamentStreamingChannelEditor from './TournamentStreamingChannelEditor';
import TournamentStreamingVodEditor from './TournamentStreamingVodlEditor';
import TournamentStreamingClipEditor from './TournamentStreamingClipEditor';

function TournamentStreamingEditor() {
  return (
    <div>
      <TournamentStreamingChannelEditor />
      <div className="my-4">
        <TournamentStreamingVodEditor />
      </div>
      <TournamentStreamingClipEditor />
    </div>
  );
}

export default TournamentStreamingEditor;
