import React, { useState } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useOrgaPage } from 'components/orgas/OrgaPage';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Components
import Popup from 'reactjs-popup';

function OrgaRemoveTeamPopup() {
  const {
    teamToRemove, setTeamToRemove, removeTeam, publicId: orgaId,
  } = useOrgaPage();

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { post } = useAPI();

  const { t } = useTranslation(['general']);

  if (!teamToRemove) return null;

  const { publicId: teamId, name } = teamToRemove;

  const _removeTeam = async (close) => {
    try {
      setStatus('');
      setDisabled(true);

      const data = {
        teamId,
        orgaId,
      };

      await post('/orgas/teams/remove', data);

      removeTeam(teamId);

      stats.push(['trackEvent', 'Orga Profile', 'Removed team']);

      setDisabled(false);
      close();
    } catch (e) {
      console.log(e);
      setStatus(t('errors:could_not_remove_team_error'));
      setDisabled(false);
    }
  };

  return (
    <div className="">
      <Popup
        position="top center"
        keepTooltipInside="#root"
        repositionOnResize
        closeOnDocumentClick={!disabled}
        closeOnEscape={!disabled}
        modal
        onClose={() => setTeamToRemove()}
        open
      >
        {
          (close) => (
            <div className="box p-0 is-max-fullheight has-max-width-300">
              <div
                className="has-background-primary has-text-centered p-4 pb-6 is-relative br-5"
              >
                <div className="has-text-right">
                  <button
                    type="button"
                    className=" cleanButton has-text-white"
                    onClick={close}
                    disabled={disabled}
                  >
                    <i className="fas fa-times" />
                  </button>
                </div>
                <p
                  className="has-text-weight-semibold is-size-3 mt-"
                >
                  {t('general:remove_team')}
                </p>

                <div
                  className="br-100 has-background-primary is-absolute is-flex has-content-centered"
                  style={{
                    left: 'calc(50% - 45px)', width: '90px', height: '85px',
                  }}
                >
                  <span className="is-size-3 br-5 fas fa-trash" />
                </div>

              </div>

              <div className="p-5 has-background-black-gradient pb-6 has-text-centered">
                <label className="label pt-6 has-text-weight-semibold">
                  {t('general:remove_team_confirmation')}
                </label>
                <p>
                  { name }
                </p>
                <div className="control">

                  <div className="has-fullwidth has-text-centered has-min-width-250">
                    <button
                      type="button"
                      className={`button is-danger mt-5 has-text-weight-semibold ${disabled ? 'is-loading' : ''}`}
                      onClick={() => _removeTeam(close)}
                      disabled={disabled}
                    >
                      {t('general:kick')}
                    </button>

                  </div>
                  <p className=" mt-5 is-size-7">
                    { status }
                  </p>
                </div>
              </div>
            </div>
          )
        }
      </Popup>
    </div>
  );
}

export default OrgaRemoveTeamPopup;
