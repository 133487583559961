import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';

// Components
import AsyncImageUploadPopup from 'components/utils/images/AsyncImageUploadPopup';

function TournamentSponsorCardPicEditor(props) {
  const { publicId } = useTournament();
  const { publicId: sponsorId, cardPic, setCardPic } = props;

  const { t } = useTranslation(['general', 'errors']);
  const { post } = useAPI();

  const saveSponsorCardPic = async (cardPicObj) => {
    const formData = new FormData();
    formData.append('cardPic', cardPicObj);
    formData.append('publicId', sponsorId);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
    };

    const cardPicImg = await post(`/tournaments/sponsors/cardPic/${publicId}`, formData, config);
    setCardPic(cardPicImg);
  };

  return (
    <div className="has-width-200 has-min-height-200 has-fullheight">
      <AsyncImageUploadPopup
        label="sponsor_logo"
        width={200}
        height={200}
        onSave={saveSponsorCardPic}
        imgPath={cardPic ? `/tournaments/card_pictures/${cardPic}` : ''}
      />
    </div>
  );
}

TournamentSponsorCardPicEditor.propTypes = {
  publicId: PropTypes.string.isRequired,
  cardPic: PropTypes.string.isRequired,
  setCardPic: PropTypes.func.isRequired,
};

export default TournamentSponsorCardPicEditor;
