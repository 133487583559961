import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import CardPicDisplay from 'components/utils/CardPicDisplay';
import TournamentKickPlayerHandler from './TournamentKickPlayerHandler';
import TournamentRoleHandler from './TournamentRoleHandler';

function TournamentStaffDisplay(props) {
  const { staffMember } = props;

  // const { timezone, language } = useSelector((state) => state.session);

  const { t } = useTranslation(['general']);

  const {
    name, cardPic, tournamentRole, role,
  } = staffMember;

  return (
    <div className="box p-0 has-cursor-pointer">
      <div
        className="columns is-multiline has-width-350"
      >
        <div className="column has-max-width-80 p-0">
          <div className="">
            <CardPicDisplay img={cardPic} />
          </div>

        </div>
        <div
          className="column"
        >
          <div className="is-flex flex-direction-column has-fullheight has-text-left">
            <div>
              <div className="is-flex">
                <p
                  className="is-size-5 mr-2"
                >
                  {name}
                </p>
              </div>

              { role !== 'staff' && (
              <p className="is-size-7 has-text-warning">
                {t(`general:${role}`)}
              </p>
              )}

              { tournamentRole && (
              <p className="is-size-7 has-text-primary">
                {t(`general:${tournamentRole}`)}
              </p>
              )}
            </div>

          </div>

        </div>

        <div className="column has-text-right is-narrow">
          <div className="mb-2">
            <TournamentRoleHandler staffMember={staffMember} />
          </div>
          <div className="">
            <TournamentKickPlayerHandler staffMember={staffMember} />
          </div>
        </div>
      </div>
    </div>
  );
}

TournamentStaffDisplay.propTypes = {
  staffMember: PropTypes.shape({
    name: PropTypes.string.isRequired,
    cardPic: PropTypes.string.isRequired,
    tournamentRole: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  }).isRequired,
};

export default TournamentStaffDisplay;
