import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  matches: [],
  hasData: false,
};

export const matchSlice = createSlice({
  name: 'match',
  initialState,
  reducers: {
    setMatches: (state, action) => ({
      ...state,
      matches: action.payload || [],
      hasData: true,
    }),
    addMatch: (state, action) => ({
      ...state,
      matches: [...state.matches, action.payload],
    }),
    clearMatches: () => initialState,
  },
});

export const { setMatches, addMatch, clearMatches } = matchSlice.actions;

export default matchSlice.reducer;
