import React, { useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useOrgaPage } from 'components/orgas/OrgaPage';

// Components
import Popup from 'reactjs-popup';
import SetPermissionDropdown from './SetPermissionDropdown';
import SetOrgaRoleDropdown from './SetOrgaRoleDropdown';

function OrgaMemberRolePopup() {
  const { memberToSetRoles, setMemberToSetRoles } = useOrgaPage();

  const [disabled, setDisabled] = useState(false);

  const { t } = useTranslation(['general']);

  if (!memberToSetRoles) return null;

  const { playerId, orgaRole, role } = memberToSetRoles;

  return (
    <div className="">
      <Popup
        position="top center"
        keepTooltipInside="#root"
        repositionOnResize
        closeOnDocumentClick={!disabled}
        closeOnEscape={!disabled}
        modal
        open={!!memberToSetRoles}
        onClose={() => setMemberToSetRoles()}
      >
        {
          (close) => (
            <div className="box p-0 is-max-fullheight has-width-500">
              <div
                className="has-background-primary has-text-centered p-4 pb-6 is-relative br-5"
              >
                <div className="has-text-right">
                  <button
                    type="button"
                    className=" cleanButton has-text-white"
                    onClick={close}
                    disabled={disabled}
                  >
                    <i className="fas fa-times" />
                  </button>
                </div>
                <p
                  className="has-text-weight-semibold is-size-3 mt-"
                >
                  {t('general:edit_player')}
                </p>

                <div
                  className="br-100 has-background-primary is-absolute is-flex has-content-centered"
                  style={{
                    left: 'calc(50% - 45px)', width: '90px', height: '85px',
                  }}
                >
                  <span className="is-size-3 br-5 fas fa-cog" />
                </div>

              </div>

              <div className="p-5 has-background-black-gradient pb-6">
                <label className="label pt-6 mb-5 has-text-weight-semibold">
                  {t('general:edit_player')}
                </label>
                <div className="control">

                  <SetPermissionDropdown
                    playerId={playerId}
                    role={role}
                    disabled={disabled}
                    setDisabled={setDisabled}
                  />

                  <SetOrgaRoleDropdown
                    playerId={playerId}
                    orgaRole={orgaRole}
                    disabled={disabled}
                    setDisabled={setDisabled}
                  />

                </div>
              </div>
            </div>
          )
        }
      </Popup>
    </div>
  );
}

export default OrgaMemberRolePopup;
