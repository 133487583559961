import React, { useMemo, useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Constants
import * as routes from 'constants/routes';

// Components
import LanguageSelector from 'components/utils/LanguageSelector';
import TimezoneSelector from 'components/utils/TimezoneSelector';
import { Link, useLocation } from 'react-router-dom';

// Assets
import icon from 'assets/images/base/icon_no_text.png';
import iconFont from 'assets/images/base/scrimps-text-font.png';
import newSVG from 'assets/images/icons/new_icon.svg';

// Styles
import CardPicDisplay from 'components/utils/CardPicDisplay';
import NavigationRegionSelector from './NavigationRegionSelector';

function Navigation() {
  const [isActive, setIsActive] = useState(false);

  const { isAuthenticated } = useAuth0();

  const toggleNavbarMobile = () => {
    setIsActive(!isActive);
  };

  const hideNavbar = () => {
    setIsActive(false);
  };

  if (!isAuthenticated) {
    return (
      <NavigationNoAuth
        isActive={isActive}
        toggleNavbarMobile={toggleNavbarMobile}
        hideNavbar={hideNavbar}
      />
    );
  }

  return (
    <NavigationAuth
      isActive={isActive}
      toggleNavbarMobile={toggleNavbarMobile}
      hideNavbar={hideNavbar}
    />
  );
}

function NavigationNoAuth() {
  const { t } = useTranslation(['general', 'hotscrimps', 'playerboard']);

  const location = useLocation();
  const { pathname } = location;

  return (
    <aside
      className="menu is-fullheight has-overflow-y-auto box br-0 has-width-250 px-4 pt-5 pb-2 is-flex flex-direction-column has-background-dark-with-gradient"
    >
      <div className="has-text-centered mb-5">
        <Link
          to={routes.LANDING}
          className="is-flex has-content-centered"
        >
          <img src={icon} alt="" width="64" height="64" className="has-max-fullheight" />
          <img src={iconFont} alt="" height="64" className="has-max-fullheight has-max-width-100" />
        </Link>
      </div>

      <div className="">
        <p className="has-text-grey is-size-7 has-text-weight-light mb-3">
          {t('general:crowd')}
        </p>
        <div className="px-2">
          <Link
            className={`has-fullwidth is-block is-size-6 has-text-weight-medium has-text-primary ${pathname === routes.PLAYERS_LIST ? 'has-border-right-primary-bold' : ''}`}
            to={routes.PLAYERS_LIST}
          >
            {`${t('general:players').toUpperCase()}`}
          </Link>

          <Link
            className={`my-1 has-fullwidth is-block is-size-6 has-text-weight-medium has-text-primary ${pathname === routes.TEAMS_LIST ? 'has-border-right-primary-bold' : ''}`}
            to={routes.TEAMS_LIST}
          >
            {`${t('general:teams').toUpperCase()}`}
          </Link>

          <Link
            className={`my-1 has-fullwidth is-block is-size-6 has-text-weight-medium has-text-primary ${pathname === routes.ORGA_LIST ? 'has-border-right-primary-bold' : ''}`}
            to={routes.ORGA_LIST}
          >
            {`${t('general:orgas').toUpperCase()}`}
          </Link>
        </div>
      </div>

      <div className="mt-5">
        <p className="has-text-grey is-size-7 has-text-weight-light mb-3">
          {t('general:competitive')}
        </p>
        <div className="px-2">
          <Link
            className={`has-fullwidth is-block is-size-6 has-text-weight-medium has-text-primary ${pathname === routes.TOURNAMENT_LIST ? 'has-border-right-primary-bold' : ''}`}
            to={routes.TOURNAMENT_LIST}
          >
            {`${t('general:tournaments').toUpperCase()}`}
          </Link>
        </div>
      </div>

      <div className="mt-5">
        <p className="has-text-grey is-size-7 has-text-weight-light mb-3">
          {t('general:special_module')}
        </p>
        <div className="px-2">
          <Link
            className={`has-fullwidth is-block is-size-6 has-text-weight-medium has-text-primary ${pathname === routes.HOT_SCRIMPS ? 'has-border-right-primary-bold' : ''}`}
            to={routes.HOT_SCRIMPS}
          >
            {`${t('hotscrimps:hotscrimps').toUpperCase()} (LFS)`}
          </Link>
          <Link
            className={`my-1 has-fullwidth is-block is-size-6 has-text-weight-medium has-text-primary ${pathname === routes.PLAYERBOARD ? 'has-border-right-primary-bold' : ''}`}
            to={routes.PLAYERBOARD}
          >
            {`${t('playerboard:playerboard').toUpperCase()} (LFT)`}
          </Link>
        </div>
      </div>

      <div className="mt-5">
        <p className="has-text-grey is-size-7 has-text-weight-light mb-3">
          {t('general:ecosystem')}
        </p>
        <div className="px-2">
          <Link
            className={`is-flex align-items-center has-fullwidth is-block is-size-6 has-text-weight-medium has-text-primary ${pathname === routes.PINBOARD_LIST ? 'has-border-right-primary-bold' : ''}`}
            to={routes.PINBOARD_LIST}
          >
            {`${t('general:pinboard').toUpperCase()}`}
            <img src={newSVG} alt="" className=" ml-2" />
          </Link>
        </div>
      </div>

      <div className="mt-5">
        <p className="has-text-grey is-size-7 has-text-weight-light mb-3">
          {t('general:food')}
        </p>
        <div className="px-2">
          <Link
            className={`has-fullwidth is-block is-size-6 has-text-weight-medum has-text-primary ${pathname === routes.RECIPE_LIST ? 'has-border-right-primary-bold' : ''}`}
            to={routes.RECIPE_LIST}
          >
            {`${t('general:recipes').toUpperCase()}`}
          </Link>
        </div>
      </div>

      <div className="mt-5">

        <p className="has-text-grey is-size-7 has-text-weight-light mb-3">
          {t('general:settings')}
        </p>

        <div className="px-2">
          <TimezoneSelector />

          <div className="my-4">
            <LanguageSelector />
          </div>

          <NavigationRegionSelector />
        </div>

        <div className="is-flex has-content-centered mt-6 mb-5">

          <div className="">
            <Link
              to={routes.LOGIN}
              type="button"
              className="button boxshadow has-border-black-light has-background-primary has-text-weight-semibold"
            >
              {t('general:login')}
            </Link>
          </div>
        </div>

      </div>
    </aside>
  );
}

NavigationNoAuth.propTypes = {
  isActive: PropTypes.bool.isRequired,
  toggleNavbarMobile: PropTypes.func.isRequired,
  hideNavbar: PropTypes.func.isRequired,
};

/*
<Link
              className="navbar-item button is-rounded has-background-secondary px-4 has-text-weight-semibold has-margin-auto mx-1 is-inline-block"
              to={routes.HOME}
              onClick={hideNavbar}
            >
              Slip Cards
            </Link>
*/
function NavigationAuth(props) {
  const { logout } = useAuth0();
  const { t } = useTranslation(['general', 'hotscrimps', 'playerboard']);

  const { language, name, cardPic } = useSelector((state) => state.player.player);

  const { isActive, toggleNavbarMobile, hideNavbar } = props;

  const location = useLocation();
  const { pathname } = location;

  const logUserOut = async () => {
    try {
      await logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <aside
      className="menu is-fullheight has-overflow-y-auto box br-0 has-width-250 px-4 pt-5 pb-2 is-flex flex-direction-column has-background-dark-with-gradient"
    >
      <div className="has-text-centered mb-5">
        <Link
          to={routes.LANDING}
          className="is-flex has-content-centered"
        >
          <img src={icon} alt="" width="64" height="64" className="has-max-fullheight" />
          <img src={iconFont} alt="" height="64" className="has-max-fullheight has-max-width-100" />
        </Link>
      </div>

      <div className="">
        <p className="has-text-grey is-size-7 has-text-weight-light mb-3">
          {t('general:crowd')}
        </p>
        <div className="px-2">
          <Link
            className={`has-fullwidth is-block is-size-6 has-text-weight-medium has-text-primary ${pathname === routes.PLAYERS_LIST ? 'has-border-right-primary-bold' : ''}`}
            to={routes.PLAYERS_LIST}
          >
            {`${t('general:players').toUpperCase()}`}
          </Link>

          <Link
            className={`my-1 has-fullwidth is-block is-size-6 has-text-weight-medium has-text-primary ${pathname === routes.TEAMS_LIST ? 'has-border-right-primary-bold' : ''}`}
            to={routes.TEAMS_LIST}
          >
            {`${t('general:teams').toUpperCase()}`}
          </Link>

          <Link
            className={`my-1 has-fullwidth is-block is-size-6 has-text-weight-medium has-text-primary ${pathname === routes.ORGA_LIST ? 'has-border-right-primary-bold' : ''}`}
            to={routes.ORGA_LIST}
          >
            {`${t('general:orgas').toUpperCase()}`}
          </Link>
        </div>
      </div>

      <div className="mt-5">
        <p className="has-text-grey is-size-7 has-text-weight-light mb-3">
          {t('general:competitive')}
        </p>
        <div className="px-2">
          <Link
            className={`has-fullwidth is-block is-size-6 has-text-weight-medium has-text-primary ${pathname === routes.TOURNAMENT_LIST ? 'has-border-right-primary-bold' : ''}`}
            to={routes.TOURNAMENT_LIST}
          >
            {`${t('general:tournaments').toUpperCase()}`}
          </Link>
        </div>
      </div>

      <div className="mt-5">
        <p className="has-text-grey is-size-7 has-text-weight-light mb-3">
          {t('general:special_module')}
        </p>
        <div className="px-2">
          <Link
            className={`has-fullwidth is-block is-size-6 has-text-weight-medium has-text-primary ${pathname === routes.HOT_SCRIMPS ? 'has-border-right-primary-bold' : ''}`}
            to={routes.HOT_SCRIMPS}
          >
            {`${t('hotscrimps:hotscrimps').toUpperCase()} (LFS)`}
          </Link>
          <Link
            className={`my-1 has-fullwidth is-block is-size-6 has-text-weight-medium has-text-primary ${pathname === routes.PLAYERBOARD ? 'has-border-right-primary-bold' : ''}`}
            to={routes.PLAYERBOARD}
          >
            {`${t('playerboard:playerboard').toUpperCase()} (LFT)`}
          </Link>
        </div>
      </div>

      <div className="mt-5">
        <p className="has-text-grey is-size-7 has-text-weight-light mb-3">
          {t('general:ecosystem')}
        </p>
        <div className="px-2">
          <Link
            className={`is-flex align-items-center has-fullwidth is-block is-size-6 has-text-weight-medium has-text-primary ${pathname === routes.PINBOARD_LIST ? 'has-border-right-primary-bold' : ''}`}
            to={routes.PINBOARD_LIST}
          >
            {`${t('general:pinboard').toUpperCase()}`}
            <img src={newSVG} alt="" className=" ml-2" />
          </Link>
        </div>
      </div>

      <div className="mt-5">
        <p className="has-text-grey is-size-7 has-text-weight-light mb-3">
          {t('general:food')}
        </p>
        <div className="px-2">
          <Link
            className={`has-fullwidth is-block is-size-6 has-text-weight-medum has-text-primary ${pathname === routes.RECIPE_LIST ? 'has-border-right-primary-bold' : ''}`}
            to={routes.RECIPE_LIST}
          >
            {`${t('general:recipes').toUpperCase()}`}
          </Link>
        </div>
      </div>

      <div className="mt-5">

        <p className="has-text-grey is-size-7 has-text-weight-light mb-3">
          {t('general:settings')}
        </p>

        <div className="px-2">
          <TimezoneSelector />

          <div className="my-4">
            <LanguageSelector />
          </div>

          <NavigationRegionSelector />
        </div>

        <div className="is-flex has-content-centered mt-6 mb-5">

          <div className="has-text-centered pt-2 is-relative">
            <Link
              to={routes.PLAYER_PROFILE}
              className="is-inline-block"
            >
              <div
                className="has-max-width-70 br-10 has-border-black-light boxshadow has-margin-auto"
              >
                <CardPicDisplay img={cardPic} />

              </div>
              <div className="is-flex has-content-centered mt-3">
                <i className="fas fa-circle has-text-primary mr-2" style={{ fontSize: '7px', marginTop: '2px' }} />
                <p
                  className="is-inline-block is-size-6 py-0 has-text-weight-semibold has-text-standard"
                >
                  {name}
                </p>
              </div>
            </Link>
          </div>

          <div className="ml-5">
            <button
              className="button has-no-background has-width-40 has-height-40 p-2 is-block"
              type="button"
              onClick={logUserOut}
              aria-label={t('general:logout')}
            >
              <i className="fas fa-power-off has-text-grey is-size-5" />
            </button>
            <Link
              className="button has-no-background has-width-40 has-height-40 p-2 is-block mt-1"
              to={routes.PROFILE}
            >
              <i className="fas fa-user-cog has-text-grey is-size-5" />
            </Link>
          </div>
        </div>

      </div>
    </aside>

  );
}

NavigationAuth.propTypes = {
  isActive: PropTypes.bool.isRequired,
  toggleNavbarMobile: PropTypes.func.isRequired,
  hideNavbar: PropTypes.func.isRequired,
};

export default Navigation;
