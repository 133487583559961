export const MAX_TEAM_NAME_LENGTH = 50;
export const MAX_TEAM_DESCRIPTION_LENGTH = 500;
export const MAX_TEAM_NATIONALITIES = 5;

export const ROLES = [
  {
    type: 'owner',
    permissions: ['invite', 'removeAdmin', 'removeMember', 'updateTeam', 'deleteTeam', 'changePermissions', 'changeTeamRole', 'changeIngameRole', 'createHotscrimp', 'cancelHotscrimp', 'acceptHotscrimpRequest', 'declineHotscrimpRequest'],
  },
  {
    type: 'admin',
    permissions: ['invite', 'removeMember', 'updateTeam', 'leave', 'createHotscrimp', 'cancelHotscrimp', 'acceptHotscrimpRequest', 'declineHotscrimpRequest'],
  },
  {
    type: 'member',
    permissions: ['leave'],
  },
];

export const TEAM_ROLES = [
  {
    type: '',
  },
  {
    type: 'coach',
  },
  {
    type: 'sub',
  },
  {
    type: 'player',
  },
];

export const INGAME_ROLES = [
  {
    type: '',
  },
  {
    type: 'jungler',
  },
  {
    type: 'support',
  },
  {
    type: 'mid',
  },
];

export const getPermissionsForRole = (role) => {
  const roleObj = ROLES.find((r) => r.type === role);

  if (!roleObj) throw new Error('Role not found');

  return roleObj.permissions;
};

export const hasPermission = (role, permission) => {
  const permissions = getPermissionsForRole(role);

  return permissions.includes(permission);
};
