import React, { useMemo } from 'react';

// Components
import Dropdown from 'components/utils/Dropdown';

// Hooks
import { useTranslation } from 'react-i18next';

function PinboardAmbitionTypeSelector({
  ambitionTypes, setAmbitionTypes,
}) {
  const { t } = useTranslation(['general', 'pinboard']);

  const preparedMonetaryValues = useMemo(() => [
    { value: 'paid', label: t('pinboard:paid') },
    { value: 'unpaid', label: t('pinboard:unpaid') },
  ], [t]);

  const preparedEngagementValues = useMemo(() => [
    { value: 'competitive', label: t('general:competitive') },
    { value: 'just_for_fun', label: t('general:just_for_fun') },
  ], [t]);

  const preparedMonetaryValue = useMemo(() => preparedMonetaryValues.find((preparedValue) => preparedValue.value === ambitionTypes[0]), [preparedMonetaryValues, ambitionTypes]);

  const preparedEngagementValue = useMemo(() => preparedEngagementValues.find((preparedValue) => preparedValue.value === ambitionTypes[1]), [preparedEngagementValues, ambitionTypes]);

  const setMonetaryValue = (newValue) => {
    setAmbitionTypes([newValue.value, ambitionTypes[1]]);
  };

  const setEngagementValue = (newValue) => {
    setAmbitionTypes([ambitionTypes[0], newValue.value]);
  };

  return (
    <div>
      <div className="columns is-vcentered">
        <div className="column">
          <p className="has-text-weight-bold is-size-5">
            {t('pinboard:ambition_types_label')}
          </p>
          <p className="mt-2">
            {t('pinboard:ambition_types_description')}
          </p>
        </div>
        <div className="column">
          <div>
            <Dropdown
              items={preparedMonetaryValues}
              value={preparedMonetaryValue}
              onSelect={setMonetaryValue}
            />
          </div>
          <div className="mt-4">
            <Dropdown
              items={preparedEngagementValues}
              value={preparedEngagementValue}
              onSelect={setEngagementValue}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PinboardAmbitionTypeSelector;
