import React, { useState, useEffect } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { Trans, useTranslation } from 'react-i18next';

function TeamDeletedNotification(props) {
  const { notification } = props;
  const {
    data,
  } = notification;

  // const [isOpen, setOpen] = useState(false);

  const { t } = useTranslation(['general']);

  const teamName = data && data.length !== 0 ? data[0] : '';

  return (
    <div>

      <button
        type="button"
        className="cleanButton has-fullwidth has-text-standard p-0"
      >

        <div className="columns">
          <div className="column px-0 is-3 is-flex has-content-centered">
            <i className="fas fa-trash is-size-3 has-text-grey" />
          </div>

          <div className="column px-0 is-9 has-text-left">
            <div
              className="has-fullwidth"
            >
              <p className="mt-2 has-text-grey-light has-line-height-full">
                <Trans
                  i18nKey="general:your_team_got_deleted"
                  values={{ name: teamName }}
                />
              </p>

            </div>

          </div>
        </div>

      </button>
    </div>
  );
}

TeamDeletedNotification.propTypes = {
  notification: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default TeamDeletedNotification;
