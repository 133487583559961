import React, { useState, useEffect, useMemo } from 'react';

// Components
import Dropdown from 'components/utils/Dropdown';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import GAMES from 'constants/games';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

function GameSelector({
  game, setGame, filter, showGames, disabled,
}) {
  const [activeGame, setActiveGame] = useState(GAMES[0]);

  const { t } = useTranslation(['general']);

  useEffect(() => {
    if (!game) {
      return;
    }

    // if we have data, set it
    const gameSelected = GAMES.find((gameObj) => gameObj.tag === game);

    if (gameSelected) {
      setActiveGame(gameSelected);
    }
  }, [game]);

  const availableGames = useMemo(() => {
    let games = GAMES;

    if (filter.length !== 0) {
      games = GAMES.filter((_game) => !filter.includes(_game.tag));
    }

    return games.map((_game) => ({
      label: _game.name,
      value: _game.tag,
    }));
  }, [filter]);

  const setGameActive = (newGame) => {
    const gameSelected = GAMES.find((gameObj) => gameObj.tag === newGame.value);

    if (gameSelected === undefined || gameSelected.tag === activeGame.tag) {
      return;
    }

    stats.push(['trackEvent', 'Hot Scrimps Creator', 'Game selected']);

    setActiveGame(gameSelected);

    // set game tag
    setGame(gameSelected.tag);
  };

  const renderedGameSelector = useMemo(() => {
    if (showGames === 'short') {
      return (
        <div className="">
          <p className="is-size-5 has-text-grey mb-2">
            {t('general:game')}
          </p>
          <div className="has-width-200 has-margin-left-auto">
            <Dropdown
              items={availableGames}
              onSelect={setGameActive}
              value={activeGame.name}
              disabled={disabled}
            />
          </div>
        </div>

      );
    }

    const _games = availableGames.map((_game) => {
      const { value } = _game;
      const { iconWhite } = GAMES.find((gameObj) => gameObj.tag === _game.value);

      return (
        <button
          type="button"
          key={value}
          onClick={() => setGameActive(_game)}
          className={`has-no-background has-cursor-pointer has-no-border mx-2 ${activeGame.tag === value ? 'has-border-bottom-primary py-2' : ''}`}
          disabled={disabled}
        >
          <img src={iconWhite} alt="" className="has-width-50 has-height-50" />
        </button>
      );
    });

    return (
      <div>
        <p className="is-size-5 has-text-grey">
          {t('general:game')}
        </p>
        <div className="is-flex mt-2">
          { _games }
        </div>
      </div>
    );
  }, [showGames, availableGames, activeGame, disabled]);

  return (
    <div className="field is-inline-block">
      { renderedGameSelector }
    </div>
  );
}

GameSelector.propTypes = {
  game: PropTypes.string.isRequired,
  setGame: PropTypes.func.isRequired,

  showGames: PropTypes.string,
  filter: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
};

GameSelector.defaultProps = {
  filter: [],
  showGames: 'full',
  disabled: false,
};

export default GameSelector;
