import React, { useEffect, useState } from 'react';

// Components
import ShareCopyButton from '../ShareCopyButton';

// Hooks
import { useTranslation } from 'react-i18next';

function CopyInfoButton(props) {
  const { text, url } = props;

  const { t } = useTranslation(['general']);

  return (
    <ShareCopyButton
      text={text}
      indicator={t('general:copy_info')}
      url={url}
      icon="fas fa-info"
    />
  );
}

export default CopyInfoButton;
