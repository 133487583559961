import React, { useMemo } from 'react';

// Hooks
import { useTeamProfile } from 'components/Teams/TeamProfile';

// Components
import TeamNameEditor from './TeamNameEditor';

const TeamNameHandler = () => {
  const { permissions, name } = useTeamProfile();
  const { canUpdateTeam } = permissions;

  const renderedName = useMemo(() => {
    if (!canUpdateTeam) {
      return (
        <p
          className="is-size-2 has-text-primary has-text-weight-bold"
        >
          { name }
        </p>
      );
    }

    return (
      <TeamNameEditor />
    );
  }, [name]);

  return renderedName;
};

export default TeamNameHandler;
