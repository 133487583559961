import React, { useMemo } from 'react';

// Libaries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import Popup from 'reactjs-popup';
import GameFilterDropdown from 'components/utils/games/GameFilterDropdown';
import ModeFilterDropdown from 'components/utils/games/ModeFilterDropdown';
import PlatformFilterDropdown from 'components/utils/PlatformFilterDropdown';
import MmrFilterDropdown from 'components/utils/games/MmrFilterDropdown';
import DateFilter from './DateFilter';
import TimeFilter from './TimeFilter';

function FilterPopup(props) {
  const {
    isLoading, filter, setFilter, resetFilter,
  } = props;

  const { t } = useTranslation(['general']);

  const {
    rank, platform, date,
  } = filter;

  const { game, mode, mmr } = rank;

  const _setGameFilter = (_game) => {
    setFilter({
      ...filter,
      rank: {
        game: _game,
        mmr: { start: -1 },
        mode: '',
      },
    });

    // dispatch(setRankFilter({ game: _game, mmr: { start: -1 }, mode: '' }));
  };

  const _setModeFilter = (_mode) => {
    setFilter({
      ...filter,
      rank: {
        ...filter.rank,
        mode: _mode,
        mmr: { start: -1 },
      },
    });
    // dispatch(setRankFilter({ ...rank, mode: _mode, mmr: { start: -1 } }));
  };

  const _setMmrFilter = (_mmr) => {
    setFilter({
      ...filter,
      rank: {
        ...filter.rank,
        mmr: { ...rank.mmr, start: _mmr },
      },
    });

    // dispatch(setRankFilter({ ...rank, mmr: { ...rank.mmr, start: _mmr } }));
  };

  const _setPlatformFilter = (_platform) => {
    setFilter({
      ...filter, platform: _platform,
    });

    // dispatch(setPlatformFilter(_platform));
  };

  const setDateFilter = (_date) => {
    setFilter({
      ...filter, date: _date,
    });
  };

  const amountActiveFilters = useMemo(() => {
    let amount = 0;

    if (filter.date) amount++;
    if (filter.name) amount++;
    if (filter.rank.game) amount++;

    return amount;
  }, [filter]);

  return (
    <Popup
      position="top center"
      keepTooltipInside="#root"
      repositionOnResize
      closeOnDocumentClick
      closeOnEscape
      className="no-blur"
      trigger={(
        <div className="is-flex has-content-centered-vertically has-cursor-pointer dont-select-text">
          <i className="fas fa-sliders-h mr-3 is-size-5" />
          <p className="has-text-weight-semibold is-size-5">
            { `${t('general:filter')} (${amountActiveFilters})`}
          </p>
        </div>
        )}
    >
      {
        () => (
          <div className="">
            <div className="box has-max-width-600">
              <div className="is-flex has-content-centered-vertically">
                <p className="has-text-weight-semibold is-size-5">
                  { t('general:filter')}
                </p>
                <button
                  onClick={resetFilter}
                  type="button"
                  className="cleanButton has-margin-left-auto"
                >
                  <i className="fas fa-redo-alt has-text-primary is-size-5" />
                </button>
              </div>
              <div className="columns is-multiline">

                <div className="column is-2-desktop has-min-width-250">
                  <GameFilterDropdown
                    game={game}
                    setGame={_setGameFilter}
                    filter={['NL']}
                    disabled={isLoading}
                  />
                </div>

                {
                    game && (
                      <>
                        <div className="column is-2-desktop has-min-width-250">
                          <ModeFilterDropdown
                            game={game}
                            mode={mode}
                            setMode={_setModeFilter}
                            disabled={isLoading}
                          />
                        </div>
                        <div className="column is-2-desktop has-min-width-250">
                          <PlatformFilterDropdown
                            game={game}
                            platform={platform}
                            setPlatform={_setPlatformFilter}
                            disabled={isLoading}
                          />
                        </div>
                      </>
                    )
                  }

                {
                    mode && (
                      <div className="column is-2-desktop has-min-width-250">
                        <MmrFilterDropdown
                          game={game}
                          mode={mode}
                          mmr={mmr.start}
                          setMmr={_setMmrFilter}
                          disabled={isLoading}
                        />
                      </div>
                    )
                  }

                <div className="column is-2-desktop has-min-width-250">
                  <DateFilter
                    isLoading={isLoading}
                    date={date}
                    setDateFilter={setDateFilter}
                  />
                </div>

                <div className="column is-2-desktop has-min-width-250">
                  <TimeFilter
                    isLoading={isLoading}
                    date={date}
                    setDateFilter={setDateFilter}
                  />
                </div>

              </div>
            </div>
          </div>
        )
        }
    </Popup>
  );
}

FilterPopup.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  filter: PropTypes.shape({
    name: PropTypes.string,
    rank: PropTypes.shape({
      game: PropTypes.string,
      mode: PropTypes.string,
      mmr: PropTypes.shape({
        start: PropTypes.number,
        end: PropTypes.number,
      }),
    }),
    platform: PropTypes.string,
    date: PropTypes.string,
  }).isRequired,
  setFilter: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
};

export default FilterPopup;
