import React, { useState } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import useAPI from 'components/hooks/useAPI';

// Constants
import { MAX_TOURNAMENT_SUBTITLE_LENGTH } from 'constants/tournaments';

// Components
import AsyncInputEditor from 'components/utils/inputs/AsyncInputEditor';

function SubtitleEditor() {
  const { info, setInfo, publicId } = useTournament();
  const { subtitle } = info;

  const { post } = useAPI();

  const saveSubtitle = async (newSubtitle) => {
    const data = { subtitle: newSubtitle };

    await post(`/tournaments/subtitle/${publicId}`, data);

    setInfo({ subtitle: newSubtitle });

    stats.push(['trackEvent', 'Tournament Editor', 'New subtitle set']);
  };

  return (
    <AsyncInputEditor
      label="subtitle"
      text={subtitle}
      onSave={saveSubtitle}
      maxLength={MAX_TOURNAMENT_SUBTITLE_LENGTH}
    />
  );
}

export default SubtitleEditor;
