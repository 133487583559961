import React, { useState, useEffect, useLayoutEffect } from 'react';

// Hooks
import { useNavigate, useParams } from 'react-router-dom';
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

// Constants
import { TOURNAMENT_PAGE, LOGIN } from 'constants/routes';

// Components
import TriangleDisplay from 'components/utils/TriangleDisplay';
import Loading from 'components/utils/Loading';
import InvitationPlayerDisplay from './InvitationPlayerDisplay';
import InvitationTournamentDisplay from './InvitationTournamentDisplay';

function TournamentAcceptInvitePage() {
  const params = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation(['general', 'errors']);

  const { get, post } = useAPI();

  const [invitation, setInviation] = useState();
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { isAuthenticated } = useAuth0();

  useLayoutEffect(() => {
    if (!isAuthenticated) {
      localStorage.setItem('redirectTo', JSON.stringify(window.location.pathname));
      navigate(LOGIN);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const { id } = params;

    const getInvitationData = async () => {
      try {
        setStatus('');
        const _invitation = await get(`/tournaments/invite/staff/${id}`);
        setInviation(_invitation);
      } catch (e) {
        setStatus(t('errors:tournament_not_found'));
      }
    };

    if (!isAuthenticated) return;

    getInvitationData();
  }, [isAuthenticated]);

  const joinTeam = async () => {
    try {
      setDisabled(true);
      setStatus('');

      const { id } = params;

      const tournamentPublicId = await post(`/tournaments/invite/staff/accept/${id}`);

      setDisabled(false);

      navigate(`${TOURNAMENT_PAGE}/${tournamentPublicId}`);
    } catch (e) {
      setDisabled(false);

      setStatus(t('errors:could_not_join_tournament_error'));
    }
  };

  if (!invitation) return <Loading error={status} />;

  const { player, tournament } = invitation;

  return (
    <div className="is-min-fullheight">

      <TriangleDisplay />

      <div
        className="columns is-centered is-mobile is-multiline m-0 px-0 is-min-fullheight"
      >

        <div className="column is-11-desktop is-11-tablet is-11-mobile">

          <div
            className="pt-0"
          >

            <div className="my-6">
              <h1
                className="is-outlined has-text-weight-semibold has-text-primary"
                style={{ fontSize: '65px' }}
              >
                {t('general:join_tournament')}
              </h1>
              <p
                className="is-size-4-desktop is-size-5-tablet is-size-5-mobile has-text-grey"
              >
                {t('general:you_have_been_invited_to_join_a_tournament_staff')}
              </p>
            </div>

          </div>

          <div className="">
            <div className="columns is-multiline">

              <div className="column is-flex flex-direction-column">
                <div className="flex-grow">

                  <div className="mb-4">
                    <InvitationPlayerDisplay player={player} />
                    <p className="mt-3">
                      {`${t('general:has_invited_you_to_join_the_staff_for_the_tournament')}:`}
                    </p>
                  </div>

                  <div className="my-4">
                    <InvitationTournamentDisplay tournament={tournament} />
                  </div>

                  <button
                    type="button"
                    className="button grow is-primary has-text-weight-bold mt-5"
                    onClick={joinTeam}
                    disabled={disabled}
                  >
                    {t('general:join_staff')}
                  </button>

                  <p className="has-text-weight-semibold is-size-6 mt-5">
                    { status }
                  </p>

                </div>

              </div>

            </div>
          </div>

        </div>
      </div>

    </div>
  );
}

export default TournamentAcceptInvitePage;
