import React, { useState } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';

// Assets
import header from 'assets/images/backgrounds/tournament_editor_header.png';

// Components
import AdminTournamentHandler from './AdminTournamentHandler';
import AdminAlertHandler from './AdminAlertHandler';

function TournamentAdminPage() {
  const [currentTab, setCurrentTab] = useState('tournament_handler');

  const { currentPhase, setContentToShow } = useTournament();
  const { t } = useTranslation(['general']);

  return (
    <div className="pb-6 is-min-fullheight">
      <div className="px-5">
        <img src={header} alt="header" />
      </div>

      <div className="mt-6 px-6">
        <button
          type="button"
          className="button is-primary is-small mb-5 py-1 px-6"
          onClick={() => setContentToShow('info')}
          aria-label={t('general:back')}
        >
          <i className="fas fa-arrow-left is-size-5" />
        </button>

        <h1
          className="has-text-danger has-text-weight-bold is-size-1"
        >
          {t('general:admin_panel')}
        </h1>

        <div className="tabs is-boxed mb-5">
          <ul className="pb-2">
            <li className="">
              <button
                type="button"
                className={`button ${currentTab === 'tournament_handler' ? 'is-active' : ''}`}
                onClick={() => setCurrentTab('tournament_handler')}
              >
                {t('general:tournament_handler')}
              </button>
            </li>

            <li className="mx-3">
              <button
                type="button"
                className={`button ${currentTab === 'dispute_handler' ? 'is-active' : ''}`}
                onClick={() => setCurrentTab('dispute_handler')}
              >
                {t('general:disputes')}
              </button>
            </li>

          </ul>
        </div>

        {
          currentTab === 'tournament_handler'
            ? <AdminTournamentHandler />
            : <AdminAlertHandler />
        }

      </div>
    </div>
  );
}

export default TournamentAdminPage;
