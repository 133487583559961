import React, { useEffect, useState } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components
import Popup from 'reactjs-popup';

// Actions
import { removeTeam } from 'store/teams';
import { useTeamProfile } from 'components/Teams/TeamProfile';

// Constants
import { TEAMS_LIST } from 'constants/routes';

function DeleteTeamButton() {
  const { publicId } = useTeamProfile();

  const { del } = useAPI();
  const dispatch = useDispatch();

  const { t } = useTranslation(['general']);

  const navigate = useNavigate();

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const deleteTeam = async (close) => {
    try {
      setStatus('');
      setDisabled(true);

      await del(`/teams/${publicId}`);

      stats.push(['trackEvent', 'Team Profile', 'Team deleted']);

      setDisabled(false);
      close();

      dispatch(removeTeam(publicId));

      navigate(TEAMS_LIST);
    } catch (e) {
      console.log(e);
      setStatus(t('errors:could_not_delete_team_error'));
      setDisabled(false);
    }
  };

  return (
    <div className="">
      <Popup
        trigger={(
          <div className="is-inline-block">
            <div className="button is-danger p-3">
              <i className="fas fa-trash has-text-white mr-3" />
              <p>
                {t('general:delete_team')}
              </p>
            </div>
          </div>
        )}
        position="top center"
        keepTooltipInside="#root"
        repositionOnResize
        closeOnDocumentClick={!disabled}
        closeOnEscape={!disabled}
        modal
      >
        {
          (close) => (
            <div className="box p-0 is-max-fullheight has-max-width-300">
              <div
                className="has-background-primary has-text-centered p-4 pb-6 is-relative br-5"
              >
                <div className="has-text-right">
                  <button
                    type="button"
                    className=" cleanButton has-text-white"
                    onClick={close}
                    disabled={disabled}
                  >
                    <i className="fas fa-times" />
                  </button>
                </div>
                <p
                  className="has-text-weight-semibold is-size-3 mt-"
                >
                  {t('general:delete')}
                </p>

                <div
                  className="br-100 has-background-primary is-absolute is-flex has-content-centered"
                  style={{
                    left: 'calc(50% - 45px)', width: '90px', height: '85px',
                  }}
                >
                  <span className="is-size-3 br-5 fas fa-trash" />
                </div>

              </div>

              <div className="p-5 has-background-black-gradient pb-6 has-text-centered">
                <label className="label pt-6 has-text-weight-semibold">
                  {t('general:team_delete_confirmation')}
                </label>
                <div className="control">

                  <div className="has-fullwidth has-text-centered has-min-width-250">
                    <button
                      type="button"
                      className={`button is-danger mt-5 has-text-weight-semibold ${disabled ? 'is-loading' : ''}`}
                      onClick={() => deleteTeam(close)}
                      disabled={disabled}
                    >
                      {t('general:delete')}
                    </button>

                  </div>
                  <p className=" mt-5 is-size-7">
                    { status }
                  </p>
                </div>
              </div>
            </div>
          )
        }
      </Popup>
    </div>
  );
}

export default DeleteTeamButton;
