import React, { useState, useEffect, useLayoutEffect } from 'react';

// Hooks
import { useNavigate, useParams } from 'react-router-dom';
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

// Constants
import { LOGIN, ORGA_PAGE } from 'constants/routes';

// Components
import TriangleDisplay from 'components/utils/TriangleDisplay';
import Loading from 'components/utils/Loading';
import InvitationOrgaDisplay from './InvitationOrgaDisplay';
import InvitationplayerDisplay from './InvitationPlayerDisplay';
import InvitationTeamSelector from './InvitationTeamSelector';

function OrgaTeamInvitationPage() {
  const params = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation(['general', 'errors']);

  const { get, post } = useAPI();

  const [teamId, setTeamId] = useState('');
  const [invitation, setInviation] = useState();
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { isAuthenticated } = useAuth0();

  useLayoutEffect(() => {
    if (!isAuthenticated) {
      localStorage.setItem('redirectTo', JSON.stringify(window.location.pathname));
      navigate(LOGIN);
    }
  }, []);

  useEffect(() => {
    const { id } = params;

    const getInvitationData = async () => {
      try {
        setStatus('');
        const _invitation = await get(`/orgas/invite/team/${id}`);
        setInviation(_invitation);
      } catch (e) {
        if (e.response.status === 403) {
          setStatus(t('errors:cannot_join_orga_error'));
          return;
        }

        setStatus(t('errors:orga_not_found'));
      }
    };

    if (!isAuthenticated) return;

    getInvitationData();
  }, [isAuthenticated]);

  const joinOrga = async () => {
    try {
      setDisabled(true);
      setStatus('');

      const { id } = params;
      const data = {
        invitationId: id,
        teamId,
      };

      const orgaPublicId = await post('/orgas/invite/team/accept', data);

      setDisabled(false);

      navigate(ORGA_PAGE + orgaPublicId);
    } catch (e) {
      setDisabled(false);

      setStatus(t('errors:could_not_join_orga_error'));
    }
  };

  if (!invitation) return <Loading error={status} />;

  const { orga, player, availableTeams } = invitation;

  return (
    <div className="is-min-fullheight">

      <TriangleDisplay />

      <div
        className="columns is-centered is-mobile is-multiline m-0 px-0 is-min-fullheight"
      >

        <div className="column is-11-desktop is-11-tablet is-11-mobile">

          <div
            className="pt-0"
          >

            <div className="my-6">
              <h1
                className="is-outlined has-text-weight-semibold has-text-primary"
                style={{ fontSize: '65px' }}
              >
                {t('general:join_orga')}
              </h1>
              <p
                className="is-size-4-desktop is-size-5-tablet is-size-5-mobile has-text-grey"
              >
                {t('general:you_have_been_invited_to_join_a_orga')}
              </p>
            </div>

          </div>

          <div className="">
            <div className="columns is-multiline">

              <div className="column is-flex flex-direction-column">
                <div className="flex-grow">

                  <div className="mb-4">
                    <p>
                      Team Selector
                    </p>
                  </div>

                  <div className="mb-4">
                    <InvitationplayerDisplay player={player} orga={orga} />
                    <p className="mt-3">
                      {t('general:has_invited_you_to_join_the_orga')}
                    </p>
                  </div>

                  <div className="my-4">
                    <InvitationOrgaDisplay orga={orga} />
                  </div>

                  <div className="mb-6">
                    <p
                      className="is-size-5"
                    >
                      {t('general:select_a_team_that_joins_orga')}
                    </p>
                    <div className="has-max-width-200">
                      <InvitationTeamSelector
                        teams={availableTeams}
                        team={teamId}
                        setTeam={setTeamId}
                      />
                    </div>
                  </div>

                  <button
                    type="button"
                    className="button grow is-primary has-text-weight-bold mt-5"
                    onClick={joinOrga}
                    disabled={disabled || !teamId}
                  >
                    {t('general:join_orga')}
                  </button>

                  <p className="has-text-weight-semibold is-size-6 mt-5">
                    { status }
                  </p>

                </div>

              </div>

            </div>
          </div>

        </div>
      </div>

    </div>
  );
}

export default OrgaTeamInvitationPage;
