import React, { useMemo } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useTournamentRegistration } from 'components/tournaments/TournamentPage/TournamentPhaseHandler/TournamentRegistration';
import { useTournament } from 'components/tournaments/TournamentPage';

// Components
import Dropdown from 'components/utils/Dropdown';
import { getSelectedModeFromGame } from 'constants/games';

function TournamentPlayerRoleSelector() {
  const { game } = useTournament();

  const onlyOnePlayer = useMemo(() => {
    const mode = getSelectedModeFromGame(game.tag, game.mode);
    return mode.amountPlayers === 1;
  }, [game]);

  const {
    selectedParticipants, disabled,
    setSelectedParticipants,
  } = useTournamentRegistration();

  const { t } = useTranslation(['general']);

  const availablePlayers = useMemo(() => selectedParticipants.map((p) => ({
    value: p.playerId,
    label: p.name,
  })), [selectedParticipants]);

  const selectedCaptain = useMemo(() => selectedParticipants.find((p) => p.role === 'captain'), [selectedParticipants]);

  const selectedCoCaptain = useMemo(() => selectedParticipants.find((p) => p.role === 'co-captain'), [selectedParticipants]);

  const setRole = (player, role) => {
    // remove role from other players, then add role to selected player
    const newPlayers = selectedParticipants.map((p) => {
      if (p.playerId === player.value) {
        return {
          ...p,
          role,
        };
      }

      if (p.role === role) {
        return {
          ...p,
          role: '',
        };
      }

      return p;
    });

    setSelectedParticipants(newPlayers);
  };

  return (
    <div>
      <div className="">
        <p className="mb-1 has-text-grey is-size-5">
          {t('general:captain')}
        </p>
        <div className="has-width-200">
          <Dropdown
            items={availablePlayers}
            value={selectedCaptain ? selectedCaptain.name : ''}
            onSelect={(player) => setRole(player, 'captain')}
            disabled={disabled || selectedParticipants.length === 0}
          />
        </div>
      </div>
      {
        !onlyOnePlayer && (
          <div className="mt-4">
            <p className="mb-1 has-text-grey is-size-5">
              {t('general:co_captain_optional')}
            </p>
            <div className="has-width-200">
              <Dropdown
                items={availablePlayers}
                value={selectedCoCaptain ? selectedCoCaptain.name : ''}
                onSelect={(player) => setRole(player, 'co-captain')}
                disabled={disabled || selectedParticipants.length === 0}
              />
            </div>
          </div>
        )
      }
    </div>
  );
}

export default TournamentPlayerRoleSelector;
