import React, { useMemo, useState } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Constants
import { BEST_OF_MATCHES } from 'constants/tournaments';

// Components
import Dropdown from 'components/utils/Dropdown';

function TournamentBestOfMatchesSelector() {
  const {
    currentPhase, tournamentSettings, setBestOfMatches, publicId,
  } = useTournament();
  const {
    bestOfMatches,
  } = tournamentSettings;

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { t } = useTranslation(['general']);
  const { post } = useAPI();

  const saveNewBestOfMatches = async (newBestOfMatches) => {
    try {
      setDisabled(true);
      setStatus('');
      const data = { bestOfMatches: newBestOfMatches.value };

      await post(`/tournaments/settings/bestOfMatches/${publicId}`, data);

      setBestOfMatches(newBestOfMatches.value);

      stats.push(['trackEvent', 'Tournament Editor', 'New best of for matches set']);

      setDisabled(false);
    } catch (e) {
      console.log(e);
      setDisabled(false);
      setStatus(t('errors:save_error'));
    }
  };

  const availableBestOfMatchess = useMemo(() => BEST_OF_MATCHES.map((_bestOfMatches) => ({
    label: t(`general:${_bestOfMatches}`),
    value: _bestOfMatches,
  })), [t]);

  const selectedBestOfMatches = useMemo(() => {
    const _selectedBestOfMatches = availableBestOfMatchess.find((_bestOfMatches) => _bestOfMatches.value === bestOfMatches);

    return _selectedBestOfMatches || null;
  }, [availableBestOfMatchess, bestOfMatches]);

  const renderedBestOfMatchesSelector = useMemo(() => {
    if (currentPhase !== 'draft') {
      return (
        <div className="">
          <p className="is-size-5 has-text-grey mb-2">
            {t('general:best_of_matches')}
          </p>
          <div className="has-fullwidth">
            <p>
              {bestOfMatches}
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="has-fullwidth">
        <p className="is-size-5 has-text-grey mb-2">
          {t('general:best_of_matches')}
        </p>
        <div className="has-fullwidth">
          <Dropdown
            items={availableBestOfMatchess}
            onSelect={saveNewBestOfMatches}
            value={selectedBestOfMatches}
          />
        </div>
      </div>

    );
  }, [currentPhase, availableBestOfMatchess, bestOfMatches, disabled, selectedBestOfMatches, t]);

  return (
    <div className="field">
      {renderedBestOfMatchesSelector}
      {
        status && (
        <p className="has-text-weight-semibold mt-4">
            {status}
        </p>
        )
    }
    </div>
  );
}

export default TournamentBestOfMatchesSelector;
