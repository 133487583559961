import React, { useMemo, useState } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import useAPI from 'components/hooks/useAPI';

// Actions
import { setLanguage } from 'store/session';

// Constants
import i18n, { AVAILABLE_LANGUAGES } from 'locales/i18n';

// Components
import Dropdown from 'components/utils/Dropdown';

function LanguageSelector() {
  const { isAuthenticated } = useAuth0();

  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.session);

  const [status, setStatus] = useState('');
  const [disabled, setDisabled] = useState(false);

  const { post } = useAPI();

  const { t } = useTranslation(['general']);

  const _setLanguage = async (_language) => {
    try {
      // if the user is not authenticated, just change it locally
      if (!isAuthenticated) {
        i18n.changeLanguage(_language.value || 'us');
        dispatch(setLanguage(_language.value));
        stats.push(['trackEvent', 'Navigation', 'Language set']);

        return;
      }

      setStatus('');
      setDisabled(true);

      const data = {
        language: _language.value,
      };

      await post('/users/language', data);

      i18n.changeLanguage(_language.value || 'us');

      dispatch(setLanguage(_language.value));

      stats.push(['trackEvent', 'Profile', 'Language set']);

      setDisabled(false);
    } catch (e) {
      console.log(e);
      setDisabled(false);
      setStatus(t('errors:save_language_error'));
    }
  };

  const currentValue = useMemo(() => {
    const foundLanguage = AVAILABLE_LANGUAGES.find((_lang) => _lang.value === language);
    return foundLanguage || { label: '', value: '' };
  }, [language]);

  return (
    <div className="is-flex has-content-centered">
      <i
        className="fas fa-book is-size-6 mr-3 has-text-primary has-width-16 has-height-16"
      />
      <div className="control has-width-150">
        <Dropdown
          items={AVAILABLE_LANGUAGES}
          onSelect={_setLanguage}
          value={currentValue}
          disabled={disabled}

        />
      </div>
    </div>

  );
}

export default LanguageSelector;
