import React, { useEffect } from 'react';

// HOCs
import { withAuthenticationRequired } from '@auth0/auth0-react';

// hooks
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// constants
import { HOT_SCRIMPS, ONBOARDING } from 'constants/routes';

function Login() {
  const { hasData, hasAcceptedTerms } = useSelector((state) => state.session);
  const { hasCreatedPlayer } = useSelector((state) => state.player);

  const { t } = useTranslation(['general', 'errors']);

  const navigate = useNavigate();

  useEffect(() => {
    if (!hasData) return;

    if (!hasCreatedPlayer || !hasAcceptedTerms) {
      navigate(ONBOARDING);
      return;
    }

    // Data Provider will take care of redirecting
    const redirectTo = JSON.parse(localStorage.getItem('redirectTo'));

    if (redirectTo) {
      localStorage.removeItem('redirectTo');
      navigate(redirectTo);
    }

    navigate(HOT_SCRIMPS);
  }, [hasData, hasCreatedPlayer]);

  return (
    <div className="hero is-min-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">
          <p className="is-size-7">
            {`${t('general:loading')}...`}
          </p>
        </div>
      </div>
    </div>
  );
}

export default withAuthenticationRequired(Login);
