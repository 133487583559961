import React, { useState, useContext, createContext } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

// Components
import TournamentParticipantSelectorPopup from './TournamentParticipantSelectorPopup';
import TournamentsParticipantsList from '../../common/TournamentParticipantsList';
import TournamentDeregisterHandler from './TournamentDeregisterHandler';

const TournamentRegistrationContext = createContext();

function TournamentRegistration() {
  const { t } = useTranslation(['tournaments']);

  const { isAuthenticated } = useAuth0();
  const { participant } = useTournament();

  const isRegistered = !!participant.publicId;

  // stays '' if 1v1
  const [teamId, setTeamId] = useState('');
  const [selectedParticipants, setSelectedParticipants] = useState([]);

  return (
    <TournamentRegistrationContext.Provider
      value={{
        teamId,
        setTeamId,
        selectedParticipants,
        setSelectedParticipants,
      }}
    >
      <div className="px-6">
        <TournamentDeregisterHandler />

        {
          (!isRegistered && isAuthenticated) && (
            <div className="has-text-centered">
              <TournamentParticipantSelectorPopup />
            </div>
          )
        }

        <div className="mt-6">
          <div className="mt-2">
            <TournamentsParticipantsList />
          </div>
        </div>

      </div>
    </TournamentRegistrationContext.Provider>
  );
}

export const useTournamentRegistration = () => {
  const data = useContext(TournamentRegistrationContext);

  return data;
};

export default TournamentRegistration;
