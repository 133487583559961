import React, { useState } from 'react';

// Hooks
import { useAuth0 } from '@auth0/auth0-react';

// Libraries
import PropTypes from 'prop-types';

// Components
import Popup from 'reactjs-popup';
import CardPicDisplay from 'components/utils/CardPicDisplay';
import PlayerDetailsDisplay from 'components/Players/utils/PlayerDetailsDisplay';

function PlayerDisplay(props) {
  const { player } = props;

  const [disabled, setDisabled] = useState(false);

  const { isAuthenticated } = useAuth0();

  const {
    name, nationality, cardPic, ingameRole, playerId,
  } = player;

  return (
    <Popup
      trigger={(
        <div className="box p-0 has-cursor-pointer is-inline-block">
          <div
            className="columns is-multiline has-width-350"
          >
            <div className="column has-max-width-120 p-0">
              <div className="">
                <CardPicDisplay img={cardPic} />
              </div>

            </div>
            <div
              className="column"
            >
              <div className="is-flex flex-direction-column has-fullheight">

                <div>
                  <div className="is-flex">
                    <p
                      className="is-size-5 mr-2"
                    >
                      {name}
                    </p>
                    <span className={`nmt3 br-5 is-size-5 fi fi-${nationality}`} />
                  </div>

                  { ingameRole && (
                  <p className="is-size-7 has-text-info">
                    {ingameRole}
                  </p>
                  )}
                </div>

                <div className="has-margin-top-auto" />
              </div>

            </div>
          </div>
        </div>
      )}
      position="top center"
      keepTooltipInside="#root"
      modal
      closeOnDocumentClick={!disabled}
      closeOnEscape={!disabled}
      disabled={!isAuthenticated}
    >
      {
        (close) => (
          <PlayerDetailsDisplay
            playerId={playerId}
            close={close}
            setDisabled={setDisabled}
            disabled={disabled}
          />
        )
        }
    </Popup>

  );
}

PlayerDisplay.propTypes = {
  player: PropTypes.shape({
    name: PropTypes.string.isRequired,
    nationality: PropTypes.string.isRequired,
    cardPic: PropTypes.string.isRequired,
    ingameRole: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    playerId: PropTypes.string.isRequired,
  }).isRequired,
};

export default PlayerDisplay;
