import React, { useMemo } from 'react';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Libraries
import regionToLabel from 'utils/regionToLabel';
import { getCurrentLocale } from 'locales/i18n';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

// Constants
import GAMES from 'constants/games';
import { HOT_SCRIMPS } from 'constants/routes';

// Components
import PlatformDisplay from 'components/utils/PlatformDisplay';
import CardPicDisplay from 'components/utils/CardPicDisplay';
import { Link } from 'react-router-dom';
import AverageMmrDisplay from './AverageMmrDisplay';

/**
 * Displays the details of a hotscrimp
 * @param {Object} props
 * @param {number} props.index - The index of the hotscrimp in the list
 * @param {function} props.close - The function to close the details
 * @param {Object} props.hotscrimp - The hotscrimp to display
 *
 * @returns {React.Component} - The component
  */
function HotscrimpDisplay(props) {
  const { t } = useTranslation(['general']);
  const { hotscrimps } = props;

  const { timezone, language } = useSelector((state) => state.session);

  const renderTime = (minutes, time) => {
    const locale = getCurrentLocale();
    time = time.setLocale(locale);

    if (minutes <= 5) {
      if (minutes < -1) {
        return t('general:minutes_ago', { count: Math.abs(minutes) });
        // return `Vor ${Math.abs(minutes)} Minuten`;
      }
      if (minutes < 1) {
        return t('general:now');
      }

      return t('general:in_minutes', { count: minutes });
    }

    // const now = DateTime.now().setZone(timezone);

    // if the hotscrimp is not today, show the whole date
    /*     if (time.startOf('day') <= now.startOf('day')) {
      return time.toLocaleString(DateTime.TIME_SIMPLE);
    } */

    // otherwise, just show the time
    return time.toLocaleString(DateTime.TIME_SIMPLE);
  };

  const getRegionIcon = (region) => {
    if (region === 'eu') return 'europe';

    if (region === 'asia') return 'asia';

    return 'americas';
  };

  const renderedHotscrimps = useMemo(() => hotscrimps.map((hotscrimp, index) => {
    const {
      mode, platform, region, startDate, game, team, publicId,
    } = hotscrimp;

    const { tag } = game;

    const selectedGame = GAMES.find((_game) => _game.tag === tag);

    const currentTime = DateTime.now().setZone(timezone);

    const time = DateTime.fromISO(startDate).setZone(timezone);

    const difference = time - currentTime;
    const minutes = Math.round((difference / 1000) / 60);

    // const isHot = minutes <= 30;

    const renderedDate = time.hasSame(currentTime, 'day') ? t('general:today') : time.toFormat('D');

    const renderedRegion = regionToLabel(region);
    const regionIcon = getRegionIcon(region);

    const renderedTime = renderTime(minutes, time);
    const renderedImage = selectedGame ? selectedGame.icon : '';
    // const renderedMMR = renderMMR(game);

    return (
      <Link
        to={`${HOT_SCRIMPS}/${publicId}`}
        key={index}
      >
        <div className="px-4 py-1 has-background-black-ter has-border-black-light br-5 mb-1 has-cursor-pointer">
          <div className="columns is-vcentered is-multiline">

            <div className="column is-narrow has-width-100 has-text-centered">
              <img
                className="has-fullwidth has-fullheight has-max-width-64 has-max-height-64"
                src={renderedImage}
                alt={game.tag}
              />
            </div>

            <div className="column is-narrow has-width-100 has-text-centered">
              <p
                className="has-text-weight-semibold is-size-7"
              >
                { mode }
              </p>
            </div>

            <div className="column is-narrow has-width-140 has-text-centered">
              <PlatformDisplay platform={platform} />
            </div>

            <div className="column is-narrow has-width-100 has-text-centered">
              <p
                className="has-text-weight-semibold is-flex has-content-centered-vertically ml-2"
              >
                <i className={`fas is-size-4 has-text-primary mr-2 fa-globe-${regionIcon}`} />
                { renderedRegion }
              </p>
            </div>

            <div className="column is-narrow has-width-100">
              <div className="has-height-64 has-width-64 has-margin-auto">
                <CardPicDisplay url="/images/teams/card_pictures/" img={team.cardPic} quadratic />
              </div>
            </div>

            <div className="column is-narrow has-width-120 has-text-centered">
              <div className="has-height-50 has-width-50 has-margin-auto">
                <AverageMmrDisplay game={game.tag} mode={mode} mmr={team.averageMmrPlayers} />
              </div>
            </div>

            <div className="column is-flex">
              <p
                className="has-text-weight-semibold is-flex has-content-centered-vertically mr-3"
              >
                <i className="fas fa-calendar-alt has-text-primary is-size-5 mr-2" />
                { renderedDate }
              </p>
              <p
                className="has-text-weight-semibold is-flex has-content-centered-vertically"
              >
                <i className="fas fa-clock has-text-primary is-size-5 mr-2" />
                { renderedTime }
              </p>
            </div>
          </div>
        </div>
      </Link>

    );
  }), [hotscrimps, timezone, language]);

  const showScrimps = () => {
    if (hotscrimps.length === 0) {
      return (
        <div
          className="has-text-centered mt-5 mb-3"
        >
          <p className="has-text-weight-semibold">
            {t('hotscrimps:no_hotscrimps_info')}
          </p>
        </div>
      );
    }

    return renderedHotscrimps;
  };

  return (
    <div>
      <div className="px-5">

        <div className="columns px-4 is-multiline">

          <div className="column is-narrow has-width-100 has-text-centered">
            <p
              className="has-text-weight-semibold"
            >
              {t('general:game')}
            </p>
          </div>

          <div className="column is-2 has-width-100 has-text-centered">
            <p
              className="has-text-weight-semibold"
            >
              {t('general:mode')}
            </p>
          </div>

          <div className="column is-narrow has-width-140 has-text-centered">
            <p
              className="has-text-weight-semibold"
            >
              {t('general:platform')}
            </p>
          </div>

          <div className="column is-narrow has-width-100 has-text-centered">
            <p
              className="has-text-weight-semibold"
            >
              {t('general:region')}
            </p>
          </div>

          <div className="column is-narrow has-width-100 has-text-centered">
            <p
              className="has-text-weight-semibold"
            >
              {t('general:team')}
            </p>
          </div>

          <div className="column is-narrow has-width-120 has-text-centered">
            <p
              className="has-text-weight-semibold"
            >
              {t('general:team_mmr')}
            </p>
          </div>

          <div className="column is-2 has-text-right">
            <p
              className="has-text-weight-semibold"
            >
              {t('general:date_and_time')}
            </p>
          </div>

        </div>

        <div className="mt-4">
          { showScrimps() }
        </div>
      </div>
    </div>
  );
}

HotscrimpDisplay.propTypes = {
  hotscrimps: PropTypes.arrayOf(PropTypes.shape({
    publicId: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
    platform: PropTypes.string.isRequired,
    team: PropTypes.shape({
      name: PropTypes.string.isRequired,
      averageMmrPlayers: PropTypes.number.isRequired,
      cardPic: PropTypes.string.isRequired,
    }).isRequired,
    game: PropTypes.shape({
      tag: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    }).isRequired,
  })).isRequired,
};

export default HotscrimpDisplay;
