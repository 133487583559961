import React from 'react';

// Libaries
import PropTypes from 'prop-types';
import NameFilter from './NameFilter';

function FilterSelector(props) {
  const {
    filter, setFilter, resetFilter, disabled,
  } = props;

  const setNameFilter = (name) => {
    setFilter({ ...filter, name });
  };

  return (
    <div className="">

      <div className="has-text-right is-flex has-content-centered-vertically">
        <div className="has-margin-left-auto mr-3">
          <NameFilter name={filter.name} setName={setNameFilter} disabled={disabled} />
        </div>
        <button
          onClick={resetFilter}
          type="button"
          className="cleanButton"
        >
          <i className="fas fa-redo-alt has-text-primary is-size-5" />
        </button>
      </div>

    </div>
  );
}

FilterSelector.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default FilterSelector;
