import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useTournament } from 'components/tournaments/TournamentPage';

// Components
import ChatLayout from 'components/utils/ChatLayout';

function TournamentGlobalChatHandler() {
  const { t } = useTranslation(['general']);

  const {
    publicId, sidebarContent,
  } = useTournament();

  return (
    <div
      className="has-background-dark-blue has-text-weight-light is-flex flex-direction-column is-relative"
      style={{ height: 'calc(100vh - 55px)' }}
    >
      <div className="has-fullheight is-flex flex-direction-column p-0">
        <div className="has-text-centered mt-1">
          <h2 className="has-text-standard is-size-5 has-text-weight-light">
            {t('general:global_tournament_chat')}
          </h2>
        </div>
        <div className="is-flex is-relative has-flex-1 has-overflow-hidden pt-1">
          <ChatLayout
            channel="tournament_global_chat"
            id={publicId}
            allowImageUpload
          />
        </div>
      </div>

    </div>

  );
}

export default TournamentGlobalChatHandler;
