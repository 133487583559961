import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useTournament } from 'components/tournaments/TournamentPage';

// Components
import CardPicDisplay from 'components/utils/CardPicDisplay';

function TournamentPlayerRegistrationHandler(props) {
  const { close } = props;

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const {
    ranks, name, cardPic,
  } = useSelector((state) => state.player.player);

  const {
    game, publicId, setParticipant,
  } = useTournament();

  const { post } = useAPI();
  const { t } = useTranslation(['general']);

  const isValid = () => {
    const { mmrRange } = game;

    if (mmrRange.start !== -1) {
      const rank = ranks.find((r) => r.game === game.tag && r.mode === game.mode);

      if (!rank) {
        setStatus(t('errors:player_missing_mmr_error'));
        return false;
      }

      // check if the average mmr is within the mmr range
      if (rank.mmr < mmrRange.start || rank.mmr > mmrRange.end) {
        setStatus(t('errors:mmr_not_in_mmr_range_error'));
        return false;
      }
    }

    return true;
  };

  const registerPlayer = async () => {
    try {
      if (!isValid()) return;

      setStatus('');
      setDisabled(true);

      const participant = await post(`/tournaments/registration/player/${publicId}`);

      setDisabled(false);
      close();
      setParticipant(participant);

      // setStatus(t('general:registration_successful'));
    } catch (err) {
      setDisabled(false);

      if (err.response && err.response.status === 405) {
        setStatus(t('errors:player_missing_mmr_error'));
        return;
      }

      if (err.response && err.response.status === 409) {
        setStatus(t('errors:max_participants_error'));
        return;
      }

      setStatus(t('errors:tournament_registration_error'));
    }
  };

  return (
    <div className="has-text-centered">
      <p className="has-text-weight-bold mb-6">
        {t('general:do_you_want_to_register_for_tournament')}
      </p>
      <div className="has-max-width-100 has-margin-auto">
        <CardPicDisplay img={cardPic} />
        <p
          className="mt-2"
        >
          {name}
        </p>
      </div>
      {
        status && (
          <p
            className="has-text-centered has-text-white has-text-weight-semibold mt-6"
          >
            {status}
          </p>
        )
      }
      <div className="has-text-centered">
        <button
          type="button"
          className={`button is-primary mt-6 ${disabled ? 'is-loading' : ''}`}
          onClick={registerPlayer}
          disabled={disabled}
        >
          {t('general:register_for_tournament')}
        </button>
      </div>
    </div>
  );
}

TournamentPlayerRegistrationHandler.propTypes = {
  close: PropTypes.func.isRequired,
};

export default TournamentPlayerRegistrationHandler;
