import React, { useState, useMemo } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';
import { getSelectedModeFromGame } from 'constants/games';

// Components
import Popup from 'reactjs-popup';
import TournamentPlayerRegistrationHandler from './PlayerRegistrationHandler';
import TournamentTeamRegistrationHandler from './TeamRegistrationHandler';

function TournamentParticipantSelectorPopup() {
  const { publicId, game } = useTournament();
  const { t } = useTranslation(['tournaments']);

  const is1v1 = useMemo(() => {
    const mode = getSelectedModeFromGame(game.tag, game.mode);
    return mode.amountPlayers === 1;
  }, [game]);

  return (
    <Popup
      trigger={(
        <div className="p-5">
          <div className="box has-border-dashed-grey has-text-grey has-max-width-300 p-6 has-margin-auto br-20 has-cursor-pointer grow_small">
            <div className="is-flex has-content-centered flex-direction-column">
              <i className="fas fa-circle-plus is-size-2 mb-2" />
              <p
                className="is-size-5"
              >
                {t('general:select_participants')}
              </p>
            </div>
          </div>
          <button
            type="button"
            className="button is-primary is-size-4 mt-5"
            disabled
          >
            {t('general:register')}
          </button>
        </div>
        )}
      position="top center"
      keepTooltipInside="#root"
      closeOnDocumentClick
      closeOnEscape
      modal
    >
      {(close) => (
        <div className="is-fullwidth has-min-width-300 has-max-width-700 is-flex has-content-centered is-in-center has-overflow-hidden box boxshadow has-background-black-gradient p-0">
          <div className="has-fullwidth is-max-fullheight has-overflow-y-auto has-min-height-500">
            <div
              className="has-text-centered"
            >
              <div
                className="has-gradient-primary has-fullwidth is-relative py-6"
              >

                <div className="is-absolute" style={{ right: 20, top: 20, zIndex: '99' }}>

                  <div className="has-margin-left-auto">
                    <button
                      type="button"
                      onClick={close}
                      className="cleanButton has-text-white"
                      aria-label={t('general:close')}
                    >
                      <i className="fas fa-times is-size-5" />
                    </button>
                  </div>
                </div>

                <p
                  className="is-size-2-desktop is-size-3-touch has-text-weight-semibold"
                >
                  {t('general:register_for_tournament')}
                </p>

              </div>
            </div>

            <div className="p-6">
              {
                is1v1
                  ? <TournamentPlayerRegistrationHandler close={close} />
                  : <TournamentTeamRegistrationHandler close={close} />
              }
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
}

export default TournamentParticipantSelectorPopup;
