import React, { useMemo, useState } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Constants
import { BEST_OF_FINALS } from 'constants/tournaments';

// Components
import Dropdown from 'components/utils/Dropdown';

function TournamentBestOfFinalsSelector() {
  const {
    currentPhase, tournamentSettings, setBestOfFinals, publicId,
  } = useTournament();
  const {
    bestOfFinals,
  } = tournamentSettings;

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { t } = useTranslation(['general']);
  const { post } = useAPI();

  const saveNewBestOfFinals = async (newBestOfFinals) => {
    try {
      setDisabled(true);
      setStatus('');
      const data = { bestOfFinals: newBestOfFinals.value };

      await post(`/tournaments/settings/bestOfFinals/${publicId}`, data);

      setBestOfFinals(newBestOfFinals.value);

      stats.push(['trackEvent', 'Tournament Editor', 'New best of for finals set']);

      setDisabled(false);
    } catch (e) {
      console.log(e);
      setDisabled(false);
      setStatus(t('errors:save_error'));
    }
  };

  const availableBestOfFinals = useMemo(() => BEST_OF_FINALS.map((_bestOfFinals) => ({
    label: t(`general:${_bestOfFinals}`),
    value: _bestOfFinals,
  })), [t]);

  const selectedBestOfFinals = useMemo(() => {
    const _selectedBestOfFinals = availableBestOfFinals.find((_bestOfFinals) => _bestOfFinals.value === bestOfFinals);

    return _selectedBestOfFinals || null;
  }, [availableBestOfFinals, bestOfFinals]);

  const renderedBestOfFinalsSelector = useMemo(() => {
    if (currentPhase !== 'draft') {
      return (
        <div className="">
          <p className="is-size-5 has-text-grey mb-2">
            {t('general:best_of_finals')}
          </p>
          <div className="has-fullwidth">
            <p>
              {bestOfFinals}
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="has-fullwidth">
        <p className="is-size-5 has-text-grey mb-2">
          {t('general:best_of_finals')}
        </p>
        <div className="has-fullwidth">
          <Dropdown
            items={availableBestOfFinals}
            onSelect={saveNewBestOfFinals}
            value={selectedBestOfFinals}
          />
        </div>
      </div>

    );
  }, [currentPhase, availableBestOfFinals, bestOfFinals, disabled, selectedBestOfFinals, t]);

  return (
    <div className="field">
      {renderedBestOfFinalsSelector}
      {
                status && (
                <p className="has-text-weight-semibold mt-4">
                  {status}
                </p>
                )
            }
    </div>
  );
}

export default TournamentBestOfFinalsSelector;
