import React, { useEffect, useLayoutEffect, useState } from 'react';

// Hooks
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// ACtions
import { addEntries } from 'store/playerboard';

function LoadMoreButton() {
  const { t } = useTranslation(['general']);
  const { region } = useSelector((state) => state.session);
  const { entries, filter } = useSelector((state) => state.playerboard);
  const { isAuthenticated } = useAuth0();

  const { post } = useAPI();
  const dispatch = useDispatch();

  const [showButton, setShowButton] = useState(true);
  const [disabled, setDisabled] = useState(false);

  useLayoutEffect(() => {
    if (entries.length % 20 !== 0 || entries.length === 0) {
      setShowButton(false);
      return;
    }

    setShowButton(true);
  }, []);

  const loadEntries = async () => {
    try {
      setDisabled(true);

      // find entry with postedAt date of last entry

      const lastEntry = entries.reduce((min, entry) => (entry.postedAt < min.postedAt ? entry : min), entries[0]);

      const data = {
        filter,
        postedAt: lastEntry ? lastEntry.postedAt : '',
      };

      if (!isAuthenticated) {
        // set region filter if user is not logged in
        data.filter = {
          ...data.filter,
          region,
        };

        const loadedBoard = await post('/playerboard/view', data);

        // hide button if there are not enough entries
        if (loadedBoard.entries.length !== 20) {
          setShowButton(false);
        }

        dispatch(addEntries(loadedBoard.entries));
      } else {
        const loadedBoard = await post('/playerboard', data);

        // hide button if there are not enough entries
        if (loadedBoard.entries.length !== 20) {
          setShowButton(false);
        }

        dispatch(addEntries(loadedBoard.entries));
      }

      setDisabled(false);
    } catch (e) {
      console.log(e);
      setDisabled(false);
    }
  };

  if (showButton === false) return null;

  return (
    <button
      type="button"
      onClick={loadEntries}
      disabled={disabled}
      className="button has-text-white has-text-weight-semibold has-background-primary my-4 grow"
    >
      {t('general:load_more')}
    </button>
  );
}

export default LoadMoreButton;
