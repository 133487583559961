import React, { useMemo } from 'react';

// Hooks
import { useTeamProfile } from 'components/Teams/TeamProfile';

// Constants
import { getSelectedGame } from 'constants/games';

const TeamBreadcrumbs = () => {
  const { game } = useTeamProfile();

  const renderedBreadcrumbs = useMemo(() => {
    if (!game) return null;

    const selectedGame = getSelectedGame(game.tag);

    if (!selectedGame) return null;

    return (
      <p
        className="has-text-black"
      >
        { `${selectedGame.name}`}
      </p>
    );
  }, [game]);

  return renderedBreadcrumbs;
};

export default TeamBreadcrumbs;
