import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import Dropdown from 'components/utils/Dropdown';

// Constants
import { AVAILABLE_NATIONALITIES } from 'constants/user';

function OrgaCountrySelector(props) {
  const { country, setCountry } = props;

  const { t } = useTranslation(['general']);

  const _setCountry = async (_country) => {
    setCountry(_country.value);
  };

  const currentValue = useMemo(() => {
    const foundNationality = AVAILABLE_NATIONALITIES.find((_nationality) => _nationality.value === country);

    return foundNationality || { label: '', value: '' };
  }, [country]);

  return (

    <div className="field">
      <label className="label">
        {t('general:country')}
      </label>
      <div className="control">
        <Dropdown
          items={AVAILABLE_NATIONALITIES}
          onSelect={_setCountry}
          value={currentValue}
          className="has-width-205"
          isSearchable
        />
      </div>
    </div>

  );
}

OrgaCountrySelector.propTypes = {
  country: PropTypes.string.isRequired,
  setCountry: PropTypes.func.isRequired,
};

export default OrgaCountrySelector;
