import React from 'react';
import PropTypes from 'prop-types';

import {
  DISCORD,
  STEAM,
  RIOT,
  BATTLENET,
  EPIC,
  TWITTER,
  TAGSGG,
} from 'constants/contacts';

// Components
import CopyButton from 'components/utils/CopyButton';
import LinkButton from 'components/utils/LinkButton';

// Assets
import tagsIcon from 'assets/images/icons/tagsgg.png';
import epicIcon from 'assets/images/icons/epic_games.png';
import riotIcon from 'assets/images/icons/riot_games.png';

/*
    return (
      <div className="has-max-width-90-percent has-margin-auto my-3 has-border-bottom-grey pb-3" key={index}>
        <div className="is-flex">
          {
            img
              ? (
                <>
                  <img alt="" src={img} className="has-width-40 has-height-40" />
                  <div className="has-margin-left-auto">
                    <LinkButton text={info.id} color="playerboard" />
                  </div>
                </>
              )
              : (
                <>
                  <i className={`is-size-2 ${icon}`} />
                  <div className="has-margin-left-auto">
                    <CopyButton text={info.id} color="playerboard" />
                  </div>
                </>
              )
          }
        </div>
        <p className="has-text-right break-word has-text-weight-semibold">{info.id}</p>
      </div>
    );
*/

function ContactDisplay(props) {
  const { contact, displayType, showText } = props;

  if (contact.length === 0) {
    return null;
  }

  const showContactInfo = () => contact.map((info, index) => {
    let icon = '';
    let img = '';
    let link = '';

    if (!info.id) return null;

    switch (info.type) {
      case DISCORD:
        icon = 'fab fa-discord has-text-white is-size-3';
        break;
      case STEAM:
        icon = 'fab fa-steam has-text-white is-size-3';
        break;
      case EPIC:
        img = epicIcon;
        break;
      case RIOT:
        img = riotIcon;
        break;
      case BATTLENET:
        icon = 'fab fa-battle-net is-size-3 has-text-white';
        break;
      case TWITTER:
        icon = 'fab fa-twitter has-text-white is-size-3';
        break;
      case TAGSGG:
        img = tagsIcon;
        link = `https://tags.gg/${info.id}`;
        break;

      default: return null;
    }

    if (link) {
      return (
        <div className="mr-2 my-3 is-flex has-content-centered-vertically" key={index}>
          <LinkButton url={link} img={img} showText={showText} />
        </div>
      );
    }

    return (
      <div className="mr-2 my-3 is-flex has-content-centered-vertically" key={index}>
        <CopyButton text={info.id} icon={icon || img} showText={showText} />
      </div>
    );
  });

  return (

    <div
      className={`is-inline-flex align-items-center my-3 ${displayType === 'column' ? 'flex-direction-column' : ''}`}
    >
      {showContactInfo()}
    </div>
  );
}

ContactDisplay.propTypes = {
  contact: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  displayType: PropTypes.string,
  showText: PropTypes.bool,
};

ContactDisplay.defaultProps = {
  displayType: 'column',
  showText: true,
};

export default ContactDisplay;
