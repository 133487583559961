import React, { useState, useMemo } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Actions
import { setContact } from 'store/player';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';

// Components
import ContactCopyDisplay from 'components/utils/ContactCopyDisplay';
import AddContactInfoPopup from './AddContactInfoPopup';

function ContactInfoEditor() {
  const { t } = useTranslation(['general', 'errors']);

  const { contact } = useSelector((state) => state.player.player);

  return (
    <div>
      <div className="is-flex has-content-centered-vertically">
        <p className="has-text-weight-semibold is-size-4-desktop is-size-5-touch">
          {t('general:player_ids_and_game_accounts')}
        </p>
        <AddContactInfoPopup />
      </div>

      <ContactCopyDisplay contact={contact} showIds />
    </div>

  );
}

export default ContactInfoEditor;
