import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import { MAX_DESCRIPTION_LENGTH } from 'constants/playerboard';

function DescriptionEditor(props) {
  const { description, setDescription } = props;

  const { t } = useTranslation(['general', 'errors', 'playerboard']);

  const [status, setStatus] = useState('');

  const _setDescription = (_description) => {
    setStatus('');

    if (_description.length >= MAX_DESCRIPTION_LENGTH) {
      setStatus(`${t('errors:max_length_reached_error')}!`);
      return;
    }

    setDescription(_description);
  };

  return (
    <div className="has-fullheight is-flex flex-direction-column">
      <p
        className="has-text-grey is-size-5 mb-2"
      >
        {t('playerboard:description_for_playerboard')}
      </p>
      <textarea
        onChange={(event) => _setDescription(event.target.value)}
        type="text"
        value={description}
        rows={5}
        placeholder={t('general:description')}
        onKeyPress={(e) => {
          if (e.key === 'Enter') e.preventDefault();
        }}
        className="textarea br-10 no-shadow has-border p-3 has-fixed-size is-size-6 is-paddingless is-inline has-fullwidth has-flex-1"
      />
      {
        status && (
          <p
            className="my-2 has-text-weight-semibold is-size-7"
          >
            { status }
          </p>
        )
      }
    </div>
  );
}

DescriptionEditor.propTypes = {
  description: PropTypes.string.isRequired,
  setDescription: PropTypes.func.isRequired,
};

export default DescriptionEditor;
