import React, { useMemo } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import TournamentSponsorEditor from './TournamentSponsorEditor';

const TournamentSponsorsEditor = () => {
  const { publicId, info, setInfo } = useTournament();
  const { sponsors } = info;

  const updateSponsor = async (sponsor) => {
    const newSponsors = [...sponsors];
    // find by publicId
    const index = newSponsors.findIndex((s) => s.publicId === sponsor.publicId);

    if (index !== -1) {
      newSponsors[index] = sponsor;
    } else {
      newSponsors.push(sponsor);
    }

    setInfo({ ...info, sponsors: newSponsors });
  };

  const renderedSponsors = useMemo(() => sponsors.map((sponsor, index) => (
    <div className="column is-narrow" key={index}>
      <TournamentSponsorEditor
        key={index}
        sponsor={sponsor}
        index={index}
        updateSponsor={updateSponsor}
      />
    </div>
  )), [sponsors]);

  return renderedSponsors;
};

export default TournamentSponsorsEditor;
