import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Constants
import { getSelectedGame } from 'constants/games';

function HotscrimpBreadcrumbs(props) {
  const { game } = props;

  const renderedBreadcrumbs = useMemo(() => {
    if (!game) return null;

    const selectedGame = getSelectedGame(game.tag);

    if (!selectedGame) return null;

    return (
      <p
        className="has-text-black"
      >
        { `Hot Scrimps / ${selectedGame.name}`}
      </p>
    );
  }, [game]);

  return renderedBreadcrumbs;
}

HotscrimpBreadcrumbs.propTypes = {
  game: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }).isRequired,
};

export default HotscrimpBreadcrumbs;
