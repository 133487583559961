import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';

// Components
import TournamentEditRoleButton from './TournamentEditRoleButton';

function TournamentRoleHandler(props) {
  const { staffMember } = props;

  const { staffPermissions } = useTournament();
  const { canSetStaffPermissions, canSetStaffTournamentRole } = staffPermissions;

  const renderedButton = useMemo(() => {
    if (!canSetStaffPermissions && !canSetStaffTournamentRole) return null;

    return <TournamentEditRoleButton staffMember={staffMember} />;
  }, [canSetStaffPermissions, canSetStaffTournamentRole, staffMember]);

  return renderedButton;
}

TournamentRoleHandler.propTypes = {
  staffMember: PropTypes.shape({}).isRequired,
};

export default TournamentRoleHandler;
