import React, {
  useState, useMemo, useEffect, useRef,
} from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useAPI from 'components/hooks/useAPI';

// Constants
import { AVAILABLE_NATIONALITIES } from 'constants/user';

// Actions
import { setUserEntry, deleteUserEntry } from 'store/playerboard';

// Components
import PlatformSelector from 'components/utils/PlatformSelector';
import ContactInfoSelector from 'components/utils/ContactInfoSelector';
import CardPicDisplay from 'components/utils/CardPicDisplay';
import TagSelector from 'components/utils/TagSelector';
import withAuthentication from 'components/hocs/withAuthentication';
import RegionSelectorEditor from 'components/utils/RegionSelectorEditor';
import RankSelector from 'components/utils/ranks/RankSelector';
import LanguageSelector from './LanguageSelector';
import DescriptionEditor from './DescriptionEditor';
import EngagementSelector from './EngagementSelector';

function PlayerboardEditor({ disabled, setDisabled, close }) {
  const { t } = useTranslation(['general', 'playerboard', 'validation']);
  const {
    region: userRegion, language,
  } = useSelector((state) => state.session);

  const ref = useRef();

  const {
    name, nationality, platform: currentPlatform, tags: currentTags, languages: currentLanguages, cardPic, description: currentDescription, engagement: currentEngagement, contact: currentContact, hasCreatedEntry: hasCurrentlyEntry, rank: currentRank, region: currentRegion,
  } = useSelector((state) => state.playerboard.userEntry);

  const [rank, setRank] = useState(currentRank);
  const [platform, setPlatform] = useState(currentPlatform);
  const [languages, setLanguages] = useState(currentLanguages.length === 0 ? [language] : currentLanguages);
  const [tags, setTags] = useState(currentTags);
  const [contact, setContact] = useState(currentContact);
  const [description, setDescription] = useState(currentDescription);
  const [engagement, setEngagement] = useState(currentEngagement);
  const [region, setRegion] = useState(currentRegion);
  const [hasCreatedEntry, setHasCreatedEntry] = useState(hasCurrentlyEntry);

  const [status, setStatus] = useState('');

  const { post } = useAPI();
  const dispatch = useDispatch();

  const scrollToStatus = () => {
    if (!ref || !ref.current) return;

    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  const isInputValid = (contactWithData) => {
    if (rank.game === '') {
      setStatus(t('validation:enter_game_error'));
      scrollToStatus();
      return false;
    }

    if (rank.mode === '') {
      setStatus(t('validation:enter_mode_error'));
      scrollToStatus();
      return false;
    }

    if (rank.mmr === -1) {
      setStatus(t('validation:enter_mmr_error'));
      scrollToStatus();
      return false;
    }

    if (contactWithData.length === 0) {
      setStatus(t('validation:enter_atleast_one_contact_error'));
      scrollToStatus();
      return false;
    }

    for (let i = 0; i < contactWithData.length; i++) {
      const contactInfo = contactWithData[i];
      // each contact must have at least 2 letters, or they are optional
      // so if length is just 1, throw an error
      if (contactInfo.length === 1) {
        setStatus(t('validation:enter_atleast_one_contact_error'));
        scrollToStatus();
        return false;
      }
    }

    if (languages.length === 0) {
      setStatus(t('validation:enter_atleast_one_language_error'));
      scrollToStatus();
      return false;
    }

    return true;
  };

  const saveEntry = async () => {
    try {
      const contactWithData = contact.filter((_contact) => _contact.id.length !== 0);
      if (!isInputValid(contactWithData)) return;

      setDisabled(true);
      setStatus('');

      const data = {
        rank,
        platform,
        languages,
        tags,
        contact: contactWithData,
        description,
        engagement,
        region,
      };

      const newUserEntry = await post('/playerboard/save', data);

      setDisabled(false);

      dispatch(setUserEntry(newUserEntry));

      close();
    } catch (e) {
      console.log(e);

      setDisabled(false);
      setStatus(t('errors:an_error_occured_error'));
    }
  };

  const deleteEntry = async () => {
    try {
      setDisabled(true);
      setStatus('');

      const data = { region };

      await post('/playerboard/delete', data);

      setDisabled(false);

      dispatch(deleteUserEntry());

      close();
    } catch (e) {
      console.log(e);

      setDisabled(false);
      setStatus(t('errors:an_error_occured_error'));
    }
  };

  const closeIfNotDisabled = () => {
    if (disabled) return;

    close();
  };

  return (

    <div className="is-fullwidth has-min-width-300 has-max-width-900 ">
      <div className="">
        <div className="">
          <div className="box p-0 pb-6 is-max-fullheight has-overflow-y-auto has-background-black-gradient">
            <div
              className="has-text-centered"
            >

              <div
                className="is-relative"

              >

                <div className="is-absolute" style={{ right: 20, top: 20, zIndex: '99' }}>

                  <div className="has-margin-left-auto">
                    <button
                      type="button"
                      onClick={closeIfNotDisabled}
                      className="cleanButton has-text-white"
                    >
                      <i className="fas fa-times is-size-5" />
                    </button>
                  </div>
                </div>

                <div
                  className="has-gradient-primary has-fullwidth is-relative"
                  style={{ height: '170px' }}
                />

                <div
                  className="is-relative"
                  style={{ marginTop: '-110px' }}
                >
                  <div
                    className="columns px-6"
                  >
                    <div className="column is-narrow">
                      <CardPicDisplay img={cardPic} />
                    </div>

                    <div className="column">
                      <div className="is-flex has-content-centered-vertically">
                        <div>
                          <div className="is-flex has-content-centered-vertically">
                            <p
                              className="has-text-weight-semibold is-size-3-desktop is-size-4-tablet is-size-5-mobile has-text-standard"
                            >
                              {name}
                            </p>
                            <div className="ml-1">
                              <span className={`mx-3 br-5 is-size-3 fi fi-${nationality}`} />
                            </div>
                          </div>
                          <p
                            className="is-size-5-desktop is-size-5-tablet is-size-6-mobile has-text-weight-semibold has-text-dark has-text-left nmt4"
                          >
                            {t('playerboard:playerboard')}
                          </p>
                        </div>

                      </div>

                      <div className="mt-6 has-text-left">

                        <div className="mb-5">
                          <RegionSelectorEditor
                            region={region}
                            setRegion={setRegion}
                            allowAllRegions
                          />
                        </div>

                        <DescriptionEditor description={description} setDescription={setDescription} />

                        <div className="mb-5 mt-5">
                          <RankSelector
                            rank={rank}
                            setRank={setRank}
                            filter={['NL']}
                            type="primary"
                            showGames="full"
                          />
                        </div>

                        <PlatformSelector
                          game={rank.game}
                          platform={platform}
                          setPlatform={setPlatform}
                        />

                        <div className="my-4 has-max-width-350">
                          <LanguageSelector
                            languages={languages}
                            setLanguages={setLanguages}
                          />
                        </div>

                        <div className="my-5">
                          <ContactInfoSelector
                            game={rank.game}
                            contactInfo={contact}
                            setContactInfo={setContact}
                          />
                        </div>

                        <EngagementSelector engagement={engagement} setEngagement={setEngagement} />

                        <div className="mt-4">
                          <TagSelector tags={tags} setTags={setTags} game={rank.game} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>

            <div
              className="is-relative px-6"
            >

              <div className="mt-6 pb-5">

                <div
                  className="has-text-centered mb-5 mt-4"
                >
                  <p className="has-text-weight-semiboldis-size-5" ref={ref}>
                    { status }
                  </p>
                </div>

                <div className="columns is-mobile is-multiline has-text-centered-mobile">
                  <div className="column" />
                  <div className="column has-text-centered">
                    <button
                      type="button"
                      onClick={saveEntry}
                      disabled={disabled}
                      className="button is-size-5 px-5 has-background-primary has-text-white has-text-weight-semibold "
                    >
                      {t('general:save')}
                    </button>
                  </div>
                  <div className="column has-text-right">
                    {
                      hasCreatedEntry && (
                        <button
                          type="button"
                          onClick={deleteEntry}
                          disabled={disabled}
                          className="button is-size-7 px-5 has-background-danger has-text-white has-text-weight-semibold "
                        >
                          <i className="fas fa-trash mr-2" />
                          {t('general:delete')}
                        </button>
                      )
                    }
                  </div>
                </div>

              </div>

            </div>

          </div>

        </div>
      </div>
    </div>

  );
}

PlayerboardEditor.propTypes = {
  disabled: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,

  close: PropTypes.func.isRequired,
};

export default withAuthentication(PlayerboardEditor);
