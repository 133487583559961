import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

// Hooks
import { useTeamProfile } from 'components/Teams/TeamProfile';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Components
import Popup from 'reactjs-popup';

function TeamKickButton(props) {
  const { memberIndex } = props;

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { post } = useAPI();

  const { t } = useTranslation(['general']);

  const { members, kickPlayer, publicId } = useTeamProfile();
  const member = members[memberIndex];

  if (!member) return null;

  const { playerId, name } = member;

  const _kickPlayer = async (close) => {
    try {
      setStatus('');
      setDisabled(true);

      const data = {
        playerId,
        teamId: publicId,
      };

      await post('/teams/members/remove', data);

      kickPlayer(playerId);

      stats.push(['trackEvent', 'Team Profile', 'Member kicked']);

      setDisabled(false);
      close();
    } catch (e) {
      console.log(e);
      setStatus(t('errors:could_not_kick_player_error'));
      setDisabled(false);
    }
  };

  return (
    <div className="">
      <Popup
        trigger={(
          <div className="is-inline-block">
            <div className="button is-danger p-3 is-size-7">
              <i className="fas fa-trash has-text-white" />
            </div>
          </div>
        )}
        position="top center"
        keepTooltipInside="#root"
        repositionOnResize
        closeOnDocumentClick={!disabled}
        closeOnEscape={!disabled}
        modal
      >
        {
          (close) => (
            <div className="box p-0 is-max-fullheight has-max-width-300">
              <div
                className="has-background-primary has-text-centered p-4 pb-6 is-relative br-5"
              >
                <div className="has-text-right">
                  <button
                    type="button"
                    className=" cleanButton has-text-white"
                    onClick={close}
                    disabled={disabled}
                  >
                    <i className="fas fa-times" />
                  </button>
                </div>
                <p
                  className="has-text-weight-semibold is-size-3 mt-"
                >
                  {t('general:kick')}
                </p>

                <div
                  className="br-100 has-background-primary is-absolute is-flex has-content-centered"
                  style={{
                    left: 'calc(50% - 45px)', width: '90px', height: '85px',
                  }}
                >
                  <span className="is-size-3 br-5 fas fa-trash" />
                </div>

              </div>

              <div className="p-5 has-background-black-gradient pb-6 has-text-centered">
                <label className="label pt-6 has-text-weight-semibold">
                  {t('general:kick_player_confirmation')}
                </label>
                <p>
                  { name }
                </p>
                <div className="control">

                  <div className="has-fullwidth has-text-centered has-min-width-250">
                    <button
                      type="button"
                      className={`button is-danger mt-5 has-text-weight-semibold ${disabled ? 'is-loading' : ''}`}
                      onClick={() => _kickPlayer(close)}
                      disabled={disabled}
                    >
                      {t('general:kick')}
                    </button>

                  </div>
                  <p className=" mt-5 is-size-7">
                    { status }
                  </p>
                </div>
              </div>
            </div>
          )
        }
      </Popup>
    </div>
  );
}

TeamKickButton.propTypes = {
  memberIndex: PropTypes.number.isRequired,
};

export default TeamKickButton;
