import React from 'react';

// Hooks
import { useDispatch } from 'react-redux';

// Actions
import {
  resetFilter,
} from 'store/playerboard';

// Components

import FilterPopup from './FilterPopup';

function FilterSelector() {
  const dispatch = useDispatch();

  const _resetFilter = () => {
    dispatch(resetFilter());
  };

  return (

    <div className="">

      <div className="has-text-right is-flex has-content-centered-vertically">
        <div className="has-margin-left-auto">
          <FilterPopup />
        </div>
        <button
          onClick={_resetFilter}
          type="button"
          className="cleanButton ml-3"
        >
          <i className="fas fa-redo-alt has-text-primary is-size-5" />
        </button>
      </div>

    </div>

  );
}

/*
<div>
      <div className="is-flex has-content-centered my-3 has-border-top-grey-lightpt-2">
        <p className="has-text-weight-semibold">
          { t('general:filter')}
        </p>
        <button
          onClick={_resetFilter}
          type="button"
          className="cleanButton has-margin-left-auto is-size-7 has-text-danger has-text-underlined"
        >
          {t('general:reset')}
        </button>
      </div>

      <NationalityFilterDropdown
        nationality={nationality}
        setNationality={_setNationalityFilter}
        disabled={isLoading}
      />

      <EngagementFilter
        type={engagement}
        setType={_setEngagementFilter}
        disabled={isLoading}
      />

      <GameFilterDropdown
        game={game}
        setGame={_setGameFilter}
        filter={['NL']}
        disabled={isLoading}
      />

      <MmrFilterDropdown
        game={game}
        setFilter={_setGameFilter}
        disabled={isLoading}
      />

      <PlatformFilterDropdown
        game={game.tag}
        platform={platform}
        setPlatform={_setPlatformFilter}
        disabled={isLoading}
      />

      <TagSelector
        game={game.tag}
        tags={tags}
        setTags={_setTagsFilter}
        disabled={isLoading}
      />

    </div>
*/

export default FilterSelector;
