import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import { getRankFromGameMode } from 'constants/games';

function AverageMmrDisplay(props) {
  const {
    game, mode, mmr, showText,
  } = props;

  const renderedMmr = useMemo(() => {
    const { img, name: rankName } = getRankFromGameMode(game, mode, mmr, 'mmr');

    return (
      <div className="is-inline-block has-margin-auto">
        <div className="has-content-centered-vertically">
          <img
            src={img}
            alt={rankName}
            className="has-fullwidth has-fullheight"
          />
          {
            showText && (
            <p className="has-text-centered">
              { rankName + (game === 'RL' ? (` (${mmr})`) : '') }
            </p>
            )
          }
        </div>
      </div>
    );
  }, [game, mode, mmr]);

  return renderedMmr;
}

AverageMmrDisplay.propTypes = {
  game: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  mmr: PropTypes.number.isRequired,

  showText: PropTypes.bool,
};

AverageMmrDisplay.defaultProps = {
  showText: false,
};

export default AverageMmrDisplay;
