import React, { useMemo } from 'react';

import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';

function NotificationDateDisplay(props) {
  const { date } = props;
  const { timezone, language } = useSelector((state) => state.session);

  const renderedDate = useMemo(() => {
    const _date = DateTime.fromISO(date).setZone(timezone).setLocale(language);
    return _date.toLocaleString(DateTime.DATETIME_MED);
  }, [date, timezone, language]);

  return (
    <p
      className="is-size-7 has-text-grey-light"
    >
      { renderedDate }
    </p>
  );
}

NotificationDateDisplay.propTypes = {
  date: PropTypes.string.isRequired,
};

export default NotificationDateDisplay;
