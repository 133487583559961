import React, { useMemo } from 'react';

// Libraries
import { sanitizeUrl } from '@braintree/sanitize-url';
import PropTypes from 'prop-types';

function LinkButton(props) {
  const {
    url, img, color, showText,
  } = props;

  const sanitizedUrl = useMemo(() => {
    const sanitized = sanitizeUrl(url);

    return sanitized;
  }, [url]);

  const imgDisplay = !img.startsWith('fa') ? <img src={img} alt="" className="has-min-width-40 has-height-40" /> : <i className={`${img} has-min-width-40 has-height-40`} />;

  return (
    <div className="is-relative">
      <a
        target="_blank"
        rel="noreferrer"
        href={sanitizedUrl}
        className="cleanButton is-flex has-content-centered"
      >
        { imgDisplay }
        {
          showText && (
            <p className="mx-3 has-text-standard is-size-7">{url}</p>
          )
        }

      </a>

    </div>

  );
}

LinkButton.propTypes = {
  url: PropTypes.string.isRequired,
  color: PropTypes.string,
  img: PropTypes.string.isRequired,
  showText: PropTypes.bool,
};

LinkButton.defaultProps = {
  color: 'primary',
  showText: true,
};

export default LinkButton;
