import React, { useState } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Actions
import { setPlayerContact } from 'store/player';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';

// Components
import {
  DiscordContact,
  SteamContact,
  RiotContact,
  BattleNetContact,
  EpicContact,
  TwitterContact,
  TagsggContact,
} from 'components/user/Contacts';
import Popup from 'reactjs-popup';

function AddContactInfoPopup() {
  const [status, setStatus] = useState('');
  const [disabled, setDisabled] = useState(false);

  const { t } = useTranslation(['general', 'errors']);
  const { post } = useAPI();

  const dispatch = useDispatch();
  const { contact } = useSelector((state) => state.player.player);

  const [newContact, setNewContact] = useState(contact);

  const setContactForGame = (type, name) => {
    const _newContact = {
      ...newContact,
    };

    _newContact[type] = name;

    setNewContact(_newContact);
  };

  const saveContact = async (close) => {
    try {
      setStatus('');
      setDisabled(true);

      const data = { contact: newContact };

      await post('/player/contact', data);

      stats.push(['trackEvent', 'Player Profile', 'Contact set']);

      dispatch(setPlayerContact(newContact));

      setDisabled(false);

      close();
    } catch (e) {
      setDisabled(false);
      setStatus(`${t('errors:save_contact_error')}!`);
    }
  };

  return (
    <Popup
      trigger={(
        <button
          type="button"
          className="button has-no-background grow_small has-text-grey has-border-dashed-grey mt-1 mx-3
          "
          onClick={saveContact}
          disabled={disabled}
        >
          <i className="fas fa-plus mr-2" />
          {t('general:add_more')}
        </button>
      )}
      position="top center"
      keepTooltipInside="#root"
      repositionOnResize
      closeOnDocumentClick={!disabled}
      closeOnEscape={!disabled}
      modal
    >
      {
        (close) => (
          <div className="box has-width-400 p-0 is-max-fullheight has-overflow-y-auto">

            <div
              className="has-background-primary has-text-centered p-4 pb-6 is-relative br-5"
            >
              <div className="has-text-right">
                <button
                  type="button"
                  className=" cleanButton has-text-white"
                  onClick={close}
                  disabled={disabled}
                >
                  <i className="fas fa-times" />
                </button>
              </div>
              <p
                className="has-text-weight-semibold is-size-3 mx-3"
              >
                {t('general:contact_info')}
              </p>

              <div
                className="br-100 has-background-primary is-absolute is-flex has-content-centered"
                style={{
                  left: 'calc(50% - 45px)', width: '90px', height: '85px', marginTop: '0px',
                }}
              >
                <i className="fas fa-id-badge is-size-2" />
              </div>

            </div>

            <div className="px-5 pb-5 pt-6 is-flex flex-direction-column has-content-centered">

              <DiscordContact value={newContact.discord} setValue={setContactForGame} />
              <TwitterContact value={newContact.twitter} setValue={setContactForGame} />
              <TagsggContact value={newContact.tagsgg} setValue={setContactForGame} />
              <BattleNetContact value={newContact.battlenet} setValue={setContactForGame} />
              <EpicContact value={newContact.epic} setValue={setContactForGame} />
              <RiotContact value={newContact.riot} setValue={setContactForGame} />
              <SteamContact value={newContact.steam} setValue={setContactForGame} />

              <div className="mt-5 has-text-right">
                <button
                  type="button"
                  className="button has-margin-auto is-primary grow_small has-text-weight-semibold"
                  onClick={() => saveContact(close)}
                  disabled={disabled}
                >
                  {t('general:save')}
                </button>

              </div>

              <p className="mt-4">
                { status }
              </p>

            </div>

          </div>
        )
        }
    </Popup>
  );
}

export default AddContactInfoPopup;
