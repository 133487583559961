import React, {
  useState, useEffect,
} from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';
import { useAuth0 } from '@auth0/auth0-react';

// Assets
import header from 'assets/images/backgrounds/pinboard_header.png';
import rivalRushPng from 'assets/images/branded/rival_rush.png';
import fragsterLeagueSvg from 'assets/images/branded/fragster_league_logo.svg';
import destinyLeaguePng from 'assets/images/branded/destiny_league.png';

// Components
import CreatedPinboardDisplay from './CreatedPinboardDisplay';
import PinboardEntryDisplay from '../common/PinboardEntryDisplay';
import PinboardFilter from './PinboardFilter';

const BASE_FILTER = {
  entryType: '',
  providerType: '',
  consumerType: '',
  game: { tag: '' },
  platform: '',
  ambitionType: '',
  specialIcon: '',
};

function PinboardList() {
  const [pinboardEntries, setPinboardEntries] = useState([]);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [filter, setFilter] = useState(BASE_FILTER);

  const { isAuthenticated } = useAuth0();
  const { t } = useTranslation(['general', 'errors']);
  const { post } = useAPI();

  const resetFilter = () => {
    setFilter(BASE_FILTER);
  };

  const loadEntries = async (updatedAfter = '') => {
    try {
      setDisabled(true);
      const data = { filter };
      let entries = [];

      if (isAuthenticated) {
        entries = await post('/pinboard/list', data);
      } else {
        entries = await post('/pinboard/list/public', data);
      }

      if (updatedAfter) {
        setPinboardEntries([...pinboardEntries, ...entries]);
      } else {
        setPinboardEntries(entries);
      }

      if (entries.length < 20) {
        setShowLoadMoreButton(false);
      } else {
        setShowLoadMoreButton(true);
      }

      setDisabled(false);
    } catch (error) {
      console.error(error);
      setDisabled(false);
    }
  };

  useEffect(() => {
    loadEntries();
  }, [filter]);

  const toggleFilter = (key, value) => {
    if (filter[key] === value) {
      setFilter({ ...filter, [key]: '' });
    } else {
      setFilter({ ...filter, [key]: value });
    }
  };

  const loadMoreEntries = async () => {
    loadEntries(pinboardEntries[pinboardEntries.length - 1].updatedAt);
  };

  const renderedEntries = pinboardEntries.map((entry, index) => {
    const { publicId } = entry;
    return (
      <div key={publicId} className="my-2">
        <PinboardEntryDisplay entry={entry} />
      </div>
    );
  });

  return (
    <div className="is-min-fullheight is-relative has-text-weight-light">

      <div className="columns">
        <div className="column is-6 px-6 is-flex has-content-centered is-relative">
          <div className="">
            <p className="has-text-primary is-size-4 has-text-weight-medium">
              {t('general:your_transfer_market')}
            </p>
            <h1
              className="is-outlined has-text-weight-bold has-text-white has-text-standard"
              style={{ fontSize: '48px', zIndex: 10 }}
            >
              {t('general:pinboard').toUpperCase()}
            </h1>
            <p
              className="has-text-white mt-3 is-size-5 has-text-weight-light"
            >
              {t('general:pinboard_description')}
            </p>
          </div>
        </div>
        <div className="column p-0">
          <img
            src={header}
            alt={t('general:pinboard')}
            className="has-fullwidth"
          />
        </div>
      </div>

      <div className="mb-6">
        <div className="columns is-centered">
          <div className="column is-11">
            <h2 className="is-size-3 has-text-standard has-text-weight-light">
              <i className="fas fa-play mr-4 has-text-primary" />
              {t('pinboard:preselect_highlights')}
            </h2>

            <div className="columns">

              <div className="column is-narrow">
                <button
                  type="button"
                  className={`button has-background-dark has-width-150 has-height-120 is-flex has-content-centered ${filter.specialIcon === 'rival_rush' ? 'has-border-primary' : 'has-border-dark'}`}
                  onClick={() => toggleFilter('specialIcon', 'rival_rush')}
                >
                  <img
                    src={rivalRushPng}
                    alt="Rival Rush"
                    className="has-width-120"
                  />
                </button>
                <p className="is-size-7 mt-2">
                  {t('pinboard:rival_rush_highlight_label')}
                </p>
              </div>

              <div className="column is-narrow">
                <button
                  type="button"
                  className={`button has-background-dark has-width-150 has-height-120 is-flex has-content-centered ${filter.specialIcon === 'fragster_league' ? 'has-border-primary' : 'has-border-dark'}`}
                  onClick={() => toggleFilter('specialIcon', 'fragster_league')}
                >
                  <img
                    src={fragsterLeagueSvg}
                    alt="Rival Rush"
                    className="has-width-120"
                  />
                </button>
                <p className="is-size-7 mt-2">
                  {t('pinboard:fragster_league_highlight_label')}
                </p>
              </div>

              <div className="column is-narrow">
                <button
                  type="button"
                  className={`button has-background-dark has-width-150 has-height-120 is-flex has-content-centered ${filter.specialIcon === 'destiny_league' ? 'has-border-primary' : 'has-border-dark'}`}
                  onClick={() => toggleFilter('specialIcon', 'destiny_league')}
                >
                  <img
                    src={destinyLeaguePng}
                    alt="Rival Rush"
                    className="has-width-120"
                  />
                </button>
                <p className="is-size-7 mt-2">
                  {t('pinboard:destiny_league_highlight_label')}
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="columns is-centered">
        <div className="column is-11">
          <CreatedPinboardDisplay />
        </div>
      </div>

      <div className="mt-6">
        <div className="columns is-centered">
          <div className="column is-11 p-0">

            <div className="columns">
              <div className="column is-narrow">
                <h2 className="is-size-3 has-text-standard has-text-weight-light">
                  <i className="fas fa-play mr-4 has-text-primary" />
                  {t('general:current_entries')}
                </h2>
              </div>
              <div className="column has-text-right">
                <PinboardFilter
                  filter={filter}
                  setFilter={setFilter}
                  resetFilter={resetFilter}
                />
              </div>
            </div>

            <div className="my-3">
              {
                !disabled && renderedEntries.length === 0 ? (
                  <div className="has-text-centered has-text-weight-semibold">
                    <p>{t('pinboard:no_entries_found')}</p>
                  </div>
                ) : renderedEntries
              }
            </div>

            {
              showLoadMoreButton && (
                <div className="has-text-centered">
                  <button
                    type="button"
                    onClick={loadMoreEntries}
                    disabled={disabled}
                    className={`button has-text-white has-text-weight-semibold has-background-primary my-4 grow ${disabled ? 'is-loading' : ''}`}
                  >
                    {t('general:load_more')}
                  </button>
                </div>
              )
            }

          </div>
        </div>
      </div>

    </div>
  );
}

export default PinboardList;
