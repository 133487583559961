import React, { useState, useEffect, useRef } from 'react';

// Hooks
import { useDropzone } from 'react-dropzone';
import { useSelector, useDispatch } from 'react-redux';
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';

// Actions
import { setCardPic } from 'store/player';

// Assets
import defaultAvatar from 'assets/images/profile/default_avatar.png';

// Components
import AvatarEditor from 'react-avatar-editor';
import Popup from 'reactjs-popup';

/**
 * Drag & Drop Zone to upload images
 */
function CardPicEditor() {
  const [currentProfilePic, setCurrentProfilePic] = useState(defaultAvatar);
  const [imgUrl, setImgUrl] = useState(defaultAvatar);

  const [status, setStatus] = useState('');
  const [disabled, setDisabled] = useState(false);

  const ref = useRef();

  const { t } = useTranslation(['general', 'errors']);
  const { post } = useAPI();

  const { cardPic } = useSelector((state) => state.player.player);

  const dispatch = useDispatch();

  useEffect(() => {
    if (cardPic) {
      setImgUrl(`${process.env.REACT_APP_CDN_URL}/images/card_pictures/${cardPic}`);
      setCurrentProfilePic(`${process.env.REACT_APP_CDN_URL}/images/card_pictures/${cardPic}`);
    }

    return () => {
      URL.revokeObjectURL(imgUrl);
    };
  }, [cardPic]);

  const getCroppedImage = () => {
    if (!ref || !ref.current) {
      throw new Error('Canvas not ready');
    }

    const canvas = ref.current.getImage();

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        // returning an error
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }

        resolve(blob);
      }, 'image/png');
    });
  };

  const saveCardPic = async () => {
    try {
      setStatus('');
      setDisabled(true);

      const image = await getCroppedImage();

      const formData = new FormData();
      formData.append('cardPic', image);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      };

      const urlToPic = await post('/player/picture', formData, config);
      dispatch(setCardPic(urlToPic));

      setDisabled(false);

      setStatus(t('general:saved_successfully'));
    } catch (e) {
      setDisabled(false);

      console.log(e);

      setStatus(t('errors:save_profile_picture_error'));
    }
  };

  const _setCardPic = (file) => {
    // if file has been rejected, data will be empty
    if (file.length === 0) return;

    setStatus('');

    URL.revokeObjectURL(imgUrl);
    const url = URL.createObjectURL(file[0]);
    setImgUrl(url);
  };

  const onDropRejected = () => {
    setStatus(t('errors:invalid_profile_picture_error'));
  };

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
    },
    noDrag: true,
    onDrop: _setCardPic,
    maxFiles: 1,
    // max 2 MB
    maxSize: 2097152,
    onDropRejected,
  });

  const hasUploadedImage = imgUrl.startsWith('blob:');

  return (

    <Popup
      trigger={(
        <div className="has-width-190">
          <div className="is-inline-flex box p-0 boxshadow is-relative has-cursor-pointer is-absolute">
            <img src={currentProfilePic} alt="" className="has-width-190 br-5" />
            <div
              className="is-absolute"
              style={{ bottom: '0', right: '0' }}
            >
              <i className="fas fa-upload m-2 p-1 br-5 has-background-grey" />
            </div>
          </div>
        </div>
      )}
      position="top center"
      keepTooltipInside="#root"
      repositionOnResize
      closeOnDocumentClick={!disabled}
      closeOnEscape={!disabled}
      modal
    >
      {
        (close) => (
          <div className="box p-0">
            <div
              className="has-background-primary has-text-centered p-4 pb-6 is-relative br-5"
            >
              <div className="has-text-right">
                <button
                  type="button"
                  className=" cleanButton has-text-white"
                  onClick={close}
                  disabled={disabled}
                >
                  <i className="fas fa-times" />
                </button>
              </div>
              <p
                className="has-text-weight-semibold is-size-3"
              >
                {t('general:profile_picture')}
              </p>

              <div
                className="br-100 has-background-primary is-absolute is-flex has-content-centered"
                style={{
                  left: 'calc(50% - 45px)', width: '90px', height: '85px', zIndex: '999',
                }}
              >
                <i className="fas fa-image is-size-2" />
              </div>

            </div>

            <div
              className="control px-6 py-4 has-background-black-gradient"
            >
              <div className="is-flex has-content-centered pt-6">
                <div className="has-border-grey is-inline-flex has-overflow-hidden p-0 br-10">
                  {
                      hasUploadedImage
                        ? (
                          <AvatarEditor
                            image={imgUrl}
                            width={250}
                            height={400}
                            disabled={!hasUploadedImage}
                            border={hasUploadedImage ? 10 : 0}
                            color={[205, 205, 205, 0.5]} // RGBA
                            scale={1}
                            rotate={0}
                            ref={ref}
                          />
                        )
                        : <img src={imgUrl} alt="" />
                    }
                </div>
              </div>
              <div className="has-fullwidth has-text-centered">
                <p
                  className="has-text-weight-semiboldis-size-7 mt-3 mb-2"
                >
                  {`${t('general:ideal_size')}: (250x400px)`}
                </p>
              </div>

              <div {...getRootProps({
                className: 'has-cursor-pointer has-fullwidth has-text-centered',
              })}
              >
                <input {...getInputProps()} />

                <button
                  type="button"
                  className="button has-fullwidth has-text-weight-semibold mt-2"
                  disabled={disabled}
                >
                  {
                      hasUploadedImage
                        ? t('general:select_different_profile_picture')
                        : t('general:select_profile_picture')
                    }
                </button>
              </div>
              {
                  hasUploadedImage
                      && (
                      <div className="has-fullwidth">
                        <button
                          type="button"
                          className="button br10 has-fullwidth is-primary has-text-weight-semibold mt-4"
                          disabled={disabled}
                          onClick={saveCardPic}
                        >
                          {t('general:save_new_profile_picture')}
                        </button>
                      </div>
                      )
                  }
              <p className="has-text-weight-semibold is-size-7 as-text-centered mb-5 mt-4">
                { status }
              </p>
            </div>
          </div>
        )
        }
    </Popup>
  );
}

export default CardPicEditor;
