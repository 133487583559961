import React from 'react';

// Assets
import icon from 'assets/images/base/icon_no_text.png';

function LogoBottomDisplay() {
  return (
    <div
      style={{
        position: 'absolute',
        bottom: '0px',
        zIndex: -1,
        marginLeft: 'calc(50% - 150px)',
        filter: 'brightness(50%)',
      }}
    >
      <img src={icon} alt="" width="300" height="300" className="" />
    </div>
  );
}

export default LogoBottomDisplay;
