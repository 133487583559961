import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import ContactField from 'components/utils/ContactField';

// Assets
import icon from 'assets/images/icons/tagsgg.png';

function TagsggContact(props) {
  const { value, setValue } = props;
  const [error, setError] = useState('');

  const { t } = useTranslation(['contacts', 'validation']);

  const setAndValidate = (name) => {
    setError('');

    if (name.includes('tags.gg/')) {
      setError(t('validation:only_enter_tags_name_error'));
      return;
    }

    if (name.length > 64) {
      setError(t('validation:choose_shorter_name_error'));
      return;
    }

    setValue('tagsgg', name);
  };

  return (
    <ContactField
      img={icon}
      value={value}
      onChange={setAndValidate}
      error={error}
      label={t('contacts:tags_gg_name')}
      placeholder="luigi"
    />
  );
}

TagsggContact.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
};

TagsggContact.defaultProps = {
  value: '',
};

export default TagsggContact;
