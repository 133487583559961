import React, { useState, useEffect } from 'react';

// Libraries
import PropTypes from 'prop-types';
import axios from 'axios';
import useCards from 'components/hooks/useCards';
import { setMatches } from 'store/cards';

// Hooks
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

// Components
import FlipCard from 'components/matches/FlipCard';

function MatchDisplay(props) {
  const { index } = props;
  const { cards } = useCards();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const card = cards[index];

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const signal = axios.CancelToken.source();

  useEffect(() => {
    const loadMatches = async () => {
      try {
        setLoading(true);

        const token = await getAccessTokenSilently();

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: signal.token,
        };

        const response = await axios.get(`${process.env.REACT_APP_API}/matches/${card._id}`, config);

        const matchesData = response.data;

        dispatch(setMatches({ matches: matchesData, id: card._id }));

        setLoading(false);
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        }

        setLoading(false);

        console.log(err);

        console.log(err.response);

        if (err.response && err.response.data) {
          setError(err.response.data);
          return;
        }

        if (!err.response || err.response.status === 500) {
          setError('Server Fehler. Bitte versuche es später noch einmal');
          return;
        }

        setError('Es ist ein Fehler aufgetreten.');
      }
    };

    loadMatches();
  }, []);

  const { matches } = card;

  if (error) {
    return (<p>Error</p>);
  }

  if (loading) {
    return (<p>Loading...</p>);
  }

  if (!matches || matches.length === 0) {
    return (
      <p>Es gibt noch keine Matches für diese Karte</p>
    );
  }

  const allMatches = matches.map((match) => (
    <div className="column is-narrow" key={match._id}>
      <FlipCard data={match} />
    </div>
  ));

  return (
    <div className="columns is-centered is-multiline is-marginless pb-6">
      { allMatches }
    </div>
  );
}

MatchDisplay.propTypes = {
  index: PropTypes.number.isRequired,
};

export default MatchDisplay;
