import React, { useMemo, useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import useAPI from 'components/hooks/useAPI';

// Components
import ChatImageUploader from './ChatImageUploader';

function ChatInputHandler(props) {
  const { channelId, onSend, allowFileUpload } = props;

  const [currentMsg, setCurrentMsg] = useState('');
  const [currentFiles, setCurrentFiles] = useState([]);

  const { post } = useAPI();

  const uploadMessageWithChat = async () => {
    try {
      const formData = new FormData();
      formData.append('channel', channelId);
      formData.append('message', currentMsg.trim());
      formData.append('image', currentFiles[0].data);

      await post('/chat/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const _sendMessage = () => {
    if (currentFiles.length > 0) {
      uploadMessageWithChat();
      setCurrentMsg('');
      setCurrentFiles([]);
      return;
    }

    const msg = currentMsg.trim();
    if (currentMsg.length === 0 || msg.length === 0) return;

    onSend(msg);
    setCurrentMsg('');
  };

  const handleImage = (file) => {
    setCurrentFiles([file]);
  };

  const _setCurrentMsg = (msg) => {
    if (msg === currentMsg || msg.length >= 500) return;

    // dont allow new lines
    if (msg.includes('\n')) return;

    setCurrentMsg(msg);
  };

  const handleFiles = (files) => {
    setCurrentFiles(files);
  };

  const renderedFilesToUpload = useMemo(() => currentFiles.map((file, index) => {
    const { type, url } = file;

    if (type !== 'image') return null;

    return (
      <div
        key={index}
        className="is-flex has-content-centered has-border-grey-top"
      >
        <img
          alt="chat"
          src={url}
          className="has-max-height-100"
        />
      </div>
    );
  }), [currentFiles]);

  return (
    <div>
      { renderedFilesToUpload }
      <div className="columns is-mobile has-background-dark-blue">
        {
          allowFileUpload && (
            <div className="column is-narrow pr-0 is-flex has-content-centered">
              <ChatImageUploader onUpload={handleImage} />
            </div>
          )
        }
        <div className="column is-flex has-content-centered has-overflow-hidden">
          <textarea
            value={currentMsg}
            onChange={(e) => {
              _setCurrentMsg(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                _sendMessage();
              }
            }}
            rows={1}
            className="textarea resize-none has-height-50 br-10"
          />
        </div>

        <div className="column is-narrow pl-0 is-flex has-content-centered">
          <button
            onClick={_sendMessage}
            className="cleanButton has-cursor-pointer has-text-grey is-size-4 has-no-background is-flex has-content-centered"
            type="button"
          >
            <i className="fas fa-circle-arrow-right" />
          </button>
        </div>
      </div>
    </div>
  );
}

ChatInputHandler.propTypes = {
  channelId: PropTypes.string.isRequired,
  onSend: PropTypes.func.isRequired,
  allowFileUpload: PropTypes.bool.isRequired,
};

export default ChatInputHandler;
