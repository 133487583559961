import React, { useMemo } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';

function TournamentInfoBar() {
  const { deadlines, tournamentSettings } = useTournament();
  const { maxParticipants, maxGroups, tournamentType } = tournamentSettings;

  const { t } = useTranslation(['general']);

  const { language, timezone } = useSelector((state) => state.session);

  const renderedMaxGroups = useMemo(() => (
    <div className="column p-0 has-text-centered">
      <p className="mb-3 is-size-5">
        { t('general:max_groups')}
      </p>
      <div className="has-background-grey-darker has-border-grey-thin is-flex has-content-centered py-5 px-5 br-10-left">
        <p
          className="has-text-weight-bold"
        >
          {maxGroups === -1 ? t('general:unlimited') : maxGroups}
        </p>
      </div>
    </div>
  ), [maxGroups, t]);

  const renderedMaxParticipants = useMemo(() => (
    <div className="column p-0 has-text-centered">
      <p className="mb-3 is-size-5">
        { t('general:max_participants')}
      </p>
      <div className="has-background-grey-darker has-border-grey-thin is-flex has-content-centered py-5 px-5">
        <p
          className="has-text-weight-bold"
        >
          {maxParticipants === -1 ? t('general:unlimited') : maxParticipants}
        </p>
      </div>
    </div>
  ), [maxParticipants, t]);

  const renderedTournamentType = useMemo(() => {
    if (!tournamentType) return null;

    return (
      <div className="column p-0 has-text-centered">
        <p className="mb-3 is-size-5">
          { t('general:type')}
        </p>
        <div className="has-background-grey-darker has-border-grey-thin is-flex has-content-centered py-5 px-5">
          <p
            className="has-text-weight-bold"
          >
            {t(`general:${tournamentType}`)}
          </p>
        </div>
      </div>
    );
  }, [tournamentType, t]);

  const renderedDeadlines = useMemo(() => {
    if (!deadlines || !deadlines.tournament || !deadlines.checkIn) return null;

    const date = DateTime.fromISO(deadlines.tournament.start || new Date().toISOString(), { zone: timezone, locale: language });
    const startDate = date.toLocaleString({
      year: 'numeric', month: '2-digit', day: '2-digit',
    });
    const startTime = date.toLocaleString({
      hour: '2-digit', minute: '2-digit',
    });

    const checkInDate = DateTime.fromISO(deadlines.checkIn.start || new Date().toISOString(), { zone: timezone, locale: language });
    const checkInStartDate = checkInDate.toLocaleString(DateTime.DATETIME_SHORT);

    return (
      <>
        <div className="column p-0 has-text-centered">
          <p className="mb-3 is-size-5">
            { t('general:date')}
          </p>
          <div
            className="has-background-grey-darker has-border-grey-thin is-flex has-content-centered py-5 px-5"
          >
            <p
              className="has-text-weight-bold"
            >
              {startDate}
            </p>
          </div>
        </div>
        <div className="column p-0 has-text-centered">
          <p className="mb-3 is-size-5">
            { t('general:start')}
          </p>
          <div className="has-background-grey-darker has-border-grey-thin is-flex has-content-centered py-5 px-5">
            <p
              className="has-text-weight-bold"
            >
              {startTime}
            </p>
          </div>
        </div>
        <div className="column p-0 has-text-centered">
          <p className="mb-3 is-size-5">
            { t('general:open_checkin')}
          </p>
          <div className="has-background-grey-darker has-border-grey-thin is-flex has-content-centered py-5 px-5 br-10-right">
            <p
              className="has-text-weight-bold"
            >
              {checkInStartDate}
            </p>
          </div>
        </div>
      </>
    );
  }, [deadlines, language, timezone, t]);

  return (
    <div>
      <div className="columns">
        {renderedMaxGroups}
        {renderedMaxParticipants}
        {renderedTournamentType}
        {renderedDeadlines}
      </div>
    </div>
  );
}

export default TournamentInfoBar;
