import { sanitizeUrl } from '@braintree/sanitize-url';
import React, { useMemo } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

function AnnouncementDetailView(props) {
  const { notification } = props;
  const {
    read, createdAt, link, message, publicId, title,
  } = notification;

  const cleanLink = useMemo(() => {
    if (!link) return '';

    const _cleanLink = sanitizeUrl(link);

    return _cleanLink;
  }, [link]);

  const { t } = useTranslation(['general']);

  return (
    <div className="mx-1 mt-2 has-text-white has-text-left">
      <div>
        <p
          className="has-text-weight-semibold"
        >
          {title}
        </p>
      </div>
      { message && (
        <div>
          <p className="is-size-7 has-text-grey-light mt-2">
            {message}
          </p>
        </div>
      )}
      {
        cleanLink && (
        <div className="is-flex mt-4">
          <a
            className="button is-primary has-margin-left-auto is-size-7"
            href={cleanLink}
          >
            { t('general:moreInfo') }
          </a>
        </div>
        )
      }
    </div>
  );
}

export default AnnouncementDetailView;
