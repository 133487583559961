import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTeamProfile } from 'components/Teams/TeamProfile';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Components
import Popup from 'reactjs-popup';
import SetIngameRoleDropdown from './SetIngameRoleDropdown';
import SetPermissionDropdown from './SetPermissionDropdown';
import SetTeamRoleDropdown from './SetTeamRoleDropdown';

/**
 * @name TeamEditRoleButton
 * @description Button to edit a team member's role, teamRole and ingameRole.
 * @param {Object} props Component props
 * @param {number} props.memberIndex Index of the member in the members array
 * @returns {React.Component} A React component
 * @example
 * <TeamEditRoleButton memberIndex={0} />
 */
function TeamEditRoleButton(props) {
  const { memberIndex } = props;

  const { members } = useTeamProfile();

  const [disabled, setDisabled] = useState(false);

  const { t } = useTranslation(['general']);

  const member = members[memberIndex];

  if (!member) return null;

  return (
    <div className="">
      <Popup
        trigger={(
          <div className="is-inline-block">
            <div className="button is-primary p-3 is-size-7">
              <i className="fas fa-cog has-text-white" />
            </div>
          </div>
        )}
        position="top center"
        keepTooltipInside="#root"
        repositionOnResize
        closeOnDocumentClick={!disabled}
        closeOnEscape={!disabled}
        modal
      >
        {
          (close) => (
            <div className="box p-0 is-max-fullheight has-width-500">
              <div
                className="has-background-primary has-text-centered p-4 pb-6 is-relative br-5"
              >
                <div className="has-text-right">
                  <button
                    type="button"
                    className=" cleanButton has-text-white"
                    onClick={close}
                    disabled={disabled}
                  >
                    <i className="fas fa-times" />
                  </button>
                </div>
                <p
                  className="has-text-weight-semibold is-size-3 mt-"
                >
                  {t('general:edit_player')}
                </p>

                <div
                  className="br-100 has-background-primary is-absolute is-flex has-content-centered"
                  style={{
                    left: 'calc(50% - 45px)', width: '90px', height: '85px',
                  }}
                >
                  <span className="is-size-3 br-5 fas fa-cog" />
                </div>

              </div>

              <div className="p-5 has-background-black-gradient pb-6">
                <label className="label pt-6 mb-5 has-text-weight-semibold">
                  {t('general:edit_player')}
                </label>
                <div className="control">

                  <SetPermissionDropdown
                    playerIndex={memberIndex}
                    disabled={disabled}
                    setDisabled={setDisabled}
                  />

                  <div className="mb-2">
                    <SetIngameRoleDropdown
                      playerIndex={memberIndex}
                      disabled={disabled}
                      setDisabled={setDisabled}
                    />
                  </div>

                  <SetTeamRoleDropdown
                    playerIndex={memberIndex}
                    disabled={disabled}
                    setDisabled={setDisabled}
                  />

                </div>
              </div>
            </div>
          )
        }
      </Popup>
    </div>
  );
}

TeamEditRoleButton.propTypes = {
  memberIndex: PropTypes.number.isRequired,
};

export default TeamEditRoleButton;
